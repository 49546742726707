<template>
  <div id="my_header">
    <div class="my_header-block">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="">
      </div>
      <div class="search" id="search_header"></div>
      <div class="logout" @click="logout">
        <img src="@/assets/images/logout.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({name: 'login'})
    }
  },
}
</script>
<style lang="scss" scoped>
  .my_header-block {
    margin-top: 21px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logout {
    flex: 0 0 auto;
    transform: rotate(180deg);
  }
  .logo {
    flex: 0 0 auto;
  }
</style>