const url_auth = `${process.env.VUE_APP_AUTH_URL}/`;

export const get_token = async (data) => { //получение токена
  const url = new URL('login', url_auth);
  return await window.ky_auth.post(url, {
    body: data
  }).json();
}

export const get_owner = async () => {
  const url = new URL('owner', url_auth);
  return await window.ky_auth.get(url, {
    guard: true,
    retry: {
      limit: 1,
    }
  }).json();
}

export const get_permission = async () => { //получение доступов
  const url = new URL('permission', url_auth);
  return await window.ky_auth.get(url, {
    guard: true
  }).json();
}

export const set_recovery = async (data) => { //получение токена
  const url = new URL('password/email', url_auth);
  return await window.ky_auth.post(url, {
    body: data
  }).json();
}