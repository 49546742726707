<script setup>
import icon_home from "@/components/menu_icons/home.vue";
import icon_car from "@/components/menu_icons/car.vue";
import icon_gserver from "@/components/menu_icons/gserver.vue";
</script>

<template>
  <div class="footer-app">
    <router-link :to="{name: 'home'}" custom v-slot="{ navigate, isActive }">
      <div class="has-text-centered" @click="navigate">
        <icon_home class="icon-svg" :icon_color="isActive ? '#44FF8F' : 'white'" />
        <p class="text-white" :class="{'active-link': isActive}">Главная</p>
      </div>
    </router-link>
    <router-link :to="{name: 'vehicles'}" custom v-slot="{ navigate, isActive }">
      <div class="has-text-centered" @click="navigate">
        <icon_car class="icon-svg" :icon_color="isActive ? '#44FF8F' : 'white'" />  
        <p class="text-white" :class="{'active-link': isActive}">Автомобили</p>
      </div>
    </router-link>
    <router-link :to="{name: 'installation_kit'}" custom v-slot="{ navigate, isActive }">
      <div class="has-text-centered" @click="navigate">
        <icon_gserver class="icon-svg" :icon_color="isActive ? '#44FF8F' : 'white'" />
        <p class="text-white"  :class="{'active-link': isActive}" >Gservers</p>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // isActive: false,
    }
  }
};
</script>
<style lang="scss" scoped>
.footer-app {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(92.22deg, #3db7b0 0.37%, #32219a 99.58%);
}

.active-link {
  color: $active-green-text !important;
}
.has-text-centered {
  text-align: center;
}
</style>