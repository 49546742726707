import { ref, onMounted, watch } from 'vue'

//задаем переменные, создаем запрос для присваивания этих переменных 
export function vehicles_sensor() {
  const vehicles_sensor = ref(null)
  
  const fetching = () => {
    Api.carpark.vehicles_sensor({query: route.query}).then((res)=> {
      vehicles_sensor.value = res.data;
    })
  }


  return {
    vehicles_sensor
  }
}