<template>
  <div class="platforms-statistic">
    <div class="platforms-title">
      <h4 class="h4">Площадки</h4>
      <p class="count-all">
        {{ summary?.platforms?.all }}
      </p>
    </div>
    <div class="screen-block">
      <div class="screen screen--green">
        <p class="strong">
          {{ summary?.platforms?.success }}
        </p>
        <p>Вывоз</p>
      </div>
      <div class="screen screen--orange">
        <p class="strong">
          {{ summary?.platforms?.error }}
        </p>
        <p>Невывоз</p>
      </div>
      <div class="screen screen--blue">
        <p class="strong">
          {{ summary?.platforms?.remain }}
        </p>
        <p>Остаток</p>
      </div>
    </div>
  </div>
  <div class="containers-statistic">
    <div class="platforms-title">
      <h4 class="h4">Контейнеры</h4>
      <p class="count-all">
        {{ summary?.containers?.all }}
      </p>
    </div>
    <div class="screen-block">
      <div class="screen screen--green">
        <p class="strong">
          {{ summary?.containers?.success }}
        </p>
        <p>Вывоз</p>
      </div>
      <div class="screen screen--orange">
        <p class="strong">
          {{ summary?.containers?.error }}
        </p>
        <p>Невывоз</p>
      </div>
      <div class="screen screen--blue">
        <p class="strong">
          {{ summary?.containers?.remain }}
        </p>
        <p>Остаток</p>
      </div>
    </div>
  </div>
  <div class="volume-statistic">
    <div class="platforms-title">
      <h4 class="h4">Объем вывоза (м3)</h4>
      <p class="count-all">
        {{ summary?.volume?.all }}
      </p>
    </div>
    <div class="screen-block">
      <div class="screen screen--green">
        <p class="strong">
          {{ summary?.volume?.success }}
        </p>
        <p>Вывоз</p>
      </div>
      <div class="screen screen--orange">
        <p class="strong">
          {{ summary?.volume?.error }}
        </p>
        <p>Невывоз</p>
      </div>
      <div class="screen screen--blue">
        <p class="strong">
          {{ summary?.volume?.remain }}
        </p>
        <p>Остаток</p>
      </div>
    </div>
  </div>
  <div class="events">
    <div class="events-block">
      <div class="events-block-title">
        События
      </div>
      <div class="events-block-item" @click="open_event = 'breakdown'" >
        <div class="events-block-item-img">
          <img src="@/assets/images/tools.svg" alt="">
        </div>
        <div class="events-block-item-description">
          <div class="events-block-item-description-text">
            Поломка
          </div>
          <div class="events-block-item-description-count">
            {{ events_counter_list?.breakdown?.total }}
          </div>
        </div>
        <img src="@/assets/images/vehicles/route.svg" alt="" class="events-block-item-route">
      </div>
      <div class="events-block-delimitter"></div>
      <div class="events-block-item" @click="open_event = 'failure'">
        <div class="events-block-item-img">
          <img src="@/assets/images/container_lock.svg" alt="">
        </div>
        <div class="events-block-item-description">
          <div class="events-block-item-description-text">
            Невывозы
          </div>
          <div class="events-block-item-description-count">
            {{ events_counter_list?.failure?.total }}
          </div>
        </div>
        <img src="@/assets/images/vehicles/route.svg" alt="" class="events-block-item-route">
      </div>
    </div>
    <div class="events-block">
      <div class="events-block-title">
        КГО
      </div>
      <div class="events-block-item" @click="open_event ='kgo'">
        <div class="events-block-item-img">
          <img src="@/assets/images/sofa_success.svg" alt="">
        </div>
        <div class="events-block-item-description">
          <div class="events-block-item-description-text">
            Забрали
          </div>
          <div class="events-block-item-description-count">
            {{ events_counter_list?.kgo?.total }}
          </div>
        </div>
        <img src="@/assets/images/vehicles/route.svg" alt="" class="events-block-item-route">
      </div>
      <div class="events-block-delimitter"></div>
      <div class="events-block-item" @click="open_event = 'kgo_remaining'">
        <div class="events-block-item-img">
          <img src="@/assets/images/sofa_up.svg" alt="">
        </div>
        <div class="events-block-item-description">
          <div class="events-block-item-description-text">
            К вывозу
          </div>
          <div class="events-block-item-description-count">
            {{ events_counter_list?.kgo_remaining?.total }}
          </div>
        </div>
        <img src="@/assets/images/vehicles/route.svg" alt="" class="events-block-item-route">
      </div>
    </div>
  </div>
  <openEvent :key_event="open_event" @close="open_event = null"  v-if="open_event" />
</template>
<script>
// open_event имеет статусы --> breakdown - Поломка; failure: Невывозы; kgo - кго забрали; kgo_remaining - кго к вывозу;
import openEvent from "@/pages/work_app/home/events.vue"

export default {
  components: {
    openEvent
  },
  props: {
    events_counter_list: {
      default: null,
    },
    summary: {
      default: null,
    }
  },
  data() {
    return {
      open_event: null,
    }
  },
};
</script>

<style lang="scss" scoped>
.screen-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
}

.screen {
  padding: 10px 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &--green {
    background: #dbf9ea;
    border-radius: 10px 0px 0px 10px;
  }
  &--orange {
    background: #fcf3de;
  }
  &--blue {
    background: #daedf9;
    border-radius: 0px 10px 10px 0px;
  }

  & .strong {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
}

.h4 {
  margin-bottom: 15px;
}

.platforms-statistic, .containers-statistic  {
  margin-bottom: 22px;
}

.count-all {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.platforms-title {
  display: flex;
  justify-content: space-between;
}

.volume-statistic {
  margin-bottom: 25px;
}

.events {
  margin-bottom: 30px;
  display: grid;
  row-gap: 25px;
}

.events-block {
  padding-bottom: 25px;
  display: grid;
  grid-row-gap: 25px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 13.0715px;

  &-title {
    margin-left: 22px;
    margin-top: 17px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
  }

  &-delimitter {
    height: 2px;
    background-color: #C3DFF9;
  }

  &-item {
    display: flex;
    align-items: center;

    &-count {
      margin-top: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
    }

    &-text {
      margin-bottom: 17px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
    }

    &-img {
      margin-left: 30px;
      margin-right: 20px;
      width: 60px;

      & img {
        width: 100%;
      }
    }

    &-route {
      margin-left: auto;
      margin-right: 31px;
      width: 13px;
    }
  }
}

.events-block-item-description {
  &-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  }

  &-count {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
}
</style>