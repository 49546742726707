<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 2500 1664" xml:space="preserve">
  <g>
    <g>
      <g>
        <g>
          <path id="XMLID_15_" class="st2" d="M2003.5,1092.5H583c-12.2,0-22.1-9.9-22.1-22.1v-15.9c0-12.2,9.9-22.1,22.1-22.1h1420.5
            c12.2,0,22.1,9.9,22.1,22.1v15.9C2025.6,1082.6,2015.7,1092.5,2003.5,1092.5z"/>
          <g>
            <path class="st3" d="M1622.4,1081.2c-1-0.5-1.4-1.4-1.3-2.2c-1,0.7-1.2,2.6,0.4,3.1c0.9,0.3,1.6-0.1,2-0.7
              C1623.1,1081.4,1622.8,1081.4,1622.4,1081.2z"/>
            <path class="st3" d="M1620.5,1089.5c-1.9-0.6-3,1.9-1.8,3h2.3C1621.9,1091.7,1622,1090,1620.5,1089.5z"/>
            <path class="st3" d="M1627.9,1085.2c-0.1-0.1-0.3-0.2-0.5-0.3c-2.2-0.7-3.3,2.6-1.1,3.4c1.2,0.4,2.1-0.5,2.3-1.5
              C1628,1086.4,1627.8,1085.8,1627.9,1085.2z"/>
            <path class="st3" d="M1632.7,1080.1c-2.2-0.7-3.3,2.6-1.1,3.4C1633.7,1084.1,1634.9,1080.8,1632.7,1080.1z"/>
            <path class="st3" d="M1616.1,1082.8c-2.2-0.7-3.3,2.6-1.1,3.4C1617.1,1086.9,1618.3,1083.6,1616.1,1082.8z"/>
            <path class="st3" d="M1635.7,1089.8c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.2,0.1-0.4,0.3-0.6c0,0,0,0-0.1,0c-0.9,0-1.9,0.7-1.9,1.7
              c0,0.9,0.7,1.8,1.7,1.9C1635.8,1090.9,1635.6,1090.4,1635.7,1089.8z"/>
            <path class="st3" d="M1612.2,1092.5h1.4c-0.2-0.1-0.4-0.2-0.6-0.2C1612.7,1092.3,1612.4,1092.4,1612.2,1092.5z"/>
            <path class="st3" d="M1630.5,1072.2c1.8,1,0.9,3.4-0.8,3.6c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.6-0.6,0.9-0.8
              c0.7-0.6,0.9-1.8,0.2-2.5c-0.6-0.7-1.8-0.9-2.5-0.2c0,0-0.1,0.1-0.1,0.1C1629.7,1071.9,1630.1,1072,1630.5,1072.2z"/>
            <path class="st3" d="M1639.7,1080.3c-2.2-0.7-3.3,2.6-1.1,3.4C1640.7,1084.4,1641.8,1081.1,1639.7,1080.3z"/>
            <path class="st3" d="M1613.6,1073.8c-2.2-0.7-3.3,2.6-1.1,3.4C1614.7,1077.9,1615.8,1074.5,1613.6,1073.8z"/>
            <path class="st3" d="M1605.5,1090.5c-1.4-0.5-2.4,0.8-2.3,2h3.4C1606.8,1091.7,1606.5,1090.8,1605.5,1090.5z"/>
            <path class="st3" d="M1603.5,1080.1c-1-0.4-1.8,0.3-2.2,1.1c0.2,0,0.3,0.1,0.5,0.2c0.8,0.3,1.4,1.3,1.4,2.2
              C1604.8,1083.2,1605.3,1080.7,1603.5,1080.1z"/>
            <path class="st3" d="M1652.2,1075.8c-0.1,0-0.2,0.1-0.4,0.1c-0.7,0-1.4,0.1-2.2,0.1c-0.5,0-0.9-0.1-1.2-0.3
              c-0.1,0.7,0.1,1.5,0.9,1.9C1650.9,1078.5,1652.2,1077.1,1652.2,1075.8z"/>
            <path class="st3" d="M1647,1085.4c-2.2-1.2-4,2.2-1.8,3.4C1647.3,1089.9,1649.1,1086.5,1647,1085.4z"/>
            <path class="st3" d="M1655.2,1081.8c-2.2-1.2-4,2.2-1.8,3.4C1655.5,1086.3,1657.3,1083,1655.2,1081.8z"/>
            <path class="st3" d="M1659.6,1091.3c-1.2-0.7-2.4,0.1-2.7,1.2h3.6C1660.4,1092,1660.1,1091.6,1659.6,1091.3z"/>
            <path class="st3" d="M1661.7,1077.5c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.1c-0.5,0.4-1.2,0.5-1.7,0.2
              c-0.9,0.8-1.1,2.4,0.2,3.2C1662,1082.1,1663.9,1078.7,1661.7,1077.5z"/>
            <path class="st3" d="M1642.6,1078.1c0.9-0.1,1.5,0.3,1.8,1c0-0.6-0.2-1.3-1-1.7c-2.1-1.1-3.8,1.9-2.1,3.2
              C1640.9,1079.7,1641.3,1078.3,1642.6,1078.1z"/>
            <path class="st3" d="M1665.5,1086.8c-0.5-0.1-0.9-0.2-1.4-0.3c-1-0.2-2.1,0.4-2.3,1.4c-0.2,1,0.4,2.1,1.4,2.3
              c0.5,0.1,0.9,0.2,1.4,0.3c1,0.2,2.1-0.4,2.3-1.4C1667.1,1088.1,1666.5,1087,1665.5,1086.8z"/>
            <path class="st3" d="M1652.6,1091.3c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3-1-1.4-1.6-2.4-1.3c-1,0.3-1.6,1.4-1.3,2.4
              c0.1,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1h3.6C1652.7,1092.1,1652.8,1091.7,1652.6,1091.3z"/>
            <path class="st3" d="M1638.4,1087c-0.8-0.1-1.6,0.2-2,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.5-0.2,0.9-0.3,1.4
              c-0.1,0.5,0,1.1,0.3,1.5c0.3,0.4,0.6,0.7,1.1,0.8c1,0.2,2.1-0.4,2.3-1.4c0.1-0.5,0.2-0.9,0.3-1.4
              C1640,1088.3,1639.4,1087.2,1638.4,1087z"/>
            <path class="st3" d="M1659.7,1068.2c-0.4,0.2-0.7,0.5-1.1,0.7c-0.8,0.6-1.2,1.7-0.6,2.6c0.5,0.8,1.8,1.3,2.6,0.6
              c0.4-0.2,0.7-0.5,1.1-0.7c0.8-0.6,1.2-1.7,0.6-2.6C1661.8,1068,1660.6,1067.5,1659.7,1068.2z"/>
            <path class="st3" d="M1669.1,1079.1c-2.2-1.2-4,2.2-1.8,3.4C1669.4,1083.6,1671.3,1080.3,1669.1,1079.1z"/>
            <path class="st3" d="M1640.6,1070.7c0.1,0,0.2,0.1,0.3,0.1c0.2-0.5,0.5-0.9,1-1.1c0.4-0.2,1-0.2,1.4,0c0.4-0.8,0.2-1.9-0.8-2.4
              C1640.3,1066.1,1638.5,1069.5,1640.6,1070.7z"/>
            <path class="st3" d="M1630.7,1083.7c-1.4-0.8-2.6,0.3-2.8,1.5c-0.1,0.6,0.1,1.2,0.6,1.6c0.1,0.1,0.2,0.2,0.3,0.2
              C1631,1088.2,1632.9,1084.9,1630.7,1083.7z"/>
            <path class="st3" d="M1628.7,1072.7c0-0.1,0.1-0.1,0.1-0.2C1628.7,1072.6,1628.7,1072.6,1628.7,1072.7z"/>
            <path class="st3" d="M1628.9,1072.2c-0.1,0.1-0.1,0.1-0.1,0.2C1628.9,1072.3,1628.9,1072.2,1628.9,1072.2z"/>
            <path class="st3" d="M1628.9,1072.2C1628.9,1072.2,1628.9,1072.2,1628.9,1072.2c-0.1,0.1-0.1,0.2-0.1,0.3
              c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.4c-0.1,0.6-0.4,1-0.9,1.3c0.1,0.4,0.4,0.8,0.9,1c0.4,0.2,0.7,0.3,1.1,0.2
              c1.6-0.2,2.6-2.6,0.8-3.6c-0.4-0.2-0.8-0.3-1.1-0.2c-0.1,0-0.2,0-0.2,0.1C1629,1072.1,1629,1072.1,1628.9,1072.2z"/>
            <path class="st3" d="M1650.8,1059.9c-0.7-0.4-1-1.3-0.8-2.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
              c0.4,0.6,0.4,1.4,0.1,2.1c-0.3,0.7-1,1.1-1.7,1.2c0.1,0.1,0.2,0.2,0.3,0.2C1649.3,1061.8,1650.5,1061,1650.8,1059.9z"/>
            <path class="st3" d="M1688.3,1061.9c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.9,0.3-2.6-1.3-2.6c-1.9,0-2.1,2.6-0.6,3.2
              C1687.3,1061.9,1687.8,1061.8,1688.3,1061.9z"/>
            <path class="st3" d="M1688.6,1072.3c0.8,0,1.3-0.5,1.5-1.1c-0.5,0.4-1.2,0.6-2,0.2c-0.6-0.3-0.9-0.7-1-1.2
              C1686.9,1071.2,1687.4,1072.3,1688.6,1072.3z"/>
            <path class="st3" d="M1693.3,1065.5c-0.1,0.3-0.4,0.5-0.6,0.7c0.2,0.1,0.4,0.2,0.7,0.2c2.1,0,2.2-3.2,0.1-3.3
              c-0.2,0-0.4,0-0.6,0.1C1693.5,1063.8,1693.7,1064.6,1693.3,1065.5z"/>
            <path class="st3" d="M1700,1071.5c0-0.1,0-0.1,0-0.2C1700,1071.3,1700,1071.4,1700,1071.5z"/>
            <path class="st3" d="M1700.6,1068.6c-0.1,0-0.3,0-0.4,0c0.4,0.6,0.5,1.4,0.2,2c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
              c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.1C1702.6,1071.9,1702.7,1068.6,1700.6,1068.6z"/>
            <path class="st3" d="M1700.1,1071c0,0.1-0.1,0.1-0.1,0.2C1700.1,1071.1,1700.1,1071,1700.1,1071z"/>
            <path class="st3" d="M1696.6,1059.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.2-0.7,0-1.4,0.4-1.9c0,0-0.1,0-0.1,0c-2.1,0-2.2,3.2-0.1,3.3
              c0.1,0,0.3,0,0.4,0C1696.9,1060.3,1696.7,1060,1696.6,1059.7z"/>
            <path class="st3" d="M1681.3,1065.8c-0.1,0.9,0.4,1.9,1.5,1.9c2.1,0,2.2-3.2,0.1-3.3c-0.4,0-0.7,0.1-0.9,0.2
              C1681.9,1065.1,1681.6,1065.5,1681.3,1065.8z"/>
            <path class="st3" d="M1702.1,1063.1c-0.8,0.2-1.4,1.1-1.2,2c0.3,0.8,1.1,1.4,2,1.2c0.4-0.1,0.8-0.2,1.2-0.3
              c0.8-0.2,1.4-1.1,1.2-2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0-0.1,0.1-0.2,0.1C1703.7,1064.1,1702.7,1063.8,1702.1,1063.1z"/>
            <path class="st3" d="M1695,1071c-0.6-0.6-1.7-0.7-2.3,0c-0.6,0.6-0.7,1.7,0,2.3c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.6,1.7,0.7,2.3,0
              c0.6-0.6,0.7-1.7,0-2.3C1695.2,1071.2,1695.1,1071.1,1695,1071z"/>
            <path class="st3" d="M1684.8,1074.9c-0.2-0.8-1.1-1.4-2-1.2c-0.8,0.3-1.4,1.1-1.2,2c0.1,0.4,0.2,0.8,0.3,1.2
              c0.2,0.8,1.1,1.4,2,1.2c0.8-0.3,1.4-1.1,1.2-2C1685,1075.6,1684.9,1075.2,1684.8,1074.9z"/>
            <path class="st3" d="M1693.8,1050.4c-0.7-0.4-1.8-0.3-2.3,0.5c1-0.1,1.8,0.6,2.2,1.5c0.2,0,0.4,0.1,0.6,0.2
              C1694.8,1051.9,1694.6,1050.8,1693.8,1050.4z"/>
            <path class="st3" d="M1691,1077.5c-0.7-0.1-1.3-0.1-2-0.2c-0.9-0.1-1.7,0.8-1.7,1.6c0,0.9,0.7,1.6,1.6,1.7
              c0.7,0.1,1.3,0.1,2,0.2c0.9,0.1,1.7-0.8,1.7-1.6C1692.6,1078.2,1691.9,1077.6,1691,1077.5z"/>
            <path class="st3" d="M1699.9,1081.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.7-1.6-1.7
              c-1,0-1.6,0.7-1.7,1.6c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.4,1.1,2.2,0.6c0.7-0.4,1.2-1.5,0.6-2.2
              C1700,1081.4,1700,1081.3,1699.9,1081.2z M1699.8,1080.7c0,0,0,0.1,0,0.2C1699.8,1080.9,1699.8,1080.8,1699.8,1080.7z
              M1699.9,1081.2c0-0.1-0.1-0.1-0.1-0.2C1699.9,1081.1,1699.9,1081.1,1699.9,1081.2z"/>
            <path class="st3" d="M1704,1075.5c-2.1,0-2.2,3.2-0.1,3.3C1706,1078.8,1706.1,1075.5,1704,1075.5z"/>
            <path class="st3" d="M1690.9,1082.3c-2.1,0-2.2,3.2-0.1,3.3C1693,1085.6,1693.1,1082.3,1690.9,1082.3z"/>
            <path class="st3" d="M1682.2,1081.5c-2.1,0-2.2,3.2-0.1,3.3C1684.3,1084.8,1684.4,1081.5,1682.2,1081.5z"/>
            <path class="st3" d="M1702,1058.5c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0,2.2-3.2,0.1-3.3c-1.5,0-2,1.7-1.3,2.7
              C1701.8,1058.3,1701.9,1058.4,1702,1058.5z"/>
            <path class="st3" d="M1678.1,1060.4c2.1,0,2.2-3.2,0.1-3.3C1676,1057.1,1676,1060.4,1678.1,1060.4z"/>
            <path class="st3" d="M1675.7,1074.2c-2.1,0-2.2,3.2-0.1,3.3C1677.7,1077.5,1677.8,1074.2,1675.7,1074.2z"/>
            <path class="st3" d="M1711.1,1069.6c-2.1,0-2.2,3.2-0.1,3.3C1713.2,1072.9,1713.2,1069.6,1711.1,1069.6z"/>
            <path class="st3" d="M1671,1065.5c-0.5,0-0.9,0.2-1.2,0.5c0.1,0.6,0,1.3-0.3,1.8c0.2,0.5,0.7,1,1.4,1
              C1673,1068.8,1673.1,1065.6,1671,1065.5z"/>
            <path class="st3" d="M1680,1046.9c-2.1,0-2.2,3.2-0.1,3.3C1682.1,1050.2,1682.1,1047,1680,1046.9z"/>
            <path class="st3" d="M1706.9,1034.9c0,0,0.1-0.1,0.1-0.1c0.9-0.6,2.1-0.2,2.7,0.6c0.1,0.1,0.1,0.2,0.1,0.3
              c1.5-0.7,1.6-2.2,0.9-3.3h-4.1C1706.2,1033.2,1706.4,1034.2,1706.9,1034.9z"/>
            <path class="st3" d="M1716.8,1045.7c1.1,0.6,1.2,1.7,0.8,2.5c0.2,0,0.4-0.1,0.6-0.2c2.8-1.4,0.6-5.7-2.2-4.3
              c-1,0.5-1.3,1.3-1.3,2.2C1715.3,1045.5,1716,1045.3,1716.8,1045.7z"/>
            <path class="st3" d="M1718.3,1032.7c-2.8,1.4-0.6,5.7,2.2,4.3C1723.2,1035.6,1721,1031.3,1718.3,1032.7z"/>
            <path class="st3" d="M1731.3,1035c-2.8,1.4-0.6,5.7,2.2,4.3C1736.2,1037.9,1734,1033.6,1731.3,1035z"/>
            <path class="st3" d="M1706.4,1046.2c0-1.2,1.1-2.4,2.4-2.1c0.2-1.7-1.5-3.5-3.4-2.5C1703,1042.8,1704.2,1046.2,1706.4,1046.2z"
              />
            <path class="st3" d="M1724.1,1046.5c0.2,0.1,0.4,0.1,0.7,0.2c1.2,0.4,2.7-0.3,3.1-1.5c0.4-1.3-0.2-2.6-1.5-3.1
              c-0.2-0.1-0.4-0.1-0.7-0.2c-1.2-0.4-2.7,0.3-3.1,1.5C1722.2,1044.7,1722.8,1046.1,1724.1,1046.5z"/>
            <path class="st3" d="M1716.2,1055.3c-0.4-0.4-0.8-0.9-1.2-1.3c-0.7-0.8-1.9-1-2.9-0.6c0.4,0.1,0.8,0.2,1.1,0.2
              c1.1,0.2,1.7,1.3,1.5,2.3c-0.2,1-1.3,1.7-2.3,1.5c-0.3-0.1-0.7-0.1-1-0.2c0.4,0.4,0.8,0.8,1.1,1.3c0.9,0.9,2.5,1.1,3.4,0.2
              C1716.9,1057.8,1717.1,1056.3,1716.2,1055.3z"/>
            <path class="st3" d="M1728.1,1054.2c-0.7-1.2-2-1.6-3.2-1.1c-0.9,0.4-1.8,0.7-2.8,1.1c-1.2,0.5-1.7,2.2-1.1,3.2
              c0.7,1.2,2,1.6,3.2,1.1c0.9-0.4,1.8-0.7,2.8-1.1C1728.2,1057,1728.7,1055.3,1728.1,1054.2z"/>
            <path class="st3" d="M1739.4,1052.1c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
              c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.2-2.2-1.7-3.2-1.1c-1.2,0.7-1.6,2-1.1,3.2c0.5,1.3,1.5,2.4,2.7,3c1.1,0.6,2.6,0.5,3.3-0.7
              C1740.7,1054.4,1740.6,1052.7,1739.4,1052.1z M1738.5,1051.4c0,0,0.1,0.1,0.1,0.2C1738.6,1051.6,1738.6,1051.5,1738.5,1051.4z
              M1738.8,1051.7c0.1,0.1,0.1,0.1,0.2,0.2C1738.9,1051.8,1738.8,1051.8,1738.8,1051.7z"/>
            <path class="st3" d="M1740.4,1041.7c-2.8,1.4-0.6,5.7,2.2,4.3C1745.3,1044.7,1743.2,1040.3,1740.4,1041.7z"/>
            <path class="st3" d="M1728.1,1059.5c-2.8,1.4-0.6,5.7,2.2,4.3C1733,1062.4,1730.8,1058.1,1728.1,1059.5z"/>
            <path class="st3" d="M1716.2,1064.3c-2.8,1.4-0.6,5.7,2.2,4.3C1721.1,1067.2,1718.9,1062.9,1716.2,1064.3z M1716.3,1068.5
              c-2.2-1.1-0.5-4.6,1.8-3.5C1720.3,1066.1,1718.5,1069.6,1716.3,1068.5z"/>
            <path class="st3" d="M1696,1039.8c-0.1,0-0.2-0.1-0.3-0.1c-2.4-0.9-1.4-4.2,0.6-4.4c-0.6-0.3-1.3-0.3-2,0
              C1691.6,1036.7,1693.4,1040.6,1696,1039.8z"/>
            <path class="st3" d="M1704.8,1059.3c-0.6-0.4-1.4-0.4-2.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.9-0.2,1.9-1,2.5
              c0.1,0.5,0.3,0.9,0.7,1.3c0.6,0.7,1.6,1,2.7,0.4c0.1,0,0.1-0.1,0.2-0.1c0.6-0.4,0.9-0.9,1-1.4c-0.1,0-0.3-0.1-0.4-0.2
              C1704.5,1061.3,1704.3,1060.1,1704.8,1059.3z"/>
            <path class="st3" d="M1747.8,1033.5c0.6-0.3,0.9-0.7,1.1-1.1h-4.3C1745.2,1033.4,1746.5,1034.2,1747.8,1033.5z"/>
            <path class="st3" d="M1693.8,1052.4c-0.4-0.9-1.2-1.5-2.2-1.5c-0.3,0-0.6,0.1-1,0.3c-2.6,1.3-0.9,5.1,1.5,4.5
              C1690.9,1054.5,1692.1,1052.1,1693.8,1052.4z"/>
            <path class="st3" d="M1646.2,1055.8c0.5-1.1-0.2-2.8-1.8-2.5c-2,0.3-1.8,3-0.3,3.5C1644.5,1056,1645.4,1055.7,1646.2,1055.8z"
              />
            <path class="st3" d="M1647.3,1063.8c-2.3,0.3-1.8,3.8,0.5,3.5C1650.1,1067,1649.6,1063.4,1647.3,1063.8z"/>
            <path class="st3" d="M1650.8,1059.9c0.3,0.2,0.7,0.3,1.1,0.2c2.3-0.3,1.8-3.8-0.5-3.5c-0.8,0.1-1.2,0.6-1.4,1.2
              C1649.8,1058.6,1650.1,1059.5,1650.8,1059.9z"/>
            <path class="st3" d="M1660.4,1064.7c2.3-0.3,1.8-3.8-0.5-3.5c-0.6,0.1-1,0.4-1.2,0.8c0,0.1-0.1,0.2-0.2,0.3
              C1658.2,1063.4,1658.9,1065,1660.4,1064.7z"/>
            <path class="st3" d="M1652.9,1052.8c0.3,0.4,0.8,0.6,1.5,0.5c1.7-0.2,1.8-2.3,0.9-3.1
              C1655.5,1051.6,1654.3,1052.9,1652.9,1052.8z"/>
            <path class="st3" d="M1640.7,1063.3c0.1,0,0.1,0,0.2,0c2.3-0.3,1.8-3.8-0.5-3.5c-0.1,0-0.3,0.1-0.4,0.1
              c0.2,0.1,0.4,0.2,0.5,0.3C1641.6,1061.2,1641.4,1062.5,1640.7,1063.3z"/>
            <path class="st3" d="M1660.6,1055.2c-0.8,0.4-1.3,1.5-0.9,2.3c0.4,0.9,1.4,1.3,2.3,0.9c0.4-0.2,0.8-0.4,1.2-0.5
              c0.8-0.4,1.3-1.5,0.9-2.3c-0.2-0.4-0.5-0.7-0.8-0.8c-0.5,0.5-1.2,0.8-1.9,0.7C1661.1,1055.4,1660.8,1055.3,1660.6,1055.2z"/>
            <path class="st3" d="M1652.3,1067.6c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.6,1.9,0.4,2.5-0.4c0.5-0.8,0.4-1.9-0.4-2.5
              c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.6-1.9-0.4-2.5,0.4C1651.4,1065.9,1651.5,1067,1652.3,1067.6z"/>
            <path class="st3" d="M1641.9,1069.7c-0.5,0.2-0.8,0.6-1,1.1c-0.1,0.4-0.1,0.8,0,1.3c0.2,0.4,0.4,0.8,0.5,1.2
              c0.4,0.8,1.5,1.3,2.3,0.9c0.9-0.4,1.3-1.4,0.9-2.3c-0.2-0.4-0.4-0.8-0.5-1.2c-0.2-0.4-0.5-0.7-0.9-0.9
              C1642.8,1069.5,1642.3,1069.5,1641.9,1069.7z"/>
            <path class="st3" d="M1647.3,1047.1c0.2,0.2,0.4,0.3,0.6,0.4c0.9,0.3,2,0,2.3-1c0.1-0.4,0.3-0.8,0.4-1.1c0.3-0.9,0-2-1-2.3
              c-0.9-0.3-2,0-2.3,1c-0.1,0.4-0.3,0.8-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3C1647.2,1046,1647.4,1046.5,1647.3,1047.1z"/>
            <path class="st3" d="M1651.8,1075.9c0.1,0,0.2,0,0.4-0.1c0.8-0.3,1.3-1.2,1.1-1.9c-0.2-1-1-1.6-2-1.5c-0.1,1.6-2.1,3-3.7,1.7
              c0,0.1,0,0.3,0,0.4c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.2,0.8,0.3,1.2,0.3C1650.4,1076,1651.1,1075.9,1651.8,1075.9z"/>
            <path class="st3" d="M1661.4,1077.5C1661.4,1077.5,1661.4,1077.4,1661.4,1077.5c0.7-0.7,0.9-1.8,0.3-2.5c0,0-0.2-0.1-0.2-0.2
              c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1.1-1.7-2-1.5c-1,0.2-1.5,1-1.5,2c0,1,0.5,2,1.2,2.7
              c0.2,0.2,0.5,0.4,0.8,0.5C1660.2,1078,1660.8,1078,1661.4,1077.5z M1661.4,1074.8c0-0.1-0.1-0.1-0.1-0.2
              C1661.3,1074.7,1661.4,1074.7,1661.4,1074.8z M1661.3,1074.5c0,0,0-0.1,0-0.2C1661.2,1074.4,1661.2,1074.4,1661.3,1074.5z"/>
            <path class="st3" d="M1665.3,1071.5c2.3-0.3,1.8-3.8-0.5-3.5C1662.5,1068.4,1663,1071.9,1665.3,1071.5z"/>
            <path class="st3" d="M1652.1,1077.5c-2.3,0.3-1.8,3.8,0.5,3.5C1654.8,1080.7,1654.3,1077.2,1652.1,1077.5z"/>
            <path class="st3" d="M1644.4,1079.1c-0.3-0.6-0.9-1.1-1.8-1c-1.3,0.2-1.7,1.5-1.3,2.5c0.3,0.7,0.9,1.2,1.8,1
              C1644.5,1081.5,1644.9,1080.1,1644.4,1079.1z"/>
            <path class="st3" d="M1659.8,1050.3c0.4-0.7,1.3-1.2,2.2-1.1c0,0,0.1,0,0.1,0c0.3-1.1-0.4-2.5-1.9-2.3
              C1658.4,1047.2,1658.4,1049.7,1659.8,1050.3z"/>
            <path class="st3" d="M1634.6,1056.4c2.3-0.3,1.8-3.8-0.5-3.5C1631.8,1053.2,1632.3,1056.7,1634.6,1056.4z"/>
            <path class="st3" d="M1634.9,1075c0.5-0.1,0.9-0.3,1.1-0.6c-0.4-0.1-0.7-0.2-1.1-0.5c-0.8-0.7-0.9-1.6-0.6-2.3
              C1632.1,1071.9,1632.6,1075.3,1634.9,1075z"/>
            <path class="st3" d="M1672.3,1060.6c0,0.2-0.1,0.4-0.1,0.5c-0.3,0.9-1.3,1.5-2.3,1.4c0.2,0.8,0.8,1.5,1.9,1.4
              C1673.7,1063.7,1673.7,1061.3,1672.3,1060.6z"/>
            <path class="st3" d="M1628.4,1066.6c2.3-0.3,1.8-3.8-0.5-3.5C1625.6,1063.4,1626.1,1066.9,1628.4,1066.6z"/>
            <path class="st3" d="M1633.1,1042.5c-0.7,1.1,0,3,1.7,2.8c1.8-0.3,1.8-2.5,0.7-3.2C1634.9,1042.6,1634,1042.9,1633.1,1042.5z"
              />
            <path class="st3" d="M1696.7,1044.7c2.2,1.1,4-2.3,1.8-3.5C1696.2,1040.1,1694.4,1043.6,1696.7,1044.7z"/>
            <path class="st3" d="M1692.1,1055.7c0.1,0.1,0.3,0.3,0.5,0.4c2.2,1.1,4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.2
              C1692.1,1052.1,1690.9,1054.5,1692.1,1055.7z"/>
            <path class="st3" d="M1700.9,1052.3c2.2,1.1,4-2.3,1.8-3.5C1700.4,1047.6,1698.6,1051.1,1700.9,1052.3z"/>
            <path class="st3" d="M1707.4,1058.4c-1.1-0.6-2.1,0-2.6,0.9c-0.4,0.9-0.3,2,0.8,2.6c0.1,0.1,0.3,0.1,0.4,0.2
              C1708,1062.5,1709.4,1059.4,1707.4,1058.4z"/>
            <path class="st3" d="M1706.4,1046.2c0,0.6,0.3,1.2,1,1.6c2.2,1.1,4-2.3,1.8-3.5c-0.1-0.1-0.3-0.1-0.4-0.2
              C1707.5,1043.8,1706.4,1045,1706.4,1046.2z"/>
            <path class="st3" d="M1688.9,1048.1c0.5,0.2,0.9,0.3,1.3,0.2c-0.2-0.4-0.3-0.9-0.3-1.3c0.1-0.8,0.6-1.5,1.4-1.8
              c-0.2-0.2-0.4-0.3-0.6-0.5C1688.4,1043.5,1686.6,1047,1688.9,1048.1z"/>
            <path class="st3" d="M1714.8,1055.9c0.2-1-0.4-2.1-1.5-2.3c-0.4-0.1-0.8-0.2-1.1-0.2c-0.1,0-0.2,0-0.3-0.1
              c-1-0.2-2.1,0.5-2.3,1.5c-0.2,1,0.4,2.1,1.5,2.3c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2
              C1713.4,1057.6,1714.6,1057,1714.8,1055.9z"/>
            <path class="st3" d="M1700,1057.6c-0.4-0.8-1.4-1.2-2.3-0.9c-0.3,0.1-0.6,0.3-0.8,0.6c-0.5,0.5-0.7,1.2-0.4,1.9
              c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.5,1.1,0.6C1697.5,1059.7,1698.4,1057.8,1700,1057.6z"/>
            <path class="st3" d="M1684.6,1059.7c1,0.2,2.1-0.4,2.3-1.5c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.4,0-0.7-0.1-1.1c-0.3,0-0.6,0-1-0.2
              c-0.6-0.2-0.9-0.6-1.2-1c-0.7,0.1-1.4,0.7-1.5,1.5c-0.1,0.5-0.2,0.9-0.3,1.4C1682.9,1058.4,1683.6,1059.6,1684.6,1059.7z"/>
            <path class="st3" d="M1707,1034.8c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.5-1,0.7c-0.8,0.6-1.2,1.8-0.6,2.7
              c0.6,0.8,1.8,1.2,2.7,0.6c0.4-0.3,0.7-0.5,1.1-0.8c0.7-0.5,1.1-1.6,0.7-2.4c0-0.1-0.1-0.2-0.1-0.3
              C1709.1,1034.6,1707.9,1034.2,1707,1034.8z"/>
            <path class="st3" d="M1688.8,1062c1.7,0.7,1.7,2.6,0.8,3.7c0.4,0.2,0.7,0.5,1.1,0.7c0.6,0.4,1.4,0.3,2-0.1
              c0.3-0.2,0.5-0.4,0.6-0.7c0.4-0.9,0.2-1.7-0.5-2.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.4-1.4-0.8-2-1.3c-0.5-0.3-1.2-0.3-1.7,0
              c-0.2,0.1-0.3,0.2-0.5,0.3C1688.5,1061.9,1688.6,1061.9,1688.8,1062z"/>
            <path class="st3" d="M1700.1,1071.2c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.4c0.4-0.6,0.3-1.4-0.2-2
              c-0.2-0.3-0.4-0.5-0.7-0.6c-1-0.5-2-0.1-2.6,0.8c-0.6,0.9-0.8,2.1-0.7,3.2c0.2,1,0.9,2,2.1,1.8c1-0.1,2-1,1.8-2.1
              c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3C1700,1071.4,1700,1071.3,1700.1,1071.2z M1700,1071.5c0-0.1,0-0.2,0-0.2
              C1700,1071.3,1700,1071.4,1700,1071.5z M1700.1,1071.1c0,0,0-0.1,0.1-0.2C1700.1,1071,1700.1,1071.1,1700.1,1071.1z"/>
            <path class="st3" d="M1707.3,1067.6c-2.2-1.1-4,2.3-1.8,3.5C1707.8,1072.2,1709.6,1068.7,1707.3,1067.6z"/>
            <path class="st3" d="M1688.1,1071.5c0.8,0.4,1.5,0.2,2-0.2c1-0.8,1.2-2.5-0.3-3.3c-1.7-0.9-3.1,0.9-2.8,2.3
              C1687.2,1070.7,1687.5,1071.2,1688.1,1071.5z"/>
            <path class="st3" d="M1679.7,1063.4c0.9,0.3,1.6,1.5,1.4,2.5c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.6-0.7,0.7-1.1
              c0.2-0.8,0-1.6-1-2.1c-1.1-0.5-2,0-2.5,0.8C1678.9,1063.2,1679.3,1063.3,1679.7,1063.4z"/>
            <path class="st3" d="M1715,1049.2c1.1,0.6,2.2,0,2.6-1c0.4-0.9,0.3-2-0.8-2.5c-0.8-0.4-1.5-0.2-2,0.2
              C1713.8,1046.7,1713.5,1048.4,1715,1049.2z"/>
            <path class="st3" d="M1689.5,1037.8c1.1-0.7,1.5-2.6,0-3.4c-0.7-0.4-1.4-0.3-1.9,0.1c0.3,0,0.5,0,0.8,0.2
              C1689.8,1035.2,1690,1036.7,1689.5,1037.8z"/>
            <path class="st3" d="M1675.1,1053.5c0-0.1-0.1-0.2-0.1-0.3c0,0.4,0.2,0.8,0.5,1.1c-0.1-0.1-0.1-0.2-0.2-0.3
              C1675.3,1053.9,1675.2,1053.7,1675.1,1053.5z"/>
            <path class="st3" d="M1718.1,1065c-2.2-1.1-4,2.3-1.8,3.5C1718.5,1069.6,1720.3,1066.1,1718.1,1065z"/>
            <path class="st3" d="M1675.6,1043.1c2.2,1.1,4-2.3,1.8-3.5C1675.1,1038.4,1673.3,1041.9,1675.6,1043.1z"/>
            <path class="st3" d="M1673.8,1036.6c2.7,1.1,4.4-3,1.8-4.2h-1.9C1672.1,1033.2,1671.7,1035.7,1673.8,1036.6z"/>
            <path class="st3" d="M1672,1046c-2.7-1.1-4.4,3.2-1.7,4.3C1673.1,1051.4,1674.8,1047.1,1672,1046z"/>
            <path class="st3" d="M1679.5,1044.9c2.7,1.1,4.4-3.2,1.7-4.3C1678.5,1039.5,1676.8,1043.8,1679.5,1044.9z"/>
            <path class="st3" d="M1686.1,1055.5c0.4,0.1,0.7,0.2,1,0.2c2.1-0.1,3.1-3.5,0.7-4.4c-2.2-0.9-3.7,1.6-2.9,3.2
              C1685.1,1054.9,1685.5,1055.3,1686.1,1055.5z"/>
            <path class="st3" d="M1686.7,1038.9c1.3,0.5,2.3-0.2,2.8-1.1c0.5-1.1,0.4-2.6-1.1-3.1c-0.3-0.1-0.6-0.2-0.8-0.2
              C1685.3,1034.5,1684.2,1037.9,1686.7,1038.9z"/>
            <path class="st3" d="M1665.1,1041.4c2.7,1.1,4.4-3.2,1.7-4.3C1664,1036.1,1662.3,1040.3,1665.1,1041.4z"/>
            <path class="st3" d="M1689.9,1046.9c0,0.5,0.1,0.9,0.3,1.3c0.3,0.6,1,1.1,1.7,1.2c0.6,0.1,1.1,0.1,1.7,0.2
              c1.2,0.1,2.4-0.8,2.5-2c0.1-1.2-0.8-2.4-2-2.5c-0.6-0.1-1.1-0.1-1.7-0.2c-0.4,0-0.8,0-1.2,0.2
              C1690.5,1045.4,1689.9,1046.1,1689.9,1046.9z"/>
            <path class="st3" d="M1679.6,1052.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1.1-1.9-1.7-3-1.2c-1,0.5-1.6,1.6-1.3,2.8
              c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.2,0.3c0.6,0.9,1.8,1.4,2.9,0.9
              C1679.5,1054.7,1680.1,1053.4,1679.6,1052.3z"/>
            <path class="st3" d="M1659.8,1050.3c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.9,0.4,1.8,1.2,2.3
              c0.2,0.1,0.5,0.2,0.8,0.2c0.7,0,1.4-0.2,1.9-0.7c0.3-0.3,0.5-0.8,0.6-1.3c0.1-0.6,0.1-1.1,0.2-1.7c0.1-1.2-0.7-2.4-1.9-2.5
              c0,0-0.1,0-0.1,0C1661.1,1049.1,1660.3,1049.6,1659.8,1050.3z"/>
            <path class="st3" d="M1672.2,1061.2c0.1-0.2,0.1-0.4,0.1-0.5c0.1-1-0.4-1.9-1.4-2.4c-0.8-0.4-1.7-0.8-2.5-1.2
              c-1.1-0.5-2.6,0.2-3,1.3c-0.4,1.2,0.1,2.4,1.3,3c0.8,0.4,1.7,0.8,2.5,1.2c0.2,0.1,0.4,0.2,0.7,0.2
              C1670.9,1062.7,1671.9,1062.1,1672.2,1061.2z"/>
            <path class="st3" d="M1680.8,1067.8c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1
              c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.2-1-0.5-2.2-1.4-2.5c-0.4-0.1-0.8-0.2-1.2-0.1c-0.8,0.1-1.4,0.6-1.8,1.4
              c-0.6,1.1-0.7,2.6-0.4,3.8c0.3,1.2,1.3,2.2,2.6,1.9C1680,1070.2,1681.1,1069.1,1680.8,1067.8z M1680.7,1067c0,0,0-0.1,0-0.2
              C1680.7,1066.8,1680.7,1066.9,1680.7,1067z M1680.7,1067.2c0,0.1,0,0.2,0,0.2C1680.7,1067.3,1680.7,1067.2,1680.7,1067.2z"/>
            <path class="st3" d="M1689.6,1065.7c0.9-1.1,0.9-3-0.8-3.7c-0.2-0.1-0.3-0.1-0.5-0.1c-0.5-0.1-1,0-1.4,0.3
              c-1.4,0.9-1.8,3.3,0.2,4.1C1688.1,1066.7,1689,1066.3,1689.6,1065.7z"/>
            <path class="st3" d="M1669.8,1066c-0.1-0.6-0.5-1.2-1.3-1.5c-2.7-1.1-4.4,3.2-1.7,4.3c1.2,0.5,2.2-0.1,2.7-0.9
              C1669.8,1067.3,1669.9,1066.6,1669.8,1066z"/>
            <path class="st3" d="M1658.6,1062.3c0.1-0.1,0.1-0.2,0.2-0.3c0.4-1.1,0.1-2.3-1.2-2.9c-2.7-1.1-4.4,3.2-1.7,4.3
              C1657.1,1063.9,1658.1,1063.3,1658.6,1062.3z"/>
            <path class="st3" d="M1695.6,1039.7c0.1,0,0.2,0.1,0.3,0.1c2.5,0.6,4-3.3,1.4-4.3c-0.4-0.1-0.7-0.2-1.1-0.2
              C1694.2,1035.5,1693.3,1038.8,1695.6,1039.7z"/>
            <path class="st3" d="M1650.8,1050.6c0.1-1.4,1.6-2.7,3.1-2.1c0.1-0.9-0.3-1.8-1.4-2.2
              C1649.8,1045.2,1648.1,1049.5,1650.8,1050.6z"/>
            <path class="st3" d="M1702.4,1059.4c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.7-0.4
              c-0.4-0.1-0.7-0.2-1-0.2c-1.6,0.1-2.5,2.1-1.8,3.4c0.2,0.4,0.6,0.8,1.1,1c0.8,0.3,1.5,0.2,2-0.2
              C1702.1,1061.3,1702.5,1060.3,1702.4,1059.4z"/>
            <path class="st3" d="M1649.6,1035.5c0.2,0.6,0.1,1.2-0.1,1.7c2.4,0.4,3.7-3.4,1.1-4.4c-1.3-0.5-2.4,0.2-2.8,1.2
              C1648.6,1034.1,1649.3,1034.7,1649.6,1035.5z"/>
            <path class="st3" d="M1587.1,1075.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-1.2,0.1-2.1,1.7-1,2.7
              c0.7,0.7,1.6,0.5,2.2-0.1C1587.1,1076.6,1587,1076,1587.1,1075.5z"/>
            <path class="st3" d="M1581.7,1082.8c-1.5-1.4-3.6,1-2.1,2.3C1581.1,1086.5,1583.2,1084.2,1581.7,1082.8z"/>
            <path class="st3" d="M1588.8,1081.4c-1.4-0.9-3.2,1-2.1,2.3C1586.8,1082.6,1587.6,1081.5,1588.8,1081.4z"/>
            <path class="st3" d="M1590.6,1090.1c-1.5-1.4-3.6,1-2.1,2.3c0,0,0.1,0,0.1,0.1h1.9C1591.2,1092,1591.5,1090.9,1590.6,1090.1z"
              />
            <path class="st3" d="M1592.9,1081.8c1.5,1.4,3.6-1,2.1-2.3c-0.4-0.4-0.9-0.5-1.3-0.4c-0.3,0.3-0.6,0.5-0.9,0.6
              C1592.3,1080.3,1592.2,1081.2,1592.9,1081.8z"/>
            <path class="st3" d="M1580.7,1077.8c0.4-0.6,0.5-1.4-0.2-2.1c-0.1-0.1-0.3-0.2-0.4-0.3
              C1580.7,1076.1,1580.9,1077,1580.7,1077.8z"/>
            <path class="st3" d="M1596.2,1087.6c-0.4-0.2-0.7-0.4-1.1-0.5c-0.7-0.4-1.8-0.1-2.1,0.7c-0.4,0.8-0.1,1.7,0.7,2.1
              c0.4,0.2,0.7,0.4,1.1,0.5c0.7,0.4,1.8,0.1,2.1-0.7C1597.3,1089,1597,1088,1596.2,1087.6z"/>
            <path class="st3" d="M1583.4,1086.7c-0.9,0.1-1.5,0.8-1.5,1.7c0,0.2,0,0.3,0,0.5c0,0.8,0.8,1.6,1.7,1.5
              c0.9-0.1,1.5-0.8,1.5-1.7c0-0.2,0-0.3,0-0.5C1585,1087.4,1584.3,1086.7,1583.4,1086.7z"/>
            <path class="st3" d="M1574.5,1082.4c-0.8-0.4-1.7-0.1-2.1,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.4,0.7-0.1,1.8,0.7,2.1
              c0.8,0.4,1.7,0.1,2.1-0.7c0.2-0.4,0.4-0.7,0.5-1.1C1575.6,1083.8,1575.3,1082.8,1574.5,1082.4z"/>
            <path class="st3" d="M1595.4,1071.6c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.1-1,2c0.3,0.8,1.2,1.4,2,1c0.3-0.1,0.7-0.3,1-0.4
              c0.8-0.3,1.3-1.1,1-2C1597.1,1071.9,1596.2,1071.3,1595.4,1071.6z"/>
            <path class="st3" d="M1578,1090.4c-0.4-0.5-0.9-0.9-1.3-1.4c-0.6-0.6-1.7-0.5-2.2,0.1c-0.6,0.7-0.5,1.6,0.1,2.2
              c0.4,0.4,0.7,0.8,1.1,1.1h2.6C1578.7,1091.8,1578.6,1091,1578,1090.4z"/>
            <path class="st3" d="M1567,1090.1c1.5,1.4,3.6-1,2.1-2.3C1567.6,1086.4,1565.5,1088.7,1567,1090.1z"/>
            <path class="st3" d="M1599,1082c-0.6,0.3-1.1,1.1-0.9,1.8c0.1-0.5,0.3-1,0.6-1.4C1598.7,1082.3,1598.9,1082.1,1599,1082z"/>
            <path class="st3" d="M1581.3,1069c0.1-0.5,0.2-1.1,0.3-1.6c-1.2-0.9-2.8,0.5-2.4,1.8c0.4,0.2,0.7,0.6,0.9,1
              c0.5,0.2,0.9,0.1,1.3-0.1C1581.3,1069.7,1581.2,1069.3,1581.3,1069z"/>
            <path class="st3" d="M1566.9,1080.6c1.4,1.3,3.4-0.7,2.3-2.1c-0.3,0.5-0.8,0.9-1.5,1c-0.5,0-0.9-0.1-1.3-0.3
              C1566.3,1079.6,1566.5,1080.1,1566.9,1080.6z"/>
            <path class="st3" d="M1570.3,1068.6c0,0.9-0.5,1.7-1.4,2c-0.1,0.1-0.2,0.1-0.4,0.1c0.1,0.2,0.2,0.4,0.4,0.6
              c1.5,1.4,3.6-1,2.1-2.3C1570.9,1068.7,1570.6,1068.6,1570.3,1068.6z"/>
            <path class="st3" d="M1587.4,1063.7c1.5,1.4,3.6-1,2.1-2.3C1588.1,1059.9,1585.9,1062.3,1587.4,1063.7z"/>
            <path class="st3" d="M1449.2,1077.5c-3-0.2-3.3,4.4-0.3,4.7C1451.9,1082.3,1452.2,1077.7,1449.2,1077.5z"/>
            <path class="st3" d="M1450.1,1091.9c-0.8-0.1-1.3,0.2-1.7,0.6h3.2C1451.2,1092.2,1450.7,1091.9,1450.1,1091.9z"/>
            <path class="st3" d="M1457.4,1083.8c-0.2,0-0.4,0-0.6,0c0,0.9-0.9,1.8-1.8,1.8c-0.3,1.3,0.4,2.7,2,2.9
              C1460,1088.7,1460.4,1084,1457.4,1083.8z"/>
            <path class="st3" d="M1467.1,1092.1c-0.6,0-1.1,0.1-1.4,0.4h2.2c0.1,0,0.1-0.1,0.2-0.1
              C1467.7,1092.3,1467.4,1092.2,1467.1,1092.1z"/>
            <path class="st3" d="M1462.5,1077.3c0.8,0.4,1.1,1.6,0.6,2.4c-0.2,0.3-0.3,0.5-0.5,0.8c2.5-0.3,2.6-4.4-0.2-4.6
              c-1.5-0.1-2.4,1.1-2.4,2.3c0-0.1,0.1-0.1,0.1-0.2C1460.7,1077,1461.7,1076.8,1462.5,1077.3z"/>
            <path class="st3" d="M1442.2,1084.9c-3-0.2-3.3,4.4-0.3,4.7C1444.9,1089.7,1445.2,1085.1,1442.2,1084.9z"/>
            <path class="st3" d="M1473.6,1085c-0.1,0.9-0.9,1.5-1.7,1.6c-0.9,0-1.7-0.9-1.7-1.8c0-0.1,0-0.3,0-0.4c-0.2,0-0.4,0.1-0.5,0.1
              c-1.2,0.3-2.1,1.5-1.8,2.8c0.3,1.2,1.5,2.1,2.8,1.8c0.6-0.1,1.1-0.2,1.7-0.4c1.2-0.3,2.1-1.5,1.8-2.8
              C1474,1085.6,1473.8,1085.3,1473.6,1085z"/>
            <path class="st3" d="M1455.4,1071c1,0.6,2.6,0.5,3.2-0.6c0.3-0.5,0.6-0.9,0.8-1.4c0.6-1,0.5-2.6-0.6-3.2
              c-1-0.6-2.6-0.5-3.2,0.6c-0.3,0.5-0.6,0.9-0.8,1.4C1454.1,1068.8,1454.3,1070.3,1455.4,1071z"/>
            <path class="st3" d="M1470.4,1078.3c0.2,0.1,0.5,0.2,0.7,0.2c3,0.2,3.3-4.4,0.3-4.7c-2.4-0.2-3.1,2.7-1.8,4
              C1469.9,1077.9,1470.2,1078,1470.4,1078.3C1470.4,1078.2,1470.4,1078.3,1470.4,1078.3z"/>
            <path class="st3" d="M1436,1074.1c-3-0.2-3.3,4.4-0.3,4.7C1438.7,1079,1439,1074.3,1436,1074.1z"/>
            <path class="st3" d="M1425.2,1085.6c-3-0.2-3.3,4.4-0.3,4.7C1427.8,1090.4,1428.2,1085.8,1425.2,1085.6z"/>
            <path class="st3" d="M1439.5,1059.8c-3-0.2-3.3,4.4-0.3,4.7C1442.1,1064.7,1442.5,1060,1439.5,1059.8z"/>
            <path class="st3" d="M1535.9,1074.8c0.1,0,0.1,0.1,0.2,0.2c0-0.4-0.2-0.8-0.6-1.1c-1.7-1.6-4.1,0.7-3,2.4
              C1532.8,1074.9,1534.4,1073.8,1535.9,1074.8z"/>
            <path class="st3" d="M1526.9,1084.8c0.4-0.6,1-0.9,1.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-1.8-1.7-4.5,1-2.7,2.8
              c0.3,0.3,0.7,0.5,1.1,0.5C1526.4,1086.2,1526.5,1085.5,1526.9,1084.8z"/>
            <path class="st3" d="M1537.1,1082.5c0-0.2,0-0.3,0-0.5c-1.8-1.3-4.2,1.3-2.5,3c1.2,1.1,2.7,0.4,3.2-0.8
              c-0.3-0.3-0.5-0.7-0.5-1.1C1537.2,1083,1537.2,1082.7,1537.1,1082.5z"/>
            <path class="st3" d="M1536.8,1092.5h1.8C1538,1092.1,1537.4,1092.2,1536.8,1092.5z"/>
            <path class="st3" d="M1542.2,1082.7c1.8,1.7,4.5-1,2.7-2.8C1543.1,1078.2,1540.4,1081,1542.2,1082.7z"/>
            <path class="st3" d="M1527.1,1074.8c-1.2-1.1-2.8-0.3-3.2,1c0.6-0.3,1.3-0.4,1.9-0.2c0.8,0.3,1.3,1,1.4,1.8
              c0.2-0.2,0.3-0.5,0.4-0.8C1527.1,1076.1,1526.9,1075.4,1527.1,1074.8z"/>
            <path class="st3" d="M1546,1090c-0.4-0.2-0.9-0.5-1.3-0.7c-0.9-0.5-2.2-0.2-2.6,0.8c-0.4,0.8-0.2,1.8,0.5,2.4h4.3
              C1547.2,1091.6,1546.9,1090.5,1546,1090z"/>
            <path class="st3" d="M1532.2,1090.9c0-0.2,0-0.4,0-0.6c0-0.2-0.1-0.5-0.1-0.7c-0.7,0.5-1.9,0.6-2.5,0c-0.2-0.2-0.5-0.4-0.7-0.6
              c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0.1,0.5c0.4,0.3,0.6,0.7,0.7,1h2.2
              C1531.9,1092.1,1532.3,1091.6,1532.2,1090.9z"/>
            <path class="st3" d="M1519.5,1082.8C1519.5,1082.8,1519.5,1082.8,1519.5,1082.8c-1-0.5-2.2-0.2-2.7,0.7
              c-0.1,0.2-0.2,0.3-0.3,0.5c0.7-0.7,1.7-0.9,2.7-0.2c0.7,0.5,0.9,1.1,0.9,1.7c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.3,0.2-0.5
              C1519.7,1084.5,1519.4,1083.6,1519.5,1082.8z"/>
            <path class="st3" d="M1545.8,1071.8c0.8,0.6,1,1.4,0.9,2.1c0.9-0.4,1.6-1.4,1.3-2.4c-0.3-1-1.4-1.7-2.4-1.4
              c-0.4,0.1-0.8,0.3-1.3,0.4c-0.7,0.2-1.3,0.9-1.4,1.7C1543.5,1071.5,1544.7,1071,1545.8,1071.8z"/>
            <path class="st3" d="M1519.2,1091c-0.4,0.5-0.6,1-0.5,1.5h4.6c-0.5-0.5-0.9-1-1.4-1.5C1521.3,1090.2,1519.8,1090.3,1519.2,1091
              z"/>
            <path class="st3" d="M1509.9,1091.9c1.8,1.7,4.5-1,2.7-2.8C1510.9,1087.3,1508.1,1090.1,1509.9,1091.9z"/>
            <path class="st3" d="M1549.3,1083.3c0.1,0,0.1,0.1,0.2,0.1c1.3,0.9,1.1,2.5,0.2,3.4c1.7,0.5,3.5-1.7,1.9-3.3
              C1550.9,1082.7,1550,1082.8,1549.3,1083.3z"/>
            <path class="st3" d="M1526.2,1067.5c1.8,1.7,4.5-1,2.7-2.8C1527.1,1062.9,1524.4,1065.7,1526.2,1067.5z"/>
            <path class="st3" d="M1511.5,1076.9c0.2,0.1,0.3,0.3,0.5,0.5c0.3,0.4,0.4,1,0.3,1.5c0.3,0.4,0.5,1,0.3,1.5c0.9-0.6,1.4-2,0.4-3
              C1512.5,1077,1511.9,1076.8,1511.5,1076.9z"/>
            <path class="st3" d="M1516.3,1068c0.2-0.6,0.1-1.3-0.5-1.9c-1.8-1.7-4.5,1-2.7,2.8c0.9,0.8,1.9,0.6,2.6,0
              C1515.9,1068.6,1516.1,1068.3,1516.3,1068z"/>
            <path class="st3" d="M1537,1056.9c1.1,1.2,0.3,2.8-0.9,3.3c1.8,1.8,4.5-1,2.7-2.8C1538.2,1056.8,1537.6,1056.7,1537,1056.9z"/>
            <path class="st3" d="M1477.3,1071.7c-1.3,0.1-2.1-1.2-2-2.3c-1.1,0.4-1.8,1.8-0.8,2.8
              C1475.5,1073.3,1476.9,1072.7,1477.3,1071.7z"/>
            <path class="st3" d="M1470.4,1078.3c-0.2-0.3-0.5-0.4-0.7-0.5c-1.5-0.5-3.1,1.5-1.8,2.8
              C1469.4,1082.2,1471.9,1079.9,1470.4,1078.3z"/>
            <path class="st3" d="M1478.4,1077.4c-1.5-1.6-4,0.8-2.5,2.4C1477.4,1081.4,1479.9,1079,1478.4,1077.4z"/>
            <path class="st3" d="M1477.1,1089.2c1.5,1.6,4-0.8,2.5-2.4C1478,1085.2,1475.5,1087.6,1477.1,1089.2z"/>
            <path class="st3" d="M1485.2,1075.6c-1.5-1.6-4,0.8-2.5,2.4C1484.2,1079.5,1486.7,1077.2,1485.2,1075.6z"/>
            <path class="st3" d="M1469.5,1070.5c-1.5-1.6-4,0.8-2.5,2.4C1468.6,1074.5,1471.1,1072.1,1469.5,1070.5z"/>
            <path class="st3" d="M1486.5,1086.7c0.3-0.8,0.1-1.7-0.7-2.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.5-1.9-0.2-2.4,0.6
              c-0.4,0.8-0.2,1.9,0.6,2.4c0.4,0.2,0.7,0.4,1.1,0.6c0.5,0.3,1,0.3,1.5,0.1C1485.8,1087.2,1486.1,1086.9,1486.5,1086.7z"/>
            <path class="st3" d="M1471.8,1086.6c0.9,0,1.7-0.7,1.7-1.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0-0.9-0.8-1.8-1.7-1.8
              c-0.9,0-1.7,0.7-1.8,1.7c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4C1470.1,1085.7,1470.9,1086.6,1471.8,1086.6z"/>
            <path class="st3" d="M1462.5,1080.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,0.2-1.9-0.6-2.4
              c-0.8-0.4-1.9-0.2-2.4,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.5,0.9c-0.5,0.8-0.2,1.9,0.6,2.4
              C1460.9,1081.8,1462,1081.6,1462.5,1080.8z"/>
            <path class="st3" d="M1485.7,1070.7c0.4-0.1,0.8-0.2,1.1-0.3c0.9-0.3,1.5-1.2,1.3-2.1c-0.1-0.4-0.3-0.7-0.6-0.9
              c-0.3,0.1-0.7,0-1.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.9,0.3-1.5,1.2-1.3,2.1
              C1483.9,1070.3,1484.8,1071,1485.7,1070.7z"/>
            <path class="st3" d="M1464.5,1084.6c-0.6-0.7-1.8-0.7-2.4-0.1c-0.7,0.7-0.7,1.7-0.1,2.4c0.4,0.5,0.9,1.1,1.3,1.6
              c0.6,0.7,1.8,0.7,2.4,0.1c0.7-0.7,0.7-1.7,0.1-2.4C1465.4,1085.7,1464.9,1085.2,1464.5,1084.6z"/>
            <path class="st3" d="M1468,1092.4c-0.1,0-0.1,0.1-0.2,0.1h2.3C1469.5,1091.9,1468.7,1091.9,1468,1092.4z"/>
            <path class="st3" d="M1462.1,1089.8c-1.5-1.6-4,0.8-2.5,2.4c0.1,0.1,0.3,0.2,0.4,0.3h1.5
              C1462.5,1092,1463.1,1090.8,1462.1,1089.8z"/>
            <path class="st3" d="M1456.8,1083.8c0-0.4-0.1-0.8-0.5-1.1c-1.5-1.6-4,0.8-2.5,2.4c0.4,0.4,0.8,0.5,1.2,0.5
              C1455.9,1085.6,1456.8,1084.7,1456.8,1083.8z"/>
            <path class="st3" d="M1489.9,1078.6c0.2-0.1,0.4-0.1,0.6-0.1c-1.5-0.6-3.2,1.4-1.9,2.8c0.1,0.1,0.2,0.2,0.4,0.3
              C1488.3,1080.6,1488.4,1079.1,1489.9,1078.6z"/>
            <path class="st3" d="M1471.5,1061.5c-1.5-1.6-4,0.8-2.5,2.4C1470.5,1065.5,1473,1063.1,1471.5,1061.5z"/>
            <path class="st3" d="M1456.9,1072.3c-1.5-1.6-4,0.8-2.5,2.4C1455.9,1076.3,1458.4,1073.9,1456.9,1072.3z"/>
            <path class="st3" d="M1459.9,1062.4c-1.5-1.6-4,0.8-2.5,2.4C1458.9,1066.4,1461.4,1064,1459.9,1062.4z"/>
            <path class="st3" d="M1480.5,1055.4c-1.5-1.6-4,0.8-2.5,2.4C1479.5,1059.4,1482,1057,1480.5,1055.4z"/>
            <path class="st3" d="M1539.7,1066.3c1.7,1.2,3.5-0.4,3.5-1.9c-0.6-0.3-1-1-1-1.7C1539.9,1061.1,1537.4,1064.7,1539.7,1066.3z"
              />
            <path class="st3" d="M1536.1,1075c-0.1-0.1-0.1-0.1-0.2-0.2c-1.5-1.1-3.1,0.1-3.4,1.4c-0.2,0.8,0,1.6,0.9,2.2
              C1535.6,1080,1538.1,1076.7,1536.1,1075z"/>
            <path class="st3" d="M1545.8,1071.8c-1.1-0.8-2.3-0.4-2.9,0.5c-0.7,0.9-0.8,2.3,0.4,3.2c1.5,1.1,3.2-0.1,3.4-1.5
              C1546.8,1073.2,1546.6,1072.4,1545.8,1071.8z"/>
            <path class="st3" d="M1549.5,1083.4c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.3-4.6,2.1-2.4,3.7c1,0.7,2.1,0.4,2.8-0.3
              C1550.6,1085.8,1550.9,1084.3,1549.5,1083.4z"/>
            <path class="st3" d="M1553.9,1067.9c-2.3-1.6-4.9,2-2.6,3.6C1553.6,1073.1,1556.2,1069.5,1553.9,1067.9z"/>
            <path class="st3" d="M1533,1065.3c-2.3-1.6-4.9,2-2.6,3.6C1532.7,1070.5,1535.3,1066.9,1533,1065.3z"/>
            <path class="st3" d="M1556.9,1079c-0.5-0.2-1-0.4-1.6-0.5c-1.1-0.4-2.5,0.2-2.8,1.3c-0.4,1.1,0.2,2.4,1.3,2.8
              c0.5,0.2,1,0.4,1.6,0.5c1.1,0.4,2.5-0.2,2.8-1.3C1558.6,1080.7,1558.1,1079.4,1556.9,1079z"/>
            <path class="st3" d="M1541.5,1081.8c-0.2-1.1-1.4-2.1-2.6-1.8c-1,0.2-1.8,1.1-1.8,2.1c0,0.1,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6
              c0.1,0.4,0.3,0.8,0.5,1.1c0.5,0.6,1.3,0.9,2,0.7c1.2-0.3,2-1.3,1.8-2.6C1541.6,1082.2,1541.5,1082,1541.5,1081.8z"/>
            <path class="st3" d="M1525.8,1075.5c-0.7-0.2-1.4-0.1-1.9,0.2c-0.4,0.2-0.7,0.6-0.9,1.1c-0.2,0.5-0.4,1-0.5,1.6
              c-0.4,1.1,0.2,2.5,1.3,2.8c1.1,0.4,2.4-0.2,2.8-1.3c0.2-0.5,0.4-1,0.5-1.6c0.1-0.3,0.1-0.7,0.1-1
              C1527.1,1076.5,1526.6,1075.8,1525.8,1075.5z"/>
            <path class="st3" d="M1551.9,1060.4c-0.2,0.1-0.5,0.2-0.9,0.3c-0.2,0-0.3,0-0.4,0c0.2,0.3,0.4,0.5,0.6,0.7
              C1551.4,1061,1551.6,1060.7,1551.9,1060.4z"/>
            <path class="st3" d="M1552.2,1060.2c0.6-0.4,1.5-0.4,2.2,0.2c0.2,0.1,0.3,0.3,0.4,0.4c1-0.6,1.6-1.8,1.1-2.9
              c-0.5-1-1.8-1.7-2.9-1.1c-0.2,0.1-0.4,0.2-0.6,0.3C1553.1,1058,1553.1,1059.4,1552.2,1060.2z"/>
            <path class="st3" d="M1532.1,1089.7c0.2-0.1,0.4-0.3,0.5-0.5c0.7-1.1,0.4-2.3-0.5-3.1c-0.7-0.6-1.4-1.2-2.1-1.7
              c-0.4-0.3-0.9-0.4-1.4-0.4c-0.7,0-1.4,0.4-1.7,0.9c-0.4,0.7-0.5,1.4-0.2,2c0.1,0.4,0.4,0.8,0.8,1.1c0.5,0.4,0.9,0.8,1.4,1.2
              c0.2,0.2,0.5,0.4,0.7,0.6C1530.2,1090.2,1531.3,1090.2,1532.1,1089.7z"/>
            <path class="st3" d="M1528.4,1091.5C1528.4,1091.4,1528.4,1091.4,1528.4,1091.5c-1.5-1.1-2.9-0.2-3.4,1h4.2
              C1529.1,1092.1,1528.8,1091.8,1528.4,1091.5z"/>
            <path class="st3" d="M1519.3,1083.8c-1-0.7-2-0.4-2.7,0.2c-0.9,0.9-1.2,2.5,0.1,3.4c1.7,1.2,3.5-0.3,3.5-1.9
              C1520.2,1084.9,1519.9,1084.3,1519.3,1083.8z"/>
            <path class="st3" d="M1562.1,1070.7c-2.3-1.6-4.9,2-2.6,3.6C1561.9,1075.9,1564.5,1072.3,1562.1,1070.7z"/>
            <path class="st3" d="M1530.7,1057.1c2.3,1.6,4.9-2,2.6-3.6C1530.9,1051.9,1528.3,1055.5,1530.7,1057.1z"/>
            <path class="st3" d="M1518.2,1071.4c-0.4,0.2-0.9,0.2-1.4,0c-0.6-0.2-0.9-0.6-1.1-1.1c-1.6,0.4-2.5,2.7-0.8,4
              C1517,1075.7,1519.1,1073.2,1518.2,1071.4z"/>
            <path class="st3" d="M1558.1,1092.5h2.4C1559.6,1091.9,1558.8,1092,1558.1,1092.5z"/>
            <path class="st3" d="M1517.2,1057.1c-1.7,0.3-2.8,2.7-1,4c2.3,1.6,4.9-2,2.6-3.6c-0.2-0.1-0.3-0.2-0.5-0.3
              C1518,1057.2,1517.6,1057.2,1517.2,1057.1z"/>
            <path class="st3" d="M1540.5,1047.2c1.4,1,2.9,0,3.4-1.2c-0.5,0.1-1-0.1-1.4-0.5c-0.8-0.7-0.8-1.6-0.5-2.3
              C1540,1043,1538.5,1045.8,1540.5,1047.2z"/>
            <path class="st3" d="M1599.5,1048.5c-2.8-1.3-4.8,3-2,4.3C1600.2,1054.2,1602.3,1049.9,1599.5,1048.5z"/>
            <path class="st3" d="M1592.3,1066.6c0-1,0.6-2,1.8-2.1c1-0.1,1.6,0.4,2,1c0.4-1,0.1-2.3-1.2-2.9
              C1592.4,1061.4,1590.5,1065,1592.3,1066.6z"/>
            <path class="st3" d="M1604.9,1057.6c-0.1-0.1-0.2-0.1-0.3-0.1c0.3,1.1-0.3,2.5-1.8,2.6c-0.5,0-0.9-0.1-1.3-0.3
              c0,0.8,0.4,1.6,1.3,2.1C1605.7,1063.2,1607.7,1058.9,1604.9,1057.6z"/>
            <path class="st3" d="M1609,1073.5c2.8,1.3,4.8-3,2-4.3C1608.3,1067.9,1606.2,1072.2,1609,1073.5z"/>
            <path class="st3" d="M1612.8,1051.9c-2.8-1.3-4.8,3-2,4.3c1.6,0.7,2.9-0.3,3.3-1.6c-0.4-0.6-0.5-1.3-0.3-1.9
              C1613.5,1052.3,1613.2,1052.1,1612.8,1051.9z"/>
            <path class="st3" d="M1590.1,1053c-2.5-1.2-4.5,2.3-2.7,3.9c0.3-0.4,0.8-0.7,1.4-0.8c0.8-0.1,1.4,0.2,1.8,0.7
              C1591.7,1055.8,1591.8,1053.8,1590.1,1053z"/>
            <path class="st3" d="M1619.8,1064.4c-0.3-0.3-0.7-0.7-1-1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.6-0.1-1.2-0.2-1.8-0.3
              c-1.2-0.2-2.6,0.7-2.8,1.9c-0.1,1,0.3,2,1.1,2.5c0.4-1.4,2.2-2.6,3.6-1.3c0.5,0.4,0.7,0.9,0.7,1.4c0.5-0.4,0.9-0.9,0.9-1.5
              C1620.1,1065.4,1620,1064.9,1619.8,1064.4C1619.8,1064.4,1619.8,1064.4,1619.8,1064.4z"/>
            <path class="st3" d="M1601,1072.7c1.2-0.5,1.9-1.8,1.4-3.1c-0.1-0.2-0.2-0.4-0.2-0.7c-0.4-1.2-1.9-1.9-3.1-1.4
              c-1.2,0.5-1.9,1.8-1.4,3.1c0.1,0.2,0.2,0.4,0.2,0.7C1598.4,1072.4,1599.8,1073.2,1601,1072.7z"/>
            <path class="st3" d="M1586,1069.8c0.1-0.6,0.2-1.2,0.3-1.8c0.2-1.2-0.7-2.6-1.9-2.8c-1.3-0.2-2.6,0.6-2.8,1.9
              c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.4,0,0.7,0.1,1.1c0.2,0.9,0.9,1.6,1.9,1.7c0.9,0.1,1.8-0.3,2.3-0.9
              c0.1-0.2,0.2-0.4,0.3-0.6C1586,1070.1,1586,1069.9,1586,1069.8z"/>
            <path class="st3" d="M1609.8,1044c0.5,0.5,0.7,1,0.7,1.6c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.3,0.9-0.7,1.3-1c1-0.8,1.4-2.3,0.6-3.3
              c-0.7-1-2.3-1.4-3.3-0.6c-0.4,0.3-0.9,0.7-1.3,1c-0.7,0.6-1.1,1.5-1,2.4C1608.1,1043.3,1609,1043.3,1609.8,1044z"/>
            <path class="st3" d="M1593.7,1079.1c0.2-0.2,0.4-0.4,0.5-0.6c0.5-1.3,0-2.5-1.1-3.2c-0.9-0.5-1.7-1-2.6-1.5
              c0,0.7-0.4,1.4-0.9,1.7c-0.8,0.6-1.7,0.5-2.5-0.1c-0.1,0.5,0,1.1,0.2,1.5c0.2,0.4,0.6,0.8,1.1,1.1c0.9,0.5,1.7,1,2.6,1.5
              c0.5,0.3,1.2,0.3,1.8,0.1C1593.2,1079.6,1593.5,1079.4,1593.7,1079.1z"/>
            <path class="st3" d="M1601.8,1081.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.9-0.2-1.7,0.1-2.3,0.8c-0.1,0.1-0.2,0.3-0.3,0.5
              c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.9-0.3,1.8-0.2,2.6c0.2,1.2,1.2,2.4,2.6,2.2c1.2-0.2,2.4-1.3,2.2-2.6c0-0.1-0.1-0.3-0.1-0.5
              c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.3,0.2-0.4c0.2-0.3,0.2-0.6,0.2-1
              C1603.2,1082.6,1602.6,1081.6,1601.8,1081.3z M1602.6,1085.5c0-0.1,0-0.2,0-0.2C1602.6,1085.4,1602.6,1085.4,1602.6,1085.5z
              M1602.7,1085.1c0,0,0-0.1,0.1-0.2C1602.7,1084.9,1602.7,1085,1602.7,1085.1z"/>
            <path class="st3" d="M1611.4,1080.4c-2.8-1.3-4.8,3-2,4.3C1612.2,1086.1,1614.2,1081.7,1611.4,1080.4z"/>
            <path class="st3" d="M1590,1081.7c-0.4-0.2-0.9-0.3-1.2-0.2c-1.2,0.1-2,1.2-2.1,2.3c-0.1,0.9,0.3,1.8,1.3,2.3
              C1590.8,1087.3,1592.8,1083,1590,1081.7z"/>
            <path class="st3" d="M1579.7,1079c-0.2,0.1-0.5,0.2-0.7,0.2c-2.6,0.2-3-3.5-0.8-4.1c-2.4-0.3-3.8,3.4-1.3,4.5
              C1578,1080.2,1579.1,1079.8,1579.7,1079z"/>
            <path class="st3" d="M1620.1,1057.6c2.8,1.3,4.8-3,2-4.3C1619.3,1052,1617.3,1056.3,1620.1,1057.6z"/>
            <path class="st3" d="M1586.1,1044.8c1.6,0.7,2.9-0.3,3.3-1.6c-1-0.4-1.4-1.6-1.1-2.6c-0.1,0-0.1-0.1-0.2-0.1
              C1585.4,1039.1,1583.3,1043.5,1586.1,1044.8z"/>
            <path class="st3" d="M1572.3,1061.8c-1.3,1-1.6,3.2,0.3,4.1c2.5,1.2,4.4-2.2,2.7-3.8c-0.3,0.3-0.7,0.4-1.2,0.5
              C1573.3,1062.7,1572.7,1062.3,1572.3,1061.8z"/>
            <path class="st3" d="M1624.5,1076.9c-1.8-0.8-3.2,0.6-3.4,2.1c-0.1,0.9,0.3,1.8,1.3,2.2c0.4,0.2,0.7,0.2,1,0.2
              C1625.7,1081.4,1626.9,1078,1624.5,1076.9z"/>
            <path class="st3" d="M1571.2,1049.9c-0.4,0-0.7,0-1-0.2c0,0.7,0.4,1.5,1.3,1.9c2.8,1.3,4.8-3,2-4.3c-0.2-0.1-0.4-0.2-0.6-0.2
              C1573.3,1048.3,1572.8,1049.8,1571.2,1049.9z"/>
            <path class="st3" d="M1596.7,1032.4h-2C1595.5,1032.7,1596.1,1032.6,1596.7,1032.4z"/>
            <path class="st3" d="M1633.1,1042.5c0.9,0.4,1.8,0.1,2.4-0.5c0.8-0.8,1.1-2.2,0-3.2c-2.2-1.9-5.1,1.4-3,3.3
              C1632.7,1042.2,1632.9,1042.4,1633.1,1042.5z"/>
            <path class="st3" d="M1624.8,1053.5c2.2,1.9,5.1-1.4,3-3.3C1625.6,1048.3,1622.7,1051.6,1624.8,1053.5z"/>
            <path class="st3" d="M1635.1,1051.6c2.2,1.9,5.1-1.4,3-3.3C1635.9,1046.4,1632.9,1049.7,1635.1,1051.6z"/>
            <path class="st3" d="M1637.6,1063.6c1.1,1,2.4,0.6,3.1-0.3c0.7-0.8,0.9-2.1-0.2-3.1c-0.2-0.2-0.3-0.3-0.5-0.3
              C1637.9,1058.9,1635.6,1061.9,1637.6,1063.6z"/>
            <path class="st3" d="M1647.3,1047.1c0.1-0.5,0-1.1-0.5-1.6c-0.1-0.1-0.2-0.2-0.3-0.3c-2.2-1.9-5.1,1.4-3,3.3
              C1645.1,1049.9,1647.1,1048.6,1647.3,1047.1z"/>
            <path class="st3" d="M1625.9,1040.3c-2.2-1.9-5.1,1.4-3,3.3C1625.1,1045.5,1628.1,1042.2,1625.9,1040.3z"/>
            <path class="st3" d="M1649.4,1059.7c0.3-0.7,0.3-1.5-0.1-2.1c-0.2-0.4-0.5-0.7-0.9-0.9c-0.5-0.2-1-0.5-1.5-0.7
              c-0.2-0.1-0.5-0.2-0.7-0.2c-0.8-0.1-1.6,0.2-2.1,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,1.1-0.1,2.5,1,3c0.5,0.2,1,0.5,1.5,0.7
              c0.4,0.2,0.9,0.3,1.3,0.2C1648.5,1060.8,1649.1,1060.4,1649.4,1059.7z"/>
            <path class="st3" d="M1628.2,1058.7c0.1,1.2,1.2,2.2,2.4,2.1c1.2-0.1,2.2-1.1,2.1-2.4c0-0.2,0-0.4,0-0.6
              c-0.1-1.2-1.2-2.2-2.4-2.1c-1.2,0.1-2.2,1.1-2.1,2.4C1628.2,1058.3,1628.2,1058.5,1628.2,1058.7z"/>
            <path class="st3" d="M1614,1054.6c0.2,0.3,0.5,0.6,0.9,0.8c1.1,0.5,2.5,0.1,3-1c0.2-0.5,0.5-1,0.7-1.5c0.5-1.1,0.1-2.5-1-3
              c-1.1-0.5-2.5-0.1-3,1c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.1-0.1,0.2-0.1,0.3C1613.6,1053.3,1613.7,1054,1614,1054.6z"/>
            <path class="st3" d="M1646.8,1038.8c0.5-0.2,1-0.4,1.4-0.6c0.5-0.2,1-0.6,1.3-1.1c0.3-0.5,0.4-1.1,0.1-1.7
              c-0.3-0.8-1-1.4-1.8-1.5c-0.3,0-0.7,0-1,0.1c-0.5,0.2-1,0.4-1.4,0.6c-1.1,0.4-1.9,1.6-1.4,2.8
              C1644.4,1038.5,1645.6,1039.3,1646.8,1038.8z"/>
            <path class="st3" d="M1619.8,1064.4c0.8,0.8,2.4,0.6,3.1-0.2c0.8-1,0.7-2.3-0.2-3.2c-0.6-0.7-1.3-1.3-1.9-2
              c-0.8-0.8-2.4-0.7-3.2,0.2c-0.8,1-0.7,2.3,0.2,3.2c0.3,0.3,0.6,0.6,0.9,0.9C1619.1,1063.7,1619.4,1064,1619.8,1064.4
              C1619.8,1064.4,1619.8,1064.4,1619.8,1064.4z"/>
            <path class="st3" d="M1624.2,1072.3c-0.1,1.2,0.5,2.4,1.8,2.6c0.6,0.1,1.3-0.1,1.8-0.4c0.5-0.3,0.8-0.8,0.9-1.3
              c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.1
              c0.9-0.8,0.7-2.4-0.2-3.2c-1-0.8-2.2-0.7-3.2,0.2C1625,1069.8,1624.4,1071.1,1624.2,1072.3z M1628.7,1072.7
              c0-0.1,0-0.2,0.1-0.2C1628.7,1072.6,1628.7,1072.7,1628.7,1072.7z M1628.8,1072.4c0,0,0.1-0.1,0.1-0.2
              C1628.9,1072.2,1628.9,1072.3,1628.8,1072.4z"/>
            <path class="st3" d="M1634.9,1073.8c0.3,0.3,0.7,0.5,1.1,0.5c1.9,0.3,3.7-2.3,1.9-3.9c-1.4-1.2-3.1-0.3-3.6,1
              C1634,1072.2,1634.1,1073.1,1634.9,1073.8z"/>
            <path class="st3" d="M1618.4,1066c-1.5-1.3-3.3-0.2-3.6,1.3c-0.2,0.7,0,1.5,0.7,2.1c1.7,1.5,3.8-0.2,3.7-1.9
              C1619.1,1066.9,1618.9,1066.4,1618.4,1066z"/>
            <path class="st3" d="M1610.1,1057.4c-2.2-1.9-5.1,1.4-3,3.3C1609.3,1062.7,1612.3,1059.3,1610.1,1057.4z"/>
            <path class="st3" d="M1650.9,1050.6c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,0.9,0.5,1.3,0.6c1.3,0.1,2.5-1.3,2.4-2.6
              c-0.1-0.5-0.3-0.9-0.7-1.3c-0.2-0.2-0.4-0.3-0.7-0.4C1652.5,1047.9,1650.9,1049.2,1650.9,1050.6z"/>
            <path class="st3" d="M1626.5,1032.4h-1.2C1625.7,1032.5,1626.1,1032.5,1626.5,1032.4z"/>
            <path class="st3" d="M1610.5,1045.6c0-0.5-0.2-1.1-0.7-1.6c-0.8-0.7-1.7-0.7-2.4-0.3c-1.2,0.7-1.9,2.4-0.6,3.7
              C1608.4,1048.8,1610.5,1047.2,1610.5,1045.6z"/>
            <path class="st3" d="M1651.3,1072.4c0-0.6-0.2-1.2-0.7-1.7c-2.2-1.9-5.1,1.4-3,3.3c0,0,0,0,0.1,0
              C1649.2,1075.4,1651.2,1074,1651.3,1072.4z"/>
            <path class="st3" d="M1609.6,1034.2c1.7,1.5,3.8-0.2,3.7-1.9h-4.4C1608.8,1033,1609,1033.7,1609.6,1034.2z"/>
            <path class="st3" d="M1570.2,1049.8c0.3,0.1,0.6,0.2,1,0.2c1.6-0.1,2.1-1.6,1.7-2.8c-0.3-0.8-1-1.5-2.1-1.4
              C1568.5,1045.9,1568.4,1049,1570.2,1049.8z"/>
            <path class="st3" d="M1573.7,1058.4c-1.9,0.2-2.3,2.2-1.4,3.4c0.4,0.5,1,0.9,1.8,0.8c0.5,0,0.9-0.2,1.2-0.5
              C1576.6,1061,1575.9,1058.2,1573.7,1058.4z"/>
            <path class="st3" d="M1579.4,1054.3c2.7-0.3,2.3-4.5-0.4-4.2C1576.3,1050.4,1576.7,1054.6,1579.4,1054.3z"/>
            <path class="st3" d="M1588.9,1056.1c-0.7,0.1-1.1,0.4-1.4,0.8c-0.9,1.3-0.2,3.6,1.8,3.4c1.9-0.2,2.3-2.3,1.4-3.4
              C1590.3,1056.4,1589.7,1056.1,1588.9,1056.1z"/>
            <path class="st3" d="M1582.8,1046.5c2.7-0.3,2.3-4.5-0.4-4.2C1579.7,1042.5,1580,1046.7,1582.8,1046.5z"/>
            <path class="st3" d="M1566.1,1057.5c2.7-0.3,2.3-4.5-0.4-4.2C1563,1053.6,1563.4,1057.8,1566.1,1057.5z"/>
            <path class="st3" d="M1591.5,1048.4c-0.5,0.2-1,0.4-1.5,0.6c-1,0.4-1.7,1.7-1.2,2.7c0.4,1,1.6,1.7,2.7,1.2
              c0.5-0.2,1-0.4,1.5-0.6c1-0.4,1.7-1.7,1.2-2.7C1593.7,1048.6,1592.6,1048,1591.5,1048.4z"/>
            <path class="st3" d="M1582.1,1060c-0.9-0.7-2.3-0.6-3,0.3c-0.7,0.9-0.6,2.2,0.3,3c0.2,0.1,0.3,0.3,0.5,0.4
              c0.9,0.7,2.3,0.6,3-0.3c0.7-0.9,0.6-2.2-0.3-3C1582.4,1060.2,1582.2,1060.1,1582.1,1060z"/>
            <path class="st3" d="M1570.3,1068.6c0-0.2,0-0.5-0.1-0.7c-0.2-0.5-0.4-1-0.6-1.5c-0.4-1-1.7-1.7-2.7-1.2
              c-1,0.4-1.7,1.6-1.2,2.7c0.2,0.5,0.4,1,0.6,1.5c0.4,0.9,1.4,1.5,2.4,1.3c0.1,0,0.2-0.1,0.4-0.1
              C1569.8,1070.2,1570.3,1069.4,1570.3,1068.6z"/>
            <path class="st3" d="M1575.3,1039.1c1,0.4,2.4,0.1,2.8-1c0.2-0.4,0.4-0.9,0.5-1.3c0.4-1,0.1-2.3-1-2.8c-1-0.4-2.4-0.1-2.8,1
              c-0.2,0.4-0.4,0.9-0.5,1.3C1573.9,1037.3,1574.2,1038.6,1575.3,1039.1z"/>
            <path class="st3" d="M1579.1,1069.1c-0.3-0.2-0.8-0.3-1.2-0.3c-0.9,0-1.7,0-2.6,0c-1.1,0-2,1.2-1.9,2.3
              c0.2,1.2,1.1,1.9,2.3,1.9c0.9,0,1.7,0,2.6,0c1.1,0,2-1.2,1.9-2.3c0-0.2-0.1-0.4-0.2-0.6
              C1579.9,1069.7,1579.5,1069.4,1579.1,1069.1z"/>
            <path class="st3" d="M1587.1,1075.5c0.7,0.5,1.7,0.7,2.5,0.1c0.5-0.4,0.9-1.1,0.9-1.8c0-0.4-0.1-0.8-0.4-1.2
              c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.5c0-1.1-1.2-2-2.3-1.9
              c-0.6,0.1-1.1,0.4-1.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.9,0.3,1.8,0.8,2.6c0.1,0.2,0.3,0.5,0.5,0.7
              C1586.8,1075.2,1587,1075.4,1587.1,1075.5z M1589.8,1072.3c-0.1-0.1-0.1-0.1-0.1-0.2
              C1589.7,1072.2,1589.8,1072.2,1589.8,1072.3z M1589.6,1072c0,0,0-0.1,0-0.2C1589.6,1071.8,1589.6,1071.9,1589.6,1072z"/>
            <path class="st3" d="M1594.2,1064.5c-1.3,0.1-1.8,1.1-1.8,2.1c0,1.1,0.8,2.3,2.2,2.1c1.7-0.2,2.2-2,1.6-3.2
              C1595.8,1064.9,1595.1,1064.4,1594.2,1064.5z"/>
            <path class="st3" d="M1578.9,1079.2c0.3,0,0.5-0.1,0.7-0.2c0.5-0.2,0.8-0.7,1-1.2c0.2-0.8,0-1.8-0.6-2.3
              c-0.4-0.3-0.9-0.5-1.5-0.5c-0.1,0-0.2,0-0.4,0.1C1575.9,1075.7,1576.4,1079.5,1578.9,1079.2z"/>
            <path class="st3" d="M1569.3,1078.5c0.7-1.3,0-3.4-1.9-3.2c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
              C1568.5,1079.4,1569,1079,1569.3,1078.5z"/>
            <path class="st3" d="M1589.4,1043.2c0.3,0.1,0.7,0.2,1.1,0.2c2.7-0.3,2.3-4.5-0.4-4.2c-1,0.1-1.5,0.7-1.7,1.4
              C1588,1041.5,1588.4,1042.7,1589.4,1043.2z"/>
            <path class="st3" d="M1559,1048.9c0.2,0,0.3,0,0.5-0.1c0-0.2,0.1-0.4,0.1-0.6c0.2-0.5,0.7-0.9,1.2-1c0.2-1.2-0.6-2.6-2.2-2.5
              C1555.9,1045,1556.3,1049.2,1559,1048.9z"/>
            <path class="st3" d="M1557.9,1066.8c-2.7,0.3-2.3,4.5,0.4,4.2C1561,1070.8,1560.6,1066.6,1557.9,1066.8z"/>
            <path class="st3" d="M1604.6,1057.5c-0.2-0.9-1-1.7-2.2-1.6c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
              C1604.3,1060,1604.9,1058.6,1604.6,1057.5z"/>
            <path class="st3" d="M1550.6,1060.7c0.1,0,0.3,0,0.4,0c0.3,0,0.6-0.1,0.9-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.9-0.8,0.8-2.2,0.1-3.1
              c-0.4-0.4-0.9-0.7-1.7-0.6C1548.1,1056.7,1548.3,1060.5,1550.6,1060.7z"/>
            <path class="st3" d="M1559.9,1035.6c1.8-0.2,2.2-2.1,1.5-3.3h-3.4C1557.2,1033.7,1557.9,1035.8,1559.9,1035.6z"/>
            <path class="st3" d="M1490.4,1078.5c-0.2,0-0.4,0-0.6,0.1c-1.5,0.5-1.6,2-0.9,3c0.4,0.6,1.2,1,2.1,0.7
              C1493.3,1081.6,1492.4,1078.4,1490.4,1078.5z"/>
            <path class="st3" d="M1495.1,1089.6c-1.4,0.5-1.6,2-1,2.9h3.2C1498.2,1091.2,1497,1088.9,1495.1,1089.6z"/>
            <path class="st3" d="M1499,1080.8c-0.3,0-0.5,0-0.8,0.1c-2.4,0.8-1.2,4.5,1.2,3.7c0.9-0.3,1.3-1,1.3-1.8
              c-0.2,0-0.3-0.1-0.5-0.1C1499.3,1082.4,1499,1081.6,1499,1080.8z"/>
            <path class="st3" d="M1508.4,1084.4c-2.4,0.8-1.2,4.5,1.2,3.7C1512.1,1087.3,1510.9,1083.6,1508.4,1084.4z"/>
            <path class="st3" d="M1499.1,1073.8c0.1-0.2,0.2-0.5,0.4-0.6c-2.1,0.9-0.9,4.4,1.4,3.6c0,0,0.1,0,0.1-0.1
              c-0.3-0.1-0.6-0.3-0.8-0.4C1499.2,1075.9,1498.7,1074.9,1499.1,1073.8z"/>
            <path class="st3" d="M1486.8,1086.6c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.2-0.8,0.5-0.9,0.9c-0.6,1.3,0.6,3.3,2.4,2.7
              C1490.4,1089.5,1489.2,1085.8,1486.8,1086.6z"/>
            <path class="st3" d="M1511.3,1078.2c0.3,0.1,0.7,0.4,0.9,0.7c0.1-0.5,0.1-1.1-0.3-1.5c-0.1-0.2-0.3-0.3-0.5-0.5
              c-0.7-0.5-1.5-0.6-2.3-0.1c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.9-0.6,2.7c0.2,0.3,0.5,0.5,0.9,0.7
              c0.1-0.6,0.2-1.2,0.5-1.7C1509.3,1078.3,1510.2,1077.8,1511.3,1078.2z"/>
            <path class="st3" d="M1503.6,1089.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.9c-0.4,0.8-0.2,1.8,0.5,2.4h3.3
              c0.1-0.1,0.1-0.2,0.2-0.3C1504.9,1091.2,1504.5,1090,1503.6,1089.5z"/>
            <path class="st3" d="M1493.8,1069.2c0-0.5,0.1-1,0.1-1.5c0-0.4,0.2-0.7,0.4-1c-0.2-0.2-0.5-0.3-0.7-0.3c-1-0.2-2.2,0.4-2.3,1.5
              c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,1,0.4,2.2,1.5,2.3c0.7,0.1,1.4-0.1,1.9-0.6C1494,1070.5,1493.7,1069.9,1493.8,1069.2z"/>
            <path class="st3" d="M1515,1090.9c-0.9,0.3-1.2,0.9-1.3,1.6h3.8C1517.3,1091.4,1516.4,1090.4,1515,1090.9z"/>
            <path class="st3" d="M1508.5,1071.1c-0.4-0.2-0.8-0.5-0.9-1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.3-0.2-0.7-0.1-1
              c-0.3-0.1-0.7-0.1-1.2,0c-2.4,0.8-1.2,4.5,1.2,3.7C1508,1072.2,1508.4,1071.7,1508.5,1071.1z"/>
            <path class="st3" d="M1478.5,1080.2c-2.4,0.8-1.2,4.5,1.2,3.7C1482.2,1083.1,1480.9,1079.4,1478.5,1080.2z"/>
            <path class="st3" d="M1520.8,1081.4c-0.8,0.3-1.2,0.8-1.3,1.4c-0.1,0.8,0.2,1.7,0.9,2.1c0.4,0.3,1,0.4,1.6,0.2
              C1524.4,1084.3,1523.2,1080.6,1520.8,1081.4z"/>
            <path class="st3" d="M1473.9,1092.5h0.9C1474.5,1092.4,1474.2,1092.4,1473.9,1092.5z"/>
            <path class="st3" d="M1477.3,1071.7c0.2,0,0.3,0,0.5-0.1c2.4-0.8,1.2-4.5-1.2-3.7c-0.8,0.3-1.2,0.9-1.3,1.6
              C1475.2,1070.6,1476.1,1071.8,1477.3,1071.7z"/>
            <path class="st3" d="M1549.2,1035.1c1.8,1.6,4.2-1,2.9-2.7h-3.1C1548.4,1033.1,1548.2,1034.3,1549.2,1035.1z"/>
            <path class="st3" d="M1542.4,1045.5c0.5,0.4,1,0.5,1.4,0.5c1.5-0.2,2.7-2.2,1.2-3.5c-1.2-1-2.5-0.3-3.1,0.7
              C1541.6,1043.9,1541.6,1044.8,1542.4,1045.5z"/>
            <path class="st3" d="M1554.2,1040.6c-2-1.7-4.6,1.4-2.6,3C1553.5,1045.3,1556.1,1042.2,1554.2,1040.6z"/>
            <path class="st3" d="M1554,1054.3c2,1.7,4.6-1.4,2.6-3C1554.6,1049.6,1552,1052.6,1554,1054.3z"/>
            <path class="st3" d="M1561.8,1037.6c-2-1.7-4.6,1.4-2.6,3C1561.1,1042.4,1563.8,1039.3,1561.8,1037.6z"/>
            <path class="st3" d="M1543.2,1033.6c-2-1.7-4.6,1.4-2.6,3C1542.5,1038.3,1545.2,1035.2,1543.2,1033.6z"/>
            <path class="st3" d="M1559.6,1048.3c-0.1,0.2-0.1,0.4-0.1,0.6c-0.1,0.8,0.3,1.7,1.1,2.1c0.5,0.2,0.9,0.4,1.4,0.6
              c1,0.4,2.2,0,2.7-1c0.4-1,0.1-2.2-1-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-0.5-0.2-1-0.2-1.5-0.1
              C1560.3,1047.4,1559.8,1047.7,1559.6,1048.3z"/>
            <path class="st3" d="M1545.5,1050c0.1,1,1.1,2,2.2,1.8c1.1-0.1,1.9-1,1.8-2.2c0-0.2,0-0.4,0-0.6c-0.1-1-1.1-2-2.2-1.8
              c-1.1,0.1-1.9,1-1.8,2.2C1545.5,1049.7,1545.5,1049.8,1545.5,1050z"/>
            <path class="st3" d="M1533.6,1047.2c1,0.4,2.2,0.1,2.7-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.7c-1-0.4-2.2-0.1-2.7,1
              c-0.2,0.5-0.4,0.9-0.6,1.4C1532.2,1045.5,1532.6,1046.8,1533.6,1047.2z"/>
            <path class="st3" d="M1536.3,1053.5c0.6,0.6,1.2,1.2,1.7,1.7c0.7,0.7,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8
              c-0.6-0.6-1.2-1.2-1.7-1.7c-0.7-0.7-2.2-0.6-2.8,0.2C1535.4,1051.6,1535.5,1052.7,1536.3,1053.5z"/>
            <path class="st3" d="M1543.2,1064.4c0.2,0.1,0.4,0.2,0.6,0.2c1,0.1,2.2-0.5,2.3-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
              c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3.1
              c0,0.1,0,0.2,0,0.4C1542.2,1063.3,1542.6,1064,1543.2,1064.4z M1546.4,1062.1c0,0-0.1,0.1-0.1,0.2
              C1546.3,1062.2,1546.3,1062.2,1546.4,1062.1z M1546.2,1062.6c0-0.1,0-0.2,0.1-0.2C1546.2,1062.5,1546.2,1062.5,1546.2,1062.6z"
              />
            <path class="st3" d="M1552.2,1060.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.6-0.6,0.9c-0.3,0.7-0.2,1.5,0.6,2.1
              c1.8,1.5,4.1-0.9,3-2.6c-0.1-0.1-0.2-0.3-0.4-0.4C1553.7,1059.8,1552.9,1059.8,1552.2,1060.2z"/>
            <path class="st3" d="M1537,1056.9c-0.1-0.1-0.1-0.1-0.2-0.2c-2-1.7-4.6,1.4-2.6,3c0.6,0.5,1.3,0.6,1.8,0.4
              C1537.3,1059.7,1538.1,1058,1537,1056.9z"/>
            <path class="st3" d="M1526.7,1052.2c2,1.7,4.6-1.4,2.6-3C1527.3,1047.5,1524.7,1050.5,1526.7,1052.2z"/>
            <path class="st3" d="M1569,1040.9c-2-1.7-4.6,1.4-2.6,3C1568.4,1045.6,1571,1042.5,1569,1040.9z"/>
            <path class="st3" d="M1528.8,1037.1c-2-1.7-4.6,1.4-2.6,3C1528.2,1041.9,1530.8,1038.8,1528.8,1037.1z"/>
            <path class="st3" d="M1565.7,1060.4c-2-1.7-4.6,1.4-2.6,3C1565.1,1065.1,1567.7,1062.1,1565.7,1060.4z"/>
            <path class="st3" d="M1506.1,1055.1c2.4,0.9,3.8-2.7,1.4-3.7C1505.2,1050.5,1503.8,1054.2,1506.1,1055.1z"/>
            <path class="st3" d="M1504.7,1063.2c-2.4-0.9-3.8,2.7-1.4,3.7C1505.6,1067.8,1507,1064.2,1504.7,1063.2z"/>
            <path class="st3" d="M1511.1,1062.2c2.4,0.9,3.8-2.7,1.4-3.7C1510.2,1057.6,1508.7,1061.3,1511.1,1062.2z"/>
            <path class="st3" d="M1516.8,1071.4c0.5,0.2,1,0.2,1.4,0c1.4-0.6,1.9-3,0.1-3.7c-0.8-0.3-1.5-0.1-1.9,0.3
              c-0.3,0.2-0.5,0.5-0.6,0.9c-0.2,0.5-0.2,1,0,1.4C1515.9,1070.8,1516.2,1071.1,1516.8,1071.4z"/>
            <path class="st3" d="M1517.2,1057.1c0.4,0.2,0.8,0.2,1.1,0.1c1.6-0.3,2.3-3,0.3-3.8C1516.3,1052.5,1514.9,1056.1,1517.2,1057.1
              z"/>
            <path class="st3" d="M1498.7,1059.3c2.4,0.9,3.8-2.7,1.4-3.7C1497.7,1054.7,1496.3,1058.4,1498.7,1059.3z"/>
            <path class="st3" d="M1521.8,1066.1c0.5,0,1,0.1,1.5,0.1c1,0.1,2.1-0.7,2.2-1.8c0.1-1.1-0.7-2.1-1.8-2.2c-0.5,0-1-0.1-1.5-0.1
              c-1-0.1-2.1,0.7-2.2,1.8C1520,1065,1520.7,1066,1521.8,1066.1z"/>
            <path class="st3" d="M1507.4,1069.6c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.4,0.5,0.7,0.9,1c0.5,0.3,1.1,0.3,1.7,0.1
              c1-0.5,1.5-1.6,1-2.6c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.5-2.6-1c-0.6,0.3-1.1,0.9-1.2,1.6
              C1507.2,1069,1507.2,1069.3,1507.4,1069.6z"/>
            <path class="st3" d="M1497.7,1069.6c0-0.5,0.1-1,0.1-1.5c0.1-1-0.7-2.1-1.8-2.2c-0.7,0-1.3,0.2-1.7,0.7c-0.2,0.3-0.4,0.6-0.4,1
              c0,0.5-0.1,1-0.1,1.5c-0.1,0.6,0.2,1.3,0.7,1.7c0.3,0.2,0.6,0.4,1,0.4C1496.6,1071.4,1497.6,1070.7,1497.7,1069.6z"/>
            <path class="st3" d="M1516.9,1048.1c0.3-0.3,0.7-0.6,1-0.9c0.8-0.7,1-1.9,0.3-2.8c-0.7-0.8-1.9-1.1-2.8-0.3
              c-0.3,0.3-0.7,0.6-1,0.9c-0.8,0.7-1,1.9-0.3,2.8C1514.8,1048.6,1516.1,1048.8,1516.9,1048.1z"/>
            <path class="st3" d="M1503.8,1073.7c-0.7-0.4-1.5-0.7-2.2-1.1c-0.7-0.3-1.6-0.1-2.1,0.5c-0.2,0.2-0.3,0.4-0.4,0.6
              c-0.4,1.1,0.1,2.1,1.1,2.6c0.3,0.1,0.6,0.3,0.8,0.4c0.4,0.2,0.9,0.4,1.3,0.7c0.9,0.5,2.2-0.2,2.6-1.1
              C1505.3,1075.2,1504.8,1074.2,1503.8,1073.7z"/>
            <path class="st3" d="M1512.2,1078.9c-0.2-0.3-0.5-0.6-0.9-0.7c-1.1-0.4-2.1,0.1-2.6,1.1c-0.3,0.5-0.4,1.1-0.5,1.7
              c0,0.5,0,1.1,0.1,1.6c0.3,1,1.1,1.9,2.2,1.6c1-0.2,1.9-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
              c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3C1512.7,1079.9,1512.5,1079.3,1512.2,1078.9z M1512.2,1081.4
              c0,0.1,0,0.1,0,0.2C1512.2,1081.5,1512.2,1081.4,1512.2,1081.4z M1512.2,1081.3c0,0,0-0.1,0-0.2
              C1512.2,1081.1,1512.2,1081.2,1512.2,1081.3z"/>
            <path class="st3" d="M1519.2,1076.9c-2.4-0.9-3.8,2.7-1.4,3.7C1520.1,1081.5,1521.5,1077.8,1519.2,1076.9z"/>
            <path class="st3" d="M1501.7,1079.1c-1.4-0.6-2.5,0.5-2.6,1.7c-0.1,0.8,0.2,1.6,1.2,2c0.2,0.1,0.4,0.1,0.5,0.1
              C1502.8,1083.2,1503.8,1080,1501.7,1079.1z"/>
            <path class="st3" d="M1492.2,1074.6c-2.4-0.9-3.8,2.7-1.4,3.7C1493.2,1079.1,1494.6,1075.5,1492.2,1074.6z"/>
            <path class="st3" d="M1525,1057.7c2.4,0.9,3.8-2.7,1.4-3.7C1524,1053.1,1522.6,1056.8,1525,1057.7z"/>
            <path class="st3" d="M1496.4,1049.1c2.4,0.9,3.8-2.7,1.4-3.7C1495.5,1044.5,1494,1048.2,1496.4,1049.1z"/>
            <path class="st3" d="M1487.5,1067.3c1.7-0.3,2.4-3,0.4-3.8c-2.2-0.9-3.6,2.2-1.9,3.4c0.1,0.1,0.3,0.2,0.5,0.2
              C1486.9,1067.4,1487.2,1067.4,1487.5,1067.3z"/>
            <path class="st3" d="M1529.7,1073.3c-1.3-0.5-2.4,0.4-2.6,1.5c-0.1,0.6,0,1.3,0.6,1.8c0.2,0.1,0.4,0.3,0.6,0.4
              C1530.6,1077.9,1532,1074.2,1529.7,1073.3z"/>
            <path class="st3" d="M1484.8,1055.6c2.4,0.9,3.8-2.7,1.4-3.7C1483.9,1051,1482.4,1054.6,1484.8,1055.6z"/>
            <path class="st3" d="M1502.7,1038.4c2.4,0.9,3.8-2.7,1.4-3.7C1501.8,1033.8,1500.4,1037.5,1502.7,1038.4z"/>
            <path class="st3" d="M832.4,1063.3c-2.6-1.9-5.7,2.2-3,4.1C832,1069.3,835,1065.2,832.4,1063.3z"/>
            <path class="st3" d="M824.8,1077.1c-2.6-1.9-5.7,2.2-3,4.1C824.4,1083.1,827.5,1079,824.8,1077.1z"/>
            <path class="st3" d="M836.2,1073.8c-2.6-1.9-5.7,2.2-3,4.1C835.9,1079.9,838.9,1075.8,836.2,1073.8z"/>
            <path class="st3" d="M840.3,1087.2c-2.6-1.9-5.7,2.2-3,4.1C839.9,1093.2,843,1089.1,840.3,1087.2z"/>
            <path class="st3" d="M845.6,1069.4c-2.6-1.9-5.7,2.2-3,4.1C845.2,1075.5,848.3,1071.4,845.6,1069.4z"/>
            <path class="st3" d="M821.6,1066c-2.6-1.9-5.7,2.2-3,4.1C821.2,1072.1,824.3,1068,821.6,1066z"/>
            <path class="st3" d="M848.9,1082.3c-0.6-0.2-1.2-0.4-1.8-0.7c-1.2-0.5-2.9,0.2-3.3,1.5c-0.4,1.3,0.1,2.8,1.5,3.3
              c0.6,0.2,1.2,0.4,1.8,0.7c1.2,0.5,2.9-0.2,3.3-1.5C850.8,1084.3,850.2,1082.8,848.9,1082.3z"/>
            <path class="st3" d="M831.1,1085.2c-0.2-1.3-1.6-2.4-2.9-2.1c-1.4,0.3-2.3,1.5-2.1,2.9c0,0.2,0.1,0.5,0.1,0.7
              c0.2,1.3,1.6,2.4,2.9,2.1c1.4-0.3,2.3-1.5,2.1-2.9C831.2,1085.7,831.1,1085.5,831.1,1085.2z"/>
            <path class="st3" d="M813.2,1077.7c-1.3-0.4-2.8,0.1-3.3,1.5c-0.2,0.6-0.4,1.2-0.7,1.8c-0.5,1.2,0.2,2.9,1.5,3.3
              c1.3,0.4,2.8-0.1,3.3-1.5c0.2-0.6,0.4-1.2,0.7-1.8C815.1,1079.8,814.5,1078.1,813.2,1077.7z"/>
            <path class="st3" d="M843.1,1057.5c-1.2,0.6-2,2.1-1.3,3.4c0.6,1.2,2.1,2,3.4,1.3c0.5-0.3,1-0.5,1.6-0.8c1.2-0.6,2-2.1,1.3-3.4
              c-0.6-1.2-2.1-2-3.4-1.3C844.2,1057,843.7,1057.3,843.1,1057.5z"/>
            <path class="st3" d="M820.2,1089.9c-0.8-0.7-1.6-1.4-2.4-2.1c-1-0.9-2.8-0.5-3.6,0.5c-0.8,1.2-0.5,2.6,0.5,3.6
              c0.2,0.2,0.4,0.3,0.6,0.5h5.8C821.4,1091.6,821,1090.6,820.2,1089.9z"/>
            <path class="st3" d="M805.5,1087.1c-2.6-1.9-5.7,2.2-3,4.1C805.1,1093.2,808.1,1089.1,805.5,1087.1z"/>
            <path class="st3" d="M855.1,1072.8c-2.6-1.9-5.7,2.2-3,4.1C854.7,1078.9,857.7,1074.8,855.1,1072.8z"/>
            <path class="st3" d="M819.1,1056.7c2.6,1.9,5.7-2.2,3-4.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.9-1.3,1.6-2.4,0.9
              c-0.2-0.1-0.4-0.3-0.5-0.5C817.9,1053.8,817.7,1055.6,819.1,1056.7z"/>
            <path class="st3" d="M803.7,1071.9c-2.6-1.9-5.7,2.2-3,4.1C803.3,1078,806.4,1073.9,803.7,1071.9z"/>
            <path class="st3" d="M805.5,1056.8c-2.6-1.9-5.7,2.2-3,4.1C805.1,1062.8,808.1,1058.7,805.5,1056.8z"/>
            <path class="st3" d="M830.4,1043.3c0.1,0.6,0,1.1-0.4,1.5c0.2,0.2,0.3,0.4,0.6,0.6c1.2,0.9,2.4,0.5,3.2-0.3
              c0-0.6,0.3-1.2,0.8-1.5c0.1-0.8-0.1-1.7-1-2.3c-1.4-1.1-3-0.3-3.7,0.9c0.2,0.2,0.3,0.4,0.3,0.6
              C830.4,1043,830.4,1043.1,830.4,1043.3z"/>
            <path class="st3" d="M867.7,1062.9c-2.8,0.4-2.2,4.8,0.6,4.4C871.2,1066.8,870.6,1062.5,867.7,1062.9z"/>
            <path class="st3" d="M871.4,1076c-2.8,0.4-2.2,4.8,0.6,4.4C874.9,1080,874.3,1075.6,871.4,1076z"/>
            <path class="st3" d="M877.2,1071.5c2.8-0.4,2.2-4.8-0.6-4.4C873.7,1067.5,874.4,1071.9,877.2,1071.5z"/>
            <path class="st3" d="M887.2,1072.9c-2.8,0.4-2.2,4.8,0.6,4.4C890.7,1076.8,890,1072.5,887.2,1072.9z"/>
            <path class="st3" d="M879.7,1058.6c-2.8,0.4-2.2,4.8,0.6,4.4C883.1,1062.6,882.5,1058.2,879.7,1058.6z"/>
            <path class="st3" d="M862.7,1071.1c-2.8,0.4-2.2,4.8,0.6,4.4C866.2,1075.1,865.6,1070.7,862.7,1071.1z"/>
            <path class="st3" d="M892.5,1065.8c-0.5-1.1-1.8-1.7-2.9-1.1c-0.5,0.2-1,0.4-1.5,0.7c-1.1,0.5-1.7,1.8-1.1,2.9
              c0.5,1.1,1.8,1.7,2.9,1.1c0.5-0.2,1-0.4,1.5-0.7C892.4,1068.2,893,1066.8,892.5,1065.8z"/>
            <path class="st3" d="M880.8,1077.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-0.7-2.4-0.5-3.1,0.4c-0.7,1-0.5,2.4,0.4,3.1
              c0.2,0.1,0.3,0.3,0.5,0.4c0.9,0.7,2.4,0.5,3.1-0.4C881.9,1079.7,881.8,1078.4,880.8,1077.6z"/>
            <path class="st3" d="M867.5,1084.6c-0.5-1.1-1.8-1.7-2.9-1.1c-1.1,0.5-1.7,1.8-1.1,2.9c0.2,0.5,0.4,1,0.7,1.5
              c0.5,1.1,1.8,1.7,2.9,1.1c1.1-0.5,1.7-1.8,1.1-2.9C868,1085.6,867.7,1085.1,867.5,1084.6z"/>
            <path class="st3" d="M871.4,1051.3c-0.2,0.5-0.3,1-0.5,1.4c-0.4,1.1,0,2.5,1.2,2.9c1.1,0.4,2.5,0,2.9-1.2
              c0.2-0.5,0.3-1,0.5-1.4c0.4-1.1,0-2.5-1.2-2.9C873.2,1049.7,871.8,1050.1,871.4,1051.3z"/>
            <path class="st3" d="M876.4,1086.8c-0.9,0.1-1.8,0.1-2.7,0.2c-1.2,0.1-2.1,1.4-1.9,2.5c0.2,1.3,1.3,2,2.5,1.9
              c0.9-0.1,1.8-0.1,2.7-0.2c1.2-0.1,2.1-1.4,1.9-2.5C878.7,1087.4,877.6,1086.7,876.4,1086.8z"/>
            <path class="st3" d="M889.3,1090.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2
              c0-0.2,0-0.4,0-0.5c-0.1-1.2-1.4-2.1-2.5-1.9c-1.3,0.2-1.9,1.3-1.9,2.5c0.1,1.1,0.5,2.2,1.2,3.1h4
              C889.9,1091.7,889.9,1090.8,889.3,1090.1z M888.8,1089.3c0,0,0,0.1,0.1,0.2C888.8,1089.4,888.8,1089.4,888.8,1089.3z
              M889,1089.8c-0.1-0.1-0.1-0.1-0.1-0.2C888.9,1089.7,889,1089.7,889,1089.8z"/>
            <path class="st3" d="M893.2,1081.4c-2.8,0.4-2.2,4.8,0.6,4.4C896.7,1085.3,896,1081,893.2,1081.4z"/>
            <path class="st3" d="M889.1,1055.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c-0.1,1.1-1.4,1.7-2.4,1.6c-0.3,0-0.5-0.2-0.8-0.3
              c0.2,1.1,1,2.1,2.4,1.9c2.2-0.3,2.3-3,1-4C889.2,1055.4,889.1,1055.5,889.1,1055.6z"/>
            <path class="st3" d="M889.1,1055.5c0-0.1,0.1-0.1,0.1-0.1C889.2,1055.4,889.1,1055.5,889.1,1055.5z"/>
            <path class="st3" d="M889.1,1055.6c0,0-0.1,0.1-0.1,0.2C889,1055.8,889,1055.7,889.1,1055.6z"/>
            <path class="st3" d="M855.5,1066.8c2.8-0.4,2.2-4.8-0.6-4.4C852.1,1062.8,852.7,1067.2,855.5,1066.8z"/>
            <path class="st3" d="M855.2,1085.7c-2.8,0.4-2.2,4.8,0.6,4.4C858.7,1089.6,858.1,1085.2,855.2,1085.7z"/>
            <path class="st3" d="M901.5,1072c-2.8,0.4-2.2,4.8,0.6,4.4C904.9,1075.9,904.3,1071.6,901.5,1072z"/>
            <path class="st3" d="M847.1,1075.2c-2.8,0.4-2.2,4.8,0.6,4.4C850.6,1079.1,849.9,1074.8,847.1,1075.2z"/>
            <path class="st3" d="M855.8,1052.8c2.8-0.4,2.2-4.8-0.6-4.4C852.3,1048.8,853,1053.2,855.8,1052.8z"/>
            <path class="st3" d="M1067.1,1049.5c-2.7,1.4-0.5,5.5,2.2,4.2C1072,1052.3,1069.8,1048.1,1067.1,1049.5z"/>
            <path class="st3" d="M1077.6,1065.5c2.7-1.4,0.5-5.5-2.2-4.2C1072.7,1062.7,1074.9,1066.9,1077.6,1065.5z"/>
            <path class="st3" d="M1079.5,1054.8c2.7-1.4,0.5-5.5-2.2-4.2C1074.7,1052,1076.9,1056.2,1079.5,1054.8z"/>
            <path class="st3" d="M1090,1052.7c-2.7,1.4-0.5,5.5,2.2,4.2C1094.9,1055.5,1092.7,1051.3,1090,1052.7z"/>
            <path class="st3" d="M1079.7,1045.3c2.7-1.4,0.5-5.5-2.2-4.2C1074.9,1042.5,1077.1,1046.7,1079.7,1045.3z"/>
            <path class="st3" d="M1065,1059.5c-2.7,1.4-0.5,5.5,2.2,4.2C1069.8,1062.2,1067.7,1058.1,1065,1059.5z"/>
            <path class="st3" d="M1088.1,1048.2c0.3,0.3,0.7,0.6,1.2,0.7c0.3-0.8,0.9-1.4,2-1.5c0.4,0,0.7,0,1,0.1c0.1-0.1,0.2-0.2,0.4-0.3
              c0.9-0.8,1-2.4,0.1-3.3c-0.9-0.9-2.4-1-3.3-0.1c-0.4,0.4-0.8,0.8-1.3,1.2C1087.3,1045.7,1087.2,1047.3,1088.1,1048.2z"/>
            <path class="st3" d="M1083.2,1063.9c0.2,0.1,0.4,0.1,0.6,0.2c1.2,0.4,2.6-0.3,2.9-1.5c0.3-1.2-0.3-2.5-1.5-2.9
              c-0.2-0.1-0.4-0.1-0.6-0.2c-1.2-0.4-2.6,0.3-2.9,1.5C1081.4,1062.2,1082,1063.5,1083.2,1063.9z"/>
            <path class="st3" d="M1075.7,1072.6c-0.4-0.4-0.8-0.8-1.2-1.3c-0.8-0.9-2.4-1-3.3-0.1c-0.9,0.9-1,2.4-0.1,3.3
              c0.4,0.4,0.8,0.8,1.2,1.3c0.8,0.9,2.4,1,3.3,0.1C1076.4,1075,1076.6,1073.5,1075.7,1072.6z"/>
            <path class="st3" d="M1069,1040.8c1.2,0,2.5-0.9,2.5-2.2c0-0.5,0-1.1,0-1.6c0-1.2-0.9-2.4-2.2-2.5c-1.2,0-2.5,0.9-2.5,2.2
              c0,0.5,0,1.1,0,1.6C1066.8,1039.5,1067.7,1040.7,1069,1040.8z"/>
            <path class="st3" d="M1080.5,1074.6c0.7,1.2,1.9,1.5,3.2,1c0.9-0.4,1.8-0.7,2.7-1.1c1.1-0.5,1.6-2.1,1-3.2
              c-0.7-1.2-1.9-1.5-3.2-1c-0.9,0.4-1.8,0.7-2.7,1.1C1080.3,1071.9,1079.9,1073.6,1080.5,1074.6z"/>
            <path class="st3" d="M1098.9,1072.4c0.6-1,0.4-2.6-0.7-3.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
              c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.1-2.1-1.6-3.2-1c-1.2,0.7-1.5,1.9-1,3.2
              c0.5,1.2,1.5,2.3,2.7,2.9C1096.7,1073.7,1098.2,1073.6,1098.9,1072.4z M1097.7,1069c-0.1-0.1-0.2-0.1-0.2-0.2
              C1097.6,1068.9,1097.7,1068.9,1097.7,1069z M1097.4,1068.7c0,0-0.1-0.1-0.1-0.2C1097.3,1068.6,1097.4,1068.6,1097.4,1068.7z"/>
            <path class="st3" d="M1099,1059.1c-2.7,1.4-0.5,5.5,2.2,4.2C1103.8,1061.9,1101.7,1057.7,1099,1059.1z"/>
            <path class="st3" d="M1087.3,1076.5c-2.7,1.4-0.5,5.5,2.2,4.2C1092.1,1079.2,1089.9,1075.1,1087.3,1076.5z"/>
            <path class="st3" d="M1075.8,1081.3c-2.7,1.4-0.5,5.5,2.2,4.2C1080.6,1084.1,1078.5,1079.9,1075.8,1081.3z"/>
            <path class="st3" d="M1086.4,1038.9c2.7-1.4,0.5-5.5-2.2-4.2C1081.6,1036.1,1083.7,1040.3,1086.4,1038.9z"/>
            <path class="st3" d="M1054.1,1053.5c-2.7,1.4-0.5,5.5,2.2,4.2C1059,1056.3,1056.8,1052.1,1054.1,1053.5z"/>
            <path class="st3" d="M1062.6,1076.6c-2.7,1.4-0.5,5.5,2.2,4.2C1067.4,1079.4,1065.3,1075.2,1062.6,1076.6z"/>
            <path class="st3" d="M1106.1,1051c2.7-1.4,0.5-5.5-2.2-4.2C1101.3,1048.2,1103.4,1052.4,1106.1,1051z"/>
            <path class="st3" d="M1050.8,1069c-2.7,1.4-0.5,5.5,2.2,4.2C1055.7,1071.7,1053.5,1067.6,1050.8,1069z"/>
            <path class="st3" d="M1051.7,1043.6c2.7-1.4,0.5-5.5-2.2-4.2C1046.9,1040.9,1049,1045,1051.7,1043.6z"/>
            <path class="st3" d="M1104.9,1064.3c-3.2,0.3-2.7,5.3,0.5,5C1108.6,1068.9,1108.1,1063.9,1104.9,1064.3z"/>
            <path class="st3" d="M1108.5,1079.4c-3.2,0.3-2.7,5.3,0.5,5C1112.2,1084.1,1111.7,1079,1108.5,1079.4z"/>
            <path class="st3" d="M1115.2,1074.5c3.2-0.3,2.7-5.3-0.5-5C1111.5,1069.8,1112,1074.8,1115.2,1074.5z"/>
            <path class="st3" d="M1126.5,1076.5c-3.2,0.3-2.7,5.3,0.5,5C1130.3,1081.2,1129.8,1076.2,1126.5,1076.5z"/>
            <path class="st3" d="M1119.2,1065c3.2-0.3,2.7-5.3-0.5-5C1115.4,1060.3,1115.9,1065.3,1119.2,1065z"/>
            <path class="st3" d="M1098.9,1073.4c-3.2,0.3-2.7,5.3,0.5,5C1102.6,1078.1,1102.1,1073.1,1098.9,1073.4z"/>
            <path class="st3" d="M1132.8,1068.7c-0.5-1.2-1.9-2-3.3-1.4c-0.6,0.2-1.2,0.5-1.7,0.7c-1.2,0.5-2,2-1.4,3.3
              c0.3,0.8,1,1.4,1.8,1.5c0.3-0.5,0.9-0.9,1.7-0.8c0.3,0,0.6,0.1,0.8,0.3c0.2-0.1,0.4-0.2,0.6-0.3
              C1132.6,1071.4,1133.4,1069.9,1132.8,1068.7z"/>
            <path class="st3" d="M1119.2,1081.8c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-1-0.8-2.7-0.7-3.5,0.4
              c-0.8,1.1-0.7,2.7,0.4,3.5c0.2,0.2,0.4,0.3,0.6,0.5c1,0.8,2.7,0.7,3.5-0.4c0.2-0.3,0.3-0.5,0.4-0.8
              C1118.9,1083.8,1118.7,1082.6,1119.2,1081.8z"/>
            <path class="st3" d="M1104.4,1090.7c-0.2-0.6-0.5-1.2-0.7-1.7c-0.5-1.2-2-2-3.3-1.4c-1.2,0.5-2,1.9-1.4,3.3
              c0.2,0.6,0.5,1.1,0.7,1.7h4.7C1104.6,1091.9,1104.6,1091.3,1104.4,1090.7z"/>
            <path class="st3" d="M1110.2,1056.3c1.2,0.5,2.8,0.1,3.3-1.3c0.2-0.5,0.4-1.1,0.6-1.6c0.5-1.2,0.1-2.8-1.3-3.3
              c-1.2-0.5-2.8-0.1-3.3,1.3c-0.2,0.5-0.4,1.1-0.6,1.6C1108.5,1054.2,1108.9,1055.7,1110.2,1056.3z"/>
            <path class="st3" d="M1110.6,1091.9c-0.5,0-1,0.3-1.4,0.6h6.5c-0.5-0.5-1.2-0.7-2-0.7
              C1112.6,1091.8,1111.6,1091.9,1110.6,1091.9z"/>
            <path class="st3" d="M1134.3,1091.2c2.3-1,1.7-5.1-1.3-4.8c-3.1,0.3-2.7,5,0.2,5c0.2-0.1,0.3-0.2,0.5-0.2
              C1133.9,1091.2,1134.1,1091.2,1134.3,1091.2z"/>
            <path class="st3" d="M1128.3,1061.2c3.2-0.3,2.7-5.3-0.5-5C1124.6,1056.6,1125.1,1061.6,1128.3,1061.2z"/>
            <path class="st3" d="M1090.3,1063.2c-3.2,0.3-2.7,5.3,0.5,5C1094,1067.9,1093.5,1062.9,1090.3,1063.2z"/>
            <path class="st3" d="M1089.7,1089.6c-1.7,0.2-2.3,1.6-2.1,2.9h4.8C1092.5,1091,1091.6,1089.4,1089.7,1089.6z"/>
            <path class="st3" d="M1142.8,1076.1c-0.3,0-0.5,0.1-0.8,0.2c0.2,1-0.3,2.1-1.4,2.3c0,1.4,0.9,2.7,2.7,2.5
              C1146.5,1080.8,1146,1075.8,1142.8,1076.1z"/>
            <path class="st3" d="M1080.9,1077.3c-3.2,0.3-2.7,5.3,0.5,5C1084.7,1082,1084.2,1077,1080.9,1077.3z"/>
            <path class="st3" d="M1091.3,1047.3c-1.1,0.1-1.7,0.7-2,1.5c-0.6,1.6,0.3,3.7,2.5,3.5c2.8-0.3,2.8-4.2,0.5-4.9
              C1092,1047.3,1091.7,1047.3,1091.3,1047.3z"/>
            <path class="st3" d="M888.2,1035.5c-1.9-1.7-4.6,1.3-2.7,3C887.5,1040.3,890.1,1037.3,888.2,1035.5z"/>
            <path class="st3" d="M897.4,1033.8c-1.9-1.7-4.6,1.3-2.7,3C896.7,1038.5,899.4,1035.5,897.4,1033.8z"/>
            <path class="st3" d="M899.7,1044.6c-1.9-1.7-4.6,1.3-2.7,3C898.9,1049.3,901.6,1046.3,899.7,1044.6z"/>
            <path class="st3" d="M902.4,1034c1.5,1.3,3.4-0.1,3.3-1.7h-4C901.7,1032.9,901.9,1033.5,902.4,1034z"/>
            <path class="st3" d="M907.7,1044.1c0.4-1,0.1-2.2-0.9-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.5-2.3-0.1-2.7,0.9
              c-0.4,1-0.1,2.2,0.9,2.7c0.5,0.2,0.9,0.4,1.4,0.6C905.9,1045.4,907.3,1045.1,907.7,1044.1z"/>
            <path class="st3" d="M888.6,1043.2c0.1,1.1,1.1,2,2.1,1.9c1.1-0.1,2-1,1.9-2.1c0-0.2,0-0.4,0-0.6c-0.1-1.1-1.1-2-2.1-1.9
              c-1.1,0.1-2,1-1.9,2.1C888.5,1042.8,888.5,1043,888.6,1043.2z"/>
            <path class="st3" d="M876.6,1040.2c1,0.4,2.2,0.1,2.7-0.9c0.2-0.5,0.4-0.9,0.6-1.4c0.5-1,0.1-2.3-0.9-2.7
              c-1-0.4-2.2-0.1-2.7,0.9c-0.2,0.5-0.4,0.9-0.6,1.4C875.2,1038.4,875.6,1039.7,876.6,1040.2z"/>
            <path class="st3" d="M881.9,1043.5c-0.7-0.8-2.2-0.6-2.8,0.2c-0.7,0.9-0.6,2,0.2,2.8c0.6,0.6,1.1,1.2,1.7,1.8
              c0.7,0.8,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8C883.1,1044.7,882.5,1044.1,881.9,1043.5z"/>
            <path class="st3" d="M885.8,1057.5c0.2,0.2,0.5,0.3,0.8,0.3c1,0.2,2.3-0.5,2.4-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
              c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3
              C884.9,1056.2,885.2,1057.1,885.8,1057.5z M889,1055.8c0-0.1,0-0.2,0.1-0.2C889,1055.7,889,1055.8,889,1055.8z M889.2,1055.4
              c0,0-0.1,0.1-0.1,0.1C889.1,1055.5,889.2,1055.4,889.2,1055.4C889.2,1055.4,889.2,1055.4,889.2,1055.4z"/>
            <path class="st3" d="M894.6,1056.8c1.9,1.7,4.6-1.3,2.7-3C895.4,1052.1,892.7,1055.1,894.6,1056.8z"/>
            <path class="st3" d="M879.7,1049.8c-1.9-1.7-4.6,1.3-2.7,3C879,1054.5,881.7,1051.5,879.7,1049.8z"/>
            <path class="st3" d="M872.3,1042c-1.9-1.7-4.6,1.3-2.7,3C871.5,1046.8,874.2,1043.8,872.3,1042z"/>
            <path class="st3" d="M909.6,1037.4c1.9,1.7,4.6-1.3,2.7-3C910.4,1032.6,907.7,1035.7,909.6,1037.4z"/>
            <path class="st3" d="M872.4,1032.4h-3.5c0.1,0.2,0.3,0.4,0.5,0.6C870.4,1034,871.8,1033.4,872.4,1032.4z"/>
            <path class="st3" d="M906,1057c1.9,1.7,4.6-1.3,2.7-3C906.8,1052.3,904.1,1055.3,906,1057z"/>
            <path class="st3" d="M824.2,1040.8c1.8,1.1,3.5-1.7,1.7-2.8C824.1,1036.9,822.4,1039.7,824.2,1040.8z"/>
            <path class="st3" d="M831.3,1038.1c1.8,1.1,3.5-1.7,1.7-2.8C831.2,1034.2,829.5,1037,831.3,1038.1z"/>
            <path class="st3" d="M833.9,1045.1c0,0.5,0.2,0.9,0.8,1.2c1.8,1.1,3.5-1.7,1.7-2.8c-0.6-0.4-1.2-0.3-1.7,0
              C834.2,1043.9,833.8,1044.5,833.9,1045.1z"/>
            <path class="st3" d="M837,1034.7c1.6,1,3.1-1.1,2.1-2.4h-2.5C836.1,1033.1,836,1034.2,837,1034.7z"/>
            <path class="st3" d="M821.5,1033.9c1.3,0.8,2.5-0.4,2.5-1.5h-3.2C820.7,1032.9,820.9,1033.5,821.5,1033.9z"/>
            <path class="st3" d="M839.4,1042.8c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.8-0.3,2-1.1c0.2-0.9-0.2-1.8-1.1-2
              c-0.4-0.1-0.8-0.2-1.2-0.3c-0.8-0.2-1.8,0.3-2,1.1C838.1,1041.6,838.6,1042.5,839.4,1042.8z"/>
            <path class="st3" d="M827.3,1044c0.2,0.8,1.1,1.4,2,1.2c0.3-0.1,0.6-0.3,0.8-0.5c0.4-0.4,0.5-0.9,0.4-1.5
              c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.2-0.5-0.3-0.6c-0.4-0.5-1-0.7-1.6-0.6c-0.8,0.2-1.4,1.1-1.2,2
              C827.2,1043.7,827.2,1043.9,827.3,1044z"/>
            <path class="st3" d="M819.3,1042.2c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.1-2c-0.9-0.2-1.8,0.2-2,1.1
              c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8,0.3,1.8,1.1,2C818.1,1043.5,819.1,1043.1,819.3,1042.2z"/>
            <path class="st3" d="M820.3,1048c0.5,0.4,1.1,0.8,1.6,1.2c0.7,0.5,1.8,0.2,2.2-0.5c0.5-0.8,0.2-1.7-0.5-2.2
              c-0.5-0.4-1.1-0.8-1.6-1.2c-0.7-0.5-1.8-0.2-2.2,0.5C819.3,1046.6,819.6,1047.5,820.3,1048z"/>
            <path class="st3" d="M827.8,1056c0.8,0,1.7-0.7,1.7-1.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2
              c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.8-0.5-2.2c-0.8-0.5-1.7-0.2-2.2,0.5c-0.5,0.7-0.8,1.7-0.8,2.6
              C826.3,1055.1,826.8,1056,827.8,1056z M829.5,1053.6c0,0,0,0.1-0.1,0.1C829.5,1053.7,829.5,1053.7,829.5,1053.6z M829.4,1053.9
              c0,0.1,0,0.1,0,0.2C829.4,1054,829.4,1053.9,829.4,1053.9z"/>
            <path class="st3" d="M834.1,1054c1.8,1.1,3.5-1.7,1.7-2.8C834,1050.1,832.3,1052.9,834.1,1054z"/>
            <path class="st3" d="M819.5,1053.3c1,0.6,2.1,0,2.4-0.9c0.2-0.6,0.1-1.4-0.7-1.8c-1.6-0.9-3,1-2.2,2.3
              C819.1,1053,819.2,1053.2,819.5,1053.3z"/>
            <path class="st3" d="M814.1,1045.4c-1.8-1.1-3.5,1.7-1.7,2.8C814.2,1049.3,815.9,1046.5,814.1,1045.4z"/>
            <path class="st3" d="M843.2,1036.4c1.8,1.1,3.5-1.7,1.7-2.8C843.1,1032.5,841.4,1035.3,843.2,1036.4z"/>
            <path class="st3" d="M810.5,1038.7c1.8,1.1,3.5-1.7,1.7-2.8C810.4,1034.8,808.7,1037.6,810.5,1038.7z"/>
            <path class="st3" d="M844.8,1049.7c-1.8-1.1-3.5,1.7-1.7,2.8C844.9,1053.6,846.6,1050.8,844.8,1049.7z"/>
            <path class="st3" d="M1142,1076.3c-0.1-0.7-0.6-1.4-1.6-1.4c-2.4-0.2-2.8,3.5-0.4,3.7c0.2,0,0.4,0,0.6,0
              C1141.7,1078.4,1142.2,1077.3,1142,1076.3z"/>
            <path class="st3" d="M1140.8,1086.5c-2.4-0.2-2.8,3.5-0.4,3.7C1142.9,1090.4,1143.2,1086.7,1140.8,1086.5z"/>
            <path class="st3" d="M1146.8,1080.1c-2.4-0.2-2.8,3.5-0.4,3.7C1148.9,1084.1,1149.2,1080.4,1146.8,1080.1z"/>
            <path class="st3" d="M1154.4,1087c-2.4-0.2-2.8,3.5-0.4,3.7C1156.5,1091,1156.8,1087.3,1154.4,1087z"/>
            <path class="st3" d="M1151.1,1073.8c-2.4-0.2-2.8,3.5-0.4,3.7C1153.2,1077.8,1153.5,1074,1151.1,1073.8z"/>
            <path class="st3" d="M1134.6,1080.7c-2.4-0.2-2.8,3.5-0.4,3.7C1136.7,1084.6,1137.1,1080.9,1134.6,1080.7z"/>
            <path class="st3" d="M1158,1080.7c-0.5,0.1-0.9,0.2-1.4,0.2c-1,0.2-1.7,1.2-1.5,2.2c0.2,1,1.1,1.7,2.2,1.5
              c0.5-0.1,0.9-0.2,1.4-0.2c1-0.2,1.7-1.2,1.5-2.2C1160,1081.3,1159.1,1080.5,1158,1080.7z"/>
            <path class="st3" d="M1148.2,1089.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6-0.8-1.9-0.9-2.6-0.3c-0.8,0.7-0.9,1.8-0.3,2.6
              c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.3,0.6,0.4h1.8c0.1-0.1,0.2-0.1,0.3-0.2C1148.7,1091.6,1148.9,1090.5,1148.2,1089.7z"/>
            <path class="st3" d="M1134.3,1091.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.2c-0.4,0.2-0.8,0.6-0.9,1.1h3.6
              C1135.5,1091.8,1134.9,1091.3,1134.3,1091.2z"/>
            <path class="st3" d="M1148.4,1065.7c-0.8-0.5-2.1-0.5-2.6,0.4c-0.2,0.4-0.5,0.7-0.7,1.1c-0.5,0.8-0.5,2,0.4,2.6
              c0.8,0.5,2.1,0.5,2.6-0.4c0.2-0.4,0.5-0.7,0.7-1.1C1149.4,1067.5,1149.3,1066.3,1148.4,1065.7z"/>
            <path class="st3" d="M1158,1076.1c2.4,0.2,2.8-3.5,0.4-3.7C1156,1072.2,1155.6,1075.9,1158,1076.1z"/>
            <path class="st3" d="M1128.2,1072.7c-0.6,1.1-0.3,2.8,1.3,2.9c2.1,0.2,2.6-2.5,1.2-3.5c-0.2-0.1-0.5-0.2-0.8-0.3
              C1129.1,1071.8,1128.5,1072.2,1128.2,1072.7z"/>
            <path class="st3" d="M1125.5,1091.1c-1.1-0.1-1.7,0.6-1.9,1.4h3.5C1126.9,1091.8,1126.4,1091.2,1125.5,1091.1z"/>
            <path class="st3" d="M1166.4,1089.2c-2-0.2-2.6,2.3-1.4,3.3h2.4C1168.4,1091.6,1168.2,1089.4,1166.4,1089.2z"/>
            <path class="st3" d="M1120.9,1080.8c-0.9-0.1-1.4,0.3-1.8,0.9c-0.5,0.9-0.3,2.1,0.6,2.6c0.2,0.1,0.5,0.2,0.8,0.2
              C1123,1084.8,1123.4,1081.1,1120.9,1080.8z"/>
            <path class="st3" d="M1132.6,1064.2c2.4,0.2,2.8-3.5,0.4-3.7C1130.6,1060.2,1130.2,1063.9,1132.6,1064.2z"/>
            <path class="st3" d="M623.3,1072.5c-2.2-2.1-5.4,1.3-3.2,3.4C622.2,1077.9,625.4,1074.6,623.3,1072.5z"/>
            <path class="st3" d="M611.7,1087.5c2.2,2.1,5.4-1.3,3.2-3.4C612.7,1082.1,609.5,1085.4,611.7,1087.5z"/>
            <path class="st3" d="M622.3,1085.9c2.2,2.1,5.4-1.3,3.2-3.4C623.4,1080.5,620.2,1083.8,622.3,1085.9z"/>
            <path class="st3" d="M631.3,1083c2.2,2.1,5.4-1.3,3.2-3.4C632.3,1077.6,629.1,1080.9,631.3,1083z"/>
            <path class="st3" d="M613.3,1073.8c-2.2-2.1-5.4,1.3-3.2,3.4C612.2,1079.2,615.4,1075.8,613.3,1073.8z"/>
            <path class="st3" d="M636,1091.6c-0.5-0.3-1-0.5-1.5-0.8c-1.1-0.6-2.6-0.2-3.1,1c-0.1,0.2-0.2,0.5-0.2,0.8h5.8
              C636.7,1092.1,636.4,1091.8,636,1091.6z"/>
            <path class="st3" d="M617.2,1089.9c-1.3,0.1-2.3,1.1-2.3,2.4c0,0.1,0,0.1,0,0.2h4.6c0-0.1,0-0.2,0-0.3
              C619.6,1091,618.5,1089.8,617.2,1089.9z"/>
            <path class="st3" d="M604.3,1083.4c-1.1-0.5-2.5-0.2-3.1,1c-0.3,0.5-0.5,1-0.8,1.5c-0.6,1.1-0.2,2.6,1,3.1
              c1.1,0.5,2.5,0.2,3.1-1c0.3-0.5,0.5-1,0.8-1.5C605.8,1085.4,605.4,1083.9,604.3,1083.4z"/>
            <path class="st3" d="M638,1069.7c-0.4-1.1-1.7-2-2.9-1.6c-0.5,0.2-1,0.4-1.5,0.5c-1.1,0.4-2,1.6-1.6,2.9c0.4,1.1,1.7,2,2.9,1.6
              c0.2-0.1,0.4-0.2,0.7-0.2c-1.7-0.5-1.8-3.3,0.3-3.6c1.4-0.2,2.1,1,2,2.1C638.1,1070.8,638.2,1070.2,638,1069.7z"/>
            <path class="st3" d="M605.2,1092.5h1.1C605.9,1092.4,605.6,1092.4,605.2,1092.5z"/>
            <path class="st3" d="M592.4,1092.5h4.6c0-0.5-0.2-1.1-0.7-1.5C594.7,1089.5,592.6,1090.8,592.4,1092.5z"/>
            <path class="st3" d="M639.4,1087.1c2.2,2.1,5.4-1.3,3.2-3.4C640.5,1081.7,637.3,1085.1,639.4,1087.1z"/>
            <path class="st3" d="M612.1,1065c2.2,2.1,5.4-1.3,3.2-3.4C613.1,1059.6,609.9,1063,612.1,1065z"/>
            <path class="st3" d="M593.2,1080.4c2.2,2.1,5.4-1.3,3.2-3.4C594.3,1075,591.1,1078.4,593.2,1080.4z"/>
            <path class="st3" d="M599.8,1063.6c-2.2-2.1-5.4,1.3-3.2,3.4C598.7,1069,601.9,1065.6,599.8,1063.6z"/>
            <path class="st3" d="M627,1052.8c-2.2-2.1-5.4,1.3-3.2,3.4C625.9,1058.3,629.1,1054.9,627,1052.8z"/>
            <path class="st3" d="M638.1,1043.4c-2.4,0.3-1.9,3.9,0.5,3.7C641,1046.7,640.5,1043.1,638.1,1043.4z"/>
            <path class="st3" d="M641,1054.4c-2.4,0.3-1.9,3.9,0.5,3.7C643.8,1057.7,643.4,1054.1,641,1054.4z"/>
            <path class="st3" d="M645.9,1050.7c2.4-0.3,1.9-3.9-0.5-3.7C643.1,1047.3,643.5,1050.9,645.9,1050.7z"/>
            <path class="st3" d="M654.2,1052c-2.4,0.3-1.9,3.9,0.5,3.7C657,1055.3,656.5,1051.7,654.2,1052z"/>
            <path class="st3" d="M648.2,1040c-2.4,0.3-1.9,3.9,0.5,3.7C651,1043.4,650.5,1039.7,648.2,1040z"/>
            <path class="st3" d="M634.3,1053.8c2.4-0.3,1.9-3.9-0.5-3.7C631.5,1050.4,632,1054.1,634.3,1053.8z"/>
            <path class="st3" d="M658.7,1046.1c-0.4-0.9-1.5-1.4-2.4-1c-0.4,0.2-0.8,0.4-1.3,0.5c-0.9,0.4-1.4,1.5-1,2.4
              c0.4,0.9,1.5,1.4,2.4,1c0.4-0.2,0.8-0.4,1.3-0.5C658.6,1048.2,659.1,1047,658.7,1046.1z"/>
            <path class="st3" d="M648.8,1055.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2-0.5-2.6,0.3c-0.6,0.8-0.5,2,0.3,2.6
              c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2,0.5,2.6-0.3C649.7,1057.6,649.6,1056.5,648.8,1055.8z"/>
            <path class="st3" d="M637.6,1061.5c-0.4-0.9-1.5-1.4-2.4-1c-0.9,0.4-1.4,1.5-1,2.4c0.2,0.4,0.4,0.8,0.5,1.3
              c0.4,0.9,1.5,1.4,2.4,1c0.9-0.4,1.4-1.5,1-2.4C638,1062.3,637.8,1061.9,637.6,1061.5z"/>
            <path class="st3" d="M641.9,1037.4c0.9,0.3,2.1,0,2.4-1c0.1-0.4,0.3-0.8,0.4-1.2c0.3-0.9,0-2.1-1-2.4c-0.9-0.3-2.1,0-2.4,1
              c-0.1,0.4-0.3,0.8-0.4,1.2C640.6,1035.9,641,1037.1,641.9,1037.4z"/>
            <path class="st3" d="M643.2,1067.1c0.8,0,1.5-0.1,2.3-0.1c1,0,1.8-1.1,1.6-2.1c-0.2-1-1-1.6-2.1-1.6c-0.8,0-1.5,0.1-2.3,0.1
              c-1,0-1.8,1.1-1.6,2.1C641.3,1066.6,642.2,1067.2,643.2,1067.1z"/>
            <path class="st3" d="M655.4,1066.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-1-1.1-1.8-2.1-1.6
              c-1.1,0.2-1.6,1-1.6,2.1c0,1,0.5,2.1,1.2,2.9c0.7,0.7,1.8,1,2.6,0.3c0.7-0.6,1-1.8,0.3-2.6
              C655.6,1066.3,655.5,1066.2,655.4,1066.1z M655.2,1065.6c0,0,0,0.1,0,0.2C655.3,1065.8,655.3,1065.7,655.2,1065.6z
              M655.4,1066.1c0-0.1-0.1-0.1-0.1-0.2C655.4,1066,655.4,1066,655.4,1066.1z"/>
            <path class="st3" d="M659.1,1059.1c-2.4,0.3-1.9,3.9,0.5,3.7C661.9,1062.5,661.4,1058.8,659.1,1059.1z"/>
            <path class="st3" d="M645.7,1068.8c-2.4,0.3-1.9,3.9,0.5,3.7C648.5,1072.1,648,1068.5,645.7,1068.8z"/>
            <path class="st3" d="M635.9,1069.2c-2.1,0.3-2,3.2-0.3,3.6c0.2,0.1,0.5,0.1,0.7,0c1-0.1,1.5-0.8,1.5-1.6
              C638,1070.2,637.3,1069,635.9,1069.2z"/>
            <path class="st3" d="M655.3,1040.8c2.4-0.3,1.9-3.9-0.5-3.7C652.5,1037.4,652.9,1041.1,655.3,1040.8z"/>
            <path class="st3" d="M627.5,1042.8c-2.4,0.3-1.9,3.9,0.5,3.7C630.3,1046.2,629.8,1042.5,627.5,1042.8z"/>
            <path class="st3" d="M627.4,1062.1c-2.4,0.3-1.9,3.9,0.5,3.7C630.2,1065.5,629.8,1061.8,627.4,1062.1z"/>
            <path class="st3" d="M666.1,1051.4c-2.4,0.3-1.9,3.9,0.5,3.7C668.9,1054.8,668.4,1051.1,666.1,1051.4z"/>
            <path class="st3" d="M621.3,1056.9c2.4-0.3,1.9-3.9-0.5-3.7C618.5,1053.6,618.9,1057.2,621.3,1056.9z"/>
            <path class="st3" d="M628.4,1034.8c1.4-0.2,1.8-1.5,1.4-2.5h-3.3C626.1,1033.5,626.8,1035,628.4,1034.8z"/>
            <path class="st3" d="M1761,1036.1c1.7-0.8,1.5-2.8,0.4-3.8h-2.9C1756.7,1033.9,1758.5,1037.3,1761,1036.1z"/>
            <path class="st3" d="M1747.7,1036.3c-2.7,1.2-0.7,5.4,1.9,4.1C1752.3,1039.2,1750.4,1035,1747.7,1036.3z"/>
            <path class="st3" d="M1737,1035.3c1.3-0.6,1.5-1.9,1.1-2.9h-4.1C1733.3,1034,1734.9,1036.2,1737,1035.3z"/>
            <path class="st3" d="M1797.3,1035.1c1-0.4,1.9-1.6,1.5-2.7h-4.5c0.1,0.4,0.2,0.8,0.3,1.2
              C1795,1034.6,1796.1,1035.5,1797.3,1035.1z"/>
            <path class="st3" d="M1785.6,1035.1c1.8,0.4,2.9-1.3,2.6-2.7h-4.1C1783.8,1033.5,1784.2,1034.7,1785.6,1035.1z"/>
            <path class="st3" d="M1885.1,1056.3c2.7,1.6,5.1-2.6,2.4-4.2C1884.9,1050.6,1882.4,1054.7,1885.1,1056.3z"/>
            <path class="st3" d="M1892.2,1061.7c-2.7-1.6-5.1,2.6-2.4,4.2C1892.5,1067.4,1894.9,1063.2,1892.2,1061.7z"/>
            <path class="st3" d="M1897.4,1073.8c-2.7-1.6-5.1,2.6-2.4,4.2C1897.6,1079.5,1900.1,1075.4,1897.4,1073.8z"/>
            <path class="st3" d="M1900.6,1056.6c-2.7-1.6-5.1,2.6-2.4,4.2C1900.8,1062.3,1903.3,1058.2,1900.6,1056.6z"/>
            <path class="st3" d="M1875.3,1060c2.7,1.6,5.1-2.6,2.4-4.2C1875,1054.3,1872.6,1058.4,1875.3,1060z"/>
            <path class="st3" d="M1906.7,1071.3c0.3-1.3-0.4-2.6-1.7-2.9c-0.6-0.1-1.2-0.3-1.7-0.4c-1.2-0.3-2.7,0.4-2.9,1.7
              c-0.3,1.3,0.4,2.6,1.7,2.9c0.6,0.1,1.2,0.3,1.7,0.4C1904.9,1073.3,1906.4,1072.6,1906.7,1071.3z"/>
            <path class="st3" d="M1888.5,1072.9c-0.3-1.2-1.7-2.1-2.9-1.7c-1.2,0.4-2.1,1.6-1.7,2.9c0.1,0.2,0.1,0.4,0.2,0.7
              c0.3,1.2,1.7,2.1,2.9,1.7c1.2-0.4,2.1-1.6,1.7-2.9C1888.6,1073.3,1888.6,1073.1,1888.5,1072.9z"/>
            <path class="st3" d="M1872.7,1070.6c0.3-1.2-0.4-2.7-1.7-2.9c-1.3-0.3-2.6,0.4-2.9,1.7c-0.1,0.6-0.3,1.2-0.4,1.7
              c-0.3,1.2,0.4,2.7,1.7,2.9c1.3,0.3,2.6-0.4,2.9-1.7C1872.4,1071.7,1872.5,1071.1,1872.7,1070.6z"/>
            <path class="st3" d="M1899.4,1049.9c0.5-0.3,0.9-0.6,1.4-0.9c1.1-0.7,1.6-2.1,0.9-3.3c-0.7-1.1-2.2-1.6-3.3-0.9
              c-0.5,0.3-0.9,0.6-1.4,0.9c-1.1,0.7-1.6,2.1-0.9,3.3C1896.8,1050,1898.3,1050.6,1899.4,1049.9z"/>
            <path class="st3" d="M1878.8,1078.4c-0.8-0.6-1.6-1.1-2.4-1.7c-1-0.7-2.7-0.2-3.3,0.9c-0.6,1.2-0.2,2.5,0.9,3.3
              c0.8,0.6,1.6,1.1,2.4,1.7c1,0.7,2.7,0.2,3.3-0.9C1880.3,1080.4,1879.9,1079.2,1878.8,1078.4z"/>
            <path class="st3" d="M1887.1,1085.2c-1.2-0.7-2.5-0.2-3.3,0.9c-0.8,1.1-1.2,2.6-1.1,3.9c0.1,1.2,1,2.5,2.4,2.4
              c1.2-0.1,2.5-1.1,2.4-2.4c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4
              C1888.7,1087.4,1888.2,1085.7,1887.1,1085.2z M1887.7,1088.8c0,0,0,0.1-0.1,0.2C1887.6,1089,1887.6,1088.9,1887.7,1088.8z
              M1887.5,1089.2c0,0.1,0,0.2,0,0.2C1887.5,1089.4,1887.5,1089.3,1887.5,1089.2z"/>
            <path class="st3" d="M1895.6,1087.4c-1.2-0.3-1.6-1.3-1.4-2.2c-1.2,0.9-1.7,3,0.1,4.1c2.2,1.3,4.2-1.2,3.4-3.1
              C1897.5,1087,1896.7,1087.7,1895.6,1087.4z"/>
            <path class="st3" d="M1875.2,1084.5c-2.7-1.6-5.1,2.6-2.4,4.2C1875.5,1090.2,1877.9,1086.1,1875.2,1084.5z"/>
            <path class="st3" d="M1864.7,1077.2c-2.7-1.6-5.1,2.6-2.4,4.2C1864.9,1083,1867.4,1078.8,1864.7,1077.2z"/>
            <path class="st3" d="M1907.4,1063c2.7,1.6,5.1-2.6,2.4-4.2C1907.1,1057.3,1904.7,1061.4,1907.4,1063z"/>
            <path class="st3" d="M1874.4,1047.2c2.7,1.6,5.1-2.6,2.4-4.2C1874.2,1041.5,1871.8,1045.7,1874.4,1047.2z"/>
            <path class="st3" d="M1861.5,1063.2c-2.7-1.6-5.1,2.6-2.4,4.2C1861.7,1068.9,1864.2,1064.7,1861.5,1063.2z"/>
            <path class="st3" d="M1910.2,1085.3c0.4,0.1,0.6,0.2,0.8,0.4c0.5-1,0.4-2.4-0.9-3.1c-2.7-1.6-5.1,2.6-2.4,4.2
              c0.1,0,0.1,0.1,0.2,0.1C1908.1,1085.9,1908.9,1085,1910.2,1085.3z"/>
            <path class="st3" d="M1859.2,1052.9c2.7,1.6,5.1-2.6,2.4-4.2C1859,1047.2,1856.5,1051.3,1859.2,1052.9z"/>
            <path class="st3" d="M1884.1,1035.5c2.2,1.3,4.2-1.3,3.4-3.2h-4.2C1882.7,1033.4,1882.8,1034.8,1884.1,1035.5z"/>
            <path class="st3" d="M1916.7,1080.3c-2.4-0.5-3.2,3.1-0.8,3.7C1918.2,1084.5,1919.1,1080.8,1916.7,1080.3z"/>
            <path class="st3" d="M1913.8,1092.5h2.8c-0.2-0.3-0.6-0.5-1-0.6C1914.8,1091.7,1914.2,1092,1913.8,1092.5z"/>
            <path class="st3" d="M1922.4,1086.4c-2.4-0.5-3.2,3.1-0.8,3.7C1923.9,1090.6,1924.8,1086.9,1922.4,1086.4z"/>
            <path class="st3" d="M1927.5,1080.6c-2.4-0.5-3.2,3.1-0.8,3.7C1929,1084.8,1929.9,1081.2,1927.5,1080.6z"/>
            <path class="st3" d="M1911,1085.8c-0.2-0.2-0.5-0.4-0.8-0.4c-1.3-0.3-2.1,0.6-2.2,1.6c-0.1,0.9,0.3,1.8,1.4,2.1
              C1911.3,1089.5,1912.3,1086.9,1911,1085.8z"/>
            <path class="st3" d="M1933.5,1088.4c-0.5,0-0.9,0-1.4,0.1c-1,0.1-1.9,0.9-1.8,2c0.1,1,0.9,1.9,2,1.8c0.5,0,0.9,0,1.4-0.1
              c1-0.1,1.9-0.9,1.8-2C1935.4,1089.2,1934.6,1088.3,1933.5,1088.4z"/>
            <path class="st3" d="M1925.8,1072.2c-0.7-0.6-2-0.7-2.7,0.1c-0.3,0.3-0.6,0.7-0.8,1c-0.6,0.8-0.7,2,0.1,2.7
              c0.7,0.6,2,0.7,2.7-0.1c0.3-0.3,0.6-0.7,0.8-1C1926.6,1074.1,1926.6,1072.9,1925.8,1072.2z"/>
            <path class="st3" d="M1934.9,1080.2c-2.4-0.5-3.2,3.1-0.8,3.7C1936.4,1084.4,1937.3,1080.7,1934.9,1080.2z"/>
            <path class="st3" d="M1906.6,1076c-2.4-0.5-3.2,3.1-0.8,3.7C1908.1,1080.2,1909,1076.5,1906.6,1076z"/>
            <path class="st3" d="M1895.6,1087.4c1.1,0.3,1.9-0.4,2.1-1.2c0.3-1,0-2.2-1.3-2.5c-1.2-0.3-2,0.5-2.2,1.5
              C1894.1,1086.1,1894.5,1087.2,1895.6,1087.4z"/>
          </g>
        </g>
        <g>
          <rect id="XMLID_14_" x="1740.3" y="934.3" class="st2" width="192.3" height="133.7"/>
          <g>
            <path class="st3" d="M1749.5,1015.6c1.2,0.4,2.2-0.1,2.8-1c-0.4-0.8-0.3-1.9,0.3-2.6c-0.2-0.5-0.7-1-1.4-1.2
              C1748,1009.7,1746.4,1014.5,1749.5,1015.6z"/>
            <path class="st3" d="M1746.6,1030.9c3.1,1,4.7-3.7,1.6-4.8C1745.1,1025,1743.5,1029.8,1746.6,1030.9z"/>
            <path class="st3" d="M1758.8,1023.7c-0.1-0.7,0.1-1.6,0.7-2c0-0.9-0.5-1.8-1.6-2.2c-1.8-0.6-3.1,0.8-3.2,2.3
              c0.7-0.1,1.5,0.1,2.1,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.4,0.4,0.6,0.9,0.7,1.4C1758.3,1024.3,1758.6,1024,1758.8,1023.7z"/>
            <path class="st3" d="M1766.7,1031.4c-0.2-0.2-0.5-0.4-0.9-0.5c-2.1-0.7-3.6,1.4-3.2,3.1c0.6,0.3,1.2,0.9,1.3,1.6
              c0.1,0,0.2,0.1,0.3,0.1c1,0.4,1.9,0,2.5-0.6C1765.3,1034.4,1765.2,1032.1,1766.7,1031.4z"/>
            <path class="st3" d="M1765.2,1013.9c-0.2-0.5-0.1-1,0-1.4c-2.9-0.7-4.4,3.8-1.4,4.8c1.6,0.5,2.8-0.5,3.1-1.7
              C1766.1,1015.4,1765.4,1014.8,1765.2,1013.9z"/>
            <path class="st3" d="M1741.8,1016.6c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0.5-0.5,0.9-0.9,1.2v3.7
              C1743.3,1022.3,1744.9,1017.6,1741.8,1016.6z"/>
            <path class="st3" d="M1772.4,1023.7c-0.6,0-1.2-0.1-1.9-0.1c-1.3-0.1-2.6,1-2.7,2.4c0,1.4,1,2.6,2.4,2.7c0.6,0,1.2,0.1,1.9,0.1
              c1.3,0.1,2.6-1,2.7-2.4C1774.8,1025,1773.8,1023.8,1772.4,1023.7z"/>
            <path class="st3" d="M1753.5,1034.4c0,0.4-0.1,0.8-0.4,1.2c0.7,0.5,1.7,0.6,2.5,0.2c1.2-0.7,1.8-2.1,1.1-3.4
              c-0.1-0.2-0.2-0.4-0.3-0.7c-0.6-1.2-2.2-1.8-3.4-1.1c-0.6,0.3-1.1,0.9-1.3,1.5C1752.8,1032.5,1753.5,1033.3,1753.5,1034.4z"/>
            <path class="st3" d="M1760.8,1006.5c0.7,0.1,1.5,0,2-0.6c0.4-0.4,0.8-0.8,1.2-1.2c0.9-0.9,1.2-2.5,0.2-3.6
              c-0.9-0.9-2.6-1.2-3.6-0.2c-0.4,0.4-0.8,0.8-1.2,1.2c-0.2,0.2-0.3,0.4-0.4,0.6C1761.2,1002.8,1761.7,1005.1,1760.8,1006.5z"/>
            <path class="st3" d="M1749,1040.6c-0.3-0.4-0.7-0.8-1.3-1.1c-1-0.4-1.9-0.8-2.9-1.2c-1.2-0.5-2.8,0.4-3.2,1.6
              c-0.4,1.4,0.3,2.6,1.6,3.2c1,0.4,1.9,0.8,2.9,1.2c1.2,0.5,2.8-0.3,3.2-1.5C1748.9,1042.2,1748.8,1041.3,1749,1040.6z"/>
            <path class="st3" d="M1757.7,1044.7c-1.4-0.4-2.6,0.3-3.2,1.6c-0.6,1.3-0.7,2.9-0.2,4.2c0.4,1.3,1.6,2.3,3,2
              c1.2-0.3,2.4-1.6,2-3c0-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.4,0.2-0.4
              C1759.8,1046.7,1758.9,1045.1,1757.7,1044.7z M1759,1048.4c0,0.1,0,0.1,0,0.2C1759,1048.6,1759,1048.5,1759,1048.4z
              M1759,1048.8c0,0.1,0,0.2,0,0.3C1759,1048.9,1759,1048.9,1759,1048.8z"/>
            <path class="st3" d="M1767.6,1042.6c-3.1-1-4.7,3.7-1.6,4.8C1769.1,1048.4,1770.7,1043.7,1767.6,1042.6z"/>
            <path class="st3" d="M1745.4,1046.6c-3.1-1-4.7,3.7-1.6,4.8C1746.8,1052.4,1748.4,1047.6,1745.4,1046.6z"/>
            <path class="st3" d="M1773.8,1017.7c3.1,1,4.7-3.7,1.6-4.8C1772.3,1011.9,1770.7,1016.7,1773.8,1017.7z"/>
            <path class="st3" d="M1780.8,1037.3c-1.9-0.6-3.2,0.9-3.2,2.4c0.6,0.4,0.9,1.2,0.8,1.9c0.2,0.2,0.5,0.4,0.9,0.5
              C1782.2,1043.1,1783.9,1038.3,1780.8,1037.3z"/>
            <path class="st3" d="M1745.7,989.7c-3.1-1-4.7,3.7-1.6,4.8C1747.2,995.5,1748.8,990.7,1745.7,989.7z"/>
            <path class="st3" d="M1778.4,1012.2C1778.4,1012.2,1778.3,1012.2,1778.4,1012.2c-0.8,1.2-0.1,3.2,1.7,3.1
              c2.6-0.2,2.2-4.2-0.3-4c-0.1,0-0.1,0-0.2,0C1779.4,1011.8,1779,1012.2,1778.4,1012.2z"/>
            <path class="st3" d="M1782.8,1027.4c2.6-0.2,2.2-4.2-0.3-4c-0.1,0-0.2,0-0.3,0.1c-0.2,0.6-0.7,1.1-1.4,1.4
              C1780.5,1026,1781.2,1027.5,1782.8,1027.4z"/>
            <path class="st3" d="M1789.3,1016.4c-0.3-0.5-0.9-0.9-1.8-0.8c-2.6,0.2-2.2,4.2,0.3,4c0.9-0.1,1.5-0.7,1.7-1.3
              c-0.1-0.2-0.2-0.5-0.3-0.7C1789.1,1017.2,1789.1,1016.8,1789.3,1016.4z"/>
            <path class="st3" d="M1798.8,1022.8c-0.2-0.7-0.7-1.3-1.6-1.4c-0.2,0-0.3,0-0.5,0c-2.5,0.3-2.1,4.2,0.4,4
              c0.2,0,0.4-0.1,0.5-0.1C1797.2,1024.3,1797.5,1022.9,1798.8,1022.8z"/>
            <path class="st3" d="M1790.9,1008.1c0.1,0.8-0.3,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0c-0.1,1.1,0.6,2.4,2.1,2.3
              C1793.7,1011.9,1793.4,1008,1790.9,1008.1z"/>
            <path class="st3" d="M1774.9,1018.5c-2.6,0.2-2.2,4.2,0.3,4C1777.8,1022.3,1777.4,1018.3,1774.9,1018.5z"/>
            <path class="st3" d="M1799.1,1018.4c0.1,0,0.2,0,0.2-0.1c0.5-0.2,0.9-0.4,1.4-0.5c1-0.4,1.6-1.6,1.2-2.6
              c-0.1-0.3-0.3-0.5-0.5-0.7c-0.5,0.2-1.2,0.2-1.7-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1,0c-0.5,0.2-0.9,0.4-1.4,0.5
              c-1,0.4-1.6,1.6-1.2,2.6c0.2,0.5,0.6,0.9,1,1.1c0.2,0,0.4,0,0.6,0C1798.7,1018.2,1798.9,1018.3,1799.1,1018.4z"/>
            <path class="st3" d="M1789.2,1024.5c-0.6,0-1.2,0.2-1.6,0.7c-0.7,0.9-0.6,2.1,0.2,2.8c0.1,0.1,0.3,0.2,0.4,0.4
              c0.8,0.7,2.2,0.6,2.8-0.2c0.5-0.6,0.6-1.5,0.3-2.2c-0.1,0.1-0.3,0.1-0.5,0.1C1789.7,1026.2,1789.2,1025.3,1789.2,1024.5z"/>
            <path class="st3" d="M1774.7,1032.3c0.2,0.5,0.4,0.9,0.5,1.4c0.4,1,1.6,1.6,2.6,1.2c1-0.4,1.6-1.5,1.2-2.6
              c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1-1.6-1.6-2.6-1.2C1774.9,1030.2,1774.3,1031.3,1774.7,1032.3z"/>
            <path class="st3" d="M1784.3,1003.5c-0.5,0.1-0.9-0.1-1.2-0.4c-0.1,0.8,0.3,1.7,1.1,2c1,0.4,2.2,0.1,2.7-0.9
              c0.2-0.4,0.3-0.8,0.5-1.3c0.4-1,0.1-2.2-0.9-2.7c-0.7-0.3-1.5-0.2-2,0.2C1786,1000.5,1786.1,1003.3,1784.3,1003.5z"/>
            <path class="st3" d="M1788.5,1035.2c-0.1-1.1-1-1.8-2.2-1.8c-0.8,0-1.6,0-2.5,0c-1,0-2,1.2-1.8,2.2c0.1,1.1,1,1.8,2.2,1.8
              c0.8,0,1.6,0,2.5,0C1787.8,1037.3,1788.7,1036.2,1788.5,1035.2z"/>
            <path class="st3" d="M1797.6,1036.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c0-1-1.2-2-2.2-1.8
              c-1.2,0.2-1.8,1-1.8,2.2c0,1.1,0.4,2.3,1.2,3.2c0.7,0.8,1.9,1.2,2.8,0.5c0.8-0.6,1.2-1.9,0.5-2.8
              C1797.8,1037,1797.7,1036.8,1797.6,1036.7z M1797.4,1036.2c0,0,0,0.1,0,0.2C1797.4,1036.4,1797.4,1036.3,1797.4,1036.2z
              M1797.6,1036.7c-0.1-0.1-0.1-0.1-0.1-0.2C1797.5,1036.6,1797.6,1036.7,1797.6,1036.7z"/>
            <path class="st3" d="M1802.9,1029.5c0.7,0.9,0.5,2.5-1,2.7c-1.1,0.2-1.7-0.7-1.8-1.5c-0.3,1.2,0.4,2.7,2,2.6
              C1804.3,1033.1,1804.4,1030.3,1802.9,1029.5z"/>
            <path class="st3" d="M1786.9,1039.2c-2.6,0.2-2.2,4.2,0.3,4C1789.8,1043,1789.5,1039,1786.9,1039.2z"/>
            <path class="st3" d="M1777.5,1039.6c-0.3-0.2-0.8-0.4-1.3-0.3c-2.6,0.2-2.2,4.2,0.3,4c1.1-0.1,1.7-0.9,1.7-1.7
              C1778.4,1040.8,1778.1,1040.1,1777.5,1039.6z"/>
            <path class="st3" d="M1797.9,1005.3c-2.2,0.5-1.9,4.1,0.6,3.9c1.3-0.1,1.8-1.1,1.8-2.1c-0.2,0.2-0.5,0.3-0.8,0.3
              C1798.1,1007.6,1797.5,1006.3,1797.9,1005.3z"/>
            <path class="st3" d="M1769.3,1010.5c-0.3-0.2-0.6-0.2-1.1-0.2c-0.6,0.1-1.1,0.3-1.4,0.8c0.6-0.2,1.1-0.3,1.7-0.5
              C1768.8,1010.5,1769,1010.4,1769.3,1010.5z"/>
            <path class="st3" d="M1767.6,1031.3c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.2c-1.5,0.7-1.4,3,0,3.7c0.3,0.1,0.6,0.2,1,0.2
              c1.1-0.1,1.6-0.8,1.7-1.6C1768.2,1033.4,1767.6,1032.3,1767.6,1031.3z"/>
            <path class="st3" d="M1809.7,1021.3c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0.6-0.4,1.1-1,1.3c-0.3,1.2,0.5,2.7,2,2.5
              C1812.7,1025,1812.3,1021,1809.7,1021.3z"/>
            <path class="st3" d="M1758.8,1023.7c0.2,0.9,0.9,1.7,2.1,1.6c2.6-0.2,2.2-4.2-0.3-4c-0.4,0-0.7,0.2-1,0.4
              C1758.9,1022.2,1758.7,1023,1758.8,1023.7z"/>
            <path class="st3" d="M1770.1,1001.5c1.9-0.7,1.5-4.1-0.9-3.9c-1.4,0.1-1.9,1.3-1.7,2.4c0.1,0,0.3,0,0.5,0
              C1769.1,1000,1769.8,1000.7,1770.1,1001.5z"/>
            <path class="st3" d="M1816.9,1019.9c-2.1,0.3-1.6,3.6,0.5,3.3C1819.4,1022.9,1819,1019.6,1816.9,1019.9z"/>
            <path class="st3" d="M1820.8,1032.6c1.1-0.9,0.5-3.2-1.3-2.9c-1.9,0.3-1.7,3.1,0,3.3c0.3-0.2,0.7-0.4,1.2-0.4
              C1820.8,1032.6,1820.8,1032.6,1820.8,1032.6z"/>
            <path class="st3" d="M1823.4,1023c-2.1,0.3-1.6,3.6,0.5,3.3C1826,1026,1825.5,1022.8,1823.4,1023z"/>
            <path class="st3" d="M1832.6,1029c-0.5-0.4-0.7-1-0.6-1.6c-0.2-0.1-0.4-0.1-0.7,0c-2.1,0.3-1.6,3.6,0.5,3.3
              c0.3,0,0.5-0.1,0.6-0.2C1832.3,1029.9,1832.4,1029.4,1832.6,1029z"/>
            <path class="st3" d="M1824.5,1018.9c0.2,0.7,0.8,1.3,1.7,1.1c2.1-0.3,1.6-3.6-0.5-3.3c0,0-0.1,0-0.1,0
              C1825.9,1017.7,1825.5,1018.7,1824.5,1018.9z"/>
            <path class="st3" d="M1813.1,1026c-2.1,0.3-1.6,3.6,0.5,3.3C1815.7,1029,1815.2,1025.7,1813.1,1026z"/>
            <path class="st3" d="M1833,1021.3c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.4-0.9,2.2c0.4,0.8,1.3,1.2,2.2,0.9
              c0.4-0.2,0.7-0.3,1.1-0.5c0.8-0.3,1.3-1.4,0.9-2.2c-0.2-0.5-0.6-0.8-1.1-0.9c-0.2,0.1-0.5,0.2-0.9,0.2
              C1833.2,1021.3,1833.1,1021.3,1833,1021.3z"/>
            <path class="st3" d="M1824.2,1033.3c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3c0.5-0.7,0.4-1.8-0.3-2.3
              c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3C1823.3,1031.7,1823.4,1032.7,1824.2,1033.3z"/>
            <path class="st3" d="M1816.7,1036.1c-0.3-0.8-1.4-1.3-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
              c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C1817,1036.8,1816.8,1036.5,1816.7,1036.1z"/>
            <path class="st3" d="M1820.2,1014.5c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
              c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1C1819,1013.2,1819.3,1014.2,1820.2,1014.5z"/>
            <path class="st3" d="M1825.1,1039.1c-0.2-0.9-0.9-1.5-1.9-1.4c-0.7,0-1.3,0.1-2,0.1c-0.9,0.1-1.6,1-1.4,1.9
              c0.2,0.9,0.9,1.5,1.9,1.4c0.7,0,1.3-0.1,2-0.1C1824.6,1040.9,1825.3,1039.9,1825.1,1039.1z"/>
            <path class="st3" d="M1831.7,1039.9c-0.8-0.5-0.9-1.4-0.6-2.1c-0.2,0-0.3,0-0.5,0c-0.9,0.2-1.4,0.9-1.4,1.9
              c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3C1832.5,1040.3,1832.1,1040.2,1831.7,1039.9z"/>
            <path class="st3" d="M1835.8,1033.7c-1.6,0.2-1.7,2.3-0.7,3c0.3,0,0.6,0.1,0.8,0.1c0.1,0,0.3,0.1,0.4,0.1
              C1838.3,1036.6,1837.8,1033.4,1835.8,1033.7z"/>
            <path class="st3" d="M1823.9,1042.5c-2.1,0.3-1.6,3.6,0.5,3.3C1826.5,1045.5,1826,1042.2,1823.9,1042.5z"/>
            <path class="st3" d="M1815.2,1043.1c-2.1,0.3-1.6,3.6,0.5,3.3C1817.7,1046,1817.3,1042.8,1815.2,1043.1z"/>
            <path class="st3" d="M1832.1,1017.3c2.1-0.3,1.6-3.6-0.5-3.3C1829.6,1014.4,1830,1017.6,1832.1,1017.3z"/>
            <path class="st3" d="M1807.1,1020.6c0,0,0,0.1,0.1,0.1C1807.2,1020.7,1807.1,1020.6,1807.1,1020.6z"/>
            <path class="st3" d="M1807,1020.4c0,0.1,0,0.1,0,0.1C1807.1,1020.4,1807,1020.4,1807,1020.4z"/>
            <path class="st3" d="M1809.1,1021.4c0.1-1-0.5-2.1-1.8-1.9c-0.1,0-0.2,0-0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3
              c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.5,0.5,0.3,1.3,0,1.8c0.1,0,0.2,0,0.4,0
              c0.1,0,0.2,0,0.3-0.1C1808.7,1022.5,1809,1022,1809.1,1021.4z"/>
            <path class="st3" d="M1807.5,1036.8c-2.1,0.3-1.6,3.6,0.5,3.3C1810.1,1039.8,1809.6,1036.5,1807.5,1036.8z"/>
            <path class="st3" d="M1842.4,1030c2.1-0.3,1.6-3.6-0.5-3.3C1839.8,1027,1840.3,1030.3,1842.4,1030z"/>
            <path class="st3" d="M1801.9,1032.3c1.5-0.2,1.7-1.8,1-2.7c-0.3-0.4-0.8-0.6-1.4-0.5c-1,0.1-1.4,1-1.4,1.7
              C1800.2,1031.6,1800.8,1032.4,1801.9,1032.3z"/>
            <path class="st3" d="M1806.8,1011.8c-0.1,0-0.1,0-0.2,0c0.3,0.4,0.8,0.6,1.4,0.5c2.1-0.3,1.7-3.4-0.4-3.3
              C1808.5,1009.9,1808.2,1011.7,1806.8,1011.8z"/>
            <path class="st3" d="M1852,1033.3c1.9,1.3,4-1.6,2.1-3C1852.1,1029,1850,1032,1852,1033.3z"/>
            <path class="st3" d="M1848.9,1040.3c-1.9-1.3-4,1.6-2.1,3C1848.7,1044.6,1850.8,1041.6,1848.9,1040.3z"/>
            <path class="st3" d="M1856.9,1037.8c-1.9-1.3-4,1.6-2.1,3C1856.8,1042.1,1858.9,1039.2,1856.9,1037.8z"/>
            <path class="st3" d="M1860,1047.3c-1.9-1.3-4,1.6-2.1,3c1.2,0.8,2.4,0,2.7-1c0,0,0-0.1,0-0.1
              C1860.9,1048.4,1860.7,1047.7,1860,1047.3z"/>
            <path class="st3" d="M1863.6,1034.5c-1.9-1.3-4,1.6-2.1,3C1863.4,1038.9,1865.5,1035.9,1863.6,1034.5z"/>
            <path class="st3" d="M1844.3,1035.4c1.9,1.3,4-1.6,2.1-3C1844.5,1031.1,1842.4,1034.1,1844.3,1035.4z"/>
            <path class="st3" d="M1863.6,1046.6c0.4,0.1,0.8,0.3,1.3,0.4c0.9,0.3,2-0.2,2.3-1.1c0.3-0.9-0.1-2-1.1-2.3
              c-0.4-0.1-0.8-0.3-1.3-0.4c-0.9-0.3-2,0.2-2.3,1.1C1862.2,1045.2,1862.6,1046.3,1863.6,1046.6z"/>
            <path class="st3" d="M1853.5,1046.5c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.1-1.7-2.1-1.5c-1,0.2-1.7,1.1-1.5,2.1
              c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.4c0.2-0.2,0.5-0.3,0.9-0.3c1-0.1,1.6,0.4,1.9,1
              C1853.4,1047.8,1853.7,1047.2,1853.5,1046.5z"/>
            <path class="st3" d="M1841.2,1044.5c0.1-0.4,0.3-0.8,0.4-1.3c0.1-0.2,0.1-0.3,0.1-0.5c-0.2-0.4-0.4-0.9-0.5-1.3
              c-0.2-0.2-0.4-0.4-0.7-0.5c-0.9-0.3-2,0.1-2.3,1.1c-0.1,0.4-0.3,0.8-0.4,1.3c-0.3,0.9,0.2,2,1.1,2.3
              C1839.8,1045.9,1840.9,1045.5,1841.2,1044.5z"/>
            <path class="st3" d="M1863.1,1029.4c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.4-1.5,0.9-2.4c-0.4-0.8-1.5-1.4-2.4-0.9
              c-0.4,0.2-0.7,0.4-1.1,0.6c-0.8,0.4-1.4,1.5-0.9,2.4C1861.2,1029.3,1862.2,1029.9,1863.1,1029.4z"/>
            <path class="st3" d="M1845.8,1049.5c-0.6-0.5-1.1-1-1.7-1.4c-0.7-0.6-2-0.3-2.5,0.4c-0.6,0.9-0.3,1.9,0.4,2.5
              c0.6,0.5,1.1,1,1.7,1.4c0.7,0.6,2,0.3,2.5-0.4C1846.8,1051.1,1846.6,1050.2,1845.8,1049.5z"/>
            <path class="st3" d="M1849.4,1057.6c-0.5-0.3-0.8-0.6-0.9-1.1c-0.3,0.6-0.4,1.2-0.4,1.9c0,0.9,0.6,1.9,1.7,2c0.9,0,2-0.6,2-1.7
              c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.4,0.4-1,0.2-1.6
              C1852,1057.3,1850.8,1058.4,1849.4,1057.6z M1851.6,1058.4c0-0.1,0-0.1,0-0.2C1851.6,1058.3,1851.6,1058.3,1851.6,1058.4z
              M1851.7,1058.1c0,0,0-0.1,0.1-0.2C1851.7,1058,1851.7,1058,1851.7,1058.1z"/>
            <path class="st3" d="M1856.2,1056.6c-0.3,0.7-0.2,1.5,0.6,2.1c0.6,0.4,1.3,0.4,1.8,0.1c-0.1,0-0.2,0.1-0.3,0.1
              C1856.8,1059.1,1856,1057.8,1856.2,1056.6z"/>
            <path class="st3" d="M1842.7,1053.9c-1.9-1.3-4,1.6-2.1,3C1842.5,1058.2,1844.6,1055.2,1842.7,1053.9z"/>
            <path class="st3" d="M1835.2,1047.7c-1.9-1.3-4,1.6-2.1,3C1835.1,1052,1837.2,1049.1,1835.2,1047.7z"/>
            <path class="st3" d="M1869.4,1038.9c0.4-0.3,1-0.5,1.6-0.3c0.2-0.6,0-1.4-0.7-1.8c-0.6-0.4-1.2-0.4-1.7-0.2
              C1869.4,1037.1,1869.6,1038.1,1869.4,1038.9z"/>
            <path class="st3" d="M1844.5,1025.8c0.8,0.6,1.7,0.3,2.3-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.6,0.1-1.2,0.6-1.6
              c-0.1-0.2-0.3-0.4-0.6-0.6C1844.7,1021.5,1842.6,1024.5,1844.5,1025.8z"/>
            <path class="st3" d="M1832.2,1038c0-0.6,0.5-1.1,1-1.4c-0.9-0.2-1.8,0.4-2.1,1.2c-0.3,0.7-0.2,1.5,0.6,2.1
              c0.4,0.3,0.8,0.4,1.2,0.3c0.3,0,0.6-0.2,0.9-0.4c0,0-0.1,0-0.1,0C1832.8,1039.7,1832.1,1039,1832.2,1038z"/>
            <path class="st3" d="M1868.7,1054.5c-1.8-0.8-3.5,1.9-1.7,3.2c1.6,1.2,3.4-0.9,2.7-2.3
              C1869.2,1055.2,1868.9,1054.9,1868.7,1054.5z"/>
            <path class="st3" d="M1834.8,1026.1c-1.3-0.9-2.6,0.1-2.8,1.3c-0.1,0.5,0.1,1.1,0.6,1.6c0.3-0.5,0.8-0.8,1.5-0.7
              c0.5,0,0.8,0.2,1,0.5C1835.7,1028,1835.8,1026.8,1834.8,1026.1z"/>
            <path class="st3" d="M1852.6,1017.6c1.9,1.3,4-1.6,2.1-3C1852.7,1013.3,1850.6,1016.3,1852.6,1017.6z"/>
            <path class="st3" d="M1871,1059.6c2.3,1.2,4.2-2.3,1.9-3.5C1870.6,1054.9,1868.7,1058.4,1871,1059.6z"/>
            <path class="st3" d="M1868.6,1067.7c-0.9-0.5-1.7-0.2-2.3,0.3h2.7C1868.9,1067.9,1868.8,1067.8,1868.6,1067.7z"/>
            <path class="st3" d="M1877.1,1063.9c-2.3-1.2-4.2,2.3-1.9,3.5C1877.5,1068.6,1879.4,1065.1,1877.1,1063.9z"/>
            <path class="st3" d="M1883.9,1059.4c-2.3-1.2-4.2,2.3-1.9,3.5C1884.3,1064.1,1886.2,1060.6,1883.9,1059.4z"/>
            <path class="st3" d="M1865,1059.6c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.2-4.2,2.3-1.9,3.5c0.6,0.3,1.2,0.3,1.7,0.1
              C1863.4,1062.3,1863.4,1060.1,1865,1059.6z"/>
            <path class="st3" d="M1884.2,1050.6c0.5,1-0.1,2.2-1.1,2.6c-0.4,0.2-0.9,0.4-1.3,0.6c-0.3,0.1-0.6,0.2-0.9,0.1
              c0.6,0.3,1.3,0.3,1.8-0.1c0.4-0.3,0.8-0.5,1.1-0.8c0.9-0.6,1.3-1.8,0.7-2.8c-0.5-0.8-1.6-1.2-2.5-0.8
              C1882.9,1049.3,1883.8,1049.8,1884.2,1050.6z"/>
            <path class="st3" d="M1891.6,1060.9c-2.3-1.2-4.2,2.3-1.9,3.5C1892,1065.7,1893.9,1062.1,1891.6,1060.9z"/>
            <path class="st3" d="M1864.4,1049.5c0.1,1-0.4,2-1.6,2.1c-1,0.1-1.7-0.4-2-1.1c-0.1,0.7,0.2,1.5,1,1.9
              C1863.8,1053.5,1865.5,1051,1864.4,1049.5z"/>
            <path class="st3" d="M1851.6,1066.1c-1.6-0.8-3,0.6-2.9,2h1.5c0.2-0.1,0.5-0.2,0.8-0.3c0.5-0.1,0.9,0,1.3,0.3h0.4
              C1852.7,1067.3,1852.4,1066.5,1851.6,1066.1z"/>
            <path class="st3" d="M1849.4,1057.6c1.4,0.8,2.7-0.3,2.9-1.6c0.1-0.7-0.1-1.5-1-2c-1.8-0.9-3.3,1-2.8,2.5
              C1848.6,1057,1848.9,1057.4,1849.4,1057.6z"/>
            <path class="st3" d="M1869.4,1038.9c0,0,0,0.1,0,0.1c-0.2,0.4-0.3,0.8-0.5,1.3c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0.5,0.4,1.1,1,1.4
              c2.3,1.2,4.2-2.3,1.9-3.5c-0.1-0.1-0.2-0.1-0.4-0.2C1870.4,1038.5,1869.8,1038.6,1869.4,1038.9z"/>
            <path class="st3" d="M1833,1021.3c0.1,0,0.2,0,0.3,0.1c0.3,0,0.6,0,0.9-0.2c1.2-0.7,1.1-3-0.6-3.1
              C1831.5,1017.9,1831.2,1020.9,1833,1021.3z"/>
            <path class="st3" d="M1834.1,1028.2c-0.7-0.1-1.2,0.3-1.5,0.7c-0.2,0.4-0.3,1-0.2,1.4c0.2,0.6,0.6,1,1.4,1.1
              c1.7,0.1,2.2-1.9,1.3-2.8C1834.9,1028.5,1834.5,1028.3,1834.1,1028.2z"/>
            <path class="st3" d="M1839,1025.8c2.1,0.2,2.4-3.1,0.3-3.3C1837.1,1022.4,1836.9,1025.7,1839,1025.8z"/>
            <path class="st3" d="M1846,1028.5c-2.1-0.2-2.4,3.1-0.3,3.3C1847.9,1031.9,1848.2,1028.7,1846,1028.5z"/>
            <path class="st3" d="M1842.6,1020.2c2.1,0.2,2.4-3.1,0.3-3.3C1840.8,1016.8,1840.5,1020.1,1842.6,1020.2z"/>
            <path class="st3" d="M1828.3,1026.5c2.1,0.2,2.4-3.1,0.3-3.3C1826.4,1023.1,1826.2,1026.4,1828.3,1026.5z"/>
            <path class="st3" d="M1846.6,1025.1c0,0.2,0.1,0.4,0.2,0.5c0.4,0.6,1,0.9,1.7,0.8c0.4-0.1,0.8-0.2,1.2-0.2
              c0.8-0.2,1.5-1.1,1.3-1.9c-0.2-0.9-1-1.5-1.9-1.3c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0.1-0.5,0.2-0.7,0.3
              C1846.7,1023.8,1846.4,1024.4,1846.6,1025.1z"/>
            <path class="st3" d="M1837.8,1032.7c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.7,1.7,0.8,2.3,0.2c0.7-0.6,0.8-1.6,0.2-2.3
              c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2C1837.3,1031,1837.2,1032,1837.8,1032.7z"/>
            <path class="st3" d="M1830.1,1035c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9
              c0.1,0.4,0.2,0.8,0.2,1.2c0.2,0.8,1.1,1.5,1.9,1.3C1829.6,1036.8,1830.3,1035.9,1830.1,1035z"/>
            <path class="st3" d="M1837.9,1013.5c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1c0.5-0.7,0.4-1.8-0.4-2.3
              c-0.7-0.5-1.8-0.4-2.3,0.4c-0.2,0.3-0.4,0.6-0.6,1C1837,1012,1837.1,1013,1837.9,1013.5z"/>
            <path class="st3" d="M1835.1,1036.7c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3,0-0.5,0-0.8,0.1c-0.5,0.2-1,0.8-1,1.4c0,1,0.6,1.6,1.5,1.8
              c0,0,0.1,0,0.1,0c0.6,0.1,1.3,0.2,1.9,0.3c0.9,0.1,1.7-0.7,1.8-1.5c0-0.8-0.4-1.4-1.1-1.7c-0.1,0-0.3-0.1-0.4-0.1
              C1835.7,1036.8,1835.4,1036.8,1835.1,1036.7z"/>
            <path class="st3" d="M1841.8,1042.8c0.4,0.7,1.4,1.2,2.2,0.8c0.7-0.4,1.2-1.4,0.8-2.2c0-0.1-0.1-0.2-0.2-0.3
              c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.9-0.7-1.7-1.5-1.8c-1,0-1.6,0.6-1.8,1.5
              c-0.1,0.4-0.1,0.9,0,1.3C1841.4,1041.9,1841.5,1042.3,1841.8,1042.8C1841.8,1042.7,1841.8,1042.8,1841.8,1042.8z
              M1844.6,1041.1c0-0.1-0.1-0.1-0.1-0.2C1844.6,1041,1844.6,1041,1844.6,1041.1z M1844.5,1040.7c0,0,0,0.1,0,0.2
              C1844.5,1040.8,1844.5,1040.7,1844.5,1040.7z"/>
            <path class="st3" d="M1848.8,1038.9c0.7,0.1,1.2-0.3,1.5-0.8c-0.5-0.6-0.7-1.5-0.4-2.2c-0.2-0.2-0.5-0.3-0.8-0.3
              C1846.9,1035.5,1846.7,1038.8,1848.8,1038.9z"/>
            <path class="st3" d="M1835.6,1041.7c-2.1-0.2-2.4,3.1-0.3,3.3C1837.4,1045.1,1837.7,1041.9,1835.6,1041.7z"/>
            <path class="st3" d="M1826.9,1040.3c-2.1-0.2-2.4,3.1-0.3,3.3C1828.7,1043.8,1829,1040.5,1826.9,1040.3z"/>
            <path class="st3" d="M1849,1018.9c2.1,0.2,2.4-3.1,0.3-3.3C1847.1,1015.4,1846.9,1018.7,1849,1018.9z"/>
            <path class="st3" d="M1824,1018.9c0.2,0,0.3,0,0.5,0c1-0.2,1.4-1.2,1.2-2.1c-0.2-0.6-0.6-1.1-1.4-1.1
              C1822.1,1015.5,1821.9,1018.8,1824,1018.9z"/>
            <path class="st3" d="M1820.8,1032.6c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.1-1.2,0.4c-0.9,0.8-0.7,2.8,0.9,2.9
              C1822.6,1036,1822.8,1032.9,1820.8,1032.6z"/>
            <path class="st3" d="M1856.3,1033.4c2.1,0.2,2.4-3.1,0.3-3.3C1854.4,1030,1854.2,1033.3,1856.3,1033.4z"/>
            <path class="st3" d="M1816.5,1023.7c-2.1-0.2-2.4,3.1-0.3,3.3C1818.4,1027.1,1818.7,1023.8,1816.5,1023.7z"/>
            <path class="st3" d="M1826.5,1008.8c2.1,0.2,2.4-3.1,0.3-3.3C1824.6,1005.4,1824.4,1008.7,1826.5,1008.8z"/>
            <path class="st3" d="M1793,1001.1c-2,0.2-1.7,3.3,0.3,3.1C1795.3,1004,1795,1000.9,1793,1001.1z"/>
            <path class="st3" d="M1795.5,1013.6c2-0.2,1.7-3.3-0.3-3.1C1793.2,1010.7,1793.5,1013.8,1795.5,1013.6z"/>
            <path class="st3" d="M1797.9,1005.3c-0.3,1,0.3,2.3,1.6,2.1c0.3,0,0.6-0.2,0.8-0.3c1-0.8,0.6-3-1.1-2.8
              C1798.4,1004.4,1798,1004.8,1797.9,1005.3z"/>
            <path class="st3" d="M1806.6,1011.8c0.1,0,0.1,0,0.2,0c1.5-0.1,1.7-1.9,0.9-2.7c-0.3-0.3-0.7-0.4-1.2-0.4
              C1804.5,1008.9,1804.7,1011.8,1806.6,1011.8z"/>
            <path class="st3" d="M1801.9,1001.6c2-0.2,1.7-3.3-0.3-3.1C1799.6,998.7,1799.9,1001.8,1801.9,1001.6z"/>
            <path class="st3" d="M1790.9,1008.1c-0.1-0.8-0.7-1.5-1.7-1.4c-1.8,0.2-1.7,2.8-0.2,3.1c0.2,0,0.3,0,0.5,0
              C1790.6,1009.7,1791,1008.9,1790.9,1008.1z"/>
            <path class="st3" d="M1807.3,1003.4c-0.8,0.3-1.2,1.2-0.9,2c0.3,0.8,1.2,1.2,2,0.9c0.4-0.1,0.7-0.3,1.1-0.4
              c0.8-0.3,1.2-1.2,0.9-2c-0.3-0.8-1.2-1.2-2-0.9C1808,1003.1,1807.6,1003.3,1807.3,1003.4z"/>
            <path class="st3" d="M1799.8,1014.3c0.5,0.4,1.1,0.4,1.7,0.2c0.2-0.1,0.4-0.2,0.5-0.4c0.5-0.7,0.5-1.6-0.2-2.2
              c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.5-1.7-0.5-2.2,0.2c-0.5,0.7-0.5,1.6,0.2,2.2C1799.6,1014.1,1799.7,1014.2,1799.8,1014.3z"/>
            <path class="st3" d="M1792.2,1016.4c-0.3-0.8-1.2-1.2-2-0.9c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.3-0.2,0.7,0,1.1
              c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,1.2,1.2,2,0.9c0.8-0.3,1.2-1.2,0.9-2
              C1792.5,1017.1,1792.3,1016.8,1792.2,1016.4z"/>
            <path class="st3" d="M1796.4,996.1c0.7,0.3,1.8,0,2.1-0.8c0.1-0.3,0.3-0.7,0.4-1c0.3-0.8,0-1.7-0.8-2.1c-0.7-0.3-1.8,0-2.1,0.8
              c-0.1,0.3-0.3,0.7-0.4,1C1795.3,994.8,1795.6,995.8,1796.4,996.1z"/>
            <path class="st3" d="M1797.3,1021.4c0.5,0,0.9,0,1.4,0c0.8,0,1.5-0.9,1.4-1.7c-0.1-0.6-0.5-1-1-1.2c-0.2-0.1-0.5-0.2-0.7-0.2
              c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0-1.4,0c-0.8,0-1.5,0.9-1.4,1.7c0.1,0.9,0.8,1.4,1.7,1.4
              C1796.9,1021.4,1797.1,1021.4,1797.3,1021.4z"/>
            <path class="st3" d="M1807.4,1021c0,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.3,0-0.3
              c0-0.1,0-0.3-0.1-0.4c-0.2-0.6-1-1.1-1.6-1c-0.9,0.1-1.4,0.8-1.4,1.7c0,0.9,0.4,1.8,0.9,2.5c0.5,0.6,1.5,0.9,2.2,0.3
              c0.1-0.1,0.2-0.2,0.3-0.4C1807.7,1022.3,1807.9,1021.5,1807.4,1021z M1807.2,1020.7c0-0.1-0.1-0.1-0.1-0.1
              C1807.1,1020.6,1807.2,1020.7,1807.2,1020.7z M1807.1,1020.5c0,0,0-0.1,0-0.1C1807,1020.4,1807.1,1020.4,1807.1,1020.5z"/>
            <path class="st3" d="M1810.7,1018c2-0.2,1.7-3.3-0.3-3.1C1808.4,1015.1,1808.7,1018.2,1810.7,1018z"/>
            <path class="st3" d="M1798.9,1022.8c-1.4,0.1-1.7,1.5-1.1,2.4c0.3,0.4,0.7,0.8,1.4,0.7
              C1801.1,1025.7,1800.8,1022.6,1798.9,1022.8z"/>
            <path class="st3" d="M1790.5,1023c-0.9,0.1-1.4,0.8-1.4,1.5c0,0.8,0.6,1.7,1.7,1.6c0.2,0,0.3-0.1,0.5-0.1
              C1792.7,1025.3,1792.3,1022.8,1790.5,1023z"/>
            <path class="st3" d="M1807.6,999.2c2-0.2,1.7-3.3-0.3-3.1C1805.3,996.3,1805.6,999.4,1807.6,999.2z"/>
            <path class="st3" d="M1784.3,1003.5c1.9-0.2,1.7-2.9,0.1-3.1c-0.1,0-0.2,0-0.4,0c-1.5,0.1-1.7,1.8-0.9,2.7
              C1783.3,1003.3,1783.7,1003.5,1784.3,1003.5z"/>
            <path class="st3" d="M1783.8,1019.9c2-0.2,1.7-3.3-0.3-3.1C1781.5,1017,1781.8,1020.1,1783.8,1019.9z"/>
            <path class="st3" d="M1816.8,1011.7c2-0.2,1.7-3.3-0.3-3.1C1814.5,1008.7,1814.8,1011.9,1816.8,1011.7z"/>
            <path class="st3" d="M1778.3,1012.2C1778.3,1012.2,1778.4,1012.2,1778.3,1012.2c0.7-0.1,1.1-0.4,1.3-0.9c0.4-1-0.2-2.4-1.5-2.2
              C1776.1,1009.3,1776.4,1012.3,1778.3,1012.2z"/>
            <path class="st3" d="M1784.9,993.6c2-0.2,1.7-3.3-0.3-3.1C1782.6,990.7,1782.9,993.8,1784.9,993.6z"/>
            <path class="st3" d="M1745.6,1010.1c3.1,0,3.1-4.7,0-4.8C1742.6,1005.3,1742.6,1010.1,1745.6,1010.1z"/>
            <path class="st3" d="M1747.5,1024.6c3.1,0,3.1-4.7,0-4.8C1744.5,1019.9,1744.5,1024.6,1747.5,1024.6z"/>
            <path class="st3" d="M1752.3,1014.6c0.3,0.7,1,1.3,2.1,1.3c3.1,0,3.1-4.7,0-4.8c-0.8,0-1.4,0.4-1.8,0.9
              C1752,1012.7,1751.9,1013.8,1752.3,1014.6z"/>
            <path class="st3" d="M1764.8,1023.7c3.1,0,3.1-4.7,0-4.8C1761.7,1018.9,1761.7,1023.7,1764.8,1023.7z"/>
            <path class="st3" d="M1759,1007.4c0.9,0,1.5-0.4,1.9-0.9c0.9-1.4,0.4-3.7-1.7-3.8c0,0-0.1,0-0.1,0
              C1755.9,1002.7,1755.9,1007.4,1759,1007.4z"/>
            <path class="st3" d="M1741.2,1016.5c0.3-1,0-2.2-0.9-2.8v4.1C1740.8,1017.4,1741.1,1017,1741.2,1016.5z"/>
            <path class="st3" d="M1769.3,1010.5c-0.3,0-0.5,0-0.8,0.1c-0.6,0.2-1.1,0.3-1.7,0.5c-0.7,0.2-1.4,0.8-1.6,1.5
              c-0.2,0.4-0.2,0.9,0,1.4c0.3,0.9,1,1.5,1.8,1.7c0.4,0.1,0.7,0.1,1.1,0c0.6-0.2,1.1-0.3,1.7-0.5c1.2-0.3,2.1-1.7,1.7-2.9
              C1771.1,1011.2,1770.3,1010.5,1769.3,1010.5z"/>
            <path class="st3" d="M1756.7,1022.5c-0.5-0.5-1.3-0.8-2.1-0.7c-0.5,0.1-1,0.3-1.3,0.7c-0.9,0.9-0.9,2.4,0,3.4
              c0.2,0.2,0.3,0.3,0.5,0.5c0.9,0.9,2.5,0.9,3.4,0c0.5-0.6,0.7-1.3,0.7-2c-0.1-0.5-0.3-1-0.7-1.4
              C1757.1,1022.9,1756.9,1022.7,1756.7,1022.5z"/>
            <path class="st3" d="M1740.9,1033c1.2-0.4,2-1.6,1.7-2.9c-0.2-0.6-0.3-1.1-0.5-1.7c-0.2-0.8-0.9-1.5-1.8-1.7v6.4
              C1740.5,1033.1,1740.7,1033.1,1740.9,1033z"/>
            <path class="st3" d="M1751.4,998.3c1.1,0.6,2.6,0.3,3.3-0.8c0.2-0.5,0.5-1,0.7-1.4c0.6-1.1,0.3-2.6-0.8-3.3
              c-1.1-0.6-2.6-0.3-3.3,0.8c-0.2,0.5-0.5,1-0.7,1.4C1750,996.2,1750.2,997.7,1751.4,998.3z"/>
            <path class="st3" d="M1748.3,1036.6c1,0.1,1.9,0.2,2.9,0.2c0.8,0.1,1.6-0.5,2-1.2c0.2-0.4,0.4-0.8,0.4-1.2
              c0-1.1-0.7-1.9-1.7-2.2c-0.2-0.1-0.5-0.1-0.7-0.2c-1-0.1-1.9-0.2-2.9-0.2c-1.2-0.1-2.4,1.2-2.4,2.4
              C1745.9,1035.6,1746.9,1036.5,1748.3,1036.6z"/>
            <path class="st3" d="M1762.6,1034c-0.3-0.2-0.7-0.3-1-0.2c-1.4,0.1-2.3,1-2.4,2.4c-0.1,1.3,0.3,2.8,1,3.9
              c0.7,1,2.1,1.6,3.2,0.9c1-0.6,1.6-2.2,0.9-3.2c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3
              c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6C1763.8,1034.9,1763.2,1034.3,1762.6,1034z M1764.1,1037.2
              c-0.1-0.1-0.1-0.2-0.1-0.2C1764,1037.1,1764.1,1037.1,1764.1,1037.2z M1764,1036.8c0,0,0-0.1,0-0.2
              C1763.9,1036.6,1763.9,1036.7,1764,1036.8z"/>
            <path class="st3" d="M1769.9,1028.9c-1.5,0-2.3,1.2-2.3,2.4c0,1.1,0.6,2.1,1.9,2.3c0.1,0,0.3,0,0.4,0
              C1772.9,1033.6,1772.9,1028.9,1769.9,1028.9z"/>
            <path class="st3" d="M1751.2,1039.1c-1.2,0-1.9,0.7-2.2,1.5c-0.2,0.7-0.1,1.6,0.3,2.2c0.4,0.6,1,1,1.9,1
              C1754.2,1043.8,1754.2,1039.1,1751.2,1039.1z"/>
            <path class="st3" d="M1740.3,1038.9v3.2C1741,1041.2,1741,1039.7,1740.3,1038.9z"/>
            <path class="st3" d="M1767.9,1004.8c1.9,0,2.6-1.8,2.2-3.2c-0.3-0.8-1-1.5-2.2-1.5c-0.2,0-0.3,0-0.5,0
              C1764.9,1000.5,1765,1004.8,1767.9,1004.8z"/>
            <path class="st3" d="M1782.2,1023.4c0.5-1.4-0.2-3.3-2.1-3.3c-3.1,0-3.1,4.7,0,4.8c0.3,0,0.5,0,0.7-0.1
              C1781.5,1024.6,1782,1024.1,1782.2,1023.4z"/>
            <path class="st3" d="M1862.8,1051.6c1.2-0.2,1.7-1.2,1.6-2.1c-0.1-1-0.8-2-2.1-1.8c-0.9,0.1-1.4,0.7-1.6,1.3c0,0,0,0.1,0,0.1
              c-0.1,0.4,0,0.9,0.1,1.3C1861.1,1051.2,1861.8,1051.8,1862.8,1051.6z"/>
            <path class="st3" d="M1865.4,1059.5c-0.2,0-0.3,0.1-0.4,0.1c-1.6,0.5-1.6,2.7-0.4,3.5c0.3,0.2,0.8,0.4,1.3,0.3
              C1868.4,1063,1867.9,1059.1,1865.4,1059.5z"/>
            <path class="st3" d="M1869.6,1055.4c0.3,0.1,0.6,0.2,0.9,0.1c2.5-0.3,2-4.2-0.5-3.9c-1.6,0.2-2,1.8-1.4,2.9
              C1868.9,1054.9,1869.2,1055.2,1869.6,1055.4z"/>
            <path class="st3" d="M1879.4,1056.9c-2.5,0.3-2,4.2,0.5,3.9C1882.4,1060.4,1882,1056.5,1879.4,1056.9z"/>
            <path class="st3" d="M1873.5,1048c2.5-0.3,2-4.2-0.5-3.9C1870.5,1044.5,1871,1048.4,1873.5,1048z"/>
            <path class="st3" d="M1857.8,1055c-1,0.1-1.5,0.8-1.6,1.6c-0.1,1.2,0.6,2.5,2.1,2.3c0.1,0,0.2,0,0.3-0.1
              C1860.7,1058.2,1860.2,1054.7,1857.8,1055z"/>
            <path class="st3" d="M1881.8,1053.8c0.4-0.2,0.9-0.4,1.3-0.6c0.9-0.4,1.5-1.6,1.1-2.6c-0.4-0.8-1.3-1.4-2.2-1.2
              c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.2-0.9,0.4-1.3,0.6c-0.9,0.4-1.5,1.6-1.1,2.6c0.3,0.7,1,1.1,1.7,1.2
              C1881.2,1053.9,1881.5,1053.9,1881.8,1053.8z"/>
            <path class="st3" d="M1873.7,1061c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2.1-0.5-2.8,0.4c-0.6,0.9-0.5,2.1,0.4,2.8
              c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2.1,0.5,2.8-0.4C1874.7,1062.9,1874.6,1061.7,1873.7,1061z"/>
            <path class="st3" d="M1859.3,1066c-0.8,0.4-1.3,1.2-1.2,2.1h4.2c-0.1-0.3-0.3-0.7-0.4-1
              C1861.4,1066.1,1860.2,1065.5,1859.3,1066z"/>
            <path class="st3" d="M1866.3,1041.4c0.7,0.3,1.6,0.1,2.2-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.3-0.8,0.5-1.3c0,0,0-0.1,0-0.1
              c0.2-0.8,0-1.8-0.8-2.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.9-0.4-2.2,0-2.6,1c-0.2,0.4-0.3,0.8-0.5,1.3
              C1864.9,1039.8,1865.3,1041,1866.3,1041.4z"/>
            <path class="st3" d="M1880.5,1044.9c2.5-0.3,2-4.2-0.5-3.9C1877.5,1041.3,1878,1045.2,1880.5,1044.9z"/>
            <path class="st3" d="M1850.9,1047.2c-0.3,0-0.6,0.2-0.9,0.3c-1.5,1-0.8,3.9,1.4,3.6c1.5-0.2,1.9-1.8,1.4-2.8
              C1852.5,1047.5,1851.9,1047,1850.9,1047.2z"/>
            <path class="st3" d="M1850.9,1067.8c-0.3,0-0.6,0.1-0.8,0.3h2C1851.9,1067.8,1851.4,1067.7,1850.9,1067.8z"/>
            <path class="st3" d="M1892.1,1056.3c-2.5,0.3-2,4.2,0.5,3.9C1895.1,1059.8,1894.6,1055.9,1892.1,1056.3z"/>
            <path class="st3" d="M1843.9,1058.4c-2.5,0.3-2,4.2,0.5,3.9C1846.9,1061.9,1846.4,1058.1,1843.9,1058.4z"/>
            <path class="st3" d="M1850.3,1038.1c0.4,0.4,0.9,0.6,1.6,0.5c2.5-0.3,2-4.2-0.5-3.9c-0.8,0.1-1.3,0.6-1.5,1.2
              C1849.6,1036.7,1849.7,1037.6,1850.3,1038.1z"/>
          </g>
          <g>
            <path id="XMLID_13_" class="st4" d="M2070.5,869.2v171.9c0,9.9-8.1,18-18,18h-134.7c-3.8,0-7.2-2.4-8.5-5.9
              c-17.2-48.4-63.3-83-117.6-83c-13.8,0-27.1,2.3-39.6,6.4c-5.9,2-11.9-2.3-11.9-8.5V741.4c0-9.9,8.1-18,18-18H1910
              c6.8,0,13.3,2.5,18.3,7.1l11.8,10.9l4.2,3.9l45.2,41.5l75.2,69.1C2068.4,859.3,2070.5,864.1,2070.5,869.2z"/>
            <g>
              <path class="st5" d="M1920.5,1027.2c-2.3-2-5.4,1.5-3.1,3.5C1919.6,1032.7,1922.7,1029.2,1920.5,1027.2z"/>
              <path class="st5" d="M1912.3,1039.3c-2.3-2-5.4,1.5-3.1,3.5C1911.5,1044.8,1914.6,1041.3,1912.3,1039.3z"/>
              <path class="st5" d="M1923.4,1037.5c-0.3,1.3-1.4,2.4-3.1,2c-0.4-0.1-0.7-0.3-1-0.5c0,0.6,0.2,1.2,0.8,1.8
                C1922.2,1042.7,1925.2,1039.5,1923.4,1037.5z"/>
              <path class="st5" d="M1925.2,1049.5c-2.2-0.9-4.5,2.1-2.5,4c0.1,0.1,0.1,0.1,0.2,0.2C1921.9,1052,1923,1049.4,1925.2,1049.5z"
                />
              <path class="st5" d="M1932.2,1033.9c-2.3-2-5.4,1.5-3.1,3.5C1931.3,1039.5,1934.4,1036,1932.2,1033.9z"/>
              <path class="st5" d="M1911.1,1030.4c-0.9,0.1-1.7-0.4-2.2-1.2c-0.2-0.3-0.3-0.6-0.3-1c-1.8,0.2-3.2,2.6-1.4,4.1
                C1909,1033.9,1911.2,1032.2,1911.1,1030.4z"/>
              <path class="st5" d="M1935.2,1049.2c0.5-1.2,0.1-2.6-1.1-3.2c-0.5-0.3-1.1-0.5-1.6-0.8c-1.1-0.5-2.6-0.1-3.2,1.1
                c-0.5,1.2-0.1,2.6,1.1,3.2c0.5,0.3,1.1,0.5,1.6,0.8C1933.2,1050.8,1934.7,1050.4,1935.2,1049.2z"/>
              <path class="st5" d="M1915.1,1045.1C1915,1045.1,1915,1045.1,1915.1,1045.1c-1.4,0.1-2.4,1.2-2.3,2.5c0,0.2,0,0.5,0,0.7
                c0.1,1.2,1.2,2.3,2.5,2.2c1.3-0.1,2.3-1.2,2.2-2.5c0-0.2,0-0.5,0-0.7c0-0.3-0.1-0.6-0.3-0.9
                C1916.2,1046.5,1915.4,1045.9,1915.1,1045.1z"/>
              <path class="st5" d="M1932.3,1027.3c0.1,0,0.2-0.1,0.3-0.1c-0.3-1.6,0.8-3.5,2.7-3.3c0-0.1,0-0.1,0-0.2c-0.4-1.1-1.8-2-3-1.5
                c-0.5,0.2-1,0.4-1.5,0.6c-1.1,0.5-2,1.7-1.5,3C1929.8,1026.9,1931.1,1027.7,1932.3,1027.3z"/>
              <path class="st5" d="M1938.3,1037.4c-1.5,0.6-2.4,2.6-0.9,4c0.5,0.4,1,0.6,1.5,0.6C1936.8,1041.2,1936.9,1038.6,1938.3,1037.4
                z"/>
              <path class="st5" d="M1909.4,1020.2c-0.1,0-0.2,0-0.3,0c2.2,1.4,4.9-1.9,2.8-3.8c-0.7-0.6-1.4-0.7-2.1-0.5
                C1911.5,1016.7,1911.7,1019.7,1909.4,1020.2z"/>
              <path class="st5" d="M1896.2,1019c-1.8-1.5-4,0.2-3.9,2c0.5,0.6,1,1.3,1.4,2C1895.9,1023.9,1898.3,1020.8,1896.2,1019z"/>
              <path class="st5" d="M1923.4,1007c-2.3-2-5.4,1.5-3.1,3.5C1922.6,1012.6,1925.7,1009,1923.4,1007z"/>
              <path class="st5" d="M1957.9,1034.4c-2.7-0.5-3.7,3.1-1.8,4.4c0.4-0.1,0.8-0.1,1.3,0c0.3,0.1,0.5,0.2,0.7,0.3
                C1960.2,1038.4,1960.5,1034.9,1957.9,1034.4z"/>
              <path class="st5" d="M1957.2,1049.2c-1.1-0.2-2,0.3-2.4,1.1c0.9,0,1.7,0.5,2.2,1.3c0.1,0.2,0.3,0.4,0.4,0.7
                c0.3,0.5,0.4,1,0.3,1.6C1959.6,1053,1959.7,1049.7,1957.2,1049.2z"/>
              <path class="st5" d="M1964.7,1046.5c3,0.5,3.9-4.2,0.9-4.7C1962.5,1041.2,1961.6,1045.9,1964.7,1046.5z"/>
              <path class="st5" d="M1974.5,1051.3c-3-0.5-3.9,4.2-0.9,4.7C1976.7,1056.6,1977.5,1051.9,1974.5,1051.3z"/>
              <path class="st5" d="M1972.1,1034.3c-0.1-0.1-0.3-0.1-0.4-0.1c-3-0.5-3.9,4.2-0.9,4.7c2.2,0.4,3.2-1.9,2.5-3.5
                C1972.7,1035.1,1972.3,1034.7,1972.1,1034.3z"/>
              <path class="st5" d="M1949.9,1041.2c-3-0.5-3.9,4.2-0.9,4.7C1952.1,1046.4,1953,1041.7,1949.9,1041.2z"/>
              <path class="st5" d="M1979.7,1043.6c-0.6,0.1-1.2,0.1-1.8,0.2c-1.2,0.1-2.3,1.3-2.2,2.6c0.2,1.3,1.3,2.3,2.6,2.2
                c0.6-0.1,1.2-0.1,1.8-0.2c1.2-0.1,2.3-1.3,2.2-2.6C1982.1,1044.5,1981,1043.5,1979.7,1043.6z"/>
              <path class="st5" d="M1965.2,1055c-1.3-0.3-1.9-1.4-1.9-2.5c-0.3,0.1-0.6,0.2-0.8,0.4c-1,0.8-1.4,2.2-0.6,3.3
                c0.1,0.2,0.3,0.4,0.4,0.6c0.7,1,2.3,1.4,3.3,0.6c0.8-0.6,1.1-1.5,1-2.4C1966.2,1055.1,1965.8,1055.1,1965.2,1055z"/>
              <path class="st5" d="M1950.2,1056.7c-0.1-1.2-1.3-2.3-2.6-2.2c-1.3,0.2-2.3,1.3-2.2,2.6c0.1,0.6,0.1,1.2,0.2,1.8
                c0,0.1,0,0.1,0,0.2h4.7c0-0.2,0.1-0.4,0-0.6C1950.4,1057.9,1950.3,1057.3,1950.2,1056.7z"/>
              <path class="st5" d="M1967.8,1023c-0.5,0.7-1.3,1.1-2.2,0.8c-0.4,0.5-0.7,0.9-1,1.3c-0.8,1-0.8,2.6,0.3,3.4
                c1,0.8,2.6,0.8,3.4-0.3c0.3-0.4,0.7-0.9,1-1.3c0.8-1,0.8-2.6-0.3-3.4C1968.7,1023.2,1968.2,1023.1,1967.8,1023z"/>
              <path class="st5" d="M1980.6,1033.1c-2.7-0.1-3.4,4.2-0.5,4.8c1.9,0.3,3-1.4,2.7-2.9c-0.2,0-0.4,0.1-0.6,0
                C1981.3,1034.8,1980.7,1034,1980.6,1033.1z"/>
              <path class="st5" d="M1946.5,1032.9c0-0.4,0.1-0.8,0.2-1.2c-0.1-1-0.6-1.9-1.9-2.1c-3-0.5-3.9,4.2-0.9,4.7
                C1945.2,1034.5,1946.1,1033.8,1946.5,1032.9z"/>
              <path class="st5" d="M1934.7,1057c0-0.1-0.1-0.2-0.1-0.3C1934.6,1056.8,1934.7,1056.9,1934.7,1057z"/>
              <path class="st5" d="M1934.9,1057.4c-0.1-0.1-0.2-0.2-0.2-0.4C1934.8,1057.1,1934.9,1057.3,1934.9,1057.4z"/>
              <path class="st5" d="M1935.1,1057.6C1935.1,1057.6,1935.1,1057.6,1935.1,1057.6C1935.1,1057.6,1935.1,1057.6,1935.1,1057.6z"
                />
              <path class="st5" d="M1989.5,1055.3c-2.4-0.4-3.4,2.3-2.3,3.8h3.7C1991.8,1057.8,1991.5,1055.6,1989.5,1055.3z"/>
              <path class="st5" d="M1931.6,1044.7c3,0.5,3.9-4.2,0.9-4.7C1929.4,1039.5,1928.6,1044.2,1931.6,1044.7z"/>
              <path class="st5" d="M1949,1020c3,0.5,3.9-4.2,0.9-4.7C1946.8,1014.7,1946,1019.5,1949,1020z"/>
              <path class="st5" d="M2025,1033.8c2.4-0.7,1.3-4.5-1.1-3.7C2021.5,1030.8,2022.6,1034.5,2025,1033.8z"/>
              <path class="st5" d="M2028.9,1041.1c-2.4,0.7-1.3,4.5,1.1,3.7C2032.5,1044.1,2031.3,1040.3,2028.9,1041.1z"/>
              <path class="st5" d="M2032.2,1032.5c-2.4,0.7-1.3,4.5,1.1,3.7C2035.7,1035.5,2034.6,1031.8,2032.2,1032.5z"/>
              <path class="st5" d="M2043.4,1039.9c2.4-0.7,1.3-4.5-1.1-3.7C2039.9,1036.9,2041,1040.6,2043.4,1039.9z"/>
              <path class="st5" d="M2033.8,1024.7c-2.4,0.7-1.3,4.5,1.1,3.7C2037.3,1027.8,2036.2,1024,2033.8,1024.7z"/>
              <path class="st5" d="M2020.7,1037.9c-2.4,0.7-1.3,4.5,1.1,3.7C2024.2,1040.9,2023.1,1037.2,2020.7,1037.9z"/>
              <path class="st5" d="M2044.5,1028.4c-0.4-0.1-0.9,0-1.3,0.3c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.8-0.6,2.7
                c0.6,0.9,1.8,1.2,2.7,0.6c0.4-0.3,0.8-0.5,1.2-0.8c0.2-0.1,0.4-0.3,0.5-0.5C2044.6,1031,2044,1029.5,2044.5,1028.4z"/>
              <path class="st5" d="M2037.3,1041.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.8c-0.5,1-0.2,2.1,0.8,2.6
                c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.8C2038.6,1042.9,2038.3,1041.7,2037.3,1041.2z"/>
              <path class="st5" d="M2026.7,1049.1c-0.6-0.9-1.8-1.2-2.7-0.6c-0.9,0.6-1.2,1.8-0.6,2.7c0.3,0.4,0.5,0.8,0.8,1.2
                c0.6,0.9,1.8,1.2,2.7,0.6c0.9-0.6,1.2-1.8,0.6-2.7C2027.2,1049.9,2026.9,1049.5,2026.7,1049.1z"/>
              <path class="st5" d="M2025.6,1019.5c-0.1,0.4-0.2,0.9-0.2,1.3c-0.2,1,0.4,2.1,1.5,2.4c1,0.2,2.2-0.4,2.4-1.5
                c0.1-0.4,0.2-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.4C2026.9,1017.8,2025.8,1018.4,2025.6,1019.5z"/>
              <path class="st5" d="M2034.7,1049.8c-0.8,0.2-1.6,0.3-2.3,0.5c-1,0.2-1.6,1.5-1.3,2.4c0.4,1.1,1.4,1.5,2.4,1.3
                c0.8-0.2,1.6-0.3,2.3-0.5c1-0.2,1.6-1.5,1.3-2.4C2036.8,1050,2035.8,1049.5,2034.7,1049.8z"/>
              <path class="st5" d="M2046.5,1050.9c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
                c-0.1-0.2-0.1-0.3-0.1-0.4c-0.2-1-1.5-1.6-2.4-1.3c-1.1,0.4-1.5,1.4-1.3,2.4c0.2,1.1,0.9,2.1,1.8,2.8c0.8,0.6,2,0.8,2.8-0.1
                C2047.2,1052.9,2047.3,1051.6,2046.5,1050.9z M2045.9,1050.3c0,0,0,0.1,0.1,0.2C2045.9,1050.4,2045.9,1050.3,2045.9,1050.3z
                M2046,1050.5c0,0.1,0.1,0.1,0.1,0.2C2046.1,1050.6,2046,1050.6,2046,1050.5z"/>
              <path class="st5" d="M2048.7,1042.8c-2.4,0.7-1.3,4.5,1.1,3.7C2052.2,1045.8,2051.1,1042,2048.7,1042.8z"/>
              <path class="st5" d="M2036.4,1055.3c-2.1,0.6-1.5,3.5,0.3,3.8h0.5c0.1,0,0.2,0,0.3-0.1
                C2040,1058.3,2038.8,1054.5,2036.4,1055.3z"/>
              <path class="st5" d="M2026.2,1057.5c-0.8,0.3-1.2,0.9-1.3,1.5h3.7C2028.5,1058.1,2027.5,1057.1,2026.2,1057.5z"/>
              <path class="st5" d="M2041.3,1024.2c2.4-0.7,1.3-4.5-1.1-3.7C2037.8,1021.2,2038.9,1025,2041.3,1024.2z"/>
              <path class="st5" d="M2012.6,1031.4c-2.4,0.7-1.3,4.5,1.1,3.7C2016.2,1034.4,2015,1030.7,2012.6,1031.4z"/>
              <path class="st5" d="M2016.1,1051.7c-2.4,0.7-1.3,4.5,1.1,3.7C2019.6,1054.7,2018.5,1050.9,2016.1,1051.7z"/>
              <path class="st5" d="M2055.1,1033.4c0.1,0,0.2-0.1,0.2-0.1c-0.2,0-0.4,0-0.7,0.1c-2.1,0.7-1.5,3.7,0.4,3.8
                C2053.6,1036.5,2053.2,1034.2,2055.1,1033.4z"/>
              <path class="st5" d="M2007.6,1043.6c-2.4,0.7-1.3,4.5,1.1,3.7C2011.1,1046.6,2010,1042.9,2007.6,1043.6z"/>
              <path class="st5" d="M2012.1,1022.9c2.4-0.7,1.3-4.5-1.1-3.7C2008.6,1019.9,2009.7,1023.6,2012.1,1022.9z"/>
              <path class="st5" d="M2059.4,983c-1.8-1-3.4,1.8-1.5,2.8C2059.7,986.9,2061.2,984,2059.4,983z"/>
              <path class="st5" d="M2055.8,992.4c-1.8-1-3.4,1.8-1.5,2.8C2056.1,996.2,2057.7,993.4,2055.8,992.4z"/>
              <path class="st5" d="M2062.7,989.4c-1.8-1-3.4,1.8-1.5,2.8C2063,993.2,2064.6,990.4,2062.7,989.4z"/>
              <path class="st5" d="M2066.4,997.4c-1.8-1-3.4,1.8-1.5,2.8C2066.7,1001.2,2068.3,998.4,2066.4,997.4z"/>
              <path class="st5" d="M2068.3,985.8c-1.8-1-3.4,1.8-1.5,2.8C2068.5,989.6,2070.1,986.8,2068.3,985.8z"/>
              <path class="st5" d="M2051.3,988.5c1.8,1,3.4-1.8,1.5-2.8C2051.1,984.7,2049.5,987.5,2051.3,988.5z"/>
              <path class="st5" d="M2068.3,994.5c-0.2,0.9,0.3,1.7,1.2,1.9c0.3,0.1,0.7,0.2,1,0.2v-3.3c-0.1,0-0.2,0-0.3-0.1
                C2069.4,993.1,2068.5,993.7,2068.3,994.5z"/>
              <path class="st5" d="M2058.5,995.8c-0.8,0.3-1.3,1.1-1.1,2c0,0.1,0.1,0.3,0.1,0.4c0.2,0.8,1.2,1.4,2,1.1
                c0.8-0.3,1.3-1.1,1.1-2c0-0.1-0.1-0.3-0.1-0.4C2060.2,996.1,2059.3,995.6,2058.5,995.8z"/>
              <path class="st5" d="M2047.6,998.1c0.9,0.2,1.7-0.3,1.9-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.2-1.9
                c-0.9-0.2-1.7,0.3-1.9,1.2c-0.1,0.4-0.2,0.8-0.3,1.2C2046.2,997,2046.7,997.9,2047.6,998.1z"/>
              <path class="st5" d="M2066.6,977.9c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.5-1,1.5-0.5,2.2c0.5,0.7,1.5,1.1,2.2,0.5
                c0.3-0.2,0.6-0.4,0.9-0.6c0.7-0.5,1-1.5,0.5-2.2C2068.4,977.7,2067.3,977.4,2066.6,977.9z"/>
              <path class="st5" d="M2054,1003.7c0.3-0.1,0.5-0.4,0.7-0.7c0.3-0.6,0.2-1.1-0.1-1.6C2054.8,1002.2,2054.6,1003.1,2054,1003.7z
                "/>
              <path class="st5" d="M2052.7,999.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.5-1.8-0.1-2.2,0.6c-0.4,0.7-0.2,1.5,0.4,2
                C2050.5,1001.2,2051.3,999.9,2052.7,999.9z"/>
              <path class="st5" d="M2058.6,1010.1c0.8-0.1,1.7-0.8,1.6-1.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
                c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.1-1.8-0.6-2.2c-0.8-0.4-1.7-0.1-2.2,0.6c-0.5,0.8-0.7,1.8-0.6,2.7
                C2057,1009.3,2057.7,1010.1,2058.6,1010.1z M2060.2,1007.7c0,0,0,0.1-0.1,0.1C2060.2,1007.8,2060.2,1007.7,2060.2,1007.7z
                M2060.1,1007.9c0,0.1,0,0.1,0,0.2C2060.1,1008,2060.1,1008,2060.1,1007.9z"/>
              <path class="st5" d="M2066.3,1005c-1.8-1-3.4,1.8-1.5,2.8C2066.5,1008.8,2068.1,1006,2066.3,1005z"/>
              <path class="st5" d="M2050.2,1007.8c1.8,1,3.4-1.8,1.5-2.8C2050,1004,2048.4,1006.9,2050.2,1007.8z"/>
              <path class="st5" d="M2044.6,1000.3c-1.8-1-3.4,1.8-1.5,2.8C2044.8,1004.2,2046.4,1001.3,2044.6,1000.3z"/>
              <path class="st5" d="M2052.1,977.2c-1.8-1-3.4,1.8-1.5,2.8C2052.4,981,2053.9,978.2,2052.1,977.2z"/>
              <path class="st5" d="M2040.7,992.2c-0.1-0.5,0.1-1,0.4-1.4c-1.2,0.3-1.9,2.2-0.4,3c0.5,0.3,0.9,0.3,1.3,0.1
                C2041.3,993.6,2040.8,992.9,2040.7,992.2z"/>
              <path class="st5" d="M2040.4,984.1c1.8,1,3.4-1.8,1.5-2.8C2040.1,980.3,2038.6,983.1,2040.4,984.1z"/>
              <path class="st5" d="M2058.3,969.1c-1.8-1-3.4,1.8-1.5,2.8C2058.6,973,2060.2,970.1,2058.3,969.1z"/>
              <path class="st5" d="M1749.2,963c2.5-0.9,1.1-4.8-1.4-3.9C1745.3,960,1746.7,963.9,1749.2,963z"/>
              <path class="st5" d="M1753.7,970.5c-2.5,0.9-1.1,4.8,1.4,3.9C1757.6,973.6,1756.2,969.6,1753.7,970.5z"/>
              <path class="st5" d="M1756.7,961.3c-2.5,0.9-1.1,4.8,1.4,3.9C1760.6,964.4,1759.3,960.4,1756.7,961.3z"/>
              <path class="st5" d="M1769,964.7c-0.4-0.2-0.9-0.2-1.4,0c-1.6,0.5-1.6,2.2-0.9,3.3C1766.4,966.5,1767.2,964.7,1769,964.7z"/>
              <path class="st5" d="M1758.1,953c-2.5,0.9-1.1,4.8,1.4,3.9C1762,956,1760.6,952.1,1758.1,953z"/>
              <path class="st5" d="M1747.4,970.1c0.3-1.4-0.9-3.1-2.7-2.5c-2.5,0.9-1.1,4.8,1.4,3.9c0,0,0,0,0.1,0
                C1746.4,970.9,1746.8,970.3,1747.4,970.1z"/>
              <path class="st5" d="M1768.3,956.7c-0.4,0.3-0.8,0.6-1.3,0.9c-0.9,0.6-1.2,2-0.5,2.9c0.7,0.9,1.9,1.2,2.9,0.5
                c0.4-0.3,0.8-0.6,1.3-0.9c0.9-0.6,1.2-2,0.5-2.9C1770.5,956.3,1769.2,956,1768.3,956.7z"/>
              <path class="st5" d="M1762.6,970.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.8-0.4-1.9-0.2-2.5,0.6c0,0,0.1,0,0.1,0
                c1.3-0.2,2.6,0.9,2.8,2.2c0,0.3,0.1,0.5,0.1,0.8c0.3-0.1,0.6-0.1,0.9-0.2c0.1-0.1,0.1-0.2,0.2-0.3
                C1764,972,1763.6,970.8,1762.6,970.3z"/>
              <path class="st5" d="M1753,950c0.1-0.5,0.1-0.9,0.2-1.4c0.1-1.1-0.5-2.3-1.7-2.4c-1.1-0.2-2.3,0.5-2.4,1.7
                c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,1.1,0.5,2.3,1.7,2.4C1751.6,951.8,1752.8,951.1,1753,950z"/>
              <path class="st5" d="M1764.7,948.2c-2.5,0.9-1.1,4.8,1.4,3.9c0.4-0.1,0.6-0.3,0.9-0.6c0.1-0.5,0.2-0.9,0.5-1.3
                C1767.4,948.9,1766.3,947.6,1764.7,948.2z"/>
              <path class="st5" d="M1780.6,961.3c-2.5,0.9-1.1,4.8,1.4,3.9C1784.5,964.3,1783.1,960.4,1780.6,961.3z"/>
              <path class="st5" d="M1804.5,959.8c-2.1,0.1-1.9,3.4,0.2,3.3C1806.8,963,1806.6,959.7,1804.5,959.8z"/>
              <path class="st5" d="M1806.4,969.9c-0.7,0-1.1,0.4-1.4,0.9c1,0.1,2,0.2,3,0.4C1807.9,970.5,1807.4,969.9,1806.4,969.9z"/>
              <path class="st5" d="M1810.8,963.5c-2.1,0.1-1.9,3.4,0.2,3.3c1.4-0.1,1.8-1.6,1.3-2.6c0,0,0-0.1-0.1-0.1
                C1811.9,963.8,1811.5,963.5,1810.8,963.5z"/>
              <path class="st5" d="M1818.4,968.5c-2.1,0.1-1.9,3.4,0.2,3.3C1820.8,971.7,1820.6,968.4,1818.4,968.5z"/>
              <path class="st5" d="M1814.3,960.7c1.7-0.5,1.4-3.4-0.6-3.2c-2.1,0.1-2,3.2,0,3.3C1813.9,960.7,1814.1,960.6,1814.3,960.7z"/>
              <path class="st5" d="M1800.2,965.7c-2.1,0.1-1.9,3.4,0.2,3.3C1802.6,968.9,1802.4,965.6,1800.2,965.7z"/>
              <path class="st5" d="M1820.7,962.5c-0.4,0.1-0.8,0.3-1.2,0.4c-0.8,0.3-1.4,1.3-1,2.1c0.3,0.8,1.2,1.3,2.1,1
                c0.4-0.1,0.8-0.3,1.2-0.4c0.8-0.3,1.4-1.3,1-2.1C1822.5,962.7,1821.6,962.2,1820.7,962.5z"/>
              <path class="st5" d="M1813,971.4c-0.7-0.6-1.8-0.6-2.3,0.1c1,0.2,2,0.3,3,0.5c-0.1-0.1-0.2-0.2-0.3-0.3
                C1813.2,971.6,1813.1,971.5,1813,971.4z"/>
              <path class="st5" d="M1810.5,954c0.2-0.3,0.3-0.7,0.5-1c0.3-0.8,0.1-1.8-0.7-2.2c-0.8-0.4-1.9-0.1-2.2,0.7
                c-0.2,0.3-0.3,0.7-0.5,1c-0.3,0.8-0.1,1.8,0.7,2.2C1809,955,1810.1,954.8,1810.5,954z"/>
              <path class="st5" d="M1820.1,958.5c2.1-0.1,1.9-3.4-0.2-3.3C1817.7,955.3,1817.9,958.6,1820.1,958.5z"/>
              <path class="st5" d="M1794.9,958.7c-0.2,0-0.3,0-0.4,0.1c0.5,0.4,0.9,1,1,1.7c0.1,0.5,0,1.1-0.3,1.5
                C1797.2,961.8,1797,958.6,1794.9,958.7z"/>
              <path class="st5" d="M1829.3,972.1c0.7,0,1.1-0.4,1.3-0.9c-0.6,0.5-1.5,0.6-2.3,0.1c-0.3-0.2-0.5-0.4-0.7-0.6
                C1827.8,971.5,1828.4,972.1,1829.3,972.1z"/>
              <path class="st5" d="M1789.4,968.1c0.2,0.7,0.1,1.4-0.2,2c0.2,0,0.4,0,0.6,0C1790.1,969.5,1790,968.6,1789.4,968.1z"/>
              <path class="st5" d="M1796,948.2c-0.2,0-0.4,0.1-0.6,0.1c0.5,0.9,0.4,2.1-0.2,3c0.3,0.2,0.6,0.3,1,0.2
                C1798.3,951.4,1798.1,948.1,1796,948.2z"/>
              <path class="st5" d="M1839.8,963.7c-2.6-1.4-4.8,2.6-2.2,4C1840.1,969.1,1842.4,965.1,1839.8,963.7z"/>
              <path class="st5" d="M1834.6,976.8c-0.9-0.5-1.7-0.3-2.3,0.1c1.1,0.4,2.2,0.8,3.3,1.2C1835.5,977.6,1835.2,977.1,1834.6,976.8
                z"/>
              <path class="st5" d="M1844.4,972.6c-2.6-1.4-4.8,2.6-2.2,4C1844.7,978,1847,974,1844.4,972.6z"/>
              <path class="st5" d="M1849.5,984c-0.4-0.2-0.7-0.3-1.1-0.3c0.6,0.3,1.2,0.7,1.8,1C1850.1,984.4,1849.9,984.2,1849.5,984z"/>
              <path class="st5" d="M1852.2,967.6c-2.6-1.4-4.8,2.6-2.2,4C1852.6,973,1854.8,969.1,1852.2,967.6z"/>
              <path class="st5" d="M1828.4,971.3c0.9,0.5,1.7,0.3,2.3-0.1c1.2-0.9,1.6-2.9-0.1-3.8c-2.3-1.3-4.3,1.7-2.9,3.4
                C1827.9,971,1828.1,971.2,1828.4,971.3z"/>
              <path class="st5" d="M1856.6,978.7c-0.1,0-0.1,0-0.2,0c1.3,1.8-1.1,4.5-3.1,3c-0.9-0.7-1-1.7-0.6-2.6
                c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2,1.2,0.4,2.5,1.7,2.7c0.5,0.1,1.1,0.3,1.6,0.4c1.2,0.3,2.5-0.5,2.7-1.7
                C1858.5,980.2,1857.8,979,1856.6,978.7z"/>
              <path class="st5" d="M1851,961.3c0.4-0.3,0.9-0.6,1.3-0.9c1-0.7,1.5-2.1,0.8-3.1c-0.6-1-2.1-1.5-3.1-0.8
                c-0.4,0.3-0.9,0.6-1.3,0.9c-1,0.7-1.5,2.1-0.8,3.1C1848.5,961.5,1850,962,1851,961.3z"/>
              <path class="st5" d="M1861,969.6c-2.6-1.4-4.8,2.6-2.2,4C1861.3,975,1863.5,971,1861,969.6z"/>
              <path class="st5" d="M1829.5,955.3c-2.6-1.4-4.8,2.6-2.2,4C1829.9,960.7,1832.1,956.7,1829.5,955.3z"/>
              <path class="st5" d="M1815.2,961c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0-0.7,0.1c-1.4,0.4-2.3,2.2-1.4,3.5c0,0,0,0.1,0.1,0.1
                c0.2,0.2,0.4,0.5,0.7,0.6C1815.6,966.4,1817.8,962.4,1815.2,961z"/>
              <path class="st5" d="M1838.4,944.1c-2.6-1.4-4.8,2.6-2.2,4C1838.8,949.5,1841,945.5,1838.4,944.1z"/>
              <path class="st5" d="M1853.3,981.6c2,1.6,4.4-1.2,3.1-3c-0.1-0.2-0.3-0.4-0.5-0.5c-1.3-1-2.7-0.2-3.3,0.9
                C1852.2,979.9,1852.3,980.9,1853.3,981.6z"/>
              <path class="st5" d="M1859.1,987.3c-0.8-0.6-1.6-0.5-2.3-0.2c0.3,0.5,0.5,1,0.5,1.5c0.8,0.5,1.5,1,2.3,1.5
                C1860.1,989.3,1860.2,988.1,1859.1,987.3z"/>
              <path class="st5" d="M1867.3,983.6c-2.3-1.7-4.9,1.8-2.7,3.5C1866.9,988.8,1869.5,985.3,1867.3,983.6z"/>
              <path class="st5" d="M1846.6,980.4c-1.3-1-2.7-0.3-3.3,0.9c1.3,0.6,2.6,1.2,3.8,1.8C1847.6,982.2,1847.6,981.1,1846.6,980.4z"
                />
              <path class="st5" d="M1869.9,994.7c-0.5-0.2-1-0.4-1.5-0.6c-0.8-0.3-1.8-0.1-2.4,0.5c1.6,1.2,3.2,2.4,4.7,3.7
                c0.2-0.2,0.3-0.4,0.4-0.7C1871.5,996.5,1871.1,995.2,1869.9,994.7z"/>
              <path class="st5" d="M1866.6,972.7c-0.5,0.2-0.9,0.4-1.4,0.7c-1,0.5-1.7,1.8-1.2,2.9c0.5,1,1.8,1.7,2.9,1.2
                c0.5-0.2,0.9-0.4,1.4-0.7c1-0.5,1.7-1.8,1.2-2.9C1869,972.8,1867.7,972.1,1866.6,972.7z"/>
              <path class="st5" d="M1872.4,987.1c-0.7,0.9-0.8,2.2,0.4,3c1.9,1.4,4-0.8,3.4-2.5c-0.5,0.4-1.2,0.7-1.9,0.7
                C1873.4,988.3,1872.7,987.8,1872.4,987.1z"/>
              <path class="st5" d="M1847.3,968.7c-2.3-1.7-4.9,1.8-2.7,3.5C1846.9,974,1849.5,970.4,1847.3,968.7z"/>
              <path class="st5" d="M1832.8,972.2c-2.3-1.7-4.9,1.8-2.7,3.5C1832.4,977.4,1835.1,973.9,1832.8,972.2z"/>
              <path class="st5" d="M1854.7,962.7c2.3,1.7,4.9-1.8,2.7-3.5C1855.1,957.5,1852.4,961,1854.7,962.7z"/>
              <path class="st5" d="M1898.4,1029.1c-0.2,0-0.3,0.1-0.5,0.2c0.8,1.3,1.6,2.6,2.3,3.9
                C1901.9,1031.9,1900.9,1028.5,1898.4,1029.1z"/>
              <path class="st5" d="M1910.7,1038.3c2.8-0.7,1.8-5-1-4.4C1906.8,1034.6,1907.9,1039,1910.7,1038.3z"/>
              <path class="st5" d="M1901.1,1023.3c-0.7-0.4-1.4-1-1.9-1.7c-0.5,1.5,0.6,3.5,2.6,3.1c0.7-0.2,1.1-0.5,1.4-1
                C1902.5,1023.7,1901.8,1023.6,1901.1,1023.3z"/>
              <path class="st5" d="M1908.9,1029.2c0.5,0.8,1.3,1.2,2.2,1.2c0.3,0,0.6-0.1,0.8-0.3c0.5-0.3,1-0.5,1.5-0.8c1-0.6,1.5-2,0.9-3
                c-0.6-1-2-1.5-3-0.9c-0.5,0.3-1,0.5-1.5,0.8c-0.7,0.4-1.2,1.2-1.2,2.1C1908.6,1028.6,1908.7,1028.9,1908.9,1029.2z"/>
              <path class="st5" d="M1903.6,1039.3c0,0-0.1,0-0.1-0.1c0.4,0.8,0.8,1.6,1.1,2.3C1904.8,1040.7,1904.4,1039.8,1903.6,1039.3z"
                />
              <path class="st5" d="M1894.5,1012.1c-1.1-0.3-2.5,0.3-2.8,1.5c-0.1,0.5-0.2,1-0.4,1.5c-0.3,1.1,0.3,2.5,1.5,2.8
                c1.1,0.3,2.5-0.3,2.8-1.5c0.1-0.5,0.2-1,0.4-1.5C1896.3,1013.8,1895.7,1012.4,1894.5,1012.1z"/>
              <path class="st5" d="M1913.3,1054.3c0.8-0.8,1-2.4,0.1-3.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
                c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.4c-0.2-1.2-1.6-2-2.7-1.7c-1,0.3-1.6,1.1-1.7,2c0.4,1,0.8,1.9,1.1,2.9
                c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.4,0.4C1911,1055,1912.4,1055.2,1913.3,1054.3z M1913,1050.9
                c-0.1-0.1-0.1-0.1-0.1-0.2C1912.9,1050.7,1913,1050.8,1913,1050.9z M1912.8,1050.5c0,0-0.1-0.1-0.1-0.2
                C1912.8,1050.4,1912.8,1050.4,1912.8,1050.5z"/>
              <path class="st5" d="M1916.5,1042c-1.6,0.4-2,2-1.4,3.1c0.4,0.8,1.1,1.4,2.1,1.3c0.1,0,0.2,0,0.3,0
                C1920.3,1045.7,1919.3,1041.3,1916.5,1042z"/>
              <path class="st5" d="M1909.2,1020.2c0.1,0,0.2,0,0.3,0c2.3-0.5,2-3.5,0.4-4.2c-0.4-0.2-0.9-0.2-1.4-0.1
                C1905.7,1016.5,1906.6,1020.6,1909.2,1020.2z"/>
              <path class="st5" d="M1923.2,1035.7c0.5,0.4,1.1,0.5,1.8,0.4c2.8-0.7,1.8-5-1-4.4c-1.6,0.4-2,2-1.4,3.2
                C1922.8,1035.1,1923,1035.4,1923.2,1035.7z"/>
              <path class="st5" d="M1871.9,997.4c-0.5,0.2-0.8,0.6-1.1,0.9c1.3,1,2.5,2.1,3.7,3.2C1876.6,999.9,1874.6,996.2,1871.9,997.4z"
                />
              <path class="st5" d="M1882.7,999c-2.9,1.4-0.7,5.8,2.1,4.4C1887.7,1002.1,1885.6,997.6,1882.7,999z"/>
              <path class="st5" d="M1895.9,1001.6c-2.9,1.4-0.7,5.8,2.1,4.4C1900.9,1004.7,1898.8,1000.2,1895.9,1001.6z"/>
              <path class="st5" d="M1883.2,989c-2.9,1.4-0.7,5.8,2.1,4.4C1888.2,992.1,1886.1,987.6,1883.2,989z"/>
              <path class="st5" d="M1894.1,996.8c0.9,1,2.4,1.2,3.5,0.2c0.5-0.4,0.9-0.8,1.4-1.2c1-0.9,1.2-2.5,0.2-3.5
                c-0.9-1-2.4-1.2-3.5-0.2c-0.5,0.4-0.9,0.8-1.4,1.2C1893.3,994.1,1893.1,995.8,1894.1,996.8z"/>
              <path class="st5" d="M1892.2,1011.9c0.4-1.3-0.2-2.7-1.5-3.1c-0.2-0.1-0.4-0.2-0.7-0.2c-1.2-0.4-2.8,0.2-3.1,1.5
                c-0.4,1.3,0.2,2.7,1.5,3.1c0.2,0.1,0.4,0.2,0.7,0.2C1890.3,1013.9,1891.8,1013.2,1892.2,1011.9z"/>
              <path class="st5" d="M1874.7,981.7c-1.3-0.1-2.6,0.9-2.7,2.2c0,0.6,0,1.1,0,1.7c0,0.5,0.1,1,0.4,1.5c0.4,0.7,1,1.2,1.9,1.2
                c0.7,0,1.4-0.2,1.9-0.7c0.4-0.4,0.8-0.9,0.8-1.6c0-0.6,0-1.1,0-1.7C1877,983.1,1876.1,981.8,1874.7,981.7z"/>
              <path class="st5" d="M1903.9,1019.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
                c-0.1-0.2-0.1-0.4-0.2-0.5c-0.4-1.2-2.2-1.7-3.3-1.2c-1.3,0.7-1.6,2-1.2,3.3c0.2,0.5,0.5,1,0.8,1.4c0.5,0.7,1.2,1.3,1.9,1.7
                c0.6,0.3,1.4,0.5,2,0.3c0.5-0.1,1-0.4,1.4-1C1905.2,1021.6,1905.1,1019.9,1903.9,1019.2z M1903.5,1019
                c-0.1-0.1-0.2-0.1-0.2-0.2C1903.3,1018.9,1903.4,1018.9,1903.5,1019z M1903.2,1018.7c0,0-0.1-0.1-0.1-0.2
                C1903.1,1018.5,1903.1,1018.6,1903.2,1018.7z"/>
              <path class="st5" d="M1905.1,1008.6c-2.9,1.4-0.7,5.8,2.1,4.4C1910.1,1011.7,1908,1007.3,1905.1,1008.6z"/>
              <path class="st5" d="M1892.6,986.9c2.9-1.4,0.7-5.8-2.1-4.4C1887.6,983.9,1889.7,988.3,1892.6,986.9z"/>
              <path class="st5" d="M1912.9,1000.4c2.9-1.4,0.7-5.8-2.1-4.4C1907.9,997.3,1910,1001.7,1912.9,1000.4z"/>
              <path class="st5" d="M1853.8,986.3c-0.1,0-0.2,0.1-0.2,0.1c1.3,0.7,2.5,1.4,3.7,2.2c0-0.5-0.2-1.1-0.5-1.5
                C1856.2,986.2,1855.1,985.7,1853.8,986.3z"/>
              <path class="st5" d="M2057.5,1024.5c-2.5,1.1-0.8,5,1.7,3.9C2061.7,1027.3,2060,1023.4,2057.5,1024.5z"/>
              <path class="st5" d="M2064.4,1035.7c-2.5,1.1-0.8,5,1.7,3.9C2068.6,1038.5,2066.9,1034.6,2064.4,1035.7z"/>
              <path class="st5" d="M2066.8,1026.1c-2.5,1.1-0.8,5,1.7,3.9C2071,1028.9,2069.3,1025,2066.8,1026.1z"/>
              <path class="st5" d="M2069.2,1021.4c2.5-1.1,0.8-5-1.7-3.9C2065,1018.6,2066.7,1022.5,2069.2,1021.4z"/>
              <path class="st5" d="M2055.3,1033.3c-0.1,0-0.2,0-0.2,0.1c-1.8,0.8-1.4,3.1-0.1,3.8c0.5,0.3,1.1,0.4,1.8,0.1
                C2059.2,1036.3,2057.7,1032.6,2055.3,1033.3z"/>
              <path class="st5" d="M2070.1,1035.8c-0.3,0.8-0.1,1.7,0.5,2.2v-2.9C2070.3,1035.3,2070.2,1035.5,2070.1,1035.8z"/>
              <path class="st5" d="M2063.6,1048.9c0.9-0.8,1.1-2.1,0.3-3c-0.3-0.4-0.7-0.8-1-1.2c-0.7-0.9-2.1-1.1-3-0.3
                c-0.9,0.8-1.1,2.1-0.3,3c0.3,0.4,0.7,0.8,1,1.2C2061.4,1049.4,2062.8,1049.7,2063.6,1048.9z"/>
              <path class="st5" d="M2059.7,1016.7c1.1,0.1,2.3-0.7,2.4-1.9c0-0.5,0.1-1,0.1-1.5c0.1-1.1-0.7-2.3-1.9-2.4
                c-1.1-0.1-2.3,0.7-2.4,1.9c0,0.5-0.1,1-0.1,1.5C2057.8,1015.5,2058.6,1016.6,2059.7,1016.7z"/>
              <path class="st5" d="M2068.1,1048c0.2,0.3,0.4,0.6,0.6,0.8c0.6-1.2,1-2.5,1.3-3.9c-0.3,0.1-0.6,0.2-0.9,0.3
                C2068.2,1045.6,2067.7,1047.1,2068.1,1048z"/>
              <path class="st5" d="M2063.6,1053.8c-1.1,0.5-1.4,1.4-1.2,2.3c1-0.7,2-1.4,2.9-2.3C2064.8,1053.6,2064.2,1053.6,2063.6,1053.8
                z"/>
              <path class="st5" d="M2045.5,1027.4c-0.5,0.2-0.8,0.6-1,0.9c-0.6,1.1,0.1,2.7,1.3,3c0.4,0.1,0.9,0.1,1.4-0.1
                C2049.8,1030.2,2048.1,1026.3,2045.5,1027.4z"/>
              <path class="st5" d="M2051.9,1048.8c-2.5,1.1-0.8,5,1.7,3.9C2056.1,1051.6,2054.4,1047.7,2051.9,1048.8z"/>
              <path class="st5" d="M2043.4,1045.1c2.5-1.1,0.8-5-1.7-3.9C2039.2,1042.3,2040.9,1046.2,2043.4,1045.1z"/>
              <path class="st5" d="M2043.9,1018.3c2.5-1.1,0.8-5-1.7-3.9C2039.7,1015.5,2041.4,1019.4,2043.9,1018.3z"/>
              <path class="st5" d="M1769.7,949.3c-1-0.2-1.8,0.3-2.3,0.9c-0.3,0.4-0.4,0.8-0.5,1.3c-0.1,1.2,0.5,2.5,2,2.7
                C1772.1,954.7,1772.9,949.8,1769.7,949.3z"/>
              <path class="st5" d="M1769.4,964.7c-0.1,0-0.3,0-0.4,0c-1.8,0-2.7,1.8-2.3,3.3c0.2,0.8,0.8,1.5,1.9,1.7
                C1771.9,970.2,1772.6,965.2,1769.4,964.7z"/>
              <path class="st5" d="M1777.9,956.7c-3.2-0.5-3.9,4.5-0.7,4.9C1780.3,962.1,1781,957.2,1777.9,956.7z"/>
              <path class="st5" d="M1789.4,968.1c-0.2-0.8-0.8-1.5-1.9-1.7c-2.4-0.4-3.4,2.3-2.4,3.9c1.4-0.1,2.8-0.1,4.1-0.1
                C1789.5,969.5,1789.6,968.8,1789.4,968.1z"/>
              <path class="st5" d="M1784,948.6c-3.2-0.5-3.9,4.5-0.7,4.9C1786.4,954,1787.2,949.1,1784,948.6z"/>
              <path class="st5" d="M1761.6,956.6c-3.2-0.5-3.9,4.5-0.7,4.9C1764.1,962,1764.8,957.1,1761.6,956.6z"/>
              <path class="st5" d="M1795.5,960.5c-0.1-0.7-0.5-1.3-1-1.7c-0.5-0.4-1.1-0.6-1.8-0.5c-0.6,0.1-1.2,0.2-1.8,0.2
                c-1.3,0.2-2.4,1.4-2.2,2.8c0.2,1.3,1.4,2.4,2.8,2.2c0.6-0.1,1.2-0.2,1.8-0.2c0.8-0.1,1.5-0.6,1.9-1.2
                C1795.4,961.5,1795.5,961,1795.5,960.5z"/>
              <path class="st5" d="M1778.6,968.9c-0.8-1.1-2.4-1.4-3.5-0.5c-0.9,0.7-1.2,1.9-0.8,3c1.7-0.2,3.5-0.4,5.2-0.6
                c0-0.4-0.2-0.9-0.5-1.2C1778.9,969.3,1778.7,969.1,1778.6,968.9z"/>
              <path class="st5" d="M1759.6,970.6c0,0-0.1,0-0.1,0c-1.3,0.3-2.2,1.4-2.1,2.7c0.1,0.5,0.1,1,0.2,1.5c1.6-0.5,3.2-0.9,4.9-1.3
                c0-0.3-0.1-0.5-0.1-0.8C1762.3,971.5,1761,970.3,1759.6,970.6z"/>
              <path class="st5" d="M1776.8,942.9c1,0.8,2.7,0.8,3.5-0.4c0.3-0.5,0.7-0.9,1-1.4c0.8-1.1,0.7-2.7-0.4-3.5
                c-1-0.8-2.7-0.8-3.5,0.4c-0.3,0.5-0.7,0.9-1,1.4C1775.7,940.5,1775.7,942.1,1776.8,942.9z"/>
              <path class="st5" d="M1795.4,948.4c-0.3-0.6-0.9-1-1.7-1.1c-3.2-0.5-3.9,4.5-0.7,4.9c1,0.2,1.8-0.2,2.2-0.9
                C1795.8,950.5,1796,949.3,1795.4,948.4z"/>
              <path class="st5" d="M1755.2,949.6c3.2,0.5,3.9-4.5,0.7-4.9C1752.8,944.2,1752,949.1,1755.2,949.6z"/>
              <path class="st5" d="M1748.8,969.9c-0.5-0.1-1,0-1.4,0.2c-0.6,0.3-1,0.8-1.2,1.4c-0.5,1.3,0.1,3,1.9,3.3
                C1751.3,975.3,1752,970.4,1748.8,969.9z"/>
              <path class="st5" d="M1803.2,970.1c-0.7-0.1-1.3,0.1-1.8,0.4c1.1,0.1,2.1,0.2,3.1,0.3C1804.2,970.4,1803.8,970.2,1803.2,970.1
                z"/>
              <path class="st5" d="M1743.5,955.9c-3.2-0.5-3.9,4.5-0.7,4.9C1745.9,961.3,1746.6,956.4,1743.5,955.9z"/>
              <path class="st5" d="M1760.1,934.6c3.2,0.5,3.9-4.5,0.7-4.9C1757.6,929.2,1756.9,934.1,1760.1,934.6z"/>
              <path class="st5" d="M1948.4,1035.3c3.3,0.8,4.6-4.3,1.3-5.1c-1.5-0.4-2.5,0.4-3,1.5c-0.2,0.4-0.2,0.8-0.2,1.2
                C1946.5,1033.9,1947.1,1034.9,1948.4,1035.3z"/>
              <path class="st5" d="M1947.8,1046.3c-3.3-0.8-4.6,4.3-1.3,5.1C1949.8,1052.2,1951.1,1047.1,1947.8,1046.3z"/>
              <path class="st5" d="M1956.1,1038.8c-2.2,0.6-2.7,4.4,0,5.1c3,0.8,4.3-3.4,2-4.8c-0.2-0.1-0.4-0.2-0.7-0.3
                C1957,1038.6,1956.5,1038.7,1956.1,1038.8z"/>
              <path class="st5" d="M1966.5,1049.9c-1.9-0.5-3.2,1.1-3.2,2.6c0,1.1,0.6,2.1,1.9,2.5c0.5,0.1,1,0.1,1.4,0
                C1968.8,1054.3,1969.3,1050.6,1966.5,1049.9z"/>
              <path class="st5" d="M1963.4,1036c3.3,0.8,4.6-4.3,1.3-5.1C1961.4,1030.1,1960.1,1035.2,1963.4,1036z"/>
              <path class="st5" d="M1940.5,1037c-0.9-0.2-1.6,0-2.2,0.4c-1.4,1.1-1.5,3.8,0.6,4.6c0.1,0,0.2,0.1,0.2,0.1
                C1942.5,1042.9,1943.8,1037.8,1940.5,1037z"/>
              <path class="st5" d="M1975.5,1044.4c-0.1-1.4-1.2-2.6-2.7-2.6c-0.6,0-1.3,0-1.9,0.1c-1.4,0-2.7,1.3-2.6,2.7
                c0.1,1.4,1.2,2.6,2.7,2.6c0.6,0,1.3,0,1.9-0.1C1974.3,1047.1,1975.6,1045.9,1975.5,1044.4z"/>
              <path class="st5" d="M1957.4,1052.2c-0.1-0.2-0.3-0.4-0.4-0.7c-0.4-0.7-1.3-1.2-2.2-1.3c-0.5,0-1,0.1-1.4,0.4
                c-1.2,0.8-1.7,2.3-0.9,3.6c0.1,0.2,0.3,0.4,0.4,0.7c0.7,1.2,2.4,1.7,3.6,0.9c0.7-0.5,1.2-1.2,1.3-2
                C1957.7,1053.3,1957.6,1052.7,1957.4,1052.2z"/>
              <path class="st5" d="M1937,1051.4c-1.4,0.1-2.6,1.2-2.6,2.7c0,0.6,0,1.3,0.1,1.9c0,0.2,0.1,0.4,0.1,0.7c0,0.1,0.1,0.2,0.1,0.3
                c0.1,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0.5,0.6,1.2,1,2,1c1.4-0.1,2.6-1.2,2.6-2.7
                c0-0.6,0-1.3-0.1-1.9C1939.6,1052.6,1938.4,1051.3,1937,1051.4z"/>
              <path class="st5" d="M1962.6,1019.1c-1-0.9-2.7-1.1-3.7,0c-0.4,0.5-0.8,0.9-1.2,1.4c-0.9,1-1.1,2.7,0,3.7c1,0.9,2.7,1.1,3.7,0
                c0.4-0.5,0.8-0.9,1.2-1.4C1963.5,1021.8,1963.7,1020.1,1962.6,1019.1z"/>
              <path class="st5" d="M1975.1,1030.5c-0.5,0.5-1.3,0.6-2,0.2c-1.1,0.7-1.6,2.3-1,3.5c0.2,0.5,0.6,0.9,1.2,1.1
                c0.1,0.1,0.3,0.1,0.4,0.2C1977,1036.4,1978.2,1031.4,1975.1,1030.5z"/>
              <path class="st5" d="M1932.6,1027.1c0.2,0.8,0.8,1.6,1.8,1.9c3.3,0.8,4.6-4.3,1.3-5.1c-0.1,0-0.3,0-0.4-0.1
                C1933.4,1023.6,1932.3,1025.5,1932.6,1027.1z"/>
              <path class="st5" d="M1925.7,1049.6c-0.2,0-0.4-0.1-0.6-0.1c-2.2-0.1-3.2,2.5-2.2,4.1c0.3,0.5,0.8,0.9,1.5,1.1
                C1927.7,1055.5,1929,1050.4,1925.7,1049.6z"/>
              <path class="st5" d="M1982.6,1055.3c-2.4-0.6-3.8,2-2.9,3.8h4.5C1984.8,1057.7,1984.5,1055.8,1982.6,1055.3z"/>
              <path class="st5" d="M1923.4,1037.5c0.1-0.6,0.1-1.2-0.2-1.8c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.5
                c-2.9-0.7-4.2,3.1-2.3,4.6c0.3,0.2,0.6,0.4,1,0.5C1921.9,1039.9,1923.1,1038.8,1923.4,1037.5z"/>
              <path class="st5" d="M1941.1,1013.8c3.3,0.8,4.6-4.3,1.3-5.1C1939.1,1007.9,1937.8,1013,1941.1,1013.8z"/>
              <path class="st5" d="M1988.8,1002.7c-2-1.6-4.4,1.5-2.4,3.1C1988.4,1007.3,1990.8,1004.2,1988.8,1002.7z"/>
              <path class="st5" d="M1980.2,1016.2c2,1.6,4.4-1.5,2.4-3.1C1980.6,1011.6,1978.2,1014.6,1980.2,1016.2z"/>
              <path class="st5" d="M1991.5,1010.9c-2-1.6-4.4,1.5-2.4,3.1C1991.1,1015.6,1993.5,1012.5,1991.5,1010.9z"/>
              <path class="st5" d="M1994.3,1021.3c-2-1.6-4.4,1.5-2.4,3.1C1993.9,1026,1996.3,1022.9,1994.3,1021.3z"/>
              <path class="st5" d="M1998.9,1007.8c-2-1.6-4.4,1.5-2.4,3.1C1998.4,1012.4,2000.8,1009.3,1998.9,1007.8z"/>
              <path class="st5" d="M1978,1007.6c2,1.6,4.4-1.5,2.4-3.1C1978.5,1003,1976,1006,1978,1007.6z"/>
              <path class="st5" d="M2002.1,1020.3c0.4-1,0-2.2-1-2.6c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.2,0-2.6,1c-0.4,1,0,2.2,1,2.6
                c0.5,0.2,0.9,0.4,1.4,0.6C2000.5,1021.8,2001.7,1021.3,2002.1,1020.3z"/>
              <path class="st5" d="M1987.3,1019.5c-0.1-1-1.1-1.9-2.2-1.7c-1.1,0.2-1.9,1.1-1.7,2.2c0,0.2,0,0.4,0.1,0.6
                c0.1,1,1.1,1.9,2.2,1.7c1.1-0.2,1.9-1.1,1.7-2.2C1987.3,1019.9,1987.3,1019.7,1987.3,1019.5z"/>
              <path class="st5" d="M1971.6,1018.3c1,0.4,2.2,0,2.6-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.6c-1-0.4-2.2,0-2.6,1
                c-0.2,0.5-0.4,0.9-0.6,1.4C1970.1,1016.6,1970.6,1017.9,1971.6,1018.3z"/>
              <path class="st5" d="M1998.7,1002.2c0.4-0.2,0.8-0.4,1.2-0.6c0.9-0.4,1.6-1.5,1.1-2.5c-0.4-0.9-1.6-1.6-2.5-1.1
                c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0.4-1.6,1.5-1.1,2.5C1996.6,1002,1997.7,1002.7,1998.7,1002.2z"/>
              <path class="st5" d="M1974.2,1021.6c-0.7,0.9-0.5,2,0.3,2.8c0.6,0.5,1.2,1.1,1.8,1.6c0.8,0.7,2.2,0.5,2.8-0.3
                c0.7-0.9,0.5-2-0.3-2.8c-0.6-0.5-1.2-1.1-1.8-1.6C1976.2,1020.6,1974.8,1020.8,1974.2,1021.6z"/>
              <path class="st5" d="M1984.7,1029.3c-0.9-0.7-2-0.5-2.8,0.3c-0.8,0.8-1.3,2-1.3,3.1c0,0.1,0,0.2,0,0.4
                c0.1,0.9,0.7,1.8,1.7,1.9c0.2,0,0.4,0,0.6,0c0.8-0.1,1.6-0.7,1.7-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
                c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3C1985.7,1031.3,1985.5,1029.9,1984.7,1029.3z M1984.5,1032.9
                c0-0.1,0-0.2,0.1-0.2C1984.6,1032.7,1984.6,1032.8,1984.5,1032.9z M1984.6,1032.5c0,0,0-0.1,0.1-0.2
                C1984.7,1032.4,1984.7,1032.5,1984.6,1032.5z"/>
              <path class="st5" d="M1992.5,1030.4c-2-1.6-4.4,1.5-2.4,3.1C1992.1,1035,1994.5,1032,1992.5,1030.4z"/>
              <path class="st5" d="M1975.1,1030.5c0.9-0.7,1.2-2.1,0.1-3.1c-2-1.6-4.4,1.5-2.4,3.1c0.1,0.1,0.2,0.2,0.3,0.2
                C1973.8,1031.1,1974.6,1031,1975.1,1030.5z"/>
              <path class="st5" d="M1967.8,1023c0.6-0.8,0.6-1.9-0.3-2.7c-2-1.6-4.4,1.5-2.4,3.1c0.2,0.2,0.4,0.3,0.6,0.3
                C1966.5,1024.1,1967.3,1023.7,1967.8,1023z"/>
              <path class="st5" d="M2006.1,1010.6c-2-1.6-4.4,1.5-2.4,3.1C2005.6,1015.3,2008,1012.2,2006.1,1010.6z"/>
              <path class="st5" d="M1978.8,997.2c2,1.6,4.4-1.5,2.4-3.1C1979.2,992.6,1976.8,995.7,1978.8,997.2z"/>
              <path class="st5" d="M1964.1,1011.7c2,1.6,4.4-1.5,2.4-3.1C1964.5,1007,1962.1,1010.1,1964.1,1011.7z"/>
              <path class="st5" d="M2003.6,1029.9c-2-1.6-4.4,1.5-2.4,3.1C2003.2,1034.6,2005.6,1031.5,2003.6,1029.9z"/>
              <path class="st5" d="M1965.8,1000c2,1.6,4.4-1.5,2.4-3.1C1966.3,995.4,1963.8,998.5,1965.8,1000z"/>
              <path class="st5" d="M1988,988.8c2,1.6,4.4-1.5,2.4-3.1C1988.4,984.2,1986,987.3,1988,988.8z"/>
              <path class="st5" d="M2023.9,985.8c2.7,0.5,3.4-3.8,0.7-4.2C2021.9,981.1,2021.2,985.4,2023.9,985.8z"/>
              <path class="st5" d="M2023.4,999.1c2.7,0.5,3.4-3.8,0.7-4.2C2021.4,994.4,2020.7,998.6,2023.4,999.1z"/>
              <path class="st5" d="M2030.8,992.3c2.7,0.5,3.4-3.8,0.7-4.2C2028.8,987.6,2028,991.9,2030.8,992.3z"/>
              <path class="st5" d="M2038.9,1000.8c2.7,0.5,3.4-3.8,0.7-4.2C2036.9,996.1,2036.2,1000.3,2038.9,1000.8z"/>
              <path class="st5" d="M2036.2,985.5c2.7,0.5,3.4-3.8,0.7-4.2C2034.1,980.8,2033.4,985,2036.2,985.5z"/>
              <path class="st5" d="M2017.6,987.7c-2.7-0.5-3.4,3.8-0.7,4.2C2019.6,992.4,2020.3,988.2,2017.6,987.7z"/>
              <path class="st5" d="M2046.5,991.6c-0.2-1.1-1.2-2-2.3-1.9c-0.5,0.1-1.1,0.1-1.6,0.2c-0.6,0.1-1.2,0.4-1.5,0.9
                c-0.3,0.4-0.4,0.9-0.4,1.4c0.1,0.7,0.6,1.4,1.2,1.7c0.3,0.2,0.7,0.3,1.1,0.2c0.5-0.1,1.1-0.1,1.6-0.2
                C2045.7,993.8,2046.7,992.7,2046.5,991.6z"/>
              <path class="st5" d="M2028.8,1001.6c0.6,0.9,2.1,1.2,3,0.5c0.9-0.7,1.2-2,0.5-3c-0.1-0.2-0.2-0.3-0.4-0.5
                c-0.6-0.9-2.1-1.2-3-0.5c-0.9,0.7-1.2,2-0.5,3C2028.5,1001.2,2028.7,1001.4,2028.8,1001.6z"/>
              <path class="st5" d="M2016.2,1005.5c1.1-0.2,2-1.2,1.9-2.3c-0.1-0.5-0.1-1.1-0.2-1.6c-0.1-1.1-1.2-2.1-2.3-1.9
                c-1.1,0.2-2,1.2-1.9,2.3c0.1,0.5,0.1,1.1,0.2,1.6C2014,1004.7,2015.1,1005.7,2016.2,1005.5z"/>
              <path class="st5" d="M2031.4,972.1c-0.3,0.4-0.6,0.8-0.9,1.2c-0.7,0.9-0.7,2.3,0.3,3c0.9,0.7,2.3,0.7,3-0.3
                c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,0.7-2.3-0.3-3C2033.5,971.1,2032.1,971.1,2031.4,972.1z"/>
              <path class="st5" d="M2022.3,1009.8c0.9,0.2,1.7,0.4,2.6,0.6c1.1,0.3,2.3-0.7,2.5-1.8c0.2-1.2-0.6-2.2-1.8-2.5
                c-0.9-0.2-1.7-0.4-2.6-0.6c-1.1-0.3-2.3,0.7-2.5,1.8C2020.3,1008.6,2021.1,1009.5,2022.3,1009.8z"/>
              <path class="st5" d="M2036.4,1013.1c0-0.1-0.1-0.2-0.2-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.1-0.5
                c0.3-1.1-0.7-2.3-1.8-2.5c-1.2-0.2-2.2,0.6-2.5,1.8c-0.3,1.2-0.2,2.5,0.4,3.6c0.5,1,1.6,1.7,2.8,1.2
                C2036.2,1015.4,2037,1014.2,2036.4,1013.1z M2036.2,1012.2c0,0,0,0.1,0,0.2C2036.2,1012.3,2036.2,1012.2,2036.2,1012.2z
                M2036.3,1012.7c0-0.1-0.1-0.2,0-0.2C2036.2,1012.6,2036.2,1012.7,2036.3,1012.7z"/>
              <path class="st5" d="M2041.9,1010.4c2.7,0.5,3.4-3.8,0.7-4.2C2039.9,1005.7,2039.2,1010,2041.9,1010.4z"/>
              <path class="st5" d="M2024.5,1012.4c-2.7-0.5-3.4,3.8-0.7,4.2C2026.5,1017.1,2027.2,1012.9,2024.5,1012.4z"/>
              <path class="st5" d="M2013.4,1009.7c-2.7-0.5-3.4,3.8-0.7,4.2C2015.4,1014.4,2016.1,1010.1,2013.4,1009.7z"/>
              <path class="st5" d="M2044.5,984.5c2.7,0.5,3.4-3.8,0.7-4.2C2042.5,979.8,2041.8,984,2044.5,984.5z"/>
              <path class="st5" d="M2012.2,981.6c2.7,0.5,3.4-3.8,0.7-4.2C2010.2,976.9,2009.4,981.2,2012.2,981.6z"/>
              <path class="st5" d="M2005.6,1003.2c2.7,0.5,3.4-3.8,0.7-4.2C2003.6,998.5,2002.9,1002.7,2005.6,1003.2z"/>
              <path class="st5" d="M2054,1003.7c0.6-0.6,0.8-1.5,0.6-2.3c-0.2-0.7-0.7-1.3-1.6-1.4c-0.1,0-0.2,0-0.3,0
                c-1.4-0.1-2.2,1.3-2.1,2.4c0.1,0.8,0.6,1.6,1.7,1.8C2053,1004.3,2053.6,1004.1,2054,1003.7z"/>
              <path class="st5" d="M2001.2,991.1c2.7,0.5,3.4-3.8,0.7-4.2C1999.2,986.4,1998.5,990.6,2001.2,991.1z"/>
              <path class="st5" d="M2016.6,968.8c2.7,0.5,3.4-3.8,0.7-4.2C2014.6,964.1,2013.8,968.4,2016.6,968.8z"/>
              <path class="st5" d="M2051.3,942.9c0-0.4,0-0.8,0.1-1.2c-1.1,0.4-1.8,2.1-0.4,3c0.4,0.3,0.9,0.3,1.2,0.2
                C2051.6,944.5,2051.3,943.7,2051.3,942.9z"/>
              <path class="st5" d="M2048.3,951.2c-1.8-1.2-3.6,1.6-1.8,2.8C2048.3,955.2,2050.1,952.4,2048.3,951.2z"/>
              <path class="st5" d="M2055.7,948.7c-1.8-1.2-3.6,1.6-1.8,2.8C2055.7,952.7,2057.5,949.9,2055.7,948.7z"/>
              <path class="st5" d="M2057,960.1c1.8,1.2,3.6-1.6,1.8-2.8C2057,956.1,2055.2,958.9,2057,960.1z"/>
              <path class="st5" d="M2061.7,945.5c-1.8-1.2-3.6,1.6-1.8,2.8C2061.7,949.5,2063.5,946.7,2061.7,945.5z"/>
              <path class="st5" d="M2045.9,944.1c-1.8-1.2-3.6,1.6-1.8,2.8C2045.8,948.1,2047.7,945.3,2045.9,944.1z"/>
              <path class="st5" d="M2064.3,953.8c-0.4-0.1-0.8-0.2-1.2-0.4c-0.8-0.3-1.9,0.2-2.1,1.1c-0.2,0.9,0.2,1.8,1.1,2.1
                c0.4,0.1,0.8,0.2,1.2,0.4c0.8,0.3,1.9-0.2,2.1-1.1C2065.6,955,2065.2,954.1,2064.3,953.8z"/>
              <path class="st5" d="M2049.5,957c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.1,1.5,2,1.3c0.9-0.2,1.5-1.1,1.3-2c0-0.2-0.1-0.3-0.1-0.5
                c-0.2-0.9-1.1-1.5-2-1.3C2049.9,955.3,2049.3,956.1,2049.5,957z"/>
              <path class="st5" d="M2040.7,952c-0.9-0.2-1.8,0.2-2.1,1.1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.3,0.8,0.2,1.9,1.1,2.1
                c0.9,0.2,1.8-0.2,2.1-1.1c0.1-0.4,0.2-0.8,0.4-1.2C2042.1,953.3,2041.6,952.3,2040.7,952z"/>
              <path class="st5" d="M2062.9,938c-0.4-0.8-1.4-1.2-2.2-0.8c-0.3,0.2-0.7,0.4-1,0.6c-0.8,0.4-1.2,1.4-0.8,2.2
                c0.4,0.8,1.4,1.2,2.2,0.8c0.1-0.1,0.2-0.1,0.4-0.2c0.3-0.7,0.8-1.3,1.5-1.5C2063.1,938.7,2063.1,938.3,2062.9,938z"/>
              <path class="st5" d="M2041.8,959c-0.5,0.8-0.3,1.7,0.5,2.3c0.5,0.4,1.1,0.8,1.6,1.3c0.7,0.5,1.9,0.3,2.3-0.5
                c0.5-0.8,0.3-1.7-0.5-2.3c-0.5-0.4-1.1-0.8-1.6-1.3C2043.5,958,2042.3,958.3,2041.8,959z"/>
              <path class="st5" d="M2049,965.3c-0.6,0.7-0.9,1.7-0.9,2.7c0,0.9,0.6,1.7,1.6,1.8c0.8,0,1.8-0.7,1.8-1.6c0-0.1,0-0.2,0-0.3
                c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.9-0.5-2.3
                C2050.4,964.3,2049.6,964.6,2049,965.3z M2051.5,967.4c0,0,0,0.1-0.1,0.1C2051.5,967.5,2051.5,967.4,2051.5,967.4z
                M2051.4,967.8c0-0.1,0-0.1,0-0.2C2051.4,967.7,2051.4,967.8,2051.4,967.8z"/>
              <path class="st5" d="M2056.2,967.9c1.8,1.2,3.6-1.6,1.8-2.8C2056.2,963.9,2054.4,966.7,2056.2,967.9z"/>
              <path class="st5" d="M2043.1,963.9c-1.8-1.2-3.6,1.6-1.8,2.8C2043,967.9,2044.9,965.1,2043.1,963.9z"/>
              <path class="st5" d="M2034.2,961.3c1.8,1.2,3.6-1.6,1.8-2.8C2034.2,957.3,2032.4,960.1,2034.2,961.3z"/>
              <path class="st5" d="M2066.2,950.2c1.8,1.2,3.6-1.6,1.8-2.8C2066.2,946.2,2064.4,949,2066.2,950.2z"/>
              <path class="st5" d="M2045.2,935c-1.1-0.3-2.1,0.8-2.1,1.8c0.4,0,0.9,0.1,1.4,0.4c0.4,0.3,0.6,0.6,0.8,1
                c0.9-0.4,1.5-1.6,0.9-2.5c-0.2-0.1-0.3-0.1-0.4-0.3C2045.6,935.3,2045.4,935.2,2045.2,935z"/>
              <path class="st5" d="M2032.5,951.4c1.8,1.2,3.6-1.6,1.8-2.8C2032.5,947.4,2030.7,950.2,2032.5,951.4z"/>
              <path class="st5" d="M2065.5,966.7c1.8,1.2,3.6-1.6,1.8-2.8C2065.6,962.7,2063.7,965.5,2065.5,966.7z"/>
              <path class="st5" d="M2035,938.6c-1.8-1.2-3.6,1.6-1.8,2.8C2034.9,942.6,2036.8,939.8,2035,938.6z"/>
              <path class="st5" d="M2052.9,927.5c-1.8-1.2-3.6,1.6-1.8,2.8c1.3,0.9,2.6-0.4,2.6-1.6c0-0.1,0-0.2-0.1-0.4
                C2053.4,928,2053.2,927.7,2052.9,927.5z"/>
              <path class="st5" d="M2055.9,911.3c2.4,1.7,5-2.1,2.6-3.8C2056.1,905.8,2053.5,909.6,2055.9,911.3z"/>
              <path class="st5" d="M2049.5,923.8c2.4,1.7,5-2.1,2.6-3.8C2049.7,918.4,2047.1,922.2,2049.5,923.8z"/>
              <path class="st5" d="M2059.7,920.6c2.4,1.7,5-2.1,2.6-3.8C2059.8,915.2,2057.2,919,2059.7,920.6z"/>
              <path class="st5" d="M2063.7,932.5c2.4,1.7,5-2.1,2.6-3.8C2063.9,927,2061.3,930.8,2063.7,932.5z"/>
              <path class="st5" d="M2068,916.4c0.9,0.6,1.9,0.5,2.6-0.1v-3.7C2068.1,911,2065.5,914.8,2068,916.4z"/>
              <path class="st5" d="M2046.4,914c2.4,1.7,5-2.1,2.6-3.8C2046.5,908.6,2044,912.4,2046.4,914z"/>
              <path class="st5" d="M2069.3,925c-0.3,1.1,0.1,2.3,1.2,2.8v-4.1C2070,923.9,2069.5,924.3,2069.3,925z"/>
              <path class="st5" d="M2053.6,928.7c0.2,1.2,1.5,2.1,2.7,1.8c1.2-0.3,2.1-1.4,1.8-2.7c0-0.2-0.1-0.4-0.1-0.6
                c-0.2-1.2-1.5-2.1-2.7-1.8c-1.2,0.3-2.1,1.4-1.8,2.7c0,0.1,0,0.2,0.1,0.3C2053.6,928.4,2053.6,928.5,2053.6,928.7
                C2053.6,928.7,2053.6,928.7,2053.6,928.7z"/>
              <path class="st5" d="M2039.7,926.9c1.2,0.4,2.5-0.2,2.9-1.4c0.2-0.5,0.4-1.1,0.5-1.6c0.4-1.1-0.2-2.6-1.4-2.9
                c-1.2-0.4-2.5,0.2-2.9,1.4c-0.2,0.5-0.4,1.1-0.5,1.6C2037.9,925.1,2038.5,926.5,2039.7,926.9z"/>
              <path class="st5" d="M2069.4,901.3c-0.5,0.2-0.9,0.5-1.4,0.7c-1.1,0.6-1.7,1.9-1.1,3c0.5,1,1.9,1.7,3,1.1
                c0.2-0.1,0.4-0.2,0.6-0.3v-4.8C2070.2,901.1,2069.8,901.1,2069.4,901.3z"/>
              <path class="st5" d="M2043.6,933.7c0.5,0.4,1.1,0.9,1.6,1.3c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.3
                c0.9,0.4,2.2,0,2.7-0.8c0.7-1.1,0.4-2.4-0.6-3.2c-0.7-0.6-1.5-1.2-2.2-1.8c-0.9-0.8-2.6-0.4-3.2,0.6
                C2042.3,931.6,2042.6,932.9,2043.6,933.7z"/>
              <path class="st5" d="M2055.7,938.7c-1.1-0.7-2.4-0.4-3.2,0.6c-0.6,0.7-1,1.5-1.1,2.4c-0.1,0.4-0.1,0.8-0.1,1.2
                c0,0.8,0.3,1.5,0.9,2c0.3,0.3,0.7,0.4,1.2,0.4c1.1,0,2.5-0.8,2.4-2.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3
                c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.3-0.3C2057.1,941,2056.7,939.3,2055.7,938.7z M2055.8,942.8
                c0-0.1,0-0.2,0-0.2C2055.8,942.7,2055.8,942.7,2055.8,942.8z M2055.9,942.4c0,0,0-0.1,0.1-0.2
                C2056,942.3,2056,942.3,2055.9,942.4z"/>
              <path class="st5" d="M2061.5,940.6c-0.3,0.9-0.2,1.9,0.8,2.6c2.4,1.7,5-2.1,2.6-3.8c-0.7-0.4-1.3-0.5-1.9-0.3
                C2062.3,939.3,2061.8,939.9,2061.5,940.6z"/>
              <path class="st5" d="M2044.6,937.3c-0.5-0.3-0.9-0.4-1.4-0.4c-1.9,0.1-3.2,2.8-1.2,4.2c2,1.4,4.2-1,3.4-2.8
                C2045.2,937.9,2045,937.5,2044.6,937.3z"/>
              <path class="st5" d="M2032.5,933.4c2.4,1.7,5-2.1,2.6-3.8C2032.7,927.9,2030.1,931.7,2032.5,933.4z"/>
              <path class="st5" d="M2046.5,901.9c2.4,1.7,5-2.1,2.6-3.8C2046.7,896.5,2044.1,900.3,2046.5,901.9z"/>
              <path class="st5" d="M2030.5,919.8c2.4,1.7,5-2.1,2.6-3.8C2030.7,914.4,2028.1,918.1,2030.5,919.8z"/>
              <path class="st5" d="M2031.7,906.2c2.4,1.7,5-2.1,2.6-3.8C2031.8,900.7,2029.3,904.5,2031.7,906.2z"/>
              <path class="st5" d="M2056.5,891.5c2.4,1.7,5-2.1,2.6-3.8C2056.7,886.1,2054.1,889.9,2056.5,891.5z"/>
            </g>
            <g>
              <path class="st5" d="M2015.7,1021.5c-1.7,15.4-10.6,26.7-20.3,37.6h-7.2c2.6-2.9,5.1-5.7,7.6-8.6
                c8.9-10.6,17.6-25.4,13.8-39.8c-3.5-13.4-16.9-18.9-28.9-22.4c-12.5-3.7-25.4-5.8-38.1-8.5c-12.9-2.7-25.7-6-37.7-11.5
                c-11.9-5.5-22.5-13.4-33.4-20.6c-11.2-7.5-22.7-13.9-35.8-17.3c-13.4-3.4-27.3-4-41.1-3.2c-18.3,1-36.3,4.4-54.4,7.1v-5.5
                c23.3-3.5,46.5-8.1,70.1-7.3c14.1,0.5,28.2,2.9,41.3,8.6c12.6,5.5,23.7,13.7,35,21.3c12.1,8.1,24.5,14.5,38.5,18.6
                c14.1,4.1,28.5,6.3,42.8,9.5C1990.3,984.3,2018.8,993.1,2015.7,1021.5z"/>
              <path class="st5" d="M2070.5,983.7v6.7c-15-10.5-28.6-22.9-41-37c-23.1-26.2-42.9-55.6-63.3-83.9
                c-10.7-14.8-21.7-29.5-31.5-44.8c-8.6-13.4-15.1-28.4-13.2-44.6c1.8-15.2,8.9-27.8,18.6-38.6l4.2,3.9
                c-13.4,14.9-21.8,32.9-15.7,54.2c4.6,16.1,15.8,30,25.4,43.4c10,14.2,20.2,28.2,30.3,42.3c18.9,26.2,37.4,53.1,60.1,76.2
                C2052.6,969.6,2061.3,977.1,2070.5,983.7z"/>
              <path class="st5" d="M1946.3,930.4c-18.3,2.8-37.9-4.6-53.3-13.9c-17.2-10.4-31.5-24.9-45.5-39.2
                c-14.5-14.8-29.4-29.1-46.3-41.2c-15.6-11.2-32.4-19.8-48.9-29.5c-4.1-2.4-8.2-5-12.1-7.9v-6.9c7.1,5.6,15,10.2,22.9,14.6
                c17.4,9.7,34.3,19.5,50,31.8c15.1,11.7,28.4,25.2,41.7,38.8c13.4,13.7,27.3,27.2,44.2,36.7c8.2,4.6,17,8.2,26.3,10.2
                c5.2,1.1,10.5,1.6,15.8,1.4c1.7,0,4.2,0.1,5.8-0.6c2.3-0.1,3-1.3,2-3.7c0-1,0-2-0.1-3c-0.3-0.7-1.3-1.5-1.8-2
                c-11.9-13.5-27-23.5-41.8-33.6c-14.8-10.1-28.7-20.8-41.2-33.7c-13.8-14.3-26.1-29.8-38.8-45.1
                c-13.4-16.1-26.8-32.1-40.1-48.2c-8.8-10.5-17.6-21-25.9-31.9h6.9c11.1,14.4,23.1,28.2,34.7,42.2
                c16.2,19.4,32.2,38.9,48.5,58.2c13.3,15.8,27.4,31,44,43.4c14.8,11,30.8,20.4,44.8,32.4c5.8,4.9,14.3,11.4,17.3,18.7
                C1958.5,925.3,1952.5,929.5,1946.3,930.4z"/>
              <g>
                <path class="st3" d="M1740.3,891.7c-1.9,0-3.5-1.6-3.5-3.5V741.4c0-11.9,9.6-21.5,21.5-21.5H1910c1.9,0,3.5,1.6,3.5,3.5
                  s-1.6,3.5-3.5,3.5h-151.7c-8,0-14.5,6.5-14.5,14.5v146.8C1743.8,890.1,1742.2,891.7,1740.3,891.7z"/>
              </g>
              <g>
                <path class="st0" d="M2070.5,869.2v19h-233.1c-5,0-9-4-9-9v-83.4c0-5,4-9,9-9h152l0.4,0.4l4.5,4.2l7.3,6.7l17.2,15.8l45.7,42
                  C2068.4,859.3,2070.5,864.1,2070.5,869.2z"/>
                <polygon class="st6" points="1963.7,786.8 1922.6,888.2 1873.6,888.2 1914.8,786.8 							"/>
                <polygon class="st6" points="2019,813.9 1988.8,888.2 1955.1,888.2 1994.4,791.4 2001.8,798.1 							"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path id="XMLID_12_" class="st5" d="M1685.8,602.6v413.1c0,9.9-8.1,18-18,18H492.5c-13,0-21.7-13.4-16.5-25.2
              c0.1-0.2,0.2-0.4,0.3-0.6l2-4.1l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8
              l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l8-16.7c3-6.2,9.3-10.2,16.2-10.2h976.5
              C1677.7,584.6,1685.8,592.6,1685.8,602.6z"/>
            <g>
              <path class="st4" d="M762.6,593c1-0.4,1.5-1.2,1.6-2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.7-0.7-0.8-1.4-0.7-2.2
                c-0.5-0.3-1.2-0.5-2-0.2C757.9,589.3,759.6,594.1,762.6,593z"/>
              <path class="st4" d="M778.2,586.2c0.4,0.1,0.9,0.1,1.4-0.1c0.8-0.3,1.3-0.9,1.5-1.5h-3.2C778.2,585.1,778.3,585.6,778.2,586.2
                z"/>
              <path class="st4" d="M751.2,589.5c0.3-1,1.2-1.8,2.3-1.9c0.2-1.2-0.4-2.6-1.6-3h-1.5c0,0-0.1,0-0.1,0
                C747.4,585.6,748.6,589.7,751.2,589.5z"/>
              <path class="st4" d="M767.6,590.3c0.1,0.8,0.6,1.6,1.4,2c0.2,0.1,0.4,0.2,0.7,0.3c1.2,0.6,2.8,0.1,3.4-1.2
                c0.5-1.3,0.1-2.8-1.2-3.4c-0.2-0.1-0.4-0.2-0.7-0.3c-1-0.5-2.3-0.2-3,0.6C768.2,589,768,589.7,767.6,590.3z"/>
              <path class="st4" d="M755.4,603.1c0.8,1.1,2.4,1.5,3.5,0.7c1.1-0.8,1.5-2.3,0.7-3.5c0,0,0,0-0.1-0.1c-0.5,0.4-1,0.6-1.7,0.6
                c-1.4,0-2.6-1.3-2.6-2.6c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.8-1.5,2.3-0.7,3.5
                C754.6,602,755,602.6,755.4,603.1z"/>
              <path class="st4" d="M772,600.8c-0.5-1.4-1.8-1.9-3.2-1.6c-1,0.3-2,0.5-3,0.8c-1.3,0.3-2,2-1.6,3.2c0.3,0.7,0.8,1.2,1.5,1.5
                c0,0-0.1,0-0.1-0.1c-2.3-2.4,1.4-6,3.7-3.6c1,1.1,0.9,2.3,0.2,3.3c0.3-0.1,0.6-0.2,1-0.2C771.8,603.6,772.5,602,772,600.8z"/>
              <path class="st4" d="M784,600.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0
                c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2,1-0.9,1.9-2.2,1.9c-0.3,0-0.5,0-0.7-0.1c0,0.3-0.1,0.7-0.3,1
                c-0.2,0.3-0.4,0.6-0.8,0.8c0.5,0.9,1.1,1.6,1.9,2.2c1.1,0.8,2.7,0.9,3.6-0.3C785,602.7,785.2,601,784,600.2z M783.2,599.3
                c0,0,0.1,0.1,0.1,0.2C783.3,599.5,783.2,599.4,783.2,599.3z M783.4,599.7c0.1,0.1,0.1,0.1,0.2,0.2
                C783.5,599.8,783.4,599.7,783.4,599.7z"/>
              <path class="st4" d="M788.1,594.3c3-1,1.5-5.5-1.4-4.8c1,1.5-0.1,3.3-1.5,3.8C785.8,594.2,786.8,594.7,788.1,594.3z"/>
              <path class="st4" d="M771.3,606.2c-3.1,1.1-1.4,5.8,1.6,4.8C776,609.9,774.3,605.2,771.3,606.2z"/>
              <path class="st4" d="M758.3,609.6c-3.1,1.1-1.4,5.8,1.6,4.8C763,613.3,761.3,608.5,758.3,609.6z"/>
              <path class="st4" d="M745.1,604c-0.3-0.4-0.6-0.8-1-1.1c-1,0.8-1.1,2.1-0.5,3.2c0.1,0.1,0.1,0.2,0.2,0.3
                c0.7-0.7,1.8-0.9,2.7-0.1c0.3,0.2,0.5,0.5,0.6,0.7c0.9-0.6,1.2-1.6,1.1-2.5C747.2,605,745.8,604.9,745.1,604z"/>
              <path class="st4" d="M736.2,594.7c0.1-0.3,0.2-0.6,0.4-0.9c-0.5-1.1-1.6-1.9-3-1.4c-1.1,0.4-1.6,1.2-1.6,2.1
                c0.8-0.3,1.8-0.2,2.6,0.6c0.6,0.6,0.8,1.4,0.7,2c0.1,0,0.2-0.1,0.3-0.1C735,596.3,735.3,595.1,736.2,594.7z"/>
              <path class="st4" d="M694,587.6c1.8,0,2.1-2.2,1.1-3.1H693C691.9,585.4,692.3,587.6,694,587.6z"/>
              <path class="st4" d="M695.3,598.1c2.2,0,2.2-3.4,0-3.4C693.1,594.6,693.1,598.1,695.3,598.1z"/>
              <path class="st4" d="M700.2,591.9c2.2,0,2.2-3.4,0-3.4C698.1,588.4,698,591.8,700.2,591.9z"/>
              <path class="st4" d="M707.7,597.5c1.3,0,1.9-1.2,1.6-2.2c-0.1,0-0.2,0-0.4,0c-0.8,0-1.3-0.6-1.4-1.2
                C705.5,594.3,705.5,597.5,707.7,597.5z"/>
              <path class="st4" d="M703.3,585.8c-0.2-0.4-0.2-0.8-0.1-1.2h-1.2C702.2,585.2,702.6,585.7,703.3,585.8z"/>
              <path class="st4" d="M689.3,593.3c2.2,0,2.2-3.4,0-3.4C687.1,589.9,687.1,593.3,689.3,593.3z"/>
              <path class="st4" d="M710.1,591.7c0.4-0.1,0.8-0.2,1.2-0.3c0.9-0.2,1.5-1.2,1.2-2.1c-0.3-0.9-1.2-1.5-2.1-1.2
                c-0.4,0.1-0.8,0.2-1.2,0.3c-0.9,0.2-1.5,1.2-1.2,2.1C708.3,591.4,709.2,592,710.1,591.7z"/>
              <path class="st4" d="M699.5,596.6c-0.6,0.7-0.7,1.7,0,2.4c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.6,1.8,0.7,2.4,0
                c0.6-0.7,0.7-1.7,0-2.4c-0.1-0.1-0.2-0.2-0.3-0.4C701.3,596,700.1,595.9,699.5,596.6z"/>
              <path class="st4" d="M689.7,604.1c0.3,0.1,0.5,0.1,0.8,0c0.9-0.3,1.5-1.2,1.2-2.1c-0.1-0.4-0.2-0.8-0.3-1.2
                c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.5,1.2-1.2,2.1c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.3,0.7,0.6,0.9
                C689.3,603.8,689.5,603.9,689.7,604.1z"/>
              <path class="st4" d="M696.8,603.3c-0.3,0-0.7-0.1-1-0.1c-0.9-0.1-1.8,0.8-1.7,1.7c0,1,0.7,1.6,1.7,1.7c0.1,0,0.2,0,0.3,0
                C695.3,605.6,695.4,604.1,696.8,603.3z"/>
              <path class="st4" d="M703.6,606.5c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.5,1.2,2.3,0.6c0.7-0.5,1.2-1.5,0.6-2.3
                c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.7c0,0-0.1,0-0.1,0
                C705.1,605.6,704.4,606.3,703.6,606.5z"/>
              <path class="st4" d="M711.3,601.3c-2.2,0-2.2,3.4,0,3.4C713.4,604.7,713.5,601.3,711.3,601.3z"/>
              <path class="st4" d="M697.8,608.5c-2.2,0-2.2,3.4,0,3.4C699.9,611.9,700,608.5,697.8,608.5z"/>
              <path class="st4" d="M689.8,609.5c0.2-0.1,0.4-0.1,0.5-0.2c0-0.8-0.6-1.6-1.6-1.6c-2.1,0-2.2,3.2-0.2,3.4
                C688.7,610.4,689.1,609.8,689.8,609.5z"/>
              <path class="st4" d="M683.1,602.9c0.8-1,0.4-2.8-1.2-2.9c-1.4,0-1.9,1.4-1.5,2.4c0.4-0.2,0.9-0.3,1.3-0.3
                C682.2,602.3,682.7,602.6,683.1,602.9z"/>
              <path class="st4" d="M717.1,596.3c-0.3,1,0.2,2.3,1.5,2.3c2.2,0,2.2-3.4,0-3.4c-0.1,0-0.3,0-0.4,0
                C718.1,595.7,717.6,596.1,717.1,596.3z"/>
              <path class="st4" d="M676.9,594.6c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.4-0.3,0.7-0.4c0.8-0.8,0.6-2.6-0.7-2.9
                c-0.7,0.8-1.3,1.7-1.8,2.7C675.8,594.3,676.3,594.6,676.9,594.6z"/>
              <path class="st4" d="M661.8,633.4c1.4,0,2.3-2,0.9-2.9c-0.2-0.1-0.5-0.2-0.7-0.2C662.7,631.1,662.8,632.6,661.8,633.4z"/>
              <path class="st4" d="M666.6,629.1c-0.7-0.3-1.3-0.5-2-0.8c0,0.7,0.4,1.3,1.1,1.5c0.4,0.1,0.7,0.2,1.1,0.3
                c0.8,0.2,1.7-0.2,1.9-1.1c0.1-0.2,0.1-0.4,0-0.7C668.2,629,667.3,629.4,666.6,629.1z"/>
              <path class="st4" d="M654.3,642.3c0.5,0,1-0.3,1.4-0.7c-1.4-0.7-1.6-3.2,0.2-3.8c-0.1,0-0.1-0.1-0.2-0.1
                c-0.4-0.2-0.8-0.3-1.2-0.2l-1.7,3.5C653,641.7,653.5,642.3,654.3,642.3z"/>
              <path class="st4" d="M660.4,640.5c0.1,0.1,0.2,0.1,0.3,0.2c-0.6-0.5-0.9-1.2-1-1.9C659.5,639.3,659.7,640,660.4,640.5z"/>
              <path class="st4" d="M671.6,621.8c-0.1-0.1-0.3-0.3-0.4-0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1
                c-1.7-1.1-3.4,1.6-1.7,2.7C670.7,624.5,672,623,671.6,621.8z"/>
              <path class="st4" d="M668.7,637c-0.4,0.7-0.4,1.6,0.4,2.1c1.7,1.1,3.4-1.6,1.7-2.7c-0.1,0-0.1-0.1-0.2-0.1
                c-0.2,0.3-0.6,0.5-1,0.6C669.2,637,668.9,637,668.7,637z"/>
              <path class="st4" d="M661,633.7c0.3-0.1,0.6-0.2,0.8-0.3c1-0.7,1-2.3,0.2-3.1c-0.4-0.5-1.1-0.7-1.9-0.5
                C657.5,630.3,658.4,634.3,661,633.7z"/>
              <path class="st4" d="M665.4,645.6c2.6-0.6,1.7-4.6-0.9-4C661.9,642.2,662.9,646.2,665.4,645.6z"/>
              <path class="st4" d="M668.7,637c0.3,0,0.5,0,0.8,0c0.5-0.1,0.8-0.3,1-0.6c1.2-1.3,0.2-3.9-2-3.4
                C666.3,633.5,666.8,636.6,668.7,637z"/>
              <path class="st4" d="M679.8,641.5c2.6-0.6,1.7-4.6-0.9-4C676.3,638.1,677.2,642.1,679.8,641.5z"/>
              <path class="st4" d="M670.8,624.9c-2.6,0.6-1.7,4.6,0.9,4C674.3,628.3,673.4,624.3,670.8,624.9z"/>
              <path class="st4" d="M655.7,641.6c0.4,0.2,0.8,0.2,1.3,0.1c2.6-0.6,1.7-4.6-0.9-4c-0.1,0-0.1,0-0.2,0.1
                C654,638.4,654.3,640.9,655.7,641.6z"/>
              <path class="st4" d="M678.1,631.9c-0.1,0.4,0,0.9,0.2,1.3c0.6,1,1.8,1.4,2.8,0.8c0.4-0.2,0.9-0.5,1.3-0.7
                c0.7-0.4,1.2-1.3,1.1-2.2c-0.3,0.1-0.7,0.1-1.1,0c-0.9-0.3-1.3-0.9-1.3-1.6c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.7,0.4-1,0.6
                C679.3,630.9,678.9,631.5,678.1,631.9z"/>
              <path class="st4" d="M672.7,644.9c0.5-0.2,1-0.3,1.4-0.2c0.3-0.9,0-1.8-0.8-2.4c-0.2-0.1-0.3-0.2-0.5-0.3
                c-0.9-0.6-2.3-0.3-2.8,0.6c-0.6,1-0.3,2.2,0.6,2.8c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.2
                C671.8,645.6,672.1,645.2,672.7,644.9z"/>
              <path class="st4" d="M658.7,653.2c0.5,0.9,1.8,1.4,2.8,0.8c1-0.6,1.4-1.8,0.8-2.8c-0.2-0.4-0.5-0.9-0.7-1.3
                c-0.5-0.9-1.8-1.4-2.8-0.8c-1,0.6-1.4,1.8-0.8,2.8C658.2,652.3,658.4,652.7,658.7,653.2z"/>
              <path class="st4" d="M663.6,622.7c0.1,0,0.2,0,0.3,0.1c0.1-0.7,0.5-1.4,1.3-1.8c0.4-0.2,0.8-0.2,1.1-0.2
                c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.6,0.1-1.2-0.2-1.7c-0.4-0.2-0.7-0.5-0.8-0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7-0.1-1,0
                l0,0.1l-1.9,3.9C662.5,621.9,662.9,622.5,663.6,622.7z"/>
              <path class="st4" d="M672.4,652.7c-0.3-1.1-1.3-1.7-2.5-1.5c-0.8,0.1-1.7,0.2-2.5,0.4c-1.1,0.2-1.8,1.4-1.5,2.5
                c0.3,1.1,1.3,1.7,2.5,1.5c0.8-0.1,1.7-0.2,2.5-0.4C671.9,655.1,672.7,653.8,672.4,652.7z"/>
              <path class="st4" d="M681.9,653c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2-0.1-0.4-0.1-0.4
                c-0.1-1.1-1.5-1.8-2.5-1.5c-1.2,0.3-1.7,1.3-1.5,2.5c0.1,1.1,0.8,2.3,1.6,3.1c0.8,0.7,2.1,0.9,2.9,0.1c0.7-0.7,1-2.1,0.1-2.9
                C682.1,653.3,682,653.1,681.9,653z M681.6,652.6c0,0,0,0.1,0.1,0.2C681.6,652.7,681.6,652.6,681.6,652.6z M681.7,652.9
                c0,0.1,0.1,0.1,0.1,0.2C681.8,653,681.7,652.9,681.7,652.9z"/>
              <path class="st4" d="M686,648.9c2.3-0.5,1.8-3.7-0.1-4c-0.5,0.4-1.1,0.6-1.8,0.5C682.8,646.6,683.8,649.4,686,648.9z"/>
              <path class="st4" d="M672.2,661.1c2.6-0.6,1.7-4.6-0.9-4C668.7,657.7,669.6,661.7,672.2,661.1z"/>
              <path class="st4" d="M660.4,658.7c-2.6,0.6-1.7,4.6,0.9,4C663.9,662.1,663,658.1,660.4,658.7z"/>
              <path class="st4" d="M678.8,624.9c1.5-0.3,1.8-1.8,1.3-2.9c-1,0.2-1.9-0.3-2.4-1.1C675.4,621.6,676.3,625.5,678.8,624.9z"/>
              <path class="st4" d="M651.2,655.8c2.6-0.6,1.7-4.6-0.9-4C647.7,652.4,648.6,656.4,651.2,655.8z"/>
              <path class="st4" d="M692.9,639.6c2.6-0.6,1.7-4.6-0.9-4C689.4,636.2,690.4,640.2,692.9,639.6z"/>
              <path class="st4" d="M728.4,597.3c-1-0.8-2.2-0.5-2.8,0.3c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.8-0.6,1.1c0,0.6,0.2,1.1,0.7,1.6
                C727.8,602.2,730.5,599,728.4,597.3z"/>
              <path class="st4" d="M718.8,611.4c2.1,1.7,4.8-1.5,2.7-3.2C719.5,606.5,716.8,609.7,718.8,611.4z"/>
              <path class="st4" d="M731,606.1c-2.1-1.7-4.8,1.5-2.7,3.2C730.4,611.1,733.1,607.9,731,606.1z"/>
              <path class="st4" d="M731,620.5c2.1,1.7,4.8-1.5,2.7-3.2C731.6,615.5,729,618.7,731,620.5z"/>
              <path class="st4" d="M739,603c-2.1-1.7-4.8,1.5-2.7,3.2C738.3,607.9,741,604.7,739,603z"/>
              <path class="st4" d="M716.6,601.9c0.1,0.1,0.1,0.2,0.2,0.2c2.1,1.7,4.8-1.5,2.7-3.2c-1.3-1.1-2.8-0.2-3.3,1
                C716.8,600.4,716.9,601.3,716.6,601.9z"/>
              <path class="st4" d="M742,616.5c0.4-1,0-2.3-1-2.8c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.3,0-2.8,1c-0.4,1,0,2.3,1,2.8
                c0.5,0.2,0.9,0.4,1.4,0.6C740.2,617.9,741.6,617.5,742,616.5z"/>
              <path class="st4" d="M724,613.3c-1.1,0.1-2,1.1-1.9,2.3c0,0.2,0,0.4,0.1,0.6c0.1,1.1,1.2,2,2.3,1.9c1.1-0.1,2-1.1,1.9-2.3
                c0-0.2,0-0.4-0.1-0.6C726.2,614.1,725.1,613.1,724,613.3z"/>
              <path class="st4" d="M712.4,612.3c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.3-1-2.8c-1-0.4-2.3,0-2.8,1c-0.2,0.5-0.4,0.9-0.6,1.4
                c-0.4,1,0,2.3,1,2.8C710.7,613.8,711.9,613.4,712.4,612.3z"/>
              <path class="st4" d="M736.9,594.6c1.4,0.1,1.8,1.7,1.2,2.6c0.3,0,0.6,0,0.9-0.1c0.4-0.2,0.9-0.4,1.3-0.6
                c1-0.4,1.7-1.6,1.3-2.7c0-0.1-0.1-0.2-0.2-0.3c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2,0-0.3,0.1-0.5,0.1
                c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.6-0.4,0.9C736.4,594.6,736.6,594.6,736.9,594.6z"/>
              <path class="st4" d="M715.8,617.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-2.3-0.6-2.9,0.3c-0.7,1-0.6,2.1,0.3,2.9
                c0.6,0.6,1.2,1.2,1.8,1.8c0.8,0.8,2.3,0.6,2.9-0.3c0.1-0.1,0.1-0.1,0.1-0.2C715.5,621.3,714.4,618.6,715.8,617.1z"/>
              <path class="st4" d="M723.2,625.5c-1-0.7-2.1-0.6-2.9,0.3c-0.8,0.8-1.4,2-1.5,3.2c-0.1,1.1,0.5,2.3,1.7,2.4
                c1,0.1,2.3-0.5,2.4-1.7c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.3-0.3
                C724.3,627.7,724.1,626.1,723.2,625.5z M723,629.2c0-0.1,0-0.2,0.1-0.2C723,629.1,723,629.2,723,629.2z M723.1,628.9
                c0,0,0-0.1,0.1-0.2C723.2,628.8,723.1,628.8,723.1,628.9z"/>
              <path class="st4" d="M728.9,630.1c2.1,1.7,4.8-1.5,2.7-3.2C729.5,625.1,726.8,628.3,728.9,630.1z"/>
              <path class="st4" d="M710.5,626.4c2.1,1.7,4.8-1.5,2.7-3.2C711.1,621.5,708.4,624.7,710.5,626.4z"/>
              <path class="st4" d="M703.6,617.5c-0.5,0.4-1,0.6-1.6,0.5c0.1,0.2,0.3,0.4,0.5,0.6c2,1.6,4.5-1.2,2.9-2.9
                c-0.1,0.2-0.3,0.4-0.5,0.6C704.5,616.7,704.1,617.1,703.6,617.5z"/>
              <path class="st4" d="M743.8,606.4c0.5,0.9,0.3,2-0.3,2.7c0.1,0.1,0.2,0.3,0.4,0.4c1.8,1.5,4.1-0.7,3.3-2.5
                c-0.1-0.3-0.3-0.5-0.6-0.7C745.6,605.5,744.5,605.7,743.8,606.4z"/>
              <path class="st4" d="M721.1,590.5c-0.7-0.5-0.8-1.7-0.3-2.4c-0.1-0.1-0.1-0.1-0.2-0.2c-2.1-1.7-4.8,1.5-2.7,3.2
                C719.1,592.1,720.5,591.5,721.1,590.5z"/>
              <path class="st4" d="M701.8,606.1c0.6,0.5,1.2,0.6,1.8,0.4c0.8-0.2,1.5-1,1.6-1.8c0.1-0.6-0.1-1.3-0.7-1.8
                C702.5,601.2,699.8,604.4,701.8,606.1z"/>
              <path class="st4" d="M740.7,629.9c2.1,1.7,4.8-1.5,2.7-3.2C741.3,625,738.6,628.2,740.7,629.9z"/>
              <path class="st4" d="M706.8,590.6c-2.1-1.7-4.8,1.5-2.7,3.2C706.1,595.5,708.8,592.3,706.8,590.6z"/>
              <path class="st4" d="M640.3,671.1c2.1-0.6,1.7-3.4,0-4l-1.6,3.2l-0.2,0.4C639,671.1,639.5,671.3,640.3,671.1z"/>
              <path class="st4" d="M645.5,682.8c2.6-0.8,1.4-4.7-1.2-4C641.8,679.6,643,683.5,645.5,682.8z"/>
              <path class="st4" d="M649,673.8c2.6-0.8,1.4-4.7-1.2-4C645.3,670.5,646.5,674.5,649,673.8z"/>
              <path class="st4" d="M658.5,673.7c-2.6,0.8-1.4,4.7,1.2,4C662.3,676.9,661.1,672.9,658.5,673.7z"/>
              <path class="st4" d="M650.7,665.5c2.6-0.8,1.4-4.7-1.2-4C647,662.3,648.2,666.3,650.7,665.5z"/>
              <path class="st4" d="M636.8,679.4c2.3-0.7,1.6-4-0.5-4.1l-1.6,3.4C635.1,679.3,635.9,679.7,636.8,679.4z"/>
              <path class="st4" d="M662.4,666.3c-0.6-0.9-1.9-1.3-2.9-0.7c-0.4,0.3-0.9,0.6-1.3,0.8c-0.9,0.6-1.3,1.9-0.7,2.9
                c0.6,0.9,1.9,1.3,2.9,0.7c0.4-0.3,0.9-0.6,1.3-0.8C662.6,668.6,663,667.3,662.4,666.3z"/>
              <path class="st4" d="M649.9,679.5c-0.5,1-0.2,2.3,0.8,2.8c0.2,0.1,0.4,0.2,0.5,0.3c1,0.5,2.3,0.2,2.8-0.8
                c0.5-1,0.2-2.3-0.8-2.8c-0.2-0.1-0.4-0.2-0.5-0.3C651.7,678.2,650.4,678.5,649.9,679.5z"/>
              <path class="st4" d="M639.4,691c0.6,0.7,1.8,1,2.7,0.4c0.9-0.6,1.3-1.9,0.7-2.9c-0.3-0.4-0.6-0.9-0.8-1.3
                c-0.6-0.9-1.9-1.3-2.9-0.7c-0.9,0.6-1.3,1.9-0.7,2.9C639,689.8,639.3,690.4,639.4,691z"/>
              <path class="st4" d="M644.6,658.3c0-0.1,0-0.3,0.1-0.4l-0.7,1.5C644.3,659.1,644.6,658.7,644.6,658.3z"/>
              <path class="st4" d="M650.4,688.1c-0.8,0.2-1.7,0.4-2.5,0.5c-1.1,0.2-1.7,1.6-1.4,2.6c0.4,1.1,1.4,1.6,2.6,1.4
                c0.8-0.2,1.7-0.4,2.5-0.5c1.1-0.2,1.7-1.6,1.4-2.6C652.6,688.3,651.6,687.8,650.4,688.1z"/>
              <path class="st4" d="M662.5,689.1c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.1-0.4
                c-0.2-1.1-1.6-1.7-2.6-1.4c-1.1,0.4-1.6,1.4-1.4,2.6c0.2,1.1,0.9,2.3,1.8,3c0.9,0.7,2.2,0.8,2.9-0.1c0.7-0.8,0.8-2.2-0.1-2.9
                C662.8,689.3,662.7,689.2,662.5,689.1z M662.2,688.6c0,0,0,0.1,0.1,0.2C662.3,688.8,662.3,688.7,662.2,688.6z M662.4,688.9
                c0,0.1,0.1,0.1,0.1,0.2C662.5,689,662.4,689,662.4,688.9z"/>
              <path class="st4" d="M665.3,680.7c-2.6,0.8-1.4,4.7,1.2,4C669,683.9,667.8,679.9,665.3,680.7z"/>
              <path class="st4" d="M652.2,693.9c-2.6,0.8-1.4,4.7,1.2,4C655.9,697.1,654.8,693.1,652.2,693.9z"/>
              <path class="st4" d="M643.6,697c-0.4-0.6-1.2-1-2.2-0.7c-2.6,0.8-1.4,4.7,1.2,4c1-0.3,1.4-1,1.4-1.8c-0.1-0.1-0.2-0.3-0.3-0.4
                C643.6,697.7,643.5,697.4,643.6,697z"/>
              <path class="st4" d="M657.6,661c2.6-0.8,1.4-4.7-1.2-4C653.8,657.8,655,661.8,657.6,661z"/>
              <path class="st4" d="M631.9,693.9c2.6-0.8,1.4-4.7-1.2-4C628.1,690.7,629.3,694.7,631.9,693.9z"/>
              <path class="st4" d="M671.6,670.8c-2.6,0.8-1.4,4.7,1.2,4C675.3,674,674.2,670.1,671.6,670.8z"/>
              <path class="st4" d="M626.1,712.9c0.2,0.5,0.4,0.9,0.6,1.4c0,0,0,0.1,0,0.1c0.8-0.6,1.2-1.8,0.3-2.7c-0.8-0.9-2-0.7-2.6,0
                C625.2,711.8,625.8,712.3,626.1,712.9z"/>
              <path class="st4" d="M619.7,720.5c-1.6-1.8-4.4,0.7-2.7,2.5C618.6,724.8,621.4,722.3,619.7,720.5z"/>
              <path class="st4" d="M626.5,722.9c1.5,0.4,3.2-1.6,1.9-3c-1.4-1.5-3.5,0.1-3.2,1.6C625.9,721.7,626.3,722.2,626.5,722.9z"/>
              <path class="st4" d="M627.7,730.4c-0.1-0.4,0-0.7,0.1-1.1c-1.3,0.2-2.4,1.8-1.2,3.1c0.9,1,2.1,0.7,2.8-0.1
                C628.6,732.1,627.9,731.3,627.7,730.4z"/>
              <path class="st4" d="M635.3,720.7c0.7-0.5,1.1-1.5,0.5-2.4c-0.3,0.3-0.7,0.5-1.1,0.6c-0.2,0-0.4,0-0.6,0
                c0.1,0.7,0,1.5-0.5,2.1c0.2,0.1,0.3,0.1,0.5,0.1c0.2-0.2,0.5-0.3,0.9-0.4C635.1,720.7,635.2,720.8,635.3,720.7z"/>
              <path class="st4" d="M619.5,713c-0.4,0.6-1.1,0.8-1.7,0.9l-0.2,0.4l-0.4,0.9C618.4,715.6,619.8,714.3,619.5,713z"/>
              <path class="st4" d="M634.1,729.5c0.1,0.4,0,0.8-0.1,1.2c0.1,0,0.1,0.1,0.2,0.1c0.8,0.5,2.1,0.3,2.6-0.6
                c0.5-0.9,0.3-2-0.6-2.6c-0.4-0.2-0.8-0.5-1.2-0.7c-0.8-0.5-2.1-0.3-2.6,0.6C633.3,727.8,633.9,728.6,634.1,729.5z"/>
              <path class="st4" d="M621.1,729.5c1,0,1.9-0.7,1.9-1.8c0-0.2,0-0.4,0-0.5c0-1-0.8-1.9-1.8-1.9c-1,0-1.9,0.7-1.9,1.8
                c0,0.2,0,0.4,0,0.5c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4C619.7,729,620.3,729.5,621.1,729.5z"/>
              <path class="st4" d="M639.1,710.3c-0.1-0.3-0.3-0.6-0.5-0.9c-0.6,0.9-2,1-2.8,0.4c-0.2-0.1-0.3-0.2-0.5-0.3
                c0,0-0.1-0.1-0.1-0.1c-0.7,0.4-1.2,1.2-1,2c0.2,0.9,1.2,1.7,2.2,1.4c0.4-0.1,0.8-0.2,1.2-0.3
                C638.6,712.2,639.3,711.3,639.1,710.3z"/>
              <path class="st4" d="M611.9,731.5c0.6,0.8,1.9,0.8,2.6,0.1c0.8-0.8,0.8-1.8,0.1-2.6c-0.5-0.6-0.9-1.2-1.4-1.8
                c-0.4-0.5-1-0.7-1.6-0.6l-1.3,2.8c0.1,0.1,0.1,0.2,0.2,0.3C611,730.3,611.4,730.9,611.9,731.5z"/>
              <path class="st4" d="M618.4,737.8c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0c0.8-0.6,0.8-2,0.1-2.6
                c-0.8-0.8-1.8-0.7-2.6-0.1c-0.5,0.4-0.9,0.9-1.2,1.5C616.3,736.6,617.7,736.9,618.4,737.8z"/>
              <path class="st4" d="M624.2,737.7c-1,0.5-1.6,1.8-0.6,2.9c1.1,1.2,2.6,0.5,3.1-0.7c-0.2,0-0.3,0-0.5,0
                C624.8,739.8,624.2,738.8,624.2,737.7z"/>
              <path class="st4" d="M609.7,734.1c0.5,0.1,0.9,0.3,1.2,0.5c0.2-0.6,0.2-1.3-0.3-1.9c-0.5-0.5-1.1-0.7-1.6-0.6l-0.5,1l-0.9,1.8
                C608.1,734.3,608.8,733.9,609.7,734.1z"/>
              <path class="st4" d="M641.9,721.8c-1.6-1.8-4.4,0.7-2.7,2.5C640.8,726.1,643.5,723.6,641.9,721.8z"/>
              <path class="st4" d="M634,741.9c1.6,1.8,4.4-0.7,2.7-2.5C635.1,737.6,632.4,740.1,634,741.9z"/>
              <path class="st4" d="M631.2,696.2c-1.6-1.8-4.4,0.7-2.7,2.5C630.1,700.5,632.8,698,631.2,696.2z"/>
              <path class="st4" d="M671,603.4l-0.6,1.3l-0.8,1.7C670.9,606.3,671.6,604.5,671,603.4z"/>
              <path class="st4" d="M666.3,618.3c0.1,0,0.2,0.1,0.3,0.1c2.4,0.8,3.6-3,1.2-3.8c-1.9-0.6-3,1.5-2.3,2.9
                C665.7,617.8,665.9,618.1,666.3,618.3z"/>
              <path class="st4" d="M674.2,613.1c2.4,0.8,3.6-3,1.2-3.8C673,608.6,671.8,612.4,674.2,613.1z"/>
              <path class="st4" d="M680.7,621.8c0,0-0.1,0-0.1,0C680.6,621.9,680.6,621.9,680.7,621.8c2.4,0.8,3.6-3,1.1-3.7
                c-0.2,0-0.3-0.1-0.5-0.1C682.3,619.1,682.4,621.1,680.7,621.8z"/>
              <path class="st4" d="M679,606c0-0.5,0-1,0.1-1.5c-0.4,0.7-0.5,1.7,0,2.3C679,606.6,678.9,606.3,679,606z"/>
              <path class="st4" d="M685.3,616.3c0.5,0,1,0,1.5,0c1,0,2.1-0.8,2-1.9c0-1.1-0.8-2-1.9-2c-0.5,0-1,0-1.5,0c-1,0-2.1,0.8-2,1.9
                C683.4,615.3,684.2,616.2,685.3,616.3z"/>
              <path class="st4" d="M671.6,621.8c0.6,0.6,1.6,0.8,2.3,0.4c0.9-0.5,1.3-1.7,0.8-2.7c-0.1-0.2-0.2-0.3-0.3-0.5
                c-0.5-0.9-1.8-1.4-2.7-0.8c-0.9,0.5-1.3,1.7-0.8,2.7c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
                C671.4,621.6,671.5,621.7,671.6,621.8z"/>
              <path class="st4" d="M677.3,594.6c-0.3,0.3-0.6,0.7-0.9,1c-0.7,0.7-0.9,2-0.1,2.8c0.7,0.7,2,0.9,2.8,0.1
                c0.3-0.3,0.6-0.7,0.9-1c0.7-0.7,0.9-2,0.1-2.8c-0.5-0.5-1.4-0.8-2.1-0.6C677.8,594.2,677.6,594.3,677.3,594.6
                C677.3,594.5,677.3,594.5,677.3,594.6z"/>
              <path class="st4" d="M664.3,624.4c-0.6,0.2-1.2,0.7-1.3,1.3c-0.3,1.1,0.3,2.1,1.3,2.5c0.1,0,0.2,0.1,0.3,0.1
                c0.7,0.3,1.3,0.5,2,0.8c0.8,0.3,1.7-0.1,2.2-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.3-1.1-0.3-2.1-1.3-2.5c-0.1,0-0.2-0.1-0.2-0.1
                c-0.1,0-0.1,0.1-0.2,0.1C666,625.9,664.9,625.3,664.3,624.4z"/>
              <path class="st4" d="M676.8,632.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0,0.1c0,0,0-0.1,0-0.1c-1.4-0.2-2.3-1.7-2-3
                c-0.7,0.1-1.3,0.6-1.6,1.4c-0.4,1-0.5,2.3-0.1,3.4c0.3,1,1.3,1.8,2.4,1.5c1-0.3,1.8-1.3,1.5-2.4
                C676.9,632.9,676.8,632.7,676.8,632.6z"/>
              <path class="st4" d="M682.3,631.1c0.4,0.1,0.8,0.1,1.1,0c1.6-0.5,2-3.2,0-3.8c-1.6-0.5-2.6,0.9-2.5,2.2
                C681,630.2,681.4,630.8,682.3,631.1z"/>
              <path class="st4" d="M664.8,634.7c2.4,0.8,3.6-3,1.2-3.8C663.6,630.1,662.4,633.9,664.8,634.7z"/>
              <path class="st4" d="M687.9,607.6c2.2,0.7,3.4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.3C686.6,603.1,685.4,606.8,687.9,607.6z"/>
              <path class="st4" d="M692.6,626.7c2.4,0.8,3.6-3,1.2-3.8C691.3,622.1,690.1,625.9,692.6,626.7z"/>
              <path class="st4" d="M680.2,622c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c1.7-0.8,1.6-2.7,0.7-3.8c-0.6-0.7-1.6-1-2.6-0.5
                c-1.5,0.7-1.6,2.3-0.9,3.5C678.3,621.7,679.1,622.2,680.2,622z"/>
              <path class="st4" d="M686.6,630c-2.8,1.3-0.8,5.6,2,4.4C691.4,633.1,689.4,628.7,686.6,630z"/>
              <path class="st4" d="M689.2,619.2c-2.8,1.3-0.8,5.6,2,4.4C694,622.2,692,617.9,689.2,619.2z"/>
              <path class="st4" d="M702,621.9c-2.8,1.3-0.8,5.6,2,4.4C706.8,625,704.8,620.6,702,621.9z"/>
              <path class="st4" d="M690.4,609.3c-0.2,0-0.3,0.1-0.5,0.2c-0.8,0.4-1.2,1-1.3,1.6c-0.3,1.7,1.3,3.7,3.3,2.8
                C694.5,612.6,692.9,608.7,690.4,609.3z"/>
              <path class="st4" d="M676.8,632.1c0.4,0,0.8,0,1.2-0.2c0.8-0.4,1.2-1,1.3-1.7c0.3-1.7-1.3-3.6-3.3-2.7
                c-0.8,0.3-1.2,0.9-1.3,1.6C674.5,630.4,675.4,631.9,676.8,632.1z"/>
              <path class="st4" d="M705.3,612.9c-0.9-0.9-2.4-1.2-3.4-0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-1,0.8-1.2,2.4-0.3,3.4
                c0.5,0.5,1.1,0.8,1.8,0.8c0.6,0,1.1-0.1,1.6-0.5c0.5-0.4,0.9-0.8,1.4-1.2c0.2-0.2,0.4-0.4,0.5-0.6
                C705.9,614.8,705.9,613.7,705.3,612.9z"/>
              <path class="st4" d="M696.8,628.8c-0.2-0.1-0.4-0.2-0.6-0.2c-1.2-0.4-2.7,0.2-3.1,1.4c-0.4,1.2,0.2,2.6,1.4,3.1
                c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.4,2.7-0.2,3.1-1.4C698.6,630.7,698,629.3,696.8,628.8z"/>
              <path class="st4" d="M686,644.9C686,644.9,686,644.9,686,644.9c1-0.9,1.2-2.4,0.3-3.4c-0.4-0.5-0.8-0.9-1.2-1.4
                c-0.8-1-2.4-1.2-3.4-0.3c-0.9,0.9-1.2,2.4-0.3,3.4c0.4,0.5,0.8,0.9,1.2,1.4c0.4,0.5,1,0.7,1.6,0.8
                C684.8,645.5,685.5,645.3,686,644.9z"/>
              <path class="st4" d="M679.1,606.8c0.2,1,1,1.8,2,1.8c1.2,0.1,2.6-0.8,2.6-2.1c0-0.5,0-1.1,0.1-1.6c0-0.7-0.2-1.4-0.7-1.9
                c-0.4-0.4-0.9-0.7-1.4-0.7c-0.4,0-0.9,0.1-1.3,0.3c-0.7,0.4-1.3,1-1.3,1.9c0,0,0,0.1,0,0.1c0,0.5,0,1-0.1,1.5
                C678.9,606.3,679,606.6,679.1,606.8z"/>
              <path class="st4" d="M698.2,640.8c-0.6-1.2-1.9-1.6-3.2-1.2c-0.9,0.3-1.8,0.7-2.8,1c-1.2,0.4-1.7,2.1-1.2,3.2
                c0.6,1.2,1.9,1.6,3.2,1.2c0.9-0.3,1.8-0.7,2.8-1C698.2,643.6,698.8,641.9,698.2,640.8z"/>
              <path class="st4" d="M709.4,639.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
                c-0.1-0.2-0.1-0.4-0.1-0.4c-0.4-1.2-2.1-1.7-3.2-1.2c-1.2,0.6-1.6,1.9-1.2,3.2c0.4,1.3,1.4,2.4,2.6,3.1
                c1.1,0.6,2.6,0.6,3.3-0.6C710.7,641.5,710.6,639.8,709.4,639.2z M708.6,638.4c0,0,0.1,0.1,0.1,0.2
                C708.7,638.6,708.7,638.5,708.6,638.4z M708.8,638.8c0.1,0.1,0.1,0.1,0.2,0.2C708.9,638.9,708.9,638.8,708.8,638.8z"/>
              <path class="st4" d="M710.8,628.9c-2.8,1.3-0.8,5.6,2,4.4C715.6,632,713.6,627.6,710.8,628.9z"/>
              <path class="st4" d="M698,646.1c-2.8,1.3-0.8,5.6,2,4.4C702.7,649.1,700.8,644.8,698,646.1z"/>
              <path class="st4" d="M686,650.4c-2.8,1.3-0.8,5.6,2,4.4C690.8,653.5,688.8,649.2,686,650.4z"/>
              <path class="st4" d="M696.8,603.3c-1.4,0.7-1.4,2.3-0.8,3.3c0.6,0.9,1.7,1.5,2.9,0.9c2.8-1.3,0.8-5.6-2-4.4
                C696.9,603.3,696.8,603.3,696.8,603.3z"/>
              <path class="st4" d="M663.9,622.7c-0.1,0.6,0.1,1.1,0.4,1.6c0.6,0.9,1.7,1.5,2.9,0.9c0.1,0,0.2-0.1,0.2-0.1
                c2.1-1.2,1-4.4-1.1-4.4c-0.3,0-0.7,0-1.1,0.2C664.4,621.3,664,622,663.9,622.7z"/>
              <path class="st4" d="M674.2,644.8c-0.4-0.1-0.9,0-1.4,0.2c-0.6,0.3-1,0.7-1.2,1.1c-0.8,1.7,0.9,4.2,3.1,3.2
                C677,648.3,676.1,645.1,674.2,644.8z"/>
              <path class="st4" d="M716.5,616.6c-0.3,0.1-0.5,0.3-0.7,0.5c-1.4,1.4-0.2,4.1,1.8,4.1c0.3,0,0.6-0.1,1-0.2
                C721.3,619.7,719.3,615.3,716.5,616.6z"/>
              <path class="st4" d="M661.1,636.5c-1,0.5-1.4,1.3-1.3,2.1c0,0.7,0.4,1.5,1,1.9c0.6,0.5,1.4,0.7,2.4,0.3
                C665.9,639.6,663.9,635.3,661.1,636.5z"/>
              <path class="st4" d="M751.2,589.5c-0.3,0.8-0.2,1.7,0.6,2.5c2.3,2.4,6-1.2,3.7-3.6c-0.6-0.6-1.3-0.8-2-0.7
                C752.4,587.7,751.5,588.5,751.2,589.5z"/>
              <path class="st4" d="M763.7,590.6c0.2,0.2,0.3,0.3,0.5,0.4c1.2,0.7,2.5,0.2,3.3-0.7c0.4-0.5,0.7-1.2,0.6-1.9
                c-0.1-0.5-0.3-0.9-0.7-1.4c-1.7-1.7-4-0.3-4.4,1.5C762.9,589.1,763.1,589.9,763.7,590.6z"/>
              <path class="st4" d="M769.3,601c-2.3-2.4-6,1.2-3.7,3.6c0,0,0.1,0.1,0.1,0.1c1.3,1.2,2.9,0.7,3.8-0.4
                C770.2,603.3,770.4,602,769.3,601z"/>
              <path class="st4" d="M773.8,587.7c1.7,1.7,4.1,0.3,4.4-1.5c0.1-0.5,0-1.1-0.3-1.6h-4.3C772.9,585.5,772.8,586.7,773.8,587.7z"
                />
              <path class="st4" d="M779.6,601c0.2-0.3,0.3-0.6,0.3-1c-1.2-0.4-1.7-1.6-1.5-2.7c-0.5-0.3-0.9-0.5-1.4-0.8
                c-1.2-0.7-2.9-0.3-3.5,0.9c-0.6,1.2-0.3,2.8,0.9,3.5c0.6,0.3,1.1,0.6,1.7,0.9c0.8,0.5,2,0.4,2.8-0.1
                C779.2,601.6,779.4,601.3,779.6,601z"/>
              <path class="st4" d="M757.8,600.8c0.6,0,1.2-0.3,1.7-0.6c0.6-0.5,0.9-1.1,0.9-1.9c0-0.2,0-0.5,0-0.7c0-1.4-1.2-2.7-2.6-2.6
                c-1.4,0-2.6,1.1-2.6,2.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3C755.2,599.5,756.4,600.8,757.8,600.8z"/>
              <path class="st4" d="M740.2,593.2c0.4,0.2,0.8,0.3,1.2,0.3c0.9,0,1.8-0.4,2.3-1.2c-1.8-0.4-1.5-3.3,0.5-3.2
                c0.3,0,0.6,0.1,0.8,0.3c0-0.9-0.4-1.9-1.3-2.3c-1.2-0.6-2.8-0.3-3.5,0.9c-0.3,0.6-0.6,1.1-0.9,1.7c-0.5,0.8-0.4,2,0.1,2.8
                C739.6,592.7,739.9,593,740.2,593.2z"/>
              <path class="st4" d="M746.8,598c-0.9-1-2.8-1-3.6,0c-1,1.1-1,2.5,0,3.6c0.4,0.4,0.7,0.9,1.1,1.3c0.3,0.4,0.6,0.8,1,1.1
                c0.7,0.8,2.1,1,3.1,0.5c0.2-0.1,0.4-0.3,0.6-0.4c1-1.1,1-2.5,0-3.6C748.1,599.6,747.4,598.8,746.8,598z"/>
              <path class="st4" d="M751.8,609.7c-1.1,0.9-2,2.3-2.2,3.8c-0.2,1.3,0.4,2.8,1.8,3.2c1.3,0.3,2.9-0.4,3.2-1.8
                c0-0.1,0-0.3,0.1-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0c0.1-0.1,0.3-0.3,0.4-0.3
                c1.1-0.9,1-2.8,0-3.6C754.3,608.7,752.9,608.8,751.8,609.7z M754.7,614.3c0-0.1,0.1-0.2,0.1-0.2
                C754.7,614.2,754.7,614.3,754.7,614.3z M754.9,613.9c0-0.1,0.1-0.1,0.1-0.2C755,613.8,754.9,613.9,754.9,613.9z"/>
              <path class="st4" d="M761.8,616.1c2.3,2.4,6-1.2,3.7-3.6C763.2,610.1,759.5,613.7,761.8,616.1z"/>
              <path class="st4" d="M743.8,606.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-2.3-2.4-6,1.2-3.7,3.6
                c1.3,1.3,2.9,0.8,3.8-0.3C744.1,608.3,744.3,607.3,743.8,606.4z"/>
              <path class="st4" d="M734.5,595.2c-0.8-0.8-1.7-0.9-2.6-0.6c-1.2,0.5-2.2,1.8-1.8,3.2c0.3-0.1,0.6-0.2,0.9-0.2
                c0.8,0,1.6,0.9,1.5,1.7c0,0.1,0,0.2-0.1,0.3c1.3,0,2.5-1.1,2.7-2.3C735.3,596.6,735.2,595.9,734.5,595.2z"/>
              <path class="st4" d="M786.8,589.5c-0.1-0.2-0.2-0.3-0.4-0.5c-2.3-2.4-6,1.2-3.7,3.6c0.8,0.8,1.7,0.9,2.6,0.6
                C786.7,592.7,787.7,591,786.8,589.5z"/>
              <path class="st4" d="M776.3,617.3c2.3,2.4,6-1.2,3.7-3.6C777.7,611.4,774,615,776.3,617.3z"/>
              <path class="st4" d="M803.5,585.5c-3,0-2.9,4.6,0.1,4.6C806.5,590.1,806.5,585.5,803.5,585.5z"/>
              <path class="st4" d="M805.6,599.6c-3,0-2.9,4.6,0.1,4.6C808.6,604.2,808.5,599.6,805.6,599.6z"/>
              <path class="st4" d="M812.1,595.7c3,0,2.9-4.6-0.1-4.6C809.1,591.1,809.1,595.7,812.1,595.7z"/>
              <path class="st4" d="M822.3,603.1c3,0,2.9-4.6-0.1-4.6C819.3,598.5,819.4,603.1,822.3,603.1z"/>
              <path class="st4" d="M816.5,587.4c1.7,0,2.4-1.5,2.1-2.8h-4.3C814,585.9,814.7,587.4,816.5,587.4z"/>
              <path class="st4" d="M797.3,598c3,0,2.9-4.6-0.1-4.6C794.3,593.4,794.3,598,797.3,598z"/>
              <path class="st4" d="M825.4,595.2c0.1,0,0.2-0.1,0.4-0.1c0.1-1.4,1.4-2.8,2.9-2.4c0-0.3,0-0.6-0.1-0.8
                c-0.4-1.2-1.6-1.9-2.9-1.6c-0.5,0.2-1.1,0.3-1.6,0.5c-1.2,0.3-2,1.7-1.6,2.9C823,594.8,824.2,595.6,825.4,595.2z"/>
              <path class="st4" d="M814.5,602.1c-0.9-0.8-2.4-0.9-3.3,0c-0.4,0.4-0.6,1-0.6,1.5c0.3,0,0.7,0.1,1.1,0.4
                c1,0.6,1.2,1.5,1.1,2.4c0.8,0.2,1.7,0,2.3-0.6c0.8-0.9,0.9-2.4,0-3.3C814.8,602.4,814.7,602.2,814.5,602.1z"/>
              <path class="st4" d="M800.4,608c-0.3-1.2-1.7-2-2.9-1.6c-1.2,0.4-1.9,1.6-1.6,2.9c0.2,0.5,0.3,1.1,0.5,1.6
                c0.3,1.2,1.7,2,2.9,1.6c1.2-0.4,1.9-1.6,1.6-2.9C800.7,609.1,800.5,608.5,800.4,608z"/>
              <path class="st4" d="M811.6,613.7c-0.1-1.3-1-2.2-2.3-2.3c-0.9-0.1-1.9-0.1-2.8-0.2c-1.2-0.1-2.3,1.2-2.3,2.3
                c0.1,1.3,1,2.2,2.3,2.3c0.9,0.1,1.9,0.1,2.8,0.2C810.5,616.1,811.7,614.8,811.6,613.7z"/>
              <path class="st4" d="M821.9,616.2c0,0,0-0.1-0.1-0.1c-0.6,0.3-1.4,0.3-2.2-0.2c-1.2-0.7-1.3-1.9-0.9-2.9
                c-0.9,0.3-1.5,1.1-1.6,2.2c-0.1,1.3,0.3,2.7,1.1,3.7c0.7,1,2.1,1.5,3.2,0.8c1-0.6,1.6-2.1,0.8-3.2
                C822.1,616.5,822,616.3,821.9,616.2c0,0,0-0.1,0-0.1C821.8,616.1,821.9,616.1,821.9,616.2z"/>
              <path class="st4" d="M827.3,608c-0.1,0-0.1,0-0.2,0c0,0.3-0.2,0.6-0.3,0.9c-0.3,0.6-1,1-1.7,1.1c-0.1,1.2,0.6,2.6,2.2,2.5
                C830.4,612.6,830.3,608,827.3,608z"/>
              <path class="st4" d="M809.4,618.2c-3,0-2.9,4.6,0.1,4.6C812.4,622.7,812.3,618.1,809.4,618.2z"/>
              <path class="st4" d="M797.1,617.4c-3,0-2.9,4.6,0.1,4.6C800.1,622,800.1,617.3,797.1,617.4z"/>
              <path class="st4" d="M790.4,588c2,0,2.6-2.1,1.9-3.4h-3.9C787.7,585.9,788.4,588,790.4,588z"/>
              <path class="st4" d="M787.6,607.4c-3,0-2.9,4.6,0.1,4.6C790.6,612,790.5,607.4,787.6,607.4z"/>
              <path class="st4" d="M837.1,599.4c-0.5,0-0.9,0.1-1.2,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.5,0.2,0.7
                c0.1,0.6,0,1.2-0.2,1.7c0.2,0.4,0.3,0.8,0.2,1.2c0.3,0.1,0.5,0.2,0.9,0.2C840.1,604,840.1,599.4,837.1,599.4z"/>
              <path class="st4" d="M779.9,600c0.2,0.1,0.5,0.1,0.7,0.1c1.3,0,2-0.9,2.2-1.9c0.2-1.3-0.6-2.7-2.2-2.7c-1.3,0-2,0.9-2.1,1.8
                C778.2,598.4,778.7,599.7,779.9,600z"/>
              <path class="st4" d="M838.2,595.8c-2.1,0.3-1.6,3.6,0.5,3.3C840.8,598.8,840.3,595.5,838.2,595.8z"/>
              <path class="st4" d="M841.4,608.9c2.1-0.3,1.6-3.6-0.5-3.3C838.8,605.9,839.3,609.2,841.4,608.9z"/>
              <path class="st4" d="M843.5,601.2c0.5-0.1,1,0,1.6,0.4c0.3,0.2,0.5,0.3,0.6,0.5c1.5-0.7,1-3.4-1-3.1
                C843.6,599.1,843.2,600.3,843.5,601.2z"/>
              <path class="st4" d="M852.7,603.3c-2.1,0.3-1.6,3.6,0.5,3.3C855.2,606.3,854.8,603,852.7,603.3z"/>
              <path class="st4" d="M847.6,596c2.1-0.3,1.6-3.6-0.5-3.3C845,593,845.5,596.2,847.6,596z"/>
              <path class="st4" d="M834.4,603.9c-0.4,0.1-0.9,0.1-1.3-0.1c0.1,0.8,0.8,1.5,1.8,1.3c0.8-0.1,1.2-0.7,1.3-1.3
                c0.1-0.4,0-0.9-0.2-1.2C835.7,603.3,835.1,603.7,834.4,603.9z"/>
              <path class="st4" d="M854.6,599.3c-0.2,0.7-0.6,1.2-1.2,1.5c0.4,0.1,0.8,0.2,1.2,0c0.4-0.2,0.7-0.3,1.1-0.5
                c0.8-0.3,1.3-1.4,0.9-2.2c-0.4-0.8-1.3-1.2-2.2-0.9c0,0-0.1,0-0.1,0.1C854.7,597.8,854.8,598.5,854.6,599.3z"/>
              <path class="st4" d="M847.9,606.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3c-0.5,0.7-0.4,1.8,0.3,2.3
                c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3C848.7,608.4,848.6,607.4,847.9,606.8z"/>
              <path class="st4" d="M838,612c-0.3-0.8-1.4-1.2-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
                c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C838.3,612.8,838.2,612.4,838,612z"/>
              <path class="st4" d="M841.5,590.4c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
                c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0.3,0.3,0.6,0.6,0.7,1
                C841.3,590.4,841.4,590.4,841.5,590.4z"/>
              <path class="st4" d="M844.4,616.9c0.2,0,0.5,0,0.7,0c0.9-0.1,1.6-1,1.4-1.9c-0.2-0.9-0.8-1.3-1.6-1.4
                C845.6,614.7,845.2,616.1,844.4,616.9z"/>
              <path class="st4" d="M854,615.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1-1.6-1.9-1.4
                c-0.9,0.2-1.4,0.9-1.4,1.9c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3
                C854.2,616.1,854.1,616,854,615.9z"/>
              <path class="st4" d="M857.1,609.6c-0.7,0.1-1.1,0.5-1.3,1c0.6-0.2,1.3-0.2,2,0.3c0.5,0.3,0.8,0.7,1,1.1
                C859.3,611,858.6,609.4,857.1,609.6z"/>
              <path class="st4" d="M845.3,618.4c-2.1,0.3-1.6,3.6,0.5,3.3C847.8,621.4,847.4,618.1,845.3,618.4z"/>
              <path class="st4" d="M836.5,619c-2.1,0.3-1.6,3.6,0.5,3.3C839.1,621.9,838.6,618.7,836.5,619z"/>
              <path class="st4" d="M853.5,593.3c1.2-0.2,1.5-1.2,1.3-2.1c-0.4-0.2-0.6-0.6-0.7-1c-0.3-0.2-0.6-0.2-1-0.2
                C850.9,590.3,851.4,593.6,853.5,593.3z"/>
              <path class="st4" d="M827.3,597.3c0.1,0.8,0.8,1.5,1.8,1.4c1.3-0.2,1.6-1.6,1.1-2.5C829.7,597.2,828.5,597.8,827.3,597.3z"/>
              <path class="st4" d="M828.9,612.7c-2.1,0.3-1.6,3.6,0.5,3.3c0.2,0,0.4-0.1,0.6-0.2c0.2-0.7,0.4-1.3,0.8-1.8
                C830.5,613.2,829.8,612.6,828.9,612.7z"/>
              <path class="st4" d="M863.7,605.9c2.1-0.3,1.6-3.6-0.5-3.3C861.2,603,861.6,606.2,863.7,605.9z"/>
              <path class="st4" d="M829.3,588.3c2.1-0.3,1.6-3.6-0.5-3.3C826.8,585.3,827.2,588.6,829.3,588.3z"/>
              <path class="st4" d="M626.9,697.1c2-0.3,2.1-2.9,0.7-3.7l-1.7,3.6C626.2,697.1,626.5,697.2,626.9,697.1z"/>
              <path class="st4" d="M629.7,705.1c-2.6,0.4-2,4.3,0.6,4C632.8,708.7,632.3,704.8,629.7,705.1z"/>
              <path class="st4" d="M634.9,701c2.6-0.4,2-4.3-0.6-4C631.8,697.4,632.3,701.3,634.9,701z"/>
              <path class="st4" d="M644.6,706.2c2.6-0.4,2-4.3-0.6-4C641.5,702.6,642,706.6,644.6,706.2z"/>
              <path class="st4" d="M637.8,693.3c1.3-0.2,1.8-1.3,1.6-2.3c-0.1-0.6-0.4-1.2-1-1.5c-0.3-0.2-0.7-0.3-1.2-0.2
                C634.6,689.7,635.2,693.7,637.8,693.3z"/>
              <path class="st4" d="M623.7,701.5l-1.5,3.1c0.1,0,0.1,0,0.2,0C624,704.4,624.4,702.6,623.7,701.5z"/>
              <path class="st4" d="M648.8,695.8c-0.5-1-1.6-1.5-2.6-1c-0.5,0.2-0.9,0.4-1.4,0.6c-0.6,0.3-1.1,0.9-1.2,1.6c0,0.3,0,0.7,0.1,1
                c0.1,0.2,0.2,0.3,0.3,0.4c0.6,0.7,1.5,1,2.4,0.6c0.5-0.2,0.9-0.4,1.4-0.6C648.7,698,649.3,696.8,648.8,695.8z"/>
              <path class="st4" d="M635.8,709.8c0.8,0.6,2.2,0.5,2.8-0.4c0.6-0.9,0.5-2.2-0.4-2.8c-0.2-0.1-0.3-0.2-0.5-0.3
                c-0.8-0.6-2.2-0.5-2.8,0.4c-0.6,0.9-0.5,2,0.3,2.7c0,0,0.1,0.1,0.1,0.1C635.5,709.6,635.6,709.7,635.8,709.8z"/>
              <path class="st4" d="M623,715.9c0.4,1,1.7,1.5,2.6,1c0.9-0.5,1.4-1.5,1.1-2.5c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-0.9-0.6-1.4
                c-0.3-0.7-1-1.1-1.7-1.2c-0.3,0-0.7,0-1,0.2c-1,0.5-1.5,1.6-1,2.6C622.6,715,622.8,715.5,623,715.9z"/>
              <path class="st4" d="M633,685.5c0.1-0.4,0.3-0.9,0.4-1.3c0.2-0.7,0.1-1.5-0.4-2.1l-2.2,4.6C631.7,686.8,632.7,686.4,633,685.5
                z"/>
              <path class="st4" d="M632.2,717.1c1.1,0.1,1.8,0.9,1.9,1.8c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.2,1.1-0.6c0.4-0.5,0.7-1.1,0.6-1.7
                c-0.2-1.1-1.1-1.8-2.3-1.7c-0.8,0.1-1.6,0.1-2.5,0.2c-1.1,0.1-1.9,1.3-1.7,2.3c0,0.2,0.1,0.4,0.2,0.6
                C630.6,717.4,631.3,717,632.2,717.1z"/>
              <path class="st4" d="M645.7,717.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c-0.1-1.1-1.3-1.9-2.3-1.7
                c-1.2,0.2-1.8,1.1-1.7,2.3c0.1,1.1,0.6,2.3,1.3,3.1c0.7,0.8,2,1.1,2.8,0.3c0.8-0.7,1.1-2,0.3-2.8
                C645.9,717.9,645.7,717.8,645.7,717.7z M645.4,717.2c0,0,0,0.1,0.1,0.2C645.5,717.3,645.4,717.2,645.4,717.2z M645.5,717.5
                c0,0.1,0.1,0.1,0.1,0.2C645.6,717.6,645.5,717.5,645.5,717.5z"/>
              <path class="st4" d="M649.5,710c-2.6,0.4-2,4.3,0.6,4C652.6,713.6,652,709.6,649.5,710z"/>
              <path class="st4" d="M635.3,720.7c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.2-0.9,0.4c-1.4,1.1-0.7,3.9,1.5,3.6
                C638.1,724.4,637.6,720.7,635.3,720.7z"/>
              <path class="st4" d="M622.7,723.3c-0.1,1.1,0.8,2.4,2.2,2.2c1.4-0.2,1.9-1.5,1.6-2.5c-0.2-0.6-0.6-1.2-1.3-1.4
                c-0.1,0-0.1,0-0.2,0C624.8,722.5,624,723.4,622.7,723.3z"/>
              <path class="st4" d="M645,690c2.6-0.4,2-4.3-0.6-4C641.8,686.4,642.4,690.4,645,690z"/>
              <path class="st4" d="M617.1,715.3l-1.2,2.5C617,717.4,617.4,716.2,617.1,715.3z"/>
              <path class="st4" d="M657,701.5c-2.6,0.4-2,4.3,0.6,4C660.1,705.1,659.5,701.1,657,701.5z"/>
              <path class="st4" d="M609.2,738.7c2.5,0.3,3.3-2.9,1.8-4.2c-0.3-0.3-0.7-0.4-1.2-0.5c-1-0.1-1.7,0.3-2.1,0.9
                c0,0.1-0.1,0.1-0.1,0.2l-0.2,0.4C606.9,736.8,607.4,738.5,609.2,738.7z"/>
              <path class="st4" d="M616.9,731c1.8,0.2,2.7-1.3,2.5-2.7c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.8-1.4-1.8-1.5
                C614.5,726,613.9,730.6,616.9,731z"/>
              <path class="st4" d="M626.7,735.3c-1.7-0.2-2.6,1.1-2.6,2.5c0,1,0.6,2,2,2.2c0.2,0,0.3,0,0.5,0
                C629.2,739.8,629.6,735.6,626.7,735.3z"/>
              <path class="st4" d="M622.5,723.3c0.1,0,0.1,0,0.2,0c1.3,0.1,2.1-0.8,2.3-1.8c0.3-1.2-0.3-2.6-1.9-2.8
                C620.1,718.2,619.5,722.9,622.5,723.3z"/>
              <path class="st4" d="M634.1,729.5c-0.2-0.9-0.8-1.7-1.7-1.9c-0.3-0.1-0.6-0.1-1-0.1c-0.6,0.1-1.1,0.2-1.7,0.3
                c-0.9,0.1-1.6,0.8-1.9,1.6c-0.1,0.3-0.2,0.7-0.1,1.1c0.2,0.9,0.8,1.6,1.6,1.9c0.3,0.1,0.7,0.1,1,0.1c0.6-0.1,1.1-0.2,1.7-0.3
                c0.8-0.1,1.5-0.7,1.8-1.5C634.1,730.3,634.2,729.9,634.1,729.5z"/>
              <path class="st4" d="M619,738.6c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.5c-0.7-0.9-2.1-1.2-3.1-0.5
                c-0.1,0-0.1,0.1-0.2,0.1c-1,0.8-1.2,2.3-0.4,3.3c0.1,0.2,0.3,0.4,0.4,0.5c0.8,1,2.3,1.2,3.3,0.4
                C619.3,740.9,619.6,739.6,619,738.6z"/>
              <path class="st4" d="M603.6,743.5C603.6,743.4,603.6,743.4,603.6,743.5l-1.2,2.4C603.2,745.4,603.7,744.5,603.6,743.5z"/>
              <path class="st4" d="M619.5,713c0.3-0.5,0.6-0.9,0.9-1.4c0.6-0.9,0.6-2.2-0.1-3l-0.4,0.9l-2.1,4.4
                C618.4,713.9,619.1,713.6,619.5,713z"/>
              <path class="st4" d="M611.8,746.5c-0.9-0.2-1.9-0.4-2.8-0.6c-1.2-0.3-2.5,0.8-2.6,2c-0.1,1.4,0.7,2.4,2,2.6
                c0.1,0,0.2,0,0.3,0.1c-0.5-1.4,0.4-3.3,2.3-3.1c1.8,0.2,2.3,2,1.7,3.3c0.6-0.4,1.1-1,1.1-1.7
                C613.9,747.7,613.1,746.7,611.8,746.5z"/>
              <path class="st4" d="M623.8,753.1c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
                c0.3-1.2-0.8-2.5-2-2.6c-1.4-0.1-2.3,0.7-2.6,2c-0.3,1.3-0.1,2.8,0.5,3.9c0.6,1.1,1.9,1.8,3.1,1.2c1.1-0.5,1.9-1.9,1.2-3.1
                C624,753.4,623.9,753.3,623.8,753.1z M623.7,752.5c0,0,0,0.1,0,0.2C623.7,752.7,623.7,752.6,623.7,752.5z M623.8,752.9
                c0,0.1,0,0.2,0.1,0.2C623.8,753,623.7,752.9,623.8,752.9z"/>
              <path class="st4" d="M630.5,745.7c-3-0.4-3.6,4.3-0.6,4.7C632.9,750.7,633.5,746.1,630.5,745.7z"/>
              <path class="st4" d="M611.7,757.7c1.7-0.9,1.7-4.1-0.8-4.4c-2.7-0.3-3.4,3.3-1.4,4.4c0.3-0.1,0.6-0.1,0.9-0.2
                C610.9,757.5,611.3,757.5,611.7,757.7z"/>
              <path class="st4" d="M598.1,755.4c1.6,0.2,2.5-1,2.6-2.2c-0.6-0.4-0.9-1.1-0.9-1.8l-1.3,2.7l-0.6,1.3
                C597.9,755.4,598,755.4,598.1,755.4z"/>
              <path class="st4" d="M634.1,718.9c-0.2-0.9-0.8-1.6-1.9-1.8c-0.9-0.1-1.6,0.2-2,0.8c-1,1.3-0.7,3.6,1.4,3.9
                c0.9,0.1,1.6-0.2,2-0.8C634.1,720.5,634.3,719.7,634.1,718.9z"/>
              <path class="st4" d="M641.6,738.4c-3-0.4-3.6,4.3-0.6,4.7C644,743.4,644.6,738.8,641.6,738.4z"/>
              <path class="st4" d="M597.2,756.7l-0.9,1.9l-1,2.1c0.3,0.2,0.7,0.4,1.1,0.4C599.2,761.5,599.7,757.3,597.2,756.7z"/>
              <path class="st4" d="M606,765.1c-2.9-0.3-3.4,4.2-0.5,4.5C608.4,770,608.9,765.5,606,765.1z"/>
              <path class="st4" d="M600.6,753.2c0.3,0.2,0.6,0.3,1,0.4c2.9,0.3,3.4-4.2,0.5-4.5c-0.6-0.1-1.1,0.1-1.5,0.4l-0.1,0.3l-0.8,1.7
                C599.7,752.1,600,752.8,600.6,753.2z"/>
              <path class="st4" d="M611.7,757.7c-0.4-0.2-0.8-0.3-1.3-0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0-0.5,0.1-0.7,0.1
                c-1.2,0.2-2.1,1.4-1.9,2.6c0.2,1.2,1.3,2.1,2.6,1.9c0.6-0.1,1.1-0.2,1.7-0.3c1.2-0.2,2.1-1.4,1.9-2.6
                C612.9,758.7,612.4,758,611.7,757.7z"/>
              <path class="st4" d="M598.3,768.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-0.9-2.3-1.2-3.2-0.4c-0.9,0.8-1.2,2.2-0.4,3.2
                c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.9,2.3,1.2,3.2,0.4C598.9,770.6,599.1,769.2,598.3,768.2z"/>
              <path class="st4" d="M588.4,780.2c0.9,0.2,1.8,0.4,2.7,0.5c1.2,0.2,2.4-0.8,2.5-2c0.1-1.3-0.8-2.3-2-2.5
                c-0.9-0.2-1.8-0.4-2.7-0.5c-0.3-0.1-0.6,0-0.8,0l-1.3,2.7l-0.2,0.3C586.8,779.5,587.5,780,588.4,780.2z"/>
              <path class="st4" d="M603.4,782.4c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
                c0.2-1.2-0.9-2.4-2-2.5c-1.3-0.1-2.3,0.8-2.5,2c-0.3,1.2,0,2.7,0.6,3.8c0.6,1,1.8,1.7,3,1.2c1-0.5,1.8-1.9,1.2-3
                C603.5,782.8,603.4,782.6,603.4,782.4z M603.3,781.8c0,0,0,0.1,0,0.2C603.3,782,603.3,781.9,603.3,781.8z M603.3,782.2
                c0,0.1,0,0.1,0.1,0.2C603.3,782.4,603.3,782.3,603.3,782.2z"/>
              <path class="st4" d="M609.7,775.1c-2.9-0.3-3.4,4.2-0.5,4.5C612.1,780,612.6,775.5,609.7,775.1z"/>
              <path class="st4" d="M590.9,782.8C590.8,782.8,590.8,782.8,590.9,782.8c1.4,1.3,0.2,4.1-1.9,3.8c0.3,0.4,0.8,0.6,1.4,0.7
                C593.3,787.7,593.8,783.2,590.9,782.8z"/>
              <path class="st4" d="M611,747.5c-1.9-0.2-2.8,1.7-2.3,3.1c0.2,0.7,0.8,1.3,1.8,1.4c1.1,0.1,1.9-0.4,2.2-1.2
                C613.3,749.5,612.8,747.7,611,747.5z"/>
              <path class="st4" d="M620.4,767.9c-2.9-0.3-3.4,4.2-0.5,4.5C622.8,772.8,623.3,768.3,620.4,767.9z"/>
              <path class="st4" d="M580.8,797.8c-2.7-1-4.3,3.1-1.6,4.2C581.9,803,583.5,798.9,580.8,797.8z"/>
              <path class="st4" d="M587,791.8c-0.6-0.1-1.1-0.1-1.7-0.2c-1.2-0.1-2.4,0.8-2.4,2c-0.1,1.2,0.7,2.3,2,2.4
                c0.6,0.1,1.1,0.1,1.7,0.2c1.2,0.1,2.4-0.8,2.4-2C589,793,588.2,791.9,587,791.8z"/>
              <path class="st4" d="M572.3,816.6c1.1-0.2,2.2-1.3,1.9-2.6c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2
                c0.1-0.2,0.2-0.4,0.2-0.4c0.5-1-0.2-2.5-1.3-2.9c-0.5-0.2-0.9-0.2-1.4-0.1l-1.3,2.7l-0.8,1.6c0,0.3,0.1,0.5,0.1,0.8
                C570,815.9,571,816.8,572.3,816.6z M574.1,813c0,0,0,0.1,0,0.2C574,813.2,574.1,813.1,574.1,813z M574,813.4
                c0,0.1,0,0.2,0,0.2C574,813.5,574,813.4,574,813.4z"/>
              <path class="st4" d="M581.9,808.3c-2.7-1-4.3,3.1-1.6,4.2C583,813.5,584.6,809.3,581.9,808.3z"/>
              <path class="st4" d="M590.8,782.8c-0.2-0.2-0.4-0.3-0.7-0.5c-2.7-1-4.3,3.1-1.6,4.2c0.2,0.1,0.4,0.1,0.5,0.1
                C591,787,592.2,784.2,590.8,782.8z"/>
              <path class="st4" d="M1676.3,707.4c-1.2-0.1-1.8,0.9-1.7,1.9c0.4,0,0.7,0,1.1,0.1c0.6,0.3,1.1,0.5,1.7,0.8
                C1678.1,709.3,1677.8,707.6,1676.3,707.4z"/>
              <path class="st4" d="M1677,720.8c1.7-0.3,1.7-3-0.1-3.2c-0.1,0.1-0.2,0.1-0.2,0.2C1677.6,718.5,1677.6,719.9,1677,720.8z"/>
              <path class="st4" d="M1681.7,715.2c2.1,0.2,2.4-3.1,0.3-3.3C1679.9,711.8,1679.6,715,1681.7,715.2z"/>
              <path class="st4" d="M1684,707.4c-0.3,0.9,0.1,2.1,1.3,2.2c0.1,0,0.3,0,0.4,0v-2.1C1685.2,707.7,1684.6,707.7,1684,707.4z"/>
              <path class="st4" d="M1671,715.9c2.1,0.2,2.4-3.1,0.3-3.3C1669.2,712.4,1668.9,715.7,1671,715.9z"/>
              <path class="st4" d="M1683.4,720.4c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2
                c-0.7,0.6-0.8,1.6-0.2,2.3c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.3,0.7,0.5,1.1,0.6C1681.8,722,1682.2,720.9,1683.4,720.4z"/>
              <path class="st4" d="M1672.5,723.2c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9c0.1,0.4,0.2,0.8,0.2,1.2
                c0.2,0.8,1.1,1.5,1.9,1.3c0.9-0.2,1.5-1,1.3-1.9C1672.7,724,1672.6,723.6,1672.5,723.2z"/>
              <path class="st4" d="M1683.2,699.3c-0.7-0.5-1.8-0.4-2.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.6-0.2,1.3-0.7,1.8
                c0,0.5,0.2,0.9,0.7,1.2c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1C1684,700.8,1683.9,699.8,1683.2,699.3z"/>
              <path class="st4" d="M1678.6,726.2c-0.7-0.1-1.3-0.2-2-0.3c-0.9-0.1-1.7,0.7-1.8,1.5c0,1,0.6,1.6,1.5,1.8
                c0.7,0.1,1.3,0.2,2,0.3c0.9,0.1,1.7-0.7,1.8-1.5C1680.2,727,1679.5,726.3,1678.6,726.2z"/>
              <path class="st4" d="M1684,729.4c-0.1,0.6,0,1.2,0.1,1.8c0.2,0.3,0.4,0.6,0.4,1c0.3,0.4,0.8,0.8,1.3,0.8v-5.1c0,0,0,0-0.1,0
                C1684.8,727.9,1684.1,728.5,1684,729.4z"/>
              <path class="st4" d="M1678.2,731c-2.1-0.2-2.4,3.1-0.3,3.3C1680.1,734.5,1680.3,731.2,1678.2,731z"/>
              <path class="st4" d="M1670.9,731.8c-1-0.2-1.7-1.2-1.9-2.2c-1.6,0.3-1.7,3.1,0.2,3.2C1670.2,733,1670.7,732.5,1670.9,731.8z"
                />
              <path class="st4" d="M1666,705.4c0-0.1,0-0.1,0-0.2c-1.1,0.7-1,2.9,0.7,3.1c0.4,0,0.8-0.1,1.1-0.3
                C1666.8,707.7,1666,706.6,1666,705.4z"/>
              <path class="st4" d="M1663.5,721.9c-2.1-0.2-2.4,3.1-0.3,3.3C1665.3,725.3,1665.6,722.1,1663.5,721.9z"/>
              <path class="st4" d="M1659,716.2c2.1,0.2,2.4-3.1,0.3-3.3C1657.2,712.8,1656.9,716,1659,716.2z"/>
              <path class="st4" d="M1669.6,694.9c-2.1-0.2-2.4,3.1-0.3,3.3C1671.4,698.4,1671.7,695.1,1669.6,694.9z"/>
              <path class="st4" d="M1670.9,731.8c0.4,0.1,0.8,0.1,1.3,0c3.1-0.9,1.7-5.8-1.4-4.9c-1.4,0.4-1.9,1.6-1.7,2.8
                C1669.2,730.7,1669.9,731.6,1670.9,731.8z"/>
              <path class="st4" d="M1677,741.3c-3.1,0.9-1.7,5.8,1.4,4.9C1681.6,745.2,1680.2,740.4,1677,741.3z"/>
              <path class="st4" d="M1681.4,730.3c-3.1,0.9-1.7,5.8,1.4,4.9c1.5-0.4,2-1.8,1.7-3c-0.1-0.4-0.2-0.7-0.4-1
                C1683.5,730.5,1682.6,730,1681.4,730.3z"/>
              <path class="st4" d="M1684.2,721.4c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
                c-1.2,0.5-1.6,1.6-1.4,2.6c0.2,1.4,1.4,2.7,3.1,2.2c0.3-0.1,0.5-0.2,0.7-0.3v-1.6C1685,722.9,1684.4,722.2,1684.2,721.4z"/>
              <path class="st4" d="M1666.4,737c-3.1,0.9-1.7,5.8,1.4,4.9C1671,741,1669.6,736.1,1666.4,737z"/>
              <path class="st4" d="M1683.8,742.3c-0.6,1.2-0.3,2.8,1,3.4c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.1,0.4,0.2V741
                C1685,741.1,1684.2,741.5,1683.8,742.3z"/>
              <path class="st4" d="M1673.9,751.6c-0.7-1.1-2.3-1.6-3.5-0.8c-1.1,0.8-1.6,2.3-0.8,3.5c0.3,0.5,0.7,1.1,1,1.6
                c0.7,1.1,2.3,1.6,3.5,0.8c1.1-0.8,1.6-2.3,0.8-3.5C1674.6,752.7,1674.3,752.1,1673.9,751.6z"/>
              <path class="st4" d="M1676.7,715.2c-0.9-0.4-1.8-0.8-2.7-1.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.1,0.5-0.2,1-0.3,1.6
                c-0.2,1,0.1,2,0.9,2.6c0.6-0.4,1.3-0.6,2.1-0.3c0.3,0.1,0.5,0.3,0.7,0.4c0.1,0,0.2-0.1,0.2-0.2c0.4-0.3,0.8-0.8,0.9-1.4
                c0.1-0.3,0.1-0.6,0.2-0.9C1677.5,715.4,1677.1,715.4,1676.7,715.2z"/>
              <path class="st4" d="M1684.3,752.7c-1,0.2-2,0.4-3.1,0.6c-1.3,0.3-2.1,1.9-1.7,3.1c0.5,1.4,1.7,2,3.1,1.7c1-0.2,2-0.4,3.1-0.6
                c0,0,0.1,0,0.1,0v-4.8C1685.3,752.6,1684.8,752.6,1684.3,752.7z"/>
              <path class="st4" d="M1685.7,764.4C1685.7,764.4,1685.8,764.3,1685.7,764.4l0.1-4.3C1684.1,761,1684.4,763.4,1685.7,764.4z"/>
              <path class="st4" d="M1673.2,762.5c-1.8,0.5-2.1,2.3-1.4,3.6c0.1,0,0.2,0,0.3,0c0.9,0.1,1.3,0.7,1.4,1.3
                c0.4,0.1,0.8,0.1,1.2-0.1C1677.7,766.5,1676.3,761.6,1673.2,762.5z"/>
              <path class="st4" d="M1656.2,728.4c-3.1,0.9-1.7,5.8,1.4,4.9C1660.7,732.4,1659.3,727.5,1656.2,728.4z"/>
              <path class="st4" d="M1660.2,754.7c-3.1,0.9-1.7,5.8,1.4,4.9C1664.7,758.6,1663.3,753.8,1660.2,754.7z"/>
              <path class="st4" d="M1649.4,744.1c-3.1,0.9-1.7,5.8,1.4,4.9C1653.9,748,1652.5,743.2,1649.4,744.1z"/>
              <path class="st4" d="M1655.7,717.4c3.1-0.9,1.7-5.8-1.4-4.9C1651.2,713.4,1652.6,718.3,1655.7,717.4z"/>
              <path class="st4" d="M1672,766.1c-0.1,0-0.2,0-0.3,0c-1.8,0.1-1.9,3,0,3.2c1.2,0.1,1.8-0.9,1.6-1.9
                C1673.3,766.8,1672.8,766.2,1672,766.1z"/>
              <path class="st4" d="M1672.3,779.1c2,0.2,2.3-3,0.2-3.2C1670.5,775.8,1670.2,778.9,1672.3,779.1z"/>
              <path class="st4" d="M1677.3,773.6c2,0.2,2.3-3,0.2-3.2C1675.5,770.3,1675.2,773.5,1677.3,773.6z"/>
              <path class="st4" d="M1683.8,779.3c2,0.2,2.3-3,0.2-3.2C1682,776,1681.8,779.2,1683.8,779.3z"/>
              <path class="st4" d="M1680.8,768.2c2,0.2,2.3-3,0.2-3.2C1679,764.9,1678.7,768,1680.8,768.2z"/>
              <path class="st4" d="M1667.2,771.1c-2-0.2-2.3,3-0.2,3.2C1669,774.5,1669.2,771.3,1667.2,771.1z"/>
              <path class="st4" d="M1684.6,772.8c0.1,0.6,0.6,1.1,1.2,1.2V771C1685,771.2,1684.4,772,1684.6,772.8z"/>
              <path class="st4" d="M1676.4,780.6c0.5,0.6,1.6,0.8,2.2,0.2c0.6-0.6,0.7-1.6,0.2-2.2c-0.1-0.1-0.2-0.2-0.3-0.3
                c-0.5-0.6-1.6-0.8-2.2-0.2c-0.6,0.6-0.7,1.6-0.2,2.2C1676.2,780.3,1676.3,780.5,1676.4,780.6z"/>
              <path class="st4" d="M1668.7,782.5c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1-1.5-1.9-1.3c-0.8,0.2-1.4,1-1.3,1.9
                c0.1,0.4,0.2,0.8,0.2,1.2c0,0.1,0,0.2,0.1,0.2c0.7,0.1,1.3,0.5,1.6,1c0.1,0,0.1,0,0.2,0
                C1668.3,784.2,1668.9,783.4,1668.7,782.5z"/>
              <path class="st4" d="M1678.6,758.2c-0.7-0.4-1.7-0.4-2.2,0.4c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.7-0.4,1.7,0.4,2.2
                c0.7,0.4,1.7,0.4,2.2-0.4c0.2-0.3,0.4-0.6,0.6-0.9C1679.4,759.7,1679.3,758.7,1678.6,758.2z"/>
              <path class="st4" d="M1674.3,784.2c-0.6-0.1-1.3-0.2-1.9-0.3c-0.8-0.1-1.7,0.7-1.7,1.5c0,0.9,0.6,1.6,1.5,1.7
                c0.6,0.1,1.3,0.2,1.9,0.3c0.8,0.1,1.7-0.7,1.7-1.5C1675.8,785,1675.2,784.4,1674.3,784.2z"/>
              <path class="st4" d="M1681.8,788.6c0.2-0.5,0.4-1,0.6-1.5c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.5-0.8-0.9-1.3-0.9
                c-0.9,0-1.6,0.6-1.7,1.5c-0.2,0.9,0,1.9,0.5,2.6c0.4,0.7,1.2,1.1,2,0.8C1681.6,790.1,1681.6,789.3,1681.8,788.6z"/>
              <path class="st4" d="M1685.8,785.8v-2.4C1685.1,784.1,1685.1,785.2,1685.8,785.8z"/>
              <path class="st4" d="M1673.7,792.1c2,0.2,2.3-3,0.2-3.2C1671.9,788.7,1671.7,791.9,1673.7,792.1z"/>
              <path class="st4" d="M1664.5,788c-0.9,0.8-0.7,2.7,0.9,2.8c1.7,0.1,2.2-2.1,1-2.9c-0.2,0.1-0.5,0.2-0.8,0.2
                C1665.2,788.1,1664.8,788.1,1664.5,788z"/>
              <path class="st4" d="M1685.8,766.3v-2c0,0,0,0.1-0.1,0.1C1685.4,765,1685.4,765.8,1685.8,766.3z"/>
              <path class="st4" d="M1662.8,767c2,0.2,2.3-3,0.2-3.2C1661,763.7,1660.8,766.8,1662.8,767z"/>
              <path class="st4" d="M1659.7,780.2c-2-0.2-2.3,3-0.2,3.2C1661.5,783.5,1661.7,780.3,1659.7,780.2z"/>
              <path class="st4" d="M1655.4,774.7c2,0.2,2.3-3,0.2-3.2C1653.6,771.4,1653.3,774.6,1655.4,774.7z"/>
              <path class="st4" d="M1665.4,754.1c-2-0.2-2.3,3-0.2,3.2C1667.2,757.4,1667.4,754.2,1665.4,754.1z"/>
              <path class="st4" d="M1665.7,677.4c1.9,1.1,3.5-1.9,1.6-3C1665.4,673.4,1663.8,676.4,1665.7,677.4z"/>
              <path class="st4" d="M1663.5,684.2c-1.9-1.1-3.5,1.9-1.6,3C1663.7,688.2,1665.4,685.3,1663.5,684.2z"/>
              <path class="st4" d="M1669.1,684.1c1.9,1.1,3.5-1.9,1.6-3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0.1-0.4,0.1-0.7,0.1
                C1668.2,681.4,1667.6,683.3,1669.1,684.1z"/>
              <path class="st4" d="M1674.6,689.6c0.6,0.9,0.3,2.3-0.4,3.1C1675.3,692.3,1675.9,690.4,1674.6,689.6z"/>
              <path class="st4" d="M1675.4,678.7c0.7-0.4,1.4-0.4,2-0.2c-0.1-0.4-0.3-0.8-0.8-1.1c-1.6-0.9-3,1.1-2.3,2.3
                C1674.5,679.4,1674.8,679,1675.4,678.7z"/>
              <path class="st4" d="M1659.7,677c-1.5,0-2.4,2.3-0.8,3.2c1.4,0.8,2.7-0.7,2.5-1.9C1660.6,678.1,1660,677.6,1659.7,677z"/>
              <path class="st4" d="M1679.3,685.5c-0.2-0.1-0.5-0.1-0.7-0.2c-0.9-0.2-1.9,0.3-2,1.2c-0.2,0.9,0.3,1.8,1.2,2
                c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.7-0.2,2-1c-0.1,0-0.1,0-0.2,0C1679.6,687.5,1679.2,686.5,1679.3,685.5z"/>
              <path class="st4" d="M1668.4,689.5c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.4,1.2-1.2,2.1
                c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.2,1.5,2.1,1.2C1668.1,691.3,1668.7,690.4,1668.4,689.5z"/>
              <path class="st4" d="M1654.8,690.1c0.9,0.2,1.8-0.3,2-1.2c0,0,0-0.1,0-0.1c-0.6,0.4-1.3,0.6-2.1,0.3c-0.5-0.2-0.9-0.5-1.2-0.9
                C1653.4,688.9,1653.9,689.9,1654.8,690.1z"/>
              <path class="st4" d="M1675.1,672.6c-0.1,0.1-0.3,0.3-0.5,0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,1-0.6
                c0.7-0.5,1.1-1.5,0.6-2.3c-0.4-0.6-1.2-1-1.8-0.8C1676.3,670,1676.1,671.6,1675.1,672.6z"/>
              <path class="st4" d="M1657.5,692.5c-0.4,0.9-0.1,1.8,0.7,2.3c0.6,0.4,1.2,0.8,1.7,1.2c0.7,0.5,1.9,0.1,2.3-0.7
                c0.4-0.9,0.1-1.8-0.7-2.3c-0.6-0.4-1.2-0.8-1.7-1.2C1659.1,691.4,1657.9,691.7,1657.5,692.5z"/>
              <path class="st4" d="M1667.9,700.3c0.1-0.1,0.2-0.3,0.2-0.3c0.5-0.7,0.1-1.9-0.7-2.3c-0.9-0.4-1.8-0.1-2.3,0.7
                c-0.5,0.8-0.8,1.8-0.7,2.8c0.1,0.9,0.8,1.7,1.7,1.7c0.1,0,0.2,0,0.3,0c0.3-0.5,0.7-0.9,1.3-1.1c0.1-0.2,0.1-0.4,0.1-0.6
                c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2C1667.8,700.4,1667.9,700.3,1667.9,700.3z"/>
              <path class="st4" d="M1672.6,700.5c1.9,1.1,3.5-1.9,1.6-3C1672.3,696.4,1670.7,699.4,1672.6,700.5z"/>
              <path class="st4" d="M1658.7,698.7c0.5,0.2,0.8,0.5,1.1,0.8c0.3-0.7,0.2-1.6-0.7-2.1c-1.3-0.7-2.5,0.4-2.5,1.6
                C1657.2,698.5,1657.9,698.4,1658.7,698.7z"/>
              <path class="st4" d="M1649.9,695.3c1.9,1.1,3.6-1.9,1.6-3C1649.7,691.3,1648,694.3,1649.9,695.3z"/>
              <path class="st4" d="M1681.4,681.8c1.9,1.1,3.5-1.9,1.6-3C1681.1,677.8,1679.5,680.8,1681.4,681.8z"/>
              <path class="st4" d="M1659.7,668.3c-1.9-1.1-3.5,1.9-1.6,3C1660,672.3,1661.6,669.3,1659.7,668.3z"/>
              <path class="st4" d="M1649.2,682.5c-1.9-1.1-3.5,1.9-1.6,3C1649.4,686.6,1651.1,683.6,1649.2,682.5z"/>
              <path class="st4" d="M1683.6,695.6c-1.9-1.1-3.5,1.9-1.6,3C1683.9,699.6,1685.5,696.7,1683.6,695.6z"/>
              <path class="st4" d="M1647.5,675.4c1.9,1.1,3.5-1.9,1.6-3C1647.2,671.4,1645.6,674.3,1647.5,675.4z"/>
              <path class="st4" d="M1666.4,659.9c-1.9-1.1-3.5,1.9-1.6,3C1666.6,663.9,1668.3,661,1666.4,659.9z"/>
              <path class="st4" d="M1654.4,590.1c1.8,1.4,4-1.4,2.2-2.8C1654.8,585.9,1652.6,588.7,1654.4,590.1z"/>
              <path class="st4" d="M1662.4,588c1.8,1.4,4-1.4,2.2-2.8C1662.8,583.9,1660.6,586.6,1662.4,588z"/>
              <path class="st4" d="M1665,595.9c0.1-0.4,0.1-0.9,0.2-1.3c0,0,0-0.1,0-0.1c-0.9,0.6-1.3,2-0.2,2.9c0.1,0.1,0.3,0.2,0.4,0.2
                C1665,597.2,1664.9,596.5,1665,595.9z"/>
              <path class="st4" d="M1669,585.2c0.8,0.7,1.8,0.4,2.3-0.2c-0.9-0.2-1.9-0.3-2.8-0.3C1668.6,584.8,1668.8,585,1669,585.2z"/>
              <path class="st4" d="M1670.6,594.1c0.4,0.2,0.8,0.3,1.2,0.5c0.9,0.3,2-0.1,2.3-1c0.3-0.9,0-1.9-1-2.3
                c-0.4-0.2-0.8-0.3-1.2-0.5c-0.9-0.3-2,0.1-2.3,1C1669.3,592.7,1669.7,593.7,1670.6,594.1z"/>
              <path class="st4" d="M1657.4,594c0.1,0.9,1,1.7,2,1.5c0.9-0.2,1.7-1,1.5-2c0-0.2,0-0.3-0.1-0.5c-0.1-0.9-1-1.7-2-1.5
                c-0.9,0.2-1.7,1-1.5,2C1657.3,593.6,1657.3,593.8,1657.4,594z"/>
              <path class="st4" d="M1646.7,592c0.9,0.3,1.9,0,2.3-1c0.2-0.4,0.3-0.8,0.5-1.2c0.3-0.9-0.1-2-1-2.3c-0.9-0.3-1.9,0-2.3,1
                c-0.2,0.4-0.3,0.8-0.5,1.2C1645.4,590.5,1645.8,591.7,1646.7,592z"/>
              <path class="st4" d="M1649.1,595c-0.6,0.8-0.4,1.8,0.3,2.5c0.5,0.5,1.1,1,1.6,1.5c0.7,0.6,2,0.4,2.5-0.3
                c0.6-0.8,0.4-1.8-0.3-2.5c0,0-0.1-0.1-0.1-0.1c0.2,0.9-0.1,1.9-1.2,2.3c-2.4,0.8-3.6-2.9-1.2-3.7c0.3-0.1,0.5-0.1,0.7-0.1
                C1650.7,594.1,1649.6,594.3,1649.1,595z"/>
              <path class="st4" d="M1658.6,601.8c-0.8-0.6-1.8-0.4-2.5,0.3c-0.7,0.7-1.1,1.8-1.1,2.8c0,0.9,0.5,1.9,1.5,2
                c0.9,0.1,1.9-0.5,2-1.5c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
                C1659.5,603.6,1659.3,602.4,1658.6,601.8z M1658.5,604.7c0,0,0-0.1,0.1-0.1C1658.6,604.6,1658.6,604.7,1658.5,604.7z"/>
              <path class="st4" d="M1665.6,605.5c0.8-0.7,1.1-2,0.1-2.8c-1.7-1.3-3.8,1.2-2.3,2.6c0.1-0.1,0.3-0.2,0.5-0.2
                C1664.5,604.9,1665.1,605.1,1665.6,605.5z"/>
              <path class="st4" d="M1650,600.3C1650,600.2,1650,600.2,1650,600.3c-1.8-1.4-4,1.4-2.2,2.8c0.9,0.7,1.8,0.4,2.4-0.3
                C1649.5,602.1,1649.4,601,1650,600.3z"/>
              <path class="st4" d="M1643.5,596c-0.2,0.1-0.5,0.1-0.7,0.2c-0.9,0.2-1.8-0.4-2.1-1.3c-0.1-0.3-0.1-0.6,0-0.8
                c-0.7,0.7-0.9,1.9,0.1,2.6C1641.9,597.5,1643.1,596.9,1643.5,596z"/>
              <path class="st4" d="M1675.5,587.7c0.8,0.6,1.7,0.4,2.2-0.1c-0.9-0.6-1.8-1.1-2.8-1.5C1674.7,586.6,1674.9,587.2,1675.5,587.7
                z"/>
              <path class="st4" d="M1639.9,586.1c0.5,0.4,1,0.4,1.5,0.3c0.1-0.7,0.5-1.4,1.3-1.5c0-0.1,0-0.2,0-0.4h-3.5
                C1639.2,585.1,1639.3,585.7,1639.9,586.1z"/>
              <path class="st4" d="M1674.7,603.4c-0.7,0.2-1.3,0.1-1.8-0.3c-0.3,0.6-0.2,1.4,0.5,2c1.7,1.3,3.8-1.2,2.3-2.6
                C1675.6,602.8,1675.2,603.2,1674.7,603.4z"/>
              <path class="st4" d="M1665,608.9c1.6-0.5,1.6-2.5,0.6-3.3c-0.4-0.4-1.1-0.6-1.8-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
                C1661.5,606.5,1662.7,609.6,1665,608.9z"/>
              <path class="st4" d="M1669,616c-2.4,0.8-1.2,4.5,1.2,3.7C1672.6,618.9,1671.4,615.2,1669,616z"/>
              <path class="st4" d="M1673.2,611.2c2.4-0.8,1.2-4.5-1.2-3.7C1669.6,608.3,1670.9,612,1673.2,611.2z"/>
              <path class="st4" d="M1683.4,614.6c2.4-0.8,1.2-4.5-1.2-3.7C1679.8,611.6,1681,615.3,1683.4,614.6z"/>
              <path class="st4" d="M1673.5,599.7c-1.7,0.5-1.6,2.5-0.5,3.4c0.4,0.4,1,0.5,1.8,0.3c0.6-0.2,0.9-0.5,1.1-0.9
                C1676.4,601.1,1675.3,599.1,1673.5,599.7z"/>
              <path class="st4" d="M1661.9,616.8c2.4-0.8,1.2-4.5-1.2-3.7C1658.3,613.9,1659.5,617.6,1661.9,616.8z"/>
              <path class="st4" d="M1682.9,603.4c-0.4,0.3-0.8,0.5-1.2,0.8c-0.8,0.6-1.2,1.8-0.5,2.7c0.6,0.8,1.8,1.2,2.7,0.5
                c0.4-0.3,0.8-0.5,1.2-0.8c0.3-0.2,0.6-0.6,0.7-0.9v-1.4c0-0.1-0.1-0.2-0.2-0.3C1685,603.1,1683.8,602.7,1682.9,603.4z"/>
              <path class="st4" d="M1674.2,616.6c-0.4,1-0.1,2.1,0.9,2.6c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.9
                c0.4-1,0.1-2.1-0.9-2.6c-0.2-0.1-0.3-0.2-0.5-0.3C1675.9,615.3,1674.6,615.6,1674.2,616.6z"/>
              <path class="st4" d="M1664.4,627.4c0.3,0.4,0.7,0.7,1.2,0.8c0.2-1.1,1.3-1.8,2.3-2c0-0.3-0.1-0.7-0.3-1
                c-0.3-0.4-0.5-0.8-0.8-1.2c-0.6-0.8-1.8-1.2-2.7-0.5c-0.8,0.6-1.2,1.8-0.5,2.7C1663.9,626.6,1664.2,627,1664.4,627.4z"/>
              <path class="st4" d="M1665.2,594.6c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.6,0.1,1.2,0.5,1.7c0.3,0.3,0.6,0.5,1,0.6
                c1,0.2,2.1-0.4,2.3-1.5c0.1-0.4,0.1-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.3c-1-0.2-2,0.4-2.3,1.4
                C1665.2,594.5,1665.2,594.6,1665.2,594.6z"/>
              <path class="st4" d="M1677.4,625.8c-0.4-1.1-1.4-1.5-2.5-1.2c-0.8,0.2-1.6,0.4-2.3,0.6c-1,0.2-1.6,1.5-1.2,2.5
                c0.4,1.1,1.4,1.5,2.5,1.2c0.8-0.2,1.6-0.4,2.3-0.6C1677.1,628,1677.7,626.7,1677.4,625.8z"/>
              <path class="st4" d="M1683.5,623.2c-1.1,0.4-1.5,1.4-1.2,2.5c0.2,1,0.8,1.9,1.6,2.6c0.1-0.6,0.6-1.2,1.3-1.6
                c0.2-0.1,0.4-0.2,0.7-0.2v-2.3C1685.4,623.3,1684.3,622.9,1683.5,623.2z"/>
              <path class="st4" d="M1678.4,633.5c1.7-1.1,0.5-4.2-1.7-3.5c-2.3,0.7-1.3,4.1,0.8,3.8c0,0,0.1-0.1,0.1-0.1
                C1677.9,633.6,1678.2,633.5,1678.4,633.5z"/>
              <path class="st4" d="M1666.5,632.6c-2.2,0.9-1,4.4,1.4,3.6c1.5-0.5,1.6-2,0.9-3c-0.2,0-0.4,0.1-0.7,0.1
                C1667.4,633.3,1666.9,633,1666.5,632.6z"/>
              <path class="st4" d="M1679.8,595.3c-2.4,0.8-1.2,4.5,1.2,3.7C1683.4,598.2,1682.2,594.5,1679.8,595.3z"/>
              <path class="st4" d="M1652.5,606.7c-2.4,0.8-1.2,4.5,1.2,3.7C1656.1,609.7,1654.9,606,1652.5,606.7z"/>
              <path class="st4" d="M1657.6,630.5c2.4-0.8,1.2-4.5-1.2-3.7C1654,627.6,1655.2,631.3,1657.6,630.5z"/>
              <path class="st4" d="M1649,622.7c2.4-0.8,1.2-4.5-1.2-3.7C1645.4,619.8,1646.6,623.5,1649,622.7z"/>
              <path class="st4" d="M1651.9,598.3c1.1-0.4,1.4-1.4,1.2-2.3c-0.2-0.8-0.8-1.5-1.7-1.5c-0.2,0-0.5,0-0.7,0.1
                C1648.3,595.4,1649.5,599.1,1651.9,598.3z"/>
              <path class="st4" d="M1665.9,643c-3,1.5-0.6,6.2,2.4,4.7C1671.3,646.2,1668.9,641.5,1665.9,643z"/>
              <path class="st4" d="M1675.1,656.4c-3,1.5-0.6,6.2,2.4,4.7C1680.5,659.5,1678.1,654.8,1675.1,656.4z"/>
              <path class="st4" d="M1677.4,644.4c-3,1.5-0.6,6.2,2.4,4.7C1682.8,647.5,1680.4,642.8,1677.4,644.4z"/>
              <path class="st4" d="M1678.4,633.5c-0.2,0-0.5,0.1-0.7,0.2c0,0-0.1,0.1-0.1,0.1c-2.8,1.6-0.5,6.1,2.5,4.6
                C1682.8,637,1681,633,1678.4,633.5z"/>
              <path class="st4" d="M1665.8,658.8c3-1.5,0.6-6.2-2.4-4.7C1660.5,655.7,1662.8,660.4,1665.8,658.8z"/>
              <path class="st4" d="M1682.1,656c-0.4,1.4,0.3,2.8,1.7,3.3c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0.1,0.8,0.1,1.2,0.1v-5.2
                c-0.1,0-0.2-0.1-0.3-0.1C1684.1,653.9,1682.5,654.7,1682.1,656z"/>
              <path class="st4" d="M1674.7,673c0.2-0.1,0.3-0.2,0.5-0.4c1-1,1.1-2.6,0.2-3.6c0,0,0-0.1-0.1-0.1c-0.4-0.5-0.9-0.9-1.3-1.4
                c-0.9-1-2.7-1.2-3.7-0.2c-1,1-1.2,2.6-0.2,3.7c0.4,0.5,0.9,0.9,1.3,1.4C1672.2,673.3,1673.7,673.5,1674.7,673z"/>
              <path class="st4" d="M1665.7,628.2c0,0.2-0.1,0.3-0.1,0.5c0,0.6,0,1.2,0,1.8c0,0.8,0.3,1.5,0.9,2.1c0.4,0.4,1,0.7,1.6,0.7
                c0.2,0,0.4,0,0.7-0.1c1.1-0.3,2.1-1.2,2.1-2.4c0-0.6,0-1.2,0-1.8c0-1.4-1-2.7-2.5-2.8c-0.1,0-0.2,0-0.4,0
                C1666.9,626.4,1665.9,627.1,1665.7,628.2z"/>
              <path class="st4" d="M1681.8,667.7c-1.3,0.5-1.8,2.4-1.1,3.5c0.7,1.3,2.2,1.7,3.5,1.1c0.5-0.2,1.1-0.5,1.6-0.7v-5.5
                c-0.3,0-0.7,0.1-1,0.2C1683.8,666.9,1682.8,667.3,1681.8,667.7z"/>
              <path class="st4" d="M1677.8,683.4c2.3-1.2,1.5-4.1-0.4-4.8c-0.6-0.2-1.3-0.2-2,0.2c-0.5,0.3-0.9,0.7-1.1,1.1
                C1673.3,681.7,1675.3,684.6,1677.8,683.4z"/>
              <path class="st4" d="M1683.8,628.2c-0.3,1.4,0.6,3,2,3.3v-5.1c-0.2,0-0.4,0.1-0.7,0.2C1684.4,627,1684,627.6,1683.8,628.2z"/>
              <path class="st4" d="M1653.7,652.1c3-1.5,0.6-6.2-2.4-4.7C1648.3,649,1650.7,653.7,1653.7,652.1z"/>
              <path class="st4" d="M1660.6,673.3c-1.6,0.8-1.7,2.4-1,3.6c0.4,0.6,0.9,1.1,1.6,1.3c0.5,0.1,1.1,0.1,1.7-0.2
                C1666,676.5,1663.6,671.8,1660.6,673.3z"/>
              <path class="st4" d="M1649.9,669.4c3-1.5,0.6-6.2-2.4-4.7C1644.5,666.2,1646.9,670.9,1649.9,669.4z"/>
              <path class="st4" d="M1648.7,636.4c3-1.5,0.6-6.2-2.4-4.7C1643.3,633.2,1645.7,637.9,1648.7,636.4z"/>
              <path class="st4" d="M1679.3,685.5c-0.1,1,0.3,1.9,1.5,2.4c0.1,0,0.1,0,0.2,0c2.8,0.9,4.4-3.5,1.5-4.5
                C1680.7,682.8,1679.4,684.1,1679.3,685.5z"/>
              <path class="st4" d="M1679.2,697.9c-2.9-1.1-4.6,3.4-1.7,4.5c1.1,0.4,2,0,2.5-0.6c0.4-0.5,0.7-1.1,0.7-1.8
                C1680.7,699.1,1680.2,698.3,1679.2,697.9z"/>
              <path class="st4" d="M1685.8,695.5v-2.4C1685.4,693.9,1685.4,694.8,1685.8,695.5z"/>
              <path class="st4" d="M1674.6,689.6c-0.2-0.4-0.6-0.7-1.2-0.9c-2.9-1.1-4.6,3.4-1.7,4.5c1,0.4,1.9,0.1,2.4-0.5
                C1674.9,691.9,1675.2,690.5,1674.6,689.6z"/>
              <path class="st4" d="M1683.8,702.4c-1.2,0.6-1.7,2-1.2,3.2c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.5,0.7,0.9,1.2,1.1
                c0.5,0.3,1.2,0.3,1.8,0.1v-5.1C1685.2,702.2,1684.4,702.1,1683.8,702.4z"/>
              <path class="st4" d="M1666.5,702.7c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5-0.1,1-0.1,1.6c0,0.1,0,0.1,0,0.2c-0.1,1.1,0.7,2.3,1.8,2.5
                c0.1,0,0.2,0.1,0.4,0.1c1.3,0.1,2.5-0.8,2.6-2.2c0-0.6,0.1-1.2,0.1-1.8c0.1-1.3-0.9-2.5-2.2-2.6c-0.4,0-0.7,0.1-1,0.2
                C1667.2,701.8,1666.8,702.2,1666.5,702.7z"/>
              <path class="st4" d="M1674.6,709.3c-0.9,0.1-1.8,0.7-2,1.6c-0.3,1-0.1,1.9,0.6,2.5c0.2,0.2,0.5,0.4,0.8,0.6
                c0.9,0.4,1.8,0.8,2.7,1.2c0.4,0.2,0.8,0.2,1.3,0.1c0.8-0.2,1.6-0.8,1.8-1.5c0.4-1.3-0.2-2.5-1.4-3.1c-0.3-0.2-0.7-0.3-1-0.5
                c-0.6-0.3-1.1-0.5-1.7-0.8C1675.3,709.3,1674.9,709.3,1674.6,709.3z"/>
              <path class="st4" d="M1684.6,717.3c-0.4,0.9-0.6,1.9-0.5,2.9c0,0.4,0.1,0.8,0.2,1.2c0.2,0.8,0.8,1.6,1.6,1.9V716
                C1685.3,716.3,1684.8,716.8,1684.6,717.3z"/>
              <path class="st4" d="M1676.6,717.8c-0.2-0.2-0.4-0.3-0.7-0.4c-0.8-0.3-1.6-0.1-2.1,0.3c-1.3,1-1.6,3.5,0.5,4.2
                c1.2,0.5,2.3-0.1,2.8-1C1677.6,719.9,1677.6,718.5,1676.6,717.8z"/>
              <path class="st4" d="M1662.6,716.5c2.9,1.1,4.6-3.4,1.7-4.5C1661.4,710.9,1659.7,715.4,1662.6,716.5z"/>
              <path class="st4" d="M1670.4,676.3c-2.9-1.1-4.6,3.4-1.7,4.5c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1
                C1672.1,680.4,1672.8,677.2,1670.4,676.3z"/>
              <path class="st4" d="M1657,703.2c2.4,0.9,4-2.1,2.7-3.7c-0.2-0.3-0.6-0.6-1.1-0.8c-0.8-0.3-1.6-0.1-2.1,0.2
                C1655.2,699.9,1655,702.4,1657,703.2z"/>
              <path class="st4" d="M1654.7,689c0.8,0.3,1.6,0.1,2.1-0.3c1.3-1,1.6-3.5-0.5-4.2c-2.4-0.9-3.9,1.9-2.8,3.6
                C1653.8,688.5,1654.2,688.8,1654.7,689z"/>
              <path class="st4" d="M1677.9,663.1c-2.9-1.1-4.6,3.4-1.7,4.5C1679.1,668.7,1680.8,664.2,1677.9,663.1z"/>
              <path class="st4" d="M1678,799.3c-3,0.3-2.5,5.1,0.5,4.7C1681.6,803.7,1681.1,799,1678,799.3z"/>
              <path class="st4" d="M1682,818.3c3-0.3,2.5-5.1-0.5-4.7C1678.5,813.9,1679,818.6,1682,818.3z"/>
              <path class="st4" d="M1685.8,808v-3C1685.1,805.8,1685.2,807.1,1685.8,808z"/>
              <path class="st4" d="M1672.9,812.7c3-0.3,2.5-5.1-0.5-4.7C1669.3,808.3,1669.8,813,1672.9,812.7z"/>
              <path class="st4" d="M1677,822.6c-0.5-1.2-1.9-1.9-3.1-1.3c-1.2,0.5-1.8,1.9-1.3,3.1c0.2,0.5,0.4,1.1,0.7,1.6
                c0.5,1.2,1.9,1.9,3.1,1.3c1.2-0.5,1.8-1.9,1.3-3.1C1677.4,823.7,1677.2,823.2,1677,822.6z"/>
              <path class="st4" d="M1682.5,786.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.5-0.4,1-0.6,1.5c-0.3,0.7-0.2,1.5,0.1,2.2
                c0.2,0.4,0.6,0.8,1.1,0.9c0.9,0.4,2.1,0.2,2.8-0.6V786c-0.1,0-0.1-0.1-0.2-0.1C1684.5,785.4,1683.1,785.8,1682.5,786.8z"/>
              <path class="st4" d="M1681.4,828c0.2,1.4,1.3,2.1,2.6,2.1c0.6,0,1.2,0,1.7,0v-3.1c0-0.2,0-0.4,0-0.7v-0.9
                c-0.8,0-1.5,0-2.3,0.1C1682.3,825.4,1681.2,826.8,1681.4,828z"/>
              <path class="st4" d="M1685.8,836.2v-3.3C1684.9,833.8,1685,835.3,1685.8,836.2z"/>
              <path class="st4" d="M1673.9,837.2c0.3,0.1,0.7,0.2,1.2,0.2c3-0.3,2.5-5.1-0.5-4.7c-1.6,0.2-2.2,1.5-2,2.7c0.1,0,0.1,0,0.2,0
                C1673.6,835.7,1673.9,836.4,1673.9,837.2z"/>
              <path class="st4" d="M1664.8,803.1c3-0.3,2.5-5.1-0.5-4.7C1661.2,798.7,1661.7,803.4,1664.8,803.1z"/>
              <path class="st4" d="M1664.3,828c3-0.3,2.5-5.1-0.5-4.7C1660.7,823.6,1661.3,828.3,1664.3,828z"/>
              <path class="st4" d="M1656,816.4c3-0.3,2.5-5.1-0.5-4.7C1652.4,812.1,1652.9,816.8,1656,816.4z"/>
              <path class="st4" d="M1665.6,783.4c-0.2,0-0.4,0-0.5,0c-2.6,0.3-2.6,3.9-0.6,4.6c0.3,0.1,0.7,0.2,1.1,0.1
                c0.3,0,0.5-0.1,0.8-0.2c1.3-0.6,1.6-2.3,0.9-3.5C1666.9,783.8,1666.4,783.4,1665.6,783.4z"/>
              <path class="st4" d="M1679.9,831.3c-2.3-0.9-3.6,2.7-1.3,3.5C1680.8,835.7,1682.2,832.1,1679.9,831.3z"/>
              <path class="st4" d="M1676.5,844.9c-0.9-0.4-1.2-1.2-1.2-1.9c-0.9,0.9-1,2.6,0.5,3.2c1.1,0.4,2-0.2,2.4-1.1
                C1677.7,845.2,1677.1,845.2,1676.5,844.9z"/>
              <path class="st4" d="M1684.7,838.1c-2.3-0.9-3.6,2.7-1.3,3.5c1.2,0.5,2.1-0.3,2.4-1.2v-1.3
                C1685.6,838.7,1685.3,838.3,1684.7,838.1z"/>
              <path class="st4" d="M1671.4,838.9c1.4,0.5,2.4-0.6,2.5-1.7c0.1-0.7-0.3-1.5-1.2-1.8c-0.1,0-0.1,0-0.2,0
                C1670.4,834.7,1669.2,838.1,1671.4,838.9z"/>
              <path class="st4" d="M1679.8,848.8c0.1,0.2,0.1,0.3,0.2,0.5c0.4,0.9,1.6,1.4,2.5,1c0.9-0.5,1.4-1.5,1-2.5
                c-0.1-0.2-0.1-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-1C1679.9,846.7,1679.4,847.8,1679.8,848.8z"/>
              <path class="st4" d="M1668.5,850.5c1,0.1,2-0.6,2.1-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2.1c-1-0.1-2,0.6-2.1,1.7
                c0,0.5-0.1,0.9-0.1,1.4C1666.7,849.5,1667.5,850.5,1668.5,850.5z"/>
              <path class="st4" d="M1685.8,826.9v-0.7C1685.7,826.4,1685.7,826.7,1685.8,826.9z"/>
              <path class="st4" d="M1676.4,852.7c-0.7-0.3-1.4-0.7-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
                c0.7,0.3,1.4,0.7,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1677.9,854.1,1677.4,853.2,1676.4,852.7z"/>
              <path class="st4" d="M1681.3,858c-0.4,0.8-0.5,1.7-0.4,2.6c0.8,0.5,1.2,1.2,1.1,2c0.3,0.2,0.7,0.3,1.1,0.2
                c0.9-0.2,1.8-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0.2-0.4
                c0.4-0.9-0.2-2.1-1.1-2.4C1682.7,856.6,1681.8,857.1,1681.3,858z"/>
              <path class="st4" d="M1674.5,857.9c-2.3-0.9-3.6,2.7-1.3,3.5C1675.4,862.3,1676.7,858.8,1674.5,857.9z"/>
              <path class="st4" d="M1664,857.2c2.3,0.9,3.6-2.7,1.3-3.5C1663.1,852.8,1661.7,856.3,1664,857.2z"/>
              <path class="st4" d="M1669.1,829.1c2.3,0.9,3.6-2.7,1.3-3.5C1668.2,824.7,1666.8,828.3,1669.1,829.1z"/>
              <path class="st4" d="M1659.7,846.7c2.3,0.9,3.6-2.7,1.3-3.5C1658.8,842.3,1657.5,845.8,1659.7,846.7z"/>
              <path class="st4" d="M1658,835.5c2.3,0.9,3.6-2.7,1.3-3.5C1657.1,831.1,1655.7,834.6,1658,835.5z"/>
              <path class="st4" d="M1676.5,815.3c-2.3-0.9-3.6,2.7-1.3,3.5C1677.4,819.7,1678.7,816.1,1676.5,815.3z"/>
              <path class="st4" d="M1680.9,860.6C1680.9,860.6,1680.8,860.6,1680.9,860.6c-2.7-1.4-4.8,2.7-2.1,4.1c1.7,0.9,3.2-0.6,3.3-2.1
                C1682.1,861.8,1681.7,861.1,1680.9,860.6z"/>
              <path class="st4" d="M1676.2,874c-2.6-1.3-4.7,2.8-2.1,4.1C1676.8,879.5,1678.8,875.3,1676.2,874z"/>
              <path class="st4" d="M1683.8,873.5c0.7,0.4,1.4,0.3,2,0v-4.1C1683.2,868.1,1681.2,872.2,1683.8,873.5z"/>
              <path class="st4" d="M1669.6,868.8c2.6,1.3,4.7-2.8,2.1-4.1C1669,863.3,1667,867.5,1669.6,868.8z"/>
              <path class="st4" d="M1680.1,878.8c-1.2,0.4-1.9,1.7-1.5,2.9c0.1,0.2,0.1,0.4,0.2,0.6c0.4,1.1,1.8,1.9,2.9,1.5
                c1.2-0.4,1.9-1.7,1.5-2.9c-0.1-0.2-0.1-0.4-0.2-0.6C1682.6,879.1,1681.3,878.4,1680.1,878.8z"/>
              <path class="st4" d="M1665.9,876.4c-1.2-0.2-2.5,0.5-2.7,1.8c-0.1,0.6-0.2,1.1-0.3,1.7c-0.2,1.2,0.6,2.5,1.8,2.7
                c1.2,0.2,2.5-0.5,2.7-1.8c0.1-0.6,0.2-1.1,0.3-1.7C1668,877.9,1667.2,876.5,1665.9,876.4z"/>
              <path class="st4" d="M1669.6,888.8c0.8,0.5,1.6,1,2.4,1.5c1,0.6,2.6,0,3.1-1c0.5-1.2,0.1-2.4-1-3.1c-0.8-0.5-1.6-1-2.4-1.5
                c-1-0.6-2.6,0-3.1,1C1668,886.9,1668.5,888.1,1669.6,888.8z"/>
              <path class="st4" d="M1682.4,892.1c-1.2-0.5-2.4-0.1-3.1,1c-0.7,1.1-1,2.5-0.8,3.8c0.2,1.2,1.1,2.3,2.4,2.1
                c1.1-0.1,2.4-1.2,2.1-2.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.2-0.4
                C1684.1,894.2,1683.5,892.6,1682.4,892.1z M1683.1,896.2c0-0.1,0-0.2,0-0.2C1683.1,896,1683.1,896.1,1683.1,896.2z
                M1683.2,895.6c0,0,0,0.1-0.1,0.2C1683.1,895.7,1683.1,895.7,1683.2,895.6z"/>
              <path class="st4" d="M1671,892.2c-2.6-1.3-4.7,2.8-2.1,4.1C1671.6,897.7,1673.7,893.5,1671,892.2z"/>
              <path class="st4" d="M1660.5,885.9c-2.6-1.3-4.7,2.8-2.1,4.1C1661.1,891.4,1663.2,887.2,1660.5,885.9z"/>
              <path class="st4" d="M1670.1,852.6c-2.6-1.3-4.7,2.8-2.1,4.1C1670.7,858,1672.7,853.9,1670.1,852.6z"/>
              <path class="st4" d="M1656.6,872.7c-2.6-1.3-4.7,2.8-2.1,4.1C1657.2,878.1,1659.3,874,1656.6,872.7z"/>
              <path class="st4" d="M1653.8,863c2.7,1.3,4.7-2.8,2.1-4.1C1653.2,857.6,1651.2,861.7,1653.8,863z"/>
              <path class="st4" d="M1676.5,844.9c0.6,0.3,1.2,0.3,1.7,0.1c1.7-0.6,2.4-3.2,0.4-4.3c-1.8-0.9-3.3,0.7-3.3,2.2
                C1675.3,843.8,1675.7,844.5,1676.5,844.9z"/>
              <path class="st4" d="M1581.8,588.5c-2.1-2-5.1,1.3-3,3.2C1580.8,593.7,1583.9,590.5,1581.8,588.5z"/>
              <path class="st4" d="M1592.4,587.4c-0.1-0.2-0.2-0.4-0.4-0.5c-2.1-2-5.1,1.3-3,3.2c1.4,1.3,3.3,0.2,3.7-1.2
                C1592.3,588.5,1592.3,587.9,1592.4,587.4z"/>
              <path class="st4" d="M1591.1,602.1c2.1,2,5.1-1.3,3-3.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.1,0-0.2,0.1-0.3,0.1
                C1590.7,598.7,1589.7,600.7,1591.1,602.1z"/>
              <path class="st4" d="M1597.5,587.3c1.9,1.8,4.6-0.8,3.5-2.7h-0.4c-0.4,0.2-0.8,0.2-1.4,0c0,0,0,0,0,0h-2
                C1596.7,585.4,1596.6,586.4,1597.5,587.3z"/>
              <path class="st4" d="M1603,598.5c0.5-1.1,0.2-2.4-0.9-3c-0.5-0.3-1-0.5-1.5-0.8c-1-0.5-2.5-0.2-3,0.9c-0.5,1.1-0.2,2.4,0.9,3
                c0.5,0.3,1,0.5,1.5,0.8C1601,600,1602.5,599.6,1603,598.5z M1599.1,598.2c-2.3-0.8-1-4.4,1.3-3.5
                C1602.7,595.5,1601.4,599.1,1599.1,598.2z"/>
              <path class="st4" d="M1582,597c0,1.2,1.1,2.2,2.3,2.1c1.2-0.1,2.2-1.1,2.1-2.3c0-0.2,0-0.4,0-0.6c0-1.2-1.1-2.2-2.3-2.1
                c-1.2,0.1-2.2,1.1-2.1,2.3C1581.9,596.5,1582,596.7,1582,597z"/>
              <path class="st4" d="M1568.8,593.2c0,0,0.1,0,0.1,0c-0.1-1.2,1-2.5,2.5-2c0.3,0.1,0.5,0.3,0.7,0.4c0.2-0.3,0.3-0.6,0.5-0.9
                c0,0,0-0.1,0.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.3-2-1.5-1.9-2.7c-0.5,0.2-0.9,0.5-1.1,1c-0.3,0.5-0.5,1-0.8,1.5
                C1567.4,591.3,1567.8,592.7,1568.8,593.2z"/>
              <path class="st4" d="M1575.8,601.7c0.4,0.1,0.6,0.3,0.8,0.6c0.9-1,0.8-2.2-0.1-3.2c-0.6-0.7-1.2-1.3-1.8-2
                c-0.8-0.9-2.4-0.8-3.1,0.1c-0.8,1-0.7,2.2,0.1,3.1c0.6,0.7,1.2,1.3,1.8,2c0,0,0.1,0.1,0.1,0.1
                C1574,601.8,1574.8,601.3,1575.8,601.7z"/>
              <path class="st4" d="M1582.5,606.9c-1-0.8-2.2-0.7-3.1,0.1c-0.9,0.8-1.6,2.1-1.8,3.3c-0.1,1.1,0.4,2.4,1.7,2.7
                c1.1,0.2,2.5-0.4,2.7-1.7c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.4-0.3
                c0.3-0.2,0.4-0.5,0.5-0.8C1582.3,608.7,1582.2,607.7,1582.5,606.9z M1582,610.9c0-0.1,0-0.2,0.1-0.2
                C1582.1,610.7,1582.1,610.8,1582,610.9z M1582.2,610.5c0,0,0.1-0.1,0.1-0.2C1582.3,610.4,1582.2,610.5,1582.2,610.5z"/>
              <path class="st4" d="M1591.3,608.9c-2.1-2-5.2,1.3-3.1,3.2c1.7,1.6,4-0.2,3.7-2c-0.3-0.3-0.5-0.7-0.6-1.1
                C1591.3,609,1591.3,609,1591.3,608.9z"/>
              <path class="st4" d="M1569,607.1c2.1,2,5.1-1.3,3-3.2C1569.9,601.9,1566.9,605.2,1569,607.1z"/>
              <path class="st4" d="M1562.9,594.6c-1.9-0.3-3.6,2.2-1.9,3.8c1.6,1.5,3.8-0.1,3.7-1.8c-0.2-0.1-0.4-0.1-0.6-0.3
                C1563.4,595.9,1563,595.3,1562.9,594.6z"/>
              <path class="st4" d="M1608.4,588c-1.5-1.4-3.5-0.1-3.7,1.5c0.3,0,0.5,0,0.8,0.1c1,0.1,1.8,1,1.7,2c0,0.1,0,0.1,0,0.2
                C1608.7,591.5,1609.9,589.4,1608.4,588z"/>
              <path class="st4" d="M1564.4,584.6h-3.8c0.1,0.2,0.2,0.4,0.4,0.5C1562.2,586.2,1563.7,585.6,1564.4,584.6z"/>
              <path class="st4" d="M1600.8,612.7c2.1,2,5.1-1.3,3-3.2C1601.7,607.5,1598.7,610.7,1600.8,612.7z"/>
              <path class="st4" d="M1626.8,588.2c-2.2-0.1-2.3,3.4-0.1,3.4C1628.9,591.7,1629,588.3,1626.8,588.2z"/>
              <path class="st4" d="M1627.8,602.2c2.2,0.1,2.3-3.4,0.1-3.4C1625.7,598.7,1625.6,602.1,1627.8,602.2z"/>
              <path class="st4" d="M1632.9,596.1c2.2,0.1,2.3-3.4,0.1-3.4C1630.8,592.6,1630.7,596,1632.9,596.1z"/>
              <path class="st4" d="M1640.3,598.5c-2.2-0.1-2.3,3.4-0.1,3.4C1642.4,602,1642.5,598.5,1640.3,598.5z"/>
              <path class="st4" d="M1636.5,586.6c-2.2-0.1-2.3,3.4-0.1,3.4C1638.6,590.1,1638.7,586.7,1636.5,586.6z"/>
              <path class="st4" d="M1621.8,597.3c2.2,0.1,2.3-3.4,0.1-3.4C1619.7,593.8,1619.6,597.2,1621.8,597.3z"/>
              <path class="st4" d="M1640.7,594.9c0.3,0.9,1.1,1.5,2.1,1.3c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0,0.4-0.1,0.5-0.1
                c0.9-0.2,1.5-1.2,1.3-2.1c-0.3-0.9-1.1-1.5-2.1-1.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.7-1.3,1.3
                C1640.7,594.3,1640.6,594.6,1640.7,594.9z"/>
              <path class="st4" d="M1634.5,600.9c-0.6-0.7-1.8-0.7-2.4-0.1c-0.6,0.7-0.7,1.7-0.1,2.4c0.1,0.1,0.2,0.2,0.3,0.4
                c0.6,0.7,1.8,0.7,2.4,0.1c0.6-0.7,0.7-1.7,0.1-2.4C1634.7,601.1,1634.6,601,1634.5,600.9z"/>
              <path class="st4" d="M1623.8,604.8c-0.2-0.9-1.2-1.5-2.1-1.3c-0.9,0.3-1.5,1.1-1.3,2.1c0.1,0.4,0.2,0.8,0.3,1.2
                c0.2,0.9,1.2,1.5,2.1,1.3c0.9-0.3,1.5-1.1,1.3-2.1C1624,605.6,1623.9,605.2,1623.8,604.8z"/>
              <path class="st4" d="M1630.2,607.6c-0.7-0.1-1.4-0.2-2.1-0.2c-0.9-0.1-1.8,0.8-1.8,1.7c0,1,0.7,1.7,1.7,1.8
                c0.7,0.1,1.4,0.2,2.1,0.2c0.9,0.1,1.8-0.8,1.8-1.7C1631.9,608.4,1631.2,607.7,1630.2,607.6z"/>
              <path class="st4" d="M1639.4,611.6c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.8
                c-1,0-1.6,0.7-1.8,1.7c-0.1,1,0.1,2,0.7,2.8c0.5,0.7,1.5,1.2,2.3,0.7c0.7-0.4,1.2-1.5,0.7-2.3
                C1639.6,611.8,1639.5,611.7,1639.4,611.6z"/>
              <path class="st4" d="M1643.8,605.8c-2.2-0.1-2.3,3.4-0.1,3.4C1645.9,609.3,1646,605.8,1643.8,605.8z"/>
              <path class="st4" d="M1630.1,612.7c-2.2-0.1-2.3,3.4-0.1,3.4C1632.2,616.2,1632.3,612.7,1630.1,612.7z"/>
              <path class="st4" d="M1621,611.7c-2.2-0.1-2.3,3.4-0.1,3.4C1623.1,615.2,1623.2,611.7,1621,611.7z"/>
              <path class="st4" d="M1641.4,586.4c-0.1,0.9,0.4,1.9,1.6,1.9c2.2,0.1,2.3-3.4,0.1-3.4c-0.1,0-0.2,0-0.3,0
                C1641.9,585.1,1641.4,585.7,1641.4,586.4z"/>
              <path class="st4" d="M1617.1,586.2c-2.2-0.1-2.3,3.4-0.1,3.4C1619.2,589.7,1619.3,586.2,1617.1,586.2z"/>
              <path class="st4" d="M1614.3,603.9c-2.2-0.1-2.3,3.4-0.1,3.4C1616.3,607.4,1616.5,604,1614.3,603.9z"/>
              <path class="st4" d="M1651.3,599.7c-0.6,0-1,0.2-1.3,0.5c-0.6,0.7-0.5,1.9,0.2,2.5c0.3,0.2,0.6,0.4,1,0.4
                C1653.4,603.2,1653.5,599.8,1651.3,599.7z"/>
              <path class="st4" d="M1609.5,594.8c-2.2-0.1-2.3,3.4-0.1,3.4C1611.6,598.3,1611.7,594.9,1609.5,594.8z"/>
              <path class="st4" d="M1586.1,594.2c2.3,0.8,3.6-2.7,1.3-3.5C1585.1,589.9,1583.8,593.4,1586.1,594.2z"/>
              <path class="st4" d="M1594.8,586.1c-1.2-0.5-2.1,0.4-2.4,1.3c-0.1,0.5-0.1,1.1,0.2,1.5c0.2,0.3,0.5,0.5,0.9,0.7
                C1595.8,590.4,1597.1,586.9,1594.8,586.1z"/>
              <path class="st4" d="M1600.4,594.7c-2.3-0.8-3.6,2.7-1.3,3.5C1601.4,599.1,1602.7,595.5,1600.4,594.7z"/>
              <path class="st4" d="M1600.6,584.6h-1.4c0,0,0,0,0,0C1599.8,584.8,1600.2,584.7,1600.6,584.6z"/>
              <path class="st4" d="M1581.6,587c1.7,0.6,2.8-1.2,2.4-2.5h-3.4C1580.2,585.5,1580.4,586.6,1581.6,587z"/>
              <path class="st4" d="M1605.5,589.5c-0.3,0-0.5,0-0.8-0.1c-0.2,0-0.4,0-0.6,0c-1-0.1-2,0.7-2,1.7c0,1,0.7,2,1.7,2
                c0.5,0,0.9,0.1,1.4,0.1c0.9,0.1,1.8-0.6,2-1.5c0-0.1,0-0.1,0-0.2C1607.3,590.5,1606.6,589.6,1605.5,589.5z"/>
              <path class="st4" d="M1593.7,595.7c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-0.9c-0.9,0.5-1.4,1.5-0.9,2.5
                c0.1,0.2,0.2,0.3,0.2,0.5c0.3,0.7,1.1,1.2,1.9,1.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
                C1593.7,597.7,1594.2,596.6,1593.7,595.7z"/>
              <path class="st4" d="M1578.9,598.6c1,0,2-0.7,2-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2c-1,0-2,0.7-2,1.7
                c0,0.5-0.1,0.9-0.1,1.4C1577.1,597.6,1577.8,598.6,1578.9,598.6z"/>
              <path class="st4" d="M1586.8,600.7c-0.7-0.3-1.4-0.6-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
                c0.7,0.3,1.4,0.6,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1588.2,602.1,1587.7,601.1,1586.8,600.7z"/>
              <path class="st4" d="M1594,604.8c-1-0.3-2,0.2-2.4,1.1c-0.4,0.9-0.5,2-0.3,3c0,0,0,0.1,0,0.1c0.1,0.4,0.3,0.8,0.6,1.1
                c0.4,0.4,0.9,0.6,1.5,0.5c0.9-0.2,1.8-1.2,1.5-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
                c0.1-0.2,0.1-0.3,0.2-0.4C1595.6,606.3,1594.9,605.1,1594,604.8z"/>
              <path class="st4" d="M1601.5,603.5c-2.3-0.8-3.6,2.7-1.3,3.5C1602.4,607.8,1603.7,604.3,1601.5,603.5z"/>
              <path class="st4" d="M1584.8,605.9c-1.1-0.4-1.9,0.2-2.3,1c-0.3,0.8-0.2,1.8,0.6,2.3c0.1,0.1,0.2,0.1,0.4,0.2
                C1585.8,610.2,1587.1,606.7,1584.8,605.9z"/>
              <path class="st4" d="M1576.9,603c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.2-0.5-0.4-0.8-0.6c-1-0.4-1.8,0.1-2.2,0.8
                c-0.5,0.9-0.4,2.3,0.9,2.7c1.1,0.4,2-0.2,2.3-1.1C1576.7,603.7,1576.8,603.3,1576.9,603z"/>
              <path class="st4" d="M1608.7,584.6h-2.8c0.2,0.2,0.4,0.4,0.8,0.5C1607.5,585.4,1608.2,585.1,1608.7,584.6z"/>
              <path class="st4" d="M1569,593.3c0,0.6,0.4,1.3,1.2,1.6c2,0.7,3.2-1.9,2-3.1c-0.2-0.2-0.4-0.3-0.7-0.4
                C1570,590.8,1568.9,592.1,1569,593.3z"/>
              <path class="st4" d="M1611.4,599.8c-2.3-0.8-3.6,2.7-1.3,3.5C1612.4,604.2,1613.7,600.6,1611.4,599.8z"/>
              <path class="st4" d="M1670,904.3c-2.1-0.4-2.7,2.8-0.7,3.2C1671.4,908,1672,904.8,1670,904.3z"/>
              <path class="st4" d="M1668.6,917.6c2.1,0.4,2.7-2.8,0.7-3.2C1667.2,914,1666.5,917.2,1668.6,917.6z"/>
              <path class="st4" d="M1674.4,912.7c2.1,0.4,2.7-2.8,0.7-3.2C1673,909.1,1672.3,912.3,1674.4,912.7z"/>
              <path class="st4" d="M1681,916.2c-2.1-0.4-2.7,2.8-0.7,3.2C1682.4,919.8,1683,916.6,1681,916.2z"/>
              <path class="st4" d="M1679.3,904.4c-2.1-0.4-2.7,2.8-0.7,3.2C1680.7,908,1681.4,904.8,1679.3,904.4z"/>
              <path class="st4" d="M1664.4,908.8c-2.1-0.4-2.7,2.8-0.7,3.2C1665.8,912.4,1666.5,909.2,1664.4,908.8z"/>
              <path class="st4" d="M1683.5,911.1c-0.9,0.1-1.6,0.9-1.5,1.7c0.1,0.9,0.8,1.6,1.7,1.5c0.4,0,0.8-0.1,1.2-0.1
                c0.3,0,0.6-0.2,0.9-0.4v-2.6c-0.3-0.2-0.7-0.4-1.1-0.4C1684.3,911.1,1683.9,911.1,1683.5,911.1z"/>
              <path class="st4" d="M1675.3,917.9c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3
                c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.7,1.5,1,2.3,0.5C1675.6,919.6,1675.8,918.7,1675.3,917.9z"/>
              <path class="st4" d="M1662.6,917.9c-0.9,0.1-1.6,0.8-1.5,1.7c0,0.4,0.1,0.8,0.1,1.2c0.1,0.9,0.9,1.6,1.7,1.5
                c0.9-0.1,1.6-0.8,1.5-1.7c0-0.4-0.1-0.8-0.1-1.2C1664.3,918.6,1663.5,917.8,1662.6,917.9z"/>
              <path class="st4" d="M1677.9,899.4c0.5-0.7,0.6-1.7-0.1-2.3c-0.7-0.5-1.7-0.6-2.3,0.1c-0.2,0.3-0.5,0.6-0.7,0.9
                c-0.5,0.7-0.6,1.7,0.1,2.3c0.7,0.5,1.7,0.6,2.3-0.1C1677.4,900,1677.6,899.7,1677.9,899.4z"/>
              <path class="st4" d="M1670,923.2c-0.6-0.2-1.3-0.4-1.9-0.6c-0.8-0.2-1.8,0.4-1.9,1.3c-0.2,0.9,0.4,1.7,1.3,1.9
                c0.6,0.2,1.3,0.4,1.9,0.6c0.8,0.2,1.8-0.4,1.9-1.3C1671.4,924.2,1670.8,923.4,1670,923.2z"/>
              <path class="st4" d="M1678,928.4c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.3c0.3-0.8-0.5-1.8-1.3-1.9
                c-0.9-0.2-1.7,0.4-1.9,1.3c-0.3,0.9-0.2,1.9,0.2,2.8c0.3,0.8,1.2,1.4,2.1,1c0.8-0.3,1.4-1.2,1-2.1
                C1678.1,928.7,1678,928.5,1678,928.4z"/>
              <path class="st4" d="M1682.4,926.8c2.1,0.4,2.7-2.8,0.7-3.2C1681,923.2,1680.3,926.4,1682.4,926.8z"/>
              <path class="st4" d="M1669,927.9c-2.1-0.4-2.7,2.8-0.7,3.2C1670.4,931.5,1671,928.3,1669,927.9z"/>
              <path class="st4" d="M1660.6,925.4c-2.1-0.4-2.7,2.8-0.7,3.2C1662,929.1,1662.7,925.9,1660.6,925.4z"/>
              <path class="st4" d="M1685.1,907.1c0.2,0.1,0.5,0,0.7,0v-3.2C1683.7,903.4,1683,906.6,1685.1,907.1z"/>
              <path class="st4" d="M1660.5,904c2.1,0.4,2.7-2.8,0.7-3.2C1659.1,900.4,1658.4,903.6,1660.5,904z"/>
              <path class="st4" d="M1655.5,917c-2.1-0.4-2.7,2.8-0.7,3.2C1656.9,920.7,1657.6,917.5,1655.5,917z"/>
              <path class="st4" d="M1652.5,907.7c-2.1-0.4-2.7,2.8-0.7,3.2C1653.9,911.3,1654.6,908.1,1652.5,907.7z"/>
              <path class="st4" d="M1664.9,891.1c-2.1-0.4-2.7,2.8-0.7,3.2C1666.3,894.8,1666.9,891.6,1664.9,891.1z"/>
              <path class="st4" d="M873.9,587.4c1.7,0.3,2.5-1.7,1.7-2.8h-2.7C872.2,585.5,872.4,587.1,873.9,587.4z"/>
              <path class="st4" d="M873.3,598.2c2.2,0.4,2.9-3,0.6-3.4C871.8,594.3,871.1,597.8,873.3,598.2z"/>
              <path class="st4" d="M879.4,592.8c2.2,0.4,2.9-3,0.6-3.4C877.9,588.9,877.2,592.4,879.4,592.8z"/>
              <path class="st4" d="M886.6,596.4c-2.2-0.4-2.9,3-0.6,3.4C888.2,600.2,888.8,596.8,886.6,596.4z"/>
              <path class="st4" d="M883.9,587.3c1.7,0.3,2.5-1.6,1.8-2.7h-2.8C882.3,585.5,882.5,587,883.9,587.3z"/>
              <path class="st4" d="M868.7,588.9c-2.2-0.4-2.9,3-0.6,3.4C870.3,592.7,870.9,589.3,868.7,588.9z"/>
              <path class="st4" d="M890.4,590.8c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0.1-1.7,0.9-1.6,1.9c0.1,0.9,0.9,1.7,1.9,1.6
                c0.4,0,0.9-0.1,1.3-0.1c0.9-0.1,1.7-0.9,1.6-1.9C892.2,591.5,891.4,590.7,890.4,590.8z"/>
              <path class="st4" d="M880.6,598.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.8-1.7-1-2.4-0.5c-0.8,0.6-1,1.6-0.5,2.4
                c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.8,1.7,1,2.4,0.5C880.9,600.2,881.1,599.1,880.6,598.3z"/>
              <path class="st4" d="M867,598.6c-0.9,0.1-1.7,0.9-1.6,1.9c0,0.4,0.1,0.9,0.1,1.3c0.1,0.9,0.9,1.7,1.9,1.6
                c0.9-0.1,1.7-0.9,1.6-1.9c0-0.4-0.1-0.9-0.1-1.3C868.8,599.3,867.9,598.5,867,598.6z"/>
              <path class="st4" d="M875,604.1c-0.7-0.2-1.4-0.4-2.1-0.6c-0.9-0.2-1.9,0.5-2,1.4c-0.1,1,0.4,1.8,1.4,2
                c0.7,0.2,1.4,0.4,2.1,0.6c0.9,0.2,1.9-0.5,2-1.4C876.5,605.1,875.9,604.3,875,604.1z"/>
              <path class="st4" d="M883.6,609.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.4c0.3-0.9-0.5-1.9-1.4-2
                c-1-0.1-1.8,0.5-2,1.4c-0.3,0.9-0.2,2.1,0.2,2.9c0.4,0.8,1.3,1.4,2.2,1.1c0.8-0.3,1.5-1.4,1.1-2.2
                C883.8,609.8,883.7,609.6,883.6,609.5z"/>
              <path class="st4" d="M888.9,604.3c-2.2-0.4-2.9,3-0.6,3.4C890.5,608.1,891.1,604.7,888.9,604.3z"/>
              <path class="st4" d="M874,609.1c-2.2-0.4-2.9,3-0.6,3.4C875.6,613,876.2,609.5,874,609.1z"/>
              <path class="st4" d="M865,606.7c-2.2-0.4-2.9,3-0.6,3.4C866.6,610.6,867.2,607.1,865,606.7z"/>
              <path class="st4" d="M890.8,586.6c1.4,0.3,2.1-0.9,2-2h-3.3C889.3,585.4,889.7,586.4,890.8,586.6z"/>
              <path class="st4" d="M859.4,597.8c-2.2-0.4-2.9,3-0.6,3.4C861,601.7,861.6,598.2,859.4,597.8z"/>
              <path class="st4" d="M897.5,599.3c-2.2-0.4-2.9,3-0.6,3.4C899.1,603.2,899.7,599.8,897.5,599.3z"/>
              <path class="st4" d="M854.8,591.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0.1,0.8,0,1.1-0.2c-0.3-0.1-0.6-0.2-0.9-0.3
                c-1-0.6-1.3-1.6-1.1-2.5c-0.5,0.5-0.7,1.2-0.5,1.8C854.1,590.6,854.4,590.9,854.8,591.1z"/>
              <path class="st4" d="M825.8,595.1c0,0.8,0.3,1.5,1.1,2.1c0.1,0.1,0.3,0.1,0.4,0.2c1.2,0.5,2.4-0.1,2.9-1.1
                c0.6-1,0.6-2.4-0.7-3.3c-0.3-0.2-0.5-0.3-0.8-0.3C827.2,592.3,825.9,593.7,825.8,595.1z"/>
              <path class="st4" d="M837.6,593.2c2.1,1.3,4.2-1,3.6-2.9c-0.1-0.4-0.3-0.7-0.7-1c-0.1-0.1-0.2-0.2-0.3-0.3
                C837.5,587.4,834.9,591.5,837.6,593.2z"/>
              <path class="st4" d="M845.1,601.5c-0.6-0.3-1.1-0.4-1.6-0.4c-1.9,0.3-3.1,3.2-1,4.5c2.4,1.5,4.8-1.7,3.2-3.6
                C845.6,601.9,845.4,601.7,845.1,601.5z"/>
              <path class="st4" d="M846.3,588.3c2.5,1.5,4.9-1.9,3.1-3.7h-3.5C844.9,585.6,844.8,587.3,846.3,588.3z"/>
              <path class="st4" d="M823,586.8c1.9,1.2,3.7-0.5,3.7-2.2h-4.8C821.9,585.4,822.1,586.2,823,586.8z"/>
              <path class="st4" d="M853.4,600.7c0.6-0.3,1-0.8,1.2-1.5c0.2-0.7,0-1.4-0.3-2c-0.3-0.5-0.7-0.8-1.3-1
                c-0.6-0.2-1.2-0.3-1.8-0.5c-1.2-0.4-2.7,0.4-3,1.7c-0.3,1.3,0.3,2.7,1.7,3c0.6,0.2,1.2,0.3,1.8,0.5
                C852.2,601.1,852.8,601,853.4,600.7z"/>
              <path class="st4" d="M834.4,603.9c0.7-0.2,1.3-0.7,1.6-1.3c0.3-0.5,0.4-1.1,0.2-1.7c-0.1-0.2-0.1-0.5-0.2-0.7
                c0-0.2-0.1-0.4-0.2-0.5c-0.5-1-1.7-1.6-2.7-1.3c-1.3,0.4-2.1,1.6-1.8,2.9c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.8,0.9,1.5,1.7,1.8
                C833.6,604,834,604.1,834.4,603.9z"/>
              <path class="st4" d="M819.6,599.2c0.2-0.6,0.3-1.2,0.5-1.8c0.4-1.2-0.4-2.7-1.7-3c-1.3-0.3-2.7,0.3-3,1.7
                c-0.2,0.6-0.3,1.2-0.5,1.8c-0.4,1.2,0.4,2.7,1.7,3C817.8,601.2,819.2,600.5,819.6,599.2z"/>
              <path class="st4" d="M826.8,609c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.9-0.3-1.8-1.1-2.4c-0.8-0.6-1.6-1.2-2.4-1.8
                c-1-0.8-2.8-0.3-3.4,0.8c-0.7,1.2-0.3,2.6,0.8,3.4c0.8,0.6,1.6,1.2,2.4,1.8c0.5,0.4,1.1,0.4,1.7,0.3
                C825.8,610,826.5,609.6,826.8,609z"/>
              <path class="st4" d="M834.3,612.8c-1.2-0.7-2.5-0.3-3.4,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.6-0.6,1.2-0.8,1.8
                c-0.1,0.6-0.2,1.1-0.2,1.7c0.1,1.3,1,2.5,2.4,2.5c1.2,0,2.6-1,2.5-2.4c0-0.1,0-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.3
                c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4C835.8,615.1,835.4,613.4,834.3,612.8z M834.6,617.2
                c0-0.1,0-0.2,0-0.2C834.6,617,834.6,617.1,834.6,617.2z M834.7,616.7c0,0,0-0.1,0.1-0.2C834.7,616.6,834.7,616.6,834.7,616.7z
                "/>
              <path class="st4" d="M844.8,613.6c-0.2-0.2-0.4-0.5-0.7-0.6c-2.7-1.7-5.3,2.5-2.6,4.2c1.1,0.7,2.1,0.4,2.8-0.2
                C845.2,616.1,845.6,614.7,844.8,613.6z"/>
              <path class="st4" d="M822.2,611.7c-1.5-0.9-3,0-3.5,1.2c-0.4,1-0.2,2.2,0.9,2.9c0.8,0.5,1.5,0.5,2.2,0.2
                C823.4,615.3,824.1,612.9,822.2,611.7z"/>
              <path class="st4" d="M811.7,604c-0.4-0.2-0.7-0.3-1.1-0.4c-2.2-0.2-3.8,3.1-1.5,4.5c1.7,1.1,3.4-0.3,3.7-1.8
                C813,605.5,812.7,604.6,811.7,604z"/>
              <path class="st4" d="M855.6,590.8c0.3,0.2,0.6,0.3,0.9,0.3c2.3,0.4,4.1-3,1.7-4.5c-1.7-1-3.3,0.2-3.6,1.6
                C854.3,589.2,854.6,590.2,855.6,590.8z"/>
              <path class="st4" d="M806.3,593.7c2.7,1.7,5.3-2.5,2.6-4.2C806.2,587.9,803.6,592.1,806.3,593.7z"/>
              <path class="st4" d="M857.8,610.9c-0.7-0.4-1.4-0.5-2-0.3c-1.7,0.6-2.6,3.2-0.6,4.4c2.2,1.4,4.3-1.2,3.6-3
                C858.6,611.6,858.3,611.2,857.8,610.9z"/>
              <path class="st4" d="M721.1,590.5c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0.1,2.3-3.1,0.2-3.2c-0.6,0-1,0.2-1.3,0.6
                C720.3,588.8,720.3,590,721.1,590.5z"/>
              <path class="st4" d="M726.9,585.2c0.6,0,1.1-0.2,1.3-0.6h-2.5C726,584.9,726.4,585.1,726.9,585.2z"/>
              <path class="st4" d="M733.7,590.9c2.1,0.1,2.3-3.1,0.2-3.2C731.8,587.5,731.6,590.7,733.7,590.9z"/>
              <path class="st4" d="M716.4,586c1,0.1,1.6-0.7,1.6-1.5H715C715,585.3,715.5,586,716.4,586z"/>
              <path class="st4" d="M736.2,585.5c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1-0.7h-4C734.7,585.2,735.5,585.7,736.2,585.5z"/>
              <path class="st4" d="M726.1,592.3c0.6,0.6,1.7,0.7,2.3,0.1c0.6-0.6,0.7-1.6,0.1-2.3c-0.1-0.1-0.2-0.2-0.3-0.3
                c-0.6-0.6-1.7-0.7-2.3-0.1c-0.6,0.6-0.7,1.6-0.1,2.3C725.9,592,726,592.1,726.1,592.3z"/>
              <path class="st4" d="M715.1,595c0.2,0.8,1.1,1.5,1.9,1.2c0.6-0.2,1-0.6,1.2-1.1c0.1-0.3,0.1-0.5,0-0.8
                c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.8-1.1-1.5-1.9-1.2c-0.8,0.2-1.4,1-1.2,1.9C715,594.2,715,594.6,715.1,595z"/>
              <path class="st4" d="M725.6,597.5c-0.1-0.8-0.7-1.4-1.5-1.5c-0.7-0.1-1.3-0.2-2-0.3c-0.8-0.1-1.7,0.7-1.7,1.5
                c0,0.9,0.6,1.6,1.5,1.7c0.7,0.1,1.3,0.2,2,0.3c0.4,0.1,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.7,0.6-1.1
                C725.6,597.7,725.6,597.6,725.6,597.5z"/>
              <path class="st4" d="M732.6,599.3c0.1-0.8-0.7-1.7-1.5-1.7c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.3-0.7,0.7-0.8,1.3
                c-0.1,0.9,0.1,1.9,0.6,2.7c0.5,0.7,1.4,1.2,2.2,0.7c0.7-0.4,1.2-1.4,0.7-2.2c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.1-0.1-0.2
                c0-0.1,0-0.1,0-0.2C732.6,599.5,732.6,599.3,732.6,599.3z"/>
              <path class="st4" d="M736.7,597.8c0.6,0,1.1-0.2,1.4-0.7c0.6-0.9,0.3-2.5-1.2-2.6c-0.3,0-0.5,0-0.7,0.1
                c-0.9,0.4-1.2,1.6-0.7,2.4C735.7,597.5,736.1,597.8,736.7,597.8z"/>
              <path class="st4" d="M723.6,604c2.1,0.1,2.3-3.1,0.2-3.2C721.7,600.6,721.5,603.9,723.6,604z"/>
              <path class="st4" d="M715.1,602.8c0.8,0,1.3-0.4,1.5-0.9c0.3-0.7,0.2-1.5-0.4-2c-0.2-0.2-0.5-0.3-0.9-0.4
                C713.2,599.5,713,602.7,715.1,602.8z"/>
              <path class="st4" d="M708.9,595.3c0.1,0,0.2,0,0.4,0c1.7-0.2,1.8-3.1-0.2-3.2c-1.3-0.1-1.8,1.1-1.6,2
                C707.6,594.8,708.1,595.3,708.9,595.3z"/>
              <path class="st4" d="M743.7,592.3c0.1,0,0.2,0,0.3,0c1.8,0.1,2.2-2.2,1-3c-0.2-0.1-0.5-0.2-0.8-0.3
                C742.2,589,741.9,591.9,743.7,592.3z"/>
              <path class="st4" d="M703.3,585.8c0.2,0.5,0.6,0.8,1.3,0.9c1.3,0.1,1.8-1.1,1.6-2.1h-3C703.1,585,703.1,585.4,703.3,585.8z"/>
              <path class="st4" d="M1547.2,587.8c2.3,1.3,4.5-1.3,3.7-3.2h-4.7C1545.7,585.7,1545.9,587,1547.2,587.8z"/>
              <path class="st4" d="M1566.8,591.8c-2.1-1.2-4.1,0.9-3.8,2.8c0.1,0.7,0.5,1.3,1.2,1.7c0.2,0.1,0.4,0.2,0.6,0.3
                C1567.4,597.4,1569.5,593.4,1566.8,591.8z"/>
              <path class="st4" d="M1571.7,590.5c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.6,0.2,1,0.2c1.3,0.3,2.9-0.5,3.2-1.9
                c0.3-1.4-0.5-2.8-1.9-3.2c-0.6-0.2-1.3-0.3-1.9-0.5c-1.3-0.3-2.9,0.5-3.2,1.9c0,0.2-0.1,0.3-0.1,0.5
                C1569.7,589,1570.5,590.1,1571.7,590.5z"/>
              <path class="st4" d="M1557.1,590.8c-0.4-1.3-1.9-2.3-3.2-1.8c-1.3,0.4-2.2,1.8-1.8,3.2c0.1,0.2,0.1,0.5,0.2,0.7
                c0.4,1.3,1.9,2.3,3.2,1.8c1.3-0.4,2.2-1.8,1.8-3.2C1557.3,591.3,1557.2,591.1,1557.1,590.8z"/>
              <path class="st4" d="M1539.9,588.4c0.3-1.3-0.5-2.9-1.9-3.2c-1.4-0.3-2.8,0.5-3.2,1.9c-0.2,0.6-0.3,1.3-0.5,1.9
                c-0.3,1.3,0.5,2.9,1.9,3.2c1.4,0.3,2.8-0.5,3.2-1.9C1539.6,589.7,1539.8,589,1539.9,588.4z"/>
              <path class="st4" d="M1546.6,596.9c-0.9-0.6-1.8-1.2-2.6-1.8c-1.1-0.8-3-0.2-3.6,1c-0.7,1.3-0.3,2.7,1,3.6
                c0.9,0.6,1.8,1.2,2.6,1.8c1.1,0.8,3,0.2,3.6-1C1548.3,599.1,1547.8,597.7,1546.6,596.9z"/>
              <path class="st4" d="M1555.6,604.2c-1.4-0.7-2.7-0.2-3.6,1c-0.8,1.2-1.3,2.8-1.1,4.3c0.1,1.4,1.1,2.7,2.6,2.6
                c1.3-0.1,2.7-1.2,2.6-2.6c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
                c0.1-0.2,0.2-0.3,0.3-0.4C1557.4,606.6,1556.8,604.8,1555.6,604.2z M1556.3,608.2c0,0,0,0.1-0.1,0.2
                C1556.2,608.4,1556.2,608.3,1556.3,608.2z M1556.1,608.6c0,0.1,0,0.2,0,0.3C1556.1,608.7,1556.1,608.6,1556.1,608.6z"/>
              <path class="st4" d="M1566.1,604.1c-2.9-1.7-5.5,2.8-2.6,4.5C1566.4,610.3,1569.1,605.8,1566.1,604.1z"/>
              <path class="st4" d="M1542.8,603.5c-2.9-1.7-5.5,2.8-2.6,4.5C1543.1,609.7,1545.7,605.2,1542.8,603.5z"/>
              <path class="st4" d="M1531.3,595.7c-2.9-1.7-5.5,2.8-2.6,4.5C1531.6,601.9,1534.2,597.4,1531.3,595.7z"/>
              <path class="st4" d="M1528.2,584.6h-3.5c0.1,0.1,0.3,0.3,0.5,0.4C1526.3,585.6,1527.4,585.3,1528.2,584.6z"/>
              <path class="st4" d="M1580.6,601.4c-1.7-1-3.3,0.2-3.7,1.6c-0.1,0.4-0.2,0.8-0.1,1.2c0.1,0.7,0.5,1.3,1.2,1.8
                C1580.9,607.6,1583.5,603,1580.6,601.4z"/>
            </g>
          </g>
          <g>
            <path class="st3" d="M1685.8,665.6c-1.9,0-3.5-1.6-3.5-3.5v-59.6c0-8-6.5-14.5-14.5-14.5h-551.5c-1.9,0-3.5-1.6-3.5-3.5
              s1.6-3.5,3.5-3.5h551.5c11.9,0,21.5,9.6,21.5,21.5v59.6C1689.3,664.1,1687.7,665.6,1685.8,665.6z"/>
          </g>
          <g>
            <path class="st4" d="M1636.1,650.9v316.6c0,9.2-7.4,16.6-16.6,16.6H569.3c-12.2,0-20.2-12.8-14.9-23.8l110.1-228.7l42.3-87.9
              c2.8-5.7,8.6-9.4,14.9-9.4h897.8C1628.7,634.3,1636.1,641.7,1636.1,650.9z"/>
            <path class="st3" d="M1636.1,829.4v4.9c-28.5,7.7-57.1,15.4-85.6,23.1c-43.3,11.7-125.2,34.8-169.8,21.2
              c-19.6-6-38.1-15.4-58.3-19.8c-18.5-4-37.3-2.1-53.9,7.3c-30.4,17.3-48.7,52.8-51,87c-0.7,10.4-0.3,20.7,0.9,30.9h-5
              c-1.6-13.6-1.7-27.4,0.4-41.2c5.1-33.5,25.2-67.5,56.2-82.9c15.8-7.9,33.2-9.6,50.5-6.3c21.3,4,40.8,14.1,61.4,20.4
              c47.1,14.4,132.6-11.9,178.2-24.1C1585.4,843.1,1610.8,836.2,1636.1,829.4z"/>
            <path class="st3" d="M1636.1,710v4.8c-41.7-0.9-84.1-8.2-125,2.6c-21,5.6-38.3,17-56,29c-15.5,10.5-31.9,21.3-50.9,23.6
              c-21.2,2.6-40.4-7.5-56.8-19.9c-18.5-14-35.8-30-52.1-46.5c-15.4-15.7-31.8-32.2-43.1-51.2c-3.4-5.6-6.3-11.8-8.3-18.2h4.8
              c3.7,11.2,11,21.9,17.7,30.2c15.7,19.4,33.2,37.5,51.5,54.5c15.9,14.6,32.6,30.4,52.1,40c18.9,9.3,38.6,8.1,57.2-1.1
              c17.1-8.5,31.9-20.8,48.2-30.5c17.3-10.3,36.4-16.2,56.3-18.7C1566.5,704.3,1601.3,709.3,1636.1,710z"/>
            <path class="st3" d="M1452.8,932.5c4.1,5.5,11.2,8.2,17.6,10c8.2,2.4,16.6,4.1,25,5.8c46.2,9.3,93.7,12,140.7,8.2v4.8
              c-16.4,1.3-32.8,1.8-49.3,1.6c-38.5-0.6-77.3-5.4-114.6-15c-11.1-2.9-26-8.6-26.8-22.1c-0.8-13.2,12.9-21,23.8-24.4
              c16.2-5.2,34-7,50.9-8.3c18-1.4,36.2-1.8,54.3-2.4c20.6-0.7,41.4-1.7,61.7-5.3v4.8c-20,3.5-40.6,4.6-60.9,5.3
              c-33.7,1.1-68.2,0.9-101.1,9.5C1463.2,907.8,1442.1,918.3,1452.8,932.5z"/>
            <path class="st3" d="M1218,829.9c-12.1,16.9-37.2,22.7-56.6,24.9c-22.4,2.5-45,0.2-67.4-1.3c-22-1.5-44.1-1.8-66.1-0.7
              c-22.5,1.1-44.6,4.2-66.9,7.5c-10.7,1.6-22.6,2.4-31.9-4.1c-3.4-2.3-6.3-5.4-6.4-9.7c-0.2-5.6,4-8.4,8.3-11.1
              c17.9-11.3,38.4-22.1,60.2-20.2c19.4,1.7,37.2,10.8,56.2,14.3c20.5,3.7,40.7-1.1,59.8-8.2c23-8.6,45.3-20.5,70-23.4
              c10.9-1.3,23.2-1,33.3,3.9c11.7,5.7,14.1,17.9,7.3,28.6c-1.6,2.6,2.7,4.6,4.3,2c4.9-7.7,6.4-17.7,1.4-25.7
              c-4.4-7-12.7-10.7-20.5-12.5c-22.7-5.2-46.3,2.2-67.3,10.5c-22.6,9-45.3,20.5-70.1,21.4c-22.9,0.9-43.4-10.4-65.4-14.5
              c-22.3-4.1-42.9,3.1-62.2,14.1c-7.1,4.1-17.4,8.3-19.5,17.2c-1.9,8,3.2,14.5,9.7,18.4c9,5.3,19.7,5.6,29.8,4.3
              c12.6-1.5,25.2-4,37.8-5.4c25.1-2.8,50.5-3.9,75.7-3.1c28.1,0.8,56.3,5.2,84.5,3c12.6-1,25.2-3.3,37.1-7.7
              c10.8-4.1,21.9-9.8,28.7-19.4C1223.4,830.5,1219.8,827.4,1218,829.9z"/>
            <path class="st3" d="M1125.6,708.5c-5.3,5.7-13,9.1-19.9,12.5c-7.9,3.9-16.1,7.3-24.4,10.4c-17,6.2-34.7,10.7-52.6,13.3
              c-75.2,10.9-147.1-15.4-218.5-35.2c-40.7-11.2-82.2-19.1-124-23.3l2.2-4.5c46.2,4.9,91.9,14.2,136.8,27.1
              c69.5,19.9,140.2,43.3,213.3,29.5c17.5-3.3,34.7-8.4,51.2-15.2c8.1-3.3,16.2-7.1,23.8-11.4c7-4,13.9-9.7,12.6-18.6
              c-2.7-18.7-24.5-21.3-39.5-19.6c-17.3,2-34.2,5-51.7,5.8c-67.8,2.9-134.5-12.6-200.5-25.8c-39.5-7.9-79.6-15.3-119.9-17.5
              c2.2-1,4.6-1.6,7.1-1.6h29.4c37.9,4.6,75.5,12.6,112.7,20.1c69.7,14.1,140.7,27.2,211.8,15.8c15.3-2.5,33-5.2,45.8,5.7
              C1131,684.2,1134.9,698.4,1125.6,708.5z"/>
            <path class="st3" d="M1114.1,984h-14.5c-7.2-2.2-14.3-4.3-21.3-6.4c-49.1-15.2-98.9-29.7-150.2-34.9
              c-48.8-5-97.9,0.4-146.7-4.4c-24.2-2.4-47.4-7.7-70-16.7c-23-9.2-45.1-19-69.7-22.9c-18.8-3-37.6-3.5-56.5-2.4l2.3-4.9
              c23.8-1.2,47.5,0.1,70.9,5.7c24.6,6,46.8,18,70.8,25.9c24.2,7.9,49.6,11,75,12c25.3,1.1,50.7,0.2,76,0.5
              c25.4,0.2,50.6,2,75.7,6c51,8.2,101.1,23.6,150.1,39.6C1108.6,982.1,1111.3,983,1114.1,984z"/>
          </g>
        </g>
        <g>
          <circle class="st3" cx="1789.1" cy="1107.1" r="96"/>
          <circle class="st2" cx="1789.1" cy="1107.1" r="31.3"/>
          <g>
            <path class="st2" d="M1771.2,1178.4c-0.3,0-0.6,0-0.9-0.1c-30.4-5.8-50.7-28.1-51.8-56.9c-0.1-2.6,1.9-4.7,4.5-4.8
              c2.5-0.1,4.7,1.9,4.8,4.5c0.9,24.3,18.2,43.2,44.2,48.1c2.5,0.5,4.2,2.9,3.7,5.4C1775.3,1176.8,1773.4,1178.4,1771.2,1178.4z"
              />
          </g>
          <circle class="st3" cx="1789.1" cy="1107.1" r="12.7"/>
        </g>
        <g>
          <circle class="st3" cx="728.2" cy="1107.1" r="96"/>
          <circle class="st2" cx="728.2" cy="1107.1" r="31.3"/>
          <circle class="st3" cx="728.2" cy="1107.1" r="12.7"/>
          <g>
            <path class="st2" d="M661.5,1094.1c-0.3,0-0.6,0-0.9-0.1c-2.5-0.5-4.2-2.9-3.7-5.4c5.6-30.4,27.9-50.8,56.7-52
              c2.6-0.1,4.7,1.9,4.8,4.4s-1.9,4.7-4.4,4.8c-24.3,1-43.1,18.4-47.9,44.4C665.6,1092.5,663.7,1094.1,661.5,1094.1z"/>
          </g>
        </g>
        <g>
          <circle class="st3" cx="993.3" cy="1107.1" r="96"/>
          <circle class="st2" cx="993.3" cy="1107.1" r="31.3"/>
          <g>
            <path class="st2" d="M1045.8,1154c-1.3,0-2.6-0.5-3.5-1.6c-1.7-1.9-1.5-4.9,0.4-6.5c18.3-16,22-41.4,9.5-64.6
              c-1.2-2.3-0.4-5.1,1.9-6.3c2.3-1.2,5.1-0.4,6.3,1.9c14.7,27.2,10.2,57.1-11.5,76C1048,1153.7,1046.9,1154,1045.8,1154z"/>
          </g>
          <circle class="st3" cx="993.3" cy="1107.1" r="12.7"/>
        </g>
      </g>
      <g>
        <path class="st3" d="M2106.1,1209.5H443.9c-3.9,0-7-3.1-7-7s3.1-7,7-7h1662.2c3.9,0,7,3.1,7,7S2110,1209.5,2106.1,1209.5z"/>
      </g>
    </g>
    <g>
      <path class="st8" :fill="`${color}`" :d="`M${Number(multiplier) + (981.5 - 250)},650.9v316.6c0,9.2-7.4,16.6-16.6,16.6H569.3c-12.2,0-20.2-12.8-14.9-23.8l110.1-228.7l42.3-87.9
        c2.8-5.7,8.6-9.4,14.9-9.4H${Number(multiplier) + (965 - 250)}C${Number(multiplier) + (974.1 - 250)},634.3,${Number(multiplier) + (981.5 - 250)},641.7,${Number(multiplier) + (981.5 - 250)},650.9z`"/>
      <path class="st6" d="M569.3,988c-7.1,0-13.6-3.6-17.4-9.6c-3.8-6-4.2-13.5-1.1-19.9l152.3-316.6c3.4-7.1,10.7-11.6,18.5-11.6
        c2.2,0,4,1.8,4,4s-1.8,4-4,4c-4.8,0-9.2,2.8-11.3,7.1L558,962c-1.9,3.9-1.6,8.5,0.7,12.1c2.3,3.7,6.3,5.9,10.6,5.9
        c2.2,0,4,1.8,4,4S571.5,988,569.3,988z"/>
      <path class="st6" :d="`M${Number(multiplier) + (965 - 250)},988H569.3c-2.2,0-2.2-8,0-8H${Number(multiplier) + (965 - 250)}C${Number(multiplier) + (967.2 - 250)},980,${Number(multiplier) + (967.2 - 250)},988,${Number(multiplier) + (965 - 250)},988z`"/>
      <path :transform="`translate(${multiplier - 250})`" class="st6" d="M965,988c-2.2,0-4-1.8-4-4s1.8-4,4-4c6.9,0,12.6-5.6,12.6-12.6V650.9c0-6.9-5.6-12.6-12.6-12.6
        c-2.2,0-4-1.8-4-4s1.8-4,4-4c11.3,0,20.6,9.2,20.6,20.6v316.6C985.5,978.8,976.3,988,965,988z"/>
      <path class="st6" :d="`M${Number(multiplier) + (965 - 250)},638.3H721.7c-2.2,0-2.2-8,0-8H${Number(multiplier) + (965 - 250)}C${Number(multiplier) + (967.2 - 250)},630.3,${Number(multiplier) + (967.2 - 250)},638.3,${Number(multiplier) + (965 - 250)},638.3z`"/>
    </g>
    <g :transform="`translate(${multiplier - 250})`">
      <path class="st7" d="M941.1,806.8l-111.9-72.4c-4.6-3-10.6,0.3-10.6,5.8v36.1H606.8c-3.8,0-6.9,3.1-6.9,6.9V842
        c0,3.8,3.1,6.9,6.9,6.9h211.8V885c0,5.4,6,8.7,10.6,5.8l111.9-72.4C945.3,815.6,945.3,809.5,941.1,806.8z"/>
      <g>
        <path class="st3" d="M818.6,827.8H606.8c-3.8,0-6.9-3.1-6.9-6.9v21c0,3.8,3.1,6.9,6.9,6.9h211.8V827.8z"/>
        <path class="st3" d="M941.1,806.8l-7.3-4.8l-104.6,67.7c-4.6,3-10.6-0.3-10.6-5.8v21c0,5.4,6,8.7,10.6,5.8l111.9-72.4
          C945.3,815.6,945.3,809.5,941.1,806.8z"/>
      </g>
      <path class="st6" d="M928.1,805.3c-1.3,0-2.6-0.4-3.7-1.1l-98.9-64v36.1c0,3.8-3.1,6.9-6.9,6.9H607.4c-3.8,0-6.9-3.1-6.9-6.9
        s3.1-6.9,6.9-6.9h204.4v-29.3c0-5,2.7-9.7,7.2-12.1c4.4-2.4,9.8-2.2,14,0.5l98.9,64c3.2,2.1,4.1,6.3,2,9.5
        C932.6,804.2,930.4,805.3,928.1,805.3z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  props: {
    loadlevel: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    multiplier() {
      return this.loadlevel * 9;
    },
    color() {
      let color = '#FC3232';

      if (this.loadlevel < 60) {
        color = '#279193'
      } else if (this.loadlevel < 80) {
        color = '#FFC655'
      }

      return color;
    }
  }
}
</script>

<style lang="scss" scoped>
.st0{fill:#FFE4DE;}
.st1{fill:#FFEEEB;}
.st2{fill:#265987;}
.st3{fill:#173D60;}
.st4{fill:#F99178;}
.st5{fill:#D65C5C;}
.st6{fill:#FFFFFF;}
.st7{fill:#58DEF4;}
.st8{opacity:0.8;}
.st9{opacity:0.8;fill:#FFC655;}
.st10{opacity:0.8;fill:#FC3232;}
</style>