<!-- Поломка -->
<template>
  <div class="kgo-remaining-card">
    <img
      @click="toggle_img = !toggle_img"
      class="kgo-remaining-card-download"
      src="@/assets/images/events/download_photo.svg"
      alt=""
    />
    <div class="kgo-remaining-card-car">Авто {{ item?.vehicle.number }}</div>
    <div class="kgo-remaining-card-platform">КП {{ item?.original_id }}</div>
    <div class="kgo-remaining-card-volume">Объём КГО (м3) {{ item?.kgo_remaining_volume }}</div>
    <div class="kgo-remaining-card-area">
      <img src="@/assets/images/events/pin.svg" alt="" />
      {{ item?.address }}
    </div>
    <div class="kgo-remaining-card-images" v-if="toggle_img">
      <div
        class="kgo-remaining-card-images-box"
        v-for="(item_media, index) in item.media"
        :key="index"
        @click="loupe_image = item_media != 'not_ready'? item_media: null"
      >
        <template v-if="item_media != 'not_ready'">
          <img :src="host(item_media)" alt="" />
          <div class="kgo-remaining-card-images-box-loupe">
            <img src="@/assets/images/events/loupe.svg" alt="" />
          </div>
        </template>
        <template v-else>
          Нет фото
        </template>
      </div>
    </div>
    <images_modal
      :images="[loupe_image]"
      v-if="loupe_image"
      @close="loupe_image = null"
    />
  </div>
</template>

<script>
import images_modal from "@/components/modals/images_modal.vue";

export default {
  components: {
    images_modal,
  },
  props: {
    item: {
      required: true,
      // type: () => {},
    },
  },
  data() {
    return {
      toggle_img: false,
      loupe_image: null,
    };
  },
  methods: {
    host(link) {
      return `${
        process.env.VUE_APP_WN
      }/file/${link}?api_token=${localStorage.getItem("auth_token")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.kgo-remaining-card {
  margin-bottom: 15px;
  padding: 21px 15px 21px 13px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 10px;

  &-download {
    position: absolute;
    top: 21px;
    right: 15px;
  }

  &-car {
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  &-platform {
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 25px;
  }

  &-volume {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 25px;
  }
}

.kgo-remaining-card-images {
  overflow-x: auto;
  display: flex;
  column-gap: 15px;

  &-box {
    position: relative;
    flex: 0 0 131px;
    border-radius: 7px;
    overflow: hidden;

    &-loupe {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.kgo-remaining-card-area {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;

  & img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>