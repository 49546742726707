import uiBoxCard from '@/components/primitives/ui-box-card'
import blockStatistics from '@/pages/work_app/home/statistics'
import blockFilling_sensor_stats from '@/components/sensors/filling_sensor_stats'
import vehiclesSensor from '@/components/vehicles_sensor/vehicles_sensor'
import defaultLayout from '@/layouts/default-layout'
import authLayout from '@/layouts/auth-layout'
import preloaderPage from '@/components/preloader/preloader_page'
import preloaderLogo from '@/components/icons_color/preloader_logo'

const components = [
  {
    name: 'ui-box-card',
    component: uiBoxCard,
  },
  {
    name: 'block-statistics',
    component: blockStatistics,
  },
  {
    name: 'block-filling_sensor_stats',
    component: blockFilling_sensor_stats,
  },
  {
    name: 'component-vehicles-sensor',
    component: vehiclesSensor,
  },
  {
    name: 'preloader-page',
    component: preloaderPage,
  },
  {
    name: 'preloader-logo',
    component: preloaderLogo,
  },
  {
    name: 'default-layout',
    component: defaultLayout,
  },
  {
    name: 'auth-layout',
    component: authLayout,
  },

]

export default components;