<!-- Невывозы -->
<template>
  <div class="failure-card">
    <img
      @click="toggle_img = !toggle_img"
      class="failure-card-download"
      src="@/assets/images/events/download_photo.svg"
      alt=""
    />
    <div class="failure-card-car">Авто {{ item?.vehicle.number }}</div>
    <div class="failure-card-platform">КП {{ item?.platform_id }}</div>
    <div class="failure-card-area">
      <img src="@/assets/images/events/pin.svg" alt="" />
      {{ item?.address }}
    </div>
    <div class="failure-card-images" v-if="toggle_img">
      <div
        class="failure-card-images-box"
        v-for="(item_media, index) in item.media"
        :key="index"
        @click="
          loupe_image = item_media != 'not_ready' ? item_media : null
        "
      >
        <template v-if="item_media != 'not_ready'">
          <img :src="host(item_media)" alt="" />
          <div class="failure-card-images-box-loupe">
            <img src="@/assets/images/events/loupe.svg" alt="" />
          </div>
        </template>
        <template v-else> Нет фото </template>
      </div>
      <!-- скорее всего в будущем нужно будет как-то подписать что это фото по контейнерам -->
      <div v-for="(cs_item, index) in item.cs" :key="index" style="display: flex; column-gap: 15px;">
        <div
          class="failure-card-images-box"
          v-for="(cs_item_media, index) in cs_item.media"
          :key="index"
          @click="
            loupe_image = cs_item_media != 'not_ready' ? cs_item_media : null
          "
        >
          <template v-if="cs_item_media != 'not_ready'">
            <img :src="host(cs_item_media)" alt="" />
            <div class="failure-card-images-box-loupe">
              <img src="@/assets/images/events/loupe.svg" alt="" />
            </div>
          </template>
          <template v-else> Нет фото </template>
        </div>
      </div>      
    </div>
    <images_modal
      :images="[loupe_image]"
      v-if="loupe_image"
      @close="loupe_image = null"
    />
  </div>
</template>

<script>
import images_modal from "@/components/modals/images_modal.vue";

export default {
  components: {
    images_modal,
  },
  props: {
    item: {
      required: true,
      // type: () => {},
    },
  },
  data() {
    return {
      toggle_img: false,
      loupe_image: null,
    };
  },
  methods: {
    host(link) {
      return `${
        process.env.VUE_APP_WN
      }/file/${link}?api_token=${localStorage.getItem("auth_token")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.failure-card {
  margin-bottom: 15px;
  padding: 21px 15px 21px 13px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 10px;

  &-download {
    position: absolute;
    top: 21px;
    right: 15px;
  }

  &-car {
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  &-platform {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 25px;
  }
}

.failure-card-images {
  overflow-x: auto;
  display: flex;
  column-gap: 15px;

  &-box {
    position: relative;
    flex: 0 0 131px;
    overflow: hidden;
    border-radius: 7px;

    &-loupe {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.failure-card-area {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;

  & img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>