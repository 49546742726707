<template>
  <div class="ui-box">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.ui-box {
  padding: 10px;
  // box-shadow: 0 0 11px 1px $blue-shadow;
  border-radius: 10px;
}
</style>