<script setup >
import wasteTypeIcon from "@/components/helpers/icons/waste_type.vue";
import car_0 from "@/components/helpers/icons/car_0.vue";
import car_1 from "@/components/helpers/icons/car_1.vue";
import fullness_vehicle_icon from "@/components/helpers/icons/fullness_vehicle_icon.vue";
import chart from "@/components/helpers/charts/chart.vue";
</script>

<template>
  <ui-box-card>
    <div class="content">
      <p class="mb-1">
        <strong>{{ item.number }}</strong>
        <span class="is-size-7">({{ item.name }})</span>
      </p>
      <p>
        Кол-во рейсов за сегодня: <strong>{{ item.trip_info?.count }}</strong>
        Средняя заполненность за сутки: <strong>{{ item.trip_info?.workload_average }}</strong>
        Max заполненность за сутки: <strong>{{ item.trip_info?.workload_max }}</strong>
      </p>
      <div
        class="is-flex is-justify-content-space-between"
        @click="detail_chart"
      >
        <div class="is-flex is-align-items-center" v-if="item.load_level">
          <span style="display: inline-block">
            {{ item.load_level ? `${item.load_level}%` : "--" }}
          </span>
          <span style="display: inline-block; width: 115px" v-if="item.calibrated">
            <car_0 style="width: 100%" v-if="item.load_level === 0" />
            <car_1 style="width: 100%" v-else-if="item.load_level < 7" />
            <fullness_vehicle_icon
              style="width: 100%"
              v-else
              :loadlevel="item.load_level"
            />
          </span>
          <div v-if="!item.calibrated">
            <p>Требуется калибровка</p>
            <span style="display: inline-block; width: 115px" >
              <img src="@/assets/images/icon_vehicle/icon_9.svg" alt="">
            </span>
          </div>
        </div>
        <div v-else>Нет данных по заполненности</div>
        <div class="is-flex is-align-items-center">
          <div v-for="(waste_type, index) of item.waste_types" :key="index">
            <wasteTypeIcon
              :color="waste_type.color?.hex ?? `fff`"
              style="width: 30px; height: 29px"
              :style="{
                backgroundColor:
                  waste_type.color?.hex === 'fff' ||
                  waste_type.color?.hex === 'ffffff'
                    ? 'antiquewhite'
                    : 'transparent',
              }"
            />
            <small> Тип: {{ waste_type.color?.name ?? `--` }} </small>
          </div>
        </div>
      </div>
      <div v-if="toggle_chart">
        <chart
          :items="chart_sensor.map(item => {item.ts = item.ts.replace(/ /g,'T'); return item})"
          :width="270"
          :height="170"
          :hidden_date_bottom="false"
          style="margin-left: -1rem"
          v-if="chart_sensor"
        ></chart>
        <p v-else-if="load_chart_sensor == 'load'">Загрузка...</p>
        <p v-else>Нет данных по графику за текущий день</p>
      </div>
    </div>
  </ui-box-card>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      toggle_chart: false,
      chart_sensor: null,
      load_chart_sensor: "",
    };
  },
  methods: {
    detail_chart() {
      this.toggle_chart = !this.toggle_chart;
      this.load_chart_sensor = "load";

      if (this.toggle_chart) {
        Api.summary
          .vehicle_statistic({
            query: this.$route.query,
            vehicle_id: this.item.id,
          })
          .then((res) => {
            this.chart_sensor = res?.sensor;
          })
          .finally(() => {
            this.load_chart_sensor = "";
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>