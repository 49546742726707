<template>
  <div class="columns mx-3">
    <div class="column" @click="view_details = !view_details">
      <ui-box-card >
        <div class="content" >
          <div class="is-flex is-justify-content-space-between">
            <div class="">
              Все датчики:
              <span class="has-text-success mx-2">
                {{ use_sensors?.success_count }}
              </span>
              <span class="has-text-danger mx-2">
                {{ use_sensors?.error_count }}
              </span>
            </div>
            <div class="" v-if="view_details">
              <img src="@/assets/arrow.svg" alt="" style="transform: rotate(270deg)">
            </div>
            <div class="" v-else>
              <img src="@/assets/arrow.svg" alt="" style="transform: rotate(90deg)">
            </div>
          </div>
        </div>
      </ui-box-card>      
    </div>
    <div class="column" v-if="!view_details">
      <ui-box-card >
        <div class="h5 is-size-5 has-text-centered mb-2" v-if="use_sensors?.error_sensors.length > 0">Нет данных с gserver</div>
        <div class="box-danger p-2" v-if="use_sensors?.error_sensors.length > 0">
          <div class="" v-for="item of use_sensors.error_sensors" :key="item.id">
            <div>
              <template v-if="item.vehicle">
                <div>
                  <p>
                    № авто: <strong>{{ item.vehicle.registration_number? item.vehicle.registration_number: item.vehicle.name }}</strong> id: {{ item.vehicle.id }}
                  </p>
                </div>
              </template>
            </div>
            <hr class="my-1">
          </div>
        </div>
        <div class="h5 is-size-5 has-text-centered mb-2" v-if="use_sensors?.success_sensors.length > 0">Работающие gservers</div>
        <div class="box-success p-2" v-if="use_sensors?.success_sensors.length > 0">
          <div class="" v-for="item of use_sensors.success_sensors" :key="item.id">
            <div>
              <template v-if="item.vehicle">
                <div>
                  <p>
                    № авто: <strong>{{ item.vehicle.registration_number? item.vehicle.registration_number: item.vehicle.name }}</strong> id: {{ item.vehicle.id }}
                  </p>
                </div>
              </template>
            </div>
            <hr class="my-1">
          </div>
        </div>
      </ui-box-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view_details: true,
      use_sensors: null,
    }
  },
  created() {
    Api.sensors.filling_sensor_stats().then(res => {
      this.use_sensors = res;
    })
  },
}
</script>

<style lang="scss" scoped>
.box-danger {
  border: 1px solid  hsl(348, 100%, 61%);
  border-radius: 10px;
}
.box-success {
  border: 1px solid   hsl(141, 71%, 48%);
  border-radius: 10px;

}

</style>