<template>
  <div class="columns mx-3">
    <div class="column" v-for="(item, index) in get_vehicles_sensor" :key="index">
      <vehicle_item :item="item" />
    </div>
  </div>
</template>
<script>
import { vehicles_sensor } from '@/hooks/summary/vehicles_sensor'
import vehicle_item from '@/components/vehicles_sensor/vehicle_item'

export default {
  components: {
    vehicle_item
  },
  setup() {
    const { vehicles_sensor: get_vehicles_sensor  } = vehicles_sensor();
    
    return {
      get_vehicles_sensor,
    }
  },
}
</script>