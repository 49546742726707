<template>
  <svg
    width="31"
    height="23"
    viewBox="0 0 31 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9 15.8125H29.1333V10.9564C29.1333 10.3859 28.889 9.83789 28.4577 9.43359L23.6708 4.9459C23.2396 4.5416 22.655 4.3125 22.0465 4.3125H19.9333V2.15625C19.9333 0.96582 18.9031 0 17.6333 0H2.3C1.03021 0 0 0.96582 0 2.15625V16.5312C0 17.7217 1.03021 18.6875 2.3 18.6875H3.06667C3.06667 21.0684 5.12708 23 7.66667 23C10.2063 23 12.2667 21.0684 12.2667 18.6875H18.4C18.4 21.0684 20.4604 23 23 23C25.5396 23 27.6 21.0684 27.6 18.6875H29.9C30.3217 18.6875 30.6667 18.3641 30.6667 17.9688V16.5312C30.6667 16.1359 30.3217 15.8125 29.9 15.8125ZM7.66667 20.8438C6.39687 20.8438 5.36667 19.8779 5.36667 18.6875C5.36667 17.4971 6.39687 16.5312 7.66667 16.5312C8.93646 16.5312 9.96667 17.4971 9.96667 18.6875C9.96667 19.8779 8.93646 20.8438 7.66667 20.8438ZM23 20.8438C21.7302 20.8438 20.7 19.8779 20.7 18.6875C20.7 17.4971 21.7302 16.5312 23 16.5312C24.2698 16.5312 25.3 17.4971 25.3 18.6875C25.3 19.8779 24.2698 20.8438 23 20.8438ZM26.8333 11.5H19.9333V6.46875H22.0465L26.8333 10.9564V11.5Z"
      :fill="icon_color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon_color: {
      type: String,
      default: "white",
    },
  },
};
</script>