<!-- КГО забрали -->
<template>
  <div class="kgo">
    <div class="kgo-title">
      <img src="@/assets/images/sofa_success.svg" alt="" />
      КГО забрали
    </div>
    <div class="kgo-cards">
      <kgo-card :item="item" v-for="(item, index) of items" :key="index" />
      <div class="preloader" v-if="loading">
        <preloaderLogo />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { global_state } from "@/store/global";
import kgoCard from "./kgo-card.vue";
import preloaderLogo from "@/components/icons_color/preloader_logo";

export default {
  components: { kgoCard, preloaderLogo },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.fetching();
  },
  setup() {
    const globalStore = global_state();

    return { globalStore };
  },
  methods: {
    fetching() {
      this.loading = true;

      if (this.globalStore.use_organisation?.id && this.globalStore.use_date) {
        Api.events
          .event_kgo({
            organisation: this.globalStore.use_organisation.id,
            date: this.globalStore.use_date,
          })
          .then((res) => {
            this.items = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.kgo {
  padding: 0 26px;
}

.kgo-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  & img {
    margin-right: 15px;
    display: inline-block;
    width: 30px;
  }
}

.preloader {
  padding-top: 50px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.kgo-cards {
}
</style>