<template>
  <div class="layout">
    <slot></slot>
  </div>
</template>
<script>
export default {
  created() {
  },
};
</script>
<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
}
</style>