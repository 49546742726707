import ky from 'ky'
import { guard_auth, guard_error } from "@/utils/guard"

const url_auth = new URL(`${process.env.VUE_APP_AUTH_URL}`);
const url_wn = new URL(`${process.env.VUE_APP_WN}`);

const auth = ky.create({ //Запросы на auth могут быть с защитой и без, если нужен токен, то проверяем поле guard
  timeout: 2 * 60 * 1000,
  hooks: {
    beforeRequest: [
			(request, {guard}) => {
        if (guard) {
          guard_auth(request)
        }
			}
		],
    beforeError: [
      error => {
        return guard_error(error);        
			}
    ],
  }
})

const worknote = ky.create({ //Запросы на worknote всегда с данными доступа в виде token и прочего
  timeout: 2 * 60 * 1000,
  hooks: {
    beforeRequest: [
			request => {
        guard_auth(request)
			}
		],
    beforeError: [
      (error) => {
        return guard_error(error);
			}
    ]
  }
})

window.ky_auth = auth
window.ky_wn = worknote

export default ky;