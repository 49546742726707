<template>
  <div>
    <div class="canvas" :class="{'canvas--pointer': tooltip}">
      <canvas ref="canvas" :width="width + offset" :height="height + offset"></canvas>
      <div class="canvas-tooltip" :style="{top: `${this.tooltip.y + 15}px`, left: `${this.tooltip.x + 15}px`}" v-if="tooltip">
        <strong>Время: </strong>{{tooltip.ts}} <br>
        <strong>Значение: </strong> {{tooltip.val}} <br>
      </div>
      <div class="canvas-scale" v-if="open_scale" :style="{left: `${open_scale.x}px`}">
        <div class="canvas-scale__close" @click="open_scale = null">
          <i class="mdi mdi-close"></i>
        </div>
        <canvas width="150" :height="height" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    width: {
      default: 1000,
      type: Number,
    },
    height: {
      default: 500,
      type: Number,
    },
    items: {
      default: () => [],
    },
    radius: {
      default: 2,
      type: Number,
    },
    var_scroll_chart: {
      type: Object,
      default: null,
    },
    hidden_date_bottom: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    items() {
      let ctx  = this.$refs.canvas.getContext('2d');
      
      this.$refs.canvas.width = 0;
      this.$refs.canvas.width = this.width + this.offset

      this.buildCanvas();
    }
  },
  mounted() {
    if (this.var_scroll_chart) {
      this.countScroll = this.var_scroll_chart.count;
    }
    
    this.buildCanvas();
  },
  data() {
    return {
      offset: 50,
      tooltip: null,
      open_scale: null,
      countScroll: 100, //Для того, что бы определить, насколько нужно приблизить/отдалить вводим множитель
    };
  },
  methods: {
    buildCanvas() {
      let offset = this.offset/2;
      let base_offset = this.offset;
      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext('2d');

      let xMin = Number(new Date(this.items[0].ts));
      let xMax = Number(new Date(this.items[this.items.length - 1].ts));
      let xQ = Math.abs((this.width - base_offset)/(xMax - xMin));

      let yMin = 0;
      // let yMax = Math.max(...this.items.map(e => e.val)); // нужно для динамической высоты
      let yMax = 100;
      let yQ = Math.abs((this.height - base_offset)/(yMax - yMin));

      ctx.translate(base_offset, canvas.height - base_offset); //смещаем точку отсчета
      ctx.scale(1, -1); //смещаем точку отсчета
      
      //Расчет дней
      for (const i of this.spliceUniqTime()) {
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([4, 16]);
        ctx.globalAlpha = 0.4;
        ctx.scale(1, -1)
        ctx.moveTo(Math.round(Math.abs(xMin - i) * xQ), - this.height + offset * 2);
        ctx.lineWidth=2;
        ctx.strokeStyle="red";
        ctx.lineTo(Math.round(Math.abs(xMin - i) * xQ), 0 );
        ctx.fillText(`${new Date(i).toISOString().substr(0, 10)}`, Math.round(Math.abs(xMin - i) * xQ) - 26, -10);
        ctx.stroke();
        ctx.restore();
      }

      //Граница для отслеживания выхода за рамки
      ctx.beginPath();
      ctx.setLineDash([4, 16]);
      ctx.globalAlpha = 0.4;
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'red';
      ctx.moveTo(0, this.height - base_offset);
      ctx.lineTo(this.width - base_offset, this.height - base_offset);
      ctx.stroke();
      
      //Линии для определения границ
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.globalAlpha = 0.4;
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'black';
      ctx.moveTo(0, 0);
      ctx.lineTo(0, this.height - base_offset);
      ctx.moveTo(0, 0);
      ctx.lineTo(this.width - base_offset, 0);
      ctx.stroke();
      ctx.globalAlpha = 1;

      //Описание координат
      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = "#000";
      ctx.strokeStyle = "#000";
      ctx.font = "normal 11pt Arial";
      ctx.scale(1, -1)
      ctx.fillText(`Заполненность, max: 100%`, -base_offset, base_offset - this.height - 20);
      ctx.fillText(`Начало периода: `, -base_offset, 20);
      ctx.fillText(`${this.items[0].ts}`,  -base_offset, 40);
      ctx.fillText(`Конец периода:`, this.width - base_offset * 2 - 20, 20);
      ctx.fillText(`${this.items[this.items.length - 1].ts}`, this.width - base_offset * 2 - 50, 40);
      ctx.stroke();
      ctx.restore();

      //Черточки по оси Y
      this.buildAxisLineFragmentY(ctx, base_offset);
      

      //Черточки по оси X
      this.buildAxisLineFragmentX(ctx, xMin, xMax, xQ, base_offset);
      
      // График линии
      ctx.beginPath();
      ctx.moveTo(0, this.items[0].val * yQ);
      ctx.lineWidth=2.5;
      ctx.strokeStyle='#2a669e';
      for (const i of this.items) {
        let xSec = Number(new Date(i.ts));
        let x = Math.round(Math.abs(xMin - xSec) * xQ);
        let y = Math.round(i.val * yQ);
        
        ctx.lineTo(x, y);
      }
      ctx.stroke();

      //Градиент
      // ctx.globalAlpha = 0.5;
      // let gradient = ctx.createLinearGradient(0, 0, 0, 100);
      // gradient.addColorStop(.8, '#2a669e');
      // gradient.addColorStop(.2, '#fff');
      // ctx.fillStyle = gradient;
      // ctx.fillRect(20, 20, 200, 100);
      // ctx.globalAlpha = 1;


      //Точки на графике
      let count = 0;
      let count_end = this.items.length;
      ctx.moveTo(0, 0);
      ctx.lineWidth=2;      
      for (const i of this.items) {
        count++
        let xSec = Number(new Date(i.ts));
        let x = Math.round(Math.abs(xMin - xSec) * xQ);
        let y = Math.round(i.val * yQ);
        
        ctx.beginPath();
        if (count === 1 || count_end === count) {
          ctx.strokeStyle="red";
        } else {
          ctx.strokeStyle="black";
        }
        ctx.arc(x,y,this.radius,0,2*Math.PI);
        ctx.stroke();
      }

      ctx.strokeStyle="black";
      //Отслеживание мышки
      canvas.onmousemove = e => {
        document.querySelector('body').style.overflow = 'hidden';

        let xRect = e.offsetX - base_offset,
          yRect = this.height - e.offsetY;
        let tooltip = null;

        for (const i of this.items) {
          let xSec = Number(new Date(i.ts));
          let x = Math.round(Math.abs(xMin - xSec) * xQ);
          let y = Math.round(i.val * yQ);

          if ((x >= xRect - this.radius && x <= xRect + this.radius) && (y >= yRect - this.radius && y <= yRect + this.radius)) {
            tooltip = Object.assign(i, {xRect: xRect, yRect: yRect, x: x + base_offset, y: this.height - y});
          }
        }

        if (tooltip) {
          this.tooltip = tooltip;
          this.$emit('tooltip', tooltip)
        } else {
          this.tooltip = null
          this.$emit('tooltip', null)
        }
      }

      canvas.onmouseout = (event) => {
        document.querySelector('body').style.overflow = 'auto';
      }

      canvas.onwheel = (event) => {
        let { layerX, wheelDelta } = event; //вытаскиваем местоположение курсора на холсте
        let widthLayerX = new Date(this.items[this.items.length - 1].ts) - new Date(this.items[0].ts);  //высчитываем наш отрезок времени
        if (layerX < canvas.width - base_offset) { //проверяем, что бы скролл был сделан не выходя за координаты
          layerX = layerX - base_offset > 0? layerX - base_offset: 0; //если меньше нуля то делаем 0
          let procentLayerx = ((layerX * 100)/(canvas.width - base_offset * 2))/100; //высчитываем процент места скролла от длины холста
          layerX = Math.round(Number(new Date(this.items[0].ts)) + widthLayerX * procentLayerx);  //получаем процент от отрезка времени и прибавляем к нашему минимуму, 
          //таким образом мы получим время, от которого, сможем пройтись по всем точкам и определить ближаюшую
          if (wheelDelta >= 0) {
            this.countScroll = this.countScroll - 10 > 10? this.countScroll - 10: 10;
            this.$emit('scrollChart', { ts: layerX, scale: true, count: this.countScroll, procentLayerx })
          } else {
            this.countScroll = this.countScroll + 10 < 100? this.countScroll + 10: 100;  
            this.$emit('scrollChart', { ts: layerX, scale: false, count: this.countScroll, procentLayerx })
          }
        }
      }
    },
    buildAxisLineFragmentY(ctx, base_offset) {
      const fragment = Math.ceil((this.height - base_offset)/10);
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.globalAlpha = 0.4;
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'black';
      for (let index = 1; index < 10; index++) { //от 1 до 9
        ctx.save();
        ctx.moveTo(-10, fragment * index);
        ctx.lineTo(10, fragment * index);
        ctx.scale(1, -1)
        ctx.fillText(index * 10, -30, -fragment * index + 5);
        ctx.restore();
      }
      ctx.stroke();
      ctx.globalAlpha = 1;
    },
    buildAxisLineFragmentX(ctx, xMin, xMax, xQ, base_offset) {
      if (this.hidden_date_bottom) {        
        const fragment = (xMax - xMin)/10;
        ctx.beginPath();
        ctx.setLineDash([]);
        ctx.globalAlpha = 0.4;
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black';
        for (let index = 1; index < 10; index++) { //от 1 до 9
          const timeFragment = new Date((xMin) + (fragment * index));
          const dayAndMounth = `${timeFragment.toISOString().substr(0, 10).split('-')[1]}-${timeFragment.toISOString().substr(0, 10).split('-')[2]}`;
          const hoursAndMinutes = `${String(timeFragment.getHours()).padStart(2, '0')}:${String(timeFragment.getMinutes()).padStart(2, '0')}`;
          ctx.save();
          ctx.moveTo(Math.round((fragment * index) * xQ), -10);
          ctx.lineTo(Math.round((fragment * index) * xQ), 10);
          ctx.scale(1, -1)
          ctx.fillText(`${dayAndMounth} ${hoursAndMinutes}`, Math.round((fragment * index) * xQ) - 27, 20);
          ctx.restore();
        }
        ctx.stroke();
        ctx.globalAlpha = 1;
      }
    },
    spliceUniqTime() {
      let uniqTime = [...new Set(this.items.map(e => {return Number(new Date(e.ts.substr(0, 10))) }))];

      if (uniqTime.length > 2) {
        uniqTime = uniqTime.slice(1, uniqTime.length -1);
      } else {
        uniqTime = [];
      }

      return uniqTime
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas {
  position: relative;

  &-tooltip {
    position: absolute;
    padding: 10px;
    background-color: #808E96;
    color: #F0F5F7;
    font-size: 10pt;
    border-radius: 5px;
  }

  &--pointer {
    cursor: pointer;
  }

  &-scale {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 22px 2px rgba(81, 125, 188, 0.25);
    border-radius: 5px;
    background-color: white;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>