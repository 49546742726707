import {
  createRouter,
  createWebHistory
} from 'vue-router'
import {
  auth
} from "./router/auth"

const routes = [{
    path: '/',
    redirect: {
      name: 'home'
    },
    component: () => import('@/pages/work_app/base'),
    children: [{
        path: '/home',
        name: 'home',
        components: {
          default: () => import('@/pages/work_app/home/home'),
        },
        meta: {
          layout: 'default-layout',
          requiresAuth: true,
          title: 'Главная',
          breadcrumb: [{
            name: 'home',
            description: 'Главная'
          }, ]
        }
      },
      {
        path: '/vehicles',
        children: [{
            path: '',
            name: 'vehicles',
            components: {
              default: () => import('@/pages/work_app/vehicles/vehicles'),
            },
            meta: {
              layout: 'default-layout',
              requiresAuth: true,
              title: 'Автомобили',
              breadcrumb: [{
                name: 'vehicles',
                description: 'Автомобили'
              }, ]
            }
          },
          {
            path: ':vehicle_id',
            name: 'vehicle_detail',
            components: {
              default: () => import('@/pages/work_app/vehicles/vehicle_detail/vehicle_detail'),
            },
            meta: {
              layout: 'default-layout',
              requiresAuth: true,
              title: 'Автомобили',
              breadcrumb: [{
                name: 'vehicles',
                description: 'Автомобили'
              }, ]
            }
          },

        ]
      },
      {
        path: '/installation_kit',
        name: 'installation_kit',
        components: {
          default: () => import('@/pages/work_app/installation_kit/installation_kit'),
        },
        meta: {
          layout: 'default-layout',
          requiresAuth: true,
          title: 'installation kit',
          breadcrumb: [{
            name: 'installation_kit',
            description: 'Комплект установки'
          }, ]
        }
      }
    ]
  },
  {
    path: '/auth',
    children: auth
  },
  {
    path: '/ecomteh',
    name: 'autoLogin',
    components: {
      default: () => import('@/pages/auth/autoLogin'),
    },
    meta: {
      layout: 'auth-layout',
      title: 'Авто авторизация',
      breadcrumb: [{
        name: 'autoLogin',
        description: 'Авто авторизация'
      }, ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    components: {
      default: () => import('@/pages/not-found/not-found'),
    },
    meta: {
      layout: 'auth-layout',
      title: 'Страница не найдена',
      breadcrumb: [{
        name: 'not-found',
        description: 'Страница не найдена'
      }, ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) { //проверяем обязательна ли для страницы авторизация
    if (!localStorage.getItem('auth_token')) { //если обязательна, то проверяем, есть ли данные по токену в сторе
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router