<template>
	<svg  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2500 1664" style="enable-background:new 0 0 2500 1664;" xml:space="preserve">
<g>
	<g>
		<g>
			<g>
				<path id="XMLID_15_" class="st2" d="M2005.2,1094.4H584.7c-12.2,0-22.1-9.9-22.1-22.1v-15.9c0-12.2,9.9-22.1,22.1-22.1h1420.5
					c12.2,0,22.1,9.9,22.1,22.1v15.9C2027.3,1084.5,2017.4,1094.4,2005.2,1094.4z"/>
				<g>
					<path class="st3" d="M1624.2,1083.1c-1-0.5-1.4-1.4-1.3-2.2c-1,0.7-1.2,2.6,0.4,3.1c0.9,0.3,1.6-0.1,2-0.7
						C1624.9,1083.3,1624.6,1083.2,1624.2,1083.1z"/>
					<path class="st3" d="M1622.3,1091.4c-1.9-0.6-3,1.9-1.8,3h2.3C1623.7,1093.6,1623.8,1091.9,1622.3,1091.4z"/>
					<path class="st3" d="M1629.7,1087.1c-0.1-0.1-0.3-0.2-0.5-0.3c-2.2-0.7-3.3,2.6-1.1,3.4c1.2,0.4,2.1-0.5,2.3-1.5
						C1629.8,1088.3,1629.6,1087.7,1629.7,1087.1z"/>
					<path class="st3" d="M1634.5,1081.9c-2.2-0.7-3.3,2.6-1.1,3.4C1635.5,1086,1636.7,1082.7,1634.5,1081.9z"/>
					<path class="st3" d="M1617.9,1084.7c-2.2-0.7-3.3,2.6-1.1,3.4C1618.9,1088.8,1620,1085.4,1617.9,1084.7z"/>
					<path class="st3" d="M1637.5,1091.7c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.2,0.1-0.4,0.3-0.6c0,0,0,0-0.1,0c-0.9,0-1.9,0.7-1.9,1.7
						c0,0.9,0.7,1.8,1.7,1.9C1637.5,1092.8,1637.4,1092.2,1637.5,1091.7z"/>
					<path class="st3" d="M1613.9,1094.4h1.4c-0.2-0.1-0.4-0.2-0.6-0.2C1614.5,1094.2,1614.2,1094.3,1613.9,1094.4z"/>
					<path class="st3" d="M1632.3,1074.1c1.8,1,0.9,3.4-0.8,3.6c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.6-0.6,0.9-0.8
						c0.7-0.6,0.9-1.8,0.2-2.5c-0.6-0.7-1.8-0.9-2.5-0.2c0,0-0.1,0.1-0.1,0.1C1631.5,1073.8,1631.9,1073.8,1632.3,1074.1z"/>
					<path class="st3" d="M1641.5,1082.2c-2.2-0.7-3.3,2.6-1.1,3.4C1642.5,1086.3,1643.6,1082.9,1641.5,1082.2z"/>
					<path class="st3" d="M1615.4,1075.7c-2.2-0.7-3.3,2.6-1.1,3.4C1616.4,1079.7,1617.6,1076.4,1615.4,1075.7z"/>
					<path class="st3" d="M1607.3,1092.4c-1.4-0.5-2.4,0.8-2.3,2h3.4C1608.5,1093.6,1608.2,1092.7,1607.3,1092.4z"/>
					<path class="st3" d="M1605.3,1082c-1-0.4-1.8,0.3-2.2,1.1c0.2,0,0.3,0.1,0.5,0.2c0.8,0.3,1.4,1.3,1.4,2.2
						C1606.5,1085.1,1607.1,1082.6,1605.3,1082z"/>
					<path class="st3" d="M1653.9,1077.7c-0.1,0-0.2,0.1-0.4,0.1c-0.7,0-1.4,0.1-2.2,0.1c-0.5,0-0.9-0.1-1.2-0.3
						c-0.1,0.7,0.1,1.5,0.9,1.9C1652.6,1080.3,1654,1079,1653.9,1077.7z"/>
					<path class="st3" d="M1648.7,1087.2c-2.2-1.2-4,2.2-1.8,3.4C1649.1,1091.8,1650.9,1088.4,1648.7,1087.2z"/>
					<path class="st3" d="M1656.9,1083.7c-2.2-1.2-4,2.2-1.8,3.4C1657.3,1088.2,1659.1,1084.8,1656.9,1083.7z"/>
					<path class="st3" d="M1661.3,1093.2c-1.2-0.7-2.4,0.1-2.7,1.2h3.6C1662.2,1093.9,1661.9,1093.5,1661.3,1093.2z"/>
					<path class="st3" d="M1663.5,1079.4c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.1c-0.5,0.4-1.2,0.5-1.7,0.2
						c-0.9,0.8-1.1,2.4,0.2,3.2C1663.8,1083.9,1665.7,1080.6,1663.5,1079.4z"/>
					<path class="st3" d="M1644.4,1080c0.9-0.1,1.5,0.3,1.8,1c0-0.6-0.2-1.3-1-1.7c-2.1-1.1-3.8,1.9-2.1,3.2
						C1642.7,1081.5,1643.1,1080.2,1644.4,1080z"/>
					<path class="st3" d="M1667.2,1088.7c-0.5-0.1-0.9-0.2-1.4-0.3c-1-0.2-2.1,0.4-2.3,1.4c-0.2,1,0.4,2.1,1.4,2.3
						c0.5,0.1,0.9,0.2,1.4,0.3c1,0.2,2.1-0.4,2.3-1.4C1668.9,1089.9,1668.3,1088.9,1667.2,1088.7z"/>
					<path class="st3" d="M1654.4,1093.2c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3-1-1.4-1.6-2.4-1.3c-1,0.3-1.6,1.4-1.3,2.4
						c0.1,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1h3.6C1654.5,1094,1654.5,1093.6,1654.4,1093.2z"/>
					<path class="st3" d="M1640.1,1088.9c-0.8-0.1-1.6,0.2-2,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.5-0.2,0.9-0.3,1.4
						c-0.1,0.5,0,1.1,0.3,1.5c0.3,0.4,0.6,0.7,1.1,0.8c1,0.2,2.1-0.4,2.3-1.4c0.1-0.5,0.2-0.9,0.3-1.4
						C1641.8,1090.2,1641.2,1089.1,1640.1,1088.9z"/>
					<path class="st3" d="M1661.5,1070c-0.4,0.2-0.7,0.5-1.1,0.7c-0.8,0.6-1.2,1.7-0.6,2.6c0.5,0.8,1.8,1.3,2.6,0.6
						c0.4-0.2,0.7-0.5,1.1-0.7c0.8-0.6,1.2-1.7,0.6-2.6C1663.6,1069.8,1662.4,1069.4,1661.5,1070z"/>
					<path class="st3" d="M1670.9,1081c-2.2-1.2-4,2.2-1.8,3.4C1671.2,1085.5,1673.1,1082.1,1670.9,1081z"/>
					<path class="st3" d="M1642.4,1072.6c0.1,0,0.2,0.1,0.3,0.1c0.2-0.5,0.5-0.9,1-1.1c0.4-0.2,1-0.2,1.4,0c0.4-0.8,0.2-1.9-0.8-2.4
						C1642.1,1068,1640.3,1071.4,1642.4,1072.6z"/>
					<path class="st3" d="M1632.5,1085.6c-1.4-0.8-2.6,0.3-2.8,1.5c-0.1,0.6,0.1,1.2,0.6,1.6c0.1,0.1,0.2,0.2,0.3,0.2
						C1632.8,1090.1,1634.7,1086.7,1632.5,1085.6z"/>
					<path class="st3" d="M1630.4,1074.6c0-0.1,0.1-0.1,0.1-0.2C1630.5,1074.4,1630.4,1074.5,1630.4,1074.6z"/>
					<path class="st3" d="M1630.7,1074.1c-0.1,0.1-0.1,0.1-0.1,0.2C1630.6,1074.2,1630.7,1074.1,1630.7,1074.1z"/>
					<path class="st3" d="M1630.7,1074.1C1630.7,1074.1,1630.7,1074.1,1630.7,1074.1c-0.1,0.1-0.1,0.2-0.1,0.3
						c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.4c-0.1,0.6-0.4,1-0.9,1.3c0.1,0.4,0.4,0.8,0.9,1c0.4,0.2,0.7,0.3,1.1,0.2
						c1.6-0.2,2.6-2.6,0.8-3.6c-0.4-0.2-0.8-0.3-1.1-0.2c-0.1,0-0.2,0-0.2,0.1C1630.8,1073.9,1630.8,1074,1630.7,1074.1z"/>
					<path class="st3" d="M1652.6,1061.8c-0.7-0.4-1-1.3-0.8-2.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
						c0.4,0.6,0.4,1.4,0.1,2.1c-0.3,0.7-1,1.1-1.7,1.2c0.1,0.1,0.2,0.2,0.3,0.2C1651.1,1063.7,1652.3,1062.8,1652.6,1061.8z"/>
					<path class="st3" d="M1690.1,1063.7c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.9,0.3-2.6-1.3-2.6c-1.9,0-2.1,2.6-0.6,3.2
						C1689.1,1063.8,1689.5,1063.6,1690.1,1063.7z"/>
					<path class="st3" d="M1690.4,1074.2c0.8,0,1.3-0.5,1.5-1.1c-0.5,0.4-1.2,0.6-2,0.2c-0.6-0.3-0.9-0.7-1-1.2
						C1688.7,1073,1689.2,1074.2,1690.4,1074.2z"/>
					<path class="st3" d="M1695.1,1067.4c-0.1,0.3-0.4,0.5-0.6,0.7c0.2,0.1,0.4,0.2,0.7,0.2c2.1,0,2.2-3.2,0.1-3.3
						c-0.2,0-0.4,0-0.6,0.1C1695.3,1065.6,1695.5,1066.5,1695.1,1067.4z"/>
					<path class="st3" d="M1701.8,1073.3c0-0.1,0-0.1,0-0.2C1701.8,1073.2,1701.8,1073.3,1701.8,1073.3z"/>
					<path class="st3" d="M1702.4,1070.4c-0.1,0-0.3,0-0.4,0c0.4,0.6,0.5,1.4,0.2,2c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
						c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.1C1704.4,1073.8,1704.5,1070.5,1702.4,1070.4z"/>
					<path class="st3" d="M1701.9,1072.8c0,0.1-0.1,0.1-0.1,0.2C1701.9,1072.9,1701.9,1072.9,1701.9,1072.8z"/>
					<path class="st3" d="M1698.4,1061.6c-0.1-0.2-0.1-0.4-0.2-0.5c-0.2-0.7,0-1.4,0.4-1.9c0,0-0.1,0-0.1,0c-2.1,0-2.2,3.2-0.1,3.3
						c0.1,0,0.3,0,0.4,0C1698.7,1062.2,1698.5,1061.9,1698.4,1061.6z"/>
					<path class="st3" d="M1683.1,1067.7c-0.1,0.9,0.4,1.9,1.5,1.9c2.1,0,2.2-3.2,0.1-3.3c-0.4,0-0.7,0.1-0.9,0.2
						C1683.6,1067,1683.4,1067.4,1683.1,1067.7z"/>
					<path class="st3" d="M1703.9,1065c-0.8,0.2-1.4,1.1-1.2,2c0.3,0.8,1.1,1.4,2,1.2c0.4-0.1,0.8-0.2,1.2-0.3
						c0.8-0.2,1.4-1.1,1.2-2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0-0.1,0.1-0.2,0.1C1705.5,1066,1704.5,1065.7,1703.9,1065z"/>
					<path class="st3" d="M1696.8,1072.8c-0.6-0.6-1.7-0.7-2.3,0c-0.6,0.6-0.7,1.7,0,2.3c0.1,0.1,0.2,0.2,0.3,0.3
						c0.6,0.6,1.7,0.7,2.3,0c0.6-0.6,0.7-1.7,0-2.3C1697,1073.1,1696.9,1072.9,1696.8,1072.8z"/>
					<path class="st3" d="M1686.6,1076.7c-0.2-0.8-1.1-1.4-2-1.2c-0.8,0.3-1.4,1.1-1.2,2c0.1,0.4,0.2,0.8,0.3,1.2
						c0.2,0.8,1.1,1.4,2,1.2c0.8-0.3,1.4-1.1,1.2-2C1686.8,1077.5,1686.7,1077.1,1686.6,1076.7z"/>
					<path class="st3" d="M1695.6,1052.3c-0.7-0.4-1.8-0.3-2.3,0.5c1-0.1,1.8,0.6,2.2,1.5c0.2,0,0.4,0.1,0.6,0.2
						C1696.5,1053.7,1696.4,1052.7,1695.6,1052.3z"/>
					<path class="st3" d="M1692.8,1079.3c-0.7-0.1-1.3-0.1-2-0.2c-0.9-0.1-1.7,0.8-1.7,1.6c0,0.9,0.7,1.6,1.6,1.7
						c0.7,0.1,1.3,0.1,2,0.2c0.9,0.1,1.7-0.8,1.7-1.6C1694.4,1080.1,1693.7,1079.4,1692.8,1079.3z"/>
					<path class="st3" d="M1701.7,1083c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.7-1.6-1.7
						c-1,0-1.6,0.7-1.7,1.6c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.4,1.1,2.2,0.6c0.7-0.4,1.2-1.5,0.6-2.2
						C1701.8,1083.3,1701.7,1083.1,1701.7,1083z M1701.6,1082.6c0,0,0,0.1,0,0.2C1701.6,1082.7,1701.6,1082.7,1701.6,1082.6z
						 M1701.7,1083c0-0.1-0.1-0.1-0.1-0.2C1701.6,1082.9,1701.7,1083,1701.7,1083z"/>
					<path class="st3" d="M1705.7,1077.4c-2.1,0-2.2,3.2-0.1,3.3C1707.8,1080.7,1707.9,1077.4,1705.7,1077.4z"/>
					<path class="st3" d="M1692.7,1084.2c-2.1,0-2.2,3.2-0.1,3.3C1694.8,1087.5,1694.8,1084.2,1692.7,1084.2z"/>
					<path class="st3" d="M1684,1083.4c-2.1,0-2.2,3.2-0.1,3.3C1686.1,1086.7,1686.1,1083.4,1684,1083.4z"/>
					<path class="st3" d="M1703.8,1060.4c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0,2.2-3.2,0.1-3.3c-1.5,0-2,1.7-1.3,2.7
						C1703.6,1060.2,1703.7,1060.3,1703.8,1060.4z"/>
					<path class="st3" d="M1679.9,1062.3c2.1,0,2.2-3.2,0.1-3.3C1677.8,1058.9,1677.8,1062.2,1679.9,1062.3z"/>
					<path class="st3" d="M1677.5,1076c-2.1,0-2.2,3.2-0.1,3.3C1679.5,1079.3,1679.6,1076.1,1677.5,1076z"/>
					<path class="st3" d="M1712.9,1071.5c-2.1,0-2.2,3.2-0.1,3.3C1714.9,1074.8,1715,1071.5,1712.9,1071.5z"/>
					<path class="st3" d="M1672.7,1067.4c-0.5,0-0.9,0.2-1.2,0.5c0.1,0.6,0,1.3-0.3,1.8c0.2,0.5,0.7,1,1.4,1
						C1674.8,1070.7,1674.9,1067.4,1672.7,1067.4z"/>
					<path class="st3" d="M1681.8,1048.8c-2.1,0-2.2,3.2-0.1,3.3C1683.8,1052.1,1683.9,1048.8,1681.8,1048.8z"/>
					<path class="st3" d="M1708.7,1036.8c0,0,0.1-0.1,0.1-0.1c0.9-0.6,2.1-0.2,2.7,0.6c0.1,0.1,0.1,0.2,0.1,0.3
						c1.5-0.7,1.6-2.2,0.9-3.3h-4.1C1708,1035,1708.1,1036,1708.7,1036.8z"/>
					<path class="st3" d="M1718.5,1047.6c1.1,0.6,1.2,1.7,0.8,2.5c0.2,0,0.4-0.1,0.6-0.2c2.8-1.4,0.6-5.7-2.2-4.3
						c-1,0.5-1.3,1.3-1.3,2.2C1717.1,1047.4,1717.8,1047.2,1718.5,1047.6z"/>
					<path class="st3" d="M1720,1034.6c-2.8,1.4-0.6,5.7,2.2,4.3C1725,1037.5,1722.8,1033.2,1720,1034.6z"/>
					<path class="st3" d="M1733,1036.9c-2.8,1.4-0.6,5.7,2.2,4.3C1738,1039.8,1735.8,1035.5,1733,1036.9z"/>
					<path class="st3" d="M1708.2,1048.1c0-1.2,1.1-2.4,2.4-2.1c0.2-1.7-1.5-3.5-3.4-2.5C1704.8,1044.7,1706,1048,1708.2,1048.1z"/>
					<path class="st3" d="M1725.9,1048.4c0.2,0.1,0.4,0.1,0.7,0.2c1.2,0.4,2.7-0.3,3.1-1.5c0.4-1.3-0.2-2.6-1.5-3.1
						c-0.2-0.1-0.4-0.1-0.7-0.2c-1.2-0.4-2.7,0.3-3.1,1.5C1724,1046.6,1724.6,1048,1725.9,1048.4z"/>
					<path class="st3" d="M1717.9,1057.1c-0.4-0.4-0.8-0.9-1.2-1.3c-0.7-0.8-1.9-1-2.9-0.6c0.4,0.1,0.8,0.2,1.1,0.2
						c1.1,0.2,1.7,1.3,1.5,2.3c-0.2,1-1.3,1.7-2.3,1.5c-0.3-0.1-0.7-0.1-1-0.2c0.4,0.4,0.8,0.8,1.1,1.3c0.9,0.9,2.5,1.1,3.4,0.2
						C1718.7,1059.6,1718.9,1058.1,1717.9,1057.1z"/>
					<path class="st3" d="M1729.9,1056.1c-0.7-1.2-2-1.6-3.2-1.1c-0.9,0.4-1.8,0.7-2.8,1.1c-1.2,0.5-1.7,2.2-1.1,3.2
						c0.7,1.2,2,1.6,3.2,1.1c0.9-0.4,1.8-0.7,2.8-1.1C1730,1058.8,1730.5,1057.1,1729.9,1056.1z"/>
					<path class="st3" d="M1741.2,1054c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
						c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.2-2.2-1.7-3.2-1.1c-1.2,0.7-1.6,2-1.1,3.2c0.5,1.3,1.5,2.4,2.7,3c1.1,0.6,2.6,0.5,3.3-0.7
						C1742.5,1056.3,1742.4,1054.6,1741.2,1054z M1740.3,1053.3c0,0,0.1,0.1,0.1,0.2C1740.4,1053.5,1740.3,1053.4,1740.3,1053.3z
						 M1740.6,1053.6c0.1,0.1,0.1,0.1,0.2,0.2C1740.7,1053.7,1740.6,1053.7,1740.6,1053.6z"/>
					<path class="st3" d="M1742.2,1043.6c-2.8,1.4-0.6,5.7,2.2,4.3C1747.1,1046.5,1745,1042.2,1742.2,1043.6z"/>
					<path class="st3" d="M1729.8,1061.3c-2.8,1.4-0.6,5.7,2.2,4.3C1734.8,1064.3,1732.6,1060,1729.8,1061.3z"/>
					<path class="st3" d="M1717.9,1066.2c-2.8,1.4-0.6,5.7,2.2,4.3C1722.9,1069.1,1720.7,1064.8,1717.9,1066.2z M1718.1,1070.3
						c-2.2-1.1-0.5-4.6,1.8-3.5C1722.1,1068,1720.3,1071.5,1718.1,1070.3z"/>
					<path class="st3" d="M1697.8,1041.7c-0.1,0-0.2-0.1-0.3-0.1c-2.4-0.9-1.4-4.2,0.6-4.4c-0.6-0.3-1.3-0.3-2,0
						C1693.4,1038.5,1695.2,1042.5,1697.8,1041.7z"/>
					<path class="st3" d="M1706.6,1061.2c-0.6-0.4-1.4-0.4-2.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.9-0.2,1.9-1,2.5
						c0.1,0.5,0.3,0.9,0.7,1.3c0.6,0.7,1.6,1,2.7,0.4c0.1,0,0.1-0.1,0.2-0.1c0.6-0.4,0.9-0.9,1-1.4c-0.1,0-0.3-0.1-0.4-0.2
						C1706.3,1063.1,1706.1,1062,1706.6,1061.2z"/>
					<path class="st3" d="M1749.6,1035.4c0.6-0.3,0.9-0.7,1.1-1.1h-4.3C1747,1035.3,1748.2,1036.1,1749.6,1035.4z"/>
					<path class="st3" d="M1695.6,1054.3c-0.4-0.9-1.2-1.5-2.2-1.5c-0.3,0-0.6,0.1-1,0.3c-2.6,1.3-0.9,5.1,1.5,4.5
						C1692.6,1056.4,1693.8,1054,1695.6,1054.3z"/>
					<path class="st3" d="M1648,1057.6c0.5-1.1-0.2-2.8-1.8-2.5c-2,0.3-1.8,3-0.3,3.5C1646.3,1057.9,1647.2,1057.6,1648,1057.6z"/>
					<path class="st3" d="M1649.1,1065.6c-2.3,0.3-1.8,3.8,0.5,3.5C1651.9,1068.8,1651.4,1065.3,1649.1,1065.6z"/>
					<path class="st3" d="M1652.6,1061.8c0.3,0.2,0.7,0.3,1.1,0.2c2.3-0.3,1.8-3.8-0.5-3.5c-0.8,0.1-1.2,0.6-1.4,1.2
						C1651.6,1060.5,1651.9,1061.3,1652.6,1061.8z"/>
					<path class="st3" d="M1662.2,1066.6c2.3-0.3,1.8-3.8-0.5-3.5c-0.6,0.1-1,0.4-1.2,0.8c0,0.1-0.1,0.2-0.2,0.3
						C1660,1065.3,1660.7,1066.8,1662.2,1066.6z"/>
					<path class="st3" d="M1654.7,1054.7c0.3,0.4,0.8,0.6,1.5,0.5c1.7-0.2,1.8-2.3,0.9-3.1C1657.2,1053.4,1656,1054.8,1654.7,1054.7
						z"/>
					<path class="st3" d="M1642.5,1065.2c0.1,0,0.1,0,0.2,0c2.3-0.3,1.8-3.8-0.5-3.5c-0.1,0-0.3,0.1-0.4,0.1
						c0.2,0.1,0.4,0.2,0.5,0.3C1643.4,1063.1,1643.2,1064.4,1642.5,1065.2z"/>
					<path class="st3" d="M1662.3,1057.1c-0.8,0.4-1.3,1.5-0.9,2.3c0.4,0.9,1.4,1.3,2.3,0.9c0.4-0.2,0.8-0.4,1.2-0.5
						c0.8-0.4,1.3-1.5,0.9-2.3c-0.2-0.4-0.5-0.7-0.8-0.8c-0.5,0.5-1.2,0.8-1.9,0.7C1662.9,1057.3,1662.6,1057.2,1662.3,1057.1z"/>
					<path class="st3" d="M1654.1,1069.4c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.6,1.9,0.4,2.5-0.4c0.5-0.8,0.4-1.9-0.4-2.5
						c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.6-1.9-0.4-2.5,0.4C1653.2,1067.8,1653.3,1068.9,1654.1,1069.4z"/>
					<path class="st3" d="M1643.7,1071.6c-0.5,0.2-0.8,0.6-1,1.1c-0.1,0.4-0.1,0.8,0,1.3c0.2,0.4,0.4,0.8,0.5,1.2
						c0.4,0.8,1.5,1.3,2.3,0.9c0.9-0.4,1.3-1.4,0.9-2.3c-0.2-0.4-0.4-0.8-0.5-1.2c-0.2-0.4-0.5-0.7-0.9-0.9
						C1644.6,1071.4,1644.1,1071.4,1643.7,1071.6z"/>
					<path class="st3" d="M1649.1,1048.9c0.2,0.2,0.4,0.3,0.6,0.4c0.9,0.3,2,0,2.3-1c0.1-0.4,0.3-0.8,0.4-1.1c0.3-0.9,0-2-1-2.3
						c-0.9-0.3-2,0-2.3,1c-0.1,0.4-0.3,0.8-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3C1649,1047.8,1649.2,1048.4,1649.1,1048.9z"/>
					<path class="st3" d="M1653.6,1077.7c0.1,0,0.2,0,0.4-0.1c0.8-0.3,1.3-1.2,1.1-1.9c-0.2-1-1-1.6-2-1.5c-0.1,1.6-2.1,3-3.7,1.7
						c0,0.1,0,0.3,0,0.4c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.2,0.8,0.3,1.2,0.3C1652.1,1077.8,1652.9,1077.8,1653.6,1077.7z"/>
					<path class="st3" d="M1663.1,1079.4C1663.2,1079.4,1663.2,1079.3,1663.1,1079.4c0.7-0.7,0.9-1.8,0.3-2.5c0,0-0.2-0.1-0.2-0.2
						c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1.1-1.7-2-1.5c-1,0.2-1.5,1-1.5,2c0,1,0.5,2,1.2,2.7
						c0.2,0.2,0.5,0.4,0.8,0.5C1662,1079.8,1662.6,1079.8,1663.1,1079.4z M1663.2,1076.6c0-0.1-0.1-0.1-0.1-0.2
						C1663.1,1076.5,1663.1,1076.6,1663.2,1076.6z M1663,1076.4c0,0,0-0.1,0-0.2C1663,1076.3,1663,1076.3,1663,1076.4z"/>
					<path class="st3" d="M1667,1073.4c2.3-0.3,1.8-3.8-0.5-3.5C1664.3,1070.2,1664.8,1073.7,1667,1073.4z"/>
					<path class="st3" d="M1653.8,1079.4c-2.3,0.3-1.8,3.8,0.5,3.5C1656.6,1082.6,1656.1,1079.1,1653.8,1079.4z"/>
					<path class="st3" d="M1646.2,1081c-0.3-0.6-0.9-1.1-1.8-1c-1.3,0.2-1.7,1.5-1.3,2.5c0.3,0.7,0.9,1.2,1.8,1
						C1646.3,1083.3,1646.6,1082,1646.2,1081z"/>
					<path class="st3" d="M1661.6,1052.2c0.4-0.7,1.3-1.2,2.2-1.1c0,0,0.1,0,0.1,0c0.3-1.1-0.4-2.5-1.9-2.3
						C1660.2,1049.1,1660.2,1051.6,1661.6,1052.2z"/>
					<path class="st3" d="M1636.4,1058.3c2.3-0.3,1.8-3.8-0.5-3.5C1633.6,1055.1,1634.1,1058.6,1636.4,1058.3z"/>
					<path class="st3" d="M1636.7,1076.9c0.5-0.1,0.9-0.3,1.1-0.6c-0.4-0.1-0.7-0.2-1.1-0.5c-0.8-0.7-0.9-1.6-0.6-2.3
						C1633.9,1073.7,1634.4,1077.2,1636.7,1076.9z"/>
					<path class="st3" d="M1674.1,1062.5c0,0.2-0.1,0.4-0.1,0.5c-0.3,0.9-1.3,1.5-2.3,1.4c0.2,0.8,0.8,1.5,1.9,1.4
						C1675.5,1065.6,1675.5,1063.2,1674.1,1062.5z"/>
					<path class="st3" d="M1630.2,1068.5c2.3-0.3,1.8-3.8-0.5-3.5C1627.4,1065.3,1627.9,1068.8,1630.2,1068.5z"/>
					<path class="st3" d="M1634.9,1044.3c-0.7,1.1,0,3,1.7,2.8c1.8-0.3,1.8-2.5,0.7-3.2C1636.7,1044.4,1635.8,1044.7,1634.9,1044.3z
						"/>
					<path class="st3" d="M1698.4,1046.6c2.2,1.1,4-2.3,1.8-3.5C1698,1042,1696.2,1045.4,1698.4,1046.6z"/>
					<path class="st3" d="M1693.9,1057.6c0.1,0.1,0.3,0.3,0.5,0.4c2.2,1.1,4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.2
						C1693.8,1054,1692.6,1056.4,1693.9,1057.6z"/>
					<path class="st3" d="M1702.6,1054.1c2.2,1.1,4-2.3,1.8-3.5C1702.2,1049.5,1700.4,1053,1702.6,1054.1z"/>
					<path class="st3" d="M1709.1,1060.2c-1.1-0.6-2.1,0-2.6,0.9c-0.4,0.9-0.3,2,0.8,2.6c0.1,0.1,0.3,0.1,0.4,0.2
						C1709.8,1064.4,1711.2,1061.3,1709.1,1060.2z"/>
					<path class="st3" d="M1708.2,1048.1c0,0.6,0.3,1.2,1,1.6c2.2,1.1,4-2.3,1.8-3.5c-0.1-0.1-0.3-0.1-0.4-0.2
						C1709.2,1045.6,1708.2,1046.9,1708.2,1048.1z"/>
					<path class="st3" d="M1690.7,1050c0.5,0.2,0.9,0.3,1.3,0.2c-0.2-0.4-0.3-0.9-0.3-1.3c0.1-0.8,0.6-1.5,1.4-1.8
						c-0.2-0.2-0.4-0.3-0.6-0.5C1690.2,1045.4,1688.4,1048.8,1690.7,1050z"/>
					<path class="st3" d="M1716.5,1057.8c0.2-1-0.4-2.1-1.5-2.3c-0.4-0.1-0.8-0.2-1.1-0.2c-0.1,0-0.2,0-0.3-0.1
						c-1-0.2-2.1,0.5-2.3,1.5c-0.2,1,0.4,2.1,1.5,2.3c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2
						C1715.2,1059.5,1716.4,1058.9,1716.5,1057.8z"/>
					<path class="st3" d="M1701.8,1059.5c-0.4-0.8-1.4-1.2-2.3-0.9c-0.3,0.1-0.6,0.3-0.8,0.6c-0.5,0.5-0.7,1.2-0.4,1.9
						c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.5,1.1,0.6C1699.3,1061.6,1700.2,1059.7,1701.8,1059.5z"/>
					<path class="st3" d="M1686.4,1061.6c1,0.2,2.1-0.4,2.3-1.5c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.4,0-0.7-0.1-1.1c-0.3,0-0.6,0-1-0.2
						c-0.6-0.2-0.9-0.6-1.2-1c-0.7,0.1-1.4,0.7-1.5,1.5c-0.1,0.5-0.2,0.9-0.3,1.4C1684.7,1060.3,1685.3,1061.4,1686.4,1061.6z"/>
					<path class="st3" d="M1708.8,1036.7c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.5-1,0.7c-0.8,0.6-1.2,1.8-0.6,2.7
						c0.6,0.8,1.8,1.2,2.7,0.6c0.4-0.3,0.7-0.5,1.1-0.8c0.7-0.5,1.1-1.6,0.7-2.4c0-0.1-0.1-0.2-0.1-0.3
						C1710.9,1036.5,1709.7,1036.1,1708.8,1036.7z"/>
					<path class="st3" d="M1690.6,1063.9c1.7,0.7,1.7,2.6,0.8,3.7c0.4,0.2,0.7,0.5,1.1,0.7c0.6,0.4,1.4,0.3,2-0.1
						c0.3-0.2,0.5-0.4,0.6-0.7c0.4-0.9,0.2-1.7-0.5-2.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.4-1.4-0.8-2-1.3c-0.5-0.3-1.2-0.3-1.7,0
						c-0.2,0.1-0.3,0.2-0.5,0.3C1690.2,1063.8,1690.4,1063.8,1690.6,1063.9z"/>
					<path class="st3" d="M1701.8,1073.1c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.4c0.4-0.6,0.3-1.4-0.2-2
						c-0.2-0.3-0.4-0.5-0.7-0.6c-1-0.5-2-0.1-2.6,0.8c-0.6,0.9-0.8,2.1-0.7,3.2c0.2,1,0.9,2,2.1,1.8c1-0.1,2-1,1.8-2.1
						c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3C1701.8,1073.3,1701.8,1073.2,1701.8,1073.1z M1701.8,1073.3c0-0.1,0-0.2,0-0.2
						C1701.8,1073.2,1701.8,1073.3,1701.8,1073.3z M1701.8,1073c0,0,0-0.1,0.1-0.2C1701.9,1072.9,1701.9,1072.9,1701.8,1073z"/>
					<path class="st3" d="M1709.1,1069.4c-2.2-1.1-4,2.3-1.8,3.5C1709.6,1074,1711.4,1070.6,1709.1,1069.4z"/>
					<path class="st3" d="M1689.9,1073.3c0.8,0.4,1.5,0.2,2-0.2c1-0.8,1.2-2.5-0.3-3.3c-1.7-0.9-3.1,0.9-2.8,2.3
						C1689,1072.6,1689.3,1073,1689.9,1073.3z"/>
					<path class="st3" d="M1681.5,1065.3c0.9,0.3,1.6,1.5,1.4,2.5c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.6-0.7,0.7-1.1
						c0.2-0.8,0-1.6-1-2.1c-1.1-0.5-2,0-2.5,0.8C1680.7,1065.1,1681.1,1065.1,1681.5,1065.3z"/>
					<path class="st3" d="M1716.8,1051.1c1.1,0.6,2.2,0,2.6-1c0.4-0.9,0.3-2-0.8-2.5c-0.8-0.4-1.5-0.2-2,0.2
						C1715.6,1048.6,1715.3,1050.3,1716.8,1051.1z"/>
					<path class="st3" d="M1691.2,1039.7c1.1-0.7,1.5-2.6,0-3.4c-0.7-0.4-1.4-0.3-1.9,0.1c0.3,0,0.5,0,0.8,0.2
						C1691.6,1037.1,1691.8,1038.6,1691.2,1039.7z"/>
					<path class="st3" d="M1676.9,1055.3c0-0.1-0.1-0.2-0.1-0.3c0,0.4,0.2,0.8,0.5,1.1c-0.1-0.1-0.1-0.2-0.2-0.3
						C1677.1,1055.7,1677,1055.5,1676.9,1055.3z"/>
					<path class="st3" d="M1719.9,1066.9c-2.2-1.1-4,2.3-1.8,3.5C1720.3,1071.5,1722.1,1068,1719.9,1066.9z"/>
					<path class="st3" d="M1677.3,1044.9c2.2,1.1,4-2.3,1.8-3.5C1676.9,1040.3,1675.1,1043.8,1677.3,1044.9z"/>
					<path class="st3" d="M1675.5,1038.4c2.7,1.1,4.4-3,1.8-4.2h-1.9C1673.9,1035,1673.5,1037.6,1675.5,1038.4z"/>
					<path class="st3" d="M1673.8,1047.9c-2.7-1.1-4.4,3.2-1.7,4.3C1674.8,1053.2,1676.5,1049,1673.8,1047.9z"/>
					<path class="st3" d="M1681.3,1046.7c2.7,1.1,4.4-3.2,1.7-4.3C1680.2,1041.4,1678.6,1045.7,1681.3,1046.7z"/>
					<path class="st3" d="M1687.9,1057.4c0.4,0.1,0.7,0.2,1,0.2c2.1-0.1,3.1-3.5,0.7-4.4c-2.2-0.9-3.7,1.6-2.9,3.2
						C1686.9,1056.8,1687.3,1057.2,1687.9,1057.4z"/>
					<path class="st3" d="M1688.4,1040.8c1.3,0.5,2.3-0.2,2.8-1.1c0.5-1.1,0.4-2.6-1.1-3.1c-0.3-0.1-0.6-0.2-0.8-0.2
						C1687.1,1036.3,1686,1039.8,1688.4,1040.8z"/>
					<path class="st3" d="M1666.8,1043.3c2.7,1.1,4.4-3.2,1.7-4.3C1665.8,1037.9,1664.1,1042.2,1666.8,1043.3z"/>
					<path class="st3" d="M1691.7,1048.8c0,0.5,0.1,0.9,0.3,1.3c0.3,0.6,1,1.1,1.7,1.2c0.6,0.1,1.1,0.1,1.7,0.2
						c1.2,0.1,2.4-0.8,2.5-2c0.1-1.2-0.8-2.4-2-2.5c-0.6-0.1-1.1-0.1-1.7-0.2c-0.4,0-0.8,0-1.2,0.2
						C1692.3,1047.3,1691.7,1048,1691.7,1048.8z"/>
					<path class="st3" d="M1681.4,1054.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1.1-1.9-1.7-3-1.2c-1,0.5-1.6,1.6-1.3,2.8
						c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.2,0.3c0.6,0.9,1.8,1.4,2.9,0.9
						C1681.3,1056.6,1681.9,1055.3,1681.4,1054.1z"/>
					<path class="st3" d="M1661.6,1052.2c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.9,0.4,1.8,1.2,2.3
						c0.2,0.1,0.5,0.2,0.8,0.2c0.7,0,1.4-0.2,1.9-0.7c0.3-0.3,0.5-0.8,0.6-1.3c0.1-0.6,0.1-1.1,0.2-1.7c0.1-1.2-0.7-2.4-1.9-2.5
						c0,0-0.1,0-0.1,0C1662.9,1051,1662.1,1051.4,1661.6,1052.2z"/>
					<path class="st3" d="M1674,1063.1c0.1-0.2,0.1-0.4,0.1-0.5c0.1-1-0.4-1.9-1.4-2.4c-0.8-0.4-1.7-0.8-2.5-1.2
						c-1.1-0.5-2.6,0.2-3,1.3c-0.4,1.2,0.1,2.4,1.3,3c0.8,0.4,1.7,0.8,2.5,1.2c0.2,0.1,0.4,0.2,0.7,0.2
						C1672.7,1064.6,1673.7,1063.9,1674,1063.1z"/>
					<path class="st3" d="M1682.6,1069.7c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1
						c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.2-1-0.5-2.2-1.4-2.5c-0.4-0.1-0.8-0.2-1.2-0.1c-0.8,0.1-1.4,0.6-1.8,1.4
						c-0.6,1.1-0.7,2.6-0.4,3.8c0.3,1.2,1.3,2.2,2.6,1.9C1681.8,1072.1,1682.9,1071,1682.6,1069.7z M1682.5,1068.9c0,0,0-0.1,0-0.2
						C1682.5,1068.7,1682.5,1068.8,1682.5,1068.9z M1682.5,1069c0,0.1,0,0.2,0,0.2C1682.5,1069.2,1682.5,1069.1,1682.5,1069z"/>
					<path class="st3" d="M1691.4,1067.5c0.9-1.1,0.9-3-0.8-3.7c-0.2-0.1-0.3-0.1-0.5-0.1c-0.5-0.1-1,0-1.4,0.3
						c-1.4,0.9-1.8,3.3,0.2,4.1C1689.9,1068.5,1690.8,1068.2,1691.4,1067.5z"/>
					<path class="st3" d="M1671.6,1067.9c-0.1-0.6-0.5-1.2-1.3-1.5c-2.7-1.1-4.4,3.2-1.7,4.3c1.2,0.5,2.2-0.1,2.7-0.9
						C1671.6,1069.1,1671.7,1068.5,1671.6,1067.9z"/>
					<path class="st3" d="M1660.4,1064.2c0.1-0.1,0.1-0.2,0.2-0.3c0.4-1.1,0.1-2.3-1.2-2.9c-2.7-1.1-4.4,3.2-1.7,4.3
						C1658.8,1065.7,1659.9,1065.1,1660.4,1064.2z"/>
					<path class="st3" d="M1697.4,1041.6c0.1,0,0.2,0.1,0.3,0.1c2.5,0.6,4-3.3,1.4-4.3c-0.4-0.1-0.7-0.2-1.1-0.2
						C1696,1037.4,1695.1,1040.6,1697.4,1041.6z"/>
					<path class="st3" d="M1652.6,1052.5c0.1-1.4,1.6-2.7,3.1-2.1c0.1-0.9-0.3-1.8-1.4-2.2
						C1651.6,1047.1,1649.9,1051.4,1652.6,1052.5z"/>
					<path class="st3" d="M1704.2,1061.3c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.7-0.4
						c-0.4-0.1-0.7-0.2-1-0.2c-1.6,0.1-2.5,2.1-1.8,3.4c0.2,0.4,0.6,0.8,1.1,1c0.8,0.3,1.5,0.2,2-0.2
						C1703.9,1063.2,1704.3,1062.2,1704.2,1061.3z"/>
					<path class="st3" d="M1651.4,1037.3c0.2,0.6,0.1,1.2-0.1,1.7c2.4,0.4,3.7-3.4,1.1-4.4c-1.3-0.5-2.4,0.2-2.8,1.2
						C1650.4,1035.9,1651.1,1036.6,1651.4,1037.3z"/>
					<path class="st3" d="M1588.9,1077.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-1.2,0.1-2.1,1.7-1,2.7
						c0.7,0.7,1.6,0.5,2.2-0.1C1588.9,1078.4,1588.8,1077.9,1588.9,1077.4z"/>
					<path class="st3" d="M1583.5,1084.7c-1.5-1.4-3.6,1-2.1,2.3C1582.9,1088.4,1585,1086.1,1583.5,1084.7z"/>
					<path class="st3" d="M1590.6,1083.3c-1.4-0.9-3.2,1-2.1,2.3C1588.6,1084.5,1589.4,1083.4,1590.6,1083.3z"/>
					<path class="st3" d="M1592.4,1092c-1.5-1.4-3.6,1-2.1,2.3c0,0,0.1,0,0.1,0.1h1.9C1592.9,1093.8,1593.3,1092.7,1592.4,1092z"/>
					<path class="st3" d="M1594.7,1083.7c1.5,1.4,3.6-1,2.1-2.3c-0.4-0.4-0.9-0.5-1.3-0.4c-0.3,0.3-0.6,0.5-0.9,0.6
						C1594.1,1082.2,1594,1083,1594.7,1083.7z"/>
					<path class="st3" d="M1582.4,1079.7c0.4-0.6,0.5-1.4-0.2-2.1c-0.1-0.1-0.3-0.2-0.4-0.3
						C1582.5,1077.9,1582.7,1078.9,1582.4,1079.7z"/>
					<path class="st3" d="M1598,1089.5c-0.4-0.2-0.7-0.4-1.1-0.5c-0.7-0.4-1.8-0.1-2.1,0.7c-0.4,0.8-0.1,1.7,0.7,2.1
						c0.4,0.2,0.7,0.4,1.1,0.5c0.7,0.4,1.8,0.1,2.1-0.7C1599.1,1090.9,1598.8,1089.9,1598,1089.5z"/>
					<path class="st3" d="M1585.2,1088.6c-0.9,0.1-1.5,0.8-1.5,1.7c0,0.2,0,0.3,0,0.5c0,0.8,0.8,1.6,1.7,1.5
						c0.9-0.1,1.5-0.8,1.5-1.7c0-0.2,0-0.3,0-0.5C1586.8,1089.3,1586,1088.5,1585.2,1088.6z"/>
					<path class="st3" d="M1576.3,1084.3c-0.8-0.4-1.7-0.1-2.1,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.4,0.7-0.1,1.8,0.7,2.1
						c0.8,0.4,1.7,0.1,2.1-0.7c0.2-0.4,0.4-0.7,0.5-1.1C1577.3,1085.7,1577.1,1084.7,1576.3,1084.3z"/>
					<path class="st3" d="M1597.1,1073.5c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.1-1,2c0.3,0.8,1.2,1.4,2,1c0.3-0.1,0.7-0.3,1-0.4
						c0.8-0.3,1.3-1.1,1-2C1598.8,1073.7,1598,1073.2,1597.1,1073.5z"/>
					<path class="st3" d="M1579.8,1092.3c-0.4-0.5-0.9-0.9-1.3-1.4c-0.6-0.6-1.7-0.5-2.2,0.1c-0.6,0.7-0.5,1.6,0.1,2.2
						c0.4,0.4,0.7,0.8,1.1,1.1h2.6C1580.5,1093.7,1580.4,1092.9,1579.8,1092.3z"/>
					<path class="st3" d="M1568.8,1091.9c1.5,1.4,3.6-1,2.1-2.3C1569.4,1088.2,1567.3,1090.6,1568.8,1091.9z"/>
					<path class="st3" d="M1600.8,1083.8c-0.6,0.3-1.1,1.1-0.9,1.8c0.1-0.5,0.3-1,0.6-1.4C1600.5,1084.1,1600.6,1084,1600.8,1083.8z
						"/>
					<path class="st3" d="M1583.1,1070.8c0.1-0.5,0.2-1.1,0.3-1.6c-1.2-0.9-2.8,0.5-2.4,1.8c0.4,0.2,0.7,0.6,0.9,1
						c0.5,0.2,0.9,0.1,1.3-0.1C1583.1,1071.6,1583,1071.2,1583.1,1070.8z"/>
					<path class="st3" d="M1568.7,1082.4c1.4,1.3,3.4-0.7,2.3-2.1c-0.3,0.5-0.8,0.9-1.5,1c-0.5,0-0.9-0.1-1.3-0.3
						C1568.1,1081.5,1568.2,1082,1568.7,1082.4z"/>
					<path class="st3" d="M1572.1,1070.5c0,0.9-0.5,1.7-1.4,2c-0.1,0.1-0.2,0.1-0.4,0.1c0.1,0.2,0.2,0.4,0.4,0.6
						c1.5,1.4,3.6-1,2.1-2.3C1572.7,1070.6,1572.4,1070.5,1572.1,1070.5z"/>
					<path class="st3" d="M1589.2,1065.5c1.5,1.4,3.6-1,2.1-2.3C1589.9,1061.8,1587.7,1064.1,1589.2,1065.5z"/>
					<path class="st3" d="M1451,1079.3c-3-0.2-3.3,4.4-0.3,4.7C1453.7,1084.2,1454,1079.5,1451,1079.3z"/>
					<path class="st3" d="M1451.9,1093.8c-0.8-0.1-1.3,0.2-1.7,0.6h3.2C1453,1094,1452.5,1093.8,1451.9,1093.8z"/>
					<path class="st3" d="M1459.2,1085.7c-0.2,0-0.4,0-0.6,0c0,0.9-0.9,1.8-1.8,1.8c-0.3,1.3,0.4,2.7,2,2.9
						C1461.8,1090.5,1462.2,1085.9,1459.2,1085.7z"/>
					<path class="st3" d="M1468.8,1094c-0.6,0-1.1,0.1-1.4,0.4h2.2c0.1,0,0.1-0.1,0.2-0.1C1469.5,1094.1,1469.2,1094,1468.8,1094z"
						/>
					<path class="st3" d="M1464.3,1079.2c0.8,0.4,1.1,1.6,0.6,2.4c-0.2,0.3-0.3,0.5-0.5,0.8c2.5-0.3,2.6-4.4-0.2-4.6
						c-1.5-0.1-2.4,1.1-2.4,2.3c0-0.1,0.1-0.1,0.1-0.2C1462.4,1078.9,1463.5,1078.7,1464.3,1079.2z"/>
					<path class="st3" d="M1444,1086.7c-3-0.2-3.3,4.4-0.3,4.7C1446.7,1091.6,1447,1086.9,1444,1086.7z"/>
					<path class="st3" d="M1475.4,1086.9c-0.1,0.9-0.9,1.5-1.7,1.6c-0.9,0-1.7-0.9-1.7-1.8c0-0.1,0-0.3,0-0.4
						c-0.2,0-0.4,0.1-0.5,0.1c-1.2,0.3-2.1,1.5-1.8,2.8c0.3,1.2,1.5,2.1,2.8,1.8c0.6-0.1,1.1-0.2,1.7-0.4c1.2-0.3,2.1-1.5,1.8-2.8
						C1475.8,1087.5,1475.6,1087.2,1475.4,1086.9z"/>
					<path class="st3" d="M1457.2,1072.9c1,0.6,2.6,0.5,3.2-0.6c0.3-0.5,0.6-0.9,0.8-1.4c0.6-1,0.5-2.6-0.6-3.2
						c-1-0.6-2.6-0.5-3.2,0.6c-0.3,0.5-0.6,0.9-0.8,1.4C1455.9,1070.7,1456,1072.2,1457.2,1072.9z"/>
					<path class="st3" d="M1472.2,1080.1c0.2,0.1,0.5,0.2,0.7,0.2c3,0.2,3.3-4.4,0.3-4.7c-2.4-0.2-3.1,2.7-1.8,4
						C1471.7,1079.7,1471.9,1079.9,1472.2,1080.1C1472.2,1080.1,1472.2,1080.1,1472.2,1080.1z"/>
					<path class="st3" d="M1437.8,1076c-3-0.2-3.3,4.4-0.3,4.7C1440.5,1080.9,1440.8,1076.2,1437.8,1076z"/>
					<path class="st3" d="M1427,1087.4c-3-0.2-3.3,4.4-0.3,4.7C1429.6,1092.3,1430,1087.6,1427,1087.4z"/>
					<path class="st3" d="M1441.2,1061.6c-3-0.2-3.3,4.4-0.3,4.7C1443.9,1066.5,1444.2,1061.9,1441.2,1061.6z"/>
					<path class="st3" d="M1537.7,1076.7c0.1,0,0.1,0.1,0.2,0.2c0-0.4-0.2-0.8-0.6-1.1c-1.7-1.6-4.1,0.7-3,2.4
						C1534.6,1076.8,1536.2,1075.6,1537.7,1076.7z"/>
					<path class="st3" d="M1528.7,1086.7c0.4-0.6,1-0.9,1.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-1.8-1.7-4.5,1-2.7,2.8
						c0.3,0.3,0.7,0.5,1.1,0.5C1528.2,1088.1,1528.3,1087.4,1528.7,1086.7z"/>
					<path class="st3" d="M1538.9,1084.4c0-0.2,0-0.3,0-0.5c-1.8-1.3-4.2,1.3-2.5,3c1.2,1.1,2.7,0.4,3.2-0.8
						c-0.3-0.3-0.5-0.7-0.5-1.1C1539,1084.8,1538.9,1084.6,1538.9,1084.4z"/>
					<path class="st3" d="M1538.6,1094.4h1.8C1539.8,1094,1539.1,1094.1,1538.6,1094.4z"/>
					<path class="st3" d="M1543.9,1084.6c1.8,1.7,4.5-1,2.7-2.8C1544.9,1080,1542.1,1082.8,1543.9,1084.6z"/>
					<path class="st3" d="M1528.8,1076.7c-1.2-1.1-2.8-0.3-3.2,1c0.6-0.3,1.3-0.4,1.9-0.2c0.8,0.3,1.3,1,1.4,1.8
						c0.2-0.2,0.3-0.5,0.4-0.8C1528.9,1078,1528.7,1077.3,1528.8,1076.7z"/>
					<path class="st3" d="M1547.8,1091.9c-0.4-0.2-0.9-0.5-1.3-0.7c-0.9-0.5-2.2-0.2-2.6,0.8c-0.4,0.8-0.2,1.8,0.5,2.4h4.3
						C1549,1093.5,1548.7,1092.4,1547.8,1091.9z"/>
					<path class="st3" d="M1534,1092.8c0-0.2,0-0.4,0-0.6c0-0.2-0.1-0.5-0.1-0.7c-0.7,0.5-1.9,0.6-2.5,0c-0.2-0.2-0.5-0.4-0.7-0.6
						c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0.1,0.5c0.4,0.3,0.6,0.7,0.7,1h2.2
						C1533.7,1094,1534,1093.4,1534,1092.8z"/>
					<path class="st3" d="M1521.3,1084.7C1521.3,1084.7,1521.3,1084.6,1521.3,1084.7c-1-0.5-2.2-0.2-2.7,0.7
						c-0.1,0.2-0.2,0.3-0.3,0.5c0.7-0.7,1.7-0.9,2.7-0.2c0.7,0.5,0.9,1.1,0.9,1.7c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.3,0.2-0.5
						C1521.5,1086.4,1521.2,1085.5,1521.3,1084.7z"/>
					<path class="st3" d="M1547.6,1073.7c0.8,0.6,1,1.4,0.9,2.1c0.9-0.4,1.6-1.4,1.3-2.4c-0.3-1-1.4-1.7-2.4-1.4
						c-0.4,0.1-0.8,0.3-1.3,0.4c-0.7,0.2-1.3,0.9-1.4,1.7C1545.3,1073.3,1546.5,1072.9,1547.6,1073.7z"/>
					<path class="st3" d="M1521,1092.9c-0.4,0.5-0.6,1-0.5,1.5h4.6c-0.5-0.5-0.9-1-1.4-1.5C1523,1092.1,1521.6,1092.1,1521,1092.9z"
						/>
					<path class="st3" d="M1511.7,1093.8c1.8,1.7,4.5-1,2.7-2.8C1512.6,1089.2,1509.9,1092,1511.7,1093.8z"/>
					<path class="st3" d="M1551.1,1085.1c0.1,0,0.1,0.1,0.2,0.1c1.3,0.9,1.1,2.5,0.2,3.4c1.7,0.5,3.5-1.7,1.9-3.3
						C1552.7,1084.6,1551.8,1084.7,1551.1,1085.1z"/>
					<path class="st3" d="M1528,1069.3c1.8,1.7,4.5-1,2.7-2.8C1528.9,1064.8,1526.2,1067.6,1528,1069.3z"/>
					<path class="st3" d="M1513.3,1078.7c0.2,0.1,0.3,0.3,0.5,0.5c0.3,0.4,0.4,1,0.3,1.5c0.3,0.4,0.5,1,0.3,1.5c0.9-0.6,1.4-2,0.4-3
						C1514.2,1078.8,1513.7,1078.7,1513.3,1078.7z"/>
					<path class="st3" d="M1518.1,1069.9c0.2-0.6,0.1-1.3-0.5-1.9c-1.8-1.7-4.5,1-2.7,2.8c0.9,0.8,1.9,0.6,2.6,0
						C1517.6,1070.4,1517.8,1070.1,1518.1,1069.9z"/>
					<path class="st3" d="M1538.8,1058.7c1.1,1.2,0.3,2.8-0.9,3.3c1.8,1.8,4.5-1,2.7-2.8C1540,1058.7,1539.4,1058.6,1538.8,1058.7z"
						/>
					<path class="st3" d="M1479.1,1073.6c-1.3,0.1-2.1-1.2-2-2.3c-1.1,0.4-1.8,1.8-0.8,2.8
						C1477.3,1075.2,1478.7,1074.6,1479.1,1073.6z"/>
					<path class="st3" d="M1472.2,1080.1c-0.2-0.3-0.5-0.4-0.7-0.5c-1.5-0.5-3.1,1.5-1.8,2.8
						C1471.2,1084.1,1473.7,1081.8,1472.2,1080.1z"/>
					<path class="st3" d="M1480.2,1079.3c-1.5-1.6-4,0.8-2.5,2.4C1479.2,1083.3,1481.7,1080.9,1480.2,1079.3z"/>
					<path class="st3" d="M1478.8,1091c1.5,1.6,4-0.8,2.5-2.4C1479.8,1087.1,1477.3,1089.4,1478.8,1091z"/>
					<path class="st3" d="M1486.9,1077.4c-1.5-1.6-4,0.8-2.5,2.4C1486,1081.4,1488.5,1079,1486.9,1077.4z"/>
					<path class="st3" d="M1471.3,1072.4c-1.5-1.6-4,0.8-2.5,2.4C1470.4,1076.3,1472.9,1074,1471.3,1072.4z"/>
					<path class="st3" d="M1488.3,1088.6c0.3-0.8,0.1-1.7-0.7-2.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.5-1.9-0.2-2.4,0.6
						c-0.4,0.8-0.2,1.9,0.6,2.4c0.4,0.2,0.7,0.4,1.1,0.6c0.5,0.3,1,0.3,1.5,0.1C1487.5,1089.1,1487.9,1088.8,1488.3,1088.6z"/>
					<path class="st3" d="M1473.6,1088.4c0.9,0,1.7-0.7,1.7-1.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0-0.9-0.8-1.8-1.7-1.8
						c-0.9,0-1.7,0.7-1.8,1.7c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4C1471.9,1087.6,1472.7,1088.5,1473.6,1088.4z"/>
					<path class="st3" d="M1464.3,1082.6c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,0.2-1.9-0.6-2.4
						c-0.8-0.4-1.9-0.2-2.4,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.5,0.9c-0.5,0.8-0.2,1.9,0.6,2.4
						C1462.7,1083.7,1463.8,1083.5,1464.3,1082.6z"/>
					<path class="st3" d="M1487.5,1072.6c0.4-0.1,0.8-0.2,1.1-0.3c0.9-0.3,1.5-1.2,1.3-2.1c-0.1-0.4-0.3-0.7-0.6-0.9
						c-0.3,0.1-0.7,0-1.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.9,0.3-1.5,1.2-1.3,2.1
						C1485.7,1072.1,1486.6,1072.8,1487.5,1072.6z"/>
					<path class="st3" d="M1466.3,1086.5c-0.6-0.7-1.8-0.7-2.4-0.1c-0.7,0.7-0.7,1.7-0.1,2.4c0.4,0.5,0.9,1.1,1.3,1.6
						c0.6,0.7,1.8,0.7,2.4,0.1c0.7-0.7,0.7-1.7,0.1-2.4C1467.1,1087.6,1466.7,1087,1466.3,1086.5z"/>
					<path class="st3" d="M1469.7,1094.3c-0.1,0-0.1,0.1-0.2,0.1h2.3C1471.2,1093.8,1470.4,1093.8,1469.7,1094.3z"/>
					<path class="st3" d="M1463.9,1091.7c-1.5-1.6-4,0.8-2.5,2.4c0.1,0.1,0.3,0.2,0.4,0.3h1.5
						C1464.3,1093.9,1464.9,1092.6,1463.9,1091.7z"/>
					<path class="st3" d="M1458.6,1085.7c0-0.4-0.1-0.8-0.5-1.1c-1.5-1.6-4,0.8-2.5,2.4c0.4,0.4,0.8,0.5,1.2,0.5
						C1457.7,1087.4,1458.6,1086.6,1458.6,1085.7z"/>
					<path class="st3" d="M1491.6,1080.5c0.2-0.1,0.4-0.1,0.6-0.1c-1.5-0.6-3.2,1.4-1.9,2.8c0.1,0.1,0.2,0.2,0.4,0.3
						C1490.1,1082.5,1490.2,1080.9,1491.6,1080.5z"/>
					<path class="st3" d="M1473.3,1063.4c-1.5-1.6-4,0.8-2.5,2.4C1472.3,1067.4,1474.8,1065,1473.3,1063.4z"/>
					<path class="st3" d="M1458.7,1074.2c-1.5-1.6-4,0.8-2.5,2.4C1457.7,1078.2,1460.2,1075.8,1458.7,1074.2z"/>
					<path class="st3" d="M1461.6,1064.3c-1.5-1.6-4,0.8-2.5,2.4C1460.7,1068.3,1463.2,1065.9,1461.6,1064.3z"/>
					<path class="st3" d="M1482.3,1057.2c-1.5-1.6-4,0.8-2.5,2.4C1481.3,1061.2,1483.8,1058.8,1482.3,1057.2z"/>
					<path class="st3" d="M1541.5,1068.2c1.7,1.2,3.5-0.4,3.5-1.9c-0.6-0.3-1-1-1-1.7C1541.7,1063,1539.2,1066.5,1541.5,1068.2z"/>
					<path class="st3" d="M1537.9,1076.8c-0.1-0.1-0.1-0.1-0.2-0.2c-1.5-1.1-3.1,0.1-3.4,1.4c-0.2,0.8,0,1.6,0.9,2.2
						C1537.4,1081.9,1539.8,1078.6,1537.9,1076.8z"/>
					<path class="st3" d="M1547.6,1073.7c-1.1-0.8-2.3-0.4-2.9,0.5c-0.7,0.9-0.8,2.3,0.4,3.2c1.5,1.1,3.2-0.1,3.4-1.5
						C1548.6,1075.1,1548.4,1074.3,1547.6,1073.7z"/>
					<path class="st3" d="M1551.3,1085.2c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.3-4.6,2.1-2.4,3.7c1,0.7,2.1,0.4,2.8-0.3
						C1552.4,1087.7,1552.6,1086.2,1551.3,1085.2z"/>
					<path class="st3" d="M1555.7,1069.7c-2.3-1.6-4.9,2-2.6,3.6C1555.4,1075,1558,1071.4,1555.7,1069.7z"/>
					<path class="st3" d="M1534.8,1067.1c-2.3-1.6-4.9,2-2.6,3.6C1534.5,1072.4,1537.1,1068.8,1534.8,1067.1z"/>
					<path class="st3" d="M1558.7,1080.9c-0.5-0.2-1-0.4-1.6-0.5c-1.1-0.4-2.5,0.2-2.8,1.3c-0.4,1.1,0.2,2.4,1.3,2.8
						c0.5,0.2,1,0.4,1.6,0.5c1.1,0.4,2.5-0.2,2.8-1.3C1560.4,1082.6,1559.9,1081.3,1558.7,1080.9z"/>
					<path class="st3" d="M1543.3,1083.7c-0.2-1.1-1.4-2.1-2.6-1.8c-1,0.2-1.8,1.1-1.8,2.1c0,0.1,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6
						c0.1,0.4,0.3,0.8,0.5,1.1c0.5,0.6,1.3,0.9,2,0.7c1.2-0.3,2-1.3,1.8-2.6C1543.3,1084.1,1543.3,1083.9,1543.3,1083.7z"/>
					<path class="st3" d="M1527.6,1077.4c-0.7-0.2-1.4-0.1-1.9,0.2c-0.4,0.2-0.7,0.6-0.9,1.1c-0.2,0.5-0.4,1-0.5,1.6
						c-0.4,1.1,0.2,2.5,1.3,2.8c1.1,0.4,2.4-0.2,2.8-1.3c0.2-0.5,0.4-1,0.5-1.6c0.1-0.3,0.1-0.7,0.1-1
						C1528.9,1078.4,1528.4,1077.7,1527.6,1077.4z"/>
					<path class="st3" d="M1553.7,1062.3c-0.2,0.1-0.5,0.2-0.9,0.3c-0.2,0-0.3,0-0.4,0c0.2,0.3,0.4,0.5,0.6,0.7
						C1553.1,1062.9,1553.4,1062.6,1553.7,1062.3z"/>
					<path class="st3" d="M1554,1062.1c0.6-0.4,1.5-0.4,2.2,0.2c0.2,0.1,0.3,0.3,0.4,0.4c1-0.6,1.6-1.8,1.1-2.9
						c-0.5-1-1.8-1.7-2.9-1.1c-0.2,0.1-0.4,0.2-0.6,0.3C1554.8,1059.9,1554.8,1061.3,1554,1062.1z"/>
					<path class="st3" d="M1533.9,1091.5c0.2-0.1,0.4-0.3,0.5-0.5c0.7-1.1,0.4-2.3-0.5-3.1c-0.7-0.6-1.4-1.2-2.1-1.7
						c-0.4-0.3-0.9-0.4-1.4-0.4c-0.7,0-1.4,0.4-1.7,0.9c-0.4,0.7-0.5,1.4-0.2,2c0.1,0.4,0.4,0.8,0.8,1.1c0.5,0.4,0.9,0.8,1.4,1.2
						c0.2,0.2,0.5,0.4,0.7,0.6C1532,1092.1,1533.1,1092,1533.9,1091.5z"/>
					<path class="st3" d="M1530.2,1093.3C1530.2,1093.3,1530.2,1093.3,1530.2,1093.3c-1.5-1.1-2.9-0.2-3.4,1h4.2
						C1530.8,1094,1530.6,1093.6,1530.2,1093.3z"/>
					<path class="st3" d="M1521,1085.7c-1-0.7-2-0.4-2.7,0.2c-0.9,0.9-1.2,2.5,0.1,3.4c1.7,1.2,3.5-0.3,3.5-1.9
						C1522,1086.8,1521.7,1086.2,1521,1085.7z"/>
					<path class="st3" d="M1563.9,1072.5c-2.3-1.6-4.9,2-2.6,3.6C1563.7,1077.8,1566.3,1074.2,1563.9,1072.5z"/>
					<path class="st3" d="M1532.5,1059c2.3,1.6,4.9-2,2.6-3.6C1532.7,1053.7,1530.1,1057.4,1532.5,1059z"/>
					<path class="st3" d="M1519.9,1073.3c-0.4,0.2-0.9,0.2-1.4,0c-0.6-0.2-0.9-0.6-1.1-1.1c-1.6,0.4-2.5,2.7-0.8,4
						C1518.7,1077.6,1520.9,1075,1519.9,1073.3z"/>
					<path class="st3" d="M1559.9,1094.4h2.4C1561.4,1093.8,1560.5,1093.9,1559.9,1094.4z"/>
					<path class="st3" d="M1519,1058.9c-1.7,0.3-2.8,2.7-1,4c2.3,1.6,4.9-2,2.6-3.6c-0.2-0.1-0.3-0.2-0.5-0.3
						C1519.8,1059.1,1519.4,1059.1,1519,1058.9z"/>
					<path class="st3" d="M1542.3,1049c1.4,1,2.9,0,3.4-1.2c-0.5,0.1-1-0.1-1.4-0.5c-0.8-0.7-0.8-1.6-0.5-2.3
						C1541.8,1044.9,1540.3,1047.7,1542.3,1049z"/>
					<path class="st3" d="M1601.3,1050.4c-2.8-1.3-4.8,3-2,4.3C1602,1056,1604.1,1051.7,1601.3,1050.4z"/>
					<path class="st3" d="M1594.1,1068.4c0-1,0.6-2,1.8-2.1c1-0.1,1.6,0.4,2,1c0.4-1,0.1-2.3-1.2-2.9
						C1594.2,1063.3,1592.3,1066.8,1594.1,1068.4z"/>
					<path class="st3" d="M1606.7,1059.5c-0.1-0.1-0.2-0.1-0.3-0.1c0.3,1.1-0.3,2.5-1.8,2.6c-0.5,0-0.9-0.1-1.3-0.3
						c0,0.8,0.4,1.6,1.3,2.1C1607.4,1065.1,1609.5,1060.8,1606.7,1059.5z"/>
					<path class="st3" d="M1610.8,1075.4c2.8,1.3,4.8-3,2-4.3C1610.1,1069.7,1608,1074,1610.8,1075.4z"/>
					<path class="st3" d="M1614.6,1053.7c-2.8-1.3-4.8,3-2,4.3c1.6,0.7,2.9-0.3,3.3-1.6c-0.4-0.6-0.5-1.3-0.3-1.9
						C1615.3,1054.2,1615,1053.9,1614.6,1053.7z"/>
					<path class="st3" d="M1591.8,1054.9c-2.5-1.2-4.5,2.3-2.7,3.9c0.3-0.4,0.8-0.7,1.4-0.8c0.8-0.1,1.4,0.2,1.8,0.7
						C1593.4,1057.7,1593.6,1055.7,1591.8,1054.9z"/>
					<path class="st3" d="M1621.6,1066.3c-0.3-0.3-0.7-0.7-1-1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.6-0.1-1.2-0.2-1.8-0.3
						c-1.2-0.2-2.6,0.7-2.8,1.9c-0.1,1,0.3,2,1.1,2.5c0.4-1.4,2.2-2.6,3.6-1.3c0.5,0.4,0.7,0.9,0.7,1.4c0.5-0.4,0.9-0.9,0.9-1.5
						C1621.9,1067.3,1621.8,1066.7,1621.6,1066.3C1621.6,1066.3,1621.6,1066.3,1621.6,1066.3z"/>
					<path class="st3" d="M1602.8,1074.6c1.2-0.5,1.9-1.8,1.4-3.1c-0.1-0.2-0.2-0.4-0.2-0.7c-0.4-1.2-1.9-1.9-3.1-1.4
						c-1.2,0.5-1.9,1.8-1.4,3.1c0.1,0.2,0.2,0.4,0.2,0.7C1600.1,1074.3,1601.6,1075.1,1602.8,1074.6z"/>
					<path class="st3" d="M1587.8,1071.7c0.1-0.6,0.2-1.2,0.3-1.8c0.2-1.2-0.7-2.6-1.9-2.8c-1.3-0.2-2.6,0.6-2.8,1.9
						c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.4,0,0.7,0.1,1.1c0.2,0.9,0.9,1.6,1.9,1.7c0.9,0.1,1.8-0.3,2.3-0.9
						c0.1-0.2,0.2-0.4,0.3-0.6C1587.7,1072,1587.8,1071.8,1587.8,1071.7z"/>
					<path class="st3" d="M1611.6,1045.9c0.5,0.5,0.7,1,0.7,1.6c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.3,0.9-0.7,1.3-1
						c1-0.8,1.4-2.3,0.6-3.3c-0.7-1-2.3-1.4-3.3-0.6c-0.4,0.3-0.9,0.7-1.3,1c-0.7,0.6-1.1,1.5-1,2.4
						C1609.9,1045.2,1610.8,1045.2,1611.6,1045.9z"/>
					<path class="st3" d="M1595.5,1081c0.2-0.2,0.4-0.4,0.5-0.6c0.5-1.3,0-2.5-1.1-3.2c-0.9-0.5-1.7-1-2.6-1.5
						c0,0.7-0.4,1.4-0.9,1.7c-0.8,0.6-1.7,0.5-2.5-0.1c-0.1,0.5,0,1.1,0.2,1.5c0.2,0.4,0.6,0.8,1.1,1.1c0.9,0.5,1.7,1,2.6,1.5
						c0.5,0.3,1.2,0.3,1.8,0.1C1594.9,1081.4,1595.3,1081.2,1595.5,1081z"/>
					<path class="st3" d="M1603.6,1083.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.9-0.2-1.7,0.1-2.3,0.8c-0.1,0.1-0.2,0.3-0.3,0.5
						c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.9-0.3,1.8-0.2,2.6c0.2,1.2,1.2,2.4,2.6,2.2c1.2-0.2,2.4-1.3,2.2-2.6c0-0.1-0.1-0.3-0.1-0.5
						c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.3,0.2-0.4c0.2-0.3,0.2-0.6,0.2-1
						C1605,1084.5,1604.4,1083.5,1603.6,1083.2z M1604.4,1087.4c0-0.1,0-0.2,0-0.2C1604.4,1087.2,1604.4,1087.3,1604.4,1087.4z
						 M1604.4,1087c0,0,0-0.1,0.1-0.2C1604.5,1086.8,1604.5,1086.9,1604.4,1087z"/>
					<path class="st3" d="M1613.2,1082.3c-2.8-1.3-4.8,3-2,4.3C1614,1087.9,1616,1083.6,1613.2,1082.3z"/>
					<path class="st3" d="M1591.8,1083.5c-0.4-0.2-0.9-0.3-1.2-0.2c-1.2,0.1-2,1.2-2.1,2.3c-0.1,0.9,0.3,1.8,1.3,2.3
						C1592.5,1089.2,1594.6,1084.8,1591.8,1083.5z"/>
					<path class="st3" d="M1581.4,1080.9c-0.2,0.1-0.5,0.2-0.7,0.2c-2.6,0.2-3-3.5-0.8-4.1c-2.4-0.3-3.8,3.4-1.3,4.5
						C1579.8,1082.1,1580.8,1081.7,1581.4,1080.9z"/>
					<path class="st3" d="M1621.9,1059.5c2.8,1.3,4.8-3,2-4.3C1621.1,1053.8,1619.1,1058.2,1621.9,1059.5z"/>
					<path class="st3" d="M1587.9,1046.7c1.6,0.7,2.9-0.3,3.3-1.6c-1-0.4-1.4-1.6-1.1-2.6c-0.1,0-0.1-0.1-0.2-0.1
						C1587.2,1041,1585.1,1045.3,1587.9,1046.7z"/>
					<path class="st3" d="M1574.1,1063.7c-1.3,1-1.6,3.2,0.3,4.1c2.5,1.2,4.4-2.2,2.7-3.8c-0.3,0.3-0.7,0.4-1.2,0.5
						C1575.1,1064.6,1574.5,1064.2,1574.1,1063.7z"/>
					<path class="st3" d="M1626.3,1078.7c-1.8-0.8-3.2,0.6-3.4,2.1c-0.1,0.9,0.3,1.8,1.3,2.2c0.4,0.2,0.7,0.2,1,0.2
						C1627.4,1083.3,1628.7,1079.9,1626.3,1078.7z"/>
					<path class="st3" d="M1573,1051.8c-0.4,0-0.7,0-1-0.2c0,0.7,0.4,1.5,1.3,1.9c2.8,1.3,4.8-3,2-4.3c-0.2-0.1-0.4-0.2-0.6-0.2
						C1575.1,1050.2,1574.5,1051.7,1573,1051.8z"/>
					<path class="st3" d="M1598.5,1034.2h-2C1597.2,1034.6,1597.9,1034.5,1598.5,1034.2z"/>
					<path class="st3" d="M1634.9,1044.3c0.9,0.4,1.8,0.1,2.4-0.5c0.8-0.8,1.1-2.2,0-3.2c-2.2-1.9-5.1,1.4-3,3.3
						C1634.5,1044.1,1634.7,1044.2,1634.9,1044.3z"/>
					<path class="st3" d="M1626.6,1055.4c2.2,1.9,5.1-1.4,3-3.3C1627.4,1050.2,1624.4,1053.5,1626.6,1055.4z"/>
					<path class="st3" d="M1636.8,1053.5c2.2,1.9,5.1-1.4,3-3.3C1637.7,1048.3,1634.7,1051.6,1636.8,1053.5z"/>
					<path class="st3" d="M1639.3,1065.5c1.1,1,2.4,0.6,3.1-0.3c0.7-0.8,0.9-2.1-0.2-3.1c-0.2-0.2-0.3-0.3-0.5-0.3
						C1639.7,1060.8,1637.3,1063.7,1639.3,1065.5z"/>
					<path class="st3" d="M1649.1,1048.9c0.1-0.5,0-1.1-0.5-1.6c-0.1-0.1-0.2-0.2-0.3-0.3c-2.2-1.9-5.1,1.4-3,3.3
						C1646.9,1051.8,1648.8,1050.5,1649.1,1048.9z"/>
					<path class="st3" d="M1627.7,1042.2c-2.2-1.9-5.1,1.4-3,3.3C1626.9,1047.4,1629.8,1044.1,1627.7,1042.2z"/>
					<path class="st3" d="M1651.2,1061.6c0.3-0.7,0.3-1.5-0.1-2.1c-0.2-0.4-0.5-0.7-0.9-0.9c-0.5-0.2-1-0.5-1.5-0.7
						c-0.2-0.1-0.5-0.2-0.7-0.2c-0.8-0.1-1.6,0.2-2.1,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,1.1-0.1,2.5,1,3c0.5,0.2,1,0.5,1.5,0.7
						c0.4,0.2,0.9,0.3,1.3,0.2C1650.2,1062.7,1650.9,1062.2,1651.2,1061.6z"/>
					<path class="st3" d="M1630,1060.6c0.1,1.2,1.2,2.2,2.4,2.1c1.2-0.1,2.2-1.1,2.1-2.4c0-0.2,0-0.4,0-0.6
						c-0.1-1.2-1.2-2.2-2.4-2.1c-1.2,0.1-2.2,1.1-2.1,2.4C1629.9,1060.2,1630,1060.4,1630,1060.6z"/>
					<path class="st3" d="M1615.8,1056.4c0.2,0.3,0.5,0.6,0.9,0.8c1.1,0.5,2.5,0.1,3-1c0.2-0.5,0.5-1,0.7-1.5c0.5-1.1,0.1-2.5-1-3
						c-1.1-0.5-2.5-0.1-3,1c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.1-0.1,0.2-0.1,0.3C1615.3,1055.2,1615.4,1055.9,1615.8,1056.4z"/>
					<path class="st3" d="M1648.6,1040.7c0.5-0.2,1-0.4,1.4-0.6c0.5-0.2,1-0.6,1.3-1.1c0.3-0.5,0.4-1.1,0.1-1.7
						c-0.3-0.8-1-1.4-1.8-1.5c-0.3,0-0.7,0-1,0.1c-0.5,0.2-1,0.4-1.4,0.6c-1.1,0.4-1.9,1.6-1.4,2.8
						C1646.1,1040.4,1647.4,1041.2,1648.6,1040.7z"/>
					<path class="st3" d="M1621.6,1066.3c0.8,0.8,2.4,0.6,3.1-0.2c0.8-1,0.7-2.3-0.2-3.2c-0.6-0.7-1.3-1.3-1.9-2
						c-0.8-0.8-2.4-0.7-3.2,0.2c-0.8,1-0.7,2.3,0.2,3.2c0.3,0.3,0.6,0.6,0.9,0.9C1620.9,1065.6,1621.2,1065.9,1621.6,1066.3
						C1621.6,1066.3,1621.6,1066.3,1621.6,1066.3z"/>
					<path class="st3" d="M1626,1074.2c-0.1,1.2,0.5,2.4,1.8,2.6c0.6,0.1,1.3-0.1,1.8-0.4c0.5-0.3,0.8-0.8,0.9-1.3
						c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.1
						c0.9-0.8,0.7-2.4-0.2-3.2c-1-0.8-2.2-0.7-3.2,0.2C1626.8,1071.6,1626.1,1072.9,1626,1074.2z M1630.4,1074.6
						c0-0.1,0-0.2,0.1-0.2C1630.5,1074.5,1630.5,1074.5,1630.4,1074.6z M1630.6,1074.3c0,0,0.1-0.1,0.1-0.2
						C1630.7,1074.1,1630.6,1074.2,1630.6,1074.3z"/>
					<path class="st3" d="M1636.7,1075.7c0.3,0.3,0.7,0.5,1.1,0.5c1.9,0.3,3.7-2.3,1.9-3.9c-1.4-1.2-3.1-0.3-3.6,1
						C1635.8,1074.1,1635.9,1075,1636.7,1075.7z"/>
					<path class="st3" d="M1620.2,1067.8c-1.5-1.3-3.3-0.2-3.6,1.3c-0.2,0.7,0,1.5,0.7,2.1c1.7,1.5,3.8-0.2,3.7-1.9
						C1620.9,1068.8,1620.6,1068.3,1620.2,1067.8z"/>
					<path class="st3" d="M1611.9,1059.3c-2.2-1.9-5.1,1.4-3,3.3C1611.1,1064.5,1614,1061.2,1611.9,1059.3z"/>
					<path class="st3" d="M1652.6,1052.5c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,0.9,0.5,1.3,0.6c1.3,0.1,2.5-1.3,2.4-2.6
						c-0.1-0.5-0.3-0.9-0.7-1.3c-0.2-0.2-0.4-0.3-0.7-0.4C1654.2,1049.8,1652.7,1051.1,1652.6,1052.5z"/>
					<path class="st3" d="M1628.3,1034.2h-1.2C1627.5,1034.4,1627.9,1034.3,1628.3,1034.2z"/>
					<path class="st3" d="M1612.3,1047.4c0-0.5-0.2-1.1-0.7-1.6c-0.8-0.7-1.7-0.7-2.4-0.3c-1.2,0.7-1.9,2.4-0.6,3.7
						C1610.2,1050.7,1612.3,1049.1,1612.3,1047.4z"/>
					<path class="st3" d="M1653,1074.2c0-0.6-0.2-1.2-0.7-1.7c-2.2-1.9-5.1,1.4-3,3.3c0,0,0,0,0.1,0
						C1651,1077.3,1653,1075.8,1653,1074.2z"/>
					<path class="st3" d="M1611.4,1036.1c1.7,1.5,3.8-0.2,3.7-1.9h-4.4C1610.5,1034.9,1610.7,1035.6,1611.4,1036.1z"/>
					<path class="st3" d="M1572,1051.6c0.3,0.1,0.6,0.2,1,0.2c1.6-0.1,2.1-1.6,1.7-2.8c-0.3-0.8-1-1.5-2.1-1.4
						C1570.3,1047.8,1570.2,1050.9,1572,1051.6z"/>
					<path class="st3" d="M1575.5,1060.3c-1.9,0.2-2.3,2.2-1.4,3.4c0.4,0.5,1,0.9,1.8,0.8c0.5,0,0.9-0.2,1.2-0.5
						C1578.4,1062.8,1577.7,1060.1,1575.5,1060.3z"/>
					<path class="st3" d="M1581.2,1056.2c2.7-0.3,2.3-4.5-0.4-4.2C1578.1,1052.3,1578.5,1056.5,1581.2,1056.2z"/>
					<path class="st3" d="M1590.6,1058c-0.7,0.1-1.1,0.4-1.4,0.8c-0.9,1.3-0.2,3.6,1.8,3.4c1.9-0.2,2.3-2.3,1.4-3.4
						C1592,1058.3,1591.4,1057.9,1590.6,1058z"/>
					<path class="st3" d="M1584.5,1048.3c2.7-0.3,2.3-4.5-0.4-4.2C1581.4,1044.4,1581.8,1048.6,1584.5,1048.3z"/>
					<path class="st3" d="M1567.9,1059.4c2.7-0.3,2.3-4.5-0.4-4.2C1564.8,1055.5,1565.2,1059.7,1567.9,1059.4z"/>
					<path class="st3" d="M1593.2,1050.3c-0.5,0.2-1,0.4-1.5,0.6c-1,0.4-1.7,1.7-1.2,2.7c0.4,1,1.6,1.7,2.7,1.2
						c0.5-0.2,1-0.4,1.5-0.6c1-0.4,1.7-1.7,1.2-2.7C1595.5,1050.4,1594.3,1049.8,1593.2,1050.3z"/>
					<path class="st3" d="M1583.8,1061.9c-0.9-0.7-2.3-0.6-3,0.3c-0.7,0.9-0.6,2.2,0.3,3c0.2,0.1,0.3,0.3,0.5,0.4
						c0.9,0.7,2.3,0.6,3-0.3c0.7-0.9,0.6-2.2-0.3-3C1584.2,1062.1,1584,1062,1583.8,1061.9z"/>
					<path class="st3" d="M1572.1,1070.5c0-0.2,0-0.5-0.1-0.7c-0.2-0.5-0.4-1-0.6-1.5c-0.4-1-1.7-1.7-2.7-1.2
						c-1,0.4-1.7,1.6-1.2,2.7c0.2,0.5,0.4,1,0.6,1.5c0.4,0.9,1.4,1.5,2.4,1.3c0.1,0,0.2-0.1,0.4-0.1
						C1571.5,1072.1,1572.1,1071.3,1572.1,1070.5z"/>
					<path class="st3" d="M1577.1,1040.9c1,0.4,2.4,0.1,2.8-1c0.2-0.4,0.4-0.9,0.5-1.3c0.4-1,0.1-2.3-1-2.8c-1-0.4-2.4-0.1-2.8,1
						c-0.2,0.4-0.4,0.9-0.5,1.3C1575.7,1039.2,1576,1040.5,1577.1,1040.9z"/>
					<path class="st3" d="M1580.9,1071c-0.3-0.2-0.8-0.3-1.2-0.3c-0.9,0-1.7,0-2.6,0c-1.1,0-2,1.2-1.9,2.3c0.2,1.2,1.1,1.9,2.3,1.9
						c0.9,0,1.7,0,2.6,0c1.1,0,2-1.2,1.9-2.3c0-0.2-0.1-0.4-0.2-0.6C1581.6,1071.6,1581.3,1071.2,1580.9,1071z"/>
					<path class="st3" d="M1588.9,1077.4c0.7,0.5,1.7,0.7,2.5,0.1c0.5-0.4,0.9-1.1,0.9-1.8c0-0.4-0.1-0.8-0.4-1.2
						c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.5c0-1.1-1.2-2-2.3-1.9
						c-0.6,0.1-1.1,0.4-1.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.9,0.3,1.8,0.8,2.6c0.1,0.2,0.3,0.5,0.5,0.7
						C1588.6,1077.1,1588.7,1077.3,1588.9,1077.4z M1591.6,1074.2c-0.1-0.1-0.1-0.1-0.1-0.2
						C1591.5,1074.1,1591.5,1074.1,1591.6,1074.2z M1591.4,1073.9c0,0,0-0.1,0-0.2C1591.4,1073.7,1591.4,1073.8,1591.4,1073.9z"/>
					<path class="st3" d="M1596,1066.4c-1.3,0.1-1.8,1.1-1.8,2.1c0,1.1,0.8,2.3,2.2,2.1c1.7-0.2,2.2-2,1.6-3.2
						C1597.6,1066.7,1596.9,1066.3,1596,1066.4z"/>
					<path class="st3" d="M1580.7,1081.1c0.3,0,0.5-0.1,0.7-0.2c0.5-0.2,0.8-0.7,1-1.2c0.2-0.8,0-1.8-0.6-2.3
						c-0.4-0.3-0.9-0.5-1.5-0.5c-0.1,0-0.2,0-0.4,0.1C1577.7,1077.6,1578.1,1081.4,1580.7,1081.1z"/>
					<path class="st3" d="M1571,1080.3c0.7-1.3,0-3.4-1.9-3.2c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
						C1570.2,1081.2,1570.8,1080.8,1571,1080.3z"/>
					<path class="st3" d="M1591.2,1045.1c0.3,0.1,0.7,0.2,1.1,0.2c2.7-0.3,2.3-4.5-0.4-4.2c-1,0.1-1.5,0.7-1.7,1.4
						C1589.8,1043.4,1590.2,1044.6,1591.2,1045.1z"/>
					<path class="st3" d="M1560.8,1050.8c0.2,0,0.3,0,0.5-0.1c0-0.2,0.1-0.4,0.1-0.6c0.2-0.5,0.7-0.9,1.2-1c0.2-1.2-0.6-2.6-2.2-2.5
						C1557.7,1046.8,1558.1,1051,1560.8,1050.8z"/>
					<path class="st3" d="M1559.7,1068.7c-2.7,0.3-2.3,4.5,0.4,4.2C1562.8,1072.6,1562.4,1068.5,1559.7,1068.7z"/>
					<path class="st3" d="M1606.4,1059.4c-0.2-0.9-1-1.7-2.2-1.6c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
						C1606.1,1061.9,1606.7,1060.5,1606.4,1059.4z"/>
					<path class="st3" d="M1552.4,1062.6c0.1,0,0.3,0,0.4,0c0.3,0,0.6-0.1,0.9-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.9-0.8,0.8-2.2,0.1-3.1
						c-0.4-0.4-0.9-0.7-1.7-0.6C1549.9,1058.6,1550.1,1062.3,1552.4,1062.6z"/>
					<path class="st3" d="M1561.7,1037.5c1.8-0.2,2.2-2.1,1.5-3.3h-3.4C1559,1035.5,1559.7,1037.7,1561.7,1037.5z"/>
					<path class="st3" d="M1492.2,1080.4c-0.2,0-0.4,0-0.6,0.1c-1.5,0.5-1.6,2-0.9,3c0.4,0.6,1.2,1,2.1,0.7
						C1495.1,1083.5,1494.2,1080.3,1492.2,1080.4z"/>
					<path class="st3" d="M1496.9,1091.4c-1.4,0.5-1.6,2-1,2.9h3.2C1500,1093,1498.8,1090.8,1496.9,1091.4z"/>
					<path class="st3" d="M1500.8,1082.7c-0.3,0-0.5,0-0.8,0.1c-2.4,0.8-1.2,4.5,1.2,3.7c0.9-0.3,1.3-1,1.3-1.8
						c-0.2,0-0.3-0.1-0.5-0.1C1501.1,1084.3,1500.7,1083.5,1500.8,1082.7z"/>
					<path class="st3" d="M1510.2,1086.2c-2.4,0.8-1.2,4.5,1.2,3.7C1513.9,1089.2,1512.6,1085.4,1510.2,1086.2z"/>
					<path class="st3" d="M1500.8,1075.7c0.1-0.2,0.2-0.5,0.4-0.6c-2.1,0.9-0.9,4.4,1.4,3.6c0,0,0.1,0,0.1-0.1
						c-0.3-0.1-0.6-0.3-0.8-0.4C1501,1077.7,1500.5,1076.7,1500.8,1075.7z"/>
					<path class="st3" d="M1488.5,1088.5c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.2-0.8,0.5-0.9,0.9c-0.6,1.3,0.6,3.3,2.4,2.7
						C1492.2,1091.4,1490.9,1087.7,1488.5,1088.5z"/>
					<path class="st3" d="M1513.1,1080c0.3,0.1,0.7,0.4,0.9,0.7c0.1-0.5,0.1-1.1-0.3-1.5c-0.1-0.2-0.3-0.3-0.5-0.5
						c-0.7-0.5-1.5-0.6-2.3-0.1c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.9-0.6,2.7c0.2,0.3,0.5,0.5,0.9,0.7
						c0.1-0.6,0.2-1.2,0.5-1.7C1511.1,1080.2,1512,1079.7,1513.1,1080z"/>
					<path class="st3" d="M1505.3,1091.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.9c-0.4,0.8-0.2,1.8,0.5,2.4h3.3
						c0.1-0.1,0.1-0.2,0.2-0.3C1506.6,1093.1,1506.3,1091.9,1505.3,1091.4z"/>
					<path class="st3" d="M1495.6,1071.1c0-0.5,0.1-1,0.1-1.5c0-0.4,0.2-0.7,0.4-1c-0.2-0.2-0.5-0.3-0.7-0.3c-1-0.2-2.2,0.4-2.3,1.5
						c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,1,0.4,2.2,1.5,2.3c0.7,0.1,1.4-0.1,1.9-0.6C1495.8,1072.4,1495.5,1071.7,1495.6,1071.1z"/>
					<path class="st3" d="M1516.8,1092.7c-0.9,0.3-1.2,0.9-1.3,1.6h3.8C1519.1,1093.3,1518.1,1092.3,1516.8,1092.7z"/>
					<path class="st3" d="M1510.3,1073c-0.4-0.2-0.8-0.5-0.9-1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.3-0.2-0.7-0.1-1
						c-0.3-0.1-0.7-0.1-1.2,0c-2.4,0.8-1.2,4.5,1.2,3.7C1509.8,1074,1510.2,1073.5,1510.3,1073z"/>
					<path class="st3" d="M1480.3,1082.1c-2.4,0.8-1.2,4.5,1.2,3.7C1483.9,1085,1482.7,1081.3,1480.3,1082.1z"/>
					<path class="st3" d="M1522.6,1083.2c-0.8,0.3-1.2,0.8-1.3,1.4c-0.1,0.8,0.2,1.7,0.9,2.1c0.4,0.3,1,0.4,1.6,0.2
						C1526.2,1086.2,1525,1082.5,1522.6,1083.2z"/>
					<path class="st3" d="M1475.7,1094.4h0.9C1476.3,1094.3,1476,1094.3,1475.7,1094.4z"/>
					<path class="st3" d="M1479.1,1073.6c0.2,0,0.3,0,0.5-0.1c2.4-0.8,1.2-4.5-1.2-3.7c-0.8,0.3-1.2,0.9-1.3,1.6
						C1477,1072.5,1477.8,1073.7,1479.1,1073.6z"/>
					<path class="st3" d="M1551,1036.9c1.8,1.6,4.2-1,2.9-2.7h-3.1C1550.1,1035,1550,1036.1,1551,1036.9z"/>
					<path class="st3" d="M1544.2,1047.3c0.5,0.4,1,0.5,1.4,0.5c1.5-0.2,2.7-2.2,1.2-3.5c-1.2-1-2.5-0.3-3.1,0.7
						C1543.4,1045.7,1543.4,1046.7,1544.2,1047.3z"/>
					<path class="st3" d="M1556,1042.4c-2-1.7-4.6,1.4-2.6,3C1555.3,1047.1,1557.9,1044.1,1556,1042.4z"/>
					<path class="st3" d="M1555.8,1056.2c2,1.7,4.6-1.4,2.6-3C1556.4,1051.4,1553.8,1054.5,1555.8,1056.2z"/>
					<path class="st3" d="M1563.6,1039.5c-2-1.7-4.6,1.4-2.6,3C1562.9,1044.2,1565.5,1041.2,1563.6,1039.5z"/>
					<path class="st3" d="M1545,1035.4c-2-1.7-4.6,1.4-2.6,3C1544.3,1040.2,1546.9,1037.1,1545,1035.4z"/>
					<path class="st3" d="M1561.4,1050.1c-0.1,0.2-0.1,0.4-0.1,0.6c-0.1,0.8,0.3,1.7,1.1,2.1c0.5,0.2,0.9,0.4,1.4,0.6
						c1,0.4,2.2,0,2.7-1c0.4-1,0.1-2.2-1-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-0.5-0.2-1-0.2-1.5-0.1
						C1562.1,1049.2,1561.6,1049.6,1561.4,1050.1z"/>
					<path class="st3" d="M1547.3,1051.9c0.1,1,1.1,2,2.2,1.8c1.1-0.1,1.9-1,1.8-2.2c0-0.2,0-0.4,0-0.6c-0.1-1-1.1-2-2.2-1.8
						c-1.1,0.1-1.9,1-1.8,2.2C1547.3,1051.5,1547.3,1051.7,1547.3,1051.9z"/>
					<path class="st3" d="M1535.3,1049.1c1,0.4,2.2,0.1,2.7-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.7c-1-0.4-2.2-0.1-2.7,1
						c-0.2,0.5-0.4,0.9-0.6,1.4C1533.9,1047.4,1534.3,1048.6,1535.3,1049.1z"/>
					<path class="st3" d="M1538.1,1055.4c0.6,0.6,1.2,1.2,1.7,1.7c0.7,0.7,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8
						c-0.6-0.6-1.2-1.2-1.7-1.7c-0.7-0.7-2.2-0.6-2.8,0.2C1537.2,1053.5,1537.3,1054.6,1538.1,1055.4z"/>
					<path class="st3" d="M1545,1066.3c0.2,0.1,0.4,0.2,0.6,0.2c1,0.1,2.2-0.5,2.3-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
						c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3.1
						c0,0.1,0,0.2,0,0.4C1544,1065.2,1544.3,1065.9,1545,1066.3z M1548.2,1064c0,0-0.1,0.1-0.1,0.2
						C1548.1,1064.1,1548.1,1064,1548.2,1064z M1547.9,1064.5c0-0.1,0-0.2,0.1-0.2C1548,1064.3,1548,1064.4,1547.9,1064.5z"/>
					<path class="st3" d="M1554,1062.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.6-0.6,0.9c-0.3,0.7-0.2,1.5,0.6,2.1
						c1.8,1.5,4.1-0.9,3-2.6c-0.1-0.1-0.2-0.3-0.4-0.4C1555.5,1061.7,1554.6,1061.7,1554,1062.1z"/>
					<path class="st3" d="M1538.8,1058.7c-0.1-0.1-0.1-0.1-0.2-0.2c-2-1.7-4.6,1.4-2.6,3c0.6,0.5,1.3,0.6,1.8,0.4
						C1539.1,1061.6,1539.9,1059.9,1538.8,1058.7z"/>
					<path class="st3" d="M1528.5,1054.1c2,1.7,4.6-1.4,2.6-3C1529.1,1049.3,1526.5,1052.4,1528.5,1054.1z"/>
					<path class="st3" d="M1570.8,1042.7c-2-1.7-4.6,1.4-2.6,3C1570.1,1047.5,1572.8,1044.4,1570.8,1042.7z"/>
					<path class="st3" d="M1530.6,1039c-2-1.7-4.6,1.4-2.6,3C1529.9,1043.7,1532.6,1040.7,1530.6,1039z"/>
					<path class="st3" d="M1567.5,1062.3c-2-1.7-4.6,1.4-2.6,3C1566.9,1067,1569.5,1064,1567.5,1062.3z"/>
					<path class="st3" d="M1507.9,1057c2.4,0.9,3.8-2.7,1.4-3.7C1507,1052.4,1505.6,1056,1507.9,1057z"/>
					<path class="st3" d="M1506.4,1065.1c-2.4-0.9-3.8,2.7-1.4,3.7C1507.4,1069.7,1508.8,1066,1506.4,1065.1z"/>
					<path class="st3" d="M1512.9,1064.1c2.4,0.9,3.8-2.7,1.4-3.7C1512,1059.5,1510.5,1063.2,1512.9,1064.1z"/>
					<path class="st3" d="M1518.6,1073.2c0.5,0.2,1,0.2,1.4,0c1.4-0.6,1.9-3,0.1-3.7c-0.8-0.3-1.5-0.1-1.9,0.3
						c-0.3,0.2-0.5,0.5-0.6,0.9c-0.2,0.5-0.2,1,0,1.4C1517.7,1072.6,1518,1073,1518.6,1073.2z"/>
					<path class="st3" d="M1519,1058.9c0.4,0.2,0.8,0.2,1.1,0.1c1.6-0.3,2.3-3,0.3-3.8C1518.1,1054.4,1516.7,1058,1519,1058.9z"/>
					<path class="st3" d="M1500.4,1061.2c2.4,0.9,3.8-2.7,1.4-3.7C1499.5,1056.6,1498.1,1060.2,1500.4,1061.2z"/>
					<path class="st3" d="M1523.6,1068c0.5,0,1,0.1,1.5,0.1c1,0.1,2.1-0.7,2.2-1.8c0.1-1.1-0.7-2.1-1.8-2.2c-0.5,0-1-0.1-1.5-0.1
						c-1-0.1-2.1,0.7-2.2,1.8C1521.8,1066.9,1522.5,1067.9,1523.6,1068z"/>
					<path class="st3" d="M1509.1,1071.5c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.4,0.5,0.7,0.9,1c0.5,0.3,1.1,0.3,1.7,0.1
						c1-0.5,1.5-1.6,1-2.6c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.5-2.6-1c-0.6,0.3-1.1,0.9-1.2,1.6
						C1509,1070.8,1509,1071.2,1509.1,1071.5z"/>
					<path class="st3" d="M1499.5,1071.5c0-0.5,0.1-1,0.1-1.5c0.1-1-0.7-2.1-1.8-2.2c-0.7,0-1.3,0.2-1.7,0.7c-0.2,0.3-0.4,0.6-0.4,1
						c0,0.5-0.1,1-0.1,1.5c-0.1,0.6,0.2,1.3,0.7,1.7c0.3,0.2,0.6,0.4,1,0.4C1498.4,1073.3,1499.4,1072.6,1499.5,1071.5z"/>
					<path class="st3" d="M1518.7,1050c0.3-0.3,0.7-0.6,1-0.9c0.8-0.7,1-1.9,0.3-2.8c-0.7-0.8-1.9-1.1-2.8-0.3
						c-0.3,0.3-0.7,0.6-1,0.9c-0.8,0.7-1,1.9-0.3,2.8C1516.6,1050.4,1517.9,1050.7,1518.7,1050z"/>
					<path class="st3" d="M1505.6,1075.6c-0.7-0.4-1.5-0.7-2.2-1.1c-0.7-0.3-1.6-0.1-2.1,0.5c-0.2,0.2-0.3,0.4-0.4,0.6
						c-0.4,1.1,0.1,2.1,1.1,2.6c0.3,0.1,0.6,0.3,0.8,0.4c0.4,0.2,0.9,0.4,1.3,0.7c0.9,0.5,2.2-0.2,2.6-1.1
						C1507,1077.1,1506.6,1076.1,1505.6,1075.6z"/>
					<path class="st3" d="M1514,1080.7c-0.2-0.3-0.5-0.6-0.9-0.7c-1.1-0.4-2.1,0.1-2.6,1.1c-0.3,0.5-0.4,1.1-0.5,1.7
						c0,0.5,0,1.1,0.1,1.6c0.3,1,1.1,1.9,2.2,1.6c1-0.2,1.9-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
						c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3C1514.5,1081.8,1514.3,1081.2,1514,1080.7z M1514,1083.3c0,0.1,0,0.1,0,0.2
						C1514,1083.4,1514,1083.3,1514,1083.3z M1514,1083.1c0,0,0-0.1,0-0.2C1514,1083,1514,1083,1514,1083.1z"/>
					<path class="st3" d="M1520.9,1078.8c-2.4-0.9-3.8,2.7-1.4,3.7C1521.9,1083.4,1523.3,1079.7,1520.9,1078.8z"/>
					<path class="st3" d="M1503.5,1081c-1.4-0.6-2.5,0.5-2.6,1.7c-0.1,0.8,0.2,1.6,1.2,2c0.2,0.1,0.4,0.1,0.5,0.1
						C1504.6,1085,1505.6,1081.9,1503.5,1081z"/>
					<path class="st3" d="M1494,1076.4c-2.4-0.9-3.8,2.7-1.4,3.7C1494.9,1081,1496.4,1077.4,1494,1076.4z"/>
					<path class="st3" d="M1526.7,1059.6c2.4,0.9,3.8-2.7,1.4-3.7C1525.8,1055,1524.4,1058.7,1526.7,1059.6z"/>
					<path class="st3" d="M1498.2,1051c2.4,0.9,3.8-2.7,1.4-3.7C1497.3,1046.4,1495.8,1050.1,1498.2,1051z"/>
					<path class="st3" d="M1489.3,1069.2c1.7-0.3,2.4-3,0.4-3.8c-2.2-0.9-3.6,2.2-1.9,3.4c0.1,0.1,0.3,0.2,0.5,0.2
						C1488.6,1069.3,1489,1069.3,1489.3,1069.2z"/>
					<path class="st3" d="M1531.4,1075.1c-1.3-0.5-2.4,0.4-2.6,1.5c-0.1,0.6,0,1.3,0.6,1.8c0.2,0.1,0.4,0.3,0.6,0.4
						C1532.4,1079.7,1533.8,1076.1,1531.4,1075.1z"/>
					<path class="st3" d="M1486.6,1057.4c2.4,0.9,3.8-2.7,1.4-3.7C1485.7,1052.9,1484.2,1056.5,1486.6,1057.4z"/>
					<path class="st3" d="M1504.5,1040.3c2.4,0.9,3.8-2.7,1.4-3.7C1503.6,1035.7,1502.2,1039.4,1504.5,1040.3z"/>
					<path class="st3" d="M834.1,1065.1c-2.6-1.9-5.7,2.2-3,4.1C833.8,1071.2,836.8,1067.1,834.1,1065.1z"/>
					<path class="st3" d="M826.6,1079c-2.6-1.9-5.7,2.2-3,4.1C826.2,1085,829.2,1080.9,826.6,1079z"/>
					<path class="st3" d="M838,1075.7c-2.6-1.9-5.7,2.2-3,4.1C837.6,1081.8,840.7,1077.7,838,1075.7z"/>
					<path class="st3" d="M842.1,1089.1c-2.6-1.9-5.7,2.2-3,4.1C841.7,1095.1,844.7,1091,842.1,1089.1z"/>
					<path class="st3" d="M847.4,1071.3c-2.6-1.9-5.7,2.2-3,4.1C847,1077.4,850,1073.2,847.4,1071.3z"/>
					<path class="st3" d="M823.4,1067.9c-2.6-1.9-5.7,2.2-3,4.1C823,1074,826,1069.8,823.4,1067.9z"/>
					<path class="st3" d="M850.7,1084.2c-0.6-0.2-1.2-0.4-1.8-0.7c-1.2-0.5-2.9,0.2-3.3,1.5c-0.4,1.3,0.1,2.8,1.5,3.3
						c0.6,0.2,1.2,0.4,1.8,0.7c1.2,0.5,2.9-0.2,3.3-1.5C852.6,1086.2,852,1084.7,850.7,1084.2z"/>
					<path class="st3" d="M832.9,1087.1c-0.2-1.3-1.6-2.4-2.9-2.1c-1.4,0.3-2.3,1.5-2.1,2.9c0,0.2,0.1,0.5,0.1,0.7
						c0.2,1.3,1.6,2.4,2.9,2.1c1.4-0.3,2.3-1.5,2.1-2.9C832.9,1087.6,832.9,1087.3,832.9,1087.1z"/>
					<path class="st3" d="M815,1079.6c-1.3-0.4-2.8,0.1-3.3,1.5c-0.2,0.6-0.4,1.2-0.7,1.8c-0.5,1.2,0.2,2.9,1.5,3.3
						c1.3,0.4,2.8-0.1,3.3-1.5c0.2-0.6,0.4-1.2,0.7-1.8C816.9,1081.6,816.3,1080,815,1079.6z"/>
					<path class="st3" d="M844.9,1059.4c-1.2,0.6-2,2.1-1.3,3.4c0.6,1.2,2.1,2,3.4,1.3c0.5-0.3,1-0.5,1.6-0.8c1.2-0.6,2-2.1,1.3-3.4
						c-0.6-1.2-2.1-2-3.4-1.3C846,1058.9,845.4,1059.1,844.9,1059.4z"/>
					<path class="st3" d="M822,1091.8c-0.8-0.7-1.6-1.4-2.4-2.1c-1-0.9-2.8-0.5-3.6,0.5c-0.8,1.2-0.5,2.6,0.5,3.6
						c0.2,0.2,0.4,0.3,0.6,0.5h5.8C823.2,1093.4,822.8,1092.5,822,1091.8z"/>
					<path class="st3" d="M807.3,1089c-2.6-1.9-5.7,2.2-3,4.1C806.9,1095.1,809.9,1090.9,807.3,1089z"/>
					<path class="st3" d="M856.8,1074.7c-2.6-1.9-5.7,2.2-3,4.1C856.5,1080.7,859.5,1076.6,856.8,1074.7z"/>
					<path class="st3" d="M820.9,1058.5c2.6,1.9,5.7-2.2,3-4.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.9-1.3,1.6-2.4,0.9
						c-0.2-0.1-0.4-0.3-0.5-0.5C819.7,1055.7,819.4,1057.4,820.9,1058.5z"/>
					<path class="st3" d="M805.5,1073.8c-2.6-1.9-5.7,2.2-3,4.1C805.1,1079.9,808.1,1075.8,805.5,1073.8z"/>
					<path class="st3" d="M807.3,1058.6c-2.6-1.9-5.7,2.2-3,4.1C806.9,1064.7,809.9,1060.6,807.3,1058.6z"/>
					<path class="st3" d="M832.2,1045.1c0.1,0.6,0,1.1-0.4,1.5c0.2,0.2,0.3,0.4,0.6,0.6c1.2,0.9,2.4,0.5,3.2-0.3
						c0-0.6,0.3-1.2,0.8-1.5c0.1-0.8-0.1-1.7-1-2.3c-1.4-1.1-3-0.3-3.7,0.9c0.2,0.2,0.3,0.4,0.3,0.6
						C832.1,1044.8,832.2,1045,832.2,1045.1z"/>
					<path class="st3" d="M869.5,1064.7c-2.8,0.4-2.2,4.8,0.6,4.4C873,1068.7,872.3,1064.3,869.5,1064.7z"/>
					<path class="st3" d="M873.2,1077.9c-2.8,0.4-2.2,4.8,0.6,4.4C876.7,1081.9,876,1077.5,873.2,1077.9z"/>
					<path class="st3" d="M879,1073.3c2.8-0.4,2.2-4.8-0.6-4.4C875.5,1069.3,876.1,1073.7,879,1073.3z"/>
					<path class="st3" d="M889,1074.7c-2.8,0.4-2.2,4.8,0.6,4.4C892.4,1078.7,891.8,1074.3,889,1074.7z"/>
					<path class="st3" d="M881.5,1060.5c-2.8,0.4-2.2,4.8,0.6,4.4C884.9,1064.5,884.3,1060.1,881.5,1060.5z"/>
					<path class="st3" d="M864.5,1073c-2.8,0.4-2.2,4.8,0.6,4.4C868,1077,867.4,1072.6,864.5,1073z"/>
					<path class="st3" d="M894.2,1067.6c-0.5-1.1-1.8-1.7-2.9-1.1c-0.5,0.2-1,0.4-1.5,0.7c-1.1,0.5-1.7,1.8-1.1,2.9
						c0.5,1.1,1.8,1.7,2.9,1.1c0.5-0.2,1-0.4,1.5-0.7C894.1,1070.1,894.8,1068.7,894.2,1067.6z"/>
					<path class="st3" d="M882.6,1079.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-0.7-2.4-0.5-3.1,0.4c-0.7,1-0.5,2.4,0.4,3.1
						c0.2,0.1,0.3,0.3,0.5,0.4c0.9,0.7,2.4,0.5,3.1-0.4C883.7,1081.6,883.6,1080.2,882.6,1079.5z"/>
					<path class="st3" d="M869.3,1086.5c-0.5-1.1-1.8-1.7-2.9-1.1c-1.1,0.5-1.7,1.8-1.1,2.9c0.2,0.5,0.4,1,0.7,1.5
						c0.5,1.1,1.8,1.7,2.9,1.1c1.1-0.5,1.7-1.8,1.1-2.9C869.7,1087.5,869.5,1087,869.3,1086.5z"/>
					<path class="st3" d="M873.2,1053.2c-0.2,0.5-0.3,1-0.5,1.4c-0.4,1.1,0,2.5,1.2,2.9c1.1,0.4,2.5,0,2.9-1.2
						c0.2-0.5,0.3-1,0.5-1.4c0.4-1.1,0-2.5-1.2-2.9C875,1051.6,873.6,1052,873.2,1053.2z"/>
					<path class="st3" d="M878.2,1088.6c-0.9,0.1-1.8,0.1-2.7,0.2c-1.2,0.1-2.1,1.4-1.9,2.5c0.2,1.3,1.3,2,2.5,1.9
						c0.9-0.1,1.8-0.1,2.7-0.2c1.2-0.1,2.1-1.4,1.9-2.5C880.4,1089.3,879.4,1088.6,878.2,1088.6z"/>
					<path class="st3" d="M891.1,1092c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.2,0-0.4,0-0.5
						c-0.1-1.2-1.4-2.1-2.5-1.9c-1.3,0.2-1.9,1.3-1.9,2.5c0.1,1.1,0.5,2.2,1.2,3.1h4C891.7,1093.6,891.7,1092.6,891.1,1092z
						 M890.6,1091.1c0,0,0,0.1,0.1,0.2C890.6,1091.3,890.6,1091.2,890.6,1091.1z M890.8,1091.7c-0.1-0.1-0.1-0.1-0.1-0.2
						C890.7,1091.5,890.8,1091.6,890.8,1091.7z"/>
					<path class="st3" d="M895,1083.2c-2.8,0.4-2.2,4.8,0.6,4.4C898.4,1087.2,897.8,1082.8,895,1083.2z"/>
					<path class="st3" d="M890.9,1057.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c-0.1,1.1-1.4,1.7-2.4,1.6c-0.3,0-0.5-0.2-0.8-0.3
						c0.2,1.1,1,2.1,2.4,1.9c2.2-0.3,2.3-3,1-4C890.9,1057.3,890.9,1057.4,890.9,1057.5z"/>
					<path class="st3" d="M890.9,1057.4c0-0.1,0.1-0.1,0.1-0.1C890.9,1057.3,890.9,1057.4,890.9,1057.4z"/>
					<path class="st3" d="M890.8,1057.5c0,0-0.1,0.1-0.1,0.2C890.8,1057.6,890.8,1057.6,890.8,1057.5z"/>
					<path class="st3" d="M857.3,1068.7c2.8-0.4,2.2-4.8-0.6-4.4C853.8,1064.7,854.5,1069.1,857.3,1068.7z"/>
					<path class="st3" d="M857,1087.5c-2.8,0.4-2.2,4.8,0.6,4.4C860.5,1091.5,859.8,1087.1,857,1087.5z"/>
					<path class="st3" d="M903.2,1073.8c-2.8,0.4-2.2,4.8,0.6,4.4C906.7,1077.8,906.1,1073.4,903.2,1073.8z"/>
					<path class="st3" d="M848.9,1077c-2.8,0.4-2.2,4.8,0.6,4.4C852.3,1081,851.7,1076.6,848.9,1077z"/>
					<path class="st3" d="M857.6,1054.7c2.8-0.4,2.2-4.8-0.6-4.4C854.1,1050.7,854.8,1055.1,857.6,1054.7z"/>
					<path class="st3" d="M1068.9,1051.4c-2.7,1.4-0.5,5.5,2.2,4.2C1073.7,1054.1,1071.6,1050,1068.9,1051.4z"/>
					<path class="st3" d="M1079.3,1067.4c2.7-1.4,0.5-5.5-2.2-4.2C1074.5,1064.6,1076.7,1068.8,1079.3,1067.4z"/>
					<path class="st3" d="M1081.3,1056.7c2.7-1.4,0.5-5.5-2.2-4.2C1076.5,1053.9,1078.7,1058,1081.3,1056.7z"/>
					<path class="st3" d="M1091.8,1054.6c-2.7,1.4-0.5,5.5,2.2,4.2C1096.6,1057.3,1094.5,1053.2,1091.8,1054.6z"/>
					<path class="st3" d="M1081.5,1047.1c2.7-1.4,0.5-5.5-2.2-4.2C1076.7,1044.4,1078.8,1048.5,1081.5,1047.1z"/>
					<path class="st3" d="M1066.8,1061.3c-2.7,1.4-0.5,5.5,2.2,4.2C1071.6,1064.1,1069.5,1059.9,1066.8,1061.3z"/>
					<path class="st3" d="M1089.9,1050c0.3,0.3,0.7,0.6,1.2,0.7c0.3-0.8,0.9-1.4,2-1.5c0.4,0,0.7,0,1,0.1c0.1-0.1,0.2-0.2,0.4-0.3
						c0.9-0.8,1-2.4,0.1-3.3c-0.9-0.9-2.4-1-3.3-0.1c-0.4,0.4-0.8,0.8-1.3,1.2C1089.1,1047.6,1089,1049.1,1089.9,1050z"/>
					<path class="st3" d="M1085,1065.8c0.2,0.1,0.4,0.1,0.6,0.2c1.2,0.4,2.6-0.3,2.9-1.5c0.3-1.2-0.3-2.5-1.5-2.9
						c-0.2-0.1-0.4-0.1-0.6-0.2c-1.2-0.4-2.6,0.3-2.9,1.5C1083.2,1064.1,1083.8,1065.4,1085,1065.8z"/>
					<path class="st3" d="M1077.5,1074.4c-0.4-0.4-0.8-0.8-1.2-1.3c-0.8-0.9-2.4-1-3.3-0.1c-0.9,0.9-1,2.4-0.1,3.3
						c0.4,0.4,0.8,0.8,1.2,1.3c0.8,0.9,2.4,1,3.3,0.1C1078.2,1076.8,1078.4,1075.4,1077.5,1074.4z"/>
					<path class="st3" d="M1070.8,1042.6c1.2,0,2.5-0.9,2.5-2.2c0-0.5,0-1.1,0-1.6c0-1.2-0.9-2.4-2.2-2.5c-1.2,0-2.5,0.9-2.5,2.2
						c0,0.5,0,1.1,0,1.6C1068.6,1041.4,1069.5,1042.6,1070.8,1042.6z"/>
					<path class="st3" d="M1082.2,1076.5c0.7,1.2,1.9,1.5,3.2,1c0.9-0.4,1.8-0.7,2.7-1.1c1.1-0.5,1.6-2.1,1-3.2
						c-0.7-1.2-1.9-1.5-3.2-1c-0.9,0.4-1.8,0.7-2.7,1.1C1082.1,1073.8,1081.6,1075.5,1082.2,1076.5z"/>
					<path class="st3" d="M1100.7,1074.3c0.6-1,0.4-2.6-0.7-3.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
						c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.1-2.1-1.6-3.2-1c-1.2,0.7-1.5,1.9-1,3.2
						c0.5,1.2,1.5,2.3,2.7,2.9C1098.5,1075.5,1100,1075.4,1100.7,1074.3z M1099.5,1070.8c-0.1-0.1-0.2-0.1-0.2-0.2
						C1099.4,1070.7,1099.5,1070.8,1099.5,1070.8z M1099.2,1070.6c0,0-0.1-0.1-0.1-0.2C1099.1,1070.4,1099.2,1070.5,1099.2,1070.6z"
						/>
					<path class="st3" d="M1100.8,1061c-2.7,1.4-0.5,5.5,2.2,4.2C1105.6,1063.7,1103.5,1059.6,1100.8,1061z"/>
					<path class="st3" d="M1089.1,1078.3c-2.7,1.4-0.5,5.5,2.2,4.2C1093.9,1081.1,1091.7,1076.9,1089.1,1078.3z"/>
					<path class="st3" d="M1077.6,1083.2c-2.7,1.4-0.5,5.5,2.2,4.2C1082.4,1086,1080.3,1081.8,1077.6,1083.2z"/>
					<path class="st3" d="M1088.2,1040.8c2.7-1.4,0.5-5.5-2.2-4.2C1083.3,1038,1085.5,1042.1,1088.2,1040.8z"/>
					<path class="st3" d="M1055.9,1055.4c-2.7,1.4-0.5,5.5,2.2,4.2C1060.8,1058.2,1058.6,1054,1055.9,1055.4z"/>
					<path class="st3" d="M1064.4,1078.5c-2.7,1.4-0.5,5.5,2.2,4.2C1069.2,1081.2,1067.1,1077.1,1064.4,1078.5z"/>
					<path class="st3" d="M1107.9,1052.9c2.7-1.4,0.5-5.5-2.2-4.2C1103.1,1050.1,1105.2,1054.3,1107.9,1052.9z"/>
					<path class="st3" d="M1052.6,1070.8c-2.7,1.4-0.5,5.5,2.2,4.2C1057.4,1073.6,1055.3,1069.4,1052.6,1070.8z"/>
					<path class="st3" d="M1053.5,1045.5c2.7-1.4,0.5-5.5-2.2-4.2C1048.7,1042.7,1050.8,1046.9,1053.5,1045.5z"/>
					<path class="st3" d="M1106.7,1066.1c-3.2,0.3-2.7,5.3,0.5,5C1110.4,1070.8,1109.9,1065.8,1106.7,1066.1z"/>
					<path class="st3" d="M1110.3,1081.3c-3.2,0.3-2.7,5.3,0.5,5C1114,1085.9,1113.5,1080.9,1110.3,1081.3z"/>
					<path class="st3" d="M1117,1076.3c3.2-0.3,2.7-5.3-0.5-5C1113.3,1071.7,1113.8,1076.7,1117,1076.3z"/>
					<path class="st3" d="M1128.3,1078.4c-3.2,0.3-2.7,5.3,0.5,5C1132.1,1083,1131.5,1078,1128.3,1078.4z"/>
					<path class="st3" d="M1120.9,1066.9c3.2-0.3,2.7-5.3-0.5-5C1117.2,1062.2,1117.7,1067.2,1120.9,1066.9z"/>
					<path class="st3" d="M1100.6,1075.3c-3.2,0.3-2.7,5.3,0.5,5C1104.4,1080,1103.9,1074.9,1100.6,1075.3z"/>
					<path class="st3" d="M1134.6,1070.5c-0.5-1.2-1.9-2-3.3-1.4c-0.6,0.2-1.2,0.5-1.7,0.7c-1.2,0.5-2,2-1.4,3.3
						c0.3,0.8,1,1.4,1.8,1.5c0.3-0.5,0.9-0.9,1.7-0.8c0.3,0,0.6,0.1,0.8,0.3c0.2-0.1,0.4-0.2,0.6-0.3
						C1134.4,1073.3,1135.2,1071.8,1134.6,1070.5z"/>
					<path class="st3" d="M1121,1083.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-1-0.8-2.7-0.7-3.5,0.4
						c-0.8,1.1-0.7,2.7,0.4,3.5c0.2,0.2,0.4,0.3,0.6,0.5c1,0.8,2.7,0.7,3.5-0.4c0.2-0.3,0.3-0.5,0.4-0.8
						C1120.7,1085.7,1120.5,1084.5,1121,1083.6z"/>
					<path class="st3" d="M1106.2,1092.6c-0.2-0.6-0.5-1.2-0.7-1.7c-0.5-1.2-2-2-3.3-1.4c-1.2,0.5-2,1.9-1.4,3.3
						c0.2,0.6,0.5,1.1,0.7,1.7h4.7C1106.4,1093.8,1106.4,1093.2,1106.2,1092.6z"/>
					<path class="st3" d="M1112,1058.1c1.2,0.5,2.8,0.1,3.3-1.3c0.2-0.5,0.4-1.1,0.6-1.6c0.5-1.2,0.1-2.8-1.3-3.3
						c-1.2-0.5-2.8-0.1-3.3,1.3c-0.2,0.5-0.4,1.1-0.6,1.6C1110.3,1056,1110.7,1057.6,1112,1058.1z"/>
					<path class="st3" d="M1112.4,1093.8c-0.5,0-1,0.3-1.4,0.6h6.5c-0.5-0.5-1.2-0.7-2-0.7
						C1114.4,1093.7,1113.4,1093.7,1112.4,1093.8z"/>
					<path class="st3" d="M1136,1093.1c2.3-1,1.7-5.1-1.3-4.8c-3.1,0.3-2.7,5,0.2,5c0.2-0.1,0.3-0.2,0.5-0.2
						C1135.7,1093.1,1135.9,1093.1,1136,1093.1z"/>
					<path class="st3" d="M1130.1,1063.1c3.2-0.3,2.7-5.3-0.5-5C1126.4,1058.4,1126.9,1063.4,1130.1,1063.1z"/>
					<path class="st3" d="M1092.1,1065.1c-3.2,0.3-2.7,5.3,0.5,5C1095.8,1069.7,1095.3,1064.7,1092.1,1065.1z"/>
					<path class="st3" d="M1091.5,1091.5c-1.7,0.2-2.3,1.6-2.1,2.9h4.8C1094.3,1092.9,1093.4,1091.3,1091.5,1091.5z"/>
					<path class="st3" d="M1144.6,1078c-0.3,0-0.5,0.1-0.8,0.2c0.2,1-0.3,2.1-1.4,2.3c0,1.4,0.9,2.7,2.7,2.5
						C1148.3,1082.7,1147.8,1077.6,1144.6,1078z"/>
					<path class="st3" d="M1082.7,1079.2c-3.2,0.3-2.7,5.3,0.5,5C1086.4,1083.9,1085.9,1078.9,1082.7,1079.2z"/>
					<path class="st3" d="M1093,1049.2c-1.1,0.1-1.7,0.7-2,1.5c-0.6,1.6,0.3,3.7,2.5,3.5c2.8-0.3,2.8-4.2,0.5-4.9
						C1093.8,1049.2,1093.4,1049.2,1093,1049.2z"/>
					<path class="st3" d="M890,1037.4c-1.9-1.7-4.6,1.3-2.7,3C889.2,1042.1,891.9,1039.1,890,1037.4z"/>
					<path class="st3" d="M899.2,1035.7c-1.9-1.7-4.6,1.3-2.7,3C898.5,1040.4,901.1,1037.4,899.2,1035.7z"/>
					<path class="st3" d="M901.5,1046.5c-1.9-1.7-4.6,1.3-2.7,3C900.7,1051.2,903.4,1048.2,901.5,1046.5z"/>
					<path class="st3" d="M904.2,1035.9c1.5,1.3,3.4-0.1,3.3-1.7h-4C903.5,1034.8,903.7,1035.4,904.2,1035.9z"/>
					<path class="st3" d="M909.5,1045.9c0.4-1,0.1-2.2-0.9-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.5-2.3-0.1-2.7,0.9
						c-0.4,1-0.1,2.2,0.9,2.7c0.5,0.2,0.9,0.4,1.4,0.6C907.7,1047.3,909,1046.9,909.5,1045.9z"/>
					<path class="st3" d="M890.3,1045.1c0.1,1.1,1.1,2,2.1,1.9c1.1-0.1,2-1,1.9-2.1c0-0.2,0-0.4,0-0.6c-0.1-1.1-1.1-2-2.1-1.9
						c-1.1,0.1-2,1-1.9,2.1C890.3,1044.7,890.3,1044.9,890.3,1045.1z"/>
					<path class="st3" d="M878.4,1042c1,0.4,2.2,0.1,2.7-0.9c0.2-0.5,0.4-0.9,0.6-1.4c0.5-1,0.1-2.3-0.9-2.7c-1-0.4-2.2-0.1-2.7,0.9
						c-0.2,0.5-0.4,0.9-0.6,1.4C877,1040.3,877.3,1041.6,878.4,1042z"/>
					<path class="st3" d="M883.7,1045.4c-0.7-0.8-2.2-0.6-2.8,0.2c-0.7,0.9-0.6,2,0.2,2.8c0.6,0.6,1.1,1.2,1.7,1.8
						c0.7,0.8,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8C884.9,1046.6,884.3,1046,883.7,1045.4z"/>
					<path class="st3" d="M887.6,1059.4c0.2,0.2,0.5,0.3,0.8,0.3c1,0.2,2.3-0.5,2.4-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
						c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3
						C886.7,1058.1,887,1058.9,887.6,1059.4z M890.8,1057.7c0-0.1,0-0.2,0.1-0.2C890.8,1057.6,890.8,1057.6,890.8,1057.7z
						 M891,1057.2c0,0-0.1,0.1-0.1,0.1C890.9,1057.4,890.9,1057.3,891,1057.2C891,1057.2,891,1057.2,891,1057.2z"/>
					<path class="st3" d="M896.4,1058.7c1.9,1.7,4.6-1.3,2.7-3C897.2,1053.9,894.5,1057,896.4,1058.7z"/>
					<path class="st3" d="M881.5,1051.6c-1.9-1.7-4.6,1.3-2.7,3C880.8,1056.4,883.4,1053.3,881.5,1051.6z"/>
					<path class="st3" d="M874,1043.9c-1.9-1.7-4.6,1.3-2.7,3C873.3,1048.6,876,1045.6,874,1043.9z"/>
					<path class="st3" d="M911.4,1039.3c1.9,1.7,4.6-1.3,2.7-3C912.2,1034.5,909.5,1037.5,911.4,1039.3z"/>
					<path class="st3" d="M874.1,1034.2h-3.5c0.1,0.2,0.3,0.4,0.5,0.6C872.2,1035.8,873.6,1035.2,874.1,1034.2z"/>
					<path class="st3" d="M907.8,1058.9c1.9,1.7,4.6-1.3,2.7-3C908.5,1054.1,905.9,1057.1,907.8,1058.9z"/>
					<path class="st3" d="M826,1042.6c1.8,1.1,3.5-1.7,1.7-2.8C825.9,1038.8,824.2,1041.6,826,1042.6z"/>
					<path class="st3" d="M833.1,1039.9c1.8,1.1,3.5-1.7,1.7-2.8C833,1036.1,831.3,1038.9,833.1,1039.9z"/>
					<path class="st3" d="M835.6,1047c0,0.5,0.2,0.9,0.8,1.2c1.8,1.1,3.5-1.7,1.7-2.8c-0.6-0.4-1.2-0.3-1.7,0
						C835.9,1045.8,835.6,1046.4,835.6,1047z"/>
					<path class="st3" d="M838.8,1036.6c1.6,1,3.1-1.1,2.1-2.4h-2.5C837.9,1034.9,837.8,1036,838.8,1036.6z"/>
					<path class="st3" d="M823.3,1035.8c1.3,0.8,2.5-0.4,2.5-1.5h-3.2C822.5,1034.8,822.7,1035.4,823.3,1035.8z"/>
					<path class="st3" d="M841.2,1044.7c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.8-0.3,2-1.1c0.2-0.9-0.2-1.8-1.1-2
						c-0.4-0.1-0.8-0.2-1.2-0.3c-0.8-0.2-1.8,0.3-2,1.1C839.9,1043.5,840.3,1044.4,841.2,1044.7z"/>
					<path class="st3" d="M829,1045.9c0.2,0.8,1.1,1.4,2,1.2c0.3-0.1,0.6-0.3,0.8-0.5c0.4-0.4,0.5-0.9,0.4-1.5
						c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.2-0.5-0.3-0.6c-0.4-0.5-1-0.7-1.6-0.6c-0.8,0.2-1.4,1.1-1.2,2
						C829,1045.6,829,1045.8,829,1045.9z"/>
					<path class="st3" d="M821.1,1044.1c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.1-2c-0.9-0.2-1.8,0.2-2,1.1
						c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8,0.3,1.8,1.1,2C819.9,1045.4,820.8,1045,821.1,1044.1z"/>
					<path class="st3" d="M822.1,1049.9c0.5,0.4,1.1,0.8,1.6,1.2c0.7,0.5,1.8,0.2,2.2-0.5c0.5-0.8,0.2-1.7-0.5-2.2
						c-0.5-0.4-1.1-0.8-1.6-1.2c-0.7-0.5-1.8-0.2-2.2,0.5C821.1,1048.5,821.4,1049.4,822.1,1049.9z"/>
					<path class="st3" d="M829.6,1057.8c0.8,0,1.7-0.7,1.7-1.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2
						c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.8-0.5-2.2c-0.8-0.5-1.7-0.2-2.2,0.5c-0.5,0.7-0.8,1.7-0.8,2.6
						C828,1057,828.6,1057.8,829.6,1057.8z M831.3,1055.5c0,0,0,0.1-0.1,0.1C831.2,1055.6,831.3,1055.5,831.3,1055.5z M831.2,1055.7
						c0,0.1,0,0.1,0,0.2C831.2,1055.8,831.2,1055.8,831.2,1055.7z"/>
					<path class="st3" d="M835.8,1055.9c1.8,1.1,3.5-1.7,1.7-2.8C835.7,1052,834,1054.8,835.8,1055.9z"/>
					<path class="st3" d="M821.3,1055.2c1,0.6,2.1,0,2.4-0.9c0.2-0.6,0.1-1.4-0.7-1.8c-1.6-0.9-3,1-2.2,2.3
						C820.9,1054.9,821,1055,821.3,1055.2z"/>
					<path class="st3" d="M815.9,1047.3c-1.8-1.1-3.5,1.7-1.7,2.8C816,1051.2,817.7,1048.4,815.9,1047.3z"/>
					<path class="st3" d="M845,1038.3c1.8,1.1,3.5-1.7,1.7-2.8C844.9,1034.4,843.2,1037.2,845,1038.3z"/>
					<path class="st3" d="M812.2,1040.5c1.8,1.1,3.5-1.7,1.7-2.8C812.1,1036.7,810.5,1039.4,812.2,1040.5z"/>
					<path class="st3" d="M846.6,1051.6c-1.8-1.1-3.5,1.7-1.7,2.8C846.7,1055.5,848.4,1052.7,846.6,1051.6z"/>
					<path class="st3" d="M1143.8,1078.2c-0.1-0.7-0.6-1.4-1.6-1.4c-2.4-0.2-2.8,3.5-0.4,3.7c0.2,0,0.4,0,0.6,0
						C1143.5,1080.3,1144,1079.1,1143.8,1078.2z"/>
					<path class="st3" d="M1142.6,1088.3c-2.4-0.2-2.8,3.5-0.4,3.7C1144.6,1092.3,1145,1088.6,1142.6,1088.3z"/>
					<path class="st3" d="M1148.6,1082c-2.4-0.2-2.8,3.5-0.4,3.7C1150.7,1086,1151,1082.2,1148.6,1082z"/>
					<path class="st3" d="M1156.2,1088.9c-2.4-0.2-2.8,3.5-0.4,3.7C1158.3,1092.9,1158.6,1089.2,1156.2,1088.9z"/>
					<path class="st3" d="M1152.9,1075.7c-2.4-0.2-2.8,3.5-0.4,3.7C1154.9,1079.7,1155.3,1075.9,1152.9,1075.7z"/>
					<path class="st3" d="M1136.4,1082.5c-2.4-0.2-2.8,3.5-0.4,3.7C1138.5,1086.5,1138.8,1082.8,1136.4,1082.5z"/>
					<path class="st3" d="M1159.8,1082.6c-0.5,0.1-0.9,0.2-1.4,0.2c-1,0.2-1.7,1.2-1.5,2.2c0.2,1,1.1,1.7,2.2,1.5
						c0.5-0.1,0.9-0.2,1.4-0.2c1-0.2,1.7-1.2,1.5-2.2C1161.8,1083.1,1160.9,1082.4,1159.8,1082.6z"/>
					<path class="st3" d="M1150,1091.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6-0.8-1.9-0.9-2.6-0.3c-0.8,0.7-0.9,1.8-0.3,2.6
						c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.3,0.6,0.4h1.8c0.1-0.1,0.2-0.1,0.3-0.2C1150.5,1093.5,1150.6,1092.4,1150,1091.5z"/>
					<path class="st3" d="M1136,1093.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.2c-0.4,0.2-0.8,0.6-0.9,1.1h3.6
						C1137.3,1093.7,1136.7,1093.2,1136,1093.1z"/>
					<path class="st3" d="M1150.2,1067.6c-0.8-0.5-2.1-0.5-2.6,0.4c-0.2,0.4-0.5,0.7-0.7,1.1c-0.5,0.8-0.5,2,0.4,2.6
						c0.8,0.5,2.1,0.5,2.6-0.4c0.2-0.4,0.5-0.7,0.7-1.1C1151.1,1069.4,1151.1,1068.1,1150.2,1067.6z"/>
					<path class="st3" d="M1159.8,1078c2.4,0.2,2.8-3.5,0.4-3.7C1157.8,1074,1157.4,1077.8,1159.8,1078z"/>
					<path class="st3" d="M1130,1074.6c-0.6,1.1-0.3,2.8,1.3,2.9c2.1,0.2,2.6-2.5,1.2-3.5c-0.2-0.1-0.5-0.2-0.8-0.3
						C1130.9,1073.7,1130.3,1074.1,1130,1074.6z"/>
					<path class="st3" d="M1127.3,1093c-1.1-0.1-1.7,0.6-1.9,1.4h3.5C1128.7,1093.7,1128.2,1093.1,1127.3,1093z"/>
					<path class="st3" d="M1168.2,1091.1c-2-0.2-2.6,2.3-1.4,3.3h2.4C1170.2,1093.4,1170,1091.2,1168.2,1091.1z"/>
					<path class="st3" d="M1122.7,1082.7c-0.9-0.1-1.4,0.3-1.8,0.9c-0.5,0.9-0.3,2.1,0.6,2.6c0.2,0.1,0.5,0.2,0.8,0.2
						C1124.8,1086.7,1125.1,1082.9,1122.7,1082.7z"/>
					<path class="st3" d="M1134.4,1066.1c2.4,0.2,2.8-3.5,0.4-3.7C1132.4,1062.1,1132,1065.8,1134.4,1066.1z"/>
					<path class="st3" d="M625.1,1074.4c-2.2-2.1-5.4,1.3-3.2,3.4C624,1079.8,627.2,1076.5,625.1,1074.4z"/>
					<path class="st3" d="M613.4,1089.4c2.2,2.1,5.4-1.3,3.2-3.4C614.5,1083.9,611.3,1087.3,613.4,1089.4z"/>
					<path class="st3" d="M624.1,1087.8c2.2,2.1,5.4-1.3,3.2-3.4C625.2,1082.3,622,1085.7,624.1,1087.8z"/>
					<path class="st3" d="M633.1,1084.9c2.2,2.1,5.4-1.3,3.2-3.4C634.1,1079.4,630.9,1082.8,633.1,1084.9z"/>
					<path class="st3" d="M615,1075.7c-2.2-2.1-5.4,1.3-3.2,3.4C614,1081.1,617.2,1077.7,615,1075.7z"/>
					<path class="st3" d="M637.8,1093.5c-0.5-0.3-1-0.5-1.5-0.8c-1.1-0.6-2.6-0.2-3.1,1c-0.1,0.2-0.2,0.5-0.2,0.8h5.8
						C638.5,1094,638.2,1093.7,637.8,1093.5z"/>
					<path class="st3" d="M619,1091.8c-1.3,0.1-2.3,1.1-2.3,2.4c0,0.1,0,0.1,0,0.2h4.6c0-0.1,0-0.2,0-0.3
						C621.3,1092.8,620.2,1091.7,619,1091.8z"/>
					<path class="st3" d="M606.1,1085.2c-1.1-0.5-2.5-0.2-3.1,1c-0.3,0.5-0.5,1-0.8,1.5c-0.6,1.1-0.2,2.6,1,3.1
						c1.1,0.5,2.5,0.2,3.1-1c0.3-0.5,0.5-1,0.8-1.5C607.6,1087.3,607.2,1085.8,606.1,1085.2z"/>
					<path class="st3" d="M639.8,1071.5c-0.4-1.1-1.7-2-2.9-1.6c-0.5,0.2-1,0.4-1.5,0.5c-1.1,0.4-2,1.6-1.6,2.9
						c0.4,1.1,1.7,2,2.9,1.6c0.2-0.1,0.4-0.2,0.7-0.2c-1.7-0.5-1.8-3.3,0.3-3.6c1.4-0.2,2.1,1,2,2.1
						C639.9,1072.7,640,1072.1,639.8,1071.5z"/>
					<path class="st3" d="M607,1094.4h1.1C607.7,1094.3,607.3,1094.3,607,1094.4z"/>
					<path class="st3" d="M594.2,1094.4h4.6c0-0.5-0.2-1.1-0.7-1.5C596.5,1091.3,594.4,1092.7,594.2,1094.4z"/>
					<path class="st3" d="M641.2,1089c2.2,2.1,5.4-1.3,3.2-3.4C642.3,1083.6,639.1,1086.9,641.2,1089z"/>
					<path class="st3" d="M613.9,1066.9c2.2,2.1,5.4-1.3,3.2-3.4C614.9,1061.5,611.7,1064.9,613.9,1066.9z"/>
					<path class="st3" d="M595,1082.3c2.2,2.1,5.4-1.3,3.2-3.4C596,1076.9,592.8,1080.2,595,1082.3z"/>
					<path class="st3" d="M601.5,1065.5c-2.2-2.1-5.4,1.3-3.2,3.4C600.5,1070.9,603.7,1067.5,601.5,1065.5z"/>
					<path class="st3" d="M628.7,1054.7c-2.2-2.1-5.4,1.3-3.2,3.4C627.7,1060.1,630.9,1056.8,628.7,1054.7z"/>
					<path class="st3" d="M639.9,1045.2c-2.4,0.3-1.9,3.9,0.5,3.7C642.7,1048.6,642.3,1044.9,639.9,1045.2z"/>
					<path class="st3" d="M642.8,1056.2c-2.4,0.3-1.9,3.9,0.5,3.7C645.6,1059.6,645.2,1055.9,642.8,1056.2z"/>
					<path class="st3" d="M647.7,1052.5c2.4-0.3,1.9-3.9-0.5-3.7C644.9,1049.2,645.3,1052.8,647.7,1052.5z"/>
					<path class="st3" d="M656,1053.8c-2.4,0.3-1.9,3.9,0.5,3.7C658.8,1057.2,658.3,1053.5,656,1053.8z"/>
					<path class="st3" d="M649.9,1041.9c-2.4,0.3-1.9,3.9,0.5,3.7C652.7,1045.3,652.3,1041.6,649.9,1041.9z"/>
					<path class="st3" d="M636.1,1055.7c2.4-0.3,1.9-3.9-0.5-3.7C633.3,1052.3,633.8,1056,636.1,1055.7z"/>
					<path class="st3" d="M660.4,1048c-0.4-0.9-1.5-1.4-2.4-1c-0.4,0.2-0.8,0.4-1.3,0.5c-0.9,0.4-1.4,1.5-1,2.4
						c0.4,0.9,1.5,1.4,2.4,1c0.4-0.2,0.8-0.4,1.3-0.5C660.3,1050,660.9,1048.9,660.4,1048z"/>
					<path class="st3" d="M650.6,1057.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2-0.5-2.6,0.3c-0.6,0.8-0.5,2,0.3,2.6
						c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2,0.5,2.6-0.3C651.5,1059.5,651.4,1058.3,650.6,1057.7z"/>
					<path class="st3" d="M639.4,1063.3c-0.4-0.9-1.5-1.4-2.4-1c-0.9,0.4-1.4,1.5-1,2.4c0.2,0.4,0.4,0.8,0.5,1.3
						c0.4,0.9,1.5,1.4,2.4,1c0.9-0.4,1.4-1.5,1-2.4C639.8,1064.2,639.6,1063.7,639.4,1063.3z"/>
					<path class="st3" d="M643.7,1039.3c0.9,0.3,2.1,0,2.4-1c0.1-0.4,0.3-0.8,0.4-1.2c0.3-0.9,0-2.1-1-2.4c-0.9-0.3-2.1,0-2.4,1
						c-0.1,0.4-0.3,0.8-0.4,1.2C642.4,1037.8,642.7,1038.9,643.7,1039.3z"/>
					<path class="st3" d="M645,1069c0.8,0,1.5-0.1,2.3-0.1c1,0,1.8-1.1,1.6-2.1c-0.2-1-1-1.6-2.1-1.6c-0.8,0-1.5,0.1-2.3,0.1
						c-1,0-1.8,1.1-1.6,2.1C643.1,1068.5,643.9,1069,645,1069z"/>
					<path class="st3" d="M657.2,1068c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-1-1.1-1.8-2.1-1.6
						c-1.1,0.2-1.6,1-1.6,2.1c0,1,0.5,2.1,1.2,2.9c0.7,0.7,1.8,1,2.6,0.3c0.7-0.6,1-1.8,0.3-2.6
						C657.4,1068.2,657.3,1068.1,657.2,1068z M657,1067.5c0,0,0,0.1,0,0.2C657,1067.6,657,1067.6,657,1067.5z M657.2,1067.9
						c0-0.1-0.1-0.1-0.1-0.2C657.2,1067.8,657.2,1067.9,657.2,1067.9z"/>
					<path class="st3" d="M660.8,1061c-2.4,0.3-1.9,3.9,0.5,3.7C663.6,1064.4,663.2,1060.7,660.8,1061z"/>
					<path class="st3" d="M647.5,1070.6c-2.4,0.3-1.9,3.9,0.5,3.7C650.3,1074,649.8,1070.3,647.5,1070.6z"/>
					<path class="st3" d="M637.7,1071.1c-2.1,0.3-2,3.2-0.3,3.6c0.2,0.1,0.5,0.1,0.7,0c1-0.1,1.5-0.8,1.5-1.6
						C639.8,1072.1,639,1070.9,637.7,1071.1z"/>
					<path class="st3" d="M657,1042.6c2.4-0.3,1.9-3.9-0.5-3.7C654.2,1039.3,654.7,1042.9,657,1042.6z"/>
					<path class="st3" d="M629.3,1044.7c-2.4,0.3-1.9,3.9,0.5,3.7C632.1,1048,631.6,1044.4,629.3,1044.7z"/>
					<path class="st3" d="M629.2,1064c-2.4,0.3-1.9,3.9,0.5,3.7C632,1067.4,631.6,1063.7,629.2,1064z"/>
					<path class="st3" d="M667.8,1053.3c-2.4,0.3-1.9,3.9,0.5,3.7C670.6,1056.7,670.2,1053,667.8,1053.3z"/>
					<path class="st3" d="M623.1,1058.8c2.4-0.3,1.9-3.9-0.5-3.7C620.2,1055.4,620.7,1059.1,623.1,1058.8z"/>
					<path class="st3" d="M630.2,1036.7c1.4-0.2,1.8-1.5,1.4-2.5h-3.3C627.9,1035.4,628.6,1036.9,630.2,1036.7z"/>
					<path class="st3" d="M1762.8,1038c1.7-0.8,1.5-2.8,0.4-3.8h-2.9C1758.5,1035.7,1760.3,1039.2,1762.8,1038z"/>
					<path class="st3" d="M1749.5,1038.2c-2.7,1.2-0.7,5.4,1.9,4.1C1754.1,1041,1752.1,1036.9,1749.5,1038.2z"/>
					<path class="st3" d="M1738.8,1037.2c1.3-0.6,1.5-1.9,1.1-2.9h-4.1C1735.1,1035.9,1736.7,1038.1,1738.8,1037.2z"/>
					<path class="st3" d="M1799.1,1036.9c1-0.4,1.9-1.6,1.5-2.7h-4.5c0.1,0.4,0.2,0.8,0.3,1.2
						C1796.8,1036.5,1797.9,1037.3,1799.1,1036.9z"/>
					<path class="st3" d="M1787.4,1037c1.8,0.4,2.9-1.3,2.6-2.7h-4.1C1785.6,1035.3,1786,1036.6,1787.4,1037z"/>
					<path class="st3" d="M1886.9,1058.2c2.7,1.6,5.1-2.6,2.4-4.2C1886.6,1052.4,1884.2,1056.6,1886.9,1058.2z"/>
					<path class="st3" d="M1894,1063.6c-2.7-1.6-5.1,2.6-2.4,4.2C1894.2,1069.3,1896.7,1065.1,1894,1063.6z"/>
					<path class="st3" d="M1899.2,1075.7c-2.7-1.6-5.1,2.6-2.4,4.2C1899.4,1081.4,1901.9,1077.2,1899.2,1075.7z"/>
					<path class="st3" d="M1902.4,1058.5c-2.7-1.6-5.1,2.6-2.4,4.2C1902.6,1064.2,1905,1060,1902.4,1058.5z"/>
					<path class="st3" d="M1877.1,1061.8c2.7,1.6,5.1-2.6,2.4-4.2C1876.8,1056.1,1874.4,1060.3,1877.1,1061.8z"/>
					<path class="st3" d="M1908.4,1073.2c0.3-1.3-0.4-2.6-1.7-2.9c-0.6-0.1-1.2-0.3-1.7-0.4c-1.2-0.3-2.7,0.4-2.9,1.7
						c-0.3,1.3,0.4,2.6,1.7,2.9c0.6,0.1,1.2,0.3,1.7,0.4C1906.7,1075.2,1908.2,1074.4,1908.4,1073.2z"/>
					<path class="st3" d="M1890.3,1074.7c-0.3-1.2-1.7-2.1-2.9-1.7c-1.2,0.4-2.1,1.6-1.7,2.9c0.1,0.2,0.1,0.4,0.2,0.7
						c0.3,1.2,1.7,2.1,2.9,1.7c1.2-0.4,2.1-1.6,1.7-2.9C1890.4,1075.2,1890.4,1075,1890.3,1074.7z"/>
					<path class="st3" d="M1874.4,1072.4c0.3-1.2-0.4-2.7-1.7-2.9c-1.3-0.3-2.6,0.4-2.9,1.7c-0.1,0.6-0.3,1.2-0.4,1.7
						c-0.3,1.2,0.4,2.7,1.7,2.9c1.3,0.3,2.6-0.4,2.9-1.7C1874.2,1073.6,1874.3,1073,1874.4,1072.4z"/>
					<path class="st3" d="M1901.2,1051.7c0.5-0.3,0.9-0.6,1.4-0.9c1.1-0.7,1.6-2.1,0.9-3.3c-0.7-1.1-2.2-1.6-3.3-0.9
						c-0.5,0.3-0.9,0.6-1.4,0.9c-1.1,0.7-1.6,2.1-0.9,3.3C1898.6,1051.9,1900.1,1052.5,1901.2,1051.7z"/>
					<path class="st3" d="M1880.6,1080.3c-0.8-0.6-1.6-1.1-2.4-1.7c-1-0.7-2.7-0.2-3.3,0.9c-0.6,1.2-0.2,2.5,0.9,3.3
						c0.8,0.6,1.6,1.1,2.4,1.7c1,0.7,2.7,0.2,3.3-0.9C1882.1,1082.3,1881.7,1081,1880.6,1080.3z"/>
					<path class="st3" d="M1888.9,1087c-1.2-0.7-2.5-0.2-3.3,0.9c-0.8,1.1-1.2,2.6-1.1,3.9c0.1,1.2,1,2.5,2.4,2.4
						c1.2-0.1,2.5-1.1,2.4-2.4c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4
						C1890.5,1089.3,1889.9,1087.6,1888.9,1087z M1889.4,1090.7c0,0,0,0.1-0.1,0.2C1889.4,1090.9,1889.4,1090.8,1889.4,1090.7z
						 M1889.3,1091.1c0,0.1,0,0.2,0,0.2C1889.3,1091.2,1889.3,1091.1,1889.3,1091.1z"/>
					<path class="st3" d="M1897.4,1089.3c-1.2-0.3-1.6-1.3-1.4-2.2c-1.2,0.9-1.7,3,0.1,4.1c2.2,1.3,4.2-1.2,3.4-3.1
						C1899.3,1088.9,1898.5,1089.6,1897.4,1089.3z"/>
					<path class="st3" d="M1877,1086.4c-2.7-1.6-5.1,2.6-2.4,4.2C1877.3,1092.1,1879.7,1087.9,1877,1086.4z"/>
					<path class="st3" d="M1866.5,1079.1c-2.7-1.6-5.1,2.6-2.4,4.2C1866.7,1084.8,1869.1,1080.7,1866.5,1079.1z"/>
					<path class="st3" d="M1909.2,1064.9c2.7,1.6,5.1-2.6,2.4-4.2C1908.9,1059.2,1906.5,1063.3,1909.2,1064.9z"/>
					<path class="st3" d="M1876.2,1049.1c2.7,1.6,5.1-2.6,2.4-4.2C1876,1043.4,1873.5,1047.6,1876.2,1049.1z"/>
					<path class="st3" d="M1863.3,1065c-2.7-1.6-5.1,2.6-2.4,4.2C1863.5,1070.7,1866,1066.6,1863.3,1065z"/>
					<path class="st3" d="M1912,1087.2c0.4,0.1,0.6,0.2,0.8,0.4c0.5-1,0.4-2.4-0.9-3.1c-2.7-1.6-5.1,2.6-2.4,4.2
						c0.1,0,0.1,0.1,0.2,0.1C1909.9,1087.8,1910.7,1086.9,1912,1087.2z"/>
					<path class="st3" d="M1861,1054.8c2.7,1.6,5.1-2.6,2.4-4.2C1860.7,1049,1858.3,1053.2,1861,1054.8z"/>
					<path class="st3" d="M1885.9,1037.4c2.2,1.3,4.2-1.3,3.4-3.2h-4.2C1884.5,1035.3,1884.6,1036.6,1885.9,1037.4z"/>
					<path class="st3" d="M1918.5,1082.2c-2.4-0.5-3.2,3.1-0.8,3.7C1920,1086.4,1920.8,1082.7,1918.5,1082.2z"/>
					<path class="st3" d="M1915.6,1094.4h2.8c-0.2-0.3-0.6-0.5-1-0.6C1916.6,1093.6,1916,1093.9,1915.6,1094.4z"/>
					<path class="st3" d="M1924.2,1088.2c-2.4-0.5-3.2,3.1-0.8,3.7C1925.7,1092.5,1926.6,1088.8,1924.2,1088.2z"/>
					<path class="st3" d="M1929.3,1082.5c-2.4-0.5-3.2,3.1-0.8,3.7C1930.8,1086.7,1931.7,1083,1929.3,1082.5z"/>
					<path class="st3" d="M1912.8,1087.6c-0.2-0.2-0.5-0.4-0.8-0.4c-1.3-0.3-2.1,0.6-2.2,1.6c-0.1,0.9,0.3,1.8,1.4,2.1
						C1913.1,1091.3,1914,1088.8,1912.8,1087.6z"/>
					<path class="st3" d="M1935.3,1090.3c-0.5,0-0.9,0-1.4,0.1c-1,0.1-1.9,0.9-1.8,2c0.1,1,0.9,1.9,2,1.8c0.5,0,0.9,0,1.4-0.1
						c1-0.1,1.9-0.9,1.8-2C1937.2,1091.1,1936.3,1090.2,1935.3,1090.3z"/>
					<path class="st3" d="M1927.6,1074c-0.7-0.6-2-0.7-2.7,0.1c-0.3,0.3-0.6,0.7-0.8,1c-0.6,0.8-0.7,2,0.1,2.7
						c0.7,0.6,2,0.7,2.7-0.1c0.3-0.3,0.6-0.7,0.8-1C1928.3,1076,1928.4,1074.7,1927.6,1074z"/>
					<path class="st3" d="M1936.7,1082c-2.4-0.5-3.2,3.1-0.8,3.7C1938.2,1086.3,1939.1,1082.6,1936.7,1082z"/>
					<path class="st3" d="M1908.4,1077.9c-2.4-0.5-3.2,3.1-0.8,3.7C1909.9,1082.1,1910.8,1078.4,1908.4,1077.9z"/>
					<path class="st3" d="M1897.4,1089.3c1.1,0.3,1.9-0.4,2.1-1.2c0.3-1,0-2.2-1.3-2.5c-1.2-0.3-2,0.5-2.2,1.5
						C1895.9,1088,1896.3,1089,1897.4,1089.3z"/>
				</g>
			</g>
			<g>
				<rect id="XMLID_14_" x="1742.1" y="936.2" class="st2" width="192.3" height="133.7"/>
				<g>
					<path class="st3" d="M1751.3,1017.4c1.2,0.4,2.2-0.1,2.8-1c-0.4-0.8-0.3-1.9,0.3-2.6c-0.2-0.5-0.7-1-1.4-1.2
						C1749.8,1011.6,1748.2,1016.4,1751.3,1017.4z"/>
					<path class="st3" d="M1748.3,1032.7c3.1,1,4.7-3.7,1.6-4.8C1746.9,1026.9,1745.3,1031.7,1748.3,1032.7z"/>
					<path class="st3" d="M1760.6,1025.6c-0.1-0.7,0.1-1.6,0.7-2c0-0.9-0.5-1.8-1.6-2.2c-1.8-0.6-3.1,0.8-3.2,2.3
						c0.7-0.1,1.5,0.1,2.1,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.4,0.4,0.6,0.9,0.7,1.4C1760,1026.1,1760.4,1025.9,1760.6,1025.6z"/>
					<path class="st3" d="M1768.5,1033.3c-0.2-0.2-0.5-0.4-0.9-0.5c-2.1-0.7-3.6,1.4-3.2,3.1c0.6,0.3,1.2,0.9,1.3,1.6
						c0.1,0,0.2,0.1,0.3,0.1c1,0.4,1.9,0,2.5-0.6C1767,1036.3,1767,1033.9,1768.5,1033.3z"/>
					<path class="st3" d="M1766.9,1015.8c-0.2-0.5-0.1-1,0-1.4c-2.9-0.7-4.4,3.8-1.4,4.8c1.6,0.5,2.8-0.5,3.1-1.7
						C1767.9,1017.3,1767.2,1016.7,1766.9,1015.8z"/>
					<path class="st3" d="M1743.6,1018.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0.5-0.5,0.9-0.9,1.2v3.7
						C1745.1,1024.2,1746.7,1019.5,1743.6,1018.5z"/>
					<path class="st3" d="M1774.2,1025.6c-0.6,0-1.2-0.1-1.9-0.1c-1.3-0.1-2.6,1-2.7,2.4c0,1.4,1,2.6,2.4,2.7c0.6,0,1.2,0.1,1.9,0.1
						c1.3,0.1,2.6-1,2.7-2.4C1776.6,1026.9,1775.6,1025.6,1774.2,1025.6z"/>
					<path class="st3" d="M1755.3,1036.3c0,0.4-0.1,0.8-0.4,1.2c0.7,0.5,1.7,0.6,2.5,0.2c1.2-0.7,1.8-2.1,1.1-3.4
						c-0.1-0.2-0.2-0.4-0.3-0.7c-0.6-1.2-2.2-1.8-3.4-1.1c-0.6,0.3-1.1,0.9-1.3,1.5C1754.6,1034.4,1755.3,1035.2,1755.3,1036.3z"/>
					<path class="st3" d="M1762.6,1008.4c0.7,0.1,1.5,0,2-0.6c0.4-0.4,0.8-0.8,1.2-1.2c0.9-0.9,1.2-2.5,0.2-3.6
						c-0.9-0.9-2.6-1.2-3.6-0.2c-0.4,0.4-0.8,0.8-1.2,1.2c-0.2,0.2-0.3,0.4-0.4,0.6C1763,1004.7,1763.5,1007,1762.6,1008.4z"/>
					<path class="st3" d="M1750.8,1042.5c-0.3-0.4-0.7-0.8-1.3-1.1c-1-0.4-1.9-0.8-2.9-1.2c-1.2-0.5-2.8,0.4-3.2,1.6
						c-0.4,1.4,0.3,2.6,1.6,3.2c1,0.4,1.9,0.8,2.9,1.2c1.2,0.5,2.8-0.3,3.2-1.5C1750.6,1044,1750.5,1043.2,1750.8,1042.5z"/>
					<path class="st3" d="M1759.4,1046.6c-1.4-0.4-2.6,0.3-3.2,1.6c-0.6,1.3-0.7,2.9-0.2,4.2c0.4,1.3,1.6,2.3,3,2
						c1.2-0.3,2.4-1.6,2-3c0-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.4,0.2-0.4
						C1761.6,1048.6,1760.7,1046.9,1759.4,1046.6z M1760.8,1050.3c0,0.1,0,0.1,0,0.2C1760.8,1050.4,1760.8,1050.4,1760.8,1050.3z
						 M1760.8,1050.7c0,0.1,0,0.2,0,0.3C1760.8,1050.8,1760.7,1050.7,1760.8,1050.7z"/>
					<path class="st3" d="M1769.4,1044.5c-3.1-1-4.7,3.7-1.6,4.8C1770.8,1050.3,1772.4,1045.5,1769.4,1044.5z"/>
					<path class="st3" d="M1747.1,1048.5c-3.1-1-4.7,3.7-1.6,4.8C1748.6,1054.3,1750.2,1049.5,1747.1,1048.5z"/>
					<path class="st3" d="M1775.5,1019.6c3.1,1,4.7-3.7,1.6-4.8C1774.1,1013.8,1772.5,1018.5,1775.5,1019.6z"/>
					<path class="st3" d="M1782.6,1039.1c-1.9-0.6-3.2,0.9-3.2,2.4c0.6,0.4,0.9,1.2,0.8,1.9c0.2,0.2,0.5,0.4,0.9,0.5
						C1784,1044.9,1785.6,1040.2,1782.6,1039.1z"/>
					<path class="st3" d="M1747.5,991.6c-3.1-1-4.7,3.7-1.6,4.8C1748.9,997.4,1750.5,992.6,1747.5,991.6z"/>
					<path class="st3" d="M1780.2,1014.1C1780.1,1014.1,1780.1,1014.1,1780.2,1014.1c-0.8,1.2-0.1,3.2,1.7,3.1
						c2.6-0.2,2.2-4.2-0.3-4c-0.1,0-0.1,0-0.2,0C1781.2,1013.7,1780.8,1014,1780.2,1014.1z"/>
					<path class="st3" d="M1784.6,1029.3c2.6-0.2,2.2-4.2-0.3-4c-0.1,0-0.2,0-0.3,0.1c-0.2,0.6-0.7,1.1-1.4,1.4
						C1782.2,1027.9,1783,1029.4,1784.6,1029.3z"/>
					<path class="st3" d="M1791.1,1018.3c-0.3-0.5-0.9-0.9-1.8-0.8c-2.6,0.2-2.2,4.2,0.3,4c0.9-0.1,1.5-0.7,1.7-1.3
						c-0.1-0.2-0.2-0.5-0.3-0.7C1790.9,1019,1790.9,1018.6,1791.1,1018.3z"/>
					<path class="st3" d="M1800.6,1024.7c-0.2-0.7-0.7-1.3-1.6-1.4c-0.2,0-0.3,0-0.5,0c-2.5,0.3-2.1,4.2,0.4,4
						c0.2,0,0.4-0.1,0.5-0.1C1799,1026.2,1799.3,1024.8,1800.6,1024.7z"/>
					<path class="st3" d="M1792.7,1010c0.1,0.8-0.3,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0c-0.1,1.1,0.6,2.4,2.1,2.3
						C1795.4,1013.8,1795.1,1009.9,1792.7,1010z"/>
					<path class="st3" d="M1776.6,1020.4c-2.6,0.2-2.2,4.2,0.3,4C1779.6,1024.1,1779.2,1020.2,1776.6,1020.4z"/>
					<path class="st3" d="M1800.9,1020.2c0.1,0,0.2,0,0.2-0.1c0.5-0.2,0.9-0.4,1.4-0.5c1-0.4,1.6-1.6,1.2-2.6
						c-0.1-0.3-0.3-0.5-0.5-0.7c-0.5,0.2-1.2,0.2-1.7-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1,0c-0.5,0.2-0.9,0.4-1.4,0.5
						c-1,0.4-1.6,1.6-1.2,2.6c0.2,0.5,0.6,0.9,1,1.1c0.2,0,0.4,0,0.6,0C1800.4,1020.1,1800.7,1020.1,1800.9,1020.2z"/>
					<path class="st3" d="M1790.9,1026.4c-0.6,0-1.2,0.2-1.6,0.7c-0.7,0.9-0.6,2.1,0.2,2.8c0.1,0.1,0.3,0.2,0.4,0.4
						c0.8,0.7,2.2,0.6,2.8-0.2c0.5-0.6,0.6-1.5,0.3-2.2c-0.1,0.1-0.3,0.1-0.5,0.1C1791.5,1028.1,1790.9,1027.2,1790.9,1026.4z"/>
					<path class="st3" d="M1776.5,1034.2c0.2,0.5,0.4,0.9,0.5,1.4c0.4,1,1.6,1.6,2.6,1.2c1-0.4,1.6-1.5,1.2-2.6
						c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1-1.6-1.6-2.6-1.2C1776.7,1032.1,1776.1,1033.2,1776.5,1034.2z"/>
					<path class="st3" d="M1786.1,1005.3c-0.5,0.1-0.9-0.1-1.2-0.4c-0.1,0.8,0.3,1.7,1.1,2c1,0.4,2.2,0.1,2.7-0.9
						c0.2-0.4,0.3-0.8,0.5-1.3c0.4-1,0.1-2.2-0.9-2.7c-0.7-0.3-1.5-0.2-2,0.2C1787.8,1002.4,1787.9,1005.1,1786.1,1005.3z"/>
					<path class="st3" d="M1790.3,1037c-0.1-1.1-1-1.8-2.2-1.8c-0.8,0-1.6,0-2.5,0c-1,0-2,1.2-1.8,2.2c0.1,1.1,1,1.8,2.2,1.8
						c0.8,0,1.6,0,2.5,0C1789.6,1039.2,1790.5,1038.1,1790.3,1037z"/>
					<path class="st3" d="M1799.4,1038.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c0-1-1.2-2-2.2-1.8
						c-1.2,0.2-1.8,1-1.8,2.2c0,1.1,0.4,2.3,1.2,3.2c0.7,0.8,1.9,1.2,2.8,0.5c0.8-0.6,1.2-1.9,0.5-2.8
						C1799.6,1038.8,1799.5,1038.7,1799.4,1038.6z M1799.2,1038.1c0,0,0,0.1,0,0.2C1799.2,1038.2,1799.2,1038.2,1799.2,1038.1z
						 M1799.4,1038.6c-0.1-0.1-0.1-0.1-0.1-0.2C1799.3,1038.5,1799.4,1038.5,1799.4,1038.6z"/>
					<path class="st3" d="M1804.7,1031.4c0.7,0.9,0.5,2.5-1,2.7c-1.1,0.2-1.7-0.7-1.8-1.5c-0.3,1.2,0.4,2.7,2,2.6
						C1806.1,1035,1806.2,1032.2,1804.7,1031.4z"/>
					<path class="st3" d="M1788.7,1041.1c-2.6,0.2-2.2,4.2,0.3,4C1791.6,1044.9,1791.2,1040.9,1788.7,1041.1z"/>
					<path class="st3" d="M1779.3,1041.5c-0.3-0.2-0.8-0.4-1.3-0.3c-2.6,0.2-2.2,4.2,0.3,4c1.1-0.1,1.7-0.9,1.7-1.7
						C1780.2,1042.7,1779.9,1041.9,1779.3,1041.5z"/>
					<path class="st3" d="M1799.6,1007.2c-2.2,0.5-1.9,4.1,0.6,3.9c1.3-0.1,1.8-1.1,1.8-2.1c-0.2,0.2-0.5,0.3-0.8,0.3
						C1799.9,1009.4,1799.3,1008.1,1799.6,1007.2z"/>
					<path class="st3" d="M1771.1,1012.3c-0.3-0.2-0.6-0.2-1.1-0.2c-0.6,0.1-1.1,0.3-1.4,0.8c0.6-0.2,1.1-0.3,1.7-0.5
						C1770.6,1012.3,1770.8,1012.3,1771.1,1012.3z"/>
					<path class="st3" d="M1769.3,1033.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.2c-1.5,0.7-1.4,3,0,3.7c0.3,0.1,0.6,0.2,1,0.2
						c1.1-0.1,1.6-0.8,1.7-1.6C1770,1035.3,1769.3,1034.2,1769.3,1033.1z"/>
					<path class="st3" d="M1811.5,1023.1c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0.6-0.4,1.1-1,1.3c-0.3,1.2,0.5,2.7,2,2.5
						C1814.4,1026.9,1814.1,1022.9,1811.5,1023.1z"/>
					<path class="st3" d="M1760.6,1025.6c0.2,0.9,0.9,1.7,2.1,1.6c2.6-0.2,2.2-4.2-0.3-4c-0.4,0-0.7,0.2-1,0.4
						C1760.7,1024.1,1760.5,1024.9,1760.6,1025.6z"/>
					<path class="st3" d="M1771.9,1003.4c1.9-0.7,1.5-4.1-0.9-3.9c-1.4,0.1-1.9,1.3-1.7,2.4c0.1,0,0.3,0,0.5,0
						C1770.9,1001.9,1771.6,1002.6,1771.9,1003.4z"/>
					<path class="st3" d="M1818.6,1021.8c-2.1,0.3-1.6,3.6,0.5,3.3C1821.2,1024.7,1820.7,1021.5,1818.6,1021.8z"/>
					<path class="st3" d="M1822.6,1034.5c1.1-0.9,0.5-3.2-1.3-2.9c-1.9,0.3-1.7,3.1,0,3.3c0.3-0.2,0.7-0.4,1.2-0.4
						C1822.6,1034.5,1822.6,1034.5,1822.6,1034.5z"/>
					<path class="st3" d="M1825.2,1024.9c-2.1,0.3-1.6,3.6,0.5,3.3C1827.8,1027.9,1827.3,1024.6,1825.2,1024.9z"/>
					<path class="st3" d="M1834.4,1030.9c-0.5-0.4-0.7-1-0.6-1.6c-0.2-0.1-0.4-0.1-0.7,0c-2.1,0.3-1.6,3.6,0.5,3.3
						c0.3,0,0.5-0.1,0.6-0.2C1834.1,1031.8,1834.1,1031.3,1834.4,1030.9z"/>
					<path class="st3" d="M1826.3,1020.8c0.2,0.7,0.8,1.3,1.7,1.1c2.1-0.3,1.6-3.6-0.5-3.3c0,0-0.1,0-0.1,0
						C1827.7,1019.5,1827.2,1020.6,1826.3,1020.8z"/>
					<path class="st3" d="M1814.9,1027.9c-2.1,0.3-1.6,3.6,0.5,3.3C1817.5,1030.9,1817,1027.6,1814.9,1027.9z"/>
					<path class="st3" d="M1834.7,1023.2c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.4-0.9,2.2c0.4,0.8,1.3,1.2,2.2,0.9
						c0.4-0.2,0.7-0.3,1.1-0.5c0.8-0.3,1.3-1.4,0.9-2.2c-0.2-0.5-0.6-0.8-1.1-0.9c-0.2,0.1-0.5,0.2-0.9,0.2
						C1834.9,1023.2,1834.8,1023.2,1834.7,1023.2z"/>
					<path class="st3" d="M1826,1035.1c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3c0.5-0.7,0.4-1.8-0.3-2.3
						c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3C1825.1,1033.6,1825.2,1034.6,1826,1035.1z"/>
					<path class="st3" d="M1818.4,1038c-0.3-0.8-1.4-1.3-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
						c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C1818.8,1038.7,1818.6,1038.3,1818.4,1038z"/>
					<path class="st3" d="M1821.9,1016.4c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
						c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1C1820.8,1015.1,1821.1,1016.1,1821.9,1016.4z"/>
					<path class="st3" d="M1826.9,1041c-0.2-0.9-0.9-1.5-1.9-1.4c-0.7,0-1.3,0.1-2,0.1c-0.9,0.1-1.6,1-1.4,1.9
						c0.2,0.9,0.9,1.5,1.9,1.4c0.7,0,1.3-0.1,2-0.1C1826.4,1042.8,1827.1,1041.8,1826.9,1041z"/>
					<path class="st3" d="M1833.5,1041.8c-0.8-0.5-0.9-1.4-0.6-2.1c-0.2,0-0.3,0-0.5,0c-0.9,0.2-1.4,0.9-1.4,1.9
						c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3C1834.3,1042.1,1833.9,1042.1,1833.5,1041.8z"/>
					<path class="st3" d="M1837.6,1035.6c-1.6,0.2-1.7,2.3-0.7,3c0.3,0,0.6,0.1,0.8,0.1c0.1,0,0.3,0.1,0.4,0.1
						C1840.1,1038.4,1839.6,1035.3,1837.6,1035.6z"/>
					<path class="st3" d="M1825.7,1044.4c-2.1,0.3-1.6,3.6,0.5,3.3C1828.3,1047.4,1827.8,1044.1,1825.7,1044.4z"/>
					<path class="st3" d="M1817,1044.9c-2.1,0.3-1.6,3.6,0.5,3.3C1819.5,1047.9,1819.1,1044.6,1817,1044.9z"/>
					<path class="st3" d="M1833.9,1019.2c2.1-0.3,1.6-3.6-0.5-3.3C1831.4,1016.2,1831.8,1019.5,1833.9,1019.2z"/>
					<path class="st3" d="M1808.9,1022.5c0,0,0,0.1,0.1,0.1C1808.9,1022.6,1808.9,1022.5,1808.9,1022.5z"/>
					<path class="st3" d="M1808.8,1022.2c0,0.1,0,0.1,0,0.1C1808.8,1022.3,1808.8,1022.3,1808.8,1022.2z"/>
					<path class="st3" d="M1810.9,1023.3c0.1-1-0.5-2.1-1.8-1.9c-0.1,0-0.2,0-0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3
						c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.5,0.5,0.3,1.3,0,1.8c0.1,0,0.2,0,0.4,0
						c0.1,0,0.2,0,0.3-0.1C1810.5,1024.4,1810.8,1023.9,1810.9,1023.3z"/>
					<path class="st3" d="M1809.3,1038.7c-2.1,0.3-1.6,3.6,0.5,3.3C1811.8,1041.7,1811.4,1038.4,1809.3,1038.7z"/>
					<path class="st3" d="M1844.2,1031.9c2.1-0.3,1.6-3.6-0.5-3.3C1841.6,1028.9,1842.1,1032.2,1844.2,1031.9z"/>
					<path class="st3" d="M1803.7,1034.1c1.5-0.2,1.7-1.8,1-2.7c-0.3-0.4-0.8-0.6-1.4-0.5c-1,0.1-1.4,1-1.4,1.7
						C1802,1033.5,1802.6,1034.3,1803.7,1034.1z"/>
					<path class="st3" d="M1808.5,1013.7c-0.1,0-0.1,0-0.2,0c0.3,0.4,0.8,0.6,1.4,0.5c2.1-0.3,1.7-3.4-0.4-3.3
						C1810.2,1011.8,1810,1013.6,1808.5,1013.7z"/>
					<path class="st3" d="M1853.7,1035.2c1.9,1.3,4-1.6,2.1-3C1853.9,1030.9,1851.8,1033.8,1853.7,1035.2z"/>
					<path class="st3" d="M1850.6,1042.2c-1.9-1.3-4,1.6-2.1,3C1850.5,1046.5,1852.5,1043.5,1850.6,1042.2z"/>
					<path class="st3" d="M1858.7,1039.7c-1.9-1.3-4,1.6-2.1,3C1858.5,1044,1860.6,1041,1858.7,1039.7z"/>
					<path class="st3" d="M1861.8,1049.1c-1.9-1.3-4,1.6-2.1,3c1.2,0.8,2.4,0,2.7-1c0,0,0-0.1,0-0.1
						C1862.7,1050.3,1862.5,1049.6,1861.8,1049.1z"/>
					<path class="st3" d="M1865.3,1036.4c-1.9-1.3-4,1.6-2.1,3C1865.2,1040.7,1867.2,1037.8,1865.3,1036.4z"/>
					<path class="st3" d="M1846.1,1037.3c1.9,1.3,4-1.6,2.1-3C1846.3,1033,1844.2,1036,1846.1,1037.3z"/>
					<path class="st3" d="M1865.4,1048.5c0.4,0.1,0.8,0.3,1.3,0.4c0.9,0.3,2-0.2,2.3-1.1c0.3-0.9-0.1-2-1.1-2.3
						c-0.4-0.1-0.8-0.3-1.3-0.4c-0.9-0.3-2,0.2-2.3,1.1C1864,1047.1,1864.4,1048.2,1865.4,1048.5z"/>
					<path class="st3" d="M1855.3,1048.4c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.1-1.7-2.1-1.5c-1,0.2-1.7,1.1-1.5,2.1
						c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.4c0.2-0.2,0.5-0.3,0.9-0.3c1-0.1,1.6,0.4,1.9,1
						C1855.1,1049.7,1855.4,1049.1,1855.3,1048.4z"/>
					<path class="st3" d="M1843,1046.4c0.1-0.4,0.3-0.8,0.4-1.3c0.1-0.2,0.1-0.3,0.1-0.5c-0.2-0.4-0.4-0.9-0.5-1.3
						c-0.2-0.2-0.4-0.4-0.7-0.5c-0.9-0.3-2,0.1-2.3,1.1c-0.1,0.4-0.3,0.8-0.4,1.3c-0.3,0.9,0.2,2,1.1,2.3
						C1841.6,1047.8,1842.7,1047.3,1843,1046.4z"/>
					<path class="st3" d="M1864.9,1031.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.4-1.5,0.9-2.4c-0.4-0.8-1.5-1.4-2.4-0.9
						c-0.4,0.2-0.7,0.4-1.1,0.6c-0.8,0.4-1.4,1.5-0.9,2.4C1862.9,1031.2,1864,1031.8,1864.9,1031.3z"/>
					<path class="st3" d="M1847.6,1051.4c-0.6-0.5-1.1-1-1.7-1.4c-0.7-0.6-2-0.3-2.5,0.4c-0.6,0.9-0.3,1.9,0.4,2.5
						c0.6,0.5,1.1,1,1.7,1.4c0.7,0.6,2,0.3,2.5-0.4C1848.6,1053,1848.3,1052,1847.6,1051.4z"/>
					<path class="st3" d="M1851.1,1059.5c-0.5-0.3-0.8-0.6-0.9-1.1c-0.3,0.6-0.4,1.2-0.4,1.9c0,0.9,0.6,1.9,1.7,2c0.9,0,2-0.6,2-1.7
						c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.4,0.4-1,0.2-1.6
						C1853.8,1059.2,1852.6,1060.2,1851.1,1059.5z M1853.4,1060.3c0-0.1,0-0.1,0-0.2C1853.4,1060.1,1853.4,1060.2,1853.4,1060.3z
						 M1853.5,1060c0,0,0-0.1,0.1-0.2C1853.5,1059.8,1853.5,1059.9,1853.5,1060z"/>
					<path class="st3" d="M1857.9,1058.5c-0.3,0.7-0.2,1.5,0.6,2.1c0.6,0.4,1.3,0.4,1.8,0.1c-0.1,0-0.2,0.1-0.3,0.1
						C1858.6,1060.9,1857.8,1059.6,1857.9,1058.5z"/>
					<path class="st3" d="M1844.5,1055.7c-1.9-1.3-4,1.6-2.1,3C1844.3,1060.1,1846.4,1057.1,1844.5,1055.7z"/>
					<path class="st3" d="M1837,1049.6c-1.9-1.3-4,1.6-2.1,3C1836.8,1053.9,1838.9,1050.9,1837,1049.6z"/>
					<path class="st3" d="M1871.2,1040.8c0.4-0.3,1-0.5,1.6-0.3c0.2-0.6,0-1.4-0.7-1.8c-0.6-0.4-1.2-0.4-1.7-0.2
						C1871.1,1039,1871.4,1040,1871.2,1040.8z"/>
					<path class="st3" d="M1846.3,1027.7c0.8,0.6,1.7,0.3,2.3-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.6,0.1-1.2,0.6-1.6
						c-0.1-0.2-0.3-0.4-0.6-0.6C1846.5,1023.4,1844.4,1026.4,1846.3,1027.7z"/>
					<path class="st3" d="M1833.9,1039.9c0-0.6,0.5-1.1,1-1.4c-0.9-0.2-1.8,0.4-2.1,1.2c-0.3,0.7-0.2,1.5,0.6,2.1
						c0.4,0.3,0.8,0.4,1.2,0.3c0.3,0,0.6-0.2,0.9-0.4c0,0-0.1,0-0.1,0C1834.5,1041.5,1833.9,1040.9,1833.9,1039.9z"/>
					<path class="st3" d="M1870.5,1056.4c-1.8-0.8-3.5,1.9-1.7,3.2c1.6,1.2,3.4-0.9,2.7-2.3
						C1871,1057.1,1870.7,1056.8,1870.5,1056.4z"/>
					<path class="st3" d="M1836.6,1028c-1.3-0.9-2.6,0.1-2.8,1.3c-0.1,0.5,0.1,1.1,0.6,1.6c0.3-0.5,0.8-0.8,1.5-0.7
						c0.5,0,0.8,0.2,1,0.5C1837.5,1029.8,1837.6,1028.7,1836.6,1028z"/>
					<path class="st3" d="M1854.3,1019.5c1.9,1.3,4-1.6,2.1-3C1854.5,1015.2,1852.4,1018.2,1854.3,1019.5z"/>
					<path class="st3" d="M1872.8,1061.5c2.3,1.2,4.2-2.3,1.9-3.5C1872.4,1056.7,1870.5,1060.3,1872.8,1061.5z"/>
					<path class="st3" d="M1870.4,1069.6c-0.9-0.5-1.7-0.2-2.3,0.3h2.7C1870.7,1069.8,1870.5,1069.7,1870.4,1069.6z"/>
					<path class="st3" d="M1878.9,1065.7c-2.3-1.2-4.2,2.3-1.9,3.5C1879.3,1070.5,1881.2,1067,1878.9,1065.7z"/>
					<path class="st3" d="M1885.7,1061.2c-2.3-1.2-4.2,2.3-1.9,3.5C1886.1,1066,1888,1062.5,1885.7,1061.2z"/>
					<path class="st3" d="M1866.8,1061.4c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.2-4.2,2.3-1.9,3.5c0.6,0.3,1.2,0.3,1.7,0.1
						C1865.2,1064.1,1865.2,1062,1866.8,1061.4z"/>
					<path class="st3" d="M1886,1052.5c0.5,1-0.1,2.2-1.1,2.6c-0.4,0.2-0.9,0.4-1.3,0.6c-0.3,0.1-0.6,0.2-0.9,0.1
						c0.6,0.3,1.3,0.3,1.8-0.1c0.4-0.3,0.8-0.5,1.1-0.8c0.9-0.6,1.3-1.8,0.7-2.8c-0.5-0.8-1.6-1.2-2.5-0.8
						C1884.7,1051.2,1885.6,1051.7,1886,1052.5z"/>
					<path class="st3" d="M1893.4,1062.8c-2.3-1.2-4.2,2.3-1.9,3.5C1893.8,1067.6,1895.7,1064,1893.4,1062.8z"/>
					<path class="st3" d="M1866.2,1051.4c0.1,1-0.4,2-1.6,2.1c-1,0.1-1.7-0.4-2-1.1c-0.1,0.7,0.2,1.5,1,1.9
						C1865.6,1055.3,1867.2,1052.9,1866.2,1051.4z"/>
					<path class="st3" d="M1853.4,1068c-1.6-0.8-3,0.6-2.9,2h1.5c0.2-0.1,0.5-0.2,0.8-0.3c0.5-0.1,0.9,0,1.3,0.3h0.4
						C1854.5,1069.2,1854.2,1068.4,1853.4,1068z"/>
					<path class="st3" d="M1851.1,1059.5c1.4,0.8,2.7-0.3,2.9-1.6c0.1-0.7-0.1-1.5-1-2c-1.8-0.9-3.3,1-2.8,2.5
						C1850.3,1058.9,1850.6,1059.2,1851.1,1059.5z"/>
					<path class="st3" d="M1871.2,1040.8c0,0,0,0.1,0,0.1c-0.2,0.4-0.3,0.8-0.5,1.3c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0.5,0.4,1.1,1,1.4
						c2.3,1.2,4.2-2.3,1.9-3.5c-0.1-0.1-0.2-0.1-0.4-0.2C1872.2,1040.3,1871.6,1040.5,1871.2,1040.8z"/>
					<path class="st3" d="M1834.7,1023.2c0.1,0,0.2,0,0.3,0.1c0.3,0,0.6,0,0.9-0.2c1.2-0.7,1.1-3-0.6-3.1
						C1833.3,1019.8,1833,1022.7,1834.7,1023.2z"/>
					<path class="st3" d="M1835.9,1030.1c-0.7-0.1-1.2,0.3-1.5,0.7c-0.2,0.4-0.3,1-0.2,1.4c0.2,0.6,0.6,1,1.4,1.1
						c1.7,0.1,2.2-1.9,1.3-2.8C1836.7,1030.3,1836.3,1030.1,1835.9,1030.1z"/>
					<path class="st3" d="M1840.8,1027.7c2.1,0.2,2.4-3.1,0.3-3.3C1838.9,1024.3,1838.7,1027.6,1840.8,1027.7z"/>
					<path class="st3" d="M1847.8,1030.4c-2.1-0.2-2.4,3.1-0.3,3.3C1849.7,1033.8,1849.9,1030.5,1847.8,1030.4z"/>
					<path class="st3" d="M1844.4,1022.1c2.1,0.2,2.4-3.1,0.3-3.3C1842.6,1018.6,1842.3,1021.9,1844.4,1022.1z"/>
					<path class="st3" d="M1830.1,1028.4c2.1,0.2,2.4-3.1,0.3-3.3C1828.2,1025,1828,1028.2,1830.1,1028.4z"/>
					<path class="st3" d="M1848.4,1026.9c0,0.2,0.1,0.4,0.2,0.5c0.4,0.6,1,0.9,1.7,0.8c0.4-0.1,0.8-0.2,1.2-0.2
						c0.8-0.2,1.5-1.1,1.3-1.9c-0.2-0.9-1-1.5-1.9-1.3c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0.1-0.5,0.2-0.7,0.3
						C1848.5,1025.7,1848.2,1026.3,1848.4,1026.9z"/>
					<path class="st3" d="M1839.6,1034.6c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.7,1.7,0.8,2.3,0.2c0.7-0.6,0.8-1.6,0.2-2.3
						c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2C1839.1,1032.9,1839,1033.9,1839.6,1034.6z"/>
					<path class="st3" d="M1831.9,1036.9c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9
						c0.1,0.4,0.2,0.8,0.2,1.2c0.2,0.8,1.1,1.5,1.9,1.3C1831.4,1038.6,1832,1037.8,1831.9,1036.9z"/>
					<path class="st3" d="M1839.7,1015.4c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1c0.5-0.7,0.4-1.8-0.4-2.3
						c-0.7-0.5-1.8-0.4-2.3,0.4c-0.2,0.3-0.4,0.6-0.6,1C1838.8,1013.8,1838.9,1014.9,1839.7,1015.4z"/>
					<path class="st3" d="M1836.9,1038.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3,0-0.5,0-0.8,0.1c-0.5,0.2-1,0.8-1,1.4c0,1,0.6,1.6,1.5,1.8
						c0,0,0.1,0,0.1,0c0.6,0.1,1.3,0.2,1.9,0.3c0.9,0.1,1.7-0.7,1.8-1.5c0-0.8-0.4-1.4-1.1-1.7c-0.1,0-0.3-0.1-0.4-0.1
						C1837.4,1038.7,1837.2,1038.6,1836.9,1038.6z"/>
					<path class="st3" d="M1843.6,1044.7c0.4,0.7,1.4,1.2,2.2,0.8c0.7-0.4,1.2-1.4,0.8-2.2c0-0.1-0.1-0.2-0.2-0.3
						c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.9-0.7-1.7-1.5-1.8c-1,0-1.6,0.6-1.8,1.5
						c-0.1,0.4-0.1,0.9,0,1.3C1843.2,1043.7,1843.3,1044.2,1843.6,1044.7C1843.6,1044.6,1843.6,1044.6,1843.6,1044.7z M1846.4,1043
						c0-0.1-0.1-0.1-0.1-0.2C1846.4,1042.9,1846.4,1042.9,1846.4,1043z M1846.3,1042.5c0,0,0,0.1,0,0.2
						C1846.3,1042.7,1846.3,1042.6,1846.3,1042.5z"/>
					<path class="st3" d="M1850.6,1040.8c0.7,0.1,1.2-0.3,1.5-0.8c-0.5-0.6-0.7-1.5-0.4-2.2c-0.2-0.2-0.5-0.3-0.8-0.3
						C1848.7,1037.4,1848.4,1040.6,1850.6,1040.8z"/>
					<path class="st3" d="M1837.3,1043.6c-2.1-0.2-2.4,3.1-0.3,3.3C1839.2,1047,1839.5,1043.7,1837.3,1043.6z"/>
					<path class="st3" d="M1828.7,1042.2c-2.1-0.2-2.4,3.1-0.3,3.3C1830.5,1045.7,1830.8,1042.4,1828.7,1042.2z"/>
					<path class="st3" d="M1850.8,1020.7c2.1,0.2,2.4-3.1,0.3-3.3C1848.9,1017.3,1848.7,1020.6,1850.8,1020.7z"/>
					<path class="st3" d="M1825.8,1020.8c0.2,0,0.3,0,0.5,0c1-0.2,1.4-1.2,1.2-2.1c-0.2-0.6-0.6-1.1-1.4-1.1
						C1823.9,1017.4,1823.7,1020.6,1825.8,1020.8z"/>
					<path class="st3" d="M1822.6,1034.5c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.1-1.2,0.4c-0.9,0.8-0.7,2.8,0.9,2.9
						C1824.3,1037.9,1824.6,1034.8,1822.6,1034.5z"/>
					<path class="st3" d="M1858.1,1035.3c2.1,0.2,2.4-3.1,0.3-3.3C1856.2,1031.9,1856,1035.1,1858.1,1035.3z"/>
					<path class="st3" d="M1818.3,1025.5c-2.1-0.2-2.4,3.1-0.3,3.3C1820.2,1029,1820.4,1025.7,1818.3,1025.5z"/>
					<path class="st3" d="M1828.3,1010.7c2.1,0.2,2.4-3.1,0.3-3.3C1826.4,1007.3,1826.1,1010.5,1828.3,1010.7z"/>
					<path class="st3" d="M1794.8,1002.9c-2,0.2-1.7,3.3,0.3,3.1C1797.1,1005.9,1796.8,1002.7,1794.8,1002.9z"/>
					<path class="st3" d="M1797.3,1015.5c2-0.2,1.7-3.3-0.3-3.1C1795,1012.5,1795.3,1015.7,1797.3,1015.5z"/>
					<path class="st3" d="M1799.6,1007.2c-0.3,1,0.3,2.3,1.6,2.1c0.3,0,0.6-0.2,0.8-0.3c1-0.8,0.6-3-1.1-2.8
						C1800.2,1006.3,1799.8,1006.7,1799.6,1007.2z"/>
					<path class="st3" d="M1808.3,1013.7c0.1,0,0.1,0,0.2,0c1.5-0.1,1.7-1.9,0.9-2.7c-0.3-0.3-0.7-0.4-1.2-0.4
						C1806.3,1010.8,1806.5,1013.7,1808.3,1013.7z"/>
					<path class="st3" d="M1803.7,1003.4c2-0.2,1.7-3.3-0.3-3.1C1801.4,1000.5,1801.7,1003.6,1803.7,1003.4z"/>
					<path class="st3" d="M1792.7,1010c-0.1-0.8-0.7-1.5-1.7-1.4c-1.8,0.2-1.7,2.8-0.2,3.1c0.2,0,0.3,0,0.5,0
						C1792.3,1011.6,1792.8,1010.8,1792.7,1010z"/>
					<path class="st3" d="M1809.1,1005.3c-0.8,0.3-1.2,1.2-0.9,2c0.3,0.8,1.2,1.2,2,0.9c0.4-0.1,0.7-0.3,1.1-0.4
						c0.8-0.3,1.2-1.2,0.9-2c-0.3-0.8-1.2-1.2-2-0.9C1809.8,1005,1809.4,1005.1,1809.1,1005.3z"/>
					<path class="st3" d="M1801.6,1016.2c0.5,0.4,1.1,0.4,1.7,0.2c0.2-0.1,0.4-0.2,0.5-0.4c0.5-0.7,0.5-1.6-0.2-2.2
						c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.5-1.7-0.5-2.2,0.2c-0.5,0.7-0.5,1.6,0.2,2.2C1801.3,1016,1801.5,1016.1,1801.6,1016.2z"/>
					<path class="st3" d="M1794,1018.3c-0.3-0.8-1.2-1.2-2-0.9c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.3-0.2,0.7,0,1.1
						c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,1.2,1.2,2,0.9c0.8-0.3,1.2-1.2,0.9-2
						C1794.3,1019,1794.1,1018.7,1794,1018.3z"/>
					<path class="st3" d="M1798.1,998c0.7,0.3,1.8,0,2.1-0.8c0.1-0.3,0.3-0.7,0.4-1c0.3-0.8,0-1.7-0.8-2.1c-0.7-0.3-1.8,0-2.1,0.8
						c-0.1,0.3-0.3,0.7-0.4,1C1797.1,996.7,1797.3,997.6,1798.1,998z"/>
					<path class="st3" d="M1799.1,1023.2c0.5,0,0.9,0,1.4,0c0.8,0,1.5-0.9,1.4-1.7c-0.1-0.6-0.5-1-1-1.2c-0.2-0.1-0.5-0.2-0.7-0.2
						c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0-1.4,0c-0.8,0-1.5,0.9-1.4,1.7c0.1,0.9,0.8,1.4,1.7,1.4
						C1798.7,1023.2,1798.9,1023.2,1799.1,1023.2z"/>
					<path class="st3" d="M1809.2,1022.8c0,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.3,0-0.3
						c0-0.1,0-0.3-0.1-0.4c-0.2-0.6-1-1.1-1.6-1c-0.9,0.1-1.4,0.8-1.4,1.7c0,0.9,0.4,1.8,0.9,2.5c0.5,0.6,1.5,0.9,2.2,0.3
						c0.1-0.1,0.2-0.2,0.3-0.4C1809.5,1024.1,1809.6,1023.4,1809.2,1022.8z M1809,1022.6c0-0.1-0.1-0.1-0.1-0.1
						C1808.9,1022.5,1808.9,1022.6,1809,1022.6z M1808.9,1022.4c0,0,0-0.1,0-0.1C1808.8,1022.3,1808.8,1022.3,1808.9,1022.4z"/>
					<path class="st3" d="M1812.5,1019.9c2-0.2,1.7-3.3-0.3-3.1C1810.2,1017,1810.5,1020.1,1812.5,1019.9z"/>
					<path class="st3" d="M1800.7,1024.7c-1.4,0.1-1.7,1.5-1.1,2.4c0.3,0.4,0.7,0.8,1.4,0.7
						C1802.9,1027.6,1802.6,1024.5,1800.7,1024.7z"/>
					<path class="st3" d="M1792.3,1024.8c-0.9,0.1-1.4,0.8-1.4,1.5c0,0.8,0.6,1.7,1.7,1.6c0.2,0,0.3-0.1,0.5-0.1
						C1794.5,1027.2,1794.1,1024.7,1792.3,1024.8z"/>
					<path class="st3" d="M1809.4,1001.1c2-0.2,1.7-3.3-0.3-3.1C1807.1,998.2,1807.4,1001.3,1809.4,1001.1z"/>
					<path class="st3" d="M1786.1,1005.3c1.9-0.2,1.7-2.9,0.1-3.1c-0.1,0-0.2,0-0.4,0c-1.5,0.1-1.7,1.8-0.9,2.7
						C1785.1,1005.2,1785.5,1005.4,1786.1,1005.3z"/>
					<path class="st3" d="M1785.6,1021.7c2-0.2,1.7-3.3-0.3-3.1C1783.3,1018.8,1783.6,1021.9,1785.6,1021.7z"/>
					<path class="st3" d="M1818.6,1013.5c2-0.2,1.7-3.3-0.3-3.1C1816.3,1010.6,1816.6,1013.7,1818.6,1013.5z"/>
					<path class="st3" d="M1780.1,1014.1C1780.1,1014.1,1780.1,1014.1,1780.1,1014.1c0.7-0.1,1.1-0.4,1.3-0.9c0.4-1-0.2-2.4-1.5-2.2
						C1777.9,1011.2,1778.2,1014.2,1780.1,1014.1z"/>
					<path class="st3" d="M1786.7,995.5c2-0.2,1.7-3.3-0.3-3.1C1784.4,992.6,1784.7,995.7,1786.7,995.5z"/>
					<path class="st3" d="M1747.4,1012c3.1,0,3.1-4.7,0-4.8C1744.3,1007.2,1744.3,1011.9,1747.4,1012z"/>
					<path class="st3" d="M1749.3,1026.5c3.1,0,3.1-4.7,0-4.8C1746.3,1021.8,1746.3,1026.5,1749.3,1026.5z"/>
					<path class="st3" d="M1754.1,1016.5c0.3,0.7,1,1.3,2.1,1.3c3.1,0,3.1-4.7,0-4.8c-0.8,0-1.4,0.4-1.8,0.9
						C1753.8,1014.6,1753.7,1015.6,1754.1,1016.5z"/>
					<path class="st3" d="M1766.5,1025.6c3.1,0,3.1-4.7,0-4.8C1763.5,1020.8,1763.5,1025.6,1766.5,1025.6z"/>
					<path class="st3" d="M1760.7,1009.3c0.9,0,1.5-0.4,1.9-0.9c0.9-1.4,0.4-3.7-1.7-3.8c0,0-0.1,0-0.1,0
						C1757.7,1004.5,1757.7,1009.3,1760.7,1009.3z"/>
					<path class="st3" d="M1743,1018.4c0.3-1,0-2.2-0.9-2.8v4.1C1742.5,1019.3,1742.9,1018.9,1743,1018.4z"/>
					<path class="st3" d="M1771.1,1012.3c-0.3,0-0.5,0-0.8,0.1c-0.6,0.2-1.1,0.3-1.7,0.5c-0.7,0.2-1.4,0.8-1.6,1.5
						c-0.2,0.4-0.2,0.9,0,1.4c0.3,0.9,1,1.5,1.8,1.7c0.4,0.1,0.7,0.1,1.1,0c0.6-0.2,1.1-0.3,1.7-0.5c1.2-0.3,2.1-1.7,1.7-2.9
						C1772.9,1013.1,1772,1012.4,1771.1,1012.3z"/>
					<path class="st3" d="M1758.5,1024.4c-0.5-0.5-1.3-0.8-2.1-0.7c-0.5,0.1-1,0.3-1.3,0.7c-0.9,0.9-0.9,2.4,0,3.4
						c0.2,0.2,0.3,0.3,0.5,0.5c0.9,0.9,2.5,0.9,3.4,0c0.5-0.6,0.7-1.3,0.7-2c-0.1-0.5-0.3-1-0.7-1.4
						C1758.8,1024.7,1758.7,1024.6,1758.5,1024.4z"/>
					<path class="st3" d="M1742.7,1034.9c1.2-0.4,2-1.6,1.7-2.9c-0.2-0.6-0.3-1.1-0.5-1.7c-0.2-0.8-0.9-1.5-1.8-1.7v6.4
						C1742.3,1035,1742.5,1035,1742.7,1034.9z"/>
					<path class="st3" d="M1753.2,1000.2c1.1,0.6,2.6,0.3,3.3-0.8c0.2-0.5,0.5-1,0.7-1.4c0.6-1.1,0.3-2.6-0.8-3.3
						c-1.1-0.6-2.6-0.3-3.3,0.8c-0.2,0.5-0.5,1-0.7,1.4C1751.8,998.1,1752,999.6,1753.2,1000.2z"/>
					<path class="st3" d="M1750,1038.5c1,0.1,1.9,0.2,2.9,0.2c0.8,0.1,1.6-0.5,2-1.2c0.2-0.4,0.4-0.8,0.4-1.2c0-1.1-0.7-1.9-1.7-2.2
						c-0.2-0.1-0.5-0.1-0.7-0.2c-1-0.1-1.9-0.2-2.9-0.2c-1.2-0.1-2.4,1.2-2.4,2.4C1747.7,1037.4,1748.7,1038.3,1750,1038.5z"/>
					<path class="st3" d="M1764.4,1035.8c-0.3-0.2-0.7-0.3-1-0.2c-1.4,0.1-2.3,1-2.4,2.4c-0.1,1.3,0.3,2.8,1,3.9
						c0.7,1,2.1,1.6,3.2,0.9c1-0.6,1.6-2.2,0.9-3.2c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3
						c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6C1765.6,1036.8,1765,1036.2,1764.4,1035.8z M1765.9,1039.1
						c-0.1-0.1-0.1-0.2-0.1-0.2C1765.8,1038.9,1765.8,1039,1765.9,1039.1z M1765.7,1038.7c0,0,0-0.1,0-0.2
						C1765.7,1038.5,1765.7,1038.6,1765.7,1038.7z"/>
					<path class="st3" d="M1771.6,1030.8c-1.5,0-2.3,1.2-2.3,2.4c0,1.1,0.6,2.1,1.9,2.3c0.1,0,0.3,0,0.4,0
						C1774.7,1035.5,1774.7,1030.8,1771.6,1030.8z"/>
					<path class="st3" d="M1752.9,1040.9c-1.2,0-1.9,0.7-2.2,1.5c-0.2,0.7-0.1,1.6,0.3,2.2c0.4,0.6,1,1,1.9,1
						C1756,1045.7,1756,1040.9,1752.9,1040.9z"/>
					<path class="st3" d="M1742.1,1040.7v3.2C1742.8,1043,1742.8,1041.6,1742.1,1040.7z"/>
					<path class="st3" d="M1769.7,1006.6c1.9,0,2.6-1.8,2.2-3.2c-0.3-0.8-1-1.5-2.2-1.5c-0.2,0-0.3,0-0.5,0
						C1766.7,1002.4,1766.8,1006.6,1769.7,1006.6z"/>
					<path class="st3" d="M1784,1025.3c0.5-1.4-0.2-3.3-2.1-3.3c-3.1,0-3.1,4.7,0,4.8c0.3,0,0.5,0,0.7-0.1
						C1783.3,1026.5,1783.7,1025.9,1784,1025.3z"/>
					<path class="st3" d="M1864.6,1053.5c1.2-0.2,1.7-1.2,1.6-2.1c-0.1-1-0.8-2-2.1-1.8c-0.9,0.1-1.4,0.7-1.6,1.3c0,0,0,0.1,0,0.1
						c-0.1,0.4,0,0.9,0.1,1.3C1862.9,1053.1,1863.6,1053.6,1864.6,1053.5z"/>
					<path class="st3" d="M1867.2,1061.3c-0.2,0-0.3,0.1-0.4,0.1c-1.6,0.5-1.6,2.7-0.4,3.5c0.3,0.2,0.8,0.4,1.3,0.3
						C1870.2,1064.9,1869.7,1061,1867.2,1061.3z"/>
					<path class="st3" d="M1871.4,1057.3c0.3,0.1,0.6,0.2,0.9,0.1c2.5-0.3,2-4.2-0.5-3.9c-1.6,0.2-2,1.8-1.4,2.9
						C1870.7,1056.8,1871,1057.1,1871.4,1057.3z"/>
					<path class="st3" d="M1881.2,1058.7c-2.5,0.3-2,4.2,0.5,3.9C1884.2,1062.3,1883.7,1058.4,1881.2,1058.7z"/>
					<path class="st3" d="M1875.2,1049.9c2.5-0.3,2-4.2-0.5-3.9C1872.2,1046.3,1872.7,1050.2,1875.2,1049.9z"/>
					<path class="st3" d="M1859.6,1056.9c-1,0.1-1.5,0.8-1.6,1.6c-0.1,1.2,0.6,2.5,2.1,2.3c0.1,0,0.2,0,0.3-0.1
						C1862.5,1060.1,1862,1056.5,1859.6,1056.9z"/>
					<path class="st3" d="M1883.6,1055.7c0.4-0.2,0.9-0.4,1.3-0.6c0.9-0.4,1.5-1.6,1.1-2.6c-0.4-0.8-1.3-1.4-2.2-1.2
						c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.2-0.9,0.4-1.3,0.6c-0.9,0.4-1.5,1.6-1.1,2.6c0.3,0.7,1,1.1,1.7,1.2
						C1883,1055.8,1883.3,1055.8,1883.6,1055.7z"/>
					<path class="st3" d="M1875.5,1062.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2.1-0.5-2.8,0.4c-0.6,0.9-0.5,2.1,0.4,2.8
						c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2.1,0.5,2.8-0.4C1876.4,1064.8,1876.3,1063.6,1875.5,1062.9z"/>
					<path class="st3" d="M1861,1067.9c-0.8,0.4-1.3,1.2-1.2,2.1h4.2c-0.1-0.3-0.3-0.7-0.4-1C1863.2,1068,1862,1067.4,1861,1067.9z"
						/>
					<path class="st3" d="M1868.1,1043.3c0.7,0.3,1.6,0.1,2.2-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.3-0.8,0.5-1.3c0,0,0-0.1,0-0.1
						c0.2-0.8,0-1.8-0.8-2.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.9-0.4-2.2,0-2.6,1c-0.2,0.4-0.3,0.8-0.5,1.3
						C1866.7,1041.7,1867.1,1042.9,1868.1,1043.3z"/>
					<path class="st3" d="M1882.3,1046.8c2.5-0.3,2-4.2-0.5-3.9C1879.3,1043.2,1879.8,1047.1,1882.3,1046.8z"/>
					<path class="st3" d="M1852.7,1049c-0.3,0-0.6,0.2-0.9,0.3c-1.5,1-0.8,3.9,1.4,3.6c1.5-0.2,1.9-1.8,1.4-2.8
						C1854.3,1049.4,1853.7,1048.9,1852.7,1049z"/>
					<path class="st3" d="M1852.7,1069.7c-0.3,0-0.6,0.1-0.8,0.3h2C1853.6,1069.7,1853.2,1069.6,1852.7,1069.7z"/>
					<path class="st3" d="M1893.9,1058.1c-2.5,0.3-2,4.2,0.5,3.9C1896.9,1061.7,1896.4,1057.8,1893.9,1058.1z"/>
					<path class="st3" d="M1845.6,1060.2c-2.5,0.3-2,4.2,0.5,3.9C1848.7,1063.8,1848.2,1059.9,1845.6,1060.2z"/>
					<path class="st3" d="M1852.1,1040c0.4,0.4,0.9,0.6,1.6,0.5c2.5-0.3,2-4.2-0.5-3.9c-0.8,0.1-1.3,0.6-1.5,1.2
						C1851.4,1038.5,1851.5,1039.4,1852.1,1040z"/>
				</g>
				<g>
					<path id="XMLID_13_" class="st4" d="M2072.3,871V1043c0,9.9-8.1,18-18,18h-134.7c-3.8,0-7.2-2.4-8.5-5.9
						c-17.2-48.4-63.3-83-117.6-83c-13.8,0-27.1,2.3-39.6,6.4c-5.9,2-11.9-2.3-11.9-8.5V743.3c0-9.9,8.1-18,18-18h151.7
						c6.8,0,13.3,2.5,18.3,7.1l11.8,10.9l4.2,3.9l45.2,41.5l75.2,69.1C2070.2,861.2,2072.3,866,2072.3,871z"/>
					<g>
						<path class="st5" d="M1922.2,1029c-2.3-2-5.4,1.5-3.1,3.5C1921.4,1034.6,1924.5,1031.1,1922.2,1029z"/>
						<path class="st5" d="M1914.1,1041.2c-2.3-2-5.4,1.5-3.1,3.5C1913.3,1046.7,1916.4,1043.2,1914.1,1041.2z"/>
						<path class="st5" d="M1925.1,1039.4c-0.3,1.3-1.4,2.4-3.1,2c-0.4-0.1-0.7-0.3-1-0.5c0,0.6,0.2,1.2,0.8,1.8
							C1924,1044.6,1927,1041.4,1925.1,1039.4z"/>
						<path class="st5" d="M1926.9,1051.3c-2.2-0.9-4.5,2.1-2.5,4c0.1,0.1,0.1,0.1,0.2,0.2
							C1923.7,1053.9,1924.8,1051.2,1926.9,1051.3z"/>
						<path class="st5" d="M1933.9,1035.8c-2.3-2-5.4,1.5-3.1,3.5C1933.1,1041.4,1936.2,1037.8,1933.9,1035.8z"/>
						<path class="st5" d="M1912.9,1032.3c-0.9,0.1-1.7-0.4-2.2-1.2c-0.2-0.3-0.3-0.6-0.3-1c-1.8,0.2-3.2,2.6-1.4,4.1
							C1910.7,1035.8,1913,1034.1,1912.9,1032.3z"/>
						<path class="st5" d="M1937,1051.1c0.5-1.2,0.1-2.6-1.1-3.2c-0.5-0.3-1.1-0.5-1.6-0.8c-1.1-0.5-2.6-0.1-3.2,1.1
							c-0.5,1.2-0.1,2.6,1.1,3.2c0.5,0.3,1.1,0.5,1.6,0.8C1935,1052.7,1936.5,1052.3,1937,1051.1z"/>
						<path class="st5" d="M1916.8,1047C1916.8,1047,1916.8,1047,1916.8,1047c-1.4,0.1-2.4,1.2-2.3,2.5c0,0.2,0,0.5,0,0.7
							c0.1,1.2,1.2,2.3,2.5,2.2c1.3-0.1,2.3-1.2,2.2-2.5c0-0.2,0-0.5,0-0.7c0-0.3-0.1-0.6-0.3-0.9
							C1918,1048.3,1917.2,1047.8,1916.8,1047z"/>
						<path class="st5" d="M1934.1,1029.1c0.1,0,0.2-0.1,0.3-0.1c-0.3-1.6,0.8-3.5,2.7-3.3c0-0.1,0-0.1,0-0.2c-0.4-1.1-1.8-2-3-1.5
							c-0.5,0.2-1,0.4-1.5,0.6c-1.1,0.5-2,1.7-1.5,3C1931.6,1028.8,1932.9,1029.6,1934.1,1029.1z"/>
						<path class="st5" d="M1940.1,1039.3c-1.5,0.6-2.4,2.6-0.9,4c0.5,0.4,1,0.6,1.5,0.6C1938.6,1043.1,1938.7,1040.4,1940.1,1039.3
							z"/>
						<path class="st5" d="M1911.2,1022.1c-0.1,0-0.2,0-0.3,0c2.2,1.4,4.9-1.9,2.8-3.8c-0.7-0.6-1.4-0.7-2.1-0.5
							C1913.3,1018.6,1913.5,1021.5,1911.2,1022.1z"/>
						<path class="st5" d="M1898,1020.9c-1.8-1.5-4,0.2-3.9,2c0.5,0.6,1,1.3,1.4,2C1897.7,1025.8,1900.1,1022.7,1898,1020.9z"/>
						<path class="st5" d="M1925.2,1008.9c-2.3-2-5.4,1.5-3.1,3.5C1924.4,1014.4,1927.5,1010.9,1925.2,1008.9z"/>
						<path class="st5" d="M1959.7,1036.3c-2.7-0.5-3.7,3.1-1.8,4.4c0.4-0.1,0.8-0.1,1.3,0c0.3,0.1,0.5,0.2,0.7,0.3
							C1962,1040.3,1962.3,1036.7,1959.7,1036.3z"/>
						<path class="st5" d="M1959,1051.1c-1.1-0.2-2,0.3-2.4,1.1c0.9,0,1.7,0.5,2.2,1.3c0.1,0.2,0.3,0.4,0.4,0.7
							c0.3,0.5,0.4,1,0.3,1.6C1961.3,1054.8,1961.5,1051.5,1959,1051.1z"/>
						<path class="st5" d="M1966.4,1048.3c3,0.5,3.9-4.2,0.9-4.7C1964.3,1043.1,1963.4,1047.8,1966.4,1048.3z"/>
						<path class="st5" d="M1976.3,1053.2c-3-0.5-3.9,4.2-0.9,4.7C1978.5,1058.5,1979.3,1053.7,1976.3,1053.2z"/>
						<path class="st5" d="M1973.8,1036.1c-0.1-0.1-0.3-0.1-0.4-0.1c-3-0.5-3.9,4.2-0.9,4.7c2.2,0.4,3.2-1.9,2.5-3.5
							C1974.5,1037,1974.1,1036.6,1973.8,1036.1z"/>
						<path class="st5" d="M1951.7,1043c-3-0.5-3.9,4.2-0.9,4.7C1953.9,1048.3,1954.8,1043.6,1951.7,1043z"/>
						<path class="st5" d="M1981.5,1045.5c-0.6,0.1-1.2,0.1-1.8,0.2c-1.2,0.1-2.3,1.3-2.2,2.6c0.2,1.3,1.3,2.3,2.6,2.2
							c0.6-0.1,1.2-0.1,1.8-0.2c1.2-0.1,2.3-1.3,2.2-2.6C1983.9,1046.4,1982.8,1045.4,1981.5,1045.5z"/>
						<path class="st5" d="M1967,1056.8c-1.3-0.3-1.9-1.4-1.9-2.5c-0.3,0.1-0.6,0.2-0.8,0.4c-1,0.8-1.4,2.2-0.6,3.3
							c0.1,0.2,0.3,0.4,0.4,0.6c0.7,1,2.3,1.4,3.3,0.6c0.8-0.6,1.1-1.5,1-2.4C1968,1056.9,1967.6,1057,1967,1056.8z"/>
						<path class="st5" d="M1952,1058.6c-0.1-1.2-1.3-2.3-2.6-2.2c-1.3,0.2-2.3,1.3-2.2,2.6c0.1,0.6,0.1,1.2,0.2,1.8
							c0,0.1,0,0.1,0,0.2h4.7c0-0.2,0.1-0.4,0-0.6C1952.1,1059.8,1952.1,1059.2,1952,1058.6z"/>
						<path class="st5" d="M1969.6,1024.9c-0.5,0.7-1.3,1.1-2.2,0.8c-0.4,0.5-0.7,0.9-1,1.3c-0.8,1-0.8,2.6,0.3,3.4
							c1,0.8,2.6,0.8,3.4-0.3c0.3-0.4,0.7-0.9,1-1.3c0.8-1,0.8-2.6-0.3-3.4C1970.4,1025.1,1970,1024.9,1969.6,1024.9z"/>
						<path class="st5" d="M1982.4,1034.9c-2.7-0.1-3.4,4.2-0.5,4.8c1.9,0.3,3-1.4,2.7-2.9c-0.2,0-0.4,0.1-0.6,0
							C1983.1,1036.7,1982.5,1035.8,1982.4,1034.9z"/>
						<path class="st5" d="M1948.3,1034.8c0-0.4,0.1-0.8,0.2-1.2c-0.1-1-0.6-1.9-1.9-2.1c-3-0.5-3.9,4.2-0.9,4.7
							C1947,1036.4,1947.9,1035.7,1948.3,1034.8z"/>
						<path class="st5" d="M1936.5,1058.9c0-0.1-0.1-0.2-0.1-0.3C1936.4,1058.7,1936.4,1058.8,1936.5,1058.9z"/>
						<path class="st5" d="M1936.7,1059.3c-0.1-0.1-0.2-0.2-0.2-0.4C1936.6,1059,1936.6,1059.1,1936.7,1059.3z"/>
						<path class="st5" d="M1936.9,1059.5C1936.9,1059.5,1936.8,1059.4,1936.9,1059.5C1936.8,1059.4,1936.9,1059.5,1936.9,1059.5z"
							/>
						<path class="st5" d="M1991.2,1057.1c-2.4-0.4-3.4,2.3-2.3,3.8h3.7C1993.6,1059.7,1993.3,1057.5,1991.2,1057.1z"/>
						<path class="st5" d="M1933.4,1046.6c3,0.5,3.9-4.2,0.9-4.7C1931.2,1041.3,1930.4,1046.1,1933.4,1046.6z"/>
						<path class="st5" d="M1950.8,1021.9c3,0.5,3.9-4.2,0.9-4.7C1948.6,1016.6,1947.8,1021.3,1950.8,1021.9z"/>
						<path class="st5" d="M2026.8,1035.7c2.4-0.7,1.3-4.5-1.1-3.7C2023.3,1032.7,2024.4,1036.4,2026.8,1035.7z"/>
						<path class="st5" d="M2030.7,1042.9c-2.4,0.7-1.3,4.5,1.1,3.7C2034.3,1045.9,2033.1,1042.2,2030.7,1042.9z"/>
						<path class="st5" d="M2034,1034.4c-2.4,0.7-1.3,4.5,1.1,3.7C2037.5,1037.4,2036.4,1033.7,2034,1034.4z"/>
						<path class="st5" d="M2045.2,1041.8c2.4-0.7,1.3-4.5-1.1-3.7C2041.6,1038.8,2042.8,1042.5,2045.2,1041.8z"/>
						<path class="st5" d="M2035.6,1026.6c-2.4,0.7-1.3,4.5,1.1,3.7C2039.1,1029.6,2038,1025.9,2035.6,1026.6z"/>
						<path class="st5" d="M2022.5,1039.8c-2.4,0.7-1.3,4.5,1.1,3.7C2026,1042.8,2024.9,1039.1,2022.5,1039.8z"/>
						<path class="st5" d="M2046.3,1030.2c-0.4-0.1-0.9,0-1.3,0.3c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.8-0.6,2.7
							c0.6,0.9,1.8,1.2,2.7,0.6c0.4-0.3,0.8-0.5,1.2-0.8c0.2-0.1,0.4-0.3,0.5-0.5C2046.4,1032.9,2045.8,1031.4,2046.3,1030.2z"/>
						<path class="st5" d="M2039.1,1043.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.8c-0.5,1-0.2,2.1,0.8,2.6
							c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.8C2040.4,1044.7,2040.1,1043.6,2039.1,1043.1z"/>
						<path class="st5" d="M2028.5,1051c-0.6-0.9-1.8-1.2-2.7-0.6c-0.9,0.6-1.2,1.8-0.6,2.7c0.3,0.4,0.5,0.8,0.8,1.2
							c0.6,0.9,1.8,1.2,2.7,0.6c0.9-0.6,1.2-1.8,0.6-2.7C2029,1051.8,2028.7,1051.4,2028.5,1051z"/>
						<path class="st5" d="M2027.3,1021.3c-0.1,0.4-0.2,0.9-0.2,1.3c-0.2,1,0.4,2.1,1.5,2.4c1,0.2,2.2-0.4,2.4-1.5
							c0.1-0.4,0.2-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.4C2028.7,1019.7,2027.5,1020.3,2027.3,1021.3z"/>
						<path class="st5" d="M2036.5,1051.6c-0.8,0.2-1.6,0.3-2.3,0.5c-1,0.2-1.6,1.5-1.3,2.4c0.4,1.1,1.4,1.5,2.4,1.3
							c0.8-0.2,1.6-0.3,2.3-0.5c1-0.2,1.6-1.5,1.3-2.4C2038.6,1051.9,2037.6,1051.4,2036.5,1051.6z"/>
						<path class="st5" d="M2048.2,1052.8c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
							c-0.1-0.2-0.1-0.3-0.1-0.4c-0.2-1-1.5-1.6-2.4-1.3c-1.1,0.4-1.5,1.4-1.3,2.4c0.2,1.1,0.9,2.1,1.8,2.8c0.8,0.6,2,0.8,2.8-0.1
							C2049,1054.8,2049.1,1053.5,2048.2,1052.8z M2047.6,1052.1c0,0,0,0.1,0.1,0.2C2047.7,1052.3,2047.7,1052.2,2047.6,1052.1z
							 M2047.8,1052.4c0,0.1,0.1,0.1,0.1,0.2C2047.9,1052.5,2047.8,1052.5,2047.8,1052.4z"/>
						<path class="st5" d="M2050.5,1044.6c-2.4,0.7-1.3,4.5,1.1,3.7C2054,1047.6,2052.9,1043.9,2050.5,1044.6z"/>
						<path class="st5" d="M2038.2,1057.1c-2.1,0.6-1.5,3.5,0.3,3.8h0.5c0.1,0,0.2,0,0.3-0.1
							C2041.7,1060.2,2040.6,1056.4,2038.2,1057.1z"/>
						<path class="st5" d="M2028,1059.4c-0.8,0.3-1.2,0.9-1.3,1.5h3.7C2030.2,1059.9,2029.3,1059,2028,1059.4z"/>
						<path class="st5" d="M2043.1,1026.1c2.4-0.7,1.3-4.5-1.1-3.7C2039.6,1023.1,2040.7,1026.8,2043.1,1026.1z"/>
						<path class="st5" d="M2014.4,1033.3c-2.4,0.7-1.3,4.5,1.1,3.7C2018,1036.3,2016.8,1032.5,2014.4,1033.3z"/>
						<path class="st5" d="M2017.9,1053.5c-2.4,0.7-1.3,4.5,1.1,3.7C2021.4,1056.5,2020.3,1052.8,2017.9,1053.5z"/>
						<path class="st5" d="M2056.8,1035.3c0.1,0,0.2-0.1,0.2-0.1c-0.2,0-0.4,0-0.7,0.1c-2.1,0.7-1.5,3.7,0.4,3.8
							C2055.4,1038.4,2055,1036.1,2056.8,1035.3z"/>
						<path class="st5" d="M2009.3,1045.5c-2.4,0.7-1.3,4.5,1.1,3.7C2012.9,1048.5,2011.8,1044.7,2009.3,1045.5z"/>
						<path class="st5" d="M2013.9,1024.8c2.4-0.7,1.3-4.5-1.1-3.7C2010.3,1021.8,2011.5,1025.5,2013.9,1024.8z"/>
						<path class="st5" d="M2061.2,984.9c-1.8-1-3.4,1.8-1.5,2.8C2061.5,988.7,2063,985.9,2061.2,984.9z"/>
						<path class="st5" d="M2057.6,994.2c-1.8-1-3.4,1.8-1.5,2.8C2057.9,998.1,2059.5,995.2,2057.6,994.2z"/>
						<path class="st5" d="M2064.5,991.2c-1.8-1-3.4,1.8-1.5,2.8C2064.8,995.1,2066.3,992.2,2064.5,991.2z"/>
						<path class="st5" d="M2068.2,999.3c-1.8-1-3.4,1.8-1.5,2.8C2068.5,1003.1,2070.1,1000.3,2068.2,999.3z"/>
						<path class="st5" d="M2070,987.7c-1.8-1-3.4,1.8-1.5,2.8C2070.3,991.5,2071.9,988.7,2070,987.7z"/>
						<path class="st5" d="M2053.1,990.4c1.8,1,3.4-1.8,1.5-2.8C2052.8,986.6,2051.3,989.4,2053.1,990.4z"/>
						<path class="st5" d="M2070.1,996.4c-0.2,0.9,0.3,1.7,1.2,1.9c0.3,0.1,0.7,0.2,1,0.2v-3.3c-0.1,0-0.2,0-0.3-0.1
							C2071.2,995,2070.2,995.5,2070.1,996.4z"/>
						<path class="st5" d="M2060.3,997.7c-0.8,0.3-1.3,1.1-1.1,2c0,0.1,0.1,0.3,0.1,0.4c0.2,0.8,1.2,1.4,2,1.1
							c0.8-0.3,1.3-1.1,1.1-2c0-0.1-0.1-0.3-0.1-0.4C2062,998,2061.1,997.4,2060.3,997.7z"/>
						<path class="st5" d="M2049.4,1000c0.9,0.2,1.7-0.3,1.9-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.2-1.9
							c-0.9-0.2-1.7,0.3-1.9,1.2c-0.1,0.4-0.2,0.8-0.3,1.2C2048,998.8,2048.5,999.8,2049.4,1000z"/>
						<path class="st5" d="M2068.4,979.8c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.5-1,1.5-0.5,2.2c0.5,0.7,1.5,1.1,2.2,0.5
							c0.3-0.2,0.6-0.4,0.9-0.6c0.7-0.5,1-1.5,0.5-2.2C2070.1,979.6,2069.1,979.3,2068.4,979.8z"/>
						<path class="st5" d="M2055.8,1005.5c0.3-0.1,0.5-0.4,0.7-0.7c0.3-0.6,0.2-1.1-0.1-1.6
							C2056.6,1004.1,2056.4,1005,2055.8,1005.5z"/>
						<path class="st5" d="M2054.5,1001.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.5-1.8-0.1-2.2,0.6c-0.4,0.7-0.2,1.5,0.4,2
							C2052.2,1003.1,2053,1001.8,2054.5,1001.8z"/>
						<path class="st5" d="M2060.4,1011.9c0.8-0.1,1.7-0.8,1.6-1.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
							c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.1-1.8-0.6-2.2c-0.8-0.4-1.7-0.1-2.2,0.6c-0.5,0.8-0.7,1.8-0.6,2.7
							C2058.8,1011.2,2059.5,1012,2060.4,1011.9z M2062,1009.5c0,0,0,0.1-0.1,0.1C2061.9,1009.7,2062,1009.6,2062,1009.5z
							 M2061.9,1009.8c0,0.1,0,0.1,0,0.2C2061.9,1009.9,2061.9,1009.8,2061.9,1009.8z"/>
						<path class="st5" d="M2068,1006.9c-1.8-1-3.4,1.8-1.5,2.8C2068.3,1010.7,2069.9,1007.9,2068,1006.9z"/>
						<path class="st5" d="M2052,1009.7c1.8,1,3.4-1.8,1.5-2.8C2051.7,1005.9,2050.2,1008.7,2052,1009.7z"/>
						<path class="st5" d="M2046.3,1002.2c-1.8-1-3.4,1.8-1.5,2.8C2046.6,1006,2048.2,1003.2,2046.3,1002.2z"/>
						<path class="st5" d="M2053.9,979.1c-1.8-1-3.4,1.8-1.5,2.8C2054.1,982.9,2055.7,980,2053.9,979.1z"/>
						<path class="st5" d="M2042.5,994.1c-0.1-0.5,0.1-1,0.4-1.4c-1.2,0.3-1.9,2.2-0.4,3c0.5,0.3,0.9,0.3,1.3,0.1
							C2043,995.4,2042.6,994.8,2042.5,994.1z"/>
						<path class="st5" d="M2042.2,986c1.8,1,3.4-1.8,1.5-2.8C2041.9,982.1,2040.4,985,2042.2,986z"/>
						<path class="st5" d="M2060.1,971c-1.8-1-3.4,1.8-1.5,2.8C2060.4,974.8,2061.9,972,2060.1,971z"/>
						<path class="st5" d="M1751,964.9c2.5-0.9,1.1-4.8-1.4-3.9C1747.1,961.8,1748.5,965.8,1751,964.9z"/>
						<path class="st5" d="M1755.5,972.4c-2.5,0.9-1.1,4.8,1.4,3.9C1759.4,975.4,1758,971.5,1755.5,972.4z"/>
						<path class="st5" d="M1758.5,963.2c-2.5,0.9-1.1,4.8,1.4,3.9C1762.4,966.2,1761,962.3,1758.5,963.2z"/>
						<path class="st5" d="M1770.8,966.6c-0.4-0.2-0.9-0.2-1.4,0c-1.6,0.5-1.6,2.2-0.9,3.3C1768.2,968.4,1769,966.6,1770.8,966.6z"
							/>
						<path class="st5" d="M1759.8,954.9c-2.5,0.9-1.1,4.8,1.4,3.9C1763.8,957.9,1762.4,954,1759.8,954.9z"/>
						<path class="st5" d="M1749.2,971.9c0.3-1.4-0.9-3.1-2.7-2.5c-2.5,0.9-1.1,4.8,1.4,3.9c0,0,0,0,0.1,0
							C1748.2,972.8,1748.6,972.2,1749.2,971.9z"/>
						<path class="st5" d="M1770,958.5c-0.4,0.3-0.8,0.6-1.3,0.9c-0.9,0.6-1.2,2-0.5,2.9c0.7,0.9,1.9,1.2,2.9,0.5
							c0.4-0.3,0.8-0.6,1.3-0.9c0.9-0.6,1.2-2,0.5-2.9C1772.3,958.2,1771,957.9,1770,958.5z"/>
						<path class="st5" d="M1764.4,972.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.8-0.4-1.9-0.2-2.5,0.6c0,0,0.1,0,0.1,0
							c1.3-0.2,2.6,0.9,2.8,2.2c0,0.3,0.1,0.5,0.1,0.8c0.3-0.1,0.6-0.1,0.9-0.2c0.1-0.1,0.1-0.2,0.2-0.3
							C1765.8,973.9,1765.4,972.7,1764.4,972.2z"/>
						<path class="st5" d="M1754.8,951.8c0.1-0.5,0.1-0.9,0.2-1.4c0.1-1.1-0.5-2.3-1.7-2.4c-1.1-0.2-2.3,0.5-2.4,1.7
							c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,1.1,0.5,2.3,1.7,2.4C1753.4,953.6,1754.6,953,1754.8,951.8z"/>
						<path class="st5" d="M1766.5,950c-2.5,0.9-1.1,4.8,1.4,3.9c0.4-0.1,0.6-0.3,0.9-0.6c0.1-0.5,0.2-0.9,0.5-1.3
							C1769.2,950.8,1768.1,949.5,1766.5,950z"/>
						<path class="st5" d="M1782.4,963.1c-2.5,0.9-1.1,4.8,1.4,3.9C1786.3,966.2,1784.9,962.2,1782.4,963.1z"/>
						<path class="st5" d="M1806.3,961.7c-2.1,0.1-1.9,3.4,0.2,3.3C1808.6,964.9,1808.4,961.6,1806.3,961.7z"/>
						<path class="st5" d="M1808.2,971.8c-0.7,0-1.1,0.4-1.4,0.9c1,0.1,2,0.2,3,0.4C1809.7,972.3,1809.1,971.7,1808.2,971.8z"/>
						<path class="st5" d="M1812.6,965.4c-2.1,0.1-1.9,3.4,0.2,3.3c1.4-0.1,1.8-1.6,1.3-2.6c0,0,0-0.1-0.1-0.1
							C1813.7,965.7,1813.3,965.4,1812.6,965.4z"/>
						<path class="st5" d="M1820.2,970.4c-2.1,0.1-1.9,3.4,0.2,3.3C1822.5,973.6,1822.3,970.3,1820.2,970.4z"/>
						<path class="st5" d="M1816.1,962.5c1.7-0.5,1.4-3.4-0.6-3.2c-2.1,0.1-2,3.2,0,3.3C1815.6,962.5,1815.9,962.5,1816.1,962.5z"/>
						<path class="st5" d="M1802,967.6c-2.1,0.1-1.9,3.4,0.2,3.3C1804.3,970.7,1804.2,967.4,1802,967.6z"/>
						<path class="st5" d="M1822.5,964.4c-0.4,0.1-0.8,0.3-1.2,0.4c-0.8,0.3-1.4,1.3-1,2.1c0.3,0.8,1.2,1.3,2.1,1
							c0.4-0.1,0.8-0.3,1.2-0.4c0.8-0.3,1.4-1.3,1-2.1C1824.3,964.6,1823.4,964.1,1822.5,964.4z"/>
						<path class="st5" d="M1814.7,973.3c-0.7-0.6-1.8-0.6-2.3,0.1c1,0.2,2,0.3,3,0.5c-0.1-0.1-0.2-0.2-0.3-0.3
							C1815,973.5,1814.9,973.4,1814.7,973.3z"/>
						<path class="st5" d="M1812.3,955.8c0.2-0.3,0.3-0.7,0.5-1c0.3-0.8,0.1-1.8-0.7-2.2c-0.8-0.4-1.9-0.1-2.2,0.7
							c-0.2,0.3-0.3,0.7-0.5,1c-0.3,0.8-0.1,1.8,0.7,2.2C1810.8,956.9,1811.9,956.7,1812.3,955.8z"/>
						<path class="st5" d="M1821.8,960.4c2.1-0.1,1.9-3.4-0.2-3.3C1819.5,957.2,1819.7,960.5,1821.8,960.4z"/>
						<path class="st5" d="M1796.7,960.5c-0.2,0-0.3,0-0.4,0.1c0.5,0.4,0.9,1,1,1.7c0.1,0.5,0,1.1-0.3,1.5
							C1799,963.7,1798.8,960.4,1796.7,960.5z"/>
						<path class="st5" d="M1831.1,974c0.7,0,1.1-0.4,1.3-0.9c-0.6,0.5-1.5,0.6-2.3,0.1c-0.3-0.2-0.5-0.4-0.7-0.6
							C1829.6,973.4,1830.2,974,1831.1,974z"/>
						<path class="st5" d="M1791.2,970c0.2,0.7,0.1,1.4-0.2,2c0.2,0,0.4,0,0.6,0C1791.9,971.3,1791.8,970.5,1791.2,970z"/>
						<path class="st5" d="M1797.8,950.1c-0.2,0-0.4,0.1-0.6,0.1c0.5,0.9,0.4,2.1-0.2,3c0.3,0.2,0.6,0.3,1,0.2
							C1800.1,953.3,1799.9,950,1797.8,950.1z"/>
						<path class="st5" d="M1841.6,965.5c-2.6-1.4-4.8,2.6-2.2,4C1841.9,971,1844.1,967,1841.6,965.5z"/>
						<path class="st5" d="M1836.4,978.6c-0.9-0.5-1.7-0.3-2.3,0.1c1.1,0.4,2.2,0.8,3.3,1.2C1837.3,979.4,1837,979,1836.4,978.6z"/>
						<path class="st5" d="M1846.2,974.5c-2.6-1.4-4.8,2.6-2.2,4C1846.5,979.9,1848.7,975.9,1846.2,974.5z"/>
						<path class="st5" d="M1851.3,985.8c-0.4-0.2-0.7-0.3-1.1-0.3c0.6,0.3,1.2,0.7,1.8,1C1851.9,986.3,1851.7,986,1851.3,985.8z"/>
						<path class="st5" d="M1854,969.5c-2.6-1.4-4.8,2.6-2.2,4C1854.3,974.9,1856.6,970.9,1854,969.5z"/>
						<path class="st5" d="M1830.1,973.2c0.9,0.5,1.7,0.3,2.3-0.1c1.2-0.9,1.6-2.9-0.1-3.8c-2.3-1.3-4.3,1.7-2.9,3.4
							C1829.6,972.8,1829.9,973,1830.1,973.2z"/>
						<path class="st5" d="M1858.3,980.5c-0.1,0-0.1,0-0.2,0c1.3,1.8-1.1,4.5-3.1,3c-0.9-0.7-1-1.7-0.6-2.6
							c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2,1.2,0.4,2.5,1.7,2.7c0.5,0.1,1.1,0.3,1.6,0.4c1.2,0.3,2.5-0.5,2.7-1.7
							C1860.3,982.1,1859.6,980.8,1858.3,980.5z"/>
						<path class="st5" d="M1852.8,963.2c0.4-0.3,0.9-0.6,1.3-0.9c1-0.7,1.5-2.1,0.8-3.1c-0.6-1-2.1-1.5-3.1-0.8
							c-0.4,0.3-0.9,0.6-1.3,0.9c-1,0.7-1.5,2.1-0.8,3.1C1850.3,963.4,1851.7,963.9,1852.8,963.2z"/>
						<path class="st5" d="M1862.8,971.5c-2.6-1.4-4.8,2.6-2.2,4C1863.1,976.9,1865.3,972.9,1862.8,971.5z"/>
						<path class="st5" d="M1831.3,957.2c-2.6-1.4-4.8,2.6-2.2,4C1831.7,962.6,1833.9,958.6,1831.3,957.2z"/>
						<path class="st5" d="M1817,962.8c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0-0.7,0.1c-1.4,0.4-2.3,2.2-1.4,3.5c0,0,0,0.1,0.1,0.1
							c0.2,0.2,0.4,0.5,0.7,0.6C1817.4,968.2,1819.6,964.3,1817,962.8z"/>
						<path class="st5" d="M1840.2,945.9c-2.6-1.4-4.8,2.6-2.2,4C1840.6,951.3,1842.8,947.3,1840.2,945.9z"/>
						<path class="st5" d="M1855,983.5c2,1.6,4.4-1.2,3.1-3c-0.1-0.2-0.3-0.4-0.5-0.5c-1.3-1-2.7-0.2-3.3,0.9
							C1854,981.7,1854.1,982.8,1855,983.5z"/>
						<path class="st5" d="M1860.9,989.2c-0.8-0.6-1.6-0.5-2.3-0.2c0.3,0.5,0.5,1,0.5,1.5c0.8,0.5,1.5,1,2.3,1.5
							C1861.9,991.1,1861.9,990,1860.9,989.2z"/>
						<path class="st5" d="M1869,985.5c-2.3-1.7-4.9,1.8-2.7,3.5C1868.6,990.7,1871.3,987.2,1869,985.5z"/>
						<path class="st5" d="M1848.4,982.2c-1.3-1-2.7-0.3-3.3,0.9c1.3,0.6,2.6,1.2,3.8,1.8C1849.4,984.1,1849.4,983,1848.4,982.2z"/>
						<path class="st5" d="M1871.7,996.6c-0.5-0.2-1-0.4-1.5-0.6c-0.8-0.3-1.8-0.1-2.4,0.5c1.6,1.2,3.2,2.4,4.7,3.7
							c0.2-0.2,0.3-0.4,0.4-0.7C1873.3,998.3,1872.9,997,1871.7,996.6z"/>
						<path class="st5" d="M1868.4,974.5c-0.5,0.2-0.9,0.4-1.4,0.7c-1,0.5-1.7,1.8-1.2,2.9c0.5,1,1.8,1.7,2.9,1.2
							c0.5-0.2,0.9-0.4,1.4-0.7c1-0.5,1.7-1.8,1.2-2.9C1870.8,974.7,1869.5,974,1868.4,974.5z"/>
						<path class="st5" d="M1874.1,989c-0.7,0.9-0.8,2.2,0.4,3c1.9,1.4,4-0.8,3.4-2.5c-0.5,0.4-1.2,0.7-1.9,0.7
							C1875.2,990.2,1874.5,989.7,1874.1,989z"/>
						<path class="st5" d="M1849,970.6c-2.3-1.7-4.9,1.8-2.7,3.5C1848.6,975.8,1851.3,972.3,1849,970.6z"/>
						<path class="st5" d="M1834.6,974c-2.3-1.7-4.9,1.8-2.7,3.5C1834.2,979.3,1836.9,975.8,1834.6,974z"/>
						<path class="st5" d="M1856.4,964.6c2.3,1.7,4.9-1.8,2.7-3.5C1856.8,959.3,1854.2,962.8,1856.4,964.6z"/>
						<path class="st5" d="M1900.1,1030.9c-0.2,0-0.3,0.1-0.5,0.2c0.8,1.3,1.6,2.6,2.3,3.9
							C1903.7,1033.7,1902.6,1030.3,1900.1,1030.9z"/>
						<path class="st5" d="M1912.5,1040.2c2.8-0.7,1.8-5-1-4.4C1908.6,1036.5,1909.6,1040.8,1912.5,1040.2z"/>
						<path class="st5" d="M1902.9,1025.2c-0.7-0.4-1.4-1-1.9-1.7c-0.5,1.5,0.6,3.5,2.6,3.1c0.7-0.2,1.1-0.5,1.4-1
							C1904.3,1025.6,1903.5,1025.5,1902.9,1025.2z"/>
						<path class="st5" d="M1910.7,1031.1c0.5,0.8,1.3,1.2,2.2,1.2c0.3,0,0.6-0.1,0.8-0.3c0.5-0.3,1-0.5,1.5-0.8c1-0.6,1.5-2,0.9-3
							c-0.6-1-2-1.5-3-0.9c-0.5,0.3-1,0.5-1.5,0.8c-0.7,0.4-1.2,1.2-1.2,2.1C1910.4,1030.5,1910.5,1030.8,1910.7,1031.1z"/>
						<path class="st5" d="M1905.4,1041.2c0,0-0.1,0-0.1-0.1c0.4,0.8,0.8,1.6,1.1,2.3C1906.5,1042.6,1906.2,1041.7,1905.4,1041.2z"
							/>
						<path class="st5" d="M1896.3,1014c-1.1-0.3-2.5,0.3-2.8,1.5c-0.1,0.5-0.2,1-0.4,1.5c-0.3,1.1,0.3,2.5,1.5,2.8
							c1.1,0.3,2.5-0.3,2.8-1.5c0.1-0.5,0.2-1,0.4-1.5C1898.1,1015.7,1897.5,1014.3,1896.3,1014z"/>
						<path class="st5" d="M1915.1,1056.2c0.8-0.8,1-2.4,0.1-3.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
							c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.4c-0.2-1.2-1.6-2-2.7-1.7c-1,0.3-1.6,1.1-1.7,2c0.4,1,0.8,1.9,1.1,2.9
							c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.4,0.4C1912.8,1056.9,1914.2,1057.1,1915.1,1056.2z M1914.8,1052.7
							c-0.1-0.1-0.1-0.1-0.1-0.2C1914.7,1052.6,1914.8,1052.7,1914.8,1052.7z M1914.6,1052.4c0,0-0.1-0.1-0.1-0.2
							C1914.5,1052.3,1914.6,1052.3,1914.6,1052.4z"/>
						<path class="st5" d="M1918.3,1043.8c-1.6,0.4-2,2-1.4,3.1c0.4,0.8,1.1,1.4,2.1,1.3c0.1,0,0.2,0,0.3,0
							C1922.1,1047.5,1921.1,1043.2,1918.3,1043.8z"/>
						<path class="st5" d="M1910.9,1022.1c0.1,0,0.2,0,0.3,0c2.3-0.5,2-3.5,0.4-4.2c-0.4-0.2-0.9-0.2-1.4-0.1
							C1907.5,1018.4,1908.3,1022.4,1910.9,1022.1z"/>
						<path class="st5" d="M1925,1037.6c0.5,0.4,1.1,0.5,1.8,0.4c2.8-0.7,1.8-5-1-4.4c-1.6,0.4-2,2-1.4,3.2
							C1924.6,1037,1924.8,1037.3,1925,1037.6z"/>
						<path class="st5" d="M1873.7,999.3c-0.5,0.2-0.8,0.6-1.1,0.9c1.3,1,2.5,2.1,3.7,3.2C1878.4,1001.8,1876.4,998,1873.7,999.3z"
							/>
						<path class="st5" d="M1884.5,1000.9c-2.9,1.4-0.7,5.8,2.1,4.4C1889.5,1003.9,1887.4,999.5,1884.5,1000.9z"/>
						<path class="st5" d="M1897.7,1003.5c-2.9,1.4-0.7,5.8,2.1,4.4C1902.7,1006.6,1900.6,1002.1,1897.7,1003.5z"/>
						<path class="st5" d="M1885,990.9c-2.9,1.4-0.7,5.8,2.1,4.4C1890,994,1887.9,989.5,1885,990.9z"/>
						<path class="st5" d="M1895.8,998.6c0.9,1,2.4,1.2,3.5,0.2c0.5-0.4,0.9-0.8,1.4-1.2c1-0.9,1.2-2.5,0.2-3.5
							c-0.9-1-2.4-1.2-3.5-0.2c-0.5,0.4-0.9,0.8-1.4,1.2C1895.1,996,1894.9,997.7,1895.8,998.6z"/>
						<path class="st5" d="M1894,1013.8c0.4-1.3-0.2-2.7-1.5-3.1c-0.2-0.1-0.4-0.2-0.7-0.2c-1.2-0.4-2.8,0.2-3.1,1.5
							c-0.4,1.3,0.2,2.7,1.5,3.1c0.2,0.1,0.4,0.2,0.7,0.2C1892.1,1015.7,1893.6,1015.1,1894,1013.8z"/>
						<path class="st5" d="M1876.5,983.6c-1.3-0.1-2.6,0.9-2.7,2.2c0,0.6,0,1.1,0,1.7c0,0.5,0.1,1,0.4,1.5c0.4,0.7,1,1.2,1.9,1.2
							c0.7,0,1.4-0.2,1.9-0.7c0.4-0.4,0.8-0.9,0.8-1.6c0-0.6,0-1.1,0-1.7C1878.8,985,1877.8,983.7,1876.5,983.6z"/>
						<path class="st5" d="M1905.7,1021.1c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
							c-0.1-0.2-0.1-0.4-0.2-0.5c-0.4-1.2-2.2-1.7-3.3-1.2c-1.3,0.7-1.6,2-1.2,3.3c0.2,0.5,0.5,1,0.8,1.4c0.5,0.7,1.2,1.3,1.9,1.7
							c0.6,0.3,1.4,0.5,2,0.3c0.5-0.1,1-0.4,1.4-1C1907,1023.5,1906.9,1021.7,1905.7,1021.1z M1905.3,1020.9
							c-0.1-0.1-0.2-0.1-0.2-0.2C1905.1,1020.7,1905.2,1020.8,1905.3,1020.9z M1905,1020.6c0,0-0.1-0.1-0.1-0.2
							C1904.8,1020.4,1904.9,1020.5,1905,1020.6z"/>
						<path class="st5" d="M1906.9,1010.5c-2.9,1.4-0.7,5.8,2.1,4.4C1911.9,1013.6,1909.8,1009.1,1906.9,1010.5z"/>
						<path class="st5" d="M1894.3,988.8c2.9-1.4,0.7-5.8-2.1-4.4C1889.4,985.7,1891.5,990.2,1894.3,988.8z"/>
						<path class="st5" d="M1914.7,1002.2c2.9-1.4,0.7-5.8-2.1-4.4C1909.7,999.2,1911.8,1003.6,1914.7,1002.2z"/>
						<path class="st5" d="M1855.6,988.2c-0.1,0-0.2,0.1-0.2,0.1c1.3,0.7,2.5,1.4,3.7,2.2c0-0.5-0.2-1.1-0.5-1.5
							C1858,988.1,1856.9,987.6,1855.6,988.2z"/>
						<path class="st5" d="M2059.3,1026.4c-2.5,1.1-0.8,5,1.7,3.9C2063.5,1029.2,2061.8,1025.3,2059.3,1026.4z"/>
						<path class="st5" d="M2066.1,1037.6c-2.5,1.1-0.8,5,1.7,3.9C2070.3,1040.4,2068.6,1036.5,2066.1,1037.6z"/>
						<path class="st5" d="M2068.6,1028c-2.5,1.1-0.8,5,1.7,3.9C2072.8,1030.8,2071.1,1026.9,2068.6,1028z"/>
						<path class="st5" d="M2071,1023.3c2.5-1.1,0.8-5-1.7-3.9C2066.8,1020.5,2068.5,1024.4,2071,1023.3z"/>
						<path class="st5" d="M2057.1,1035.2c-0.1,0-0.2,0-0.2,0.1c-1.8,0.8-1.4,3.1-0.1,3.8c0.5,0.3,1.1,0.4,1.8,0.1
							C2061,1038.1,2059.4,1034.5,2057.1,1035.2z"/>
						<path class="st5" d="M2071.9,1037.7c-0.3,0.8-0.1,1.7,0.5,2.2v-2.9C2072.1,1037.1,2072,1037.4,2071.9,1037.7z"/>
						<path class="st5" d="M2065.4,1050.8c0.9-0.8,1.1-2.1,0.3-3c-0.3-0.4-0.7-0.8-1-1.2c-0.7-0.9-2.1-1.1-3-0.3
							c-0.9,0.8-1.1,2.1-0.3,3c0.3,0.4,0.7,0.8,1,1.2C2063.1,1051.3,2064.6,1051.5,2065.4,1050.8z"/>
						<path class="st5" d="M2061.5,1018.6c1.1,0.1,2.3-0.7,2.4-1.9c0-0.5,0.1-1,0.1-1.5c0.1-1.1-0.7-2.3-1.9-2.4
							c-1.1-0.1-2.3,0.7-2.4,1.9c0,0.5-0.1,1-0.1,1.5C2059.6,1017.3,2060.3,1018.5,2061.5,1018.6z"/>
						<path class="st5" d="M2069.9,1049.9c0.2,0.3,0.4,0.6,0.6,0.8c0.6-1.2,1-2.5,1.3-3.9c-0.3,0.1-0.6,0.2-0.9,0.3
							C2070,1047.5,2069.5,1048.9,2069.9,1049.9z"/>
						<path class="st5" d="M2065.3,1055.7c-1.1,0.5-1.4,1.4-1.2,2.3c1-0.7,2-1.4,2.9-2.3C2066.5,1055.5,2066,1055.4,2065.3,1055.7z"
							/>
						<path class="st5" d="M2047.3,1029.3c-0.5,0.2-0.8,0.6-1,0.9c-0.6,1.1,0.1,2.7,1.3,3c0.4,0.1,0.9,0.1,1.4-0.1
							C2051.5,1032.1,2049.8,1028.2,2047.3,1029.3z"/>
						<path class="st5" d="M2053.6,1050.7c-2.5,1.1-0.8,5,1.7,3.9C2057.9,1053.5,2056.2,1049.6,2053.6,1050.7z"/>
						<path class="st5" d="M2045.2,1047c2.5-1.1,0.8-5-1.7-3.9C2040.9,1044.2,2042.6,1048.1,2045.2,1047z"/>
						<path class="st5" d="M2045.7,1020.2c2.5-1.1,0.8-5-1.7-3.9C2041.5,1017.4,2043.2,1021.3,2045.7,1020.2z"/>
						<path class="st5" d="M1771.5,951.2c-1-0.2-1.8,0.3-2.3,0.9c-0.3,0.4-0.4,0.8-0.5,1.3c-0.1,1.2,0.5,2.5,2,2.7
							C1773.9,956.6,1774.7,951.7,1771.5,951.2z"/>
						<path class="st5" d="M1771.2,966.6c-0.1,0-0.3,0-0.4,0c-1.8,0-2.7,1.8-2.3,3.3c0.2,0.8,0.8,1.5,1.9,1.7
							C1773.7,972,1774.4,967.1,1771.2,966.6z"/>
						<path class="st5" d="M1779.6,958.6c-3.2-0.5-3.9,4.5-0.7,4.9C1782.1,964,1782.8,959.1,1779.6,958.6z"/>
						<path class="st5" d="M1791.2,970c-0.2-0.8-0.8-1.5-1.9-1.7c-2.4-0.4-3.4,2.3-2.4,3.9c1.4-0.1,2.8-0.1,4.1-0.1
							C1791.3,971.4,1791.4,970.7,1791.2,970z"/>
						<path class="st5" d="M1785.8,950.5c-3.2-0.5-3.9,4.5-0.7,4.9C1788.2,955.9,1788.9,951,1785.8,950.5z"/>
						<path class="st5" d="M1763.4,958.5c-3.2-0.5-3.9,4.5-0.7,4.9C1765.9,963.9,1766.6,959,1763.4,958.5z"/>
						<path class="st5" d="M1797.2,962.3c-0.1-0.7-0.5-1.3-1-1.7c-0.5-0.4-1.1-0.6-1.8-0.5c-0.6,0.1-1.2,0.2-1.8,0.2
							c-1.3,0.2-2.4,1.4-2.2,2.8c0.2,1.3,1.4,2.4,2.8,2.2c0.6-0.1,1.2-0.2,1.8-0.2c0.8-0.1,1.5-0.6,1.9-1.2
							C1797.2,963.4,1797.3,962.9,1797.2,962.3z"/>
						<path class="st5" d="M1780.4,970.8c-0.8-1.1-2.4-1.4-3.5-0.5c-0.9,0.7-1.2,1.9-0.8,3c1.7-0.2,3.5-0.4,5.2-0.6
							c0-0.4-0.2-0.9-0.5-1.2C1780.7,971.2,1780.5,971,1780.4,970.8z"/>
						<path class="st5" d="M1761.4,972.4c0,0-0.1,0-0.1,0c-1.3,0.3-2.2,1.4-2.1,2.7c0.1,0.5,0.1,1,0.2,1.5c1.6-0.5,3.2-0.9,4.9-1.3
							c0-0.3-0.1-0.5-0.1-0.8C1764,973.3,1762.8,972.2,1761.4,972.4z"/>
						<path class="st5" d="M1778.6,944.8c1,0.8,2.7,0.8,3.5-0.4c0.3-0.5,0.7-0.9,1-1.4c0.8-1.1,0.7-2.7-0.4-3.5
							c-1-0.8-2.7-0.8-3.5,0.4c-0.3,0.5-0.7,0.9-1,1.4C1777.5,942.4,1777.5,944,1778.6,944.8z"/>
						<path class="st5" d="M1797.2,950.2c-0.3-0.6-0.9-1-1.7-1.1c-3.2-0.5-3.9,4.5-0.7,4.9c1,0.2,1.8-0.2,2.2-0.9
							C1797.6,952.4,1797.7,951.1,1797.2,950.2z"/>
						<path class="st5" d="M1757,951.5c3.2,0.5,3.9-4.5,0.7-4.9C1754.6,946.1,1753.8,951,1757,951.5z"/>
						<path class="st5" d="M1750.6,971.8c-0.5-0.1-1,0-1.4,0.2c-0.6,0.3-1,0.8-1.2,1.4c-0.5,1.3,0.1,3,1.9,3.3
							C1753,977.2,1753.8,972.2,1750.6,971.8z"/>
						<path class="st5" d="M1805,971.9c-0.7-0.1-1.3,0.1-1.8,0.4c1.1,0.1,2.1,0.2,3.1,0.3C1806,972.3,1805.6,972,1805,971.9z"/>
						<path class="st5" d="M1745.2,957.8c-3.2-0.5-3.9,4.5-0.7,4.9C1747.7,963.2,1748.4,958.3,1745.2,957.8z"/>
						<path class="st5" d="M1761.8,936.5c3.2,0.5,3.9-4.5,0.7-4.9C1759.4,931.1,1758.7,936,1761.8,936.5z"/>
						<path class="st5" d="M1950.2,1037.1c3.3,0.8,4.6-4.3,1.3-5.1c-1.5-0.4-2.5,0.4-3,1.5c-0.2,0.4-0.2,0.8-0.2,1.2
							C1948.3,1035.8,1948.9,1036.8,1950.2,1037.1z"/>
						<path class="st5" d="M1949.6,1048.1c-3.3-0.8-4.6,4.3-1.3,5.1C1951.6,1054.1,1952.9,1049,1949.6,1048.1z"/>
						<path class="st5" d="M1957.9,1040.6c-2.2,0.6-2.7,4.4,0,5.1c3,0.8,4.3-3.4,2-4.8c-0.2-0.1-0.4-0.2-0.7-0.3
							C1958.7,1040.5,1958.3,1040.5,1957.9,1040.6z"/>
						<path class="st5" d="M1968.3,1051.8c-1.9-0.5-3.2,1.1-3.2,2.6c0,1.1,0.6,2.1,1.9,2.5c0.5,0.1,1,0.1,1.4,0
							C1970.6,1056.1,1971.1,1052.4,1968.3,1051.8z"/>
						<path class="st5" d="M1965.2,1037.9c3.3,0.8,4.6-4.3,1.3-5.1C1963.2,1032,1961.9,1037,1965.2,1037.9z"/>
						<path class="st5" d="M1942.3,1038.8c-0.9-0.2-1.6,0-2.2,0.4c-1.4,1.1-1.5,3.8,0.6,4.6c0.1,0,0.2,0.1,0.2,0.1
							C1944.3,1044.8,1945.6,1039.7,1942.3,1038.8z"/>
						<path class="st5" d="M1977.3,1046.3c-0.1-1.4-1.2-2.6-2.7-2.6c-0.6,0-1.3,0-1.9,0.1c-1.4,0-2.7,1.3-2.6,2.7
							c0.1,1.4,1.2,2.6,2.7,2.6c0.6,0,1.3,0,1.9-0.1C1976.1,1049,1977.4,1047.7,1977.3,1046.3z"/>
						<path class="st5" d="M1959.1,1054.1c-0.1-0.2-0.3-0.4-0.4-0.7c-0.4-0.7-1.3-1.2-2.2-1.3c-0.5,0-1,0.1-1.4,0.4
							c-1.2,0.8-1.7,2.3-0.9,3.6c0.1,0.2,0.3,0.4,0.4,0.7c0.7,1.2,2.4,1.7,3.6,0.9c0.7-0.5,1.2-1.2,1.3-2
							C1959.5,1055.1,1959.4,1054.6,1959.1,1054.1z"/>
						<path class="st5" d="M1938.8,1053.3c-1.4,0.1-2.6,1.2-2.6,2.7c0,0.6,0,1.3,0.1,1.9c0,0.2,0.1,0.4,0.1,0.7
							c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0.5,0.6,1.2,1,2,1
							c1.4-0.1,2.6-1.2,2.6-2.7c0-0.6,0-1.3-0.1-1.9C1941.4,1054.4,1940.2,1053.2,1938.8,1053.3z"/>
						<path class="st5" d="M1964.4,1021c-1-0.9-2.7-1.1-3.7,0c-0.4,0.5-0.8,0.9-1.2,1.4c-0.9,1-1.1,2.7,0,3.7c1,0.9,2.7,1.1,3.7,0
							c0.4-0.5,0.8-0.9,1.2-1.4C1965.3,1023.7,1965.5,1022,1964.4,1021z"/>
						<path class="st5" d="M1976.9,1032.4c-0.5,0.5-1.3,0.6-2,0.2c-1.1,0.7-1.6,2.3-1,3.5c0.2,0.5,0.6,0.9,1.2,1.1
							c0.1,0.1,0.3,0.1,0.4,0.2C1978.7,1038.2,1980,1033.3,1976.9,1032.4z"/>
						<path class="st5" d="M1934.4,1029c0.2,0.8,0.8,1.6,1.8,1.9c3.3,0.8,4.6-4.3,1.3-5.1c-0.1,0-0.3,0-0.4-0.1
							C1935.2,1025.5,1934.1,1027.4,1934.4,1029z"/>
						<path class="st5" d="M1927.5,1051.4c-0.2,0-0.4-0.1-0.6-0.1c-2.2-0.1-3.2,2.5-2.2,4.1c0.3,0.5,0.8,0.9,1.5,1.1
							C1929.5,1057.3,1930.8,1052.3,1927.5,1051.4z"/>
						<path class="st5" d="M1984.3,1057.2c-2.4-0.6-3.8,2-2.9,3.8h4.5C1986.6,1059.6,1986.3,1057.7,1984.3,1057.2z"/>
						<path class="st5" d="M1925.1,1039.4c0.1-0.6,0.1-1.2-0.2-1.8c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.5
							c-2.9-0.7-4.2,3.1-2.3,4.6c0.3,0.2,0.6,0.4,1,0.5C1923.7,1041.8,1924.9,1040.7,1925.1,1039.4z"/>
						<path class="st5" d="M1942.9,1015.7c3.3,0.8,4.6-4.3,1.3-5.1C1940.9,1009.8,1939.6,1014.8,1942.9,1015.7z"/>
						<path class="st5" d="M1990.6,1004.5c-2-1.6-4.4,1.5-2.4,3.1C1990.2,1009.2,1992.6,1006.1,1990.6,1004.5z"/>
						<path class="st5" d="M1982,1018.1c2,1.6,4.4-1.5,2.4-3.1C1982.4,1013.4,1980,1016.5,1982,1018.1z"/>
						<path class="st5" d="M1993.3,1012.8c-2-1.6-4.4,1.5-2.4,3.1C1992.8,1017.5,1995.3,1014.4,1993.3,1012.8z"/>
						<path class="st5" d="M1996.1,1023.2c-2-1.6-4.4,1.5-2.4,3.1C1995.6,1027.8,1998.1,1024.8,1996.1,1023.2z"/>
						<path class="st5" d="M2000.6,1009.6c-2-1.6-4.4,1.5-2.4,3.1C2000.2,1014.3,2002.6,1011.2,2000.6,1009.6z"/>
						<path class="st5" d="M1979.8,1009.5c2,1.6,4.4-1.5,2.4-3.1C1980.3,1004.8,1977.8,1007.9,1979.8,1009.5z"/>
						<path class="st5" d="M2003.9,1022.2c0.4-1,0-2.2-1-2.6c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.2,0-2.6,1c-0.4,1,0,2.2,1,2.6
							c0.5,0.2,0.9,0.4,1.4,0.6C2002.3,1023.7,2003.5,1023.2,2003.9,1022.2z"/>
						<path class="st5" d="M1989,1021.4c-0.1-1-1.1-1.9-2.2-1.7c-1.1,0.2-1.9,1.1-1.7,2.2c0,0.2,0,0.4,0.1,0.6
							c0.1,1,1.1,1.9,2.2,1.7c1.1-0.2,1.9-1.1,1.7-2.2C1989.1,1021.8,1989.1,1021.6,1989,1021.4z"/>
						<path class="st5" d="M1973.3,1020.1c1,0.4,2.2,0,2.6-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.6c-1-0.4-2.2,0-2.6,1
							c-0.2,0.5-0.4,0.9-0.6,1.4C1971.9,1018.5,1972.3,1019.8,1973.3,1020.1z"/>
						<path class="st5" d="M2000.5,1004.1c0.4-0.2,0.8-0.4,1.2-0.6c0.9-0.4,1.6-1.5,1.1-2.5c-0.4-0.9-1.6-1.6-2.5-1.1
							c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0.4-1.6,1.5-1.1,2.5C1998.4,1003.9,1999.5,1004.5,2000.5,1004.1z"/>
						<path class="st5" d="M1976,1023.5c-0.7,0.9-0.5,2,0.3,2.8c0.6,0.5,1.2,1.1,1.8,1.6c0.8,0.7,2.2,0.5,2.8-0.3
							c0.7-0.9,0.5-2-0.3-2.8c-0.6-0.5-1.2-1.1-1.8-1.6C1978,1022.4,1976.6,1022.6,1976,1023.5z"/>
						<path class="st5" d="M1986.5,1031.2c-0.9-0.7-2-0.5-2.8,0.3c-0.8,0.8-1.3,2-1.3,3.1c0,0.1,0,0.2,0,0.4
							c0.1,0.9,0.7,1.8,1.7,1.9c0.2,0,0.4,0,0.6,0c0.8-0.1,1.6-0.7,1.7-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
							c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3C1987.5,1033.2,1987.3,1031.8,1986.5,1031.2z M1986.3,1034.7
							c0-0.1,0-0.2,0.1-0.2C1986.4,1034.6,1986.3,1034.7,1986.3,1034.7z M1986.4,1034.4c0,0,0-0.1,0.1-0.2
							C1986.5,1034.3,1986.5,1034.3,1986.4,1034.4z"/>
						<path class="st5" d="M1994.3,1032.3c-2-1.6-4.4,1.5-2.4,3.1C1993.9,1036.9,1996.3,1033.8,1994.3,1032.3z"/>
						<path class="st5" d="M1976.9,1032.4c0.9-0.7,1.2-2.1,0.1-3.1c-2-1.6-4.4,1.5-2.4,3.1c0.1,0.1,0.2,0.2,0.3,0.2
							C1975.6,1033,1976.3,1032.8,1976.9,1032.4z"/>
						<path class="st5" d="M1969.6,1024.9c0.6-0.8,0.6-1.9-0.3-2.7c-2-1.6-4.4,1.5-2.4,3.1c0.2,0.2,0.4,0.3,0.6,0.3
							C1968.3,1025.9,1969.1,1025.5,1969.6,1024.9z"/>
						<path class="st5" d="M2007.8,1012.5c-2-1.6-4.4,1.5-2.4,3.1C2007.4,1017.2,2009.8,1014.1,2007.8,1012.5z"/>
						<path class="st5" d="M1980.6,999.1c2,1.6,4.4-1.5,2.4-3.1C1981,994.4,1978.6,997.5,1980.6,999.1z"/>
						<path class="st5" d="M1965.9,1013.6c2,1.6,4.4-1.5,2.4-3.1C1966.3,1008.9,1963.9,1012,1965.9,1013.6z"/>
						<path class="st5" d="M2005.4,1031.8c-2-1.6-4.4,1.5-2.4,3.1C2005,1036.5,2007.4,1033.4,2005.4,1031.8z"/>
						<path class="st5" d="M1967.6,1001.9c2,1.6,4.4-1.5,2.4-3.1C1968.1,997.3,1965.6,1000.3,1967.6,1001.9z"/>
						<path class="st5" d="M1989.7,990.7c2,1.6,4.4-1.5,2.4-3.1C1990.2,986.1,1987.7,989.2,1989.7,990.7z"/>
						<path class="st5" d="M2025.7,987.7c2.7,0.5,3.4-3.8,0.7-4.2C2023.7,983,2022.9,987.2,2025.7,987.7z"/>
						<path class="st5" d="M2025.2,1000.9c2.7,0.5,3.4-3.8,0.7-4.2C2023.2,996.3,2022.5,1000.5,2025.2,1000.9z"/>
						<path class="st5" d="M2032.6,994.2c2.7,0.5,3.4-3.8,0.7-4.2C2030.6,989.5,2029.8,993.7,2032.6,994.2z"/>
						<path class="st5" d="M2040.7,1002.7c2.7,0.5,3.4-3.8,0.7-4.2C2038.7,998,2037.9,1002.2,2040.7,1002.7z"/>
						<path class="st5" d="M2037.9,987.3c2.7,0.5,3.4-3.8,0.7-4.2C2035.9,982.7,2035.2,986.9,2037.9,987.3z"/>
						<path class="st5" d="M2019.3,989.6c-2.7-0.5-3.4,3.8-0.7,4.2C2021.3,994.3,2022.1,990.1,2019.3,989.6z"/>
						<path class="st5" d="M2048.3,993.5c-0.2-1.1-1.2-2-2.3-1.9c-0.5,0.1-1.1,0.1-1.6,0.2c-0.6,0.1-1.2,0.4-1.5,0.9
							c-0.3,0.4-0.4,0.9-0.4,1.4c0.1,0.7,0.6,1.4,1.2,1.7c0.3,0.2,0.7,0.3,1.1,0.2c0.5-0.1,1.1-0.1,1.6-0.2
							C2047.5,995.7,2048.5,994.6,2048.3,993.5z"/>
						<path class="st5" d="M2030.6,1003.4c0.6,0.9,2.1,1.2,3,0.5c0.9-0.7,1.2-2,0.5-3c-0.1-0.2-0.2-0.3-0.4-0.5
							c-0.6-0.9-2.1-1.2-3-0.5c-0.9,0.7-1.2,2-0.5,3C2030.3,1003.1,2030.4,1003.3,2030.6,1003.4z"/>
						<path class="st5" d="M2018,1007.4c1.1-0.2,2-1.2,1.9-2.3c-0.1-0.5-0.1-1.1-0.2-1.6c-0.1-1.1-1.2-2.1-2.3-1.9
							c-1.1,0.2-2,1.2-1.9,2.3c0.1,0.5,0.1,1.1,0.2,1.6C2015.8,1006.6,2016.9,1007.6,2018,1007.4z"/>
						<path class="st5" d="M2033.2,973.9c-0.3,0.4-0.6,0.8-0.9,1.2c-0.7,0.9-0.7,2.3,0.3,3c0.9,0.7,2.3,0.7,3-0.3
							c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,0.7-2.3-0.3-3C2035.3,973,2033.9,973,2033.2,973.9z"/>
						<path class="st5" d="M2024,1011.7c0.9,0.2,1.7,0.4,2.6,0.6c1.1,0.3,2.3-0.7,2.5-1.8c0.2-1.2-0.6-2.2-1.8-2.5
							c-0.9-0.2-1.7-0.4-2.6-0.6c-1.1-0.3-2.3,0.7-2.5,1.8C2022.1,1010.4,2022.9,1011.4,2024,1011.7z"/>
						<path class="st5" d="M2038.2,1015c0-0.1-0.1-0.2-0.2-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.1-0.5
							c0.3-1.1-0.7-2.3-1.8-2.5c-1.2-0.2-2.2,0.6-2.5,1.8c-0.3,1.2-0.2,2.5,0.4,3.6c0.5,1,1.6,1.7,2.8,1.2
							C2038,1017.3,2038.8,1016.1,2038.2,1015z M2038,1014c0,0,0,0.1,0,0.2C2037.9,1014.2,2038,1014.1,2038,1014z M2038,1014.6
							c0-0.1-0.1-0.2,0-0.2C2038,1014.5,2038,1014.5,2038,1014.6z"/>
						<path class="st5" d="M2043.7,1012.3c2.7,0.5,3.4-3.8,0.7-4.2C2041.7,1007.6,2041,1011.8,2043.7,1012.3z"/>
						<path class="st5" d="M2026.3,1014.3c-2.7-0.5-3.4,3.8-0.7,4.2C2028.3,1019,2029,1014.8,2026.3,1014.3z"/>
						<path class="st5" d="M2015.2,1011.5c-2.7-0.5-3.4,3.8-0.7,4.2C2017.2,1016.2,2017.9,1012,2015.2,1011.5z"/>
						<path class="st5" d="M2046.3,986.3c2.7,0.5,3.4-3.8,0.7-4.2C2044.3,981.6,2043.6,985.9,2046.3,986.3z"/>
						<path class="st5" d="M2013.9,983.5c2.7,0.5,3.4-3.8,0.7-4.2C2011.9,978.8,2011.2,983,2013.9,983.5z"/>
						<path class="st5" d="M2007.4,1005c2.7,0.5,3.4-3.8,0.7-4.2C2005.4,1000.3,2004.7,1004.6,2007.4,1005z"/>
						<path class="st5" d="M2055.8,1005.5c0.6-0.6,0.8-1.5,0.6-2.3c-0.2-0.7-0.7-1.3-1.6-1.4c-0.1,0-0.2,0-0.3,0
							c-1.4-0.1-2.2,1.3-2.1,2.4c0.1,0.8,0.6,1.6,1.7,1.8C2054.8,1006.2,2055.4,1006,2055.8,1005.5z"/>
						<path class="st5" d="M2003,993c2.7,0.5,3.4-3.8,0.7-4.2C2001,988.3,2000.3,992.5,2003,993z"/>
						<path class="st5" d="M2018.3,970.7c2.7,0.5,3.4-3.8,0.7-4.2C2016.3,966,2015.6,970.2,2018.3,970.7z"/>
						<path class="st5" d="M2053.1,944.8c0-0.4,0-0.8,0.1-1.2c-1.1,0.4-1.8,2.1-0.4,3c0.4,0.3,0.9,0.3,1.2,0.2
							C2053.4,946.4,2053.1,945.6,2053.1,944.8z"/>
						<path class="st5" d="M2050.1,953.1c-1.8-1.2-3.6,1.6-1.8,2.8C2050.1,957.1,2051.9,954.3,2050.1,953.1z"/>
						<path class="st5" d="M2057.5,950.6c-1.8-1.2-3.6,1.6-1.8,2.8C2057.4,954.6,2059.3,951.8,2057.5,950.6z"/>
						<path class="st5" d="M2058.8,962c1.8,1.2,3.6-1.6,1.8-2.8C2058.8,958,2057,960.8,2058.8,962z"/>
						<path class="st5" d="M2063.5,947.4c-1.8-1.2-3.6,1.6-1.8,2.8C2063.4,951.3,2065.3,948.5,2063.5,947.4z"/>
						<path class="st5" d="M2047.6,946c-1.8-1.2-3.6,1.6-1.8,2.8C2047.6,950,2049.4,947.2,2047.6,946z"/>
						<path class="st5" d="M2066.1,955.7c-0.4-0.1-0.8-0.2-1.2-0.4c-0.8-0.3-1.9,0.2-2.1,1.1c-0.2,0.9,0.2,1.8,1.1,2.1
							c0.4,0.1,0.8,0.2,1.2,0.4c0.8,0.3,1.9-0.2,2.1-1.1C2067.4,956.9,2067,955.9,2066.1,955.7z"/>
						<path class="st5" d="M2051.2,958.9c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.1,1.5,2,1.3c0.9-0.2,1.5-1.1,1.3-2c0-0.2-0.1-0.3-0.1-0.5
							c-0.2-0.9-1.1-1.5-2-1.3C2051.7,957.1,2051,958,2051.2,958.9z"/>
						<path class="st5" d="M2042.5,953.9c-0.9-0.2-1.8,0.2-2.1,1.1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.3,0.8,0.2,1.9,1.1,2.1
							c0.9,0.2,1.8-0.2,2.1-1.1c0.1-0.4,0.2-0.8,0.4-1.2C2043.9,955.2,2043.4,954.2,2042.5,953.9z"/>
						<path class="st5" d="M2064.7,939.8c-0.4-0.8-1.4-1.2-2.2-0.8c-0.3,0.2-0.7,0.4-1,0.6c-0.8,0.4-1.2,1.4-0.8,2.2
							c0.4,0.8,1.4,1.2,2.2,0.8c0.1-0.1,0.2-0.1,0.4-0.2c0.3-0.7,0.8-1.3,1.5-1.5C2064.9,940.6,2064.9,940.2,2064.7,939.8z"/>
						<path class="st5" d="M2043.6,960.9c-0.5,0.8-0.3,1.7,0.5,2.3c0.5,0.4,1.1,0.8,1.6,1.3c0.7,0.5,1.9,0.3,2.3-0.5
							c0.5-0.8,0.3-1.7-0.5-2.3c-0.5-0.4-1.1-0.8-1.6-1.3C2045.2,959.9,2044.1,960.2,2043.6,960.9z"/>
						<path class="st5" d="M2050.7,967.2c-0.6,0.7-0.9,1.7-0.9,2.7c0,0.9,0.6,1.7,1.6,1.8c0.8,0,1.8-0.7,1.8-1.6c0-0.1,0-0.2,0-0.3
							c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.9-0.5-2.3
							C2052.2,966.2,2051.3,966.4,2050.7,967.2z M2053.3,969.3c0,0,0,0.1-0.1,0.1C2053.3,969.4,2053.3,969.3,2053.3,969.3z
							 M2053.2,969.7c0-0.1,0-0.1,0-0.2C2053.2,969.6,2053.2,969.6,2053.2,969.7z"/>
						<path class="st5" d="M2058,969.8c1.8,1.2,3.6-1.6,1.8-2.8C2058,965.8,2056.2,968.6,2058,969.8z"/>
						<path class="st5" d="M2044.9,965.8c-1.8-1.2-3.6,1.6-1.8,2.8C2044.8,969.8,2046.7,967,2044.9,965.8z"/>
						<path class="st5" d="M2036,963.2c1.8,1.2,3.6-1.6,1.8-2.8C2036,959.2,2034.2,962,2036,963.2z"/>
						<path class="st5" d="M2067.9,952c1.8,1.2,3.6-1.6,1.8-2.8C2068,948.1,2066.1,950.9,2067.9,952z"/>
						<path class="st5" d="M2047,936.9c-1.1-0.3-2.1,0.8-2.1,1.8c0.4,0,0.9,0.1,1.4,0.4c0.4,0.3,0.6,0.6,0.8,1
							c0.9-0.4,1.5-1.6,0.9-2.5c-0.2-0.1-0.3-0.1-0.4-0.3C2047.4,937.2,2047.2,937,2047,936.9z"/>
						<path class="st5" d="M2034.3,953.3c1.8,1.2,3.6-1.6,1.8-2.8C2034.3,949.3,2032.5,952.1,2034.3,953.3z"/>
						<path class="st5" d="M2067.3,968.6c1.8,1.2,3.6-1.6,1.8-2.8C2067.4,964.6,2065.5,967.4,2067.3,968.6z"/>
						<path class="st5" d="M2036.7,940.5c-1.8-1.2-3.6,1.6-1.8,2.8C2036.7,944.5,2038.5,941.7,2036.7,940.5z"/>
						<path class="st5" d="M2054.6,929.3c-1.8-1.2-3.6,1.6-1.8,2.8c1.3,0.9,2.6-0.4,2.6-1.6c0-0.1,0-0.2-0.1-0.4
							C2055.2,929.9,2055,929.6,2054.6,929.3z"/>
						<path class="st5" d="M2057.7,913.1c2.4,1.7,5-2.1,2.6-3.8C2057.9,907.7,2055.3,911.5,2057.7,913.1z"/>
						<path class="st5" d="M2051.3,925.7c2.4,1.7,5-2.1,2.6-3.8C2051.5,920.3,2048.9,924.1,2051.3,925.7z"/>
						<path class="st5" d="M2061.5,922.5c2.4,1.7,5-2.1,2.6-3.8C2061.6,917.1,2059,920.8,2061.5,922.5z"/>
						<path class="st5" d="M2065.5,934.3c2.4,1.7,5-2.1,2.6-3.8C2065.7,928.9,2063.1,932.7,2065.5,934.3z"/>
						<path class="st5" d="M2069.7,918.3c0.9,0.6,1.9,0.5,2.6-0.1v-3.7C2069.9,912.9,2067.3,916.6,2069.7,918.3z"/>
						<path class="st5" d="M2048.2,915.9c2.4,1.7,5-2.1,2.6-3.8C2048.3,910.5,2045.7,914.2,2048.2,915.9z"/>
						<path class="st5" d="M2071.1,926.8c-0.3,1.1,0.1,2.3,1.2,2.8v-4.1C2071.8,925.8,2071.3,926.2,2071.1,926.8z"/>
						<path class="st5" d="M2055.4,930.5c0.2,1.2,1.5,2.1,2.7,1.8c1.2-0.3,2.1-1.4,1.8-2.7c0-0.2-0.1-0.4-0.1-0.6
							c-0.2-1.2-1.5-2.1-2.7-1.8c-1.2,0.3-2.1,1.4-1.8,2.7c0,0.1,0,0.2,0.1,0.3C2055.3,930.3,2055.4,930.4,2055.4,930.5
							C2055.4,930.5,2055.4,930.5,2055.4,930.5z"/>
						<path class="st5" d="M2041.5,928.8c1.2,0.4,2.5-0.2,2.9-1.4c0.2-0.5,0.4-1.1,0.5-1.6c0.4-1.1-0.2-2.6-1.4-2.9
							c-1.2-0.4-2.5,0.2-2.9,1.4c-0.2,0.5-0.4,1.1-0.5,1.6C2039.7,927,2040.3,928.4,2041.5,928.8z"/>
						<path class="st5" d="M2071.2,903.2c-0.5,0.2-0.9,0.5-1.4,0.7c-1.1,0.6-1.7,1.9-1.1,3c0.5,1,1.9,1.7,3,1.1
							c0.2-0.1,0.4-0.2,0.6-0.3V903C2071.9,902.9,2071.5,903,2071.2,903.2z"/>
						<path class="st5" d="M2045.4,935.6c0.5,0.4,1.1,0.9,1.6,1.3c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.3
							c0.9,0.4,2.2,0,2.7-0.8c0.7-1.1,0.4-2.4-0.6-3.2c-0.7-0.6-1.5-1.2-2.2-1.8c-0.9-0.8-2.6-0.4-3.2,0.6
							C2044.1,933.5,2044.4,934.8,2045.4,935.6z"/>
						<path class="st5" d="M2057.5,940.6c-1.1-0.7-2.4-0.4-3.2,0.6c-0.6,0.7-1,1.5-1.1,2.4c-0.1,0.4-0.1,0.8-0.1,1.2
							c0,0.8,0.3,1.5,0.9,2c0.3,0.3,0.7,0.4,1.2,0.4c1.1,0,2.5-0.8,2.4-2.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3
							c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.3-0.3C2058.9,942.8,2058.5,941.2,2057.5,940.6z M2057.6,944.7
							c0-0.1,0-0.2,0-0.2C2057.6,944.5,2057.6,944.6,2057.6,944.7z M2057.7,944.3c0,0,0-0.1,0.1-0.2
							C2057.8,944.2,2057.7,944.2,2057.7,944.3z"/>
						<path class="st5" d="M2063.3,942.4c-0.3,0.9-0.2,1.9,0.8,2.6c2.4,1.7,5-2.1,2.6-3.8c-0.7-0.4-1.3-0.5-1.9-0.3
							C2064.1,941.2,2063.5,941.7,2063.3,942.4z"/>
						<path class="st5" d="M2046.3,939.1c-0.5-0.3-0.9-0.4-1.4-0.4c-1.9,0.1-3.2,2.8-1.2,4.2c2,1.4,4.2-1,3.4-2.8
							C2047,939.7,2046.7,939.4,2046.3,939.1z"/>
						<path class="st5" d="M2034.3,935.2c2.4,1.7,5-2.1,2.6-3.8C2034.5,929.8,2031.9,933.6,2034.3,935.2z"/>
						<path class="st5" d="M2048.3,903.8c2.4,1.7,5-2.1,2.6-3.8C2048.4,898.4,2045.8,902.1,2048.3,903.8z"/>
						<path class="st5" d="M2032.3,921.7c2.4,1.7,5-2.1,2.6-3.8C2032.5,916.3,2029.9,920,2032.3,921.7z"/>
						<path class="st5" d="M2033.5,908.1c2.4,1.7,5-2.1,2.6-3.8C2033.6,902.6,2031,906.4,2033.5,908.1z"/>
						<path class="st5" d="M2058.3,893.4c2.4,1.7,5-2.1,2.6-3.8C2058.4,888,2055.8,891.7,2058.3,893.4z"/>
					</g>
					<g>
						<path class="st5" d="M2017.5,1023.3c-1.7,15.4-10.6,26.7-20.3,37.6h-7.2c2.6-2.9,5.1-5.7,7.6-8.6
							c8.9-10.6,17.6-25.4,13.8-39.8c-3.5-13.4-16.9-18.9-28.9-22.4c-12.5-3.7-25.4-5.8-38.1-8.5c-12.9-2.7-25.7-6-37.7-11.5
							c-11.9-5.5-22.5-13.4-33.4-20.6c-11.2-7.5-22.7-13.9-35.8-17.3c-13.4-3.4-27.3-4-41.1-3.2c-18.3,1-36.3,4.4-54.4,7.1v-5.5
							c23.3-3.5,46.5-8.1,70.1-7.3c14.1,0.5,28.2,2.9,41.3,8.6c12.6,5.5,23.7,13.7,35,21.3c12.1,8.1,24.5,14.5,38.5,18.6
							c14.1,4.1,28.5,6.3,42.8,9.5C1992,986.2,2020.6,994.9,2017.5,1023.3z"/>
						<path class="st5" d="M2072.3,985.6v6.7c-15-10.5-28.6-22.9-41-37c-23.1-26.2-42.9-55.6-63.3-83.9
							c-10.7-14.8-21.7-29.5-31.5-44.8c-8.6-13.4-15.1-28.4-13.2-44.6c1.8-15.2,8.9-27.8,18.6-38.6l4.2,3.9
							c-13.4,14.9-21.8,32.9-15.7,54.2c4.6,16.1,15.8,30,25.4,43.4c10,14.2,20.2,28.2,30.3,42.3c18.9,26.2,37.4,53.1,60.1,76.2
							C2054.4,971.5,2063.1,978.9,2072.3,985.6z"/>
						<path class="st5" d="M1948.1,932.3c-18.3,2.8-37.9-4.6-53.3-13.9c-17.2-10.4-31.5-24.9-45.5-39.2
							c-14.5-14.8-29.4-29.1-46.3-41.2c-15.6-11.2-32.4-19.8-48.9-29.5c-4.1-2.4-8.2-5-12.1-7.9v-6.9c7.1,5.6,15,10.2,22.9,14.6
							c17.4,9.7,34.3,19.5,50,31.8c15.1,11.7,28.4,25.2,41.7,38.8c13.4,13.7,27.3,27.2,44.2,36.7c8.2,4.6,17,8.2,26.3,10.2
							c5.2,1.1,10.5,1.6,15.8,1.4c1.7,0,4.2,0.1,5.8-0.6c2.3-0.1,3-1.3,2-3.7c0-1,0-2-0.1-3c-0.3-0.7-1.3-1.5-1.8-2
							c-11.9-13.5-27-23.5-41.8-33.6c-14.8-10.1-28.7-20.8-41.2-33.7c-13.8-14.3-26.1-29.8-38.8-45.1
							c-13.4-16.1-26.8-32.1-40.1-48.2c-8.8-10.5-17.6-21-25.9-31.9h6.9c11.1,14.4,23.1,28.2,34.7,42.2
							c16.2,19.4,32.2,38.9,48.5,58.2c13.3,15.8,27.4,31,44,43.4c14.8,11,30.8,20.4,44.8,32.4c5.8,4.9,14.3,11.4,17.3,18.7
							C1960.3,927.1,1954.3,931.4,1948.1,932.3z"/>
						<g>
							<path class="st3" d="M1742.1,893.6c-1.9,0-3.5-1.6-3.5-3.5V743.3c0-11.9,9.6-21.5,21.5-21.5h151.7c1.9,0,3.5,1.6,3.5,3.5
								s-1.6,3.5-3.5,3.5h-151.7c-8,0-14.5,6.5-14.5,14.5v146.8C1745.6,892,1744,893.6,1742.1,893.6z"/>
						</g>
						<g>
							<path class="st0" d="M2072.3,871v19h-233.1c-5,0-9-4-9-9v-83.4c0-5,4-9,9-9h152l0.4,0.4l4.5,4.2l7.3,6.7l17.2,15.8l45.7,42
								C2070.2,861.2,2072.3,866,2072.3,871z"/>
							<polygon class="st6" points="1965.5,788.7 1924.4,890.1 1875.4,890.1 1916.6,788.7 							"/>
							<polygon class="st6" points="2020.8,815.8 1990.6,890.1 1956.9,890.1 1996.2,793.2 2003.5,800 							"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path id="XMLID_12_" class="st5" d="M1687.6,604.4v413.1c0,9.9-8.1,18-18,18H494.3c-13,0-21.7-13.4-16.5-25.2
						c0.1-0.2,0.2-0.4,0.3-0.6l2-4.1l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8
						l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l8-16.7c3-6.2,9.3-10.2,16.2-10.2h976.5
						C1679.5,586.4,1687.6,594.5,1687.6,604.4z"/>
					<g>
						<path class="st4" d="M764.4,594.9c1-0.4,1.5-1.2,1.6-2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.7-0.7-0.8-1.4-0.7-2.2
							c-0.5-0.3-1.2-0.5-2-0.2C759.7,591.2,761.3,595.9,764.4,594.9z"/>
						<path class="st4" d="M780,588c0.4,0.1,0.9,0.1,1.4-0.1c0.8-0.3,1.3-0.9,1.5-1.5h-3.2C780,587,780.1,587.5,780,588z"/>
						<path class="st4" d="M753,591.4c0.3-1,1.2-1.8,2.3-1.9c0.2-1.2-0.4-2.6-1.6-3h-1.5c0,0-0.1,0-0.1,0
							C749.2,587.4,750.3,591.6,753,591.4z"/>
						<path class="st4" d="M769.3,592.1c0.1,0.8,0.6,1.6,1.4,2c0.2,0.1,0.4,0.2,0.7,0.3c1.2,0.6,2.8,0.1,3.4-1.2
							c0.5-1.3,0.1-2.8-1.2-3.4c-0.2-0.1-0.4-0.2-0.7-0.3c-1-0.5-2.3-0.2-3,0.6C770,590.9,769.8,591.6,769.3,592.1z"/>
						<path class="st4" d="M757.1,604.9c0.8,1.1,2.4,1.5,3.5,0.7c1.1-0.8,1.5-2.3,0.7-3.5c0,0,0,0-0.1-0.1c-0.5,0.4-1,0.6-1.7,0.6
							c-1.4,0-2.6-1.3-2.6-2.6c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.8-1.5,2.3-0.7,3.5
							C756.4,603.9,756.8,604.4,757.1,604.9z"/>
						<path class="st4" d="M773.8,602.6c-0.5-1.4-1.8-1.9-3.2-1.6c-1,0.3-2,0.5-3,0.8c-1.3,0.3-2,2-1.6,3.2c0.3,0.7,0.8,1.2,1.5,1.5
							c0,0-0.1,0-0.1-0.1c-2.3-2.4,1.4-6,3.7-3.6c1,1.1,0.9,2.3,0.2,3.3c0.3-0.1,0.6-0.2,1-0.2C773.5,605.5,774.3,603.8,773.8,602.6
							z"/>
						<path class="st4" d="M785.8,602c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0
							c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2,1-0.9,1.9-2.2,1.9c-0.3,0-0.5,0-0.7-0.1c0,0.3-0.1,0.7-0.3,1
							c-0.2,0.3-0.4,0.6-0.8,0.8c0.5,0.9,1.1,1.6,1.9,2.2c1.1,0.8,2.7,0.9,3.6-0.3C786.8,604.6,786.9,602.8,785.8,602z M785,601.2
							c0,0,0.1,0.1,0.1,0.2C785.1,601.4,785,601.3,785,601.2z M785.2,601.5c0.1,0.1,0.1,0.1,0.2,0.2
							C785.3,601.7,785.2,601.6,785.2,601.5z"/>
						<path class="st4" d="M789.9,596.2c3-1,1.5-5.5-1.4-4.8c1,1.5-0.1,3.3-1.5,3.8C787.6,596,788.6,596.6,789.9,596.2z"/>
						<path class="st4" d="M773,608.1c-3.1,1.1-1.4,5.8,1.6,4.8C777.8,611.8,776.1,607,773,608.1z"/>
						<path class="st4" d="M760.1,611.5c-3.1,1.1-1.4,5.8,1.6,4.8C764.8,615.2,763.1,610.4,760.1,611.5z"/>
						<path class="st4" d="M746.9,605.9c-0.3-0.4-0.6-0.8-1-1.1c-1,0.8-1.1,2.1-0.5,3.2c0.1,0.1,0.1,0.2,0.2,0.3
							c0.7-0.7,1.8-0.9,2.7-0.1c0.3,0.2,0.5,0.5,0.6,0.7c0.9-0.6,1.2-1.6,1.1-2.5C749,606.9,747.6,606.8,746.9,605.9z"/>
						<path class="st4" d="M738,596.6c0.1-0.3,0.2-0.6,0.4-0.9c-0.5-1.1-1.6-1.9-3-1.4c-1.1,0.4-1.6,1.2-1.6,2.1
							c0.8-0.3,1.8-0.2,2.6,0.6c0.6,0.6,0.8,1.4,0.7,2c0.1,0,0.2-0.1,0.3-0.1C736.8,598.2,737.1,597,738,596.6z"/>
						<path class="st4" d="M695.8,589.5c1.8,0,2.1-2.2,1.1-3.1h-2.1C693.7,587.3,694,589.5,695.8,589.5z"/>
						<path class="st4" d="M697.1,600c2.2,0,2.2-3.4,0-3.4C694.9,596.5,694.9,599.9,697.1,600z"/>
						<path class="st4" d="M702,593.7c2.2,0,2.2-3.4,0-3.4C699.9,590.3,699.8,593.7,702,593.7z"/>
						<path class="st4" d="M709.4,599.4c1.3,0,1.9-1.2,1.6-2.2c-0.1,0-0.2,0-0.4,0c-0.8,0-1.3-0.6-1.4-1.2
							C707.3,596.2,707.3,599.4,709.4,599.4z"/>
						<path class="st4" d="M705.1,587.7c-0.2-0.4-0.2-0.8-0.1-1.2h-1.2C704,587,704.4,587.5,705.1,587.7z"/>
						<path class="st4" d="M691,595.2c2.2,0,2.2-3.4,0-3.4C688.9,591.7,688.8,595.2,691,595.2z"/>
						<path class="st4" d="M711.9,593.6c0.4-0.1,0.8-0.2,1.2-0.3c0.9-0.2,1.5-1.2,1.2-2.1c-0.3-0.9-1.2-1.5-2.1-1.2
							c-0.4,0.1-0.8,0.2-1.2,0.3c-0.9,0.2-1.5,1.2-1.2,2.1C710.1,593.3,711,593.9,711.9,593.6z"/>
						<path class="st4" d="M701.3,598.5c-0.6,0.7-0.7,1.7,0,2.4c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.6,1.8,0.7,2.4,0
							c0.6-0.7,0.7-1.7,0-2.4c-0.1-0.1-0.2-0.2-0.3-0.4C703.1,597.9,701.9,597.8,701.3,598.5z"/>
						<path class="st4" d="M691.4,605.9c0.3,0.1,0.5,0.1,0.8,0c0.9-0.3,1.5-1.2,1.2-2.1c-0.1-0.4-0.2-0.8-0.3-1.2
							c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.5,1.2-1.2,2.1c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.3,0.7,0.6,0.9
							C691.1,605.7,691.3,605.8,691.4,605.9z"/>
						<path class="st4" d="M698.6,605.2c-0.3,0-0.7-0.1-1-0.1c-0.9-0.1-1.8,0.8-1.7,1.7c0,1,0.7,1.6,1.7,1.7c0.1,0,0.2,0,0.3,0
							C697.1,607.5,697.2,606,698.6,605.2z"/>
						<path class="st4" d="M705.4,608.4c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.5,1.2,2.3,0.6c0.7-0.5,1.2-1.5,0.6-2.3
							c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.7c0,0-0.1,0-0.1,0
							C706.9,607.4,706.2,608.2,705.4,608.4z"/>
						<path class="st4" d="M713.1,603.2c-2.2,0-2.2,3.4,0,3.4C715.2,606.6,715.3,603.2,713.1,603.2z"/>
						<path class="st4" d="M699.6,610.3c-2.2,0-2.2,3.4,0,3.4C701.7,613.8,701.8,610.4,699.6,610.3z"/>
						<path class="st4" d="M691.6,611.3c0.2-0.1,0.4-0.1,0.5-0.2c0-0.8-0.6-1.6-1.6-1.6c-2.1,0-2.2,3.2-0.2,3.4
							C690.4,612.3,690.8,611.7,691.6,611.3z"/>
						<path class="st4" d="M684.9,604.8c0.8-1,0.4-2.8-1.2-2.9c-1.4,0-1.9,1.4-1.5,2.4c0.4-0.2,0.9-0.3,1.3-0.3
							C684,604.2,684.5,604.4,684.9,604.8z"/>
						<path class="st4" d="M718.9,598.1c-0.3,1,0.2,2.3,1.5,2.3c2.2,0,2.2-3.4,0-3.4c-0.1,0-0.3,0-0.4,0
							C719.9,597.5,719.4,598,718.9,598.1z"/>
						<path class="st4" d="M678.7,596.4c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.4-0.3,0.7-0.4c0.8-0.8,0.6-2.6-0.7-2.9
							c-0.7,0.8-1.3,1.7-1.8,2.7C677.6,596.1,678,596.4,678.7,596.4z"/>
						<path class="st4" d="M663.6,635.2c1.4,0,2.3-2,0.9-2.9c-0.2-0.1-0.5-0.2-0.7-0.2C664.5,633,664.6,634.5,663.6,635.2z"/>
						<path class="st4" d="M668.3,630.9c-0.7-0.3-1.3-0.5-2-0.8c0,0.7,0.4,1.3,1.1,1.5c0.4,0.1,0.7,0.2,1.1,0.3
							c0.8,0.2,1.7-0.2,1.9-1.1c0.1-0.2,0.1-0.4,0-0.7C670,630.9,669.1,631.2,668.3,630.9z"/>
						<path class="st4" d="M656.1,644.2c0.5,0,1-0.3,1.4-0.7c-1.4-0.7-1.6-3.2,0.2-3.8c-0.1,0-0.1-0.1-0.2-0.1
							c-0.4-0.2-0.8-0.3-1.2-0.2l-1.7,3.5C654.8,643.5,655.3,644.2,656.1,644.2z"/>
						<path class="st4" d="M662.2,642.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.6-0.5-0.9-1.2-1-1.9C661.3,641.2,661.5,641.9,662.2,642.3z"/>
						<path class="st4" d="M673.4,623.7c-0.1-0.1-0.3-0.3-0.4-0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1
							c-1.7-1.1-3.4,1.6-1.7,2.7C672.5,626.3,673.8,624.9,673.4,623.7z"/>
						<path class="st4" d="M670.5,638.8c-0.4,0.7-0.4,1.6,0.4,2.1c1.7,1.1,3.4-1.6,1.7-2.7c-0.1,0-0.1-0.1-0.2-0.1
							c-0.2,0.3-0.6,0.5-1,0.6C671,638.9,670.7,638.9,670.5,638.8z"/>
						<path class="st4" d="M662.8,635.6c0.3-0.1,0.6-0.2,0.8-0.3c1-0.7,1-2.3,0.2-3.1c-0.4-0.5-1.1-0.7-1.9-0.5
							C659.3,632.2,660.2,636.2,662.8,635.6z"/>
						<path class="st4" d="M667.2,647.5c2.6-0.6,1.7-4.6-0.9-4C663.7,644.1,664.6,648.1,667.2,647.5z"/>
						<path class="st4" d="M670.5,638.8c0.3,0,0.5,0,0.8,0c0.5-0.1,0.8-0.3,1-0.6c1.2-1.3,0.2-3.9-2-3.4
							C668.1,635.3,668.5,638.5,670.5,638.8z"/>
						<path class="st4" d="M681.6,643.4c2.6-0.6,1.7-4.6-0.9-4C678.1,639.9,679,644,681.6,643.4z"/>
						<path class="st4" d="M672.6,626.8c-2.6,0.6-1.7,4.6,0.9,4C676.1,630.2,675.2,626.2,672.6,626.8z"/>
						<path class="st4" d="M657.5,643.5c0.4,0.2,0.8,0.2,1.3,0.1c2.6-0.6,1.7-4.6-0.9-4c-0.1,0-0.1,0-0.2,0.1
							C655.8,640.3,656,642.8,657.5,643.5z"/>
						<path class="st4" d="M679.8,633.8c-0.1,0.4,0,0.9,0.2,1.3c0.6,1,1.8,1.4,2.8,0.8c0.4-0.2,0.9-0.5,1.3-0.7
							c0.7-0.4,1.2-1.3,1.1-2.2c-0.3,0.1-0.7,0.1-1.1,0c-0.9-0.3-1.3-0.9-1.3-1.6c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.7,0.4-1,0.6
							C681.1,632.8,680.7,633.4,679.8,633.8z"/>
						<path class="st4" d="M674.5,646.8c0.5-0.2,1-0.3,1.4-0.2c0.3-0.9,0-1.8-0.8-2.4c-0.2-0.1-0.3-0.2-0.5-0.3
							c-0.9-0.6-2.3-0.3-2.8,0.6c-0.6,1-0.3,2.2,0.6,2.8c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.2
							C673.6,647.5,673.9,647.1,674.5,646.8z"/>
						<path class="st4" d="M660.4,655c0.5,0.9,1.8,1.4,2.8,0.8c1-0.6,1.4-1.8,0.8-2.8c-0.2-0.4-0.5-0.9-0.7-1.3
							c-0.5-0.9-1.8-1.4-2.8-0.8c-1,0.6-1.4,1.8-0.8,2.8C660,654.1,660.2,654.6,660.4,655z"/>
						<path class="st4" d="M665.4,624.5c0.1,0,0.2,0,0.3,0.1c0.1-0.7,0.5-1.4,1.3-1.8c0.4-0.2,0.8-0.2,1.1-0.2
							c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.6,0.1-1.2-0.2-1.7c-0.4-0.2-0.7-0.5-0.8-0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7-0.1-1,0
							l0,0.1l-1.9,3.9C664.3,623.8,664.7,624.4,665.4,624.5z"/>
						<path class="st4" d="M674.2,654.6c-0.3-1.1-1.3-1.7-2.5-1.5c-0.8,0.1-1.7,0.2-2.5,0.4c-1.1,0.2-1.8,1.4-1.5,2.5
							c0.3,1.1,1.3,1.7,2.5,1.5c0.8-0.1,1.7-0.2,2.5-0.4C673.7,656.9,674.5,655.6,674.2,654.6z"/>
						<path class="st4" d="M683.6,654.9c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2-0.1-0.4-0.1-0.4
							c-0.1-1.1-1.5-1.8-2.5-1.5c-1.2,0.3-1.7,1.3-1.5,2.5c0.1,1.1,0.8,2.3,1.6,3.1c0.8,0.7,2.1,0.9,2.9,0.1c0.7-0.7,1-2.1,0.1-2.9
							C683.9,655.1,683.7,655,683.6,654.9z M683.4,654.4c0,0,0,0.1,0.1,0.2C683.4,654.6,683.4,654.5,683.4,654.4z M683.5,654.7
							c0,0.1,0.1,0.1,0.1,0.2C683.6,654.8,683.5,654.8,683.5,654.7z"/>
						<path class="st4" d="M687.8,650.8c2.3-0.5,1.8-3.7-0.1-4c-0.5,0.4-1.1,0.6-1.8,0.5C684.6,648.5,685.6,651.3,687.8,650.8z"/>
						<path class="st4" d="M674,663c2.6-0.6,1.7-4.6-0.9-4C670.5,659.6,671.4,663.6,674,663z"/>
						<path class="st4" d="M662.2,660.6c-2.6,0.6-1.7,4.6,0.9,4C665.7,664,664.8,660,662.2,660.6z"/>
						<path class="st4" d="M680.6,626.8c1.5-0.3,1.8-1.8,1.3-2.9c-1,0.2-1.9-0.3-2.4-1.1C677.2,623.5,678.1,627.4,680.6,626.8z"/>
						<path class="st4" d="M652.9,657.7c2.6-0.6,1.7-4.6-0.9-4C649.4,654.2,650.4,658.2,652.9,657.7z"/>
						<path class="st4" d="M694.7,641.5c2.6-0.6,1.7-4.6-0.9-4C691.2,638,692.1,642,694.7,641.5z"/>
						<path class="st4" d="M730.2,599.1c-1-0.8-2.2-0.5-2.8,0.3c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.8-0.6,1.1c0,0.6,0.2,1.1,0.7,1.6
							C729.6,604.1,732.3,600.9,730.2,599.1z"/>
						<path class="st4" d="M720.6,613.3c2.1,1.7,4.8-1.5,2.7-3.2C721.2,608.3,718.6,611.6,720.6,613.3z"/>
						<path class="st4" d="M732.8,608c-2.1-1.7-4.8,1.5-2.7,3.2C732.2,612.9,734.9,609.7,732.8,608z"/>
						<path class="st4" d="M732.8,622.3c2.1,1.7,4.8-1.5,2.7-3.2C733.4,617.4,730.7,620.6,732.8,622.3z"/>
						<path class="st4" d="M740.7,604.9c-2.1-1.7-4.8,1.5-2.7,3.2C740.1,609.8,742.8,606.6,740.7,604.9z"/>
						<path class="st4" d="M718.3,603.8c0.1,0.1,0.1,0.2,0.2,0.2c2.1,1.7,4.8-1.5,2.7-3.2c-1.3-1.1-2.8-0.2-3.3,1
							C718.5,602.3,718.6,603.2,718.3,603.8z"/>
						<path class="st4" d="M743.8,618.3c0.4-1,0-2.3-1-2.8c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.3,0-2.8,1c-0.4,1,0,2.3,1,2.8
							c0.5,0.2,0.9,0.4,1.4,0.6C742,619.8,743.4,619.4,743.8,618.3z"/>
						<path class="st4" d="M725.8,615.1c-1.1,0.1-2,1.1-1.9,2.3c0,0.2,0,0.4,0.1,0.6c0.1,1.1,1.2,2,2.3,1.9c1.1-0.1,2-1.1,1.9-2.3
							c0-0.2,0-0.4-0.1-0.6C727.9,616,726.9,615,725.8,615.1z"/>
						<path class="st4" d="M714.2,614.2c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.3-1-2.8c-1-0.4-2.3,0-2.8,1c-0.2,0.5-0.4,0.9-0.6,1.4
							c-0.4,1,0,2.3,1,2.8C712.4,615.6,713.7,615.2,714.2,614.2z"/>
						<path class="st4" d="M738.7,596.5c1.4,0.1,1.8,1.7,1.2,2.6c0.3,0,0.6,0,0.9-0.1c0.4-0.2,0.9-0.4,1.3-0.6
							c1-0.4,1.7-1.6,1.3-2.7c0-0.1-0.1-0.2-0.2-0.3c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2,0-0.3,0.1-0.5,0.1
							c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.6-0.4,0.9C738.2,596.5,738.4,596.5,738.7,596.5z"/>
						<path class="st4" d="M717.5,619c-0.2-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-2.3-0.6-2.9,0.3c-0.7,1-0.6,2.1,0.3,2.9
							c0.6,0.6,1.2,1.2,1.8,1.8c0.8,0.8,2.3,0.6,2.9-0.3c0.1-0.1,0.1-0.1,0.1-0.2C717.3,623.1,716.1,620.4,717.5,619z"/>
						<path class="st4" d="M725,627.4c-1-0.7-2.1-0.6-2.9,0.3c-0.8,0.8-1.4,2-1.5,3.2c-0.1,1.1,0.5,2.3,1.7,2.4
							c1,0.1,2.3-0.5,2.4-1.7c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.3-0.3
							C726.1,629.5,725.9,628,725,627.4z M724.7,631.1c0-0.1,0-0.2,0.1-0.2C724.8,631,724.8,631,724.7,631.1z M724.9,630.8
							c0,0,0-0.1,0.1-0.2C724.9,630.6,724.9,630.7,724.9,630.8z"/>
						<path class="st4" d="M730.6,631.9c2.1,1.7,4.8-1.5,2.7-3.2C731.3,627,728.6,630.2,730.6,631.9z"/>
						<path class="st4" d="M712.3,628.3c2.1,1.7,4.8-1.5,2.7-3.2C712.9,623.3,710.2,626.6,712.3,628.3z"/>
						<path class="st4" d="M705.4,619.3c-0.5,0.4-1,0.6-1.6,0.5c0.1,0.2,0.3,0.4,0.5,0.6c2,1.6,4.5-1.2,2.9-2.9
							c-0.1,0.2-0.3,0.4-0.5,0.6C706.3,618.6,705.8,619,705.4,619.3z"/>
						<path class="st4" d="M745.6,608.2c0.5,0.9,0.3,2-0.3,2.7c0.1,0.1,0.2,0.3,0.4,0.4c1.8,1.5,4.1-0.7,3.3-2.5
							c-0.1-0.3-0.3-0.5-0.6-0.7C747.4,607.3,746.3,607.6,745.6,608.2z"/>
						<path class="st4" d="M722.8,592.4c-0.7-0.5-0.8-1.7-0.3-2.4c-0.1-0.1-0.1-0.1-0.2-0.2c-2.1-1.7-4.8,1.5-2.7,3.2
							C720.9,594,722.3,593.4,722.8,592.4z"/>
						<path class="st4" d="M703.6,608c0.6,0.5,1.2,0.6,1.8,0.4c0.8-0.2,1.5-1,1.6-1.8c0.1-0.6-0.1-1.3-0.7-1.8
							C704.2,603,701.6,606.3,703.6,608z"/>
						<path class="st4" d="M742.5,631.8c2.1,1.7,4.8-1.5,2.7-3.2C743.1,626.9,740.4,630.1,742.5,631.8z"/>
						<path class="st4" d="M708.5,592.4c-2.1-1.7-4.8,1.5-2.7,3.2C707.9,597.4,710.6,594.2,708.5,592.4z"/>
						<path class="st4" d="M642,672.9c2.1-0.6,1.7-3.4,0-4l-1.6,3.2l-0.2,0.4C640.7,673,641.3,673.2,642,672.9z"/>
						<path class="st4" d="M647.3,684.6c2.6-0.8,1.4-4.7-1.2-4C643.6,681.4,644.8,685.4,647.3,684.6z"/>
						<path class="st4" d="M650.8,675.6c2.6-0.8,1.4-4.7-1.2-4C647.1,672.4,648.2,676.4,650.8,675.6z"/>
						<path class="st4" d="M660.3,675.5c-2.6,0.8-1.4,4.7,1.2,4C664,678.7,662.8,674.8,660.3,675.5z"/>
						<path class="st4" d="M652.5,667.4c2.6-0.8,1.4-4.7-1.2-4C648.8,664.2,650,668.1,652.5,667.4z"/>
						<path class="st4" d="M638.6,681.3c2.3-0.7,1.6-4-0.5-4.1l-1.6,3.4C636.9,681.2,637.6,681.6,638.6,681.3z"/>
						<path class="st4" d="M664.2,668.2c-0.6-0.9-1.9-1.3-2.9-0.7c-0.4,0.3-0.9,0.6-1.3,0.8c-0.9,0.6-1.3,1.9-0.7,2.9
							c0.6,0.9,1.9,1.3,2.9,0.7c0.4-0.3,0.9-0.6,1.3-0.8C664.4,670.5,664.8,669.1,664.2,668.2z"/>
						<path class="st4" d="M651.7,681.4c-0.5,1-0.2,2.3,0.8,2.8c0.2,0.1,0.4,0.2,0.5,0.3c1,0.5,2.3,0.2,2.8-0.8
							c0.5-1,0.2-2.3-0.8-2.8c-0.2-0.1-0.4-0.2-0.5-0.3C653.5,680,652.2,680.4,651.7,681.4z"/>
						<path class="st4" d="M641.2,692.9c0.6,0.7,1.8,1,2.7,0.4c0.9-0.6,1.3-1.9,0.7-2.9c-0.3-0.4-0.6-0.9-0.8-1.3
							c-0.6-0.9-1.9-1.3-2.9-0.7c-0.9,0.6-1.3,1.9-0.7,2.9C640.7,691.7,641.1,692.3,641.2,692.9z"/>
						<path class="st4" d="M646.4,660.1c0-0.1,0-0.3,0.1-0.4l-0.7,1.5C646.1,660.9,646.3,660.6,646.4,660.1z"/>
						<path class="st4" d="M652.2,689.9c-0.8,0.2-1.7,0.4-2.5,0.5c-1.1,0.2-1.7,1.6-1.4,2.6c0.4,1.1,1.4,1.6,2.6,1.4
							c0.8-0.2,1.7-0.4,2.5-0.5c1.1-0.2,1.7-1.6,1.4-2.6C654.4,690.2,653.4,689.7,652.2,689.9z"/>
						<path class="st4" d="M664.3,691c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.1-0.4
							c-0.2-1.1-1.6-1.7-2.6-1.4c-1.1,0.4-1.6,1.4-1.4,2.6c0.2,1.1,0.9,2.3,1.8,3c0.9,0.7,2.2,0.8,2.9-0.1c0.7-0.8,0.8-2.2-0.1-2.9
							C664.6,691.2,664.4,691.1,664.3,691z M664,690.5c0,0,0,0.1,0.1,0.2C664.1,690.7,664,690.6,664,690.5z M664.2,690.8
							c0,0.1,0.1,0.1,0.1,0.2C664.3,690.9,664.2,690.9,664.2,690.8z"/>
						<path class="st4" d="M667,682.6c-2.6,0.8-1.4,4.7,1.2,4C670.8,685.8,669.6,681.8,667,682.6z"/>
						<path class="st4" d="M654,695.8c-2.6,0.8-1.4,4.7,1.2,4C657.7,699,656.5,695,654,695.8z"/>
						<path class="st4" d="M645.4,698.9c-0.4-0.6-1.2-1-2.2-0.7c-2.6,0.8-1.4,4.7,1.2,4c1-0.3,1.4-1,1.4-1.8
							c-0.1-0.1-0.2-0.3-0.3-0.4C645.4,699.6,645.3,699.2,645.4,698.9z"/>
						<path class="st4" d="M659.4,662.9c2.6-0.8,1.4-4.7-1.2-4C655.6,659.7,656.8,663.7,659.4,662.9z"/>
						<path class="st4" d="M633.6,695.8c2.6-0.8,1.4-4.7-1.2-4C629.9,692.6,631.1,696.6,633.6,695.8z"/>
						<path class="st4" d="M673.4,672.7c-2.6,0.8-1.4,4.7,1.2,4C677.1,675.9,675.9,671.9,673.4,672.7z"/>
						<path class="st4" d="M627.9,714.8c0.2,0.5,0.4,0.9,0.6,1.4c0,0,0,0.1,0,0.1c0.8-0.6,1.2-1.8,0.3-2.7c-0.8-0.9-2-0.7-2.6,0
							C627,713.7,627.6,714.2,627.9,714.8z"/>
						<path class="st4" d="M621.5,722.4c-1.6-1.8-4.4,0.7-2.7,2.5C620.4,726.7,623.1,724.2,621.5,722.4z"/>
						<path class="st4" d="M628.3,724.8c1.5,0.4,3.2-1.6,1.9-3c-1.4-1.5-3.5,0.1-3.2,1.6C627.6,723.6,628.1,724.1,628.3,724.8z"/>
						<path class="st4" d="M629.5,732.3c-0.1-0.4,0-0.7,0.1-1.1c-1.3,0.2-2.4,1.8-1.2,3.1c0.9,1,2.1,0.7,2.8-0.1
							C630.3,733.9,629.7,733.2,629.5,732.3z"/>
						<path class="st4" d="M637.1,722.6c0.7-0.5,1.1-1.5,0.5-2.4c-0.3,0.3-0.7,0.5-1.1,0.6c-0.2,0-0.4,0-0.6,0
							c0.1,0.7,0,1.5-0.5,2.1c0.2,0.1,0.3,0.1,0.5,0.1c0.2-0.2,0.5-0.3,0.9-0.4C636.9,722.6,637,722.6,637.1,722.6z"/>
						<path class="st4" d="M621.3,714.9c-0.4,0.6-1.1,0.8-1.7,0.9l-0.2,0.4l-0.4,0.9C620.2,717.5,621.6,716.2,621.3,714.9z"/>
						<path class="st4" d="M635.9,731.4c0.1,0.4,0,0.8-0.1,1.2c0.1,0,0.1,0.1,0.2,0.1c0.8,0.5,2.1,0.3,2.6-0.6
							c0.5-0.9,0.3-2-0.6-2.6c-0.4-0.2-0.8-0.5-1.2-0.7c-0.8-0.5-2.1-0.3-2.6,0.6C635.1,729.7,635.7,730.5,635.9,731.4z"/>
						<path class="st4" d="M622.9,731.4c1,0,1.9-0.7,1.9-1.8c0-0.2,0-0.4,0-0.5c0-1-0.8-1.9-1.8-1.9c-1,0-1.9,0.7-1.9,1.8
							c0,0.2,0,0.4,0,0.5c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4C621.5,730.8,622.1,731.4,622.9,731.4z"/>
						<path class="st4" d="M640.8,712.2c-0.1-0.3-0.3-0.6-0.5-0.9c-0.6,0.9-2,1-2.8,0.4c-0.2-0.1-0.3-0.2-0.5-0.3
							c0,0-0.1-0.1-0.1-0.1c-0.7,0.4-1.2,1.2-1,2c0.2,0.9,1.2,1.7,2.2,1.4c0.4-0.1,0.8-0.2,1.2-0.3
							C640.3,714.1,641.1,713.2,640.8,712.2z"/>
						<path class="st4" d="M613.7,733.4c0.6,0.8,1.9,0.8,2.6,0.1c0.8-0.8,0.8-1.8,0.1-2.6c-0.5-0.6-0.9-1.2-1.4-1.8
							c-0.4-0.5-1-0.7-1.6-0.6l-1.3,2.8c0.1,0.1,0.1,0.2,0.2,0.3C612.7,732.2,613.2,732.8,613.7,733.4z"/>
						<path class="st4" d="M620.2,739.7c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0c0.8-0.6,0.8-2,0.1-2.6
							c-0.8-0.8-1.8-0.7-2.6-0.1c-0.5,0.4-0.9,0.9-1.2,1.5C618.1,738.5,619.5,738.8,620.2,739.7z"/>
						<path class="st4" d="M626,739.6c-1,0.5-1.6,1.8-0.6,2.9c1.1,1.2,2.6,0.5,3.1-0.7c-0.2,0-0.3,0-0.5,0
							C626.6,741.6,626,740.6,626,739.6z"/>
						<path class="st4" d="M611.5,735.9c0.5,0.1,0.9,0.3,1.2,0.5c0.2-0.6,0.2-1.3-0.3-1.9c-0.5-0.5-1.1-0.7-1.6-0.6l-0.5,1l-0.9,1.8
							C609.9,736.2,610.6,735.8,611.5,735.9z"/>
						<path class="st4" d="M643.7,723.7c-1.6-1.8-4.4,0.7-2.7,2.5C642.6,728,645.3,725.5,643.7,723.7z"/>
						<path class="st4" d="M635.7,743.8c1.6,1.8,4.4-0.7,2.7-2.5C636.9,739.5,634.1,742,635.7,743.8z"/>
						<path class="st4" d="M633,698.1c-1.6-1.8-4.4,0.7-2.7,2.5C631.8,702.4,634.6,699.9,633,698.1z"/>
						<path class="st4" d="M672.7,605.2l-0.6,1.3l-0.8,1.7C672.7,608.1,673.4,606.4,672.7,605.2z"/>
						<path class="st4" d="M668.1,620.2c0.1,0,0.2,0.1,0.3,0.1c2.4,0.8,3.6-3,1.2-3.8c-1.9-0.6-3,1.5-2.3,2.9
							C667.5,619.7,667.7,620,668.1,620.2z"/>
						<path class="st4" d="M676,615c2.4,0.8,3.6-3,1.2-3.8C674.7,610.4,673.6,614.2,676,615z"/>
						<path class="st4" d="M682.5,623.7c0,0-0.1,0-0.1,0C682.4,623.8,682.4,623.8,682.5,623.7c2.4,0.8,3.6-3,1.1-3.7
							c-0.2,0-0.3-0.1-0.5-0.1C684.1,621,684.2,622.9,682.5,623.7z"/>
						<path class="st4" d="M680.7,607.9c0-0.5,0-1,0.1-1.5c-0.4,0.7-0.5,1.7,0,2.3C680.8,608.4,680.7,608.2,680.7,607.9z"/>
						<path class="st4" d="M687.1,618.1c0.5,0,1,0,1.5,0c1,0,2.1-0.8,2-1.9c0-1.1-0.8-2-1.9-2c-0.5,0-1,0-1.5,0c-1,0-2.1,0.8-2,1.9
							C685.2,617.1,686,618.1,687.1,618.1z"/>
						<path class="st4" d="M673.4,623.7c0.6,0.6,1.6,0.8,2.3,0.4c0.9-0.5,1.3-1.7,0.8-2.7c-0.1-0.2-0.2-0.3-0.3-0.5
							c-0.5-0.9-1.8-1.4-2.7-0.8c-0.9,0.5-1.3,1.7-0.8,2.7c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
							C673.2,623.4,673.3,623.6,673.4,623.7z"/>
						<path class="st4" d="M679.1,596.4c-0.3,0.3-0.6,0.7-0.9,1c-0.7,0.7-0.9,2-0.1,2.8c0.7,0.7,2,0.9,2.8,0.1
							c0.3-0.3,0.6-0.7,0.9-1c0.7-0.7,0.9-2,0.1-2.8c-0.5-0.5-1.4-0.8-2.1-0.6C679.6,596.1,679.3,596.2,679.1,596.4
							C679.1,596.4,679.1,596.4,679.1,596.4z"/>
						<path class="st4" d="M666.1,626.2c-0.6,0.2-1.2,0.7-1.3,1.3c-0.3,1.1,0.3,2.1,1.3,2.5c0.1,0,0.2,0.1,0.3,0.1
							c0.7,0.3,1.3,0.5,2,0.8c0.8,0.3,1.7-0.1,2.2-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.3-1.1-0.3-2.1-1.3-2.5c-0.1,0-0.2-0.1-0.2-0.1
							c-0.1,0-0.1,0.1-0.2,0.1C667.7,627.8,666.6,627.2,666.1,626.2z"/>
						<path class="st4" d="M678.6,634.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0,0.1c0,0,0-0.1,0-0.1c-1.4-0.2-2.3-1.7-2-3
							c-0.7,0.1-1.3,0.6-1.6,1.4c-0.4,1-0.5,2.3-0.1,3.4c0.3,1,1.3,1.8,2.4,1.5c1-0.3,1.8-1.3,1.5-2.4
							C678.7,634.7,678.6,634.6,678.6,634.4z"/>
						<path class="st4" d="M684.1,633c0.4,0.1,0.8,0.1,1.1,0c1.6-0.5,2-3.2,0-3.8c-1.6-0.5-2.6,0.9-2.5,2.2
							C682.8,632.1,683.2,632.7,684.1,633z"/>
						<path class="st4" d="M666.6,636.5c2.4,0.8,3.6-3,1.2-3.8C665.3,632,664.2,635.8,666.6,636.5z"/>
						<path class="st4" d="M689.6,609.4c2.2,0.7,3.4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.3C688.4,604.9,687.2,608.7,689.6,609.4z"/>
						<path class="st4" d="M694.4,628.5c2.4,0.8,3.6-3,1.2-3.8C693.1,624,691.9,627.8,694.4,628.5z"/>
						<path class="st4" d="M681.9,623.9c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c1.7-0.8,1.6-2.7,0.7-3.8c-0.6-0.7-1.6-1-2.6-0.5
							c-1.5,0.7-1.6,2.3-0.9,3.5C680.1,623.6,680.9,624.1,681.9,623.9z"/>
						<path class="st4" d="M688.4,631.9c-2.8,1.3-0.8,5.6,2,4.4C693.2,635,691.2,630.6,688.4,631.9z"/>
						<path class="st4" d="M690.9,621c-2.8,1.3-0.8,5.6,2,4.4C695.7,624.1,693.8,619.8,690.9,621z"/>
						<path class="st4" d="M703.8,623.8c-2.8,1.3-0.8,5.6,2,4.4C708.5,626.9,706.6,622.5,703.8,623.8z"/>
						<path class="st4" d="M692.1,611.1c-0.2,0-0.3,0.1-0.5,0.2c-0.8,0.4-1.2,1-1.3,1.6c-0.3,1.7,1.3,3.7,3.3,2.8
							C696.2,614.5,694.7,610.6,692.1,611.1z"/>
						<path class="st4" d="M678.6,634c0.4,0,0.8,0,1.2-0.2c0.8-0.4,1.2-1,1.3-1.7c0.3-1.7-1.3-3.6-3.3-2.7c-0.8,0.3-1.2,0.9-1.3,1.6
							C676.3,632.3,677.2,633.8,678.6,634z"/>
						<path class="st4" d="M707,614.8c-0.9-0.9-2.4-1.2-3.4-0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-1,0.8-1.2,2.4-0.3,3.4
							c0.5,0.5,1.1,0.8,1.8,0.8c0.6,0,1.1-0.1,1.6-0.5c0.5-0.4,0.9-0.8,1.4-1.2c0.2-0.2,0.4-0.4,0.5-0.6
							C707.7,616.7,707.7,615.6,707,614.8z"/>
						<path class="st4" d="M698.6,630.7c-0.2-0.1-0.4-0.2-0.6-0.2c-1.2-0.4-2.7,0.2-3.1,1.4c-0.4,1.2,0.2,2.6,1.4,3.1
							c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.4,2.7-0.2,3.1-1.4C700.4,632.5,699.8,631.2,698.6,630.7z"/>
						<path class="st4" d="M687.7,646.8C687.8,646.7,687.8,646.7,687.7,646.8c1-0.9,1.2-2.4,0.3-3.4c-0.4-0.5-0.8-0.9-1.2-1.4
							c-0.8-1-2.4-1.2-3.4-0.3c-0.9,0.9-1.2,2.4-0.3,3.4c0.4,0.5,0.8,0.9,1.2,1.4c0.4,0.5,1,0.7,1.6,0.8
							C686.6,647.3,687.3,647.2,687.7,646.8z"/>
						<path class="st4" d="M680.8,608.7c0.2,1,1,1.8,2,1.8c1.2,0.1,2.6-0.8,2.6-2.1c0-0.5,0-1.1,0.1-1.6c0-0.7-0.2-1.4-0.7-1.9
							c-0.4-0.4-0.9-0.7-1.4-0.7c-0.4,0-0.9,0.1-1.3,0.3c-0.7,0.4-1.3,1-1.3,1.9c0,0,0,0.1,0,0.1c0,0.5,0,1-0.1,1.5
							C680.7,608.2,680.8,608.4,680.8,608.7z"/>
						<path class="st4" d="M700,642.7c-0.6-1.2-1.9-1.6-3.2-1.2c-0.9,0.3-1.8,0.7-2.8,1c-1.2,0.4-1.7,2.1-1.2,3.2
							c0.6,1.2,1.9,1.6,3.2,1.2c0.9-0.3,1.8-0.7,2.8-1C700,645.4,700.5,643.8,700,642.7z"/>
						<path class="st4" d="M711.2,641c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
							c-0.1-0.2-0.1-0.4-0.1-0.4c-0.4-1.2-2.1-1.7-3.2-1.2c-1.2,0.6-1.6,1.9-1.2,3.2c0.4,1.3,1.4,2.4,2.6,3.1
							c1.1,0.6,2.6,0.6,3.3-0.6C712.4,643.4,712.4,641.7,711.2,641z M710.4,640.3c0,0,0.1,0.1,0.1,0.2
							C710.5,640.5,710.4,640.4,710.4,640.3z M710.6,640.6c0.1,0.1,0.1,0.1,0.2,0.2C710.7,640.7,710.6,640.7,710.6,640.6z"/>
						<path class="st4" d="M712.6,630.8c-2.8,1.3-0.8,5.6,2,4.4C717.4,633.8,715.4,629.5,712.6,630.8z"/>
						<path class="st4" d="M699.7,647.9c-2.8,1.3-0.8,5.6,2,4.4C704.5,651,702.5,646.6,699.7,647.9z"/>
						<path class="st4" d="M687.8,652.3c-2.8,1.3-0.8,5.6,2,4.4C692.6,655.4,690.6,651,687.8,652.3z"/>
						<path class="st4" d="M698.6,605.2c-1.4,0.7-1.4,2.3-0.8,3.3c0.6,0.9,1.7,1.5,2.9,0.9c2.8-1.3,0.8-5.6-2-4.4
							C698.7,605.2,698.6,605.2,698.6,605.2z"/>
						<path class="st4" d="M665.7,624.6c-0.1,0.6,0.1,1.1,0.4,1.6c0.6,0.9,1.7,1.5,2.9,0.9c0.1,0,0.2-0.1,0.2-0.1
							c2.1-1.2,1-4.4-1.1-4.4c-0.3,0-0.7,0-1.1,0.2C666.2,623.2,665.8,623.9,665.7,624.6z"/>
						<path class="st4" d="M675.9,646.6c-0.4-0.1-0.9,0-1.4,0.2c-0.6,0.3-1,0.7-1.2,1.1c-0.8,1.7,0.9,4.2,3.1,3.2
							C678.8,650.1,677.9,647,675.9,646.6z"/>
						<path class="st4" d="M718.3,618.5c-0.3,0.1-0.5,0.3-0.7,0.5c-1.4,1.4-0.2,4.1,1.8,4.1c0.3,0,0.6-0.1,1-0.2
							C723.1,621.6,721.1,617.2,718.3,618.5z"/>
						<path class="st4" d="M662.9,638.4c-1,0.5-1.4,1.3-1.3,2.1c0,0.7,0.4,1.5,1,1.9c0.6,0.5,1.4,0.7,2.4,0.3
							C667.7,641.5,665.7,637.1,662.9,638.4z"/>
						<path class="st4" d="M753,591.4c-0.3,0.8-0.2,1.7,0.6,2.5c2.3,2.4,6-1.2,3.7-3.6c-0.6-0.6-1.3-0.8-2-0.7
							C754.2,589.6,753.3,590.4,753,591.4z"/>
						<path class="st4" d="M765.5,592.5c0.2,0.2,0.3,0.3,0.5,0.4c1.2,0.7,2.5,0.2,3.3-0.7c0.4-0.5,0.7-1.2,0.6-1.9
							c-0.1-0.5-0.3-0.9-0.7-1.4c-1.7-1.7-4-0.3-4.4,1.5C764.7,591,764.8,591.8,765.5,592.5z"/>
						<path class="st4" d="M771.1,602.8c-2.3-2.4-6,1.2-3.7,3.6c0,0,0.1,0.1,0.1,0.1c1.3,1.2,2.9,0.7,3.8-0.4
							C772,605.2,772.1,603.9,771.1,602.8z"/>
						<path class="st4" d="M775.6,589.6c1.7,1.7,4.1,0.3,4.4-1.5c0.1-0.5,0-1.1-0.3-1.6h-4.3C774.7,587.3,774.6,588.6,775.6,589.6z"
							/>
						<path class="st4" d="M781.4,602.9c0.2-0.3,0.3-0.6,0.3-1c-1.2-0.4-1.7-1.6-1.5-2.7c-0.5-0.3-0.9-0.5-1.4-0.8
							c-1.2-0.7-2.9-0.3-3.5,0.9c-0.6,1.2-0.3,2.8,0.9,3.5c0.6,0.3,1.1,0.6,1.7,0.9c0.8,0.5,2,0.4,2.8-0.1
							C781,603.5,781.2,603.2,781.4,602.9z"/>
						<path class="st4" d="M759.6,602.7c0.6,0,1.2-0.3,1.7-0.6c0.6-0.5,0.9-1.1,0.9-1.9c0-0.2,0-0.5,0-0.7c0-1.4-1.2-2.7-2.6-2.6
							c-1.4,0-2.6,1.1-2.6,2.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3C757,601.4,758.2,602.7,759.6,602.7z"/>
						<path class="st4" d="M742,595.1c0.4,0.2,0.8,0.3,1.2,0.3c0.9,0,1.8-0.4,2.3-1.2c-1.8-0.4-1.5-3.3,0.5-3.2
							c0.3,0,0.6,0.1,0.8,0.3c0-0.9-0.4-1.9-1.3-2.3c-1.2-0.6-2.8-0.3-3.5,0.9c-0.3,0.6-0.6,1.1-0.9,1.7c-0.5,0.8-0.4,2,0.1,2.8
							C741.4,594.6,741.6,594.9,742,595.1z"/>
						<path class="st4" d="M748.5,599.9c-0.9-1-2.8-1-3.6,0c-1,1.1-1,2.5,0,3.6c0.4,0.4,0.7,0.9,1.1,1.3c0.3,0.4,0.6,0.8,1,1.1
							c0.7,0.8,2.1,1,3.1,0.5c0.2-0.1,0.4-0.3,0.6-0.4c1-1.1,1-2.5,0-3.6C749.9,601.5,749.2,600.7,748.5,599.9z"/>
						<path class="st4" d="M753.6,611.6c-1.1,0.9-2,2.3-2.2,3.8c-0.2,1.3,0.4,2.8,1.8,3.2c1.3,0.3,2.9-0.4,3.2-1.8
							c0-0.1,0-0.3,0.1-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0c0.1-0.1,0.3-0.3,0.4-0.3
							c1.1-0.9,1-2.8,0-3.6C756.1,610.6,754.7,610.7,753.6,611.6z M756.4,616.2c0-0.1,0.1-0.2,0.1-0.2
							C756.5,616.1,756.5,616.1,756.4,616.2z M756.6,615.8c0-0.1,0.1-0.1,0.1-0.2C756.8,615.7,756.7,615.7,756.6,615.8z"/>
						<path class="st4" d="M763.6,618c2.3,2.4,6-1.2,3.7-3.6C764.9,612,761.3,615.6,763.6,618z"/>
						<path class="st4" d="M745.6,608.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-2.3-2.4-6,1.2-3.7,3.6
							c1.3,1.3,2.9,0.8,3.8-0.3C745.9,610.2,746.1,609.2,745.6,608.2z"/>
						<path class="st4" d="M736.3,597.1c-0.8-0.8-1.7-0.9-2.6-0.6c-1.2,0.5-2.2,1.8-1.8,3.2c0.3-0.1,0.6-0.2,0.9-0.2
							c0.8,0,1.6,0.9,1.5,1.7c0,0.1,0,0.2-0.1,0.3c1.3,0,2.5-1.1,2.7-2.3C737.1,598.5,736.9,597.7,736.3,597.1z"/>
						<path class="st4" d="M788.5,591.3c-0.1-0.2-0.2-0.3-0.4-0.5c-2.3-2.4-6,1.2-3.7,3.6c0.8,0.8,1.7,0.9,2.6,0.6
							C788.5,594.6,789.5,592.8,788.5,591.3z"/>
						<path class="st4" d="M778.1,619.2c2.3,2.4,6-1.2,3.7-3.6C779.5,613.2,775.8,616.8,778.1,619.2z"/>
						<path class="st4" d="M805.3,587.4c-3,0-2.9,4.6,0.1,4.6C808.3,592,808.2,587.4,805.3,587.4z"/>
						<path class="st4" d="M807.4,601.5c-3,0-2.9,4.6,0.1,4.6C810.4,606.1,810.3,601.5,807.4,601.5z"/>
						<path class="st4" d="M813.9,597.6c3,0,2.9-4.6-0.1-4.6C810.9,593,810.9,597.6,813.9,597.6z"/>
						<path class="st4" d="M824.1,604.9c3,0,2.9-4.6-0.1-4.6C821.1,600.4,821.1,605,824.1,604.9z"/>
						<path class="st4" d="M818.2,589.3c1.7,0,2.4-1.5,2.1-2.8H816C815.8,587.7,816.5,589.3,818.2,589.3z"/>
						<path class="st4" d="M799.1,599.9c3,0,2.9-4.6-0.1-4.6C796.1,595.3,796.1,599.9,799.1,599.9z"/>
						<path class="st4" d="M827.2,597.1c0.1,0,0.2-0.1,0.4-0.1c0.1-1.4,1.4-2.8,2.9-2.4c0-0.3,0-0.6-0.1-0.8
							c-0.4-1.2-1.6-1.9-2.9-1.6c-0.5,0.2-1.1,0.3-1.6,0.5c-1.2,0.3-2,1.7-1.6,2.9C824.7,596.7,826,597.4,827.2,597.1z"/>
						<path class="st4" d="M816.3,603.9c-0.9-0.8-2.4-0.9-3.3,0c-0.4,0.4-0.6,1-0.6,1.5c0.3,0,0.7,0.1,1.1,0.4
							c1,0.6,1.2,1.5,1.1,2.4c0.8,0.2,1.7,0,2.3-0.6c0.8-0.9,0.9-2.4,0-3.3C816.6,604.2,816.5,604.1,816.3,603.9z"/>
						<path class="st4" d="M802.2,609.9c-0.3-1.2-1.7-2-2.9-1.6c-1.2,0.4-1.9,1.6-1.6,2.9c0.2,0.5,0.3,1.1,0.5,1.6
							c0.3,1.2,1.7,2,2.9,1.6c1.2-0.4,1.9-1.6,1.6-2.9C802.5,610.9,802.3,610.4,802.2,609.9z"/>
						<path class="st4" d="M813.4,615.5c-0.1-1.3-1-2.2-2.3-2.3c-0.9-0.1-1.9-0.1-2.8-0.2c-1.2-0.1-2.3,1.2-2.3,2.3
							c0.1,1.3,1,2.2,2.3,2.3c0.9,0.1,1.9,0.1,2.8,0.2C812.3,617.9,813.4,616.7,813.4,615.5z"/>
						<path class="st4" d="M823.6,618.1c0,0,0-0.1-0.1-0.1c-0.6,0.3-1.4,0.3-2.2-0.2c-1.2-0.7-1.3-1.9-0.9-2.9
							c-0.9,0.3-1.5,1.1-1.6,2.2c-0.1,1.3,0.3,2.7,1.1,3.7c0.7,1,2.1,1.5,3.2,0.8c1-0.6,1.6-2.1,0.8-3.2
							C823.9,618.3,823.7,618.2,823.6,618.1c0,0,0-0.1,0-0.1C823.6,618,823.6,618,823.6,618.1z"/>
						<path class="st4" d="M829.1,609.9c-0.1,0-0.1,0-0.2,0c0,0.3-0.2,0.6-0.3,0.9c-0.3,0.6-1,1-1.7,1.1c-0.1,1.2,0.6,2.6,2.2,2.5
							C832.1,614.5,832.1,609.9,829.1,609.9z"/>
						<path class="st4" d="M811.1,620c-3,0-2.9,4.6,0.1,4.6C814.2,624.6,814.1,620,811.1,620z"/>
						<path class="st4" d="M798.9,619.3c-3,0-2.9,4.6,0.1,4.6C801.9,623.8,801.9,619.2,798.9,619.3z"/>
						<path class="st4" d="M792.1,589.9c2,0,2.6-2.1,1.9-3.4h-3.9C789.5,587.8,790.1,589.9,792.1,589.9z"/>
						<path class="st4" d="M789.3,609.3c-3,0-2.9,4.6,0.1,4.6C792.4,613.8,792.3,609.2,789.3,609.3z"/>
						<path class="st4" d="M838.9,601.3c-0.5,0-0.9,0.1-1.2,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.5,0.2,0.7
							c0.1,0.6,0,1.2-0.2,1.7c0.2,0.4,0.3,0.8,0.2,1.2c0.3,0.1,0.5,0.2,0.9,0.2C841.9,605.9,841.8,601.3,838.9,601.3z"/>
						<path class="st4" d="M781.7,601.9c0.2,0.1,0.5,0.1,0.7,0.1c1.3,0,2-0.9,2.2-1.9c0.2-1.3-0.6-2.7-2.2-2.7c-1.3,0-2,0.9-2.1,1.8
							C780,600.3,780.5,601.5,781.7,601.9z"/>
						<path class="st4" d="M840,597.7c-2.1,0.3-1.6,3.6,0.5,3.3C842.5,600.6,842.1,597.4,840,597.7z"/>
						<path class="st4" d="M843.2,610.8c2.1-0.3,1.6-3.6-0.5-3.3C840.6,607.8,841.1,611,843.2,610.8z"/>
						<path class="st4" d="M845.3,603c0.5-0.1,1,0,1.6,0.4c0.3,0.2,0.5,0.3,0.6,0.5c1.5-0.7,1-3.4-1-3.1
							C845.3,601,845,602.2,845.3,603z"/>
						<path class="st4" d="M854.5,605.2c-2.1,0.3-1.6,3.6,0.5,3.3C857,608.1,856.6,604.9,854.5,605.2z"/>
						<path class="st4" d="M849.4,597.8c2.1-0.3,1.6-3.6-0.5-3.3C846.8,594.9,847.3,598.1,849.4,597.8z"/>
						<path class="st4" d="M836.2,605.8c-0.4,0.1-0.9,0.1-1.3-0.1c0.1,0.8,0.8,1.5,1.8,1.3c0.8-0.1,1.2-0.7,1.3-1.3
							c0.1-0.4,0-0.9-0.2-1.2C837.5,605.1,836.9,605.6,836.2,605.8z"/>
						<path class="st4" d="M856.4,601.1c-0.2,0.7-0.6,1.2-1.2,1.5c0.4,0.1,0.8,0.2,1.2,0c0.4-0.2,0.7-0.3,1.1-0.5
							c0.8-0.3,1.3-1.4,0.9-2.2c-0.4-0.8-1.3-1.2-2.2-0.9c0,0-0.1,0-0.1,0.1C856.4,599.7,856.6,600.4,856.4,601.1z"/>
						<path class="st4" d="M849.7,608.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3c-0.5,0.7-0.4,1.8,0.3,2.3
							c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3C850.5,610.3,850.4,609.3,849.7,608.7z"/>
						<path class="st4" d="M839.8,613.9c-0.3-0.8-1.4-1.2-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
							c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C840.1,614.6,840,614.3,839.8,613.9z"/>
						<path class="st4" d="M843.3,592.3c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
							c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0.3,0.3,0.6,0.6,0.7,1
							C843.1,592.2,843.2,592.3,843.3,592.3z"/>
						<path class="st4" d="M846.1,618.8c0.2,0,0.5,0,0.7,0c0.9-0.1,1.6-1,1.4-1.9c-0.2-0.9-0.8-1.3-1.6-1.4
							C847.4,616.6,847,618,846.1,618.8z"/>
						<path class="st4" d="M855.8,617.8c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1-1.6-1.9-1.4
							c-0.9,0.2-1.4,0.9-1.4,1.9c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3
							C855.9,618,855.8,617.9,855.8,617.8z"/>
						<path class="st4" d="M858.9,611.5c-0.7,0.1-1.1,0.5-1.3,1c0.6-0.2,1.3-0.2,2,0.3c0.5,0.3,0.8,0.7,1,1.1
							C861.1,612.9,860.4,611.3,858.9,611.5z"/>
						<path class="st4" d="M847.1,620.3c-2.1,0.3-1.6,3.6,0.5,3.3C849.6,623.3,849.2,620,847.1,620.3z"/>
						<path class="st4" d="M838.3,620.8c-2.1,0.3-1.6,3.6,0.5,3.3C840.9,623.8,840.4,620.6,838.3,620.8z"/>
						<path class="st4" d="M855.3,595.1c1.2-0.2,1.5-1.2,1.3-2.1c-0.4-0.2-0.6-0.6-0.7-1c-0.3-0.2-0.6-0.2-1-0.2
							C852.7,592.2,853.2,595.4,855.3,595.1z"/>
						<path class="st4" d="M829.1,599.2c0.1,0.8,0.8,1.5,1.8,1.4c1.3-0.2,1.6-1.6,1.1-2.5C831.5,599.1,830.3,599.7,829.1,599.2z"/>
						<path class="st4" d="M830.6,614.6c-2.1,0.3-1.6,3.6,0.5,3.3c0.2,0,0.4-0.1,0.6-0.2c0.2-0.7,0.4-1.3,0.8-1.8
							C832.2,615.1,831.6,614.5,830.6,614.6z"/>
						<path class="st4" d="M865.5,607.8c2.1-0.3,1.6-3.6-0.5-3.3C863,604.8,863.4,608.1,865.5,607.8z"/>
						<path class="st4" d="M831.1,590.2c2.1-0.3,1.6-3.6-0.5-3.3C828.6,587.2,829,590.5,831.1,590.2z"/>
						<path class="st4" d="M628.7,699c2-0.3,2.1-2.9,0.7-3.7l-1.7,3.6C628,699,628.3,699.1,628.7,699z"/>
						<path class="st4" d="M631.5,707c-2.6,0.4-2,4.3,0.6,4C634.6,710.6,634,706.6,631.5,707z"/>
						<path class="st4" d="M636.7,702.9c2.6-0.4,2-4.3-0.6-4C633.6,699.2,634.1,703.2,636.7,702.9z"/>
						<path class="st4" d="M646.4,708.1c2.6-0.4,2-4.3-0.6-4C643.2,704.5,643.8,708.5,646.4,708.1z"/>
						<path class="st4" d="M639.5,695.2c1.3-0.2,1.8-1.3,1.6-2.3c-0.1-0.6-0.4-1.2-1-1.5c-0.3-0.2-0.7-0.3-1.2-0.2
							C636.4,691.6,637,695.5,639.5,695.2z"/>
						<path class="st4" d="M625.5,703.4l-1.5,3.1c0.1,0,0.1,0,0.2,0C625.8,706.3,626.1,704.5,625.5,703.4z"/>
						<path class="st4" d="M650.6,697.7c-0.5-1-1.6-1.5-2.6-1c-0.5,0.2-0.9,0.4-1.4,0.6c-0.6,0.3-1.1,0.9-1.2,1.6c0,0.3,0,0.7,0.1,1
							c0.1,0.2,0.2,0.3,0.3,0.4c0.6,0.7,1.5,1,2.4,0.6c0.5-0.2,0.9-0.4,1.4-0.6C650.5,699.9,651.1,698.7,650.6,697.7z"/>
						<path class="st4" d="M637.6,711.7c0.8,0.6,2.2,0.5,2.8-0.4c0.6-0.9,0.5-2.2-0.4-2.8c-0.2-0.1-0.3-0.2-0.5-0.3
							c-0.8-0.6-2.2-0.5-2.8,0.4c-0.6,0.9-0.5,2,0.3,2.7c0,0,0.1,0.1,0.1,0.1C637.2,711.4,637.4,711.6,637.6,711.7z"/>
						<path class="st4" d="M624.8,717.8c0.4,1,1.7,1.5,2.6,1c0.9-0.5,1.4-1.5,1.1-2.5c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-0.9-0.6-1.4
							c-0.3-0.7-1-1.1-1.7-1.2c-0.3,0-0.7,0-1,0.2c-1,0.5-1.5,1.6-1,2.6C624.4,716.9,624.6,717.3,624.8,717.8z"/>
						<path class="st4" d="M634.7,687.4c0.1-0.4,0.3-0.9,0.4-1.3c0.2-0.7,0.1-1.5-0.4-2.1l-2.2,4.6
							C633.5,688.7,634.4,688.3,634.7,687.4z"/>
						<path class="st4" d="M634,719c1.1,0.1,1.8,0.9,1.9,1.8c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.2,1.1-0.6c0.4-0.5,0.7-1.1,0.6-1.7
							c-0.2-1.1-1.1-1.8-2.3-1.7c-0.8,0.1-1.6,0.1-2.5,0.2c-1.1,0.1-1.9,1.3-1.7,2.3c0,0.2,0.1,0.4,0.2,0.6
							C632.4,719.2,633.1,718.9,634,719z"/>
						<path class="st4" d="M647.4,719.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c-0.1-1.1-1.3-1.9-2.3-1.7
							c-1.2,0.2-1.8,1.1-1.7,2.3c0.1,1.1,0.6,2.3,1.3,3.1c0.7,0.8,2,1.1,2.8,0.3c0.8-0.7,1.1-2,0.3-2.8
							C647.7,719.8,647.5,719.6,647.4,719.5z M647.2,719c0,0,0,0.1,0.1,0.2C647.2,719.2,647.2,719.1,647.2,719z M647.3,719.3
							c0,0.1,0.1,0.1,0.1,0.2C647.4,719.5,647.3,719.4,647.3,719.3z"/>
						<path class="st4" d="M651.3,711.9c-2.6,0.4-2,4.3,0.6,4C654.4,715.5,653.8,711.5,651.3,711.9z"/>
						<path class="st4" d="M637.1,722.6c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.2-0.9,0.4c-1.4,1.1-0.7,3.9,1.5,3.6
							C639.8,726.3,639.4,722.6,637.1,722.6z"/>
						<path class="st4" d="M624.5,725.2c-0.1,1.1,0.8,2.4,2.2,2.2c1.4-0.2,1.9-1.5,1.6-2.5c-0.2-0.6-0.6-1.2-1.3-1.4
							c-0.1,0-0.1,0-0.2,0C626.5,724.4,625.7,725.2,624.5,725.2z"/>
						<path class="st4" d="M646.8,691.9c2.6-0.4,2-4.3-0.6-4C643.6,688.3,644.2,692.2,646.8,691.9z"/>
						<path class="st4" d="M618.9,717.1l-1.2,2.5C618.8,719.3,619.2,718.1,618.9,717.1z"/>
						<path class="st4" d="M658.8,703.3c-2.6,0.4-2,4.3,0.6,4C661.9,706.9,661.3,703,658.8,703.3z"/>
						<path class="st4" d="M610.9,740.6c2.5,0.3,3.3-2.9,1.8-4.2c-0.3-0.3-0.7-0.4-1.2-0.5c-1-0.1-1.7,0.3-2.1,0.9
							c0,0.1-0.1,0.1-0.1,0.2l-0.2,0.4C608.7,738.6,609.2,740.4,610.9,740.6z"/>
						<path class="st4" d="M618.7,732.9c1.8,0.2,2.7-1.3,2.5-2.7c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.8-1.4-1.8-1.5
							C616.3,727.9,615.7,732.5,618.7,732.9z"/>
						<path class="st4" d="M628.5,737.2c-1.7-0.2-2.6,1.1-2.6,2.5c0,1,0.6,2,2,2.2c0.2,0,0.3,0,0.5,0
							C631,741.7,631.4,737.5,628.5,737.2z"/>
						<path class="st4" d="M624.3,725.1c0.1,0,0.1,0,0.2,0c1.3,0.1,2.1-0.8,2.3-1.8c0.3-1.2-0.3-2.6-1.9-2.8
							C621.9,720.1,621.3,724.8,624.3,725.1z"/>
						<path class="st4" d="M635.9,731.4c-0.2-0.9-0.8-1.7-1.7-1.9c-0.3-0.1-0.6-0.1-1-0.1c-0.6,0.1-1.1,0.2-1.7,0.3
							c-0.9,0.1-1.6,0.8-1.9,1.6c-0.1,0.3-0.2,0.7-0.1,1.1c0.2,0.9,0.8,1.6,1.6,1.9c0.3,0.1,0.7,0.1,1,0.1c0.6-0.1,1.1-0.2,1.7-0.3
							c0.8-0.1,1.5-0.7,1.8-1.5C635.9,732.2,636,731.8,635.9,731.4z"/>
						<path class="st4" d="M620.8,740.4c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.5c-0.7-0.9-2.1-1.2-3.1-0.5
							c-0.1,0-0.1,0.1-0.2,0.1c-1,0.8-1.2,2.3-0.4,3.3c0.1,0.2,0.3,0.4,0.4,0.5c0.8,1,2.3,1.2,3.3,0.4
							C621.1,742.7,621.4,741.5,620.8,740.4z"/>
						<path class="st4" d="M605.4,745.3C605.4,745.3,605.3,745.3,605.4,745.3l-1.2,2.4C605,747.2,605.5,746.3,605.4,745.3z"/>
						<path class="st4" d="M621.3,714.9c0.3-0.5,0.6-0.9,0.9-1.4c0.6-0.9,0.6-2.2-0.1-3l-0.4,0.9l-2.1,4.4
							C620.2,715.7,620.9,715.5,621.3,714.9z"/>
						<path class="st4" d="M613.6,748.3c-0.9-0.2-1.9-0.4-2.8-0.6c-1.2-0.3-2.5,0.8-2.6,2c-0.1,1.4,0.7,2.4,2,2.6
							c0.1,0,0.2,0,0.3,0.1c-0.5-1.4,0.4-3.3,2.3-3.1c1.8,0.2,2.3,2,1.7,3.3c0.6-0.4,1.1-1,1.1-1.7
							C615.7,749.6,614.9,748.6,613.6,748.3z"/>
						<path class="st4" d="M625.6,755c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
							c0.3-1.2-0.8-2.5-2-2.6c-1.4-0.1-2.3,0.7-2.6,2c-0.3,1.3-0.1,2.8,0.5,3.9c0.6,1.1,1.9,1.8,3.1,1.2c1.1-0.5,1.9-1.9,1.2-3.1
							C625.8,755.3,625.7,755.1,625.6,755z M625.5,754.4c0,0,0,0.1,0,0.2C625.5,754.5,625.5,754.4,625.5,754.4z M625.5,754.7
							c0,0.1,0,0.2,0.1,0.2C625.6,754.9,625.5,754.8,625.5,754.7z"/>
						<path class="st4" d="M632.3,747.5c-3-0.4-3.6,4.3-0.6,4.7C634.7,752.6,635.3,747.9,632.3,747.5z"/>
						<path class="st4" d="M613.5,759.6c1.7-0.9,1.7-4.1-0.8-4.4c-2.7-0.3-3.4,3.3-1.4,4.4c0.3-0.1,0.6-0.1,0.9-0.2
							C612.7,759.3,613.1,759.4,613.5,759.6z"/>
						<path class="st4" d="M599.9,757.3c1.6,0.2,2.5-1,2.6-2.2c-0.6-0.4-0.9-1.1-0.9-1.8l-1.3,2.7l-0.6,1.3
							C599.7,757.3,599.8,757.3,599.9,757.3z"/>
						<path class="st4" d="M635.9,720.8c-0.2-0.9-0.8-1.6-1.9-1.8c-0.9-0.1-1.6,0.2-2,0.8c-1,1.3-0.7,3.6,1.4,3.9
							c0.9,0.1,1.6-0.2,2-0.8C635.9,722.3,636.1,721.5,635.9,720.8z"/>
						<path class="st4" d="M643.4,740.3c-3-0.4-3.6,4.3-0.6,4.7C645.8,745.3,646.4,740.6,643.4,740.3z"/>
						<path class="st4" d="M598.9,758.6l-0.9,1.9l-1,2.1c0.3,0.2,0.7,0.4,1.1,0.4C600.9,763.3,601.5,759.2,598.9,758.6z"/>
						<path class="st4" d="M607.7,767c-2.9-0.3-3.4,4.2-0.5,4.5C610.1,771.9,610.7,767.3,607.7,767z"/>
						<path class="st4" d="M602.4,755.1c0.3,0.2,0.6,0.3,1,0.4c2.9,0.3,3.4-4.2,0.5-4.5c-0.6-0.1-1.1,0.1-1.5,0.4l-0.1,0.3l-0.8,1.7
							C601.5,754,601.8,754.6,602.4,755.1z"/>
						<path class="st4" d="M613.5,759.6c-0.4-0.2-0.8-0.3-1.3-0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0-0.5,0.1-0.7,0.1
							c-1.2,0.2-2.1,1.4-1.9,2.6c0.2,1.2,1.3,2.1,2.6,1.9c0.6-0.1,1.1-0.2,1.7-0.3c1.2-0.2,2.1-1.4,1.9-2.6
							C614.6,760.5,614.1,759.9,613.5,759.6z"/>
						<path class="st4" d="M600.1,770.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-0.9-2.3-1.2-3.2-0.4c-0.9,0.8-1.2,2.2-0.4,3.2
							c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.9,2.3,1.2,3.2,0.4C600.7,772.5,600.9,771.1,600.1,770.1z"/>
						<path class="st4" d="M590.1,782c0.9,0.2,1.8,0.4,2.7,0.5c1.2,0.2,2.4-0.8,2.5-2c0.1-1.3-0.8-2.3-2-2.5
							c-0.9-0.2-1.8-0.4-2.7-0.5c-0.3-0.1-0.6,0-0.8,0l-1.3,2.7l-0.2,0.3C588.6,781.4,589.3,781.9,590.1,782z"/>
						<path class="st4" d="M605.2,784.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
							c0.2-1.2-0.9-2.4-2-2.5c-1.3-0.1-2.3,0.8-2.5,2c-0.3,1.2,0,2.7,0.6,3.8c0.6,1,1.8,1.7,3,1.2c1-0.5,1.8-1.9,1.2-3
							C605.3,784.6,605.2,784.5,605.2,784.3z M605.1,783.7c0,0,0,0.1,0,0.2C605,783.9,605,783.8,605.1,783.7z M605.1,784.1
							c0,0.1,0,0.1,0.1,0.2C605.1,784.2,605.1,784.1,605.1,784.1z"/>
						<path class="st4" d="M611.5,777c-2.9-0.3-3.4,4.2-0.5,4.5C613.9,781.9,614.4,777.3,611.5,777z"/>
						<path class="st4" d="M592.6,784.7C592.6,784.7,592.6,784.7,592.6,784.7c1.4,1.3,0.2,4.1-1.9,3.8c0.3,0.4,0.8,0.6,1.4,0.7
							C595,789.6,595.6,785,592.6,784.7z"/>
						<path class="st4" d="M612.8,749.3c-1.9-0.2-2.8,1.7-2.3,3.1c0.2,0.7,0.8,1.3,1.8,1.4c1.1,0.1,1.9-0.4,2.2-1.2
							C615.1,751.4,614.6,749.6,612.8,749.3z"/>
						<path class="st4" d="M622.1,769.8c-2.9-0.3-3.4,4.2-0.5,4.5C624.5,774.7,625.1,770.1,622.1,769.8z"/>
						<path class="st4" d="M582.6,799.7c-2.7-1-4.3,3.1-1.6,4.2C583.7,804.9,585.3,800.8,582.6,799.7z"/>
						<path class="st4" d="M588.8,793.6c-0.6-0.1-1.1-0.1-1.7-0.2c-1.2-0.1-2.4,0.8-2.4,2c-0.1,1.2,0.7,2.3,2,2.4
							c0.6,0.1,1.1,0.1,1.7,0.2c1.2,0.1,2.4-0.8,2.4-2C590.8,794.9,590,793.8,588.8,793.6z"/>
						<path class="st4" d="M574.1,818.5c1.1-0.2,2.2-1.3,1.9-2.6c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2
							c0.1-0.2,0.2-0.4,0.2-0.4c0.5-1-0.2-2.5-1.3-2.9c-0.5-0.2-0.9-0.2-1.4-0.1l-1.3,2.7l-0.8,1.6c0,0.3,0.1,0.5,0.1,0.8
							C571.8,817.7,572.8,818.7,574.1,818.5z M575.9,814.9c0,0,0,0.1,0,0.2C575.8,815,575.8,815,575.9,814.9z M575.8,815.2
							c0,0.1,0,0.2,0,0.2C575.8,815.4,575.8,815.3,575.8,815.2z"/>
						<path class="st4" d="M583.7,810.2c-2.7-1-4.3,3.1-1.6,4.2C584.7,815.4,586.4,811.2,583.7,810.2z"/>
						<path class="st4" d="M592.6,784.7c-0.2-0.2-0.4-0.3-0.7-0.5c-2.7-1-4.3,3.1-1.6,4.2c0.2,0.1,0.4,0.1,0.5,0.1
							C592.8,788.8,594,786,592.6,784.7z"/>
						<path class="st4" d="M1678.1,709.3c-1.2-0.1-1.8,0.9-1.7,1.9c0.4,0,0.7,0,1.1,0.1c0.6,0.3,1.1,0.5,1.7,0.8
							C1679.9,711.2,1679.6,709.4,1678.1,709.3z"/>
						<path class="st4" d="M1678.8,722.7c1.7-0.3,1.7-3-0.1-3.2c-0.1,0.1-0.2,0.1-0.2,0.2C1679.3,720.4,1679.4,721.7,1678.8,722.7z"
							/>
						<path class="st4" d="M1683.5,717.1c2.1,0.2,2.4-3.1,0.3-3.3C1681.7,713.6,1681.4,716.9,1683.5,717.1z"/>
						<path class="st4" d="M1685.8,709.3c-0.3,0.9,0.1,2.1,1.3,2.2c0.1,0,0.3,0,0.4,0v-2.1C1687,709.6,1686.4,709.5,1685.8,709.3z"
							/>
						<path class="st4" d="M1672.8,717.7c2.1,0.2,2.4-3.1,0.3-3.3C1671,714.3,1670.7,717.6,1672.8,717.7z"/>
						<path class="st4" d="M1685.2,722.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2
							c-0.7,0.6-0.8,1.6-0.2,2.3c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.3,0.7,0.5,1.1,0.6C1683.6,723.8,1684,722.7,1685.2,722.3z"/>
						<path class="st4" d="M1674.3,725c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9c0.1,0.4,0.2,0.8,0.2,1.2
							c0.2,0.8,1.1,1.5,1.9,1.3c0.9-0.2,1.5-1,1.3-1.9C1674.5,725.8,1674.4,725.4,1674.3,725z"/>
						<path class="st4" d="M1684.9,701.1c-0.7-0.5-1.8-0.4-2.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.6-0.2,1.3-0.7,1.8
							c0,0.5,0.2,0.9,0.7,1.2c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1C1685.8,702.7,1685.7,701.6,1684.9,701.1z"/>
						<path class="st4" d="M1680.4,728.1c-0.7-0.1-1.3-0.2-2-0.3c-0.9-0.1-1.7,0.7-1.8,1.5c0,1,0.6,1.6,1.5,1.8
							c0.7,0.1,1.3,0.2,2,0.3c0.9,0.1,1.7-0.7,1.8-1.5C1681.9,728.9,1681.3,728.2,1680.4,728.1z"/>
						<path class="st4" d="M1685.7,731.3c-0.1,0.6,0,1.2,0.1,1.8c0.2,0.3,0.4,0.6,0.4,1c0.3,0.4,0.8,0.8,1.3,0.8v-5.1c0,0,0,0-0.1,0
							C1686.6,729.8,1685.9,730.4,1685.7,731.3z"/>
						<path class="st4" d="M1680,732.9c-2.1-0.2-2.4,3.1-0.3,3.3C1681.9,736.3,1682.1,733.1,1680,732.9z"/>
						<path class="st4" d="M1672.7,733.7c-1-0.2-1.7-1.2-1.9-2.2c-1.6,0.3-1.7,3.1,0.2,3.2C1672,734.9,1672.5,734.3,1672.7,733.7z"
							/>
						<path class="st4" d="M1667.8,707.3c0-0.1,0-0.1,0-0.2c-1.1,0.7-1,2.9,0.7,3.1c0.4,0,0.8-0.1,1.1-0.3
							C1668.5,709.6,1667.7,708.4,1667.8,707.3z"/>
						<path class="st4" d="M1665.3,723.8c-2.1-0.2-2.4,3.1-0.3,3.3C1667.1,727.2,1667.4,723.9,1665.3,723.8z"/>
						<path class="st4" d="M1660.8,718.1c2.1,0.2,2.4-3.1,0.3-3.3C1659,714.6,1658.7,717.9,1660.8,718.1z"/>
						<path class="st4" d="M1671.3,696.8c-2.1-0.2-2.4,3.1-0.3,3.3C1673.2,700.2,1673.5,697,1671.3,696.8z"/>
						<path class="st4" d="M1672.7,733.7c0.4,0.1,0.8,0.1,1.3,0c3.1-0.9,1.7-5.8-1.4-4.9c-1.4,0.4-1.9,1.6-1.7,2.8
							C1671,732.5,1671.7,733.5,1672.7,733.7z"/>
						<path class="st4" d="M1678.8,743.1c-3.1,0.9-1.7,5.8,1.4,4.9C1683.3,747.1,1682,742.2,1678.8,743.1z"/>
						<path class="st4" d="M1683.2,732.2c-3.1,0.9-1.7,5.8,1.4,4.9c1.5-0.4,2-1.8,1.7-3c-0.1-0.4-0.2-0.7-0.4-1
							C1685.3,732.3,1684.4,731.9,1683.2,732.2z"/>
						<path class="st4" d="M1686,723.3c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
							c-1.2,0.5-1.6,1.6-1.4,2.6c0.2,1.4,1.4,2.7,3.1,2.2c0.3-0.1,0.5-0.2,0.7-0.3v-1.6C1686.8,724.8,1686.2,724.1,1686,723.3z"/>
						<path class="st4" d="M1668.2,738.9c-3.1,0.9-1.7,5.8,1.4,4.9C1672.7,742.9,1671.3,738,1668.2,738.9z"/>
						<path class="st4" d="M1685.6,744.1c-0.6,1.2-0.3,2.8,1,3.4c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.1,0.4,0.2v-5.2
							C1686.7,742.9,1686,743.4,1685.6,744.1z"/>
						<path class="st4" d="M1675.7,753.5c-0.7-1.1-2.3-1.6-3.5-0.8c-1.1,0.8-1.6,2.3-0.8,3.5c0.3,0.5,0.7,1.1,1,1.6
							c0.7,1.1,2.3,1.6,3.5,0.8c1.1-0.8,1.6-2.3,0.8-3.5C1676.4,754.5,1676,754,1675.7,753.5z"/>
						<path class="st4" d="M1678.4,717.1c-0.9-0.4-1.8-0.8-2.7-1.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.1,0.5-0.2,1-0.3,1.6
							c-0.2,1,0.1,2,0.9,2.6c0.6-0.4,1.3-0.6,2.1-0.3c0.3,0.1,0.5,0.3,0.7,0.4c0.1,0,0.2-0.1,0.2-0.2c0.4-0.3,0.8-0.8,0.9-1.4
							c0.1-0.3,0.1-0.6,0.2-0.9C1679.3,717.3,1678.8,717.3,1678.4,717.1z"/>
						<path class="st4" d="M1686.1,754.5c-1,0.2-2,0.4-3.1,0.6c-1.3,0.3-2.1,1.9-1.7,3.1c0.5,1.4,1.7,2,3.1,1.7c1-0.2,2-0.4,3.1-0.6
							c0,0,0.1,0,0.1,0v-4.8C1687.1,754.5,1686.6,754.4,1686.1,754.5z"/>
						<path class="st4" d="M1687.5,766.3C1687.5,766.2,1687.5,766.2,1687.5,766.3l0.1-4.3C1685.9,762.9,1686.1,765.3,1687.5,766.3z"
							/>
						<path class="st4" d="M1674.9,764.4c-1.8,0.5-2.1,2.3-1.4,3.6c0.1,0,0.2,0,0.3,0c0.9,0.1,1.3,0.7,1.4,1.3
							c0.4,0.1,0.8,0.1,1.2-0.1C1679.5,768.4,1678.1,763.5,1674.9,764.4z"/>
						<path class="st4" d="M1658,730.3c-3.1,0.9-1.7,5.8,1.4,4.9C1662.5,734.2,1661.1,729.4,1658,730.3z"/>
						<path class="st4" d="M1662,756.5c-3.1,0.9-1.7,5.8,1.4,4.9C1666.5,760.5,1665.1,755.6,1662,756.5z"/>
						<path class="st4" d="M1651.1,745.9c-3.1,0.9-1.7,5.8,1.4,4.9C1655.7,749.9,1654.3,745,1651.1,745.9z"/>
						<path class="st4" d="M1657.5,719.3c3.1-0.9,1.7-5.8-1.4-4.9C1653,715.3,1654.4,720.2,1657.5,719.3z"/>
						<path class="st4" d="M1673.8,768c-0.1,0-0.2,0-0.3,0c-1.8,0.1-1.9,3,0,3.2c1.2,0.1,1.8-0.9,1.6-1.9
							C1675.1,768.7,1674.6,768.1,1673.8,768z"/>
						<path class="st4" d="M1674.1,781c2,0.2,2.3-3,0.2-3.2C1672.3,777.7,1672,780.8,1674.1,781z"/>
						<path class="st4" d="M1679,775.5c2,0.2,2.3-3,0.2-3.2C1677.2,772.2,1677,775.3,1679,775.5z"/>
						<path class="st4" d="M1685.6,781.2c2,0.2,2.3-3,0.2-3.2C1683.8,777.9,1683.6,781,1685.6,781.2z"/>
						<path class="st4" d="M1682.5,770.1c2,0.2,2.3-3,0.2-3.2C1680.7,766.8,1680.5,769.9,1682.5,770.1z"/>
						<path class="st4" d="M1669,773c-2-0.2-2.3,3-0.2,3.2C1670.8,776.3,1671,773.2,1669,773z"/>
						<path class="st4" d="M1686.3,774.7c0.1,0.6,0.6,1.1,1.2,1.2v-3.1C1686.8,773,1686.1,773.9,1686.3,774.7z"/>
						<path class="st4" d="M1678.2,782.4c0.5,0.6,1.6,0.8,2.2,0.2c0.6-0.6,0.7-1.6,0.2-2.2c-0.1-0.1-0.2-0.2-0.3-0.3
							c-0.5-0.6-1.6-0.8-2.2-0.2c-0.6,0.6-0.7,1.6-0.2,2.2C1678,782.2,1678.1,782.3,1678.2,782.4z"/>
						<path class="st4" d="M1670.5,784.4c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1-1.5-1.9-1.3c-0.8,0.2-1.4,1-1.3,1.9
							c0.1,0.4,0.2,0.8,0.2,1.2c0,0.1,0,0.2,0.1,0.2c0.7,0.1,1.3,0.5,1.6,1c0.1,0,0.1,0,0.2,0C1670,786,1670.6,785.3,1670.5,784.4z"
							/>
						<path class="st4" d="M1680.3,760.1c-0.7-0.4-1.7-0.4-2.2,0.4c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.7-0.4,1.7,0.4,2.2
							c0.7,0.4,1.7,0.4,2.2-0.4c0.2-0.3,0.4-0.6,0.6-0.9C1681.2,761.6,1681.1,760.6,1680.3,760.1z"/>
						<path class="st4" d="M1676.1,786.1c-0.6-0.1-1.3-0.2-1.9-0.3c-0.8-0.1-1.7,0.7-1.7,1.5c0,0.9,0.6,1.6,1.5,1.7
							c0.6,0.1,1.3,0.2,1.9,0.3c0.8,0.1,1.7-0.7,1.7-1.5C1677.6,786.9,1677,786.2,1676.1,786.1z"/>
						<path class="st4" d="M1683.6,790.4c0.2-0.5,0.4-1,0.6-1.5c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.5-0.8-0.9-1.3-0.9
							c-0.9,0-1.6,0.6-1.7,1.5c-0.2,0.9,0,1.9,0.5,2.6c0.4,0.7,1.2,1.1,2,0.8C1683.4,792,1683.4,791.1,1683.6,790.4z"/>
						<path class="st4" d="M1687.6,787.7v-2.4C1686.9,785.9,1686.9,787.1,1687.6,787.7z"/>
						<path class="st4" d="M1675.5,793.9c2,0.2,2.3-3,0.2-3.2C1673.7,790.6,1673.5,793.8,1675.5,793.9z"/>
						<path class="st4" d="M1666.3,789.8c-0.9,0.8-0.7,2.7,0.9,2.8c1.7,0.1,2.2-2.1,1-2.9c-0.2,0.1-0.5,0.2-0.8,0.2
							C1667,790,1666.6,789.9,1666.3,789.8z"/>
						<path class="st4" d="M1687.6,768.2v-2c0,0,0,0.1-0.1,0.1C1687.1,766.8,1687.2,767.7,1687.6,768.2z"/>
						<path class="st4" d="M1664.6,768.9c2,0.2,2.3-3,0.2-3.2C1662.8,765.5,1662.5,768.7,1664.6,768.9z"/>
						<path class="st4" d="M1661.5,782c-2-0.2-2.3,3-0.2,3.2C1663.3,785.4,1663.5,782.2,1661.5,782z"/>
						<path class="st4" d="M1657.2,776.6c2,0.2,2.3-3,0.2-3.2C1655.4,773.3,1655.1,776.4,1657.2,776.6z"/>
						<path class="st4" d="M1667.2,756c-2-0.2-2.3,3-0.2,3.2C1669,759.3,1669.2,756.1,1667.2,756z"/>
						<path class="st4" d="M1667.5,679.3c1.9,1.1,3.5-1.9,1.6-3C1667.2,675.3,1665.6,678.2,1667.5,679.3z"/>
						<path class="st4" d="M1665.3,686.1c-1.9-1.1-3.5,1.9-1.6,3C1665.5,690.1,1667.2,687.1,1665.3,686.1z"/>
						<path class="st4" d="M1670.9,685.9c1.9,1.1,3.5-1.9,1.6-3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0.1-0.4,0.1-0.7,0.1
							C1670,683.3,1669.4,685.1,1670.9,685.9z"/>
						<path class="st4" d="M1676.4,691.5c0.6,0.9,0.3,2.3-0.4,3.1C1677.1,694.1,1677.7,692.3,1676.4,691.5z"/>
						<path class="st4" d="M1677.2,680.6c0.7-0.4,1.4-0.4,2-0.2c-0.1-0.4-0.3-0.8-0.8-1.1c-1.6-0.9-3,1.1-2.3,2.3
							C1676.3,681.2,1676.6,680.9,1677.2,680.6z"/>
						<path class="st4" d="M1661.4,678.8c-1.5,0-2.4,2.3-0.8,3.2c1.4,0.8,2.7-0.7,2.5-1.9C1662.4,679.9,1661.8,679.5,1661.4,678.8z"
							/>
						<path class="st4" d="M1681,687.4c-0.2-0.1-0.5-0.1-0.7-0.2c-0.9-0.2-1.9,0.3-2,1.2c-0.2,0.9,0.3,1.8,1.2,2
							c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.7-0.2,2-1c-0.1,0-0.1,0-0.2,0C1681.4,689.3,1680.9,688.3,1681,687.4z"/>
						<path class="st4" d="M1670.2,691.4c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.4,1.2-1.2,2.1
							c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.2,1.5,2.1,1.2C1669.9,693.2,1670.5,692.3,1670.2,691.4z"/>
						<path class="st4" d="M1656.6,691.9c0.9,0.2,1.8-0.3,2-1.2c0,0,0-0.1,0-0.1c-0.6,0.4-1.3,0.6-2.1,0.3c-0.5-0.2-0.9-0.5-1.2-0.9
							C1655.2,690.8,1655.7,691.8,1656.6,691.9z"/>
						<path class="st4" d="M1676.9,674.5c-0.1,0.1-0.3,0.3-0.5,0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,1-0.6
							c0.7-0.5,1.1-1.5,0.6-2.3c-0.4-0.6-1.2-1-1.8-0.8C1678.1,671.9,1677.9,673.5,1676.9,674.5z"/>
						<path class="st4" d="M1659.3,694.4c-0.4,0.9-0.1,1.8,0.7,2.3c0.6,0.4,1.2,0.8,1.7,1.2c0.7,0.5,1.9,0.1,2.3-0.7
							c0.4-0.9,0.1-1.8-0.7-2.3c-0.6-0.4-1.2-0.8-1.7-1.2C1660.8,693.2,1659.7,693.6,1659.3,694.4z"/>
						<path class="st4" d="M1669.7,702.1c0.1-0.1,0.2-0.3,0.2-0.3c0.5-0.7,0.1-1.9-0.7-2.3c-0.9-0.4-1.8-0.1-2.3,0.7
							c-0.5,0.8-0.8,1.8-0.7,2.8c0.1,0.9,0.8,1.7,1.7,1.7c0.1,0,0.2,0,0.3,0c0.3-0.5,0.7-0.9,1.3-1.1c0.1-0.2,0.1-0.4,0.1-0.6
							c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2C1669.6,702.3,1669.6,702.2,1669.7,702.1z"/>
						<path class="st4" d="M1674.4,702.3c1.9,1.1,3.5-1.9,1.6-3C1674.1,698.3,1672.5,701.3,1674.4,702.3z"/>
						<path class="st4" d="M1660.5,700.6c0.5,0.2,0.8,0.5,1.1,0.8c0.3-0.7,0.2-1.6-0.7-2.1c-1.3-0.7-2.5,0.4-2.5,1.6
							C1658.9,700.4,1659.7,700.2,1660.5,700.6z"/>
						<path class="st4" d="M1651.7,697.2c1.9,1.1,3.6-1.9,1.6-3C1651.5,693.2,1649.8,696.1,1651.7,697.2z"/>
						<path class="st4" d="M1683.2,683.7c1.9,1.1,3.5-1.9,1.6-3C1682.9,679.7,1681.3,682.7,1683.2,683.7z"/>
						<path class="st4" d="M1661.5,670.1c-1.9-1.1-3.5,1.9-1.6,3C1661.8,674.1,1663.4,671.2,1661.5,670.1z"/>
						<path class="st4" d="M1651,684.4c-1.9-1.1-3.5,1.9-1.6,3C1651.2,688.4,1652.9,685.5,1651,684.4z"/>
						<path class="st4" d="M1685.4,697.5c-1.9-1.1-3.5,1.9-1.6,3C1685.6,701.5,1687.3,698.5,1685.4,697.5z"/>
						<path class="st4" d="M1649.2,677.2c1.9,1.1,3.5-1.9,1.6-3C1649,673.2,1647.3,676.2,1649.2,677.2z"/>
						<path class="st4" d="M1668.1,661.8c-1.9-1.1-3.5,1.9-1.6,3C1668.4,665.8,1670,662.8,1668.1,661.8z"/>
						<path class="st4" d="M1656.2,592c1.8,1.4,4-1.4,2.2-2.8C1656.6,587.8,1654.4,590.6,1656.2,592z"/>
						<path class="st4" d="M1664.2,589.9c1.8,1.4,4-1.4,2.2-2.8C1664.6,585.7,1662.4,588.5,1664.2,589.9z"/>
						<path class="st4" d="M1666.7,597.8c0.1-0.4,0.1-0.9,0.2-1.3c0,0,0-0.1,0-0.1c-0.9,0.6-1.3,2-0.2,2.9c0.1,0.1,0.3,0.2,0.4,0.2
							C1666.8,599,1666.7,598.4,1666.7,597.8z"/>
						<path class="st4" d="M1670.8,587c0.8,0.7,1.8,0.4,2.3-0.2c-0.9-0.2-1.9-0.3-2.8-0.3C1670.4,586.7,1670.6,586.9,1670.8,587z"/>
						<path class="st4" d="M1672.4,596c0.4,0.2,0.8,0.3,1.2,0.5c0.9,0.3,2-0.1,2.3-1c0.3-0.9,0-1.9-1-2.3c-0.4-0.2-0.8-0.3-1.2-0.5
							c-0.9-0.3-2,0.1-2.3,1C1671.1,594.6,1671.5,595.6,1672.4,596z"/>
						<path class="st4" d="M1659.1,595.9c0.1,0.9,1,1.7,2,1.5c0.9-0.2,1.7-1,1.5-2c0-0.2,0-0.3-0.1-0.5c-0.1-0.9-1-1.7-2-1.5
							c-0.9,0.2-1.7,1-1.5,2C1659.1,595.5,1659.1,595.7,1659.1,595.9z"/>
						<path class="st4" d="M1648.5,593.9c0.9,0.3,1.9,0,2.3-1c0.2-0.4,0.3-0.8,0.5-1.2c0.3-0.9-0.1-2-1-2.3c-0.9-0.3-1.9,0-2.3,1
							c-0.2,0.4-0.3,0.8-0.5,1.2C1647.2,592.4,1647.6,593.5,1648.5,593.9z"/>
						<path class="st4" d="M1650.9,596.8c-0.6,0.8-0.4,1.8,0.3,2.5c0.5,0.5,1.1,1,1.6,1.5c0.7,0.6,2,0.4,2.5-0.3
							c0.6-0.8,0.4-1.8-0.3-2.5c0,0-0.1-0.1-0.1-0.1c0.2,0.9-0.1,1.9-1.2,2.3c-2.4,0.8-3.6-2.9-1.2-3.7c0.3-0.1,0.5-0.1,0.7-0.1
							C1652.4,595.9,1651.3,596.2,1650.9,596.8z"/>
						<path class="st4" d="M1660.3,603.7c-0.8-0.6-1.8-0.4-2.5,0.3c-0.7,0.7-1.1,1.8-1.1,2.8c0,0.9,0.5,1.9,1.5,2
							c0.9,0.1,1.9-0.5,2-1.5c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
							C1661.3,605.5,1661.1,604.2,1660.3,603.7z M1660.3,606.6c0,0,0-0.1,0.1-0.1C1660.4,606.5,1660.4,606.5,1660.3,606.6z"/>
						<path class="st4" d="M1667.3,607.4c0.8-0.7,1.1-2,0.1-2.8c-1.7-1.3-3.8,1.2-2.3,2.6c0.1-0.1,0.3-0.2,0.5-0.2
							C1666.3,606.8,1666.9,607,1667.3,607.4z"/>
						<path class="st4" d="M1651.8,602.1C1651.8,602.1,1651.8,602.1,1651.8,602.1c-1.8-1.4-4,1.4-2.2,2.8c0.9,0.7,1.8,0.4,2.4-0.3
							C1651.3,604,1651.2,602.8,1651.8,602.1z"/>
						<path class="st4" d="M1645.3,597.8c-0.2,0.1-0.5,0.1-0.7,0.2c-0.9,0.2-1.8-0.4-2.1-1.3c-0.1-0.3-0.1-0.6,0-0.8
							c-0.7,0.7-0.9,1.9,0.1,2.6C1643.7,599.4,1644.8,598.8,1645.3,597.8z"/>
						<path class="st4" d="M1677.3,589.6c0.8,0.6,1.7,0.4,2.2-0.1c-0.9-0.6-1.8-1.1-2.8-1.5C1676.5,588.5,1676.7,589.1,1677.3,589.6
							z"/>
						<path class="st4" d="M1641.7,588c0.5,0.4,1,0.4,1.5,0.3c0.1-0.7,0.5-1.4,1.3-1.5c0-0.1,0-0.2,0-0.4h-3.5
							C1640.9,587,1641.1,587.6,1641.7,588z"/>
						<path class="st4" d="M1676.4,605.3c-0.7,0.2-1.3,0.1-1.8-0.3c-0.3,0.6-0.2,1.4,0.5,2c1.7,1.3,3.8-1.2,2.3-2.6
							C1677.4,604.7,1677,605.1,1676.4,605.3z"/>
						<path class="st4" d="M1666.7,610.7c1.6-0.5,1.6-2.5,0.6-3.3c-0.4-0.4-1.1-0.6-1.8-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
							C1663.3,608.3,1664.5,611.5,1666.7,610.7z"/>
						<path class="st4" d="M1670.7,617.9c-2.4,0.8-1.2,4.5,1.2,3.7C1674.3,620.8,1673.1,617.1,1670.7,617.9z"/>
						<path class="st4" d="M1675,613c2.4-0.8,1.2-4.5-1.2-3.7C1671.4,610.1,1672.6,613.8,1675,613z"/>
						<path class="st4" d="M1685.1,616.4c2.4-0.8,1.2-4.5-1.2-3.7C1681.5,613.5,1682.7,617.2,1685.1,616.4z"/>
						<path class="st4" d="M1675.2,601.6c-1.7,0.5-1.6,2.5-0.5,3.4c0.4,0.4,1,0.5,1.8,0.3c0.6-0.2,0.9-0.5,1.1-0.9
							C1678.2,603,1677.1,601,1675.2,601.6z"/>
						<path class="st4" d="M1663.7,618.7c2.4-0.8,1.2-4.5-1.2-3.7C1660.1,615.7,1661.3,619.4,1663.7,618.7z"/>
						<path class="st4" d="M1684.7,605.2c-0.4,0.3-0.8,0.5-1.2,0.8c-0.8,0.6-1.2,1.8-0.5,2.7c0.6,0.8,1.8,1.2,2.7,0.5
							c0.4-0.3,0.8-0.5,1.2-0.8c0.3-0.2,0.6-0.6,0.7-0.9v-1.4c0-0.1-0.1-0.2-0.2-0.3C1686.8,604.9,1685.6,604.6,1684.7,605.2z"/>
						<path class="st4" d="M1676,618.5c-0.4,1-0.1,2.1,0.9,2.6c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.9
							c0.4-1,0.1-2.1-0.9-2.6c-0.2-0.1-0.3-0.2-0.5-0.3C1677.7,617.1,1676.4,617.5,1676,618.5z"/>
						<path class="st4" d="M1666.2,629.3c0.3,0.4,0.7,0.7,1.2,0.8c0.2-1.1,1.3-1.8,2.3-2c0-0.3-0.1-0.7-0.3-1
							c-0.3-0.4-0.5-0.8-0.8-1.2c-0.6-0.8-1.8-1.2-2.7-0.5c-0.8,0.6-1.2,1.8-0.5,2.7C1665.7,628.5,1666,628.9,1666.2,629.3z"/>
						<path class="st4" d="M1667,596.5c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.6,0.1,1.2,0.5,1.7c0.3,0.3,0.6,0.5,1,0.6
							c1,0.2,2.1-0.4,2.3-1.5c0.1-0.4,0.1-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.3c-1-0.2-2,0.4-2.3,1.4
							C1667,596.4,1667,596.4,1667,596.5z"/>
						<path class="st4" d="M1679.1,627.7c-0.4-1.1-1.4-1.5-2.5-1.2c-0.8,0.2-1.6,0.4-2.3,0.6c-1,0.2-1.6,1.5-1.2,2.5
							c0.4,1.1,1.4,1.5,2.5,1.2c0.8-0.2,1.6-0.4,2.3-0.6C1678.9,629.9,1679.5,628.6,1679.1,627.7z"/>
						<path class="st4" d="M1685.2,625.1c-1.1,0.4-1.5,1.4-1.2,2.5c0.2,1,0.8,1.9,1.6,2.6c0.1-0.6,0.6-1.2,1.3-1.6
							c0.2-0.1,0.4-0.2,0.7-0.2V626C1687.2,625.2,1686.1,624.8,1685.2,625.1z"/>
						<path class="st4" d="M1680.2,635.4c1.7-1.1,0.5-4.2-1.7-3.5c-2.3,0.7-1.3,4.1,0.8,3.8c0,0,0.1-0.1,0.1-0.1
							C1679.7,635.5,1679.9,635.4,1680.2,635.4z"/>
						<path class="st4" d="M1668.2,634.4c-2.2,0.9-1,4.4,1.4,3.6c1.5-0.5,1.6-2,0.9-3c-0.2,0-0.4,0.1-0.7,0.1
							C1669.2,635.1,1668.7,634.8,1668.2,634.4z"/>
						<path class="st4" d="M1681.5,597.2c-2.4,0.8-1.2,4.5,1.2,3.7C1685.1,600.1,1683.9,596.4,1681.5,597.2z"/>
						<path class="st4" d="M1654.3,608.6c-2.4,0.8-1.2,4.5,1.2,3.7C1657.9,611.5,1656.7,607.8,1654.3,608.6z"/>
						<path class="st4" d="M1659.4,632.4c2.4-0.8,1.2-4.5-1.2-3.7C1655.8,629.5,1657,633.2,1659.4,632.4z"/>
						<path class="st4" d="M1650.7,624.6c2.4-0.8,1.2-4.5-1.2-3.7C1647.1,621.6,1648.4,625.3,1650.7,624.6z"/>
						<path class="st4" d="M1653.6,600.2c1.1-0.4,1.4-1.4,1.2-2.3c-0.2-0.8-0.8-1.5-1.7-1.5c-0.2,0-0.5,0-0.7,0.1
							C1650,597.2,1651.3,601,1653.6,600.2z"/>
						<path class="st4" d="M1667.7,644.9c-3,1.5-0.6,6.2,2.4,4.7C1673.1,648,1670.7,643.4,1667.7,644.9z"/>
						<path class="st4" d="M1676.9,658.2c-3,1.5-0.6,6.2,2.4,4.7C1682.2,661.4,1679.9,656.7,1676.9,658.2z"/>
						<path class="st4" d="M1679.2,646.2c-3,1.5-0.6,6.2,2.4,4.7C1684.5,649.4,1682.2,644.7,1679.2,646.2z"/>
						<path class="st4" d="M1680.2,635.4c-0.2,0-0.5,0.1-0.7,0.2c0,0-0.1,0.1-0.1,0.1c-2.8,1.6-0.5,6.1,2.5,4.6
							C1684.6,638.9,1682.8,634.8,1680.2,635.4z"/>
						<path class="st4" d="M1667.6,660.7c3-1.5,0.6-6.2-2.4-4.7C1662.2,657.6,1664.6,662.2,1667.6,660.7z"/>
						<path class="st4" d="M1683.9,657.9c-0.4,1.4,0.3,2.8,1.7,3.3c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0.1,0.8,0.1,1.2,0.1v-5.2
							c-0.1,0-0.2-0.1-0.3-0.1C1685.9,655.8,1684.3,656.5,1683.9,657.9z"/>
						<path class="st4" d="M1676.5,674.8c0.2-0.1,0.3-0.2,0.5-0.4c1-1,1.1-2.6,0.2-3.6c0,0,0-0.1-0.1-0.1c-0.4-0.5-0.9-0.9-1.3-1.4
							c-0.9-1-2.7-1.2-3.7-0.2c-1,1-1.2,2.6-0.2,3.7c0.4,0.5,0.9,0.9,1.3,1.4C1674,675.2,1675.4,675.4,1676.5,674.8z"/>
						<path class="st4" d="M1667.4,630.1c0,0.2-0.1,0.3-0.1,0.5c0,0.6,0,1.2,0,1.8c0,0.8,0.3,1.5,0.9,2.1c0.4,0.4,1,0.7,1.6,0.7
							c0.2,0,0.4,0,0.7-0.1c1.1-0.3,2.1-1.2,2.1-2.4c0-0.6,0-1.2,0-1.8c0-1.4-1-2.7-2.5-2.8c-0.1,0-0.2,0-0.4,0
							C1668.7,628.3,1667.7,629,1667.4,630.1z"/>
						<path class="st4" d="M1683.6,669.6c-1.3,0.5-1.8,2.4-1.1,3.5c0.7,1.3,2.2,1.7,3.5,1.1c0.5-0.2,1.1-0.5,1.6-0.7v-5.5
							c-0.3,0-0.7,0.1-1,0.2C1685.5,668.8,1684.5,669.2,1683.6,669.6z"/>
						<path class="st4" d="M1679.5,685.3c2.3-1.2,1.5-4.1-0.4-4.8c-0.6-0.2-1.3-0.2-2,0.2c-0.5,0.3-0.9,0.7-1.1,1.1
							C1675,683.6,1677.1,686.5,1679.5,685.3z"/>
						<path class="st4" d="M1685.6,630.1c-0.3,1.4,0.6,3,2,3.3v-5.1c-0.2,0-0.4,0.1-0.7,0.2C1686.2,628.9,1685.7,629.5,1685.6,630.1
							z"/>
						<path class="st4" d="M1655.5,654c3-1.5,0.6-6.2-2.4-4.7C1650.1,650.9,1652.5,655.5,1655.5,654z"/>
						<path class="st4" d="M1662.4,675.2c-1.6,0.8-1.7,2.4-1,3.6c0.4,0.6,0.9,1.1,1.6,1.3c0.5,0.1,1.1,0.1,1.7-0.2
							C1667.8,678.3,1665.4,673.7,1662.4,675.2z"/>
						<path class="st4" d="M1651.7,671.2c3-1.5,0.6-6.2-2.4-4.7C1646.3,668.1,1648.7,672.8,1651.7,671.2z"/>
						<path class="st4" d="M1650.5,638.2c3-1.5,0.6-6.2-2.4-4.7C1645.1,635.1,1647.5,639.8,1650.5,638.2z"/>
						<path class="st4" d="M1681,687.4c-0.1,1,0.3,1.9,1.5,2.4c0.1,0,0.1,0,0.2,0c2.8,0.9,4.4-3.5,1.5-4.5
							C1682.5,684.6,1681.2,686,1681,687.4z"/>
						<path class="st4" d="M1680.9,699.8c-2.9-1.1-4.6,3.4-1.7,4.5c1.1,0.4,2,0,2.5-0.6c0.4-0.5,0.7-1.1,0.7-1.8
							C1682.4,701,1682,700.1,1680.9,699.8z"/>
						<path class="st4" d="M1687.6,697.4V695C1687.2,695.7,1687.1,696.7,1687.6,697.4z"/>
						<path class="st4" d="M1676.4,691.5c-0.2-0.4-0.6-0.7-1.2-0.9c-2.9-1.1-4.6,3.4-1.7,4.5c1,0.4,1.9,0.1,2.4-0.5
							C1676.7,693.7,1677,692.4,1676.4,691.5z"/>
						<path class="st4" d="M1685.5,704.3c-1.2,0.6-1.7,2-1.2,3.2c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.5,0.7,0.9,1.2,1.1
							c0.5,0.3,1.2,0.3,1.8,0.1v-5.1C1686.9,704,1686.2,704,1685.5,704.3z"/>
						<path class="st4" d="M1668.3,704.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5-0.1,1-0.1,1.6c0,0.1,0,0.1,0,0.2c-0.1,1.1,0.7,2.3,1.8,2.5
							c0.1,0,0.2,0.1,0.4,0.1c1.3,0.1,2.5-0.8,2.6-2.2c0-0.6,0.1-1.2,0.1-1.8c0.1-1.3-0.9-2.5-2.2-2.6c-0.4,0-0.7,0.1-1,0.2
							C1669,703.7,1668.5,704.1,1668.3,704.6z"/>
						<path class="st4" d="M1676.4,711.2c-0.9,0.1-1.8,0.7-2,1.6c-0.3,1-0.1,1.9,0.6,2.5c0.2,0.2,0.5,0.4,0.8,0.6
							c0.9,0.4,1.8,0.8,2.7,1.2c0.4,0.2,0.8,0.2,1.3,0.1c0.8-0.2,1.6-0.8,1.8-1.5c0.4-1.3-0.2-2.5-1.4-3.1c-0.3-0.2-0.7-0.3-1-0.5
							c-0.6-0.3-1.1-0.5-1.7-0.8C1677.1,711.2,1676.7,711.1,1676.4,711.2z"/>
						<path class="st4" d="M1686.3,719.2c-0.4,0.9-0.6,1.9-0.5,2.9c0,0.4,0.1,0.8,0.2,1.2c0.2,0.8,0.8,1.6,1.6,1.9v-7.2
							C1687.1,718.2,1686.6,718.6,1686.3,719.2z"/>
						<path class="st4" d="M1678.4,719.6c-0.2-0.2-0.4-0.3-0.7-0.4c-0.8-0.3-1.6-0.1-2.1,0.3c-1.3,1-1.6,3.5,0.5,4.2
							c1.2,0.5,2.3-0.1,2.8-1C1679.4,721.7,1679.3,720.4,1678.4,719.6z"/>
						<path class="st4" d="M1664.4,718.4c2.9,1.1,4.6-3.4,1.7-4.5C1663.1,712.8,1661.5,717.3,1664.4,718.4z"/>
						<path class="st4" d="M1672.2,678.2c-2.9-1.1-4.6,3.4-1.7,4.5c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1
							C1673.9,682.3,1674.6,679.1,1672.2,678.2z"/>
						<path class="st4" d="M1658.8,705.1c2.4,0.9,4-2.1,2.7-3.7c-0.2-0.3-0.6-0.6-1.1-0.8c-0.8-0.3-1.6-0.1-2.1,0.2
							C1657,701.8,1656.8,704.3,1658.8,705.1z"/>
						<path class="st4" d="M1656.5,690.9c0.8,0.3,1.6,0.1,2.1-0.3c1.3-1,1.6-3.5-0.5-4.2c-2.4-0.9-3.9,1.9-2.8,3.6
							C1655.6,690.3,1656,690.7,1656.5,690.9z"/>
						<path class="st4" d="M1679.7,665c-2.9-1.1-4.6,3.4-1.7,4.5C1680.9,670.6,1682.6,666.1,1679.7,665z"/>
						<path class="st4" d="M1679.8,801.2c-3,0.3-2.5,5.1,0.5,4.7C1683.4,805.6,1682.9,800.8,1679.8,801.2z"/>
						<path class="st4" d="M1683.8,820.1c3-0.3,2.5-5.1-0.5-4.7C1680.3,815.7,1680.8,820.5,1683.8,820.1z"/>
						<path class="st4" d="M1687.6,809.9v-3C1686.9,807.7,1686.9,809,1687.6,809.9z"/>
						<path class="st4" d="M1674.7,814.5c3-0.3,2.5-5.1-0.5-4.7C1671.1,810.1,1671.6,814.9,1674.7,814.5z"/>
						<path class="st4" d="M1678.8,824.5c-0.5-1.2-1.9-1.9-3.1-1.3c-1.2,0.5-1.8,1.9-1.3,3.1c0.2,0.5,0.4,1.1,0.7,1.6
							c0.5,1.2,1.9,1.9,3.1,1.3c1.2-0.5,1.8-1.9,1.3-3.1C1679.2,825.6,1679,825,1678.8,824.5z"/>
						<path class="st4" d="M1684.3,788.7c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.5-0.4,1-0.6,1.5c-0.3,0.7-0.2,1.5,0.1,2.2
							c0.2,0.4,0.6,0.8,1.1,0.9c0.9,0.4,2.1,0.2,2.8-0.6v-5.2c-0.1,0-0.1-0.1-0.2-0.1C1686.3,787.3,1684.9,787.6,1684.3,788.7z"/>
						<path class="st4" d="M1683.2,829.8c0.2,1.4,1.3,2.1,2.6,2.1c0.6,0,1.2,0,1.7,0v-3.1c0-0.2,0-0.4,0-0.7v-0.9
							c-0.8,0-1.5,0-2.3,0.1C1684.1,827.3,1683,828.6,1683.2,829.8z"/>
						<path class="st4" d="M1687.6,838.1v-3.3C1686.7,835.7,1686.8,837.2,1687.6,838.1z"/>
						<path class="st4" d="M1675.7,839c0.3,0.1,0.7,0.2,1.2,0.2c3-0.3,2.5-5.1-0.5-4.7c-1.6,0.2-2.2,1.5-2,2.7c0.1,0,0.1,0,0.2,0
							C1675.4,837.6,1675.7,838.3,1675.7,839z"/>
						<path class="st4" d="M1666.6,804.9c3-0.3,2.5-5.1-0.5-4.7C1663,800.5,1663.5,805.3,1666.6,804.9z"/>
						<path class="st4" d="M1666.1,829.9c3-0.3,2.5-5.1-0.5-4.7C1662.5,825.5,1663,830.2,1666.1,829.9z"/>
						<path class="st4" d="M1657.8,818.3c3-0.3,2.5-5.1-0.5-4.7C1654.2,813.9,1654.7,818.6,1657.8,818.3z"/>
						<path class="st4" d="M1667.4,785.2c-0.2,0-0.4,0-0.5,0c-2.6,0.3-2.6,3.9-0.6,4.6c0.3,0.1,0.7,0.2,1.1,0.1
							c0.3,0,0.5-0.1,0.8-0.2c1.3-0.6,1.6-2.3,0.9-3.5C1668.7,785.7,1668.2,785.3,1667.4,785.2z"/>
						<path class="st4" d="M1681.7,833.1c-2.3-0.9-3.6,2.7-1.3,3.5C1682.6,837.5,1683.9,834,1681.7,833.1z"/>
						<path class="st4" d="M1678.3,846.8c-0.9-0.4-1.2-1.2-1.2-1.9c-0.9,0.9-1,2.6,0.5,3.2c1.1,0.4,2-0.2,2.4-1.1
							C1679.5,847.1,1678.9,847.1,1678.3,846.8z"/>
						<path class="st4" d="M1686.5,839.9c-2.3-0.9-3.6,2.7-1.3,3.5c1.2,0.5,2.1-0.3,2.4-1.2V841
							C1687.4,840.5,1687.1,840.2,1686.5,839.9z"/>
						<path class="st4" d="M1673.2,840.8c1.4,0.5,2.4-0.6,2.5-1.7c0.1-0.7-0.3-1.5-1.2-1.8c-0.1,0-0.1,0-0.2,0
							C1672.2,836.6,1670.9,839.9,1673.2,840.8z"/>
						<path class="st4" d="M1681.6,850.6c0.1,0.2,0.1,0.3,0.2,0.5c0.4,0.9,1.6,1.4,2.5,1c0.9-0.5,1.4-1.5,1-2.5
							c-0.1-0.2-0.1-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-1C1681.7,848.6,1681.2,849.7,1681.6,850.6z"/>
						<path class="st4" d="M1670.3,852.4c1,0.1,2-0.6,2.1-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2.1c-1-0.1-2,0.6-2.1,1.7
							c0,0.5-0.1,0.9-0.1,1.4C1668.5,851.3,1669.3,852.4,1670.3,852.4z"/>
						<path class="st4" d="M1687.6,828.8v-0.7C1687.5,828.3,1687.5,828.5,1687.6,828.8z"/>
						<path class="st4" d="M1678.2,854.6c-0.7-0.3-1.4-0.7-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
							c0.7,0.3,1.4,0.7,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1679.7,856,1679.2,855.1,1678.2,854.6z"/>
						<path class="st4" d="M1683.1,859.9c-0.4,0.8-0.5,1.7-0.4,2.6c0.8,0.5,1.2,1.2,1.1,2c0.3,0.2,0.7,0.3,1.1,0.2
							c0.9-0.2,1.8-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0.2-0.4
							c0.4-0.9-0.2-2.1-1.1-2.4C1684.5,858.4,1683.6,858.9,1683.1,859.9z"/>
						<path class="st4" d="M1676.2,859.8c-2.3-0.9-3.6,2.7-1.3,3.5C1677.2,864.2,1678.5,860.7,1676.2,859.8z"/>
						<path class="st4" d="M1665.8,859c2.3,0.9,3.6-2.7,1.3-3.5C1664.9,854.6,1663.5,858.2,1665.8,859z"/>
						<path class="st4" d="M1670.9,831c2.3,0.9,3.6-2.7,1.3-3.5C1670,826.6,1668.6,830.1,1670.9,831z"/>
						<path class="st4" d="M1661.5,848.5c2.3,0.9,3.6-2.7,1.3-3.5C1660.6,844.1,1659.2,847.7,1661.5,848.5z"/>
						<path class="st4" d="M1659.8,837.3c2.3,0.9,3.6-2.7,1.3-3.5C1658.9,832.9,1657.5,836.5,1659.8,837.3z"/>
						<path class="st4" d="M1678.2,817.1c-2.3-0.9-3.6,2.7-1.3,3.5C1679.2,821.6,1680.5,818,1678.2,817.1z"/>
						<path class="st4" d="M1682.7,862.5C1682.6,862.5,1682.6,862.5,1682.7,862.5c-2.7-1.4-4.8,2.7-2.1,4.1c1.7,0.9,3.2-0.6,3.3-2.1
							C1683.8,863.7,1683.5,863,1682.7,862.5z"/>
						<path class="st4" d="M1678,875.9c-2.6-1.3-4.7,2.8-2.1,4.1C1678.6,881.3,1680.6,877.2,1678,875.9z"/>
						<path class="st4" d="M1685.6,875.4c0.7,0.4,1.4,0.3,2,0v-4.1C1685,870,1682.9,874.1,1685.6,875.4z"/>
						<path class="st4" d="M1671.4,870.7c2.6,1.3,4.7-2.8,2.1-4.1C1670.8,865.2,1668.7,869.3,1671.4,870.7z"/>
						<path class="st4" d="M1681.9,880.7c-1.2,0.4-1.9,1.7-1.5,2.9c0.1,0.2,0.1,0.4,0.2,0.6c0.4,1.1,1.8,1.9,2.9,1.5
							c1.2-0.4,1.9-1.7,1.5-2.9c-0.1-0.2-0.1-0.4-0.2-0.6C1684.4,881,1683.1,880.3,1681.9,880.7z"/>
						<path class="st4" d="M1667.7,878.2c-1.2-0.2-2.5,0.5-2.7,1.8c-0.1,0.6-0.2,1.1-0.3,1.7c-0.2,1.2,0.6,2.5,1.8,2.7
							c1.2,0.2,2.5-0.5,2.7-1.8c0.1-0.6,0.2-1.1,0.3-1.7C1669.8,879.7,1669,878.4,1667.7,878.2z"/>
						<path class="st4" d="M1671.4,890.7c0.8,0.5,1.6,1,2.4,1.5c1,0.6,2.6,0,3.1-1c0.5-1.2,0.1-2.4-1-3.1c-0.8-0.5-1.6-1-2.4-1.5
							c-1-0.6-2.6,0-3.1,1C1669.8,888.8,1670.3,890,1671.4,890.7z"/>
						<path class="st4" d="M1684.2,894c-1.2-0.5-2.4-0.1-3.1,1c-0.7,1.1-1,2.5-0.8,3.8c0.2,1.2,1.1,2.3,2.4,2.1
							c1.1-0.1,2.4-1.2,2.1-2.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.2-0.4
							C1685.9,896,1685.2,894.4,1684.2,894z M1684.8,898.1c0-0.1,0-0.2,0-0.2C1684.9,897.9,1684.9,898,1684.8,898.1z M1685,897.5
							c0,0,0,0.1-0.1,0.2C1684.9,897.6,1684.9,897.5,1685,897.5z"/>
						<path class="st4" d="M1672.8,894.1c-2.6-1.3-4.7,2.8-2.1,4.1C1673.4,899.5,1675.5,895.4,1672.8,894.1z"/>
						<path class="st4" d="M1662.3,887.8c-2.6-1.3-4.7,2.8-2.1,4.1C1662.9,893.2,1665,889.1,1662.3,887.8z"/>
						<path class="st4" d="M1671.9,854.5c-2.6-1.3-4.7,2.8-2.1,4.1C1672.5,859.9,1674.5,855.8,1671.9,854.5z"/>
						<path class="st4" d="M1658.4,874.5c-2.6-1.3-4.7,2.8-2.1,4.1C1659,880,1661.1,875.9,1658.4,874.5z"/>
						<path class="st4" d="M1655.6,864.9c2.7,1.3,4.7-2.8,2.1-4.1C1655,859.4,1653,863.6,1655.6,864.9z"/>
						<path class="st4" d="M1678.3,846.8c0.6,0.3,1.2,0.3,1.7,0.1c1.7-0.6,2.4-3.2,0.4-4.3c-1.8-0.9-3.3,0.7-3.3,2.2
							C1677.1,845.6,1677.4,846.4,1678.3,846.8z"/>
						<path class="st4" d="M1583.6,590.4c-2.1-2-5.1,1.3-3,3.2C1582.6,595.6,1585.7,592.3,1583.6,590.4z"/>
						<path class="st4" d="M1594.2,589.3c-0.1-0.2-0.2-0.4-0.4-0.5c-2.1-2-5.1,1.3-3,3.2c1.4,1.3,3.3,0.2,3.7-1.2
							C1594.1,590.4,1594.1,589.8,1594.2,589.3z"/>
						<path class="st4" d="M1592.9,604c2.1,2,5.1-1.3,3-3.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.1,0-0.2,0.1-0.3,0.1
							C1592.5,600.6,1591.4,602.6,1592.9,604z"/>
						<path class="st4" d="M1599.3,589.1c1.9,1.8,4.6-0.8,3.5-2.7h-0.4c-0.4,0.2-0.8,0.2-1.4,0c0,0,0,0,0,0h-2
							C1598.5,587.2,1598.4,588.3,1599.3,589.1z"/>
						<path class="st4" d="M1604.8,600.4c0.5-1.1,0.2-2.4-0.9-3c-0.5-0.3-1-0.5-1.5-0.8c-1-0.5-2.5-0.2-3,0.9
							c-0.5,1.1-0.2,2.4,0.9,3c0.5,0.3,1,0.5,1.5,0.8C1602.8,601.8,1604.3,601.5,1604.8,600.4z M1600.9,600.1
							c-2.3-0.8-1-4.4,1.3-3.5C1604.5,597.4,1603.2,600.9,1600.9,600.1z"/>
						<path class="st4" d="M1583.7,598.8c0,1.2,1.1,2.2,2.3,2.1c1.2-0.1,2.2-1.1,2.1-2.3c0-0.2,0-0.4,0-0.6c0-1.2-1.1-2.2-2.3-2.1
							c-1.2,0.1-2.2,1.1-2.1,2.3C1583.7,598.4,1583.7,598.6,1583.7,598.8z"/>
						<path class="st4" d="M1570.6,595.1c0,0,0.1,0,0.1,0c-0.1-1.2,1-2.5,2.5-2c0.3,0.1,0.5,0.3,0.7,0.4c0.2-0.3,0.3-0.6,0.5-0.9
							c0,0,0-0.1,0.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.3-2-1.5-1.9-2.7c-0.5,0.2-0.9,0.5-1.1,1c-0.3,0.5-0.5,1-0.8,1.5
							C1569.2,593.1,1569.5,594.6,1570.6,595.1z"/>
						<path class="st4" d="M1577.5,603.6c0.4,0.1,0.6,0.3,0.8,0.6c0.9-1,0.8-2.2-0.1-3.2c-0.6-0.7-1.2-1.3-1.8-2
							c-0.8-0.9-2.4-0.8-3.1,0.1c-0.8,1-0.7,2.2,0.1,3.1c0.6,0.7,1.2,1.3,1.8,2c0,0,0.1,0.1,0.1,0.1
							C1575.8,603.7,1576.6,603.2,1577.5,603.6z"/>
						<path class="st4" d="M1584.3,608.8c-1-0.8-2.2-0.7-3.1,0.1c-0.9,0.8-1.6,2.1-1.8,3.3c-0.1,1.1,0.4,2.4,1.7,2.7
							c1.1,0.2,2.5-0.4,2.7-1.7c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.4-0.3
							c0.3-0.2,0.4-0.5,0.5-0.8C1584.1,610.6,1584,609.6,1584.3,608.8z M1583.8,612.8c0-0.1,0-0.2,0.1-0.2
							C1583.9,612.6,1583.8,612.7,1583.8,612.8z M1584,612.4c0,0,0.1-0.1,0.1-0.2C1584.1,612.3,1584,612.3,1584,612.4z"/>
						<path class="st4" d="M1593.1,610.8c-2.1-2-5.2,1.3-3.1,3.2c1.7,1.6,4-0.2,3.7-2c-0.3-0.3-0.5-0.7-0.6-1.1
							C1593.1,610.9,1593.1,610.8,1593.1,610.8z"/>
						<path class="st4" d="M1570.8,609c2.1,2,5.1-1.3,3-3.2C1571.7,603.8,1568.7,607.1,1570.8,609z"/>
						<path class="st4" d="M1564.7,596.5c-1.9-0.3-3.6,2.2-1.9,3.8c1.6,1.5,3.8-0.1,3.7-1.8c-0.2-0.1-0.4-0.1-0.6-0.3
							C1565.2,597.8,1564.8,597.1,1564.7,596.5z"/>
						<path class="st4" d="M1610.2,589.8c-1.5-1.4-3.5-0.1-3.7,1.5c0.3,0,0.5,0,0.8,0.1c1,0.1,1.8,1,1.7,2c0,0.1,0,0.1,0,0.2
							C1610.5,593.3,1611.7,591.2,1610.2,589.8z"/>
						<path class="st4" d="M1566.2,586.4h-3.8c0.1,0.2,0.2,0.4,0.4,0.5C1564,588.1,1565.5,587.5,1566.2,586.4z"/>
						<path class="st4" d="M1602.6,614.6c2.1,2,5.1-1.3,3-3.2C1603.5,609.4,1600.5,612.6,1602.6,614.6z"/>
						<path class="st4" d="M1628.6,590.1c-2.2-0.1-2.3,3.4-0.1,3.4C1630.7,593.6,1630.8,590.2,1628.6,590.1z"/>
						<path class="st4" d="M1629.6,604.1c2.2,0.1,2.3-3.4,0.1-3.4C1627.5,600.6,1627.4,604,1629.6,604.1z"/>
						<path class="st4" d="M1634.7,597.9c2.2,0.1,2.3-3.4,0.1-3.4C1632.6,594.4,1632.5,597.9,1634.7,597.9z"/>
						<path class="st4" d="M1642.1,600.3c-2.2-0.1-2.3,3.4-0.1,3.4C1644.2,603.8,1644.3,600.4,1642.1,600.3z"/>
						<path class="st4" d="M1638.3,588.5c-2.2-0.1-2.3,3.4-0.1,3.4C1640.4,592,1640.5,588.6,1638.3,588.5z"/>
						<path class="st4" d="M1623.6,599.1c2.2,0.1,2.3-3.4,0.1-3.4C1621.5,595.7,1621.4,599.1,1623.6,599.1z"/>
						<path class="st4" d="M1642.5,596.8c0.3,0.9,1.1,1.5,2.1,1.3c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0,0.4-0.1,0.5-0.1
							c0.9-0.2,1.5-1.2,1.3-2.1c-0.3-0.9-1.1-1.5-2.1-1.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.7-1.3,1.3
							C1642.4,596.2,1642.4,596.5,1642.5,596.8z"/>
						<path class="st4" d="M1636.3,602.8c-0.6-0.7-1.8-0.7-2.4-0.1c-0.6,0.7-0.7,1.7-0.1,2.4c0.1,0.1,0.2,0.2,0.3,0.4
							c0.6,0.7,1.8,0.7,2.4,0.1c0.6-0.7,0.7-1.7,0.1-2.4C1636.5,603,1636.4,602.9,1636.3,602.8z"/>
						<path class="st4" d="M1625.5,606.7c-0.2-0.9-1.2-1.5-2.1-1.3c-0.9,0.3-1.5,1.1-1.3,2.1c0.1,0.4,0.2,0.8,0.3,1.2
							c0.2,0.9,1.2,1.5,2.1,1.3c0.9-0.3,1.5-1.1,1.3-2.1C1625.7,607.5,1625.6,607.1,1625.5,606.7z"/>
						<path class="st4" d="M1632,609.5c-0.7-0.1-1.4-0.2-2.1-0.2c-0.9-0.1-1.8,0.8-1.8,1.7c0,1,0.7,1.7,1.7,1.8
							c0.7,0.1,1.4,0.2,2.1,0.2c0.9,0.1,1.8-0.8,1.8-1.7C1633.7,610.3,1633,609.6,1632,609.5z"/>
						<path class="st4" d="M1641.2,613.5c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.8
							c-1,0-1.6,0.7-1.8,1.7c-0.1,1,0.1,2,0.7,2.8c0.5,0.7,1.5,1.2,2.3,0.7c0.7-0.4,1.2-1.5,0.7-2.3
							C1641.4,613.7,1641.3,613.6,1641.2,613.5z"/>
						<path class="st4" d="M1645.6,607.6c-2.2-0.1-2.3,3.4-0.1,3.4C1647.7,611.1,1647.8,607.7,1645.6,607.6z"/>
						<path class="st4" d="M1631.9,614.5c-2.2-0.1-2.3,3.4-0.1,3.4C1634,618,1634.1,614.6,1631.9,614.5z"/>
						<path class="st4" d="M1622.8,613.5c-2.2-0.1-2.3,3.4-0.1,3.4C1624.9,617,1625,613.6,1622.8,613.5z"/>
						<path class="st4" d="M1643.1,588.3c-0.1,0.9,0.4,1.9,1.6,1.9c2.2,0.1,2.3-3.4,0.1-3.4c-0.1,0-0.2,0-0.3,0
							C1643.7,586.9,1643.2,587.6,1643.1,588.3z"/>
						<path class="st4" d="M1618.9,588c-2.2-0.1-2.3,3.4-0.1,3.4C1621,591.5,1621.1,588.1,1618.9,588z"/>
						<path class="st4" d="M1616,605.8c-2.2-0.1-2.3,3.4-0.1,3.4C1618.1,609.3,1618.2,605.9,1616,605.8z"/>
						<path class="st4" d="M1653.1,601.6c-0.6,0-1,0.2-1.3,0.5c-0.6,0.7-0.5,1.9,0.2,2.5c0.3,0.2,0.6,0.4,1,0.4
							C1655.2,605.1,1655.3,601.6,1653.1,601.6z"/>
						<path class="st4" d="M1611.3,596.7c-2.2-0.1-2.3,3.4-0.1,3.4C1613.3,600.2,1613.5,596.8,1611.3,596.7z"/>
						<path class="st4" d="M1587.9,596.1c2.3,0.8,3.6-2.7,1.3-3.5C1586.9,591.7,1585.6,595.2,1587.9,596.1z"/>
						<path class="st4" d="M1596.6,588c-1.2-0.5-2.1,0.4-2.4,1.3c-0.1,0.5-0.1,1.1,0.2,1.5c0.2,0.3,0.5,0.5,0.9,0.7
							C1597.6,592.3,1598.9,588.8,1596.6,588z"/>
						<path class="st4" d="M1602.2,596.6c-2.3-0.8-3.6,2.7-1.3,3.5C1603.2,600.9,1604.5,597.4,1602.2,596.6z"/>
						<path class="st4" d="M1602.4,586.4h-1.4c0,0,0,0,0,0C1601.6,586.7,1602,586.6,1602.4,586.4z"/>
						<path class="st4" d="M1583.4,588.9c1.7,0.6,2.8-1.2,2.4-2.5h-3.4C1582,587.3,1582.2,588.5,1583.4,588.9z"/>
						<path class="st4" d="M1607.3,591.4c-0.3,0-0.5,0-0.8-0.1c-0.2,0-0.4,0-0.6,0c-1-0.1-2,0.7-2,1.7c0,1,0.7,2,1.7,2
							c0.5,0,0.9,0.1,1.4,0.1c0.9,0.1,1.8-0.6,2-1.5c0-0.1,0-0.1,0-0.2C1609,592.4,1608.3,591.5,1607.3,591.4z"/>
						<path class="st4" d="M1595.5,597.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-0.9c-0.9,0.5-1.4,1.5-0.9,2.5
							c0.1,0.2,0.2,0.3,0.2,0.5c0.3,0.7,1.1,1.2,1.9,1.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
							C1595.5,599.6,1595.9,598.5,1595.5,597.5z"/>
						<path class="st4" d="M1580.6,600.5c1,0,2-0.7,2-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2c-1,0-2,0.7-2,1.7
							c0,0.5-0.1,0.9-0.1,1.4C1578.9,599.4,1579.6,600.4,1580.6,600.5z"/>
						<path class="st4" d="M1588.5,602.6c-0.7-0.3-1.4-0.6-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
							c0.7,0.3,1.4,0.6,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1590,604,1589.5,603,1588.5,602.6z"/>
						<path class="st4" d="M1595.8,606.7c-1-0.3-2,0.2-2.4,1.1c-0.4,0.9-0.5,2-0.3,3c0,0,0,0.1,0,0.1c0.1,0.4,0.3,0.8,0.6,1.1
							c0.4,0.4,0.9,0.6,1.5,0.5c0.9-0.2,1.8-1.2,1.5-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
							c0.1-0.2,0.1-0.3,0.2-0.4C1597.3,608.2,1596.7,607,1595.8,606.7z"/>
						<path class="st4" d="M1603.2,605.3c-2.3-0.8-3.6,2.7-1.3,3.5C1604.2,609.7,1605.5,606.2,1603.2,605.3z"/>
						<path class="st4" d="M1586.6,607.8c-1.1-0.4-1.9,0.2-2.3,1c-0.3,0.8-0.2,1.8,0.6,2.3c0.1,0.1,0.2,0.1,0.4,0.2
							C1587.6,612.1,1588.9,608.6,1586.6,607.8z"/>
						<path class="st4" d="M1578.7,604.8c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.2-0.5-0.4-0.8-0.6c-1-0.4-1.8,0.1-2.2,0.8
							c-0.5,0.9-0.4,2.3,0.9,2.7c1.1,0.4,2-0.2,2.3-1.1C1578.5,605.6,1578.6,605.2,1578.7,604.8z"/>
						<path class="st4" d="M1610.4,586.4h-2.8c0.2,0.2,0.4,0.4,0.8,0.5C1609.3,587.3,1610,587,1610.4,586.4z"/>
						<path class="st4" d="M1570.7,595.1c0,0.6,0.4,1.3,1.2,1.6c2,0.7,3.2-1.9,2-3.1c-0.2-0.2-0.4-0.3-0.7-0.4
							C1571.7,592.6,1570.7,593.9,1570.7,595.1z"/>
						<path class="st4" d="M1613.2,601.7c-2.3-0.8-3.6,2.7-1.3,3.5C1614.2,606,1615.5,602.5,1613.2,601.7z"/>
						<path class="st4" d="M1671.7,906.2c-2.1-0.4-2.7,2.8-0.7,3.2C1673.1,909.8,1673.8,906.6,1671.7,906.2z"/>
						<path class="st4" d="M1670.3,919.5c2.1,0.4,2.7-2.8,0.7-3.2C1668.9,915.9,1668.3,919.1,1670.3,919.5z"/>
						<path class="st4" d="M1676.1,914.6c2.1,0.4,2.7-2.8,0.7-3.2C1674.8,910.9,1674.1,914.1,1676.1,914.6z"/>
						<path class="st4" d="M1682.8,918.1c-2.1-0.4-2.7,2.8-0.7,3.2C1684.2,921.7,1684.8,918.5,1682.8,918.1z"/>
						<path class="st4" d="M1681.1,906.3c-2.1-0.4-2.7,2.8-0.7,3.2C1682.5,909.9,1683.2,906.7,1681.1,906.3z"/>
						<path class="st4" d="M1666.2,910.7c-2.1-0.4-2.7,2.8-0.7,3.2C1667.6,914.3,1668.3,911.1,1666.2,910.7z"/>
						<path class="st4" d="M1685.3,913c-0.9,0.1-1.6,0.9-1.5,1.7c0.1,0.9,0.8,1.6,1.7,1.5c0.4,0,0.8-0.1,1.2-0.1
							c0.3,0,0.6-0.2,0.9-0.4v-2.6c-0.3-0.2-0.7-0.4-1.1-0.4C1686.1,913,1685.7,913,1685.3,913z"/>
						<path class="st4" d="M1677.1,919.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3
							c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.7,1.5,1,2.3,0.5C1677.3,921.5,1677.6,920.5,1677.1,919.8z"/>
						<path class="st4" d="M1664.4,919.8c-0.9,0.1-1.6,0.8-1.5,1.7c0,0.4,0.1,0.8,0.1,1.2c0.1,0.9,0.9,1.6,1.7,1.5
							c0.9-0.1,1.6-0.8,1.5-1.7c0-0.4-0.1-0.8-0.1-1.2C1666,920.4,1665.3,919.7,1664.4,919.8z"/>
						<path class="st4" d="M1679.7,901.3c0.5-0.7,0.6-1.7-0.1-2.3c-0.7-0.5-1.7-0.6-2.3,0.1c-0.2,0.3-0.5,0.6-0.7,0.9
							c-0.5,0.7-0.6,1.7,0.1,2.3c0.7,0.5,1.7,0.6,2.3-0.1C1679.2,901.9,1679.4,901.6,1679.7,901.3z"/>
						<path class="st4" d="M1671.7,925c-0.6-0.2-1.3-0.4-1.9-0.6c-0.8-0.2-1.8,0.4-1.9,1.3c-0.2,0.9,0.4,1.7,1.3,1.9
							c0.6,0.2,1.3,0.4,1.9,0.6c0.8,0.2,1.8-0.4,1.9-1.3C1673.2,926,1672.6,925.3,1671.7,925z"/>
						<path class="st4" d="M1679.8,930.3c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.3c0.3-0.8-0.5-1.8-1.3-1.9
							c-0.9-0.2-1.7,0.4-1.9,1.3c-0.3,0.9-0.2,1.9,0.2,2.8c0.3,0.8,1.2,1.4,2.1,1c0.8-0.3,1.4-1.2,1-2.1
							C1679.9,930.5,1679.8,930.4,1679.8,930.3z"/>
						<path class="st4" d="M1684.1,928.7c2.1,0.4,2.7-2.8,0.7-3.2C1682.7,925.1,1682.1,928.3,1684.1,928.7z"/>
						<path class="st4" d="M1670.8,929.7c-2.1-0.4-2.7,2.8-0.7,3.2C1672.2,933.4,1672.8,930.2,1670.8,929.7z"/>
						<path class="st4" d="M1662.4,927.3c-2.1-0.4-2.7,2.8-0.7,3.2C1663.8,930.9,1664.5,927.7,1662.4,927.3z"/>
						<path class="st4" d="M1686.9,909c0.2,0.1,0.5,0,0.7,0v-3.2C1685.5,905.3,1684.8,908.5,1686.9,909z"/>
						<path class="st4" d="M1662.3,905.9c2.1,0.4,2.7-2.8,0.7-3.2C1660.9,902.2,1660.2,905.4,1662.3,905.9z"/>
						<path class="st4" d="M1657.3,918.9c-2.1-0.4-2.7,2.8-0.7,3.2C1658.7,922.5,1659.4,919.3,1657.3,918.9z"/>
						<path class="st4" d="M1654.3,909.6c-2.1-0.4-2.7,2.8-0.7,3.2C1655.7,913.2,1656.4,910,1654.3,909.6z"/>
						<path class="st4" d="M1666.6,893c-2.1-0.4-2.7,2.8-0.7,3.2C1668,896.6,1668.7,893.4,1666.6,893z"/>
						<path class="st4" d="M875.7,589.3c1.7,0.3,2.5-1.7,1.7-2.8h-2.7C874,587.4,874.2,589,875.7,589.3z"/>
						<path class="st4" d="M875.1,600.1c2.2,0.4,2.9-3,0.6-3.4C873.6,596.2,872.9,599.6,875.1,600.1z"/>
						<path class="st4" d="M881.2,594.7c2.2,0.4,2.9-3,0.6-3.4C879.7,590.8,879,594.2,881.2,594.7z"/>
						<path class="st4" d="M888.4,598.3c-2.2-0.4-2.9,3-0.6,3.4C889.9,602.1,890.6,598.7,888.4,598.3z"/>
						<path class="st4" d="M885.7,589.1c1.7,0.3,2.5-1.6,1.8-2.7h-2.8C884.1,587.4,884.3,588.9,885.7,589.1z"/>
						<path class="st4" d="M870.5,590.7c-2.2-0.4-2.9,3-0.6,3.4C872.1,594.6,872.7,591.1,870.5,590.7z"/>
						<path class="st4" d="M892.2,592.7c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0.1-1.7,0.9-1.6,1.9c0.1,0.9,0.9,1.7,1.9,1.6
							c0.4,0,0.9-0.1,1.3-0.1c0.9-0.1,1.7-0.9,1.6-1.9C894,593.3,893.2,592.6,892.2,592.7z"/>
						<path class="st4" d="M882.4,600.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.8-1.7-1-2.4-0.5c-0.8,0.6-1,1.6-0.5,2.4
							c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.8,1.7,1,2.4,0.5C882.7,602.1,882.9,601,882.4,600.2z"/>
						<path class="st4" d="M868.8,600.5c-0.9,0.1-1.7,0.9-1.6,1.9c0,0.4,0.1,0.9,0.1,1.3c0.1,0.9,0.9,1.7,1.9,1.6
							c0.9-0.1,1.7-0.9,1.6-1.9c0-0.4-0.1-0.9-0.1-1.3C870.6,601.1,869.7,600.3,868.8,600.5z"/>
						<path class="st4" d="M876.7,605.9c-0.7-0.2-1.4-0.4-2.1-0.6c-0.9-0.2-1.9,0.5-2,1.4c-0.1,1,0.4,1.8,1.4,2
							c0.7,0.2,1.4,0.4,2.1,0.6c0.9,0.2,1.9-0.5,2-1.4C878.3,607,877.7,606.2,876.7,605.9z"/>
						<path class="st4" d="M885.4,611.4c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.4c0.3-0.9-0.5-1.9-1.4-2
							c-1-0.1-1.8,0.5-2,1.4c-0.3,0.9-0.2,2.1,0.2,2.9c0.4,0.8,1.3,1.4,2.2,1.1c0.8-0.3,1.5-1.4,1.1-2.2
							C885.5,611.6,885.5,611.5,885.4,611.4z"/>
						<path class="st4" d="M890.7,606.2c-2.2-0.4-2.9,3-0.6,3.4C892.3,610,892.9,606.6,890.7,606.2z"/>
						<path class="st4" d="M875.8,611c-2.2-0.4-2.9,3-0.6,3.4C877.4,614.8,878,611.4,875.8,611z"/>
						<path class="st4" d="M866.8,608.6c-2.2-0.4-2.9,3-0.6,3.4C868.4,612.4,869,609,866.8,608.6z"/>
						<path class="st4" d="M892.6,588.4c1.4,0.3,2.1-0.9,2-2h-3.3C891.1,587.3,891.5,588.2,892.6,588.4z"/>
						<path class="st4" d="M861.2,599.7c-2.2-0.4-2.9,3-0.6,3.4C862.8,603.6,863.4,600.1,861.2,599.7z"/>
						<path class="st4" d="M899.3,601.2c-2.2-0.4-2.9,3-0.6,3.4C900.8,605.1,901.5,601.6,899.3,601.2z"/>
						<path class="st4" d="M856.6,593c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0.1,0.8,0,1.1-0.2c-0.3-0.1-0.6-0.2-0.9-0.3
							c-1-0.6-1.3-1.6-1.1-2.5c-0.5,0.5-0.7,1.2-0.5,1.8C855.9,592.4,856.2,592.8,856.6,593z"/>
						<path class="st4" d="M827.6,596.9c0,0.8,0.3,1.5,1.1,2.1c0.1,0.1,0.3,0.1,0.4,0.2c1.2,0.5,2.4-0.1,2.9-1.1
							c0.6-1,0.6-2.4-0.7-3.3c-0.3-0.2-0.5-0.3-0.8-0.3C829,594.2,827.7,595.5,827.6,596.9z"/>
						<path class="st4" d="M839.4,595.1c2.1,1.3,4.2-1,3.6-2.9c-0.1-0.4-0.3-0.7-0.7-1c-0.1-0.1-0.2-0.2-0.3-0.3
							C839.3,589.2,836.7,593.4,839.4,595.1z"/>
						<path class="st4" d="M846.9,603.4c-0.6-0.3-1.1-0.4-1.6-0.4c-1.9,0.3-3.1,3.2-1,4.5c2.4,1.5,4.8-1.7,3.2-3.6
							C847.3,603.7,847.1,603.6,846.9,603.4z"/>
						<path class="st4" d="M848.1,590.2c2.5,1.5,4.9-1.9,3.1-3.7h-3.5C846.7,587.5,846.6,589.2,848.1,590.2z"/>
						<path class="st4" d="M824.8,588.6c1.9,1.2,3.7-0.5,3.7-2.2h-4.8C823.6,587.2,823.9,588.1,824.8,588.6z"/>
						<path class="st4" d="M855.2,602.6c0.6-0.3,1-0.8,1.2-1.5c0.2-0.7,0-1.4-0.3-2c-0.3-0.5-0.7-0.8-1.3-1
							c-0.6-0.2-1.2-0.3-1.8-0.5c-1.2-0.4-2.7,0.4-3,1.7c-0.3,1.3,0.3,2.7,1.7,3c0.6,0.2,1.2,0.3,1.8,0.5
							C854,603,854.6,602.9,855.2,602.6z"/>
						<path class="st4" d="M836.2,605.8c0.7-0.2,1.3-0.7,1.6-1.3c0.3-0.5,0.4-1.1,0.2-1.7c-0.1-0.2-0.1-0.5-0.2-0.7
							c0-0.2-0.1-0.4-0.2-0.5c-0.5-1-1.7-1.6-2.7-1.3c-1.3,0.4-2.1,1.6-1.8,2.9c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.8,0.9,1.5,1.7,1.8
							C835.3,605.9,835.8,605.9,836.2,605.8z"/>
						<path class="st4" d="M821.3,601.1c0.2-0.6,0.3-1.2,0.5-1.8c0.4-1.2-0.4-2.7-1.7-3c-1.3-0.3-2.7,0.3-3,1.7
							c-0.2,0.6-0.3,1.2-0.5,1.8c-0.4,1.2,0.4,2.7,1.7,3C819.6,603.1,821,602.4,821.3,601.1z"/>
						<path class="st4" d="M828.6,610.9c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.9-0.3-1.8-1.1-2.4c-0.8-0.6-1.6-1.2-2.4-1.8
							c-1-0.8-2.8-0.3-3.4,0.8c-0.7,1.2-0.3,2.6,0.8,3.4c0.8,0.6,1.6,1.2,2.4,1.8c0.5,0.4,1.1,0.4,1.7,0.3
							C827.6,611.8,828.3,611.5,828.6,610.9z"/>
						<path class="st4" d="M836.1,614.6c-1.2-0.7-2.5-0.3-3.4,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.6-0.6,1.2-0.8,1.8
							c-0.1,0.6-0.2,1.1-0.2,1.7c0.1,1.3,1,2.5,2.4,2.5c1.2,0,2.6-1,2.5-2.4c0-0.1,0-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.3
							c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4C837.6,617,837.1,615.2,836.1,614.6z M836.4,619
							c0-0.1,0-0.2,0-0.2C836.4,618.9,836.4,618.9,836.4,619z M836.4,618.6c0,0,0-0.1,0.1-0.2C836.5,618.5,836.5,618.5,836.4,618.6z
							"/>
						<path class="st4" d="M846.6,615.5c-0.2-0.2-0.4-0.5-0.7-0.6c-2.7-1.7-5.3,2.5-2.6,4.2c1.1,0.7,2.1,0.4,2.8-0.2
							C847,618,847.4,616.6,846.6,615.5z"/>
						<path class="st4" d="M824,613.6c-1.5-0.9-3,0-3.5,1.2c-0.4,1-0.2,2.2,0.9,2.9c0.8,0.5,1.5,0.5,2.2,0.2
							C825.2,617.2,825.9,614.8,824,613.6z"/>
						<path class="st4" d="M813.5,605.9c-0.4-0.2-0.7-0.3-1.1-0.4c-2.2-0.2-3.8,3.1-1.5,4.5c1.7,1.1,3.4-0.3,3.7-1.8
							C814.7,607.4,814.5,606.5,813.5,605.9z"/>
						<path class="st4" d="M857.4,592.7c0.3,0.2,0.6,0.3,0.9,0.3c2.3,0.4,4.1-3,1.7-4.5c-1.7-1-3.3,0.2-3.6,1.6
							C856.1,591.1,856.4,592.1,857.4,592.7z"/>
						<path class="st4" d="M808.1,595.6c2.7,1.7,5.3-2.5,2.6-4.2C808,589.8,805.4,593.9,808.1,595.6z"/>
						<path class="st4" d="M859.6,612.8c-0.7-0.4-1.4-0.5-2-0.3c-1.7,0.6-2.6,3.2-0.6,4.4c2.2,1.4,4.3-1.2,3.6-3
							C860.4,613.5,860.1,613.1,859.6,612.8z"/>
						<path class="st4" d="M722.8,592.4c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0.1,2.3-3.1,0.2-3.2c-0.6,0-1,0.2-1.3,0.6
							C722.1,590.7,722.1,591.9,722.8,592.4z"/>
						<path class="st4" d="M728.7,587c0.6,0,1.1-0.2,1.3-0.6h-2.5C727.8,586.8,728.1,587,728.7,587z"/>
						<path class="st4" d="M735.4,592.7c2.1,0.1,2.3-3.1,0.2-3.2C733.6,589.4,733.4,592.6,735.4,592.7z"/>
						<path class="st4" d="M718.2,587.9c1,0.1,1.6-0.7,1.6-1.5h-3.1C716.8,587.2,717.3,587.8,718.2,587.9z"/>
						<path class="st4" d="M738,587.4c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1-0.7h-4C736.5,587.1,737.2,587.6,738,587.4z"/>
						<path class="st4" d="M727.9,594.1c0.6,0.6,1.7,0.7,2.3,0.1c0.6-0.6,0.7-1.6,0.1-2.3c-0.1-0.1-0.2-0.2-0.3-0.3
							c-0.6-0.6-1.7-0.7-2.3-0.1c-0.6,0.6-0.7,1.6-0.1,2.3C727.7,593.9,727.8,594,727.9,594.1z"/>
						<path class="st4" d="M716.9,596.9c0.2,0.8,1.1,1.5,1.9,1.2c0.6-0.2,1-0.6,1.2-1.1c0.1-0.3,0.1-0.5,0-0.8
							c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.8-1.1-1.5-1.9-1.2c-0.8,0.2-1.4,1-1.2,1.9C716.7,596.1,716.8,596.5,716.9,596.9z"/>
						<path class="st4" d="M727.4,599.4c-0.1-0.8-0.7-1.4-1.5-1.5c-0.7-0.1-1.3-0.2-2-0.3c-0.8-0.1-1.7,0.7-1.7,1.5
							c0,0.9,0.6,1.6,1.5,1.7c0.7,0.1,1.3,0.2,2,0.3c0.4,0.1,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.7,0.6-1.1
							C727.4,599.5,727.4,599.5,727.4,599.4z"/>
						<path class="st4" d="M734.4,601.2c0.1-0.8-0.7-1.7-1.5-1.7c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.3-0.7,0.7-0.8,1.3
							c-0.1,0.9,0.1,1.9,0.6,2.7c0.5,0.7,1.4,1.2,2.2,0.7c0.7-0.4,1.2-1.4,0.7-2.2c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.1-0.1-0.2
							c0-0.1,0-0.1,0-0.2C734.4,601.3,734.4,601.2,734.4,601.2z"/>
						<path class="st4" d="M738.5,599.7c0.6,0,1.1-0.2,1.4-0.7c0.6-0.9,0.3-2.5-1.2-2.6c-0.3,0-0.5,0-0.7,0.1
							c-0.9,0.4-1.2,1.6-0.7,2.4C737.5,599.4,737.9,599.7,738.5,599.7z"/>
						<path class="st4" d="M725.4,605.9c2.1,0.1,2.3-3.1,0.2-3.2C723.5,602.5,723.3,605.7,725.4,605.9z"/>
						<path class="st4" d="M716.9,604.7c0.8,0,1.3-0.4,1.5-0.9c0.3-0.7,0.2-1.5-0.4-2c-0.2-0.2-0.5-0.3-0.9-0.4
							C715,601.3,714.8,604.6,716.9,604.7z"/>
						<path class="st4" d="M710.7,597.2c0.1,0,0.2,0,0.4,0c1.7-0.2,1.8-3.1-0.2-3.2c-1.3-0.1-1.8,1.1-1.6,2
							C709.4,596.6,709.9,597.2,710.7,597.2z"/>
						<path class="st4" d="M745.5,594.1c0.1,0,0.2,0,0.3,0c1.8,0.1,2.2-2.2,1-3c-0.2-0.1-0.5-0.2-0.8-0.3
							C744,590.8,743.7,593.8,745.5,594.1z"/>
						<path class="st4" d="M705.1,587.7c0.2,0.5,0.6,0.8,1.3,0.9c1.3,0.1,1.8-1.1,1.6-2.1h-3C704.9,586.8,704.9,587.3,705.1,587.7z"
							/>
						<path class="st4" d="M1549,589.7c2.3,1.3,4.5-1.3,3.7-3.2h-4.7C1547.5,587.5,1547.7,588.9,1549,589.7z"/>
						<path class="st4" d="M1568.5,593.7c-2.1-1.2-4.1,0.9-3.8,2.8c0.1,0.7,0.5,1.3,1.2,1.7c0.2,0.1,0.4,0.2,0.6,0.3
							C1569.2,599.3,1571.3,595.3,1568.5,593.7z"/>
						<path class="st4" d="M1573.5,592.3c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.6,0.2,1,0.2c1.3,0.3,2.9-0.5,3.2-1.9
							c0.3-1.4-0.5-2.8-1.9-3.2c-0.6-0.2-1.3-0.3-1.9-0.5c-1.3-0.3-2.9,0.5-3.2,1.9c0,0.2-0.1,0.3-0.1,0.5
							C1571.5,590.8,1572.3,592,1573.5,592.3z"/>
						<path class="st4" d="M1558.9,592.7c-0.4-1.3-1.9-2.3-3.2-1.8c-1.3,0.4-2.2,1.8-1.8,3.2c0.1,0.2,0.1,0.5,0.2,0.7
							c0.4,1.3,1.9,2.3,3.2,1.8c1.3-0.4,2.2-1.8,1.8-3.2C1559,593.2,1559,593,1558.9,592.7z"/>
						<path class="st4" d="M1541.7,590.3c0.3-1.3-0.5-2.9-1.9-3.2c-1.4-0.3-2.8,0.5-3.2,1.9c-0.2,0.6-0.3,1.3-0.5,1.9
							c-0.3,1.3,0.5,2.9,1.9,3.2c1.4,0.3,2.8-0.5,3.2-1.9C1541.4,591.5,1541.6,590.9,1541.7,590.3z"/>
						<path class="st4" d="M1548.4,598.7c-0.9-0.6-1.8-1.2-2.6-1.8c-1.1-0.8-3-0.2-3.6,1c-0.7,1.3-0.3,2.7,1,3.6
							c0.9,0.6,1.8,1.2,2.6,1.8c1.1,0.8,3,0.2,3.6-1C1550,601,1549.6,599.6,1548.4,598.7z"/>
						<path class="st4" d="M1557.4,606c-1.4-0.7-2.7-0.2-3.6,1c-0.8,1.2-1.3,2.8-1.1,4.3c0.1,1.4,1.1,2.7,2.6,2.6
							c1.3-0.1,2.7-1.2,2.6-2.6c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
							c0.1-0.2,0.2-0.3,0.3-0.4C1559.2,608.5,1558.6,606.7,1557.4,606z M1558.1,610c0,0,0,0.1-0.1,0.2
							C1558,610.2,1558,610.1,1558.1,610z M1557.9,610.5c0,0.1,0,0.2,0,0.3C1557.9,610.6,1557.9,610.5,1557.9,610.5z"/>
						<path class="st4" d="M1567.9,606c-2.9-1.7-5.5,2.8-2.6,4.5C1568.2,612.2,1570.8,607.6,1567.9,606z"/>
						<path class="st4" d="M1544.5,605.4c-2.9-1.7-5.5,2.8-2.6,4.5C1544.8,611.6,1547.5,607.1,1544.5,605.4z"/>
						<path class="st4" d="M1533.1,597.6c-2.9-1.7-5.5,2.8-2.6,4.5C1533.4,603.8,1536,599.2,1533.1,597.6z"/>
						<path class="st4" d="M1530,586.4h-3.5c0.1,0.1,0.3,0.3,0.5,0.4C1528.1,587.5,1529.2,587.2,1530,586.4z"/>
						<path class="st4" d="M1582.4,603.2c-1.7-1-3.3,0.2-3.7,1.6c-0.1,0.4-0.2,0.8-0.1,1.2c0.1,0.7,0.5,1.3,1.2,1.8
							C1582.7,609.4,1585.3,604.9,1582.4,603.2z"/>
					</g>
				</g>
				<g>
					<path class="st3" d="M1687.6,667.5c-1.9,0-3.5-1.6-3.5-3.5v-59.6c0-8-6.5-14.5-14.5-14.5h-551.5c-1.9,0-3.5-1.6-3.5-3.5
						s1.6-3.5,3.5-3.5h551.5c11.9,0,21.5,9.6,21.5,21.5V664C1691.1,665.9,1689.5,667.5,1687.6,667.5z"/>
				</g>
				<g>
					<path class="st4" d="M1637.9,652.7v316.6c0,9.2-7.4,16.6-16.6,16.6H571.1c-12.2,0-20.2-12.8-14.9-23.8l110.1-228.7l42.3-87.9
						c2.8-5.7,8.6-9.4,14.9-9.4h897.8C1630.4,636.2,1637.9,643.6,1637.9,652.7z"/>
					<path class="st3" d="M1637.9,831.3v4.9c-28.5,7.7-57.1,15.4-85.6,23.1c-43.3,11.7-125.2,34.8-169.8,21.2
						c-19.6-6-38.1-15.4-58.3-19.8c-18.5-4-37.3-2.1-53.9,7.3c-30.4,17.3-48.7,52.8-51,87c-0.7,10.4-0.3,20.7,0.9,30.9h-5
						c-1.6-13.6-1.7-27.4,0.4-41.2c5.1-33.5,25.2-67.5,56.2-82.9c15.8-7.9,33.2-9.6,50.5-6.3c21.3,4,40.8,14.1,61.4,20.4
						c47.1,14.4,132.6-11.9,178.2-24.1C1587.2,844.9,1612.5,838.1,1637.9,831.3z"/>
					<path class="st3" d="M1637.9,711.9v4.8c-41.7-0.9-84.1-8.2-125,2.6c-21,5.6-38.3,17-56,29c-15.5,10.5-31.9,21.3-50.9,23.6
						c-21.2,2.6-40.4-7.5-56.8-19.9c-18.5-14-35.8-30-52.1-46.5c-15.4-15.7-31.8-32.2-43.1-51.2c-3.4-5.6-6.3-11.8-8.3-18.2h4.8
						c3.7,11.2,11,21.9,17.7,30.2c15.7,19.4,33.2,37.5,51.5,54.5c15.9,14.6,32.6,30.4,52.1,40c18.9,9.3,38.6,8.1,57.2-1.1
						c17.1-8.5,31.9-20.8,48.2-30.5c17.3-10.3,36.4-16.2,56.3-18.7C1568.3,706.1,1603.1,711.1,1637.9,711.9z"/>
					<path class="st3" d="M1454.6,934.4c4.1,5.5,11.2,8.2,17.6,10c8.2,2.4,16.6,4.1,25,5.8c46.2,9.3,93.7,12,140.7,8.2v4.8
						c-16.4,1.3-32.8,1.8-49.3,1.6c-38.5-0.6-77.3-5.4-114.6-15c-11.1-2.9-26-8.6-26.8-22.1c-0.8-13.2,12.9-21,23.8-24.4
						c16.2-5.2,34-7,50.9-8.3c18-1.4,36.2-1.8,54.3-2.4c20.6-0.7,41.4-1.7,61.7-5.3v4.8c-20,3.5-40.6,4.6-60.9,5.3
						c-33.7,1.1-68.2,0.9-101.1,9.5C1464.9,909.6,1443.9,920.1,1454.6,934.4z"/>
					<path class="st3" d="M1219.8,831.7c-12.1,16.9-37.2,22.7-56.6,24.9c-22.4,2.5-45,0.2-67.4-1.3c-22-1.5-44.1-1.8-66.1-0.7
						c-22.5,1.1-44.6,4.2-66.9,7.5c-10.7,1.6-22.6,2.4-31.9-4.1c-3.4-2.3-6.3-5.4-6.4-9.7c-0.2-5.6,4-8.4,8.3-11.1
						c17.9-11.3,38.4-22.1,60.2-20.2c19.4,1.7,37.2,10.8,56.2,14.3c20.5,3.7,40.7-1.1,59.8-8.2c23-8.6,45.3-20.5,70-23.4
						c10.9-1.3,23.2-1,33.3,3.9c11.7,5.7,14.1,17.9,7.3,28.6c-1.6,2.6,2.7,4.6,4.3,2c4.9-7.7,6.4-17.7,1.4-25.7
						c-4.4-7-12.7-10.7-20.5-12.5c-22.7-5.2-46.3,2.2-67.3,10.5c-22.6,9-45.3,20.5-70.1,21.4c-22.9,0.9-43.4-10.4-65.4-14.5
						c-22.3-4.1-42.9,3.1-62.2,14.1c-7.1,4.1-17.4,8.3-19.5,17.2c-1.9,8,3.2,14.5,9.7,18.4c9,5.3,19.7,5.6,29.8,4.3
						c12.6-1.5,25.2-4,37.8-5.4c25.1-2.8,50.5-3.9,75.7-3.1c28.1,0.8,56.3,5.2,84.5,3c12.6-1,25.2-3.3,37.1-7.7
						c10.8-4.1,21.9-9.8,28.7-19.4C1225.2,832.3,1221.6,829.2,1219.8,831.7z"/>
					<path class="st3" d="M1127.4,710.4c-5.3,5.7-13,9.1-19.9,12.5c-7.9,3.9-16.1,7.3-24.4,10.4c-17,6.2-34.7,10.7-52.6,13.3
						c-75.2,10.9-147.1-15.4-218.5-35.2c-40.7-11.2-82.2-19.1-124-23.3l2.2-4.5c46.2,4.9,91.9,14.2,136.8,27.1
						c69.5,19.9,140.2,43.3,213.3,29.5c17.5-3.3,34.7-8.4,51.2-15.2c8.1-3.3,16.2-7.1,23.8-11.4c7-4,13.9-9.7,12.6-18.6
						c-2.7-18.7-24.5-21.3-39.5-19.6c-17.3,2-34.2,5-51.7,5.8c-67.8,2.9-134.5-12.6-200.5-25.8c-39.5-7.9-79.6-15.3-119.9-17.5
						c2.2-1,4.6-1.6,7.1-1.6h29.4c37.9,4.6,75.5,12.6,112.7,20.1c69.7,14.1,140.7,27.2,211.8,15.8c15.3-2.5,33-5.2,45.8,5.7
						C1132.8,686.1,1136.7,700.3,1127.4,710.4z"/>
					<path class="st3" d="M1115.8,985.9h-14.5c-7.2-2.2-14.3-4.3-21.3-6.4c-49.1-15.2-98.9-29.7-150.2-34.9
						c-48.8-5-97.9,0.4-146.7-4.4c-24.2-2.4-47.4-7.7-70-16.7c-23-9.2-45.1-19-69.7-22.9c-18.8-3-37.6-3.5-56.5-2.4l2.3-4.9
						c23.8-1.2,47.5,0.1,70.9,5.7c24.6,6,46.8,18,70.8,25.9c24.2,7.9,49.6,11,75,12c25.3,1.1,50.7,0.2,76,0.5
						c25.4,0.2,50.6,2,75.7,6c51,8.2,101.1,23.6,150.1,39.6C1110.4,984,1113.1,984.9,1115.8,985.9z"/>
				</g>
			</g>
			<g>
				<circle class="st3" cx="1790.9" cy="1109" r="96"/>
				<circle class="st2" cx="1790.9" cy="1109" r="31.3"/>
				<g>
					<path class="st2" d="M1773,1180.3c-0.3,0-0.6,0-0.9-0.1c-30.4-5.8-50.7-28.1-51.8-56.9c-0.1-2.6,1.9-4.7,4.5-4.8
						c2.5-0.1,4.7,1.9,4.8,4.5c0.9,24.3,18.2,43.2,44.2,48.1c2.5,0.5,4.2,2.9,3.7,5.4C1777.1,1178.7,1775.1,1180.3,1773,1180.3z"/>
				</g>
				<circle class="st3" cx="1790.9" cy="1109" r="12.7"/>
			</g>
			<g>
				<circle class="st3" cx="729.9" cy="1109" r="96"/>
				<circle class="st2" cx="729.9" cy="1109" r="31.3"/>
				<circle class="st3" cx="729.9" cy="1109" r="12.7"/>
				<g>
					<path class="st2" d="M663.3,1096c-0.3,0-0.6,0-0.9-0.1c-2.5-0.5-4.2-2.9-3.7-5.4c5.6-30.4,27.9-50.8,56.7-52
						c2.6-0.1,4.7,1.9,4.8,4.4s-1.9,4.7-4.4,4.8c-24.3,1-43.1,18.4-47.9,44.4C667.4,1094.4,665.5,1096,663.3,1096z"/>
				</g>
			</g>
			<g>
				<circle class="st3" cx="995.1" cy="1109" r="96"/>
				<circle class="st2" cx="995.1" cy="1109" r="31.3"/>
				<g>
					<path class="st2" d="M1047.6,1155.9c-1.3,0-2.6-0.5-3.5-1.6c-1.7-1.9-1.5-4.9,0.4-6.5c18.3-16,22-41.4,9.5-64.6
						c-1.2-2.3-0.4-5.1,1.9-6.3c2.3-1.2,5.1-0.4,6.3,1.9c14.7,27.2,10.2,57.1-11.5,76C1049.8,1155.5,1048.7,1155.9,1047.6,1155.9z"
						/>
				</g>
				<circle class="st3" cx="995.1" cy="1109" r="12.7"/>
			</g>
		</g>
		<g>
			<path class="st3" d="M2107.9,1211.4H445.7c-3.9,0-7-3.1-7-7s3.1-7,7-7h1662.2c3.9,0,7,3.1,7,7S2111.8,1211.4,2107.9,1211.4z"/>
		</g>
	</g>
	<g>
		<path class="st8" d="M666.2,733.4v235.9c0,9.2-7.4,16.6-16.6,16.6h-78.5c-12.2,0-20.2-12.8-14.9-23.8L666.2,733.4z"/>
		<path class="st6" d="M649.6,989.7h-78.5c-7.1,0-13.5-3.6-17.3-9.5c-3.8-6-4.2-13.3-1.1-19.7l110.1-228.7c0.8-1.6,2.5-2.5,4.3-2.1
			c1.7,0.4,3,1.9,3,3.7v235.9C670,980.5,660.9,989.7,649.6,989.7z M662.4,750.1L559.6,963.8c-1.9,4-1.7,8.6,0.7,12.3
			c2.4,3.7,6.4,6,10.8,6h78.5c7,0,12.8-5.7,12.8-12.8V750.1z"/>
	</g>
	<g>
		<path class="st7" d="M591.6,808.7l-111.9-72.4c-4.6-3-10.6,0.3-10.6,5.8v36.1H257.2c-3.8,0-6.9,3.1-6.9,6.9v58.8
			c0,3.8,3.1,6.9,6.9,6.9h211.8v36.1c0,5.4,6,8.7,10.6,5.8l111.9-72.4C595.8,817.5,595.8,811.4,591.6,808.7z"/>
		<g>
			<path class="st3" d="M469.1,829.7H257.2c-3.8,0-6.9-3.1-6.9-6.9v21c0,3.8,3.1,6.9,6.9,6.9h211.8V829.7z"/>
			<path class="st3" d="M591.6,808.7l-7.3-4.8l-104.6,67.7c-4.6,3-10.6-0.3-10.6-5.8v21c0,5.4,6,8.7,10.6,5.8l111.9-72.4
				C595.8,817.5,595.8,811.4,591.6,808.7z"/>
		</g>
		<path class="st6" d="M578.6,807.1c-1.3,0-2.6-0.4-3.7-1.1l-98.9-64v36.1c0,3.8-3.1,6.9-6.9,6.9H257.8c-3.8,0-6.9-3.1-6.9-6.9
			c0-3.8,3.1-6.9,6.9-6.9h204.4V742c0-5,2.7-9.7,7.2-12.1c4.4-2.4,9.8-2.2,14,0.5l98.9,64c3.2,2.1,4.1,6.3,2,9.5
			C583.1,806,580.9,807.1,578.6,807.1z"/>
	</g>
</g>
</svg>
</template>

<style lang="scss" scoped>
.st0{fill:#FFE4DE;}
.st1{fill:#FFEEEB;}
.st2{fill:#265987;}
.st3{fill:#173D60;}
.st4{fill:#F99178;}
.st5{fill:#D65C5C;}
.st6{fill:#FFFFFF;}
.st7{fill:#58DEF4;}
.st8{opacity:0.8;fill:#279193;}
</style>