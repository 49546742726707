export const auth = [
  {
    path: '',
    redirect: {name: 'login'}
  },
  {
    path: 'login',
    name: 'login',
    components: {
      default: () => import('@/pages/auth/login'),
    },
    meta: {
      layout: 'auth-layout',
      title: 'Авторизация',
      breadcrumb: [{
        name: 'login',
        description: 'Авторизация'
      }, ]
    }
  },
  {
    path: 'recovery',
    name: 'recovery',
    components: {
      default: () => import('@/pages/auth/recovery'),
    },
    meta: {
      layout: 'auth-layout',
      title: 'Восстановление пароля',
      breadcrumb: [{
        name: 'recovery',
        description: 'Восстановление пароля'
      }, ]
    }
  },
  {
    path: 'revert_recovery',
    name: 'revert_recovery',
    components: {
      default: () => import('@/pages/auth/revert_recovery'),
    },
    meta: {
      layout: 'auth-layout',
      title: 'Подтверждение восстановление пароля',
      breadcrumb: [{
        name: 'revert_recovery',
        description: 'Подтверждение восстановление пароля'
      }, ]
    }
  },
]

export const filter_auth = auth.filter(item => item.path);