<template>
  <transition name="curtain">
    <curtain_modal
      @close="$emit('close')"
      >
      <component :is='key_event' />
    </curtain_modal>
  </transition>
</template>

<script>
import curtain_modal from "@/components/modals/curtain_modal.vue";
import breakdown from "@/components/events_garbage/breakdown.vue"
import failure from "@/components/events_garbage/failure.vue"
import kgo from "@/components/events_garbage/kgo.vue"
import kgo_remaining from "@/components/events_garbage/kgo_remaining.vue"


export default {
    components: {
        curtain_modal,
        breakdown,
        failure,
        kgo,
        kgo_remaining,
    },
    props: {
        key_event: {
            required: true,
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
    
</style>