<template>
  <template v-if="$route.meta.layout">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </template>
  <transition name="fade">
    <preloader-page v-if="globalStore.preloader_page"></preloader-page>
  </transition>
  <transition name="fade">
    <events_message v-if="globalStore.events_message?.events" />
  </transition>
</template>

<script>
import { global_state } from "@/store/global";
import events_message from '@/components/event_messages/event_messages'

export default {
  components: {
    events_message
  },
  data() {
    return {
      registration_sw: null,
      refreshing: false,
    };
  },
  setup() {
    const globalStore = global_state();
    return { globalStore };
  },
  created() {
    document.addEventListener(
        'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
    );

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
  methods: {
    appUpdateUI(event) {
      this.registration_sw = event.detail;
      if (!this.registration_sw || !this.registration_sw.waiting) return
      this.registration_sw.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};
</script>

<style lang="scss">
//не удалять и не добавлять scoped!!!!!!!!!!
//что бы не было бага с подключением стилей, один из тегов style без scoped должен быть заполнен чем-нибудь, к примеру этим комментарием
@import "./styles/reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import "./styles/base.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
div,
input,
span {
  font-family: "Nunito", sans-serif;
  color: $base-color-text;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

img, svg {
  vertical-align: text-top;
}
</style>
