<template>
  <div class="preloader-page">
    <div class="preloader-page-top">
      <div class="top-img">
        <img src="@/assets/auth_logo.svg" alt="" />
      </div>
      <div class="top-text">
        <img src="@/assets/case.svg" alt="" />
        <span>Руководитель</span>
      </div>
    </div>
    <div class="preloader-page-middle">
      <img src="@/assets/preloader_boss.svg" alt="" />
    </div>
    <div class="preloader-page-bottom">
      <preloaderLogo />
    </div>
  </div>
</template>

<script>
import preloaderLogo from "@/components/icons_color/preloader_logo";

export default {
  components: {
    preloaderLogo,
  },
};
</script>

<style lang="scss" scoped>
.preloader-page {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background-color: $layout-blue;
}

.preloader-page-top {
  margin: 0 auto;
}
.top-img {
  margin-bottom: 22px;
  width: 269px;

  & img {
    width: 100%;
  }
}

.top-text {
  text-align: center;

  & img {
    vertical-align: inherit;
    margin-right: 12px;
  }

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
  }
}

.preloader-page-middle {
  text-align: center;
}

.preloader-page-bottom {
  text-align: center;
}
</style>