<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.03 426.03">
      <path
        d="M386.54,426h-347A39.53,39.53,0,0,1,0,386.54v-347A39.54,39.54,0,0,1,39.49,0H386.54A39.53,39.53,0,0,1,426,39.49V386.54A39.53,39.53,0,0,1,386.54,426ZM39.49,7.92A31.61,31.61,0,0,0,7.92,39.49V386.54a31.6,31.6,0,0,0,31.57,31.57H386.54a31.6,31.6,0,0,0,31.57-31.57v-347A31.61,31.61,0,0,0,386.54,7.92Z"
        style="fill: #c8cbce"
      />
      <path
        d="M336.55,108.14h-23v-6.35A1.78,1.78,0,0,0,311.8,100H262.47v-9.4a3,3,0,0,0-3-3h-93a3,3,0,0,0-3,3V100H114.23a1.77,1.77,0,0,0-1.77,1.78v6.35h-23a1.77,1.77,0,0,0-1.77,1.78v24.27A1.78,1.78,0,0,0,89.48,136H336.55a1.78,1.78,0,0,0,1.78-1.78V109.92A1.78,1.78,0,0,0,336.55,108.14Z"
        :style="{fill: '#' + color}"
      />
      <circle cx="167.01" cy="323.81" r="14.58" style="fill: #aaadb2" />
      <circle cx="259.03" cy="323.81" r="14.58" style="fill: #aaadb2" />
      <path
        d="M129.13,294.71a1.77,1.77,0,0,0,1.76,1.58H295.14a1.79,1.79,0,0,0,1.77-1.58L313.47,147.5a.94.94,0,0,0-.94-1.06h-199a1,1,0,0,0-.94,1.06Zm132.15-130.9a.59.59,0,0,1,.6-.59h6.66a.59.59,0,0,1,.59.59V265.55a.6.6,0,0,1-.59.59h-6.66a.6.6,0,0,1-.6-.59Zm-104.37,0a.58.58,0,0,1,.59-.59h6.66a.59.59,0,0,1,.59.59V265.55a.6.6,0,0,1-.59.59H157.5a.59.59,0,0,1-.59-.59Z"
        :style="{fill: '#' + color}"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: { 
      default: "fff"
      // default: "aaadb2"
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
