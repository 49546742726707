<template>
  <div class="images-modal" @click.self="$emit('close')">
    <div class="images-modal-body">
      <div class="loupe" @click="$emit('close')">
      <img src="@/assets/images/events/loupe_minus.svg" alt=""></div>
      <img :src="host(link)" alt="" v-for="(link, index) of images" :key="index">
    </div>
  </div>
</template>

<script>
export default {
  name: "images_modal",
  props: {
    images: {
      required: true,
      // type: () => []
    }
  },
  methods: {
    host(link) {
      return `${process.env.VUE_APP_WN}/file/${link}?api_token=${localStorage.getItem('auth_token')}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .images-modal {
    padding: 30px 0;
    overflow: auto;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(7, 21, 30, 0.8);
  }

  .images-modal-body {
    position: relative;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  .loupe {
    padding: 13px;
    position: absolute;
    top: 26px;
    right: 26px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    filter: drop-shadow(0px 5.45455px 21.8182px rgba(23, 21, 55, 0.1));
    text-align: center;
  }
</style>