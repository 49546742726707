import { get_token, get_permission, get_owner, set_recovery } from "@/services/auth";
import { get_online_monitoring, get_events_counter_list, get_vehicle_statistic } from "@/services/summary";
import { get_vehicles_sensor } from "@/services/carpark";
import { get_filling_sensor_stats } from "@/services/sensors";
import { get_event_failure, get_event_breakdown, get_event_kgo, get_event_remaining_kgo } from "@/services/events";

export const Api = () => {
  const result = {
    auth: {
      login: get_token,
      permission: get_permission,
      owner: get_owner,
      recovery: set_recovery,
    },
    summary: {
      online_monitoring: get_online_monitoring,
      events_counter_list: get_events_counter_list,
      vehicle_statistic: get_vehicle_statistic,
    },
    carpark: {
      vehicles_sensor: get_vehicles_sensor,
    },
    sensors: {
      filling_sensor_stats: get_filling_sensor_stats,
    },
    events: {
      event_failure: get_event_failure, 
      event_breakdown: get_event_breakdown, 
      event_kgo: get_event_kgo, 
      event_remaining_kgo: get_event_remaining_kgo
    }
  }

  return result
}