import { defineStore } from 'pinia'

export const global_state = defineStore('global_state', {
  state: () => ({    
    use_organisation: {},
    use_date: '',
    preloader_page: true,
    events_message: {
      events: '',
      message: '',
    }
   }),
   actions: {
    set_use_organisation({use_organisation}) {
      localStorage.setItem('use_organisation', use_organisation.id)

      this.use_organisation = use_organisation;
    },
    set_use_date({use_date}) {
      this.use_date = use_date;
    },
    set_preloader_page({view}) {
      this.preloader_page = view;
    },
    set_events_message(payload) {
      this.events_message = payload? payload: {
        events: '',
        message: '',
      };
    },
   }
})