<template>
  <div class="curtain_modal" @click.self="animate_close('close')">
    <transition name="modal-curtain">
      <div class="curtain_modal_block" v-if="animation_start">
        <div class="header">
          <div class="line"></div>
        </div>
        <div class="body">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    select_organisation: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      touch_start: 0,
      touch_end: 0,
      animation_start: false,
      target_line: false,
      divHome: null,
    };
  },
  watch: {
    select_organisation() {
      this.animate_close('update');
    },
  },
  created() {
    window.ontouchstart = ({ changedTouches, target }) => {
      const { screenY } = changedTouches[0];
      this.target_line = ["header", "line", "curtain_modal"].includes(
        target.className
      )
        ? true
        : false;
      this.touch_start = screenY;
    };

    window.ontouchend = ({ changedTouches }) => {
      const { screenY } = changedTouches[0];

      this.touch_end = screenY;
      this.close_window();
    };

    this.divHome = window.document.querySelector('.home');
    this.divHome.style.overflowY = 'hidden'
  },
  mounted() {
    this.animation_start = true;
  },
  methods: {
    close_window() {
      if (this.touch_end - this.touch_start > 20 && this.target_line) {
        this.animate_close('close');
      }
      this.target_line = false;
    },
    animate_close(emit) {
      this.animation_start = false;

      setTimeout(() => {
        this.$emit(emit);
      }, 400);
    },
  },
  unmounted() {
    window.ontouchstart = null;
    window.ontouchend = null;
    this.divHome.style.overflowY = 'scroll'
  },
};
</script>

<style lang="scss" scoped>
.curtain_modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(7, 21, 30, 0.8);
}

.curtain_modal_block {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: $layout-blue;
  border-radius: 20px 20px 0px 0px;
}

.header {
  text-align: center;
  padding-bottom: 50px;
}

.line {
  display: inline-block;
  width: 50px;
  height: 6px;
  background: #8494a6;
  border-radius: 6px;
}

.body {
  overflow-y: auto;
  height: calc(100% - 122px);
}

.modal-curtain-enter-active,
.modal-curtain-leave-active {
  transition: transform 0.4s ease;
}

.modal-curtain-enter-from,
.modal-curtain-leave-to {
  transform: translateY(100%);
}
</style>