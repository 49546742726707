const url_wn = `${process.env.VUE_APP_WN}/`;

export const get_online_monitoring = async ({
    organisation,
    date
}) => {
  //Ожидает из параметров query
  //key: о с id организации 
  //key: date для фильтрации по выбранному дню

  // for (const [key, value] of Object.entries(query)) {
  //   if (key == 'o' || key == 'date') {
  //      url.searchParams.set(key, value)
  //   }
  // }
  const url = new URL('fact/workorder/summary', url_wn);
  url.searchParams.set('o', organisation)
  url.searchParams.set('date', date)

  return await window.ky_wn.get(url).json();
}

export const get_events_counter_list =
  async ({
      organisation: o,
      date,
      page: page = 'all',
      waste_type_ids: waste_type_ids = [],
  }) => {
    //Ожидает из параметров query
    //key: о с id организации 
    //key: date для фильтрации по выбранному дню
    //key: page = 'all' дефолтное все страницы 
    //key: waste_type_ids = [] массив с id типа контейнерной площадки
    const url = new URL('fact/summary/counter_list', url_wn);

    return await window.ky_wn.post(url, {
      json: {
        o,
        date,
        page,
        waste_type_ids,
      }
    }).json();
  }

export const get_vehicle_statistic = async ({
  vehicle_id,
  date,
}) => {
  //Ожидает из параметров query
  //key: vehicle_id с id машины 
  //key: date для фильтрации по выбранному дню
  const url = new URL(`fact/vehicle/stats/${vehicle_id}`, url_wn);
  url.searchParams.set('begin_date', date)
  url.searchParams.set('end_date', date)

  return await window.ky_wn.get(url).json();
}