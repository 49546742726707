<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16667 0H19.8333C20.1428 0 20.4395 0.122916 20.6583 0.341709C20.8771 0.560501 21 0.857247 21 1.16667V9.33333H0V1.16667C0 0.857247 0.122916 0.560501 0.341709 0.341709C0.560501 0.122916 0.857247 0 1.16667 0ZM0 11.6667H21V19.8333C21 20.1428 20.8771 20.4395 20.6583 20.6583C20.4395 20.8771 20.1428 21 19.8333 21H1.16667C0.857247 21 0.560501 20.8771 0.341709 20.6583C0.122916 20.4395 0 20.1428 0 19.8333V11.6667ZM4.66667 15.1667V17.5H8.16667V15.1667H4.66667ZM4.66667 3.5V5.83333H8.16667V3.5H4.66667Z"
      :fill="icon_color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon_color: {
      type: String,
      default: "white",
    },
  },
};
</script>