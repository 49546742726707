<!-- Всего у нас два статуса danger, warning, в поле events толжно быть корректное название события для отображения -->

<template>
  <div class="event-messages" @click.self="globalStore.set_events_message(null)">
    <div class="block">
      <div class="block-close">
        <div class="block-close-img" :class="{ 'block-close-img--active': out_modal }" @click="close_events">
          <img src="@/assets/close.svg" alt="">
        </div>
      </div>
      <div class="block-header"><img :src="require(`@/assets${img[events_message.events]}`)" alt=""></div>
      <div class="block-body">
        <div class="block-body-title">
          {{ title[events_message.events] }}
        </div>
        <div class="block-body-text">
          {{ events_message.message }}
        </div>
      </div>
      <div class="block-footer"><button class="button is-primary" :disabled="disableButton" @click.prevent="refresh_page">Обновить</button></div>
    </div>
  </div>
</template>

<script>
import { global_state } from "@/store/global";

export default {
  data() {
    return {
      disableButton: false,
      out_modal: false,
      title: {
        danger: `Ошибка!`,
        warning: `Внимание!`
      },
      img: {
        danger: `/images/danger.svg`,
        warning: `/images/warning.svg`
      }
    }
  },
  setup() {
    const globalStore = global_state();
    const { events_message } = global_state();

    return { globalStore, events_message };
  },
  methods: {
    close_events() {
      this.out_modal = true;

      setTimeout(() => {
        this.globalStore.set_events_message(null);
      }, 400);
    },
    refresh_page() {
      this.disableButton = true;

      setTimeout(() => {
        this.$router.go();
      }, 400);
    }
  },
}
</script>

<style lang="scss" scoped>
  .event-messages {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(7, 21, 30, 0.8);
  }

  .block {
    margin: 0 57px;
    width: 100%;
    min-height: 396px;
    background: #F8FDFF;
    border-radius: 10px;
  }

  .block-header {
    margin-bottom: 32px;
    text-align: center;
  }

  .block-body {

    &-title {
      margin-bottom: 10px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }

    &-text {
      padding: 0 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .block-footer {
    margin: 60px 0 42px;
    padding: 0 30px;
  }

  .block-close {
    padding: 10px 10px 10px 0;
    &-img {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      aspect-ratio: 1;
      border-radius: 50%;
      transition: background 0.2s ease;
      
      &--active {
        background: rgba(148, 162, 183, 0.29);
      }
    }
  }
</style>