<template>
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 25C0.895431 25 0 24.1046 0 23V9.3273C0 8.70119 0.293213 8.1112 0.792285 7.73312L9.79229 0.914935C10.5065 0.373847 11.4935 0.373848 12.2077 0.914936L21.2077 7.73312C21.7068 8.1112 22 8.70119 22 9.3273V23C22 24.1046 21.1046 25 20 25H15.75C14.6454 25 13.75 24.1046 13.75 23V16.2778C13.75 15.7255 13.3023 15.2778 12.75 15.2778H9.25C8.69772 15.2778 8.25 15.7255 8.25 16.2778V23C8.25 24.1046 7.35457 25 6.25 25H2Z"
      :fill="icon_color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon_color: {
      type: String,
      default: "white",
    },
  },
};
</script>