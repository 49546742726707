const url_wn = `${process.env.VUE_APP_WN}/`;

export const get_filling_sensor_stats =
  async ({organisation_id}) => {
    //Ожидает из параметров query
    //key: organisation_id с id организации, если организации нет, будет показывать все gserver по пользователю, (включая дочерние орг).
    const url = new URL('filling_sensor_stats', url_wn);
    url.searchParams.append('organisation_id', organisation_id);
    
    let result = await window.ky_wn.get(url).json();

    return result
  }