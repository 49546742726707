import { defineStore } from 'pinia'

export const auth = defineStore('auth', {
  state: () => ({
    organisations: [],
    owner: {},
   }),
   actions: {
    set_owner(owner) {
      const { organisations } = owner;
      this.owner = owner;
      this.organisations = organisations;
    }
   }
})