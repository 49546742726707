import router from '@/routes';

export const guard_auth = (request) => { //Нужно для хука перед отправкой данных, если токена нет, то переводим на страницу с логином
  const auth_token = localStorage.getItem('auth_token');
  if (auth_token) {
    request.headers.set('Authorization', `Bearer ${auth_token}`);
  } else {
    redirect();
  }
}

export const guard_error = (error) => { //Нужно для хука на ошибки, если протух токен, то переводим на страницу с логином
  //если нужно вывести данные об ошибке вызываем окно с с текстом ошибки
  const {response} = error;
  
  if (response && response.body) {
    if (response.status === 401) {
      redirect();
    }
  }

  return error;
}

const redirect = () => {
  localStorage.removeItem('auth_token');
  
  router.beforeEach((to, from, next) => { //Нужно, что бы если было много запросов на странице, поле redirect не записывало само себя
    if (to.name == 'autoLogin') {
      next()
    } else if (to.name != 'login') {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  })
}
