<template>
  <div class="layout">
    <myHeader />
    <template v-if="isLoading">
      <slot></slot>
    </template>
    <my-footer />
  </div>
</template>
<script>
import { auth } from "@/store/auth";
import { global_state } from "@/store/global";
import myFooter from "@/components/footer";
import myHeader from "@/components/headers";

export default {
  components: {
    myFooter,
    myHeader,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    Api.auth
    .owner()
    .then((res) => {
      //из полученных данных по организации, пытаемся найти совпадение по данным из стора, нужно для того,
      //что бы если организации изменяться, и в сторе будут устаревшие данные не было проблем
      const find_use_organisation = res.data?.organisations.find(item => item.id == localStorage.getItem('use_organisation')); 
      const use_organisation = find_use_organisation? find_use_organisation: res.data?.organisations[0];

      this.authStore.set_owner(res.data);
        this.globalStore.set_use_organisation({use_organisation});
        this.globalStore.set_use_date({use_date: new Date().toISOString().substr(0, 10)});
        // this.globalStore.set_use_date({use_date: '2023-03-24'});
        this.isLoading = true;
      })
      // .finally(() => {
      //   if (!localStorage.getItem('auth_token')) {
      //     this.$router.push({name: 'login'})
      //   }
      // });
  },
  setup() {
    const authStore = auth();
    const globalStore = global_state();
    globalStore.set_preloader_page({ view: true });

    return { authStore, globalStore };
  },
};
</script>
<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr 78px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
  background: $layout-blue;
}
</style>