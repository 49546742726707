<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 2500 1664" style="enable-background:new 0 0 2500 1664;" xml:space="preserve">
	<g>
		<g>
			<g>
				<g>
					<path id="XMLID_25_" class="st2" d="M2007.1,1094.4H586.6c-12.2,0-22.1-9.9-22.1-22.1v-15.9c0-12.2,9.9-22.1,22.1-22.1h1420.5
						c12.2,0,22.1,9.9,22.1,22.1v15.9C2029.2,1084.5,2019.3,1094.4,2007.1,1094.4z"/>
					<g>
						<path class="st3" d="M1626.1,1083.1c-1-0.5-1.4-1.4-1.3-2.2c-1,0.7-1.2,2.6,0.4,3.1c0.9,0.3,1.6-0.1,2-0.7
							C1626.8,1083.3,1626.4,1083.2,1626.1,1083.1z"/>
						<path class="st3" d="M1624.2,1091.4c-1.9-0.6-3,1.9-1.8,3h2.3C1625.6,1093.6,1625.6,1091.9,1624.2,1091.4z"/>
						<path class="st3" d="M1631.6,1087.1c-0.1-0.1-0.3-0.2-0.5-0.3c-2.2-0.7-3.3,2.6-1.1,3.4c1.2,0.4,2.1-0.5,2.3-1.5
							C1631.7,1088.3,1631.5,1087.7,1631.6,1087.1z"/>
						<path class="st3" d="M1636.4,1081.9c-2.2-0.7-3.3,2.6-1.1,3.4C1637.4,1086,1638.5,1082.7,1636.4,1081.9z"/>
						<path class="st3" d="M1619.7,1084.7c-2.2-0.7-3.3,2.6-1.1,3.4C1620.8,1088.8,1621.9,1085.4,1619.7,1084.7z"/>
						<path class="st3" d="M1639.4,1091.7c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.2,0.1-0.4,0.3-0.6c0,0,0,0-0.1,0c-0.9,0-1.9,0.7-1.9,1.7
							c0,0.9,0.7,1.8,1.7,1.9C1639.4,1092.8,1639.3,1092.2,1639.4,1091.7z"/>
						<path class="st3" d="M1615.8,1094.4h1.4c-0.2-0.1-0.4-0.2-0.6-0.2C1616.3,1094.2,1616.1,1094.3,1615.8,1094.4z"/>
						<path class="st3" d="M1634.1,1074.1c1.8,1,0.9,3.4-0.8,3.6c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.6-0.6,0.9-0.8
							c0.7-0.6,0.9-1.8,0.2-2.5c-0.6-0.7-1.8-0.9-2.5-0.2c0,0-0.1,0.1-0.1,0.1C1633.3,1073.8,1633.7,1073.8,1634.1,1074.1z"/>
						<path class="st3" d="M1643.3,1082.2c-2.2-0.7-3.3,2.6-1.1,3.4C1644.4,1086.3,1645.5,1082.9,1643.3,1082.2z"/>
						<path class="st3" d="M1617.3,1075.7c-2.2-0.7-3.3,2.6-1.1,3.4C1618.3,1079.7,1619.4,1076.4,1617.3,1075.7z"/>
						<path class="st3" d="M1609.1,1092.4c-1.4-0.5-2.4,0.8-2.3,2h3.4C1610.4,1093.6,1610.1,1092.7,1609.1,1092.4z"/>
						<path class="st3" d="M1607.1,1082c-1-0.4-1.8,0.3-2.2,1.1c0.2,0,0.3,0.1,0.5,0.2c0.8,0.3,1.4,1.3,1.4,2.2
							C1608.4,1085.1,1609,1082.6,1607.1,1082z"/>
						<path class="st3" d="M1655.8,1077.7c-0.1,0-0.2,0.1-0.4,0.1c-0.7,0-1.4,0.1-2.2,0.1c-0.5,0-0.9-0.1-1.2-0.3
							c-0.1,0.7,0.1,1.5,0.9,1.9C1654.5,1080.3,1655.9,1079,1655.8,1077.7z"/>
						<path class="st3" d="M1650.6,1087.2c-2.2-1.2-4,2.2-1.8,3.4C1651,1091.8,1652.8,1088.4,1650.6,1087.2z"/>
						<path class="st3" d="M1658.8,1083.7c-2.2-1.2-4,2.2-1.8,3.4C1659.1,1088.2,1661,1084.8,1658.8,1083.7z"/>
						<path class="st3" d="M1663.2,1093.2c-1.2-0.7-2.4,0.1-2.7,1.2h3.6C1664.1,1093.9,1663.8,1093.5,1663.2,1093.2z"/>
						<path class="st3" d="M1665.3,1079.4c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.1c-0.5,0.4-1.2,0.5-1.7,0.2
							c-0.9,0.8-1.1,2.4,0.2,3.2C1665.7,1083.9,1667.5,1080.6,1665.3,1079.4z"/>
						<path class="st3" d="M1646.3,1080c0.9-0.1,1.5,0.3,1.8,1c0-0.6-0.2-1.3-1-1.7c-2.1-1.1-3.8,1.9-2.1,3.2
							C1644.6,1081.5,1645,1080.2,1646.3,1080z"/>
						<path class="st3" d="M1669.1,1088.7c-0.5-0.1-0.9-0.2-1.4-0.3c-1-0.2-2.1,0.4-2.3,1.4c-0.2,1,0.4,2.1,1.4,2.3
							c0.5,0.1,0.9,0.2,1.4,0.3c1,0.2,2.1-0.4,2.3-1.4C1670.7,1089.9,1670.2,1088.9,1669.1,1088.7z"/>
						<path class="st3" d="M1656.3,1093.2c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3-1-1.4-1.6-2.4-1.3c-1,0.3-1.6,1.4-1.3,2.4
							c0.1,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1h3.6C1656.4,1094,1656.4,1093.6,1656.3,1093.2z"/>
						<path class="st3" d="M1642,1088.9c-0.8-0.1-1.6,0.2-2,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.5-0.2,0.9-0.3,1.4
							c-0.1,0.5,0,1.1,0.3,1.5c0.3,0.4,0.6,0.7,1.1,0.8c1,0.2,2.1-0.4,2.3-1.4c0.1-0.5,0.2-0.9,0.3-1.4
							C1643.7,1090.2,1643,1089.1,1642,1088.9z"/>
						<path class="st3" d="M1663.4,1070c-0.4,0.2-0.7,0.5-1.1,0.7c-0.8,0.6-1.2,1.7-0.6,2.6c0.5,0.8,1.8,1.3,2.6,0.6
							c0.4-0.2,0.7-0.5,1.1-0.7c0.8-0.6,1.2-1.7,0.6-2.6C1665.5,1069.8,1664.3,1069.4,1663.4,1070z"/>
						<path class="st3" d="M1672.7,1081c-2.2-1.2-4,2.2-1.8,3.4C1673.1,1085.5,1674.9,1082.1,1672.7,1081z"/>
						<path class="st3" d="M1644.3,1072.6c0.1,0,0.2,0.1,0.3,0.1c0.2-0.5,0.5-0.9,1-1.1c0.4-0.2,1-0.2,1.4,0c0.4-0.8,0.2-1.9-0.8-2.4
							C1644,1068,1642.1,1071.4,1644.3,1072.6z"/>
						<path class="st3" d="M1634.4,1085.6c-1.4-0.8-2.6,0.3-2.8,1.5c-0.1,0.6,0.1,1.2,0.6,1.6c0.1,0.1,0.2,0.2,0.3,0.2
							C1634.7,1090.1,1636.5,1086.7,1634.4,1085.6z"/>
						<path class="st3" d="M1632.3,1074.6c0-0.1,0.1-0.1,0.1-0.2C1632.3,1074.4,1632.3,1074.5,1632.3,1074.6z"/>
						<path class="st3" d="M1632.6,1074.1c-0.1,0.1-0.1,0.1-0.1,0.2C1632.5,1074.2,1632.5,1074.1,1632.6,1074.1z"/>
						<path class="st3" d="M1632.6,1074.1C1632.6,1074.1,1632.6,1074.1,1632.6,1074.1c-0.1,0.1-0.1,0.2-0.1,0.3
							c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.4c-0.1,0.6-0.4,1-0.9,1.3c0.1,0.4,0.4,0.8,0.9,1c0.4,0.2,0.7,0.3,1.1,0.2
							c1.6-0.2,2.6-2.6,0.8-3.6c-0.4-0.2-0.8-0.3-1.1-0.2c-0.1,0-0.2,0-0.2,0.1C1632.7,1073.9,1632.6,1074,1632.6,1074.1z"/>
						<path class="st3" d="M1654.4,1061.8c-0.7-0.4-1-1.3-0.8-2.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
							c0.4,0.6,0.4,1.4,0.1,2.1c-0.3,0.7-1,1.1-1.7,1.2c0.1,0.1,0.2,0.2,0.3,0.2C1653,1063.7,1654.1,1062.8,1654.4,1061.8z"/>
						<path class="st3" d="M1692,1063.7c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.9,0.3-2.6-1.3-2.6c-1.9,0-2.1,2.6-0.6,3.2
							C1690.9,1063.8,1691.4,1063.6,1692,1063.7z"/>
						<path class="st3" d="M1692.3,1074.2c0.8,0,1.3-0.5,1.5-1.1c-0.5,0.4-1.2,0.6-2,0.2c-0.6-0.3-0.9-0.7-1-1.2
							C1690.5,1073,1691,1074.2,1692.3,1074.2z"/>
						<path class="st3" d="M1697,1067.4c-0.1,0.3-0.4,0.5-0.6,0.7c0.2,0.1,0.4,0.2,0.7,0.2c2.1,0,2.2-3.2,0.1-3.3
							c-0.2,0-0.4,0-0.6,0.1C1697.2,1065.6,1697.4,1066.5,1697,1067.4z"/>
						<path class="st3" d="M1703.7,1073.3c0-0.1,0-0.1,0-0.2C1703.7,1073.2,1703.7,1073.3,1703.7,1073.3z"/>
						<path class="st3" d="M1704.2,1070.4c-0.1,0-0.3,0-0.4,0c0.4,0.6,0.5,1.4,0.2,2c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
							c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0.1,0,0.3,0.1,0.4,0.1C1706.3,1073.8,1706.3,1070.5,1704.2,1070.4z"/>
						<path class="st3" d="M1703.8,1072.8c0,0.1-0.1,0.1-0.1,0.2C1703.7,1072.9,1703.8,1072.9,1703.8,1072.8z"/>
						<path class="st3" d="M1700.2,1061.6c-0.1-0.2-0.1-0.4-0.2-0.5c-0.2-0.7,0-1.4,0.4-1.9c0,0-0.1,0-0.1,0c-2.1,0-2.2,3.2-0.1,3.3
							c0.1,0,0.3,0,0.4,0C1700.5,1062.2,1700.3,1061.9,1700.2,1061.6z"/>
						<path class="st3" d="M1684.9,1067.7c-0.1,0.9,0.4,1.9,1.5,1.9c2.1,0,2.2-3.2,0.1-3.3c-0.4,0-0.7,0.1-0.9,0.2
							C1685.5,1067,1685.3,1067.4,1684.9,1067.7z"/>
						<path class="st3" d="M1705.7,1065c-0.8,0.2-1.4,1.1-1.2,2c0.3,0.8,1.1,1.4,2,1.2c0.4-0.1,0.8-0.2,1.2-0.3
							c0.8-0.2,1.4-1.1,1.2-2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0-0.1,0.1-0.2,0.1C1707.3,1066,1706.4,1065.7,1705.7,1065z"/>
						<path class="st3" d="M1698.6,1072.8c-0.6-0.6-1.7-0.7-2.3,0c-0.6,0.6-0.7,1.7,0,2.3c0.1,0.1,0.2,0.2,0.3,0.3
							c0.6,0.6,1.7,0.7,2.3,0c0.6-0.6,0.7-1.7,0-2.3C1698.9,1073.1,1698.7,1072.9,1698.6,1072.8z"/>
						<path class="st3" d="M1688.4,1076.7c-0.2-0.8-1.1-1.4-2-1.2c-0.8,0.3-1.4,1.1-1.2,2c0.1,0.4,0.2,0.8,0.3,1.2
							c0.2,0.8,1.1,1.4,2,1.2c0.8-0.3,1.4-1.1,1.2-2C1688.6,1077.5,1688.5,1077.1,1688.4,1076.7z"/>
						<path class="st3" d="M1697.5,1052.3c-0.7-0.4-1.8-0.3-2.3,0.5c1-0.1,1.8,0.6,2.2,1.5c0.2,0,0.4,0.1,0.6,0.2
							C1698.4,1053.7,1698.3,1052.7,1697.5,1052.3z"/>
						<path class="st3" d="M1694.7,1079.3c-0.7-0.1-1.3-0.1-2-0.2c-0.9-0.1-1.7,0.8-1.7,1.6c0,0.9,0.7,1.6,1.6,1.7
							c0.7,0.1,1.3,0.1,2,0.2c0.9,0.1,1.7-0.8,1.7-1.6C1696.3,1080.1,1695.6,1079.4,1694.7,1079.3z"/>
						<path class="st3" d="M1703.5,1083c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.7-1.6-1.7
							c-1,0-1.6,0.7-1.7,1.6c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.4,1.1,2.2,0.6c0.7-0.4,1.2-1.5,0.6-2.2
							C1703.7,1083.3,1703.6,1083.1,1703.5,1083z M1703.4,1082.6c0,0,0,0.1,0,0.2C1703.4,1082.7,1703.4,1082.7,1703.4,1082.6z
							M1703.5,1083c0-0.1-0.1-0.1-0.1-0.2C1703.5,1082.9,1703.5,1083,1703.5,1083z"/>
						<path class="st3" d="M1707.6,1077.4c-2.1,0-2.2,3.2-0.1,3.3C1709.7,1080.7,1709.7,1077.4,1707.6,1077.4z"/>
						<path class="st3" d="M1694.6,1084.2c-2.1,0-2.2,3.2-0.1,3.3C1696.6,1087.5,1696.7,1084.2,1694.6,1084.2z"/>
						<path class="st3" d="M1685.9,1083.4c-2.1,0-2.2,3.2-0.1,3.3C1687.9,1086.7,1688,1083.4,1685.9,1083.4z"/>
						<path class="st3" d="M1705.6,1060.4c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0,2.2-3.2,0.1-3.3c-1.5,0-2,1.7-1.3,2.7
							C1705.4,1060.2,1705.6,1060.3,1705.6,1060.4z"/>
						<path class="st3" d="M1681.7,1062.3c2.1,0,2.2-3.2,0.1-3.3C1679.7,1058.9,1679.6,1062.2,1681.7,1062.3z"/>
						<path class="st3" d="M1679.3,1076c-2.1,0-2.2,3.2-0.1,3.3C1681.4,1079.3,1681.4,1076.1,1679.3,1076z"/>
						<path class="st3" d="M1714.8,1071.5c-2.1,0-2.2,3.2-0.1,3.3C1716.8,1074.8,1716.9,1071.5,1714.8,1071.5z"/>
						<path class="st3" d="M1674.6,1067.4c-0.5,0-0.9,0.2-1.2,0.5c0.1,0.6,0,1.3-0.3,1.8c0.2,0.5,0.7,1,1.4,1
							C1676.7,1070.7,1676.7,1067.4,1674.6,1067.4z"/>
						<path class="st3" d="M1683.7,1048.8c-2.1,0-2.2,3.2-0.1,3.3C1685.7,1052.1,1685.8,1048.8,1683.7,1048.8z"/>
						<path class="st3" d="M1710.5,1036.8c0,0,0.1-0.1,0.1-0.1c0.9-0.6,2.1-0.2,2.7,0.6c0.1,0.1,0.1,0.2,0.1,0.3
							c1.5-0.7,1.6-2.2,0.9-3.3h-4.1C1709.9,1035,1710,1036,1710.5,1036.8z"/>
						<path class="st3" d="M1720.4,1047.6c1.1,0.6,1.2,1.7,0.8,2.5c0.2,0,0.4-0.1,0.6-0.2c2.8-1.4,0.6-5.7-2.2-4.3
							c-1,0.5-1.3,1.3-1.3,2.2C1718.9,1047.4,1719.6,1047.2,1720.4,1047.6z"/>
						<path class="st3" d="M1721.9,1034.6c-2.8,1.4-0.6,5.7,2.2,4.3C1726.8,1037.5,1724.7,1033.2,1721.9,1034.6z"/>
						<path class="st3" d="M1734.9,1036.9c-2.8,1.4-0.6,5.7,2.2,4.3C1739.8,1039.8,1737.7,1035.5,1734.9,1036.9z"/>
						<path class="st3" d="M1710,1048.1c0-1.2,1.1-2.4,2.4-2.1c0.2-1.7-1.5-3.5-3.4-2.5C1706.6,1044.7,1707.9,1048,1710,1048.1z"/>
						<path class="st3" d="M1727.7,1048.4c0.2,0.1,0.4,0.1,0.7,0.2c1.2,0.4,2.7-0.3,3.1-1.5c0.4-1.3-0.2-2.6-1.5-3.1
							c-0.2-0.1-0.4-0.1-0.7-0.2c-1.2-0.4-2.7,0.3-3.1,1.5C1725.8,1046.6,1726.4,1048,1727.7,1048.4z"/>
						<path class="st3" d="M1719.8,1057.1c-0.4-0.4-0.8-0.9-1.2-1.3c-0.7-0.8-1.9-1-2.9-0.6c0.4,0.1,0.8,0.2,1.1,0.2
							c1.1,0.2,1.7,1.3,1.5,2.3c-0.2,1-1.3,1.7-2.3,1.5c-0.3-0.1-0.7-0.1-1-0.2c0.4,0.4,0.8,0.8,1.1,1.3c0.9,0.9,2.5,1.1,3.4,0.2
							C1720.6,1059.6,1720.7,1058.1,1719.8,1057.1z"/>
						<path class="st3" d="M1731.8,1056.1c-0.7-1.2-2-1.6-3.2-1.1c-0.9,0.4-1.8,0.7-2.8,1.1c-1.2,0.5-1.7,2.2-1.1,3.2
							c0.7,1.2,2,1.6,3.2,1.1c0.9-0.4,1.8-0.7,2.8-1.1C1731.9,1058.8,1732.4,1057.1,1731.8,1056.1z"/>
						<path class="st3" d="M1743,1054c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
							c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.2-2.2-1.7-3.2-1.1c-1.2,0.7-1.6,2-1.1,3.2c0.5,1.3,1.5,2.4,2.7,3c1.1,0.6,2.6,0.5,3.3-0.7
							C1744.3,1056.3,1744.2,1054.6,1743,1054z M1742.2,1053.3c0,0,0.1,0.1,0.1,0.2C1742.3,1053.5,1742.2,1053.4,1742.2,1053.3z
							M1742.4,1053.6c0.1,0.1,0.1,0.1,0.2,0.2C1742.5,1053.7,1742.4,1053.7,1742.4,1053.6z"/>
						<path class="st3" d="M1744.1,1043.6c-2.8,1.4-0.6,5.7,2.2,4.3C1749,1046.5,1746.8,1042.2,1744.1,1043.6z"/>
						<path class="st3" d="M1731.7,1061.3c-2.8,1.4-0.6,5.7,2.2,4.3C1736.6,1064.3,1734.5,1060,1731.7,1061.3z"/>
						<path class="st3" d="M1719.8,1066.2c-2.8,1.4-0.6,5.7,2.2,4.3C1724.7,1069.1,1722.6,1064.8,1719.8,1066.2z M1719.9,1070.3
							c-2.2-1.1-0.5-4.6,1.8-3.5C1724,1068,1722.2,1071.5,1719.9,1070.3z"/>
						<path class="st3" d="M1699.6,1041.7c-0.1,0-0.2-0.1-0.3-0.1c-2.4-0.9-1.4-4.2,0.6-4.4c-0.6-0.3-1.3-0.3-2,0
							C1695.2,1038.5,1697.1,1042.5,1699.6,1041.7z"/>
						<path class="st3" d="M1708.4,1061.2c-0.6-0.4-1.4-0.4-2.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.2,0.9-0.2,1.9-1,2.5
							c0.1,0.5,0.3,0.9,0.7,1.3c0.6,0.7,1.6,1,2.7,0.4c0.1,0,0.1-0.1,0.2-0.1c0.6-0.4,0.9-0.9,1-1.4c-0.1,0-0.3-0.1-0.4-0.2
							C1708.1,1063.1,1708,1062,1708.4,1061.2z"/>
						<path class="st3" d="M1751.5,1035.4c0.6-0.3,0.9-0.7,1.1-1.1h-4.3C1748.9,1035.3,1750.1,1036.1,1751.5,1035.4z"/>
						<path class="st3" d="M1697.4,1054.3c-0.4-0.9-1.2-1.5-2.2-1.5c-0.3,0-0.6,0.1-1,0.3c-2.6,1.3-0.9,5.1,1.5,4.5
							C1694.5,1056.4,1695.7,1054,1697.4,1054.3z"/>
						<path class="st3" d="M1649.8,1057.6c0.5-1.1-0.2-2.8-1.8-2.5c-2,0.3-1.8,3-0.3,3.5C1648.2,1057.9,1649,1057.6,1649.8,1057.6z"
							/>
						<path class="st3" d="M1651,1065.6c-2.3,0.3-1.8,3.8,0.5,3.5C1653.7,1068.8,1653.2,1065.3,1651,1065.6z"/>
						<path class="st3" d="M1654.4,1061.8c0.3,0.2,0.7,0.3,1.1,0.2c2.3-0.3,1.8-3.8-0.5-3.5c-0.8,0.1-1.2,0.6-1.4,1.2
							C1653.5,1060.5,1653.8,1061.3,1654.4,1061.8z"/>
						<path class="st3" d="M1664.1,1066.6c2.3-0.3,1.8-3.8-0.5-3.5c-0.6,0.1-1,0.4-1.2,0.8c0,0.1-0.1,0.2-0.2,0.3
							C1661.9,1065.3,1662.6,1066.8,1664.1,1066.6z"/>
						<path class="st3" d="M1656.6,1054.7c0.3,0.4,0.8,0.6,1.5,0.5c1.7-0.2,1.8-2.3,0.9-3.1
							C1659.1,1053.4,1657.9,1054.8,1656.6,1054.7z"/>
						<path class="st3" d="M1644.3,1065.2c0.1,0,0.1,0,0.2,0c2.3-0.3,1.8-3.8-0.5-3.5c-0.1,0-0.3,0.1-0.4,0.1
							c0.2,0.1,0.4,0.2,0.5,0.3C1645.2,1063.1,1645,1064.4,1644.3,1065.2z"/>
						<path class="st3" d="M1664.2,1057.1c-0.8,0.4-1.3,1.5-0.9,2.3c0.4,0.9,1.4,1.3,2.3,0.9c0.4-0.2,0.8-0.4,1.2-0.5
							c0.8-0.4,1.3-1.5,0.9-2.3c-0.2-0.4-0.5-0.7-0.8-0.8c-0.5,0.5-1.2,0.8-1.9,0.7C1664.7,1057.3,1664.5,1057.2,1664.2,1057.1z"/>
						<path class="st3" d="M1655.9,1069.4c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.6,1.9,0.4,2.5-0.4c0.5-0.8,0.4-1.9-0.4-2.5
							c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.6-1.9-0.4-2.5,0.4C1655,1067.8,1655.1,1068.9,1655.9,1069.4z"/>
						<path class="st3" d="M1645.5,1071.6c-0.5,0.2-0.8,0.6-1,1.1c-0.1,0.4-0.1,0.8,0,1.3c0.2,0.4,0.4,0.8,0.5,1.2
							c0.4,0.8,1.5,1.3,2.3,0.9c0.9-0.4,1.3-1.4,0.9-2.3c-0.2-0.4-0.4-0.8-0.5-1.2c-0.2-0.4-0.5-0.7-0.9-0.9
							C1646.5,1071.4,1646,1071.4,1645.5,1071.6z"/>
						<path class="st3" d="M1650.9,1048.9c0.2,0.2,0.4,0.3,0.6,0.4c0.9,0.3,2,0,2.3-1c0.1-0.4,0.3-0.8,0.4-1.1c0.3-0.9,0-2-1-2.3
							c-0.9-0.3-2,0-2.3,1c-0.1,0.4-0.3,0.8-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3C1650.9,1047.8,1651,1048.4,1650.9,1048.9z"/>
						<path class="st3" d="M1655.5,1077.7c0.1,0,0.2,0,0.4-0.1c0.8-0.3,1.3-1.2,1.1-1.9c-0.2-1-1-1.6-2-1.5c-0.1,1.6-2.1,3-3.7,1.7
							c0,0.1,0,0.3,0,0.4c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.2,0.8,0.3,1.2,0.3C1654,1077.8,1654.7,1077.8,1655.5,1077.7z"/>
						<path class="st3" d="M1665,1079.4C1665,1079.4,1665.1,1079.3,1665,1079.4c0.7-0.7,0.9-1.8,0.3-2.5c0,0-0.2-0.1-0.2-0.2
							c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1.1-1.7-2-1.5c-1,0.2-1.5,1-1.5,2c0,1,0.5,2,1.2,2.7
							c0.2,0.2,0.5,0.4,0.8,0.5C1663.8,1079.8,1664.5,1079.8,1665,1079.4z M1665,1076.6c0-0.1-0.1-0.1-0.1-0.2
							C1665,1076.5,1665,1076.6,1665,1076.6z M1664.9,1076.4c0,0,0-0.1,0-0.2C1664.9,1076.3,1664.9,1076.3,1664.9,1076.4z"/>
						<path class="st3" d="M1668.9,1073.4c2.3-0.3,1.8-3.8-0.5-3.5C1666.1,1070.2,1666.6,1073.7,1668.9,1073.4z"/>
						<path class="st3" d="M1655.7,1079.4c-2.3,0.3-1.8,3.8,0.5,3.5C1658.5,1082.6,1658,1079.1,1655.7,1079.4z"/>
						<path class="st3" d="M1648.1,1081c-0.3-0.6-0.9-1.1-1.8-1c-1.3,0.2-1.7,1.5-1.3,2.5c0.3,0.7,0.9,1.2,1.8,1
							C1648.2,1083.3,1648.5,1082,1648.1,1081z"/>
						<path class="st3" d="M1663.5,1052.2c0.4-0.7,1.3-1.2,2.2-1.1c0,0,0.1,0,0.1,0c0.3-1.1-0.4-2.5-1.9-2.3
							C1662,1049.1,1662.1,1051.6,1663.5,1052.2z"/>
						<path class="st3" d="M1638.2,1058.3c2.3-0.3,1.8-3.8-0.5-3.5C1635.5,1055.1,1636,1058.6,1638.2,1058.3z"/>
						<path class="st3" d="M1638.5,1076.9c0.5-0.1,0.9-0.3,1.1-0.6c-0.4-0.1-0.7-0.2-1.1-0.5c-0.8-0.7-0.9-1.6-0.6-2.3
							C1635.8,1073.7,1636.3,1077.2,1638.5,1076.9z"/>
						<path class="st3" d="M1676,1062.5c0,0.2-0.1,0.4-0.1,0.5c-0.3,0.9-1.3,1.5-2.3,1.4c0.2,0.8,0.8,1.5,1.9,1.4
							C1677.4,1065.6,1677.3,1063.2,1676,1062.5z"/>
						<path class="st3" d="M1632,1068.5c2.3-0.3,1.8-3.8-0.5-3.5C1629.3,1065.3,1629.8,1068.8,1632,1068.5z"/>
						<path class="st3" d="M1636.8,1044.3c-0.7,1.1,0,3,1.7,2.8c1.8-0.3,1.8-2.5,0.7-3.2C1638.5,1044.4,1637.6,1044.7,1636.8,1044.3z
							"/>
						<path class="st3" d="M1700.3,1046.6c2.2,1.1,4-2.3,1.8-3.5C1699.9,1042,1698.1,1045.4,1700.3,1046.6z"/>
						<path class="st3" d="M1695.8,1057.6c0.1,0.1,0.3,0.3,0.5,0.4c2.2,1.1,4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.2
							C1695.7,1054,1694.5,1056.4,1695.8,1057.6z"/>
						<path class="st3" d="M1704.5,1054.1c2.2,1.1,4-2.3,1.8-3.5C1704.1,1049.5,1702.3,1053,1704.5,1054.1z"/>
						<path class="st3" d="M1711,1060.2c-1.1-0.6-2.1,0-2.6,0.9c-0.4,0.9-0.3,2,0.8,2.6c0.1,0.1,0.3,0.1,0.4,0.2
							C1711.7,1064.4,1713.1,1061.3,1711,1060.2z"/>
						<path class="st3" d="M1710,1048.1c0,0.6,0.3,1.2,1,1.6c2.2,1.1,4-2.3,1.8-3.5c-0.1-0.1-0.3-0.1-0.4-0.2
							C1711.1,1045.6,1710,1046.9,1710,1048.1z"/>
						<path class="st3" d="M1692.5,1050c0.5,0.2,0.9,0.3,1.3,0.2c-0.2-0.4-0.3-0.9-0.3-1.3c0.1-0.8,0.6-1.5,1.4-1.8
							c-0.2-0.2-0.4-0.3-0.6-0.5C1692.1,1045.4,1690.3,1048.8,1692.5,1050z"/>
						<path class="st3" d="M1718.4,1057.8c0.2-1-0.4-2.1-1.5-2.3c-0.4-0.1-0.8-0.2-1.1-0.2c-0.1,0-0.2,0-0.3-0.1
							c-1-0.2-2.1,0.5-2.3,1.5c-0.2,1,0.4,2.1,1.5,2.3c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2
							C1717.1,1059.5,1718.2,1058.9,1718.4,1057.8z"/>
						<path class="st3" d="M1703.6,1059.5c-0.4-0.8-1.4-1.2-2.3-0.9c-0.3,0.1-0.6,0.3-0.8,0.6c-0.5,0.5-0.7,1.2-0.4,1.9
							c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.3,0.7,0.5,1.1,0.6C1701.1,1061.6,1702.1,1059.7,1703.6,1059.5z"/>
						<path class="st3" d="M1688.3,1061.6c1,0.2,2.1-0.4,2.3-1.5c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.4,0-0.7-0.1-1.1c-0.3,0-0.6,0-1-0.2
							c-0.6-0.2-0.9-0.6-1.2-1c-0.7,0.1-1.4,0.7-1.5,1.5c-0.1,0.5-0.2,0.9-0.3,1.4C1686.5,1060.3,1687.2,1061.4,1688.3,1061.6z"/>
						<path class="st3" d="M1710.6,1036.7c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.5-1,0.7c-0.8,0.6-1.2,1.8-0.6,2.7
							c0.6,0.8,1.8,1.2,2.7,0.6c0.4-0.3,0.7-0.5,1.1-0.8c0.7-0.5,1.1-1.6,0.7-2.4c0-0.1-0.1-0.2-0.1-0.3
							C1712.8,1036.5,1711.5,1036.1,1710.6,1036.7z"/>
						<path class="st3" d="M1692.5,1063.9c1.7,0.7,1.7,2.6,0.8,3.7c0.4,0.2,0.7,0.5,1.1,0.7c0.6,0.4,1.4,0.3,2-0.1
							c0.3-0.2,0.5-0.4,0.6-0.7c0.4-0.9,0.2-1.7-0.5-2.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.4-1.4-0.8-2-1.3c-0.5-0.3-1.2-0.3-1.7,0
							c-0.2,0.1-0.3,0.2-0.5,0.3C1692.1,1063.8,1692.3,1063.8,1692.5,1063.9z"/>
						<path class="st3" d="M1703.7,1073.1c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.4c0.4-0.6,0.3-1.4-0.2-2
							c-0.2-0.3-0.4-0.5-0.7-0.6c-1-0.5-2-0.1-2.6,0.8c-0.6,0.9-0.8,2.1-0.7,3.2c0.2,1,0.9,2,2.1,1.8c1-0.1,2-1,1.8-2.1
							c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3C1703.7,1073.3,1703.7,1073.2,1703.7,1073.1z M1703.7,1073.3c0-0.1,0-0.2,0-0.2
							C1703.7,1073.2,1703.7,1073.3,1703.7,1073.3z M1703.7,1073c0,0,0-0.1,0.1-0.2C1703.8,1072.9,1703.7,1072.9,1703.7,1073z"/>
						<path class="st3" d="M1711,1069.4c-2.2-1.1-4,2.3-1.8,3.5C1711.4,1074,1713.2,1070.6,1711,1069.4z"/>
						<path class="st3" d="M1691.7,1073.3c0.8,0.4,1.5,0.2,2-0.2c1-0.8,1.2-2.5-0.3-3.3c-1.7-0.9-3.1,0.9-2.8,2.3
							C1690.9,1072.6,1691.2,1073,1691.7,1073.3z"/>
						<path class="st3" d="M1683.3,1065.3c0.9,0.3,1.6,1.5,1.4,2.5c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.6-0.7,0.7-1.1
							c0.2-0.8,0-1.6-1-2.1c-1.1-0.5-2,0-2.5,0.8C1682.5,1065.1,1682.9,1065.1,1683.3,1065.3z"/>
						<path class="st3" d="M1718.6,1051.1c1.1,0.6,2.2,0,2.6-1c0.4-0.9,0.3-2-0.8-2.5c-0.8-0.4-1.5-0.2-2,0.2
							C1717.4,1048.6,1717.2,1050.3,1718.6,1051.1z"/>
						<path class="st3" d="M1693.1,1039.7c1.1-0.7,1.5-2.6,0-3.4c-0.7-0.4-1.4-0.3-1.9,0.1c0.3,0,0.5,0,0.8,0.2
							C1693.5,1037.1,1693.7,1038.6,1693.1,1039.7z"/>
						<path class="st3" d="M1678.8,1055.3c0-0.1-0.1-0.2-0.1-0.3c0,0.4,0.2,0.8,0.5,1.1c-0.1-0.1-0.1-0.2-0.2-0.3
							C1678.9,1055.7,1678.8,1055.5,1678.8,1055.3z"/>
						<path class="st3" d="M1721.7,1066.9c-2.2-1.1-4,2.3-1.8,3.5C1722.2,1071.5,1724,1068,1721.7,1066.9z"/>
						<path class="st3" d="M1679.2,1044.9c2.2,1.1,4-2.3,1.8-3.5C1678.8,1040.3,1677,1043.8,1679.2,1044.9z"/>
						<path class="st3" d="M1677.4,1038.4c2.7,1.1,4.4-3,1.8-4.2h-1.9C1675.8,1035,1675.4,1037.6,1677.4,1038.4z"/>
						<path class="st3" d="M1675.7,1047.9c-2.7-1.1-4.4,3.2-1.7,4.3C1676.7,1053.2,1678.4,1049,1675.7,1047.9z"/>
						<path class="st3" d="M1683.2,1046.7c2.7,1.1,4.4-3.2,1.7-4.3C1682.1,1041.4,1680.4,1045.7,1683.2,1046.7z"/>
						<path class="st3" d="M1689.7,1057.4c0.4,0.1,0.7,0.2,1,0.2c2.1-0.1,3.1-3.5,0.7-4.4c-2.2-0.9-3.7,1.6-2.9,3.2
							C1688.8,1056.8,1689.2,1057.2,1689.7,1057.4z"/>
						<path class="st3" d="M1690.3,1040.8c1.3,0.5,2.3-0.2,2.8-1.1c0.5-1.1,0.4-2.6-1.1-3.1c-0.3-0.1-0.6-0.2-0.8-0.2
							C1688.9,1036.3,1687.9,1039.8,1690.3,1040.8z"/>
						<path class="st3" d="M1668.7,1043.3c2.7,1.1,4.4-3.2,1.7-4.3C1667.7,1037.9,1666,1042.2,1668.7,1043.3z"/>
						<path class="st3" d="M1693.5,1048.8c0,0.5,0.1,0.9,0.3,1.3c0.3,0.6,1,1.1,1.7,1.2c0.6,0.1,1.1,0.1,1.7,0.2
							c1.2,0.1,2.4-0.8,2.5-2c0.1-1.2-0.8-2.4-2-2.5c-0.6-0.1-1.1-0.1-1.7-0.2c-0.4,0-0.8,0-1.2,0.2
							C1694.2,1047.3,1693.6,1048,1693.5,1048.8z"/>
						<path class="st3" d="M1683.2,1054.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1.1-1.9-1.7-3-1.2c-1,0.5-1.6,1.6-1.3,2.8
							c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.2,0.3c0.6,0.9,1.8,1.4,2.9,0.9
							C1683.1,1056.6,1683.7,1055.3,1683.2,1054.1z"/>
						<path class="st3" d="M1663.5,1052.2c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.9,0.4,1.8,1.2,2.3
							c0.2,0.1,0.5,0.2,0.8,0.2c0.7,0,1.4-0.2,1.9-0.7c0.3-0.3,0.5-0.8,0.6-1.3c0.1-0.6,0.1-1.1,0.2-1.7c0.1-1.2-0.7-2.4-1.9-2.5
							c0,0-0.1,0-0.1,0C1664.8,1051,1663.9,1051.4,1663.5,1052.2z"/>
						<path class="st3" d="M1675.9,1063.1c0.1-0.2,0.1-0.4,0.1-0.5c0.1-1-0.4-1.9-1.4-2.4c-0.8-0.4-1.7-0.8-2.5-1.2
							c-1.1-0.5-2.6,0.2-3,1.3c-0.4,1.2,0.1,2.4,1.3,3c0.8,0.4,1.7,0.8,2.5,1.2c0.2,0.1,0.4,0.2,0.7,0.2
							C1674.5,1064.6,1675.6,1063.9,1675.9,1063.1z"/>
						<path class="st3" d="M1684.5,1069.7c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1
							c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.2-1-0.5-2.2-1.4-2.5c-0.4-0.1-0.8-0.2-1.2-0.1c-0.8,0.1-1.4,0.6-1.8,1.4
							c-0.6,1.1-0.7,2.6-0.4,3.8c0.3,1.2,1.3,2.2,2.6,1.9C1683.7,1072.1,1684.8,1071,1684.5,1069.7z M1684.4,1068.9c0,0,0-0.1,0-0.2
							C1684.4,1068.7,1684.4,1068.8,1684.4,1068.9z M1684.3,1069c0,0.1,0,0.2,0,0.2C1684.3,1069.2,1684.3,1069.1,1684.3,1069z"/>
						<path class="st3" d="M1693.2,1067.5c0.9-1.1,0.9-3-0.8-3.7c-0.2-0.1-0.3-0.1-0.5-0.1c-0.5-0.1-1,0-1.4,0.3
							c-1.4,0.9-1.8,3.3,0.2,4.1C1691.8,1068.5,1692.7,1068.2,1693.2,1067.5z"/>
						<path class="st3" d="M1673.4,1067.9c-0.1-0.6-0.5-1.2-1.3-1.5c-2.7-1.1-4.4,3.2-1.7,4.3c1.2,0.5,2.2-0.1,2.7-0.9
							C1673.4,1069.1,1673.6,1068.5,1673.4,1067.9z"/>
						<path class="st3" d="M1662.2,1064.2c0.1-0.1,0.1-0.2,0.2-0.3c0.4-1.1,0.1-2.3-1.2-2.9c-2.7-1.1-4.4,3.2-1.7,4.3
							C1660.7,1065.7,1661.7,1065.1,1662.2,1064.2z"/>
						<path class="st3" d="M1699.3,1041.6c0.1,0,0.2,0.1,0.3,0.1c2.5,0.6,4-3.3,1.4-4.3c-0.4-0.1-0.7-0.2-1.1-0.2
							C1697.9,1037.4,1696.9,1040.6,1699.3,1041.6z"/>
						<path class="st3" d="M1654.5,1052.5c0.1-1.4,1.6-2.7,3.1-2.1c0.1-0.9-0.3-1.8-1.4-2.2
							C1653.4,1047.1,1651.7,1051.4,1654.5,1052.5z"/>
						<path class="st3" d="M1706,1061.3c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.7-0.4
							c-0.4-0.1-0.7-0.2-1-0.2c-1.6,0.1-2.5,2.1-1.8,3.4c0.2,0.4,0.6,0.8,1.1,1c0.8,0.3,1.5,0.2,2-0.2
							C1705.8,1063.2,1706.2,1062.2,1706,1061.3z"/>
						<path class="st3" d="M1653.3,1037.3c0.2,0.6,0.1,1.2-0.1,1.7c2.4,0.4,3.7-3.4,1.1-4.4c-1.3-0.5-2.4,0.2-2.8,1.2
							C1652.3,1035.9,1653,1036.6,1653.3,1037.3z"/>
						<path class="st3" d="M1590.8,1077.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-1.2,0.1-2.1,1.7-1,2.7
							c0.7,0.7,1.6,0.5,2.2-0.1C1590.8,1078.4,1590.7,1077.9,1590.8,1077.4z"/>
						<path class="st3" d="M1585.4,1084.7c-1.5-1.4-3.6,1-2.1,2.3C1584.7,1088.4,1586.9,1086.1,1585.4,1084.7z"/>
						<path class="st3" d="M1592.4,1083.3c-1.4-0.9-3.2,1-2.1,2.3C1590.4,1084.5,1591.3,1083.4,1592.4,1083.3z"/>
						<path class="st3" d="M1594.3,1092c-1.5-1.4-3.6,1-2.1,2.3c0,0,0.1,0,0.1,0.1h1.9C1594.8,1093.8,1595.1,1092.7,1594.3,1092z"/>
						<path class="st3" d="M1596.6,1083.7c1.5,1.4,3.6-1,2.1-2.3c-0.4-0.4-0.9-0.5-1.3-0.4c-0.3,0.3-0.6,0.5-0.9,0.6
							C1596,1082.2,1595.9,1083,1596.6,1083.7z"/>
						<path class="st3" d="M1584.3,1079.7c0.4-0.6,0.5-1.4-0.2-2.1c-0.1-0.1-0.3-0.2-0.4-0.3
							C1584.3,1077.9,1584.6,1078.9,1584.3,1079.7z"/>
						<path class="st3" d="M1599.9,1089.5c-0.4-0.2-0.7-0.4-1.1-0.5c-0.7-0.4-1.8-0.1-2.1,0.7c-0.4,0.8-0.1,1.7,0.7,2.1
							c0.4,0.2,0.7,0.4,1.1,0.5c0.7,0.4,1.8,0.1,2.1-0.7C1600.9,1090.9,1600.7,1089.9,1599.9,1089.5z"/>
						<path class="st3" d="M1587.1,1088.6c-0.9,0.1-1.5,0.8-1.5,1.7c0,0.2,0,0.3,0,0.5c0,0.8,0.8,1.6,1.7,1.5
							c0.9-0.1,1.5-0.8,1.5-1.7c0-0.2,0-0.3,0-0.5C1588.7,1089.3,1587.9,1088.5,1587.1,1088.6z"/>
						<path class="st3" d="M1578.2,1084.3c-0.8-0.4-1.7-0.1-2.1,0.7c-0.2,0.4-0.4,0.7-0.5,1.1c-0.4,0.7-0.1,1.8,0.7,2.1
							c0.8,0.4,1.7,0.1,2.1-0.7c0.2-0.4,0.4-0.7,0.5-1.1C1579.2,1085.7,1578.9,1084.7,1578.2,1084.3z"/>
						<path class="st3" d="M1599,1073.5c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.1-1,2c0.3,0.8,1.2,1.4,2,1c0.3-0.1,0.7-0.3,1-0.4
							c0.8-0.3,1.3-1.1,1-2C1600.7,1073.7,1599.8,1073.2,1599,1073.5z"/>
						<path class="st3" d="M1581.7,1092.3c-0.4-0.5-0.9-0.9-1.3-1.4c-0.6-0.6-1.7-0.5-2.2,0.1c-0.6,0.7-0.5,1.6,0.1,2.2
							c0.4,0.4,0.7,0.8,1.1,1.1h2.6C1582.3,1093.7,1582.2,1092.9,1581.7,1092.3z"/>
						<path class="st3" d="M1570.6,1091.9c1.5,1.4,3.6-1,2.1-2.3C1571.3,1088.2,1569.1,1090.6,1570.6,1091.9z"/>
						<path class="st3" d="M1602.6,1083.8c-0.6,0.3-1.1,1.1-0.9,1.8c0.1-0.5,0.3-1,0.6-1.4C1602.4,1084.1,1602.5,1084,1602.6,1083.8z
							"/>
						<path class="st3" d="M1585,1070.8c0.1-0.5,0.2-1.1,0.3-1.6c-1.2-0.9-2.8,0.5-2.4,1.8c0.4,0.2,0.7,0.6,0.9,1
							c0.5,0.2,0.9,0.1,1.3-0.1C1584.9,1071.6,1584.9,1071.2,1585,1070.8z"/>
						<path class="st3" d="M1570.6,1082.4c1.4,1.3,3.4-0.7,2.3-2.1c-0.3,0.5-0.8,0.9-1.5,1c-0.5,0-0.9-0.1-1.3-0.3
							C1570,1081.5,1570.1,1082,1570.6,1082.4z"/>
						<path class="st3" d="M1574,1070.5c0,0.9-0.5,1.7-1.4,2c-0.1,0.1-0.2,0.1-0.4,0.1c0.1,0.2,0.2,0.4,0.4,0.6
							c1.5,1.4,3.6-1,2.1-2.3C1574.5,1070.6,1574.2,1070.5,1574,1070.5z"/>
						<path class="st3" d="M1591.1,1065.5c1.5,1.4,3.6-1,2.1-2.3C1591.7,1061.8,1589.6,1064.1,1591.1,1065.5z"/>
						<path class="st3" d="M1452.9,1079.3c-3-0.2-3.3,4.4-0.3,4.7C1455.5,1084.2,1455.9,1079.5,1452.9,1079.3z"/>
						<path class="st3" d="M1453.7,1093.8c-0.8-0.1-1.3,0.2-1.7,0.6h3.2C1454.8,1094,1454.4,1093.8,1453.7,1093.8z"/>
						<path class="st3" d="M1461,1085.7c-0.2,0-0.4,0-0.6,0c0,0.9-0.9,1.8-1.8,1.8c-0.3,1.3,0.4,2.7,2,2.9
							C1463.7,1090.5,1464,1085.9,1461,1085.7z"/>
						<path class="st3" d="M1470.7,1094c-0.6,0-1.1,0.1-1.4,0.4h2.2c0.1,0,0.1-0.1,0.2-0.1C1471.4,1094.1,1471.1,1094,1470.7,1094z"
							/>
						<path class="st3" d="M1466.2,1079.2c0.8,0.4,1.1,1.6,0.6,2.4c-0.2,0.3-0.3,0.5-0.5,0.8c2.5-0.3,2.6-4.4-0.2-4.6
							c-1.5-0.1-2.4,1.1-2.4,2.3c0-0.1,0.1-0.1,0.1-0.2C1464.3,1078.9,1465.4,1078.7,1466.2,1079.2z"/>
						<path class="st3" d="M1445.9,1086.7c-3-0.2-3.3,4.4-0.3,4.7C1448.5,1091.6,1448.9,1086.9,1445.9,1086.7z"/>
						<path class="st3" d="M1477.2,1086.9c-0.1,0.9-0.9,1.5-1.7,1.6c-0.9,0-1.7-0.9-1.7-1.8c0-0.1,0-0.3,0-0.4
							c-0.2,0-0.4,0.1-0.5,0.1c-1.2,0.3-2.1,1.5-1.8,2.8c0.3,1.2,1.5,2.1,2.8,1.8c0.6-0.1,1.1-0.2,1.7-0.4c1.2-0.3,2.1-1.5,1.8-2.8
							C1477.6,1087.5,1477.5,1087.2,1477.2,1086.9z"/>
						<path class="st3" d="M1459,1072.9c1,0.6,2.6,0.5,3.2-0.6c0.3-0.5,0.6-0.9,0.8-1.4c0.6-1,0.5-2.6-0.6-3.2
							c-1-0.6-2.6-0.5-3.2,0.6c-0.3,0.5-0.6,0.9-0.8,1.4C1457.8,1070.7,1457.9,1072.2,1459,1072.9z"/>
						<path class="st3" d="M1474.1,1080.1c0.2,0.1,0.5,0.2,0.7,0.2c3,0.2,3.3-4.4,0.3-4.7c-2.4-0.2-3.1,2.7-1.8,4
							C1473.6,1079.7,1473.8,1079.9,1474.1,1080.1C1474,1080.1,1474,1080.1,1474.1,1080.1z"/>
						<path class="st3" d="M1439.7,1076c-3-0.2-3.3,4.4-0.3,4.7C1442.4,1080.9,1442.7,1076.2,1439.7,1076z"/>
						<path class="st3" d="M1428.8,1087.4c-3-0.2-3.3,4.4-0.3,4.7C1431.5,1092.3,1431.8,1087.6,1428.8,1087.4z"/>
						<path class="st3" d="M1443.1,1061.6c-3-0.2-3.3,4.4-0.3,4.7C1445.8,1066.5,1446.1,1061.9,1443.1,1061.6z"/>
						<path class="st3" d="M1539.6,1076.7c0.1,0,0.1,0.1,0.2,0.2c0-0.4-0.2-0.8-0.6-1.1c-1.7-1.6-4.1,0.7-3,2.4
							C1536.5,1076.8,1538,1075.6,1539.6,1076.7z"/>
						<path class="st3" d="M1530.6,1086.7c0.4-0.6,1-0.9,1.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-1.8-1.7-4.5,1-2.7,2.8
							c0.3,0.3,0.7,0.5,1.1,0.5C1530.1,1088.1,1530.1,1087.4,1530.6,1086.7z"/>
						<path class="st3" d="M1540.8,1084.4c0-0.2,0-0.3,0-0.5c-1.8-1.3-4.2,1.3-2.5,3c1.2,1.1,2.7,0.4,3.2-0.8
							c-0.3-0.3-0.5-0.7-0.5-1.1C1540.8,1084.8,1540.8,1084.6,1540.8,1084.4z"/>
						<path class="st3" d="M1540.5,1094.4h1.8C1541.7,1094,1541,1094.1,1540.5,1094.4z"/>
						<path class="st3" d="M1545.8,1084.6c1.8,1.7,4.5-1,2.7-2.8C1546.7,1080,1544,1082.8,1545.8,1084.6z"/>
						<path class="st3" d="M1530.7,1076.7c-1.2-1.1-2.8-0.3-3.2,1c0.6-0.3,1.3-0.4,1.9-0.2c0.8,0.3,1.3,1,1.4,1.8
							c0.2-0.2,0.3-0.5,0.4-0.8C1530.7,1078,1530.6,1077.3,1530.7,1076.7z"/>
						<path class="st3" d="M1549.7,1091.9c-0.4-0.2-0.9-0.5-1.3-0.7c-0.9-0.5-2.2-0.2-2.6,0.8c-0.4,0.8-0.2,1.8,0.5,2.4h4.3
							C1550.9,1093.5,1550.6,1092.4,1549.7,1091.9z"/>
						<path class="st3" d="M1535.9,1092.8c0-0.2,0-0.4,0-0.6c0-0.2-0.1-0.5-0.1-0.7c-0.7,0.5-1.9,0.6-2.5,0c-0.2-0.2-0.5-0.4-0.7-0.6
							c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0.1,0.5c0.4,0.3,0.6,0.7,0.7,1h2.2
							C1535.6,1094,1535.9,1093.4,1535.9,1092.8z"/>
						<path class="st3" d="M1523.2,1084.7C1523.1,1084.7,1523.1,1084.6,1523.2,1084.7c-1-0.5-2.2-0.2-2.7,0.7
							c-0.1,0.2-0.2,0.3-0.3,0.5c0.7-0.7,1.7-0.9,2.7-0.2c0.7,0.5,0.9,1.1,0.9,1.7c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.3,0.2-0.5
							C1523.4,1086.4,1523,1085.5,1523.2,1084.7z"/>
						<path class="st3" d="M1549.4,1073.7c0.8,0.6,1,1.4,0.9,2.1c0.9-0.4,1.6-1.4,1.3-2.4c-0.3-1-1.4-1.7-2.4-1.4
							c-0.4,0.1-0.8,0.3-1.3,0.4c-0.7,0.2-1.3,0.9-1.4,1.7C1547.2,1073.3,1548.3,1072.9,1549.4,1073.7z"/>
						<path class="st3" d="M1522.8,1092.9c-0.4,0.5-0.6,1-0.5,1.5h4.6c-0.5-0.5-0.9-1-1.4-1.5
							C1524.9,1092.1,1523.5,1092.1,1522.8,1092.9z"/>
						<path class="st3" d="M1513.6,1093.8c1.8,1.7,4.5-1,2.7-2.8C1514.5,1089.2,1511.8,1092,1513.6,1093.8z"/>
						<path class="st3" d="M1553,1085.1c0.1,0,0.1,0.1,0.2,0.1c1.3,0.9,1.1,2.5,0.2,3.4c1.7,0.5,3.5-1.7,1.9-3.3
							C1554.6,1084.6,1553.7,1084.7,1553,1085.1z"/>
						<path class="st3" d="M1529.8,1069.3c1.8,1.7,4.5-1,2.7-2.8C1530.8,1064.8,1528,1067.6,1529.8,1069.3z"/>
						<path class="st3" d="M1515.1,1078.7c0.2,0.1,0.3,0.3,0.5,0.5c0.3,0.4,0.4,1,0.3,1.5c0.3,0.4,0.5,1,0.3,1.5c0.9-0.6,1.4-2,0.4-3
							C1516.1,1078.8,1515.6,1078.7,1515.1,1078.7z"/>
						<path class="st3" d="M1520,1069.9c0.2-0.6,0.1-1.3-0.5-1.9c-1.8-1.7-4.5,1-2.7,2.8c0.9,0.8,1.9,0.6,2.6,0
							C1519.5,1070.4,1519.7,1070.1,1520,1069.9z"/>
						<path class="st3" d="M1540.7,1058.7c1.1,1.2,0.3,2.8-0.9,3.3c1.8,1.8,4.5-1,2.7-2.8C1541.9,1058.7,1541.2,1058.6,1540.7,1058.7
							z"/>
						<path class="st3" d="M1481,1073.6c-1.3,0.1-2.1-1.2-2-2.3c-1.1,0.4-1.8,1.8-0.8,2.8C1479.2,1075.2,1480.5,1074.6,1481,1073.6z"
							/>
						<path class="st3" d="M1474.1,1080.1c-0.2-0.3-0.5-0.4-0.7-0.5c-1.5-0.5-3.1,1.5-1.8,2.8
							C1473.1,1084.1,1475.5,1081.8,1474.1,1080.1z"/>
						<path class="st3" d="M1482,1079.3c-1.5-1.6-4,0.8-2.5,2.4C1481.1,1083.3,1483.6,1080.9,1482,1079.3z"/>
						<path class="st3" d="M1480.7,1091c1.5,1.6,4-0.8,2.5-2.4C1481.7,1087.1,1479.2,1089.4,1480.7,1091z"/>
						<path class="st3" d="M1488.8,1077.4c-1.5-1.6-4,0.8-2.5,2.4C1487.8,1081.4,1490.3,1079,1488.8,1077.4z"/>
						<path class="st3" d="M1473.2,1072.4c-1.5-1.6-4,0.8-2.5,2.4C1472.2,1076.3,1474.7,1074,1473.2,1072.4z"/>
						<path class="st3" d="M1490.2,1088.6c0.3-0.8,0.1-1.7-0.7-2.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.5-1.9-0.2-2.4,0.6
							c-0.4,0.8-0.2,1.9,0.6,2.4c0.4,0.2,0.7,0.4,1.1,0.6c0.5,0.3,1,0.3,1.5,0.1C1489.4,1089.1,1489.7,1088.8,1490.2,1088.6z"/>
						<path class="st3" d="M1475.5,1088.4c0.9,0,1.7-0.7,1.7-1.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0-0.9-0.8-1.8-1.7-1.8
							c-0.9,0-1.7,0.7-1.8,1.7c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4C1473.8,1087.6,1474.6,1088.5,1475.5,1088.4z"/>
						<path class="st3" d="M1466.1,1082.6c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,0.2-1.9-0.6-2.4
							c-0.8-0.4-1.9-0.2-2.4,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.5,0.9c-0.5,0.8-0.2,1.9,0.6,2.4
							C1464.6,1083.7,1465.7,1083.5,1466.1,1082.6z"/>
						<path class="st3" d="M1489.4,1072.6c0.4-0.1,0.8-0.2,1.1-0.3c0.9-0.3,1.5-1.2,1.3-2.1c-0.1-0.4-0.3-0.7-0.6-0.9
							c-0.3,0.1-0.7,0-1.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.9,0.3-1.5,1.2-1.3,2.1
							C1487.5,1072.1,1488.4,1072.8,1489.4,1072.6z"/>
						<path class="st3" d="M1468.1,1086.5c-0.6-0.7-1.8-0.7-2.4-0.1c-0.7,0.7-0.7,1.7-0.1,2.4c0.4,0.5,0.9,1.1,1.3,1.6
							c0.6,0.7,1.8,0.7,2.4,0.1c0.7-0.7,0.7-1.7,0.1-2.4C1469,1087.6,1468.6,1087,1468.1,1086.5z"/>
						<path class="st3" d="M1471.6,1094.3c-0.1,0-0.1,0.1-0.2,0.1h2.3C1473.1,1093.8,1472.3,1093.8,1471.6,1094.3z"/>
						<path class="st3" d="M1465.8,1091.7c-1.5-1.6-4,0.8-2.5,2.4c0.1,0.1,0.3,0.2,0.4,0.3h1.5
							C1466.2,1093.9,1466.7,1092.6,1465.8,1091.7z"/>
						<path class="st3" d="M1460.4,1085.7c0-0.4-0.1-0.8-0.5-1.1c-1.5-1.6-4,0.8-2.5,2.4c0.4,0.4,0.8,0.5,1.2,0.5
							C1459.6,1087.4,1460.4,1086.6,1460.4,1085.7z"/>
						<path class="st3" d="M1493.5,1080.5c0.2-0.1,0.4-0.1,0.6-0.1c-1.5-0.6-3.2,1.4-1.9,2.8c0.1,0.1,0.2,0.2,0.4,0.3
							C1491.9,1082.5,1492,1080.9,1493.5,1080.5z"/>
						<path class="st3" d="M1475.1,1063.4c-1.5-1.6-4,0.8-2.5,2.4C1474.2,1067.4,1476.7,1065,1475.1,1063.4z"/>
						<path class="st3" d="M1460.6,1074.2c-1.5-1.6-4,0.8-2.5,2.4C1459.6,1078.2,1462.1,1075.8,1460.6,1074.2z"/>
						<path class="st3" d="M1463.5,1064.3c-1.5-1.6-4,0.8-2.5,2.4C1462.6,1068.3,1465.1,1065.9,1463.5,1064.3z"/>
						<path class="st3" d="M1484.1,1057.2c-1.5-1.6-4,0.8-2.5,2.4C1483.2,1061.2,1485.7,1058.8,1484.1,1057.2z"/>
						<path class="st3" d="M1543.4,1068.2c1.7,1.2,3.5-0.4,3.5-1.9c-0.6-0.3-1-1-1-1.7C1543.5,1063,1541.1,1066.5,1543.4,1068.2z"/>
						<path class="st3" d="M1539.7,1076.8c-0.1-0.1-0.1-0.1-0.2-0.2c-1.5-1.1-3.1,0.1-3.4,1.4c-0.2,0.8,0,1.6,0.9,2.2
							C1539.2,1081.9,1541.7,1078.6,1539.7,1076.8z"/>
						<path class="st3" d="M1549.4,1073.7c-1.1-0.8-2.3-0.4-2.9,0.5c-0.7,0.9-0.8,2.3,0.4,3.2c1.5,1.1,3.2-0.1,3.4-1.5
							C1550.4,1075.1,1550.2,1074.3,1549.4,1073.7z"/>
						<path class="st3" d="M1553.2,1085.2c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.3-4.6,2.1-2.4,3.7c1,0.7,2.1,0.4,2.8-0.3
							C1554.3,1087.7,1554.5,1086.2,1553.2,1085.2z"/>
						<path class="st3" d="M1557.5,1069.7c-2.3-1.6-4.9,2-2.6,3.6C1557.3,1075,1559.9,1071.4,1557.5,1069.7z"/>
						<path class="st3" d="M1536.6,1067.1c-2.3-1.6-4.9,2-2.6,3.6C1536.4,1072.4,1539,1068.8,1536.6,1067.1z"/>
						<path class="st3" d="M1560.6,1080.9c-0.5-0.2-1-0.4-1.6-0.5c-1.1-0.4-2.5,0.2-2.8,1.3c-0.4,1.1,0.2,2.4,1.3,2.8
							c0.5,0.2,1,0.4,1.6,0.5c1.1,0.4,2.5-0.2,2.8-1.3C1562.3,1082.6,1561.7,1081.3,1560.6,1080.9z"/>
						<path class="st3" d="M1545.1,1083.7c-0.2-1.1-1.4-2.1-2.6-1.8c-1,0.2-1.8,1.1-1.8,2.1c0,0.1,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6
							c0.1,0.4,0.3,0.8,0.5,1.1c0.5,0.6,1.3,0.9,2,0.7c1.2-0.3,2-1.3,1.8-2.6C1545.2,1084.1,1545.2,1083.9,1545.1,1083.7z"/>
						<path class="st3" d="M1529.5,1077.4c-0.7-0.2-1.4-0.1-1.9,0.2c-0.4,0.2-0.7,0.6-0.9,1.1c-0.2,0.5-0.4,1-0.5,1.6
							c-0.4,1.1,0.2,2.5,1.3,2.8c1.1,0.4,2.4-0.2,2.8-1.3c0.2-0.5,0.4-1,0.5-1.6c0.1-0.3,0.1-0.7,0.1-1
							C1530.8,1078.4,1530.3,1077.7,1529.5,1077.4z"/>
						<path class="st3" d="M1555.5,1062.3c-0.2,0.1-0.5,0.2-0.9,0.3c-0.2,0-0.3,0-0.4,0c0.2,0.3,0.4,0.5,0.6,0.7
							C1555,1062.9,1555.2,1062.6,1555.5,1062.3z"/>
						<path class="st3" d="M1555.9,1062.1c0.6-0.4,1.5-0.4,2.2,0.2c0.2,0.1,0.3,0.3,0.4,0.4c1-0.6,1.6-1.8,1.1-2.9
							c-0.5-1-1.8-1.7-2.9-1.1c-0.2,0.1-0.4,0.2-0.6,0.3C1556.7,1059.9,1556.7,1061.3,1555.9,1062.1z"/>
						<path class="st3" d="M1535.7,1091.5c0.2-0.1,0.4-0.3,0.5-0.5c0.7-1.1,0.4-2.3-0.5-3.1c-0.7-0.6-1.4-1.2-2.1-1.7
							c-0.4-0.3-0.9-0.4-1.4-0.4c-0.7,0-1.4,0.4-1.7,0.9c-0.4,0.7-0.5,1.4-0.2,2c0.1,0.4,0.4,0.8,0.8,1.1c0.5,0.4,0.9,0.8,1.4,1.2
							c0.2,0.2,0.5,0.4,0.7,0.6C1533.9,1092.1,1535,1092,1535.7,1091.5z"/>
						<path class="st3" d="M1532.1,1093.3C1532.1,1093.3,1532,1093.3,1532.1,1093.3c-1.5-1.1-2.9-0.2-3.4,1h4.2
							C1532.7,1094,1532.5,1093.6,1532.1,1093.3z"/>
						<path class="st3" d="M1522.9,1085.7c-1-0.7-2-0.4-2.7,0.2c-0.9,0.9-1.2,2.5,0.1,3.4c1.7,1.2,3.5-0.3,3.5-1.9
							C1523.8,1086.8,1523.6,1086.2,1522.9,1085.7z"/>
						<path class="st3" d="M1565.8,1072.5c-2.3-1.6-4.9,2-2.6,3.6C1565.6,1077.8,1568.1,1074.2,1565.8,1072.5z"/>
						<path class="st3" d="M1534.3,1059c2.3,1.6,4.9-2,2.6-3.6C1534.6,1053.7,1532,1057.4,1534.3,1059z"/>
						<path class="st3" d="M1521.8,1073.3c-0.4,0.2-0.9,0.2-1.4,0c-0.6-0.2-0.9-0.6-1.1-1.1c-1.6,0.4-2.5,2.7-0.8,4
							C1520.6,1077.6,1522.8,1075,1521.8,1073.3z"/>
						<path class="st3" d="M1561.8,1094.4h2.4C1563.3,1093.8,1562.4,1093.9,1561.8,1094.4z"/>
						<path class="st3" d="M1520.9,1058.9c-1.7,0.3-2.8,2.7-1,4c2.3,1.6,4.9-2,2.6-3.6c-0.2-0.1-0.3-0.2-0.5-0.3
							C1521.6,1059.1,1521.3,1059.1,1520.9,1058.9z"/>
						<path class="st3" d="M1544.2,1049c1.4,1,2.9,0,3.4-1.2c-0.5,0.1-1-0.1-1.4-0.5c-0.8-0.7-0.8-1.6-0.5-2.3
							C1543.7,1044.9,1542.2,1047.7,1544.2,1049z"/>
						<path class="st3" d="M1603.1,1050.4c-2.8-1.3-4.8,3-2,4.3C1603.9,1056,1605.9,1051.7,1603.1,1050.4z"/>
						<path class="st3" d="M1596,1068.4c0-1,0.6-2,1.8-2.1c1-0.1,1.6,0.4,2,1c0.4-1,0.1-2.3-1.2-2.9
							C1596.1,1063.3,1594.1,1066.8,1596,1068.4z"/>
						<path class="st3" d="M1608.6,1059.5c-0.1-0.1-0.2-0.1-0.3-0.1c0.3,1.1-0.3,2.5-1.8,2.6c-0.5,0-0.9-0.1-1.3-0.3
							c0,0.8,0.4,1.6,1.3,2.1C1609.3,1065.1,1611.4,1060.8,1608.6,1059.5z"/>
						<path class="st3" d="M1612.7,1075.4c2.8,1.3,4.8-3,2-4.3C1611.9,1069.7,1609.9,1074,1612.7,1075.4z"/>
						<path class="st3" d="M1616.4,1053.7c-2.8-1.3-4.8,3-2,4.3c1.6,0.7,2.9-0.3,3.3-1.6c-0.4-0.6-0.5-1.3-0.3-1.9
							C1617.2,1054.2,1616.9,1053.9,1616.4,1053.7z"/>
						<path class="st3" d="M1593.7,1054.9c-2.5-1.2-4.5,2.3-2.7,3.9c0.3-0.4,0.8-0.7,1.4-0.8c0.8-0.1,1.4,0.2,1.8,0.7
							C1595.3,1057.7,1595.5,1055.7,1593.7,1054.9z"/>
						<path class="st3" d="M1623.4,1066.3c-0.3-0.3-0.7-0.7-1-1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.6-0.1-1.2-0.2-1.8-0.3
							c-1.2-0.2-2.6,0.7-2.8,1.9c-0.1,1,0.3,2,1.1,2.5c0.4-1.4,2.2-2.6,3.6-1.3c0.5,0.4,0.7,0.9,0.7,1.4c0.5-0.4,0.9-0.9,0.9-1.5
							C1623.8,1067.3,1623.7,1066.7,1623.4,1066.3C1623.5,1066.3,1623.4,1066.3,1623.4,1066.3z"/>
						<path class="st3" d="M1604.7,1074.6c1.2-0.5,1.9-1.8,1.4-3.1c-0.1-0.2-0.2-0.4-0.2-0.7c-0.4-1.2-1.9-1.9-3.1-1.4
							c-1.2,0.5-1.9,1.8-1.4,3.1c0.1,0.2,0.2,0.4,0.2,0.7C1602,1074.3,1603.5,1075.1,1604.7,1074.6z"/>
						<path class="st3" d="M1589.7,1071.7c0.1-0.6,0.2-1.2,0.3-1.8c0.2-1.2-0.7-2.6-1.9-2.8c-1.3-0.2-2.6,0.6-2.8,1.9
							c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.4,0,0.7,0.1,1.1c0.2,0.9,0.9,1.6,1.9,1.7c0.9,0.1,1.8-0.3,2.3-0.9
							c0.1-0.2,0.2-0.4,0.3-0.6C1589.6,1072,1589.6,1071.8,1589.7,1071.7z"/>
						<path class="st3" d="M1613.4,1045.9c0.5,0.5,0.7,1,0.7,1.6c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.3,0.9-0.7,1.3-1
							c1-0.8,1.4-2.3,0.6-3.3c-0.7-1-2.3-1.4-3.3-0.6c-0.4,0.3-0.9,0.7-1.3,1c-0.7,0.6-1.1,1.5-1,2.4
							C1611.7,1045.2,1612.6,1045.2,1613.4,1045.9z"/>
						<path class="st3" d="M1597.4,1081c0.2-0.2,0.4-0.4,0.5-0.6c0.5-1.3,0-2.5-1.1-3.2c-0.9-0.5-1.7-1-2.6-1.5
							c0,0.7-0.4,1.4-0.9,1.7c-0.8,0.6-1.7,0.5-2.5-0.1c-0.1,0.5,0,1.1,0.2,1.5c0.2,0.4,0.6,0.8,1.1,1.1c0.9,0.5,1.7,1,2.6,1.5
							c0.5,0.3,1.2,0.3,1.8,0.1C1596.8,1081.4,1597.1,1081.2,1597.4,1081z"/>
						<path class="st3" d="M1605.5,1083.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.9-0.2-1.7,0.1-2.3,0.8c-0.1,0.1-0.2,0.3-0.3,0.5
							c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.9-0.3,1.8-0.2,2.6c0.2,1.2,1.2,2.4,2.6,2.2c1.2-0.2,2.4-1.3,2.2-2.6c0-0.1-0.1-0.3-0.1-0.5
							c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.3,0.2-0.4c0.2-0.3,0.2-0.6,0.2-1
							C1606.9,1084.5,1606.3,1083.5,1605.5,1083.2z M1606.3,1087.4c0-0.1,0-0.2,0-0.2C1606.3,1087.2,1606.3,1087.3,1606.3,1087.4z
							M1606.3,1087c0,0,0-0.1,0.1-0.2C1606.4,1086.8,1606.3,1086.9,1606.3,1087z"/>
						<path class="st3" d="M1615.1,1082.3c-2.8-1.3-4.8,3-2,4.3C1615.8,1087.9,1617.9,1083.6,1615.1,1082.3z"/>
						<path class="st3" d="M1593.7,1083.5c-0.4-0.2-0.9-0.3-1.2-0.2c-1.2,0.1-2,1.2-2.1,2.3c-0.1,0.9,0.3,1.8,1.3,2.3
							C1594.4,1089.2,1596.5,1084.8,1593.7,1083.5z"/>
						<path class="st3" d="M1583.3,1080.9c-0.2,0.1-0.5,0.2-0.7,0.2c-2.6,0.2-3-3.5-0.8-4.1c-2.4-0.3-3.8,3.4-1.3,4.5
							C1581.7,1082.1,1582.7,1081.7,1583.3,1080.9z"/>
						<path class="st3" d="M1623.7,1059.5c2.8,1.3,4.8-3,2-4.3C1623,1053.8,1620.9,1058.2,1623.7,1059.5z"/>
						<path class="st3" d="M1589.8,1046.7c1.6,0.7,2.9-0.3,3.3-1.6c-1-0.4-1.4-1.6-1.1-2.6c-0.1,0-0.1-0.1-0.2-0.1
							C1589,1041,1587,1045.3,1589.8,1046.7z"/>
						<path class="st3" d="M1576,1063.7c-1.3,1-1.6,3.2,0.3,4.1c2.5,1.2,4.4-2.2,2.7-3.8c-0.3,0.3-0.7,0.4-1.2,0.5
							C1576.9,1064.6,1576.3,1064.2,1576,1063.7z"/>
						<path class="st3" d="M1628.1,1078.7c-1.8-0.8-3.2,0.6-3.4,2.1c-0.1,0.9,0.3,1.8,1.3,2.2c0.4,0.2,0.7,0.2,1,0.2
							C1629.3,1083.3,1630.6,1079.9,1628.1,1078.7z"/>
						<path class="st3" d="M1574.8,1051.8c-0.4,0-0.7,0-1-0.2c0,0.7,0.4,1.5,1.3,1.9c2.8,1.3,4.8-3,2-4.3c-0.2-0.1-0.4-0.2-0.6-0.2
							C1576.9,1050.2,1576.4,1051.7,1574.8,1051.8z"/>
						<path class="st3" d="M1600.3,1034.2h-2C1599.1,1034.6,1599.8,1034.5,1600.3,1034.2z"/>
						<path class="st3" d="M1636.8,1044.3c0.9,0.4,1.8,0.1,2.4-0.5c0.8-0.8,1.1-2.2,0-3.2c-2.2-1.9-5.1,1.4-3,3.3
							C1636.4,1044.1,1636.6,1044.2,1636.8,1044.3z"/>
						<path class="st3" d="M1628.5,1055.4c2.2,1.9,5.1-1.4,3-3.3C1629.3,1050.2,1626.3,1053.5,1628.5,1055.4z"/>
						<path class="st3" d="M1638.7,1053.5c2.2,1.9,5.1-1.4,3-3.3C1639.5,1048.3,1636.5,1051.6,1638.7,1053.5z"/>
						<path class="st3" d="M1641.2,1065.5c1.1,1,2.4,0.6,3.1-0.3c0.7-0.8,0.9-2.1-0.2-3.1c-0.2-0.2-0.3-0.3-0.5-0.3
							C1641.6,1060.8,1639.2,1063.7,1641.2,1065.5z"/>
						<path class="st3" d="M1650.9,1048.9c0.1-0.5,0-1.1-0.5-1.6c-0.1-0.1-0.2-0.2-0.3-0.3c-2.2-1.9-5.1,1.4-3,3.3
							C1648.8,1051.8,1650.7,1050.5,1650.9,1048.9z"/>
						<path class="st3" d="M1629.5,1042.2c-2.2-1.9-5.1,1.4-3,3.3C1628.7,1047.4,1631.7,1044.1,1629.5,1042.2z"/>
						<path class="st3" d="M1653.1,1061.6c0.3-0.7,0.3-1.5-0.1-2.1c-0.2-0.4-0.5-0.7-0.9-0.9c-0.5-0.2-1-0.5-1.5-0.7
							c-0.2-0.1-0.5-0.2-0.7-0.2c-0.8-0.1-1.6,0.2-2.1,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,1.1-0.1,2.5,1,3c0.5,0.2,1,0.5,1.5,0.7
							c0.4,0.2,0.9,0.3,1.3,0.2C1652.1,1062.7,1652.8,1062.2,1653.1,1061.6z"/>
						<path class="st3" d="M1631.8,1060.6c0.1,1.2,1.2,2.2,2.4,2.1c1.2-0.1,2.2-1.1,2.1-2.4c0-0.2,0-0.4,0-0.6
							c-0.1-1.2-1.2-2.2-2.4-2.1c-1.2,0.1-2.2,1.1-2.1,2.4C1631.8,1060.2,1631.8,1060.4,1631.8,1060.6z"/>
						<path class="st3" d="M1617.7,1056.4c0.2,0.3,0.5,0.6,0.9,0.8c1.1,0.5,2.5,0.1,3-1c0.2-0.5,0.5-1,0.7-1.5c0.5-1.1,0.1-2.5-1-3
							c-1.1-0.5-2.5-0.1-3,1c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.1-0.1,0.2-0.1,0.3C1617.2,1055.2,1617.3,1055.9,1617.7,1056.4z"/>
						<path class="st3" d="M1650.4,1040.7c0.5-0.2,1-0.4,1.4-0.6c0.5-0.2,1-0.6,1.3-1.1c0.3-0.5,0.4-1.1,0.1-1.7
							c-0.3-0.8-1-1.4-1.8-1.5c-0.3,0-0.7,0-1,0.1c-0.5,0.2-1,0.4-1.4,0.6c-1.1,0.4-1.9,1.6-1.4,2.8
							C1648,1040.4,1649.3,1041.2,1650.4,1040.7z"/>
						<path class="st3" d="M1623.5,1066.3c0.8,0.8,2.4,0.6,3.1-0.2c0.8-1,0.7-2.3-0.2-3.2c-0.6-0.7-1.3-1.3-1.9-2
							c-0.8-0.8-2.4-0.7-3.2,0.2c-0.8,1-0.7,2.3,0.2,3.2c0.3,0.3,0.6,0.6,0.9,0.9C1622.8,1065.6,1623.1,1065.9,1623.5,1066.3
							C1623.4,1066.3,1623.5,1066.3,1623.5,1066.3z"/>
						<path class="st3" d="M1627.9,1074.2c-0.1,1.2,0.5,2.4,1.8,2.6c0.6,0.1,1.3-0.1,1.8-0.4c0.5-0.3,0.8-0.8,0.9-1.3
							c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.1
							c0.9-0.8,0.7-2.4-0.2-3.2c-1-0.8-2.2-0.7-3.2,0.2C1628.6,1071.6,1628,1072.9,1627.9,1074.2z M1632.3,1074.6
							c0-0.1,0-0.2,0.1-0.2C1632.4,1074.5,1632.3,1074.5,1632.3,1074.6z M1632.4,1074.3c0,0,0.1-0.1,0.1-0.2
							C1632.5,1074.1,1632.5,1074.2,1632.4,1074.3z"/>
						<path class="st3" d="M1638.6,1075.7c0.3,0.3,0.7,0.5,1.1,0.5c1.9,0.3,3.7-2.3,1.9-3.9c-1.4-1.2-3.1-0.3-3.6,1
							C1637.7,1074.1,1637.8,1075,1638.6,1075.7z"/>
						<path class="st3" d="M1622,1067.8c-1.5-1.3-3.3-0.2-3.6,1.3c-0.2,0.7,0,1.5,0.7,2.1c1.7,1.5,3.8-0.2,3.7-1.9
							C1622.7,1068.8,1622.5,1068.3,1622,1067.8z"/>
						<path class="st3" d="M1613.7,1059.3c-2.2-1.9-5.1,1.4-3,3.3C1612.9,1064.5,1615.9,1061.2,1613.7,1059.3z"/>
						<path class="st3" d="M1654.5,1052.5c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,0.9,0.5,1.3,0.6c1.3,0.1,2.5-1.3,2.4-2.6
							c-0.1-0.5-0.3-0.9-0.7-1.3c-0.2-0.2-0.4-0.3-0.7-0.4C1656.1,1049.8,1654.6,1051.1,1654.5,1052.5z"/>
						<path class="st3" d="M1630.1,1034.2h-1.2C1629.4,1034.4,1629.8,1034.3,1630.1,1034.2z"/>
						<path class="st3" d="M1614.2,1047.4c0-0.5-0.2-1.1-0.7-1.6c-0.8-0.7-1.7-0.7-2.4-0.3c-1.2,0.7-1.9,2.4-0.6,3.7
							C1612.1,1050.7,1614.2,1049.1,1614.2,1047.4z"/>
						<path class="st3" d="M1654.9,1074.2c0-0.6-0.2-1.2-0.7-1.7c-2.2-1.9-5.1,1.4-3,3.3c0,0,0,0,0.1,0
							C1652.9,1077.3,1654.8,1075.8,1654.9,1074.2z"/>
						<path class="st3" d="M1613.2,1036.1c1.7,1.5,3.8-0.2,3.7-1.9h-4.4C1612.4,1034.9,1612.6,1035.6,1613.2,1036.1z"/>
						<path class="st3" d="M1573.8,1051.6c0.3,0.1,0.6,0.2,1,0.2c1.6-0.1,2.1-1.6,1.7-2.8c-0.3-0.8-1-1.5-2.1-1.4
							C1572.1,1047.8,1572.1,1050.9,1573.8,1051.6z"/>
						<path class="st3" d="M1577.4,1060.3c-1.9,0.2-2.3,2.2-1.4,3.4c0.4,0.5,1,0.9,1.8,0.8c0.5,0,0.9-0.2,1.2-0.5
							C1580.2,1062.8,1579.6,1060.1,1577.4,1060.3z"/>
						<path class="st3" d="M1583,1056.2c2.7-0.3,2.3-4.5-0.4-4.2C1579.9,1052.3,1580.3,1056.5,1583,1056.2z"/>
						<path class="st3" d="M1592.5,1058c-0.7,0.1-1.1,0.4-1.4,0.8c-0.9,1.3-0.2,3.6,1.8,3.4c1.9-0.2,2.3-2.3,1.4-3.4
							C1593.9,1058.3,1593.3,1057.9,1592.5,1058z"/>
						<path class="st3" d="M1586.4,1048.3c2.7-0.3,2.3-4.5-0.4-4.2C1583.3,1044.4,1583.7,1048.6,1586.4,1048.3z"/>
						<path class="st3" d="M1569.7,1059.4c2.7-0.3,2.3-4.5-0.4-4.2C1566.6,1055.5,1567,1059.7,1569.7,1059.4z"/>
						<path class="st3" d="M1595.1,1050.3c-0.5,0.2-1,0.4-1.5,0.6c-1,0.4-1.7,1.7-1.2,2.7c0.4,1,1.6,1.7,2.7,1.2
							c0.5-0.2,1-0.4,1.5-0.6c1-0.4,1.7-1.7,1.2-2.7C1597.4,1050.4,1596.2,1049.8,1595.1,1050.3z"/>
						<path class="st3" d="M1585.7,1061.9c-0.9-0.7-2.3-0.6-3,0.3c-0.7,0.9-0.6,2.2,0.3,3c0.2,0.1,0.3,0.3,0.5,0.4
							c0.9,0.7,2.3,0.6,3-0.3c0.7-0.9,0.6-2.2-0.3-3C1586,1062.1,1585.9,1062,1585.7,1061.9z"/>
						<path class="st3" d="M1574,1070.5c0-0.2,0-0.5-0.1-0.7c-0.2-0.5-0.4-1-0.6-1.5c-0.4-1-1.7-1.7-2.7-1.2c-1,0.4-1.7,1.6-1.2,2.7
							c0.2,0.5,0.4,1,0.6,1.5c0.4,0.9,1.4,1.5,2.4,1.3c0.1,0,0.2-0.1,0.4-0.1C1573.4,1072.1,1574,1071.3,1574,1070.5z"/>
						<path class="st3" d="M1579,1040.9c1,0.4,2.4,0.1,2.8-1c0.2-0.4,0.4-0.9,0.5-1.3c0.4-1,0.1-2.3-1-2.8c-1-0.4-2.4-0.1-2.8,1
							c-0.2,0.4-0.4,0.9-0.5,1.3C1577.5,1039.2,1577.9,1040.5,1579,1040.9z"/>
						<path class="st3" d="M1582.8,1071c-0.3-0.2-0.8-0.3-1.2-0.3c-0.9,0-1.7,0-2.6,0c-1.1,0-2,1.2-1.9,2.3c0.2,1.2,1.1,1.9,2.3,1.9
							c0.9,0,1.7,0,2.6,0c1.1,0,2-1.2,1.9-2.3c0-0.2-0.1-0.4-0.2-0.6C1583.5,1071.6,1583.2,1071.2,1582.8,1071z"/>
						<path class="st3" d="M1590.8,1077.4c0.7,0.5,1.7,0.7,2.5,0.1c0.5-0.4,0.9-1.1,0.9-1.8c0-0.4-0.1-0.8-0.4-1.2
							c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.5c0-1.1-1.2-2-2.3-1.9
							c-0.6,0.1-1.1,0.4-1.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.9,0.3,1.8,0.8,2.6c0.1,0.2,0.3,0.5,0.5,0.7
							C1590.4,1077.1,1590.6,1077.3,1590.8,1077.4z M1593.4,1074.2c-0.1-0.1-0.1-0.1-0.1-0.2
							C1593.4,1074.1,1593.4,1074.1,1593.4,1074.2z M1593.3,1073.9c0,0,0-0.1,0-0.2C1593.3,1073.7,1593.3,1073.8,1593.3,1073.9z"/>
						<path class="st3" d="M1597.8,1066.4c-1.3,0.1-1.8,1.1-1.8,2.1c0,1.1,0.8,2.3,2.2,2.1c1.7-0.2,2.2-2,1.6-3.2
							C1599.4,1066.7,1598.8,1066.3,1597.8,1066.4z"/>
						<path class="st3" d="M1582.6,1081.1c0.3,0,0.5-0.1,0.7-0.2c0.5-0.2,0.8-0.7,1-1.2c0.2-0.8,0-1.8-0.6-2.3
							c-0.4-0.3-0.9-0.5-1.5-0.5c-0.1,0-0.2,0-0.4,0.1C1579.5,1077.6,1580,1081.4,1582.6,1081.1z"/>
						<path class="st3" d="M1572.9,1080.3c0.7-1.3,0-3.4-1.9-3.2c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
							C1572.1,1081.2,1572.6,1080.8,1572.9,1080.3z"/>
						<path class="st3" d="M1593,1045.1c0.3,0.1,0.7,0.2,1.1,0.2c2.7-0.3,2.3-4.5-0.4-4.2c-1,0.1-1.5,0.7-1.7,1.4
							C1591.7,1043.4,1592.1,1044.6,1593,1045.1z"/>
						<path class="st3" d="M1562.6,1050.8c0.2,0,0.3,0,0.5-0.1c0-0.2,0.1-0.4,0.1-0.6c0.2-0.5,0.7-0.9,1.2-1c0.2-1.2-0.6-2.6-2.2-2.5
							C1559.5,1046.8,1559.9,1051,1562.6,1050.8z"/>
						<path class="st3" d="M1561.5,1068.7c-2.7,0.3-2.3,4.5,0.4,4.2C1564.6,1072.6,1564.2,1068.5,1561.5,1068.7z"/>
						<path class="st3" d="M1608.3,1059.4c-0.2-0.9-1-1.7-2.2-1.6c-2.2,0.2-2.3,3-0.9,3.9c0.3,0.2,0.8,0.3,1.3,0.3
							C1608,1061.9,1608.5,1060.5,1608.3,1059.4z"/>
						<path class="st3" d="M1554.2,1062.6c0.1,0,0.3,0,0.4,0c0.3,0,0.6-0.1,0.9-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.9-0.8,0.8-2.2,0.1-3.1
							c-0.4-0.4-0.9-0.7-1.7-0.6C1551.7,1058.6,1551.9,1062.3,1554.2,1062.6z"/>
						<path class="st3" d="M1563.6,1037.5c1.8-0.2,2.2-2.1,1.5-3.3h-3.4C1560.8,1035.5,1561.6,1037.7,1563.6,1037.5z"/>
						<path class="st3" d="M1494.1,1080.4c-0.2,0-0.4,0-0.6,0.1c-1.5,0.5-1.6,2-0.9,3c0.4,0.6,1.2,1,2.1,0.7
							C1496.9,1083.5,1496.1,1080.3,1494.1,1080.4z"/>
						<path class="st3" d="M1498.8,1091.4c-1.4,0.5-1.6,2-1,2.9h3.2C1501.8,1093,1500.7,1090.8,1498.8,1091.4z"/>
						<path class="st3" d="M1502.7,1082.7c-0.3,0-0.5,0-0.8,0.1c-2.4,0.8-1.2,4.5,1.2,3.7c0.9-0.3,1.3-1,1.3-1.8
							c-0.2,0-0.3-0.1-0.5-0.1C1502.9,1084.3,1502.6,1083.5,1502.7,1082.7z"/>
						<path class="st3" d="M1512.1,1086.2c-2.4,0.8-1.2,4.5,1.2,3.7C1515.7,1089.2,1514.5,1085.4,1512.1,1086.2z"/>
						<path class="st3" d="M1502.7,1075.7c0.1-0.2,0.2-0.5,0.4-0.6c-2.1,0.9-0.9,4.4,1.4,3.6c0,0,0.1,0,0.1-0.1
							c-0.3-0.1-0.6-0.3-0.8-0.4C1502.8,1077.7,1502.3,1076.7,1502.7,1075.7z"/>
						<path class="st3" d="M1490.4,1088.5c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.2-0.8,0.5-0.9,0.9c-0.6,1.3,0.6,3.3,2.4,2.7
							C1494,1091.4,1492.8,1087.7,1490.4,1088.5z"/>
						<path class="st3" d="M1515,1080c0.3,0.1,0.7,0.4,0.9,0.7c0.1-0.5,0.1-1.1-0.3-1.5c-0.1-0.2-0.3-0.3-0.5-0.5
							c-0.7-0.5-1.5-0.6-2.3-0.1c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.9-0.6,2.7c0.2,0.3,0.5,0.5,0.9,0.7
							c0.1-0.6,0.2-1.2,0.5-1.7C1512.9,1080.2,1513.9,1079.7,1515,1080z"/>
						<path class="st3" d="M1507.2,1091.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.9c-0.4,0.8-0.2,1.8,0.5,2.4h3.3
							c0.1-0.1,0.1-0.2,0.2-0.3C1508.5,1093.1,1508.2,1091.9,1507.2,1091.4z"/>
						<path class="st3" d="M1497.5,1071.1c0-0.5,0.1-1,0.1-1.5c0-0.4,0.2-0.7,0.4-1c-0.2-0.2-0.5-0.3-0.7-0.3c-1-0.2-2.2,0.4-2.3,1.5
							c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,1,0.4,2.2,1.5,2.3c0.7,0.1,1.4-0.1,1.9-0.6C1497.7,1072.4,1497.4,1071.7,1497.5,1071.1z"/>
						<path class="st3" d="M1518.7,1092.7c-0.9,0.3-1.2,0.9-1.3,1.6h3.8C1521,1093.3,1520,1092.3,1518.7,1092.7z"/>
						<path class="st3" d="M1512.2,1073c-0.4-0.2-0.8-0.5-0.9-1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.3-0.2-0.7-0.1-1
							c-0.3-0.1-0.7-0.1-1.2,0c-2.4,0.8-1.2,4.5,1.2,3.7C1511.6,1074,1512,1073.5,1512.2,1073z"/>
						<path class="st3" d="M1482.2,1082.1c-2.4,0.8-1.2,4.5,1.2,3.7C1485.8,1085,1484.6,1081.3,1482.2,1082.1z"/>
						<path class="st3" d="M1524.4,1083.2c-0.8,0.3-1.2,0.8-1.3,1.4c-0.1,0.8,0.2,1.7,0.9,2.1c0.4,0.3,1,0.4,1.6,0.2
							C1528.1,1086.2,1526.8,1082.5,1524.4,1083.2z"/>
						<path class="st3" d="M1477.5,1094.4h0.9C1478.2,1094.3,1477.9,1094.3,1477.5,1094.4z"/>
						<path class="st3" d="M1481,1073.6c0.2,0,0.3,0,0.5-0.1c2.4-0.8,1.2-4.5-1.2-3.7c-0.8,0.3-1.2,0.9-1.3,1.6
							C1478.9,1072.5,1479.7,1073.7,1481,1073.6z"/>
						<path class="st3" d="M1552.8,1036.9c1.8,1.6,4.2-1,2.9-2.7h-3.1C1552,1035,1551.9,1036.1,1552.8,1036.9z"/>
						<path class="st3" d="M1546.1,1047.3c0.5,0.4,1,0.5,1.4,0.5c1.5-0.2,2.7-2.2,1.2-3.5c-1.2-1-2.5-0.3-3.1,0.7
							C1545.2,1045.7,1545.3,1046.7,1546.1,1047.3z"/>
						<path class="st3" d="M1557.8,1042.4c-2-1.7-4.6,1.4-2.6,3C1557.2,1047.1,1559.8,1044.1,1557.8,1042.4z"/>
						<path class="st3" d="M1557.6,1056.2c2,1.7,4.6-1.4,2.6-3C1558.3,1051.4,1555.7,1054.5,1557.6,1056.2z"/>
						<path class="st3" d="M1565.4,1039.5c-2-1.7-4.6,1.4-2.6,3C1564.8,1044.2,1567.4,1041.2,1565.4,1039.5z"/>
						<path class="st3" d="M1546.8,1035.4c-2-1.7-4.6,1.4-2.6,3C1546.2,1040.2,1548.8,1037.1,1546.8,1035.4z"/>
						<path class="st3" d="M1563.2,1050.1c-0.1,0.2-0.1,0.4-0.1,0.6c-0.1,0.8,0.3,1.7,1.1,2.1c0.5,0.2,0.9,0.4,1.4,0.6
							c1,0.4,2.2,0,2.7-1c0.4-1,0.1-2.2-1-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-0.5-0.2-1-0.2-1.5-0.1
							C1563.9,1049.2,1563.5,1049.6,1563.2,1050.1z"/>
						<path class="st3" d="M1549.2,1051.9c0.1,1,1.1,2,2.2,1.8c1.1-0.1,1.9-1,1.8-2.2c0-0.2,0-0.4,0-0.6c-0.1-1-1.1-2-2.2-1.8
							c-1.1,0.1-1.9,1-1.8,2.2C1549.2,1051.5,1549.2,1051.7,1549.2,1051.9z"/>
						<path class="st3" d="M1537.2,1049.1c1,0.4,2.2,0.1,2.7-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.7c-1-0.4-2.2-0.1-2.7,1
							c-0.2,0.5-0.4,0.9-0.6,1.4C1535.8,1047.4,1536.2,1048.6,1537.2,1049.1z"/>
						<path class="st3" d="M1540,1055.4c0.6,0.6,1.2,1.2,1.7,1.7c0.7,0.7,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8
							c-0.6-0.6-1.2-1.2-1.7-1.7c-0.7-0.7-2.2-0.6-2.8,0.2C1539.1,1053.5,1539.2,1054.6,1540,1055.4z"/>
						<path class="st3" d="M1546.8,1066.3c0.2,0.1,0.4,0.2,0.6,0.2c1,0.1,2.2-0.5,2.3-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
							c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3.1
							c0,0.1,0,0.2,0,0.4C1545.9,1065.2,1546.2,1065.9,1546.8,1066.3z M1550,1064c0,0-0.1,0.1-0.1,0.2
							C1549.9,1064.1,1550,1064,1550,1064z M1549.8,1064.5c0-0.1,0-0.2,0.1-0.2C1549.8,1064.3,1549.8,1064.4,1549.8,1064.5z"/>
						<path class="st3" d="M1555.9,1062.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.6-0.6,0.9c-0.3,0.7-0.2,1.5,0.6,2.1
							c1.8,1.5,4.1-0.9,3-2.6c-0.1-0.1-0.2-0.3-0.4-0.4C1557.3,1061.7,1556.5,1061.7,1555.9,1062.1z"/>
						<path class="st3" d="M1540.7,1058.7c-0.1-0.1-0.1-0.1-0.2-0.2c-2-1.7-4.6,1.4-2.6,3c0.6,0.5,1.3,0.6,1.8,0.4
							C1541,1061.6,1541.8,1059.9,1540.7,1058.7z"/>
						<path class="st3" d="M1530.3,1054.1c2,1.7,4.6-1.4,2.6-3C1531,1049.3,1528.4,1052.4,1530.3,1054.1z"/>
						<path class="st3" d="M1572.7,1042.7c-2-1.7-4.6,1.4-2.6,3C1572,1047.5,1574.6,1044.4,1572.7,1042.7z"/>
						<path class="st3" d="M1532.5,1039c-2-1.7-4.6,1.4-2.6,3C1531.8,1043.7,1534.4,1040.7,1532.5,1039z"/>
						<path class="st3" d="M1569.4,1062.3c-2-1.7-4.6,1.4-2.6,3C1568.7,1067,1571.3,1064,1569.4,1062.3z"/>
						<path class="st3" d="M1509.8,1057c2.4,0.9,3.8-2.7,1.4-3.7C1508.9,1052.4,1507.4,1056,1509.8,1057z"/>
						<path class="st3" d="M1508.3,1065.1c-2.4-0.9-3.8,2.7-1.4,3.7C1509.2,1069.7,1510.7,1066,1508.3,1065.1z"/>
						<path class="st3" d="M1514.8,1064.1c2.4,0.9,3.8-2.7,1.4-3.7C1513.8,1059.5,1512.4,1063.2,1514.8,1064.1z"/>
						<path class="st3" d="M1520.5,1073.2c0.5,0.2,1,0.2,1.4,0c1.4-0.6,1.9-3,0.1-3.7c-0.8-0.3-1.5-0.1-1.9,0.3
							c-0.3,0.2-0.5,0.5-0.6,0.9c-0.2,0.5-0.2,1,0,1.4C1519.5,1072.6,1519.9,1073,1520.5,1073.2z"/>
						<path class="st3" d="M1520.9,1058.9c0.4,0.2,0.8,0.2,1.1,0.1c1.6-0.3,2.3-3,0.3-3.8C1520,1054.4,1518.5,1058,1520.9,1058.9z"/>
						<path class="st3" d="M1502.3,1061.2c2.4,0.9,3.8-2.7,1.4-3.7C1501.4,1056.6,1499.9,1060.2,1502.3,1061.2z"/>
						<path class="st3" d="M1525.4,1068c0.5,0,1,0.1,1.5,0.1c1,0.1,2.1-0.7,2.2-1.8c0.1-1.1-0.7-2.1-1.8-2.2c-0.5,0-1-0.1-1.5-0.1
							c-1-0.1-2.1,0.7-2.2,1.8C1523.6,1066.9,1524.3,1067.9,1525.4,1068z"/>
						<path class="st3" d="M1511,1071.5c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.4,0.5,0.7,0.9,1c0.5,0.3,1.1,0.3,1.7,0.1c1-0.5,1.5-1.6,1-2.6
							c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.5-2.6-1c-0.6,0.3-1.1,0.9-1.2,1.6C1510.8,1070.8,1510.9,1071.2,1511,1071.5z"/>
						<path class="st3" d="M1501.4,1071.5c0-0.5,0.1-1,0.1-1.5c0.1-1-0.7-2.1-1.8-2.2c-0.7,0-1.3,0.2-1.7,0.7c-0.2,0.3-0.4,0.6-0.4,1
							c0,0.5-0.1,1-0.1,1.5c-0.1,0.6,0.2,1.3,0.7,1.7c0.3,0.2,0.6,0.4,1,0.4C1500.3,1073.3,1501.3,1072.6,1501.4,1071.5z"/>
						<path class="st3" d="M1520.6,1050c0.3-0.3,0.7-0.6,1-0.9c0.8-0.7,1-1.9,0.3-2.8c-0.7-0.8-1.9-1.1-2.8-0.3
							c-0.3,0.3-0.7,0.6-1,0.9c-0.8,0.7-1,1.9-0.3,2.8C1518.5,1050.4,1519.7,1050.7,1520.6,1050z"/>
						<path class="st3" d="M1507.4,1075.6c-0.7-0.4-1.5-0.7-2.2-1.1c-0.7-0.3-1.6-0.1-2.1,0.5c-0.2,0.2-0.3,0.4-0.4,0.6
							c-0.4,1.1,0.1,2.1,1.1,2.6c0.3,0.1,0.6,0.3,0.8,0.4c0.4,0.2,0.9,0.4,1.3,0.7c0.9,0.5,2.2-0.2,2.6-1.1
							C1508.9,1077.1,1508.4,1076.1,1507.4,1075.6z"/>
						<path class="st3" d="M1515.9,1080.7c-0.2-0.3-0.5-0.6-0.9-0.7c-1.1-0.4-2.1,0.1-2.6,1.1c-0.3,0.5-0.4,1.1-0.5,1.7
							c0,0.5,0,1.1,0.1,1.6c0.3,1,1.1,1.9,2.2,1.6c1-0.2,1.9-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
							c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3C1516.3,1081.8,1516.2,1081.2,1515.9,1080.7z M1515.9,1083.3
							c0,0.1,0,0.1,0,0.2C1515.8,1083.4,1515.8,1083.3,1515.9,1083.3z M1515.9,1083.1c0,0,0-0.1,0-0.2
							C1515.9,1083,1515.9,1083,1515.9,1083.1z"/>
						<path class="st3" d="M1522.8,1078.8c-2.4-0.9-3.8,2.7-1.4,3.7C1523.7,1083.4,1525.2,1079.7,1522.8,1078.8z"/>
						<path class="st3" d="M1505.3,1081c-1.4-0.6-2.5,0.5-2.6,1.7c-0.1,0.8,0.2,1.6,1.2,2c0.2,0.1,0.4,0.1,0.5,0.1
							C1506.4,1085,1507.5,1081.9,1505.3,1081z"/>
						<path class="st3" d="M1495.9,1076.4c-2.4-0.9-3.8,2.7-1.4,3.7C1496.8,1081,1498.2,1077.4,1495.9,1076.4z"/>
						<path class="st3" d="M1528.6,1059.6c2.4,0.9,3.8-2.7,1.4-3.7C1527.7,1055,1526.3,1058.7,1528.6,1059.6z"/>
						<path class="st3" d="M1500,1051c2.4,0.9,3.8-2.7,1.4-3.7C1499.1,1046.4,1497.7,1050.1,1500,1051z"/>
						<path class="st3" d="M1491.2,1069.2c1.7-0.3,2.4-3,0.4-3.8c-2.2-0.9-3.6,2.2-1.9,3.4c0.1,0.1,0.3,0.2,0.5,0.2
							C1490.5,1069.3,1490.9,1069.3,1491.2,1069.2z"/>
						<path class="st3" d="M1533.3,1075.1c-1.3-0.5-2.4,0.4-2.6,1.5c-0.1,0.6,0,1.3,0.6,1.8c0.2,0.1,0.4,0.3,0.6,0.4
							C1534.2,1079.7,1535.7,1076.1,1533.3,1075.1z"/>
						<path class="st3" d="M1488.4,1057.4c2.4,0.9,3.8-2.7,1.4-3.7C1487.5,1052.9,1486.1,1056.5,1488.4,1057.4z"/>
						<path class="st3" d="M1506.4,1040.3c2.4,0.9,3.8-2.7,1.4-3.7C1505.5,1035.7,1504,1039.4,1506.4,1040.3z"/>
						<path class="st3" d="M836,1065.1c-2.6-1.9-5.7,2.2-3,4.1C835.6,1071.2,838.7,1067.1,836,1065.1z"/>
						<path class="st3" d="M828.5,1079c-2.6-1.9-5.7,2.2-3,4.1C828.1,1085,831.1,1080.9,828.5,1079z"/>
						<path class="st3" d="M839.9,1075.7c-2.6-1.9-5.7,2.2-3,4.1C839.5,1081.8,842.5,1077.7,839.9,1075.7z"/>
						<path class="st3" d="M844,1089.1c-2.6-1.9-5.7,2.2-3,4.1C843.6,1095.1,846.6,1091,844,1089.1z"/>
						<path class="st3" d="M849.3,1071.3c-2.6-1.9-5.7,2.2-3,4.1C848.9,1077.4,851.9,1073.2,849.3,1071.3z"/>
						<path class="st3" d="M825.3,1067.9c-2.6-1.9-5.7,2.2-3,4.1C824.9,1074,827.9,1069.8,825.3,1067.9z"/>
						<path class="st3" d="M852.5,1084.2c-0.6-0.2-1.2-0.4-1.8-0.7c-1.2-0.5-2.9,0.2-3.3,1.5c-0.4,1.3,0.1,2.8,1.5,3.3
							c0.6,0.2,1.2,0.4,1.8,0.7c1.2,0.5,2.9-0.2,3.3-1.5C854.4,1086.2,853.9,1084.7,852.5,1084.2z"/>
						<path class="st3" d="M834.7,1087.1c-0.2-1.3-1.6-2.4-2.9-2.1c-1.4,0.3-2.3,1.5-2.1,2.9c0,0.2,0.1,0.5,0.1,0.7
							c0.2,1.3,1.6,2.4,2.9,2.1c1.4-0.3,2.3-1.5,2.1-2.9C834.8,1087.6,834.8,1087.3,834.7,1087.1z"/>
						<path class="st3" d="M816.8,1079.6c-1.3-0.4-2.8,0.1-3.3,1.5c-0.2,0.6-0.4,1.2-0.7,1.8c-0.5,1.2,0.2,2.9,1.5,3.3
							c1.3,0.4,2.8-0.1,3.3-1.5c0.2-0.6,0.4-1.2,0.7-1.8C818.8,1081.6,818.1,1080,816.8,1079.6z"/>
						<path class="st3" d="M846.8,1059.4c-1.2,0.6-2,2.1-1.3,3.4c0.6,1.2,2.1,2,3.4,1.3c0.5-0.3,1-0.5,1.6-0.8c1.2-0.6,2-2.1,1.3-3.4
							c-0.6-1.2-2.1-2-3.4-1.3C847.8,1058.9,847.3,1059.1,846.8,1059.4z"/>
						<path class="st3" d="M823.9,1091.8c-0.8-0.7-1.6-1.4-2.4-2.1c-1-0.9-2.8-0.5-3.6,0.5c-0.8,1.2-0.5,2.6,0.5,3.6
							c0.2,0.2,0.4,0.3,0.6,0.5h5.8C825,1093.4,824.7,1092.5,823.9,1091.8z"/>
						<path class="st3" d="M809.1,1089c-2.6-1.9-5.7,2.2-3,4.1C808.8,1095.1,811.8,1090.9,809.1,1089z"/>
						<path class="st3" d="M858.7,1074.7c-2.6-1.9-5.7,2.2-3,4.1C858.3,1080.7,861.4,1076.6,858.7,1074.7z"/>
						<path class="st3" d="M822.8,1058.5c2.6,1.9,5.7-2.2,3-4.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.9-1.3,1.6-2.4,0.9
							c-0.2-0.1-0.4-0.3-0.5-0.5C821.6,1055.7,821.3,1057.4,822.8,1058.5z"/>
						<path class="st3" d="M807.4,1073.8c-2.6-1.9-5.7,2.2-3,4.1C807,1079.9,810,1075.8,807.4,1073.8z"/>
						<path class="st3" d="M809.1,1058.6c-2.6-1.9-5.7,2.2-3,4.1C808.8,1064.7,811.8,1060.6,809.1,1058.6z"/>
						<path class="st3" d="M834.1,1045.1c0.1,0.6,0,1.1-0.4,1.5c0.2,0.2,0.3,0.4,0.6,0.6c1.2,0.9,2.4,0.5,3.2-0.3
							c0-0.6,0.3-1.2,0.8-1.5c0.1-0.8-0.1-1.7-1-2.3c-1.4-1.1-3-0.3-3.7,0.9c0.2,0.2,0.3,0.4,0.3,0.6
							C834,1044.8,834,1045,834.1,1045.1z"/>
						<path class="st3" d="M871.4,1064.7c-2.8,0.4-2.2,4.8,0.6,4.4C874.8,1068.7,874.2,1064.3,871.4,1064.7z"/>
						<path class="st3" d="M875.1,1077.9c-2.8,0.4-2.2,4.8,0.6,4.4C878.5,1081.9,877.9,1077.5,875.1,1077.9z"/>
						<path class="st3" d="M880.8,1073.3c2.8-0.4,2.2-4.8-0.6-4.4C877.4,1069.3,878,1073.7,880.8,1073.3z"/>
						<path class="st3" d="M890.9,1074.7c-2.8,0.4-2.2,4.8,0.6,4.4C894.3,1078.7,893.7,1074.3,890.9,1074.7z"/>
						<path class="st3" d="M883.3,1060.5c-2.8,0.4-2.2,4.8,0.6,4.4C886.8,1064.5,886.2,1060.1,883.3,1060.5z"/>
						<path class="st3" d="M866.4,1073c-2.8,0.4-2.2,4.8,0.6,4.4C869.8,1077,869.2,1072.6,866.4,1073z"/>
						<path class="st3" d="M896.1,1067.6c-0.5-1.1-1.8-1.7-2.9-1.1c-0.5,0.2-1,0.4-1.5,0.7c-1.1,0.5-1.7,1.8-1.1,2.9
							c0.5,1.1,1.8,1.7,2.9,1.1c0.5-0.2,1-0.4,1.5-0.7C896,1070.1,896.6,1068.7,896.1,1067.6z"/>
						<path class="st3" d="M884.4,1079.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-0.7-2.4-0.5-3.1,0.4c-0.7,1-0.5,2.4,0.4,3.1
							c0.2,0.1,0.3,0.3,0.5,0.4c0.9,0.7,2.4,0.5,3.1-0.4C885.6,1081.6,885.4,1080.2,884.4,1079.5z"/>
						<path class="st3" d="M871.2,1086.5c-0.5-1.1-1.8-1.7-2.9-1.1c-1.1,0.5-1.7,1.8-1.1,2.9c0.2,0.5,0.4,1,0.7,1.5
							c0.5,1.1,1.8,1.7,2.9,1.1c1.1-0.5,1.7-1.8,1.1-2.9C871.6,1087.5,871.4,1087,871.2,1086.5z"/>
						<path class="st3" d="M875.1,1053.2c-0.2,0.5-0.3,1-0.5,1.4c-0.4,1.1,0,2.5,1.2,2.9c1.1,0.4,2.5,0,2.9-1.2
							c0.2-0.5,0.3-1,0.5-1.4c0.4-1.1,0-2.5-1.2-2.9C876.9,1051.6,875.5,1052,875.1,1053.2z"/>
						<path class="st3" d="M880,1088.6c-0.9,0.1-1.8,0.1-2.7,0.2c-1.2,0.1-2.1,1.4-1.9,2.5c0.2,1.3,1.3,2,2.5,1.9
							c0.9-0.1,1.8-0.1,2.7-0.2c1.2-0.1,2.1-1.4,1.9-2.5C882.3,1089.3,881.3,1088.6,880,1088.6z"/>
						<path class="st3" d="M893,1092c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.2,0-0.4,0-0.5
							c-0.1-1.2-1.4-2.1-2.5-1.9c-1.3,0.2-1.9,1.3-1.9,2.5c0.1,1.1,0.5,2.2,1.2,3.1h4C893.6,1093.6,893.6,1092.6,893,1092z
							M892.4,1091.1c0,0,0,0.1,0.1,0.2C892.4,1091.3,892.4,1091.2,892.4,1091.1z M892.7,1091.7c-0.1-0.1-0.1-0.1-0.1-0.2
							C892.6,1091.5,892.6,1091.6,892.7,1091.7z"/>
						<path class="st3" d="M896.9,1083.2c-2.8,0.4-2.2,4.8,0.6,4.4C900.3,1087.2,899.7,1082.8,896.9,1083.2z"/>
						<path class="st3" d="M892.7,1057.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c-0.1,1.1-1.4,1.7-2.4,1.6c-0.3,0-0.5-0.2-0.8-0.3
							c0.2,1.1,1,2.1,2.4,1.9c2.2-0.3,2.3-3,1-4C892.8,1057.3,892.8,1057.4,892.7,1057.5z"/>
						<path class="st3" d="M892.8,1057.4c0-0.1,0.1-0.1,0.1-0.1C892.8,1057.3,892.8,1057.4,892.8,1057.4z"/>
						<path class="st3" d="M892.7,1057.5c0,0-0.1,0.1-0.1,0.2C892.7,1057.6,892.7,1057.6,892.7,1057.5z"/>
						<path class="st3" d="M859.2,1068.7c2.8-0.4,2.2-4.8-0.6-4.4C855.7,1064.7,856.3,1069.1,859.2,1068.7z"/>
						<path class="st3" d="M858.9,1087.5c-2.8,0.4-2.2,4.8,0.6,4.4C862.3,1091.5,861.7,1087.1,858.9,1087.5z"/>
						<path class="st3" d="M905.1,1073.8c-2.8,0.4-2.2,4.8,0.6,4.4C908.6,1077.8,907.9,1073.4,905.1,1073.8z"/>
						<path class="st3" d="M850.8,1077c-2.8,0.4-2.2,4.8,0.6,4.4C854.2,1081,853.6,1076.6,850.8,1077z"/>
						<path class="st3" d="M859.4,1054.7c2.8-0.4,2.2-4.8-0.6-4.4C856,1050.7,856.6,1055.1,859.4,1054.7z"/>
						<path class="st3" d="M1070.8,1051.4c-2.7,1.4-0.5,5.5,2.2,4.2C1075.6,1054.1,1073.5,1050,1070.8,1051.4z"/>
						<path class="st3" d="M1081.2,1067.4c2.7-1.4,0.5-5.5-2.2-4.2C1076.4,1064.6,1078.5,1068.8,1081.2,1067.4z"/>
						<path class="st3" d="M1083.2,1056.7c2.7-1.4,0.5-5.5-2.2-4.2C1078.4,1053.9,1080.5,1058,1083.2,1056.7z"/>
						<path class="st3" d="M1093.7,1054.6c-2.7,1.4-0.5,5.5,2.2,4.2C1098.5,1057.3,1096.4,1053.2,1093.7,1054.6z"/>
						<path class="st3" d="M1083.4,1047.1c2.7-1.4,0.5-5.5-2.2-4.2C1078.6,1044.4,1080.7,1048.5,1083.4,1047.1z"/>
						<path class="st3" d="M1068.7,1061.3c-2.7,1.4-0.5,5.5,2.2,4.2C1073.5,1064.1,1071.3,1059.9,1068.7,1061.3z"/>
						<path class="st3" d="M1091.8,1050c0.3,0.3,0.7,0.6,1.2,0.7c0.3-0.8,0.9-1.4,2-1.5c0.4,0,0.7,0,1,0.1c0.1-0.1,0.2-0.2,0.4-0.3
							c0.9-0.8,1-2.4,0.1-3.3c-0.9-0.9-2.4-1-3.3-0.1c-0.4,0.4-0.8,0.8-1.3,1.2C1091,1047.6,1090.8,1049.1,1091.8,1050z"/>
						<path class="st3" d="M1086.9,1065.8c0.2,0.1,0.4,0.1,0.6,0.2c1.2,0.4,2.6-0.3,2.9-1.5c0.3-1.2-0.3-2.5-1.5-2.9
							c-0.2-0.1-0.4-0.1-0.6-0.2c-1.2-0.4-2.6,0.3-2.9,1.5C1085,1064.1,1085.6,1065.4,1086.9,1065.8z"/>
						<path class="st3" d="M1079.3,1074.4c-0.4-0.4-0.8-0.8-1.2-1.3c-0.8-0.9-2.4-1-3.3-0.1c-0.9,0.9-1,2.4-0.1,3.3
							c0.4,0.4,0.8,0.8,1.2,1.3c0.8,0.9,2.4,1,3.3,0.1C1080.1,1076.8,1080.2,1075.4,1079.3,1074.4z"/>
						<path class="st3" d="M1072.7,1042.6c1.2,0,2.5-0.9,2.5-2.2c0-0.5,0-1.1,0-1.6c0-1.2-0.9-2.4-2.2-2.5c-1.2,0-2.5,0.9-2.5,2.2
							c0,0.5,0,1.1,0,1.6C1070.5,1041.4,1071.4,1042.6,1072.7,1042.6z"/>
						<path class="st3" d="M1084.1,1076.5c0.7,1.2,1.9,1.5,3.2,1c0.9-0.4,1.8-0.7,2.7-1.1c1.1-0.5,1.6-2.1,1-3.2
							c-0.7-1.2-1.9-1.5-3.2-1c-0.9,0.4-1.8,0.7-2.7,1.1C1084,1073.8,1083.5,1075.5,1084.1,1076.5z"/>
						<path class="st3" d="M1102.5,1074.3c0.6-1,0.4-2.6-0.7-3.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
							c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.2-0.1-0.4-0.2-0.4c-0.5-1.1-2.1-1.6-3.2-1c-1.2,0.7-1.5,1.9-1,3.2
							c0.5,1.2,1.5,2.3,2.7,2.9C1100.4,1075.5,1101.9,1075.4,1102.5,1074.3z M1101.4,1070.8c-0.1-0.1-0.2-0.1-0.2-0.2
							C1101.3,1070.7,1101.3,1070.8,1101.4,1070.8z M1101.1,1070.6c0,0-0.1-0.1-0.1-0.2C1101,1070.4,1101,1070.5,1101.1,1070.6z"/>
						<path class="st3" d="M1102.6,1061c-2.7,1.4-0.5,5.5,2.2,4.2C1107.5,1063.7,1105.3,1059.6,1102.6,1061z"/>
						<path class="st3" d="M1090.9,1078.3c-2.7,1.4-0.5,5.5,2.2,4.2C1095.7,1081.1,1093.6,1076.9,1090.9,1078.3z"/>
						<path class="st3" d="M1079.5,1083.2c-2.7,1.4-0.5,5.5,2.2,4.2C1084.3,1086,1082.1,1081.8,1079.5,1083.2z"/>
						<path class="st3" d="M1090,1040.8c2.7-1.4,0.5-5.5-2.2-4.2C1085.2,1038,1087.3,1042.1,1090,1040.8z"/>
						<path class="st3" d="M1057.8,1055.4c-2.7,1.4-0.5,5.5,2.2,4.2C1062.6,1058.2,1060.5,1054,1057.8,1055.4z"/>
						<path class="st3" d="M1066.2,1078.5c-2.7,1.4-0.5,5.5,2.2,4.2C1071.1,1081.2,1068.9,1077.1,1066.2,1078.5z"/>
						<path class="st3" d="M1109.7,1052.9c2.7-1.4,0.5-5.5-2.2-4.2C1104.9,1050.1,1107.1,1054.3,1109.7,1052.9z"/>
						<path class="st3" d="M1054.5,1070.8c-2.7,1.4-0.5,5.5,2.2,4.2C1059.3,1073.6,1057.2,1069.4,1054.5,1070.8z"/>
						<path class="st3" d="M1055.4,1045.5c2.7-1.4,0.5-5.5-2.2-4.2C1050.5,1042.7,1052.7,1046.9,1055.4,1045.5z"/>
						<path class="st3" d="M1108.5,1066.1c-3.2,0.3-2.7,5.3,0.5,5C1112.3,1070.8,1111.8,1065.8,1108.5,1066.1z"/>
						<path class="st3" d="M1112.2,1081.3c-3.2,0.3-2.7,5.3,0.5,5C1115.9,1085.9,1115.4,1080.9,1112.2,1081.3z"/>
						<path class="st3" d="M1118.9,1076.3c3.2-0.3,2.7-5.3-0.5-5C1115.1,1071.7,1115.7,1076.7,1118.9,1076.3z"/>
						<path class="st3" d="M1130.2,1078.4c-3.2,0.3-2.7,5.3,0.5,5C1133.9,1083,1133.4,1078,1130.2,1078.4z"/>
						<path class="st3" d="M1122.8,1066.9c3.2-0.3,2.7-5.3-0.5-5C1119.1,1062.2,1119.6,1067.2,1122.8,1066.9z"/>
						<path class="st3" d="M1102.5,1075.3c-3.2,0.3-2.7,5.3,0.5,5C1106.2,1080,1105.7,1074.9,1102.5,1075.3z"/>
						<path class="st3" d="M1136.5,1070.5c-0.5-1.2-1.9-2-3.3-1.4c-0.6,0.2-1.2,0.5-1.7,0.7c-1.2,0.5-2,2-1.4,3.3
							c0.3,0.8,1,1.4,1.8,1.5c0.3-0.5,0.9-0.9,1.7-0.8c0.3,0,0.6,0.1,0.8,0.3c0.2-0.1,0.4-0.2,0.6-0.3
							C1136.3,1073.3,1137,1071.8,1136.5,1070.5z"/>
						<path class="st3" d="M1122.8,1083.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-1-0.8-2.7-0.7-3.5,0.4
							c-0.8,1.1-0.7,2.7,0.4,3.5c0.2,0.2,0.4,0.3,0.6,0.5c1,0.8,2.7,0.7,3.5-0.4c0.2-0.3,0.3-0.5,0.4-0.8
							C1122.6,1085.7,1122.4,1084.5,1122.8,1083.6z"/>
						<path class="st3" d="M1108,1092.6c-0.2-0.6-0.5-1.2-0.7-1.7c-0.5-1.2-2-2-3.3-1.4c-1.2,0.5-2,1.9-1.4,3.3
							c0.2,0.6,0.5,1.1,0.7,1.7h4.7C1108.2,1093.8,1108.3,1093.2,1108,1092.6z"/>
						<path class="st3" d="M1113.9,1058.1c1.2,0.5,2.8,0.1,3.3-1.3c0.2-0.5,0.4-1.1,0.6-1.6c0.5-1.2,0.1-2.8-1.3-3.3
							c-1.2-0.5-2.8-0.1-3.3,1.3c-0.2,0.5-0.4,1.1-0.6,1.6C1112.1,1056,1112.6,1057.6,1113.9,1058.1z"/>
						<path class="st3" d="M1114.2,1093.8c-0.5,0-1,0.3-1.4,0.6h6.5c-0.5-0.5-1.2-0.7-2-0.7
							C1116.3,1093.7,1115.2,1093.7,1114.2,1093.8z"/>
						<path class="st3" d="M1137.9,1093.1c2.3-1,1.7-5.1-1.3-4.8c-3.1,0.3-2.7,5,0.2,5c0.2-0.1,0.3-0.2,0.5-0.2
							C1137.5,1093.1,1137.7,1093.1,1137.9,1093.1z"/>
						<path class="st3" d="M1132,1063.1c3.2-0.3,2.7-5.3-0.5-5C1128.2,1058.4,1128.8,1063.4,1132,1063.1z"/>
						<path class="st3" d="M1094,1065.1c-3.2,0.3-2.7,5.3,0.5,5C1097.7,1069.7,1097.2,1064.7,1094,1065.1z"/>
						<path class="st3" d="M1093.3,1091.5c-1.7,0.2-2.3,1.6-2.1,2.9h4.8C1096.2,1092.9,1095.2,1091.3,1093.3,1091.5z"/>
						<path class="st3" d="M1146.4,1078c-0.3,0-0.5,0.1-0.8,0.2c0.2,1-0.3,2.1-1.4,2.3c0,1.4,0.9,2.7,2.7,2.5
							C1150.2,1082.7,1149.6,1077.6,1146.4,1078z"/>
						<path class="st3" d="M1084.6,1079.2c-3.2,0.3-2.7,5.3,0.5,5C1088.3,1083.9,1087.8,1078.9,1084.6,1079.2z"/>
						<path class="st3" d="M1094.9,1049.2c-1.1,0.1-1.7,0.7-2,1.5c-0.6,1.6,0.3,3.7,2.5,3.5c2.8-0.3,2.8-4.2,0.5-4.9
							C1095.6,1049.2,1095.3,1049.2,1094.9,1049.2z"/>
						<path class="st3" d="M891.8,1037.4c-1.9-1.7-4.6,1.3-2.7,3C891.1,1042.1,893.8,1039.1,891.8,1037.4z"/>
						<path class="st3" d="M901.1,1035.7c-1.9-1.7-4.6,1.3-2.7,3C900.3,1040.4,903,1037.4,901.1,1035.7z"/>
						<path class="st3" d="M903.3,1046.5c-1.9-1.7-4.6,1.3-2.7,3C902.6,1051.2,905.3,1048.2,903.3,1046.5z"/>
						<path class="st3" d="M906.1,1035.9c1.5,1.3,3.4-0.1,3.3-1.7h-4C905.4,1034.8,905.5,1035.4,906.1,1035.9z"/>
						<path class="st3" d="M911.3,1045.9c0.4-1,0.1-2.2-0.9-2.7c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.5-2.3-0.1-2.7,0.9
							c-0.4,1-0.1,2.2,0.9,2.7c0.5,0.2,0.9,0.4,1.4,0.6C909.6,1047.3,910.9,1046.9,911.3,1045.9z"/>
						<path class="st3" d="M892.2,1045.1c0.1,1.1,1.1,2,2.1,1.9c1.1-0.1,2-1,1.9-2.1c0-0.2,0-0.4,0-0.6c-0.1-1.1-1.1-2-2.1-1.9
							c-1.1,0.1-2,1-1.9,2.1C892.2,1044.7,892.2,1044.9,892.2,1045.1z"/>
						<path class="st3" d="M880.2,1042c1,0.4,2.2,0.1,2.7-0.9c0.2-0.5,0.4-0.9,0.6-1.4c0.5-1,0.1-2.3-0.9-2.7c-1-0.4-2.2-0.1-2.7,0.9
							c-0.2,0.5-0.4,0.9-0.6,1.4C878.8,1040.3,879.2,1041.6,880.2,1042z"/>
						<path class="st3" d="M885.6,1045.4c-0.7-0.8-2.2-0.6-2.8,0.2c-0.7,0.9-0.6,2,0.2,2.8c0.6,0.6,1.1,1.2,1.7,1.8
							c0.7,0.8,2.2,0.6,2.8-0.2c0.7-0.9,0.6-2-0.2-2.8C886.7,1046.6,886.2,1046,885.6,1045.4z"/>
						<path class="st3" d="M889.5,1059.4c0.2,0.2,0.5,0.3,0.8,0.3c1,0.2,2.3-0.5,2.4-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
							c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.3c0.8-0.7,0.6-2.2-0.2-2.8c-0.9-0.7-2-0.6-2.8,0.2c-0.8,0.8-1.4,1.9-1.5,3
							C888.6,1058.1,888.8,1058.9,889.5,1059.4z M892.6,1057.7c0-0.1,0-0.2,0.1-0.2C892.7,1057.6,892.7,1057.6,892.6,1057.7z
							M892.9,1057.2c0,0-0.1,0.1-0.1,0.1C892.8,1057.4,892.8,1057.3,892.9,1057.2C892.9,1057.2,892.9,1057.2,892.9,1057.2z"/>
						<path class="st3" d="M898.3,1058.7c1.9,1.7,4.6-1.3,2.7-3C899,1053.9,896.3,1057,898.3,1058.7z"/>
						<path class="st3" d="M883.4,1051.6c-1.9-1.7-4.6,1.3-2.7,3C882.6,1056.4,885.3,1053.3,883.4,1051.6z"/>
						<path class="st3" d="M875.9,1043.9c-1.9-1.7-4.6,1.3-2.7,3C875.2,1048.6,877.8,1045.6,875.9,1043.9z"/>
						<path class="st3" d="M913.3,1039.3c1.9,1.7,4.6-1.3,2.7-3C914,1034.5,911.4,1037.5,913.3,1039.3z"/>
						<path class="st3" d="M876,1034.2h-3.5c0.1,0.2,0.3,0.4,0.5,0.6C874.1,1035.8,875.4,1035.2,876,1034.2z"/>
						<path class="st3" d="M909.7,1058.9c1.9,1.7,4.6-1.3,2.7-3C910.4,1054.1,907.7,1057.1,909.7,1058.9z"/>
						<path class="st3" d="M827.8,1042.6c1.8,1.1,3.5-1.7,1.7-2.8C827.7,1038.8,826,1041.6,827.8,1042.6z"/>
						<path class="st3" d="M834.9,1039.9c1.8,1.1,3.5-1.7,1.7-2.8C834.8,1036.1,833.1,1038.9,834.9,1039.9z"/>
						<path class="st3" d="M837.5,1047c0,0.5,0.2,0.9,0.8,1.2c1.8,1.1,3.5-1.7,1.7-2.8c-0.6-0.4-1.2-0.3-1.7,0
							C837.8,1045.8,837.5,1046.4,837.5,1047z"/>
						<path class="st3" d="M840.6,1036.6c1.6,1,3.1-1.1,2.1-2.4h-2.5C839.7,1034.9,839.7,1036,840.6,1036.6z"/>
						<path class="st3" d="M825.2,1035.8c1.3,0.8,2.5-0.4,2.5-1.5h-3.2C824.4,1034.8,824.6,1035.4,825.2,1035.8z"/>
						<path class="st3" d="M843.1,1044.7c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.8-0.3,2-1.1c0.2-0.9-0.2-1.8-1.1-2
							c-0.4-0.1-0.8-0.2-1.2-0.3c-0.8-0.2-1.8,0.3-2,1.1C841.8,1043.5,842.2,1044.4,843.1,1044.7z"/>
						<path class="st3" d="M830.9,1045.9c0.2,0.8,1.1,1.4,2,1.2c0.3-0.1,0.6-0.3,0.8-0.5c0.4-0.4,0.5-0.9,0.4-1.5
							c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.2-0.5-0.3-0.6c-0.4-0.5-1-0.7-1.6-0.6c-0.8,0.2-1.4,1.1-1.2,2
							C830.8,1045.6,830.9,1045.8,830.9,1045.9z"/>
						<path class="st3" d="M822.9,1044.1c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.1-2c-0.9-0.2-1.8,0.2-2,1.1
							c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8,0.3,1.8,1.1,2C821.8,1045.4,822.7,1045,822.9,1044.1z"/>
						<path class="st3" d="M824,1049.9c0.5,0.4,1.1,0.8,1.6,1.2c0.7,0.5,1.8,0.2,2.2-0.5c0.5-0.8,0.2-1.7-0.5-2.2
							c-0.5-0.4-1.1-0.8-1.6-1.2c-0.7-0.5-1.8-0.2-2.2,0.5C823,1048.5,823.3,1049.4,824,1049.9z"/>
						<path class="st3" d="M831.4,1057.8c0.8,0,1.7-0.7,1.7-1.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2
							c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.8-0.5-2.2c-0.8-0.5-1.7-0.2-2.2,0.5c-0.5,0.7-0.8,1.7-0.8,2.6
							C829.9,1057,830.5,1057.8,831.4,1057.8z M833.2,1055.5c0,0,0,0.1-0.1,0.1C833.1,1055.6,833.1,1055.5,833.2,1055.5z
							M833.1,1055.7c0,0.1,0,0.1,0,0.2C833.1,1055.8,833.1,1055.8,833.1,1055.7z"/>
						<path class="st3" d="M837.7,1055.9c1.8,1.1,3.5-1.7,1.7-2.8C837.6,1052,835.9,1054.8,837.7,1055.9z"/>
						<path class="st3" d="M823.1,1055.2c1,0.6,2.1,0,2.4-0.9c0.2-0.6,0.1-1.4-0.7-1.8c-1.6-0.9-3,1-2.2,2.3
							C822.7,1054.9,822.9,1055,823.1,1055.2z"/>
						<path class="st3" d="M817.8,1047.3c-1.8-1.1-3.5,1.7-1.7,2.8C817.9,1051.2,819.6,1048.4,817.8,1047.3z"/>
						<path class="st3" d="M846.9,1038.3c1.8,1.1,3.5-1.7,1.7-2.8C846.8,1034.4,845.1,1037.2,846.9,1038.3z"/>
						<path class="st3" d="M814.1,1040.5c1.8,1.1,3.5-1.7,1.7-2.8C814,1036.7,812.3,1039.4,814.1,1040.5z"/>
						<path class="st3" d="M848.5,1051.6c-1.8-1.1-3.5,1.7-1.7,2.8C848.6,1055.5,850.3,1052.7,848.5,1051.6z"/>
						<path class="st3" d="M1145.7,1078.2c-0.1-0.7-0.6-1.4-1.6-1.4c-2.4-0.2-2.8,3.5-0.4,3.7c0.2,0,0.4,0,0.6,0
							C1145.3,1080.3,1145.8,1079.1,1145.7,1078.2z"/>
						<path class="st3" d="M1144.5,1088.3c-2.4-0.2-2.8,3.5-0.4,3.7C1146.5,1092.3,1146.9,1088.6,1144.5,1088.3z"/>
						<path class="st3" d="M1150.5,1082c-2.4-0.2-2.8,3.5-0.4,3.7C1152.5,1086,1152.9,1082.2,1150.5,1082z"/>
						<path class="st3" d="M1158.1,1088.9c-2.4-0.2-2.8,3.5-0.4,3.7C1160.1,1092.9,1160.5,1089.2,1158.1,1088.9z"/>
						<path class="st3" d="M1154.8,1075.7c-2.4-0.2-2.8,3.5-0.4,3.7C1156.8,1079.7,1157.2,1075.9,1154.8,1075.7z"/>
						<path class="st3" d="M1138.3,1082.5c-2.4-0.2-2.8,3.5-0.4,3.7C1140.3,1086.5,1140.7,1082.8,1138.3,1082.5z"/>
						<path class="st3" d="M1161.7,1082.6c-0.5,0.1-0.9,0.2-1.4,0.2c-1,0.2-1.7,1.2-1.5,2.2c0.2,1,1.1,1.7,2.2,1.5
							c0.5-0.1,0.9-0.2,1.4-0.2c1-0.2,1.7-1.2,1.5-2.2C1163.6,1083.1,1162.7,1082.4,1161.7,1082.6z"/>
						<path class="st3" d="M1151.8,1091.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6-0.8-1.9-0.9-2.6-0.3c-0.8,0.7-0.9,1.8-0.3,2.6
							c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.3,0.6,0.4h1.8c0.1-0.1,0.2-0.1,0.3-0.2C1152.3,1093.5,1152.5,1092.4,1151.8,1091.5z"/>
						<path class="st3" d="M1137.9,1093.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.2c-0.4,0.2-0.8,0.6-0.9,1.1h3.6
							C1139.2,1093.7,1138.6,1093.2,1137.9,1093.1z"/>
						<path class="st3" d="M1152.1,1067.6c-0.8-0.5-2.1-0.5-2.6,0.4c-0.2,0.4-0.5,0.7-0.7,1.1c-0.5,0.8-0.5,2,0.4,2.6
							c0.8,0.5,2.1,0.5,2.6-0.4c0.2-0.4,0.5-0.7,0.7-1.1C1153,1069.4,1152.9,1068.1,1152.1,1067.6z"/>
						<path class="st3" d="M1161.7,1078c2.4,0.2,2.8-3.5,0.4-3.7C1159.6,1074,1159.3,1077.8,1161.7,1078z"/>
						<path class="st3" d="M1131.9,1074.6c-0.6,1.1-0.3,2.8,1.3,2.9c2.1,0.2,2.6-2.5,1.2-3.5c-0.2-0.1-0.5-0.2-0.8-0.3
							C1132.8,1073.7,1132.2,1074.1,1131.9,1074.6z"/>
						<path class="st3" d="M1129.2,1093c-1.1-0.1-1.7,0.6-1.9,1.4h3.5C1130.6,1093.7,1130.1,1093.1,1129.2,1093z"/>
						<path class="st3" d="M1170,1091.1c-2-0.2-2.6,2.3-1.4,3.3h2.4C1172.1,1093.4,1171.8,1091.2,1170,1091.1z"/>
						<path class="st3" d="M1124.6,1082.7c-0.9-0.1-1.4,0.3-1.8,0.9c-0.5,0.9-0.3,2.1,0.6,2.6c0.2,0.1,0.5,0.2,0.8,0.2
							C1126.6,1086.7,1127,1082.9,1124.6,1082.7z"/>
						<path class="st3" d="M1136.3,1066.1c2.4,0.2,2.8-3.5,0.4-3.7C1134.2,1062.1,1133.9,1065.8,1136.3,1066.1z"/>
						<path class="st3" d="M626.9,1074.4c-2.2-2.1-5.4,1.3-3.2,3.4C625.9,1079.8,629.1,1076.5,626.9,1074.4z"/>
						<path class="st3" d="M615.3,1089.4c2.2,2.1,5.4-1.3,3.2-3.4C616.3,1083.9,613.1,1087.3,615.3,1089.4z"/>
						<path class="st3" d="M626,1087.8c2.2,2.1,5.4-1.3,3.2-3.4C627,1082.3,623.8,1085.7,626,1087.8z"/>
						<path class="st3" d="M635,1084.9c2.2,2.1,5.4-1.3,3.2-3.4C636,1079.4,632.8,1082.8,635,1084.9z"/>
						<path class="st3" d="M616.9,1075.7c-2.2-2.1-5.4,1.3-3.2,3.4C615.9,1081.1,619.1,1077.7,616.9,1075.7z"/>
						<path class="st3" d="M639.6,1093.5c-0.5-0.3-1-0.5-1.5-0.8c-1.1-0.6-2.6-0.2-3.1,1c-0.1,0.2-0.2,0.5-0.2,0.8h5.8
							C640.3,1094,640,1093.7,639.6,1093.5z"/>
						<path class="st3" d="M620.9,1091.8c-1.3,0.1-2.3,1.1-2.3,2.4c0,0.1,0,0.1,0,0.2h4.6c0-0.1,0-0.2,0-0.3
							C623.2,1092.8,622.1,1091.7,620.9,1091.8z"/>
						<path class="st3" d="M607.9,1085.2c-1.1-0.5-2.5-0.2-3.1,1c-0.3,0.5-0.5,1-0.8,1.5c-0.6,1.1-0.2,2.6,1,3.1
							c1.1,0.5,2.5,0.2,3.1-1c0.3-0.5,0.5-1,0.8-1.5C609.5,1087.3,609.1,1085.8,607.9,1085.2z"/>
						<path class="st3" d="M641.7,1071.5c-0.4-1.1-1.7-2-2.9-1.6c-0.5,0.2-1,0.4-1.5,0.5c-1.1,0.4-2,1.6-1.6,2.9
							c0.4,1.1,1.7,2,2.9,1.6c0.2-0.1,0.4-0.2,0.7-0.2c-1.7-0.5-1.8-3.3,0.3-3.6c1.4-0.2,2.1,1,2,2.1
							C641.8,1072.7,641.9,1072.1,641.7,1071.5z"/>
						<path class="st3" d="M608.8,1094.4h1.1C609.6,1094.3,609.2,1094.3,608.8,1094.4z"/>
						<path class="st3" d="M596,1094.4h4.6c0-0.5-0.2-1.1-0.7-1.5C598.3,1091.3,596.2,1092.7,596,1094.4z"/>
						<path class="st3" d="M643.1,1089c2.2,2.1,5.4-1.3,3.2-3.4C644.1,1083.6,640.9,1086.9,643.1,1089z"/>
						<path class="st3" d="M615.7,1066.9c2.2,2.1,5.4-1.3,3.2-3.4C616.8,1061.5,613.6,1064.9,615.7,1066.9z"/>
						<path class="st3" d="M596.9,1082.3c2.2,2.1,5.4-1.3,3.2-3.4C597.9,1076.9,594.7,1080.2,596.9,1082.3z"/>
						<path class="st3" d="M603.4,1065.5c-2.2-2.1-5.4,1.3-3.2,3.4C602.4,1070.9,605.6,1067.5,603.4,1065.5z"/>
						<path class="st3" d="M630.6,1054.7c-2.2-2.1-5.4,1.3-3.2,3.4C629.6,1060.1,632.8,1056.8,630.6,1054.7z"/>
						<path class="st3" d="M641.8,1045.2c-2.4,0.3-1.9,3.9,0.5,3.7C644.6,1048.6,644.2,1044.9,641.8,1045.2z"/>
						<path class="st3" d="M644.7,1056.2c-2.4,0.3-1.9,3.9,0.5,3.7C647.5,1059.6,647,1055.9,644.7,1056.2z"/>
						<path class="st3" d="M649.5,1052.5c2.4-0.3,1.9-3.9-0.5-3.7C646.7,1049.2,647.2,1052.8,649.5,1052.5z"/>
						<path class="st3" d="M657.8,1053.8c-2.4,0.3-1.9,3.9,0.5,3.7C660.6,1057.2,660.2,1053.5,657.8,1053.8z"/>
						<path class="st3" d="M651.8,1041.9c-2.4,0.3-1.9,3.9,0.5,3.7C654.6,1045.3,654.2,1041.6,651.8,1041.9z"/>
						<path class="st3" d="M638,1055.7c2.4-0.3,1.9-3.9-0.5-3.7C635.2,1052.3,635.6,1056,638,1055.7z"/>
						<path class="st3" d="M662.3,1048c-0.4-0.9-1.5-1.4-2.4-1c-0.4,0.2-0.8,0.4-1.3,0.5c-0.9,0.4-1.4,1.5-1,2.4
							c0.4,0.9,1.5,1.4,2.4,1c0.4-0.2,0.8-0.4,1.3-0.5C662.2,1050,662.7,1048.9,662.3,1048z"/>
						<path class="st3" d="M652.4,1057.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2-0.5-2.6,0.3c-0.6,0.8-0.5,2,0.3,2.6
							c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2,0.5,2.6-0.3C653.3,1059.5,653.2,1058.3,652.4,1057.7z"/>
						<path class="st3" d="M641.3,1063.3c-0.4-0.9-1.5-1.4-2.4-1c-0.9,0.4-1.4,1.5-1,2.4c0.2,0.4,0.4,0.8,0.5,1.3
							c0.4,0.9,1.5,1.4,2.4,1c0.9-0.4,1.4-1.5,1-2.4C641.7,1064.2,641.5,1063.7,641.3,1063.3z"/>
						<path class="st3" d="M645.6,1039.3c0.9,0.3,2.1,0,2.4-1c0.1-0.4,0.3-0.8,0.4-1.2c0.3-0.9,0-2.1-1-2.4c-0.9-0.3-2.1,0-2.4,1
							c-0.1,0.4-0.3,0.8-0.4,1.2C644.3,1037.8,644.6,1038.9,645.6,1039.3z"/>
						<path class="st3" d="M646.8,1069c0.8,0,1.5-0.1,2.3-0.1c1,0,1.8-1.1,1.6-2.1c-0.2-1-1-1.6-2.1-1.6c-0.8,0-1.5,0.1-2.3,0.1
							c-1,0-1.8,1.1-1.6,2.1C645,1068.5,645.8,1069,646.8,1069z"/>
						<path class="st3" d="M659.1,1068c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-1-1.1-1.8-2.1-1.6
							c-1.1,0.2-1.6,1-1.6,2.1c0,1,0.5,2.1,1.2,2.9c0.7,0.7,1.8,1,2.6,0.3c0.7-0.6,1-1.8,0.3-2.6
							C659.3,1068.2,659.2,1068.1,659.1,1068z M658.9,1067.5c0,0,0,0.1,0,0.2C658.9,1067.6,658.9,1067.6,658.9,1067.5z M659.1,1067.9
							c0-0.1-0.1-0.1-0.1-0.2C659,1067.8,659.1,1067.9,659.1,1067.9z"/>
						<path class="st3" d="M662.7,1061c-2.4,0.3-1.9,3.9,0.5,3.7C665.5,1064.4,665.1,1060.7,662.7,1061z"/>
						<path class="st3" d="M649.3,1070.6c-2.4,0.3-1.9,3.9,0.5,3.7C652.1,1074,651.7,1070.3,649.3,1070.6z"/>
						<path class="st3" d="M639.5,1071.1c-2.1,0.3-2,3.2-0.3,3.6c0.2,0.1,0.5,0.1,0.7,0c1-0.1,1.5-0.8,1.5-1.6
							C641.6,1072.1,640.9,1070.9,639.5,1071.1z"/>
						<path class="st3" d="M658.9,1042.6c2.4-0.3,1.9-3.9-0.5-3.7C656.1,1039.3,656.6,1042.9,658.9,1042.6z"/>
						<path class="st3" d="M631.1,1044.7c-2.4,0.3-1.9,3.9,0.5,3.7C633.9,1048,633.5,1044.4,631.1,1044.7z"/>
						<path class="st3" d="M631.1,1064c-2.4,0.3-1.9,3.9,0.5,3.7C633.9,1067.4,633.4,1063.7,631.1,1064z"/>
						<path class="st3" d="M669.7,1053.3c-2.4,0.3-1.9,3.9,0.5,3.7C672.5,1056.7,672.1,1053,669.7,1053.3z"/>
						<path class="st3" d="M624.9,1058.8c2.4-0.3,1.9-3.9-0.5-3.7C622.1,1055.4,622.6,1059.1,624.9,1058.8z"/>
						<path class="st3" d="M632,1036.7c1.4-0.2,1.8-1.5,1.4-2.5h-3.3C629.8,1035.4,630.5,1036.9,632,1036.7z"/>
						<path class="st3" d="M1764.6,1038c1.7-0.8,1.5-2.8,0.4-3.8h-2.9C1760.4,1035.7,1762.2,1039.2,1764.6,1038z"/>
						<path class="st3" d="M1751.3,1038.2c-2.7,1.2-0.7,5.4,1.9,4.1C1755.9,1041,1754,1036.9,1751.3,1038.2z"/>
						<path class="st3" d="M1740.6,1037.2c1.3-0.6,1.5-1.9,1.1-2.9h-4.1C1737,1035.9,1738.6,1038.1,1740.6,1037.2z"/>
						<path class="st3" d="M1801,1036.9c1-0.4,1.9-1.6,1.5-2.7h-4.5c0.1,0.4,0.2,0.8,0.3,1.2
							C1798.7,1036.5,1799.8,1037.3,1801,1036.9z"/>
						<path class="st3" d="M1789.3,1037c1.8,0.4,2.9-1.3,2.6-2.7h-4.1C1787.4,1035.3,1787.9,1036.6,1789.3,1037z"/>
						<path class="st3" d="M1888.8,1058.2c2.7,1.6,5.1-2.6,2.4-4.2C1888.5,1052.4,1886.1,1056.6,1888.8,1058.2z"/>
						<path class="st3" d="M1895.9,1063.6c-2.7-1.6-5.1,2.6-2.4,4.2C1896.1,1069.3,1898.5,1065.1,1895.9,1063.6z"/>
						<path class="st3" d="M1901,1075.7c-2.7-1.6-5.1,2.6-2.4,4.2C1901.3,1081.4,1903.7,1077.2,1901,1075.7z"/>
						<path class="st3" d="M1904.2,1058.5c-2.7-1.6-5.1,2.6-2.4,4.2C1904.5,1064.2,1906.9,1060,1904.2,1058.5z"/>
						<path class="st3" d="M1878.9,1061.8c2.7,1.6,5.1-2.6,2.4-4.2C1878.7,1056.1,1876.3,1060.3,1878.9,1061.8z"/>
						<path class="st3" d="M1910.3,1073.2c0.3-1.3-0.4-2.6-1.7-2.9c-0.6-0.1-1.2-0.3-1.7-0.4c-1.2-0.3-2.7,0.4-2.9,1.7
							c-0.3,1.3,0.4,2.6,1.7,2.9c0.6,0.1,1.2,0.3,1.7,0.4C1908.6,1075.2,1910,1074.4,1910.3,1073.2z"/>
						<path class="st3" d="M1892.2,1074.7c-0.3-1.2-1.7-2.1-2.9-1.7c-1.2,0.4-2.1,1.6-1.7,2.9c0.1,0.2,0.1,0.4,0.2,0.7
							c0.3,1.2,1.7,2.1,2.9,1.7c1.2-0.4,2.1-1.6,1.7-2.9C1892.3,1075.2,1892.2,1075,1892.2,1074.7z"/>
						<path class="st3" d="M1876.3,1072.4c0.3-1.2-0.4-2.7-1.7-2.9c-1.3-0.3-2.6,0.4-2.9,1.7c-0.1,0.6-0.3,1.2-0.4,1.7
							c-0.3,1.2,0.4,2.7,1.7,2.9c1.3,0.3,2.6-0.4,2.9-1.7C1876,1073.6,1876.2,1073,1876.3,1072.4z"/>
						<path class="st3" d="M1903.1,1051.7c0.5-0.3,0.9-0.6,1.4-0.9c1.1-0.7,1.6-2.1,0.9-3.3c-0.7-1.1-2.2-1.6-3.3-0.9
							c-0.5,0.3-0.9,0.6-1.4,0.9c-1.1,0.7-1.6,2.1-0.9,3.3C1900.5,1051.9,1902,1052.5,1903.1,1051.7z"/>
						<path class="st3" d="M1882.4,1080.3c-0.8-0.6-1.6-1.1-2.4-1.7c-1-0.7-2.7-0.2-3.3,0.9c-0.6,1.2-0.2,2.5,0.9,3.3
							c0.8,0.6,1.6,1.1,2.4,1.7c1,0.7,2.7,0.2,3.3-0.9C1884,1082.3,1883.5,1081,1882.4,1080.3z"/>
						<path class="st3" d="M1890.7,1087c-1.2-0.7-2.5-0.2-3.3,0.9c-0.8,1.1-1.2,2.6-1.1,3.9c0.1,1.2,1,2.5,2.4,2.4
							c1.2-0.1,2.5-1.1,2.4-2.4c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4
							C1892.3,1089.3,1891.8,1087.6,1890.7,1087z M1891.3,1090.7c0,0,0,0.1-0.1,0.2C1891.2,1090.9,1891.3,1090.8,1891.3,1090.7z
							M1891.2,1091.1c0,0.1,0,0.2,0,0.2C1891.2,1091.2,1891.2,1091.1,1891.2,1091.1z"/>
						<path class="st3" d="M1899.3,1089.3c-1.2-0.3-1.6-1.3-1.4-2.2c-1.2,0.9-1.7,3,0.1,4.1c2.2,1.3,4.2-1.2,3.4-3.1
							C1901.2,1088.9,1900.4,1089.6,1899.3,1089.3z"/>
						<path class="st3" d="M1878.9,1086.4c-2.7-1.6-5.1,2.6-2.4,4.2C1879.1,1092.1,1881.6,1087.9,1878.9,1086.4z"/>
						<path class="st3" d="M1868.3,1079.1c-2.7-1.6-5.1,2.6-2.4,4.2C1868.6,1084.8,1871,1080.7,1868.3,1079.1z"/>
						<path class="st3" d="M1911,1064.9c2.7,1.6,5.1-2.6,2.4-4.2C1910.8,1059.2,1908.3,1063.3,1911,1064.9z"/>
						<path class="st3" d="M1878.1,1049.1c2.7,1.6,5.1-2.6,2.4-4.2C1877.8,1043.4,1875.4,1047.6,1878.1,1049.1z"/>
						<path class="st3" d="M1865.1,1065c-2.7-1.6-5.1,2.6-2.4,4.2C1865.4,1070.7,1867.8,1066.6,1865.1,1065z"/>
						<path class="st3" d="M1913.8,1087.2c0.4,0.1,0.6,0.2,0.8,0.4c0.5-1,0.4-2.4-0.9-3.1c-2.7-1.6-5.1,2.6-2.4,4.2
							c0.1,0,0.1,0.1,0.2,0.1C1911.8,1087.8,1912.6,1086.9,1913.8,1087.2z"/>
						<path class="st3" d="M1862.9,1054.8c2.7,1.6,5.1-2.6,2.4-4.2C1862.6,1049,1860.2,1053.2,1862.9,1054.8z"/>
						<path class="st3" d="M1887.8,1037.4c2.2,1.3,4.2-1.3,3.4-3.2h-4.2C1886.4,1035.3,1886.5,1036.6,1887.8,1037.4z"/>
						<path class="st3" d="M1920.3,1082.2c-2.4-0.5-3.2,3.1-0.8,3.7C1921.9,1086.4,1922.7,1082.7,1920.3,1082.2z"/>
						<path class="st3" d="M1917.5,1094.4h2.8c-0.2-0.3-0.6-0.5-1-0.6C1918.5,1093.6,1917.8,1093.9,1917.5,1094.4z"/>
						<path class="st3" d="M1926.1,1088.2c-2.4-0.5-3.2,3.1-0.8,3.7C1927.6,1092.5,1928.4,1088.8,1926.1,1088.2z"/>
						<path class="st3" d="M1931.1,1082.5c-2.4-0.5-3.2,3.1-0.8,3.7C1932.7,1086.7,1933.5,1083,1931.1,1082.5z"/>
						<path class="st3" d="M1914.7,1087.6c-0.2-0.2-0.5-0.4-0.8-0.4c-1.3-0.3-2.1,0.6-2.2,1.6c-0.1,0.9,0.3,1.8,1.4,2.1
							C1915,1091.3,1915.9,1088.8,1914.7,1087.6z"/>
						<path class="st3" d="M1937.1,1090.3c-0.5,0-0.9,0-1.4,0.1c-1,0.1-1.9,0.9-1.8,2c0.1,1,0.9,1.9,2,1.8c0.5,0,0.9,0,1.4-0.1
							c1-0.1,1.9-0.9,1.8-2C1939,1091.1,1938.2,1090.2,1937.1,1090.3z"/>
						<path class="st3" d="M1929.5,1074c-0.7-0.6-2-0.7-2.7,0.1c-0.3,0.3-0.6,0.7-0.8,1c-0.6,0.8-0.7,2,0.1,2.7
							c0.7,0.6,2,0.7,2.7-0.1c0.3-0.3,0.6-0.7,0.8-1C1930.2,1076,1930.3,1074.7,1929.5,1074z"/>
						<path class="st3" d="M1938.6,1082c-2.4-0.5-3.2,3.1-0.8,3.7C1940.1,1086.3,1940.9,1082.6,1938.6,1082z"/>
						<path class="st3" d="M1910.3,1077.9c-2.4-0.5-3.2,3.1-0.8,3.7C1911.8,1082.1,1912.6,1078.4,1910.3,1077.9z"/>
						<path class="st3" d="M1899.3,1089.3c1.1,0.3,1.9-0.4,2.1-1.2c0.3-1,0-2.2-1.3-2.5c-1.2-0.3-2,0.5-2.2,1.5
							C1897.7,1088,1898.1,1089,1899.3,1089.3z"/>
					</g>
				</g>
				<g>
					<rect id="XMLID_24_" x="1743.9" y="936.2" class="st2" width="192.3" height="133.7"/>
					<g>
						<path class="st3" d="M1753.2,1017.4c1.2,0.4,2.2-0.1,2.8-1c-0.4-0.8-0.3-1.9,0.3-2.6c-0.2-0.5-0.7-1-1.4-1.2
							C1751.7,1011.6,1750.1,1016.4,1753.2,1017.4z"/>
						<path class="st3" d="M1750.2,1032.7c3.1,1,4.7-3.7,1.6-4.8C1748.7,1026.9,1747.1,1031.7,1750.2,1032.7z"/>
						<path class="st3" d="M1762.5,1025.6c-0.1-0.7,0.1-1.6,0.7-2c0-0.9-0.5-1.8-1.6-2.2c-1.8-0.6-3.1,0.8-3.2,2.3
							c0.7-0.1,1.5,0.1,2.1,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.4,0.4,0.6,0.9,0.7,1.4C1761.9,1026.1,1762.2,1025.9,1762.5,1025.6z"/>
						<path class="st3" d="M1770.4,1033.3c-0.2-0.2-0.5-0.4-0.9-0.5c-2.1-0.7-3.6,1.4-3.2,3.1c0.6,0.3,1.2,0.9,1.3,1.6
							c0.1,0,0.2,0.1,0.3,0.1c1,0.4,1.9,0,2.5-0.6C1768.9,1036.3,1768.8,1033.9,1770.4,1033.3z"/>
						<path class="st3" d="M1768.8,1015.8c-0.2-0.5-0.1-1,0-1.4c-2.9-0.7-4.4,3.8-1.4,4.8c1.6,0.5,2.8-0.5,3.1-1.7
							C1769.8,1017.3,1769.1,1016.7,1768.8,1015.8z"/>
						<path class="st3" d="M1745.5,1018.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0.5-0.5,0.9-0.9,1.2v3.7
							C1747,1024.2,1748.5,1019.5,1745.5,1018.5z"/>
						<path class="st3" d="M1776,1025.6c-0.6,0-1.2-0.1-1.9-0.1c-1.3-0.1-2.6,1-2.7,2.4c0,1.4,1,2.6,2.4,2.7c0.6,0,1.2,0.1,1.9,0.1
							c1.3,0.1,2.6-1,2.7-2.4C1778.4,1026.9,1777.5,1025.6,1776,1025.6z"/>
						<path class="st3" d="M1757.2,1036.3c0,0.4-0.1,0.8-0.4,1.2c0.7,0.5,1.7,0.6,2.5,0.2c1.2-0.7,1.8-2.1,1.1-3.4
							c-0.1-0.2-0.2-0.4-0.3-0.7c-0.6-1.2-2.2-1.8-3.4-1.1c-0.6,0.3-1.1,0.9-1.3,1.5C1756.5,1034.4,1757.1,1035.2,1757.2,1036.3z"/>
						<path class="st3" d="M1764.5,1008.4c0.7,0.1,1.5,0,2-0.6c0.4-0.4,0.8-0.8,1.2-1.2c0.9-0.9,1.2-2.5,0.2-3.6
							c-0.9-0.9-2.6-1.2-3.6-0.2c-0.4,0.4-0.8,0.8-1.2,1.2c-0.2,0.2-0.3,0.4-0.4,0.6C1764.8,1004.7,1765.4,1007,1764.5,1008.4z"/>
						<path class="st3" d="M1752.6,1042.5c-0.3-0.4-0.7-0.8-1.3-1.1c-1-0.4-1.9-0.8-2.9-1.2c-1.2-0.5-2.8,0.4-3.2,1.6
							c-0.4,1.4,0.3,2.6,1.6,3.2c1,0.4,1.9,0.8,2.9,1.2c1.2,0.5,2.8-0.3,3.2-1.5C1752.5,1044,1752.4,1043.2,1752.6,1042.5z"/>
						<path class="st3" d="M1761.3,1046.6c-1.4-0.4-2.6,0.3-3.2,1.6c-0.6,1.3-0.7,2.9-0.2,4.2c0.4,1.3,1.6,2.3,3,2
							c1.2-0.3,2.4-1.6,2-3c0-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.4,0.2-0.4
							C1763.4,1048.6,1762.5,1046.9,1761.3,1046.6z M1762.7,1050.3c0,0.1,0,0.1,0,0.2C1762.6,1050.4,1762.6,1050.4,1762.7,1050.3z
							M1762.6,1050.7c0,0.1,0,0.2,0,0.3C1762.6,1050.8,1762.6,1050.7,1762.6,1050.7z"/>
						<path class="st3" d="M1771.2,1044.5c-3.1-1-4.7,3.7-1.6,4.8C1772.7,1050.3,1774.3,1045.5,1771.2,1044.5z"/>
						<path class="st3" d="M1749,1048.5c-3.1-1-4.7,3.7-1.6,4.8C1750.5,1054.3,1752.1,1049.5,1749,1048.5z"/>
						<path class="st3" d="M1777.4,1019.6c3.1,1,4.7-3.7,1.6-4.8C1775.9,1013.8,1774.3,1018.5,1777.4,1019.6z"/>
						<path class="st3" d="M1784.4,1039.1c-1.9-0.6-3.2,0.9-3.2,2.4c0.6,0.4,0.9,1.2,0.8,1.9c0.2,0.2,0.5,0.4,0.9,0.5
							C1785.9,1044.9,1787.5,1040.2,1784.4,1039.1z"/>
						<path class="st3" d="M1749.3,991.6c-3.1-1-4.7,3.7-1.6,4.8C1750.8,997.4,1752.4,992.6,1749.3,991.6z"/>
						<path class="st3" d="M1782,1014.1C1782,1014.1,1782,1014.1,1782,1014.1c-0.8,1.2-0.1,3.2,1.7,3.1c2.6-0.2,2.2-4.2-0.3-4
							c-0.1,0-0.1,0-0.2,0C1783.1,1013.7,1782.7,1014,1782,1014.1z"/>
						<path class="st3" d="M1786.5,1029.3c2.6-0.2,2.2-4.2-0.3-4c-0.1,0-0.2,0-0.3,0.1c-0.2,0.6-0.7,1.1-1.4,1.4
							C1784.1,1027.9,1784.8,1029.4,1786.5,1029.3z"/>
						<path class="st3" d="M1792.9,1018.3c-0.3-0.5-0.9-0.9-1.8-0.8c-2.6,0.2-2.2,4.2,0.3,4c0.9-0.1,1.5-0.7,1.7-1.3
							c-0.1-0.2-0.2-0.5-0.3-0.7C1792.8,1019,1792.8,1018.6,1792.9,1018.3z"/>
						<path class="st3" d="M1802.5,1024.7c-0.2-0.7-0.7-1.3-1.6-1.4c-0.2,0-0.3,0-0.5,0c-2.5,0.3-2.1,4.2,0.4,4
							c0.2,0,0.4-0.1,0.5-0.1C1800.9,1026.2,1801.2,1024.8,1802.5,1024.7z"/>
						<path class="st3" d="M1794.5,1010c0.1,0.8-0.3,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0c-0.1,1.1,0.6,2.4,2.1,2.3
							C1797.3,1013.8,1797,1009.9,1794.5,1010z"/>
						<path class="st3" d="M1778.5,1020.4c-2.6,0.2-2.2,4.2,0.3,4C1781.4,1024.1,1781.1,1020.2,1778.5,1020.4z"/>
						<path class="st3" d="M1802.8,1020.2c0.1,0,0.2,0,0.2-0.1c0.5-0.2,0.9-0.4,1.4-0.5c1-0.4,1.6-1.6,1.2-2.6
							c-0.1-0.3-0.3-0.5-0.5-0.7c-0.5,0.2-1.2,0.2-1.7-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1,0c-0.5,0.2-0.9,0.4-1.4,0.5
							c-1,0.4-1.6,1.6-1.2,2.6c0.2,0.5,0.6,0.9,1,1.1c0.2,0,0.4,0,0.6,0C1802.3,1020.1,1802.6,1020.1,1802.8,1020.2z"/>
						<path class="st3" d="M1792.8,1026.4c-0.6,0-1.2,0.2-1.6,0.7c-0.7,0.9-0.6,2.1,0.2,2.8c0.1,0.1,0.3,0.2,0.4,0.4
							c0.8,0.7,2.2,0.6,2.8-0.2c0.5-0.6,0.6-1.5,0.3-2.2c-0.1,0.1-0.3,0.1-0.5,0.1C1793.4,1028.1,1792.8,1027.2,1792.8,1026.4z"/>
						<path class="st3" d="M1778.4,1034.2c0.2,0.5,0.4,0.9,0.5,1.4c0.4,1,1.6,1.6,2.6,1.2c1-0.4,1.6-1.5,1.2-2.6
							c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1-1.6-1.6-2.6-1.2C1778.6,1032.1,1778,1033.2,1778.4,1034.2z"/>
						<path class="st3" d="M1787.9,1005.3c-0.5,0.1-0.9-0.1-1.2-0.4c-0.1,0.8,0.3,1.7,1.1,2c1,0.4,2.2,0.1,2.7-0.9
							c0.2-0.4,0.3-0.8,0.5-1.3c0.4-1,0.1-2.2-0.9-2.7c-0.7-0.3-1.5-0.2-2,0.2C1789.7,1002.4,1789.8,1005.1,1787.9,1005.3z"/>
						<path class="st3" d="M1792.2,1037c-0.1-1.1-1-1.8-2.2-1.8c-0.8,0-1.6,0-2.5,0c-1,0-2,1.2-1.8,2.2c0.1,1.1,1,1.8,2.2,1.8
							c0.8,0,1.6,0,2.5,0C1791.4,1039.2,1792.3,1038.1,1792.2,1037z"/>
						<path class="st3" d="M1801.3,1038.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c0-1-1.2-2-2.2-1.8
							c-1.2,0.2-1.8,1-1.8,2.2c0,1.1,0.4,2.3,1.2,3.2c0.7,0.8,1.9,1.2,2.8,0.5c0.8-0.6,1.2-1.9,0.5-2.8
							C1801.5,1038.8,1801.3,1038.7,1801.3,1038.6z M1801.1,1038.1c0,0,0,0.1,0,0.2C1801.1,1038.2,1801.1,1038.2,1801.1,1038.1z
							M1801.3,1038.6c-0.1-0.1-0.1-0.1-0.1-0.2C1801.2,1038.5,1801.2,1038.5,1801.3,1038.6z"/>
						<path class="st3" d="M1806.6,1031.4c0.7,0.9,0.5,2.5-1,2.7c-1.1,0.2-1.7-0.7-1.8-1.5c-0.3,1.2,0.4,2.7,2,2.6
							C1808,1035,1808.1,1032.2,1806.6,1031.4z"/>
						<path class="st3" d="M1790.5,1041.1c-2.6,0.2-2.2,4.2,0.3,4C1793.4,1044.9,1793.1,1040.9,1790.5,1041.1z"/>
						<path class="st3" d="M1781.2,1041.5c-0.3-0.2-0.8-0.4-1.3-0.3c-2.6,0.2-2.2,4.2,0.3,4c1.1-0.1,1.7-0.9,1.7-1.7
							C1782,1042.7,1781.8,1041.9,1781.2,1041.5z"/>
						<path class="st3" d="M1801.5,1007.2c-2.2,0.5-1.9,4.1,0.6,3.9c1.3-0.1,1.8-1.1,1.8-2.1c-0.2,0.2-0.5,0.3-0.8,0.3
							C1801.8,1009.4,1801.2,1008.1,1801.5,1007.2z"/>
						<path class="st3" d="M1772.9,1012.3c-0.3-0.2-0.6-0.2-1.1-0.2c-0.6,0.1-1.1,0.3-1.4,0.8c0.6-0.2,1.1-0.3,1.7-0.5
							C1772.4,1012.3,1772.7,1012.3,1772.9,1012.3z"/>
						<path class="st3" d="M1771.2,1033.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.2c-1.5,0.7-1.4,3,0,3.7c0.3,0.1,0.6,0.2,1,0.2
							c1.1-0.1,1.6-0.8,1.7-1.6C1771.8,1035.3,1771.2,1034.2,1771.2,1033.1z"/>
						<path class="st3" d="M1813.4,1023.1c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0.6-0.4,1.1-1,1.3c-0.3,1.2,0.5,2.7,2,2.5
							C1816.3,1026.9,1816,1022.9,1813.4,1023.1z"/>
						<path class="st3" d="M1762.5,1025.6c0.2,0.9,0.9,1.7,2.1,1.6c2.6-0.2,2.2-4.2-0.3-4c-0.4,0-0.7,0.2-1,0.4
							C1762.6,1024.1,1762.4,1024.9,1762.5,1025.6z"/>
						<path class="st3" d="M1773.7,1003.4c1.9-0.7,1.5-4.1-0.9-3.9c-1.4,0.1-1.9,1.3-1.7,2.4c0.1,0,0.3,0,0.5,0
							C1772.7,1001.9,1773.5,1002.6,1773.7,1003.4z"/>
						<path class="st3" d="M1820.5,1021.8c-2.1,0.3-1.6,3.6,0.5,3.3C1823.1,1024.7,1822.6,1021.5,1820.5,1021.8z"/>
						<path class="st3" d="M1824.5,1034.5c1.1-0.9,0.5-3.2-1.3-2.9c-1.9,0.3-1.7,3.1,0,3.3c0.3-0.2,0.7-0.4,1.2-0.4
							C1824.4,1034.5,1824.5,1034.5,1824.5,1034.5z"/>
						<path class="st3" d="M1827.1,1024.9c-2.1,0.3-1.6,3.6,0.5,3.3C1829.6,1027.9,1829.2,1024.6,1827.1,1024.9z"/>
						<path class="st3" d="M1836.3,1030.9c-0.5-0.4-0.7-1-0.6-1.6c-0.2-0.1-0.4-0.1-0.7,0c-2.1,0.3-1.6,3.6,0.5,3.3
							c0.3,0,0.5-0.1,0.6-0.2C1835.9,1031.8,1836,1031.3,1836.3,1030.9z"/>
						<path class="st3" d="M1828.1,1020.8c0.2,0.7,0.8,1.3,1.7,1.1c2.1-0.3,1.6-3.6-0.5-3.3c0,0-0.1,0-0.1,0
							C1829.5,1019.5,1829.1,1020.6,1828.1,1020.8z"/>
						<path class="st3" d="M1816.8,1027.9c-2.1,0.3-1.6,3.6,0.5,3.3C1819.3,1030.9,1818.9,1027.6,1816.8,1027.9z"/>
						<path class="st3" d="M1836.6,1023.2c-0.3,0.1-0.7,0.3-1,0.4c-0.8,0.3-1.3,1.4-0.9,2.2c0.4,0.8,1.3,1.2,2.2,0.9
							c0.4-0.2,0.7-0.3,1.1-0.5c0.8-0.3,1.3-1.4,0.9-2.2c-0.2-0.5-0.6-0.8-1.1-0.9c-0.2,0.1-0.5,0.2-0.9,0.2
							C1836.8,1023.2,1836.7,1023.2,1836.6,1023.2z"/>
						<path class="st3" d="M1827.8,1035.1c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3c0.5-0.7,0.4-1.8-0.3-2.3
							c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3C1827,1033.6,1827.1,1034.6,1827.8,1035.1z"/>
						<path class="st3" d="M1820.3,1038c-0.3-0.8-1.4-1.3-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
							c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C1820.6,1038.7,1820.5,1038.3,1820.3,1038z"/>
						<path class="st3" d="M1823.8,1016.4c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
							c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1C1822.6,1015.1,1822.9,1016.1,1823.8,1016.4z"/>
						<path class="st3" d="M1828.8,1041c-0.2-0.9-0.9-1.5-1.9-1.4c-0.7,0-1.3,0.1-2,0.1c-0.9,0.1-1.6,1-1.4,1.9
							c0.2,0.9,0.9,1.5,1.9,1.4c0.7,0,1.3-0.1,2-0.1C1828.2,1042.8,1828.9,1041.8,1828.8,1041z"/>
						<path class="st3" d="M1835.3,1041.8c-0.8-0.5-0.9-1.4-0.6-2.1c-0.2,0-0.3,0-0.5,0c-0.9,0.2-1.4,0.9-1.4,1.9
							c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3C1836.1,1042.1,1835.7,1042.1,1835.3,1041.8z"/>
						<path class="st3" d="M1839.4,1035.6c-1.6,0.2-1.7,2.3-0.7,3c0.3,0,0.6,0.1,0.8,0.1c0.1,0,0.3,0.1,0.4,0.1
							C1842,1038.4,1841.5,1035.3,1839.4,1035.6z"/>
						<path class="st3" d="M1827.6,1044.4c-2.1,0.3-1.6,3.6,0.5,3.3C1830.1,1047.4,1829.7,1044.1,1827.6,1044.4z"/>
						<path class="st3" d="M1818.8,1044.9c-2.1,0.3-1.6,3.6,0.5,3.3C1821.4,1047.9,1820.9,1044.6,1818.8,1044.9z"/>
						<path class="st3" d="M1835.8,1019.2c2.1-0.3,1.6-3.6-0.5-3.3C1833.2,1016.2,1833.7,1019.5,1835.8,1019.2z"/>
						<path class="st3" d="M1810.8,1022.5c0,0,0,0.1,0.1,0.1C1810.8,1022.6,1810.8,1022.5,1810.8,1022.5z"/>
						<path class="st3" d="M1810.7,1022.2c0,0.1,0,0.1,0,0.1C1810.7,1022.3,1810.7,1022.3,1810.7,1022.2z"/>
						<path class="st3" d="M1812.7,1023.3c0.1-1-0.5-2.1-1.8-1.9c-0.1,0-0.2,0-0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3
							c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.5,0.5,0.3,1.3,0,1.8c0.1,0,0.2,0,0.4,0
							c0.1,0,0.2,0,0.3-0.1C1812.3,1024.4,1812.7,1023.9,1812.7,1023.3z"/>
						<path class="st3" d="M1811.2,1038.7c-2.1,0.3-1.6,3.6,0.5,3.3C1813.7,1041.7,1813.3,1038.4,1811.2,1038.7z"/>
						<path class="st3" d="M1846,1031.9c2.1-0.3,1.6-3.6-0.5-3.3C1843.5,1028.9,1843.9,1032.2,1846,1031.9z"/>
						<path class="st3" d="M1805.6,1034.1c1.5-0.2,1.7-1.8,1-2.7c-0.3-0.4-0.8-0.6-1.4-0.5c-1,0.1-1.4,1-1.4,1.7
							C1803.8,1033.5,1804.5,1034.3,1805.6,1034.1z"/>
						<path class="st3" d="M1810.4,1013.7c-0.1,0-0.1,0-0.2,0c0.3,0.4,0.8,0.6,1.4,0.5c2.1-0.3,1.7-3.4-0.4-3.3
							C1812.1,1011.8,1811.9,1013.6,1810.4,1013.7z"/>
						<path class="st3" d="M1855.6,1035.2c1.9,1.3,4-1.6,2.1-3C1855.8,1030.9,1853.7,1033.8,1855.6,1035.2z"/>
						<path class="st3" d="M1852.5,1042.2c-1.9-1.3-4,1.6-2.1,3C1852.3,1046.5,1854.4,1043.5,1852.5,1042.2z"/>
						<path class="st3" d="M1860.6,1039.7c-1.9-1.3-4,1.6-2.1,3C1860.4,1044,1862.5,1041,1860.6,1039.7z"/>
						<path class="st3" d="M1863.7,1049.1c-1.9-1.3-4,1.6-2.1,3c1.2,0.8,2.4,0,2.7-1c0,0,0-0.1,0-0.1
							C1864.5,1050.3,1864.4,1049.6,1863.7,1049.1z"/>
						<path class="st3" d="M1867.2,1036.4c-1.9-1.3-4,1.6-2.1,3C1867,1040.7,1869.1,1037.8,1867.2,1036.4z"/>
						<path class="st3" d="M1848,1037.3c1.9,1.3,4-1.6,2.1-3C1848.2,1033,1846.1,1036,1848,1037.3z"/>
						<path class="st3" d="M1867.2,1048.5c0.4,0.1,0.8,0.3,1.3,0.4c0.9,0.3,2-0.2,2.3-1.1c0.3-0.9-0.1-2-1.1-2.3
							c-0.4-0.1-0.8-0.3-1.3-0.4c-0.9-0.3-2,0.2-2.3,1.1C1865.8,1047.1,1866.3,1048.2,1867.2,1048.5z"/>
						<path class="st3" d="M1857.2,1048.4c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.1-1.7-2.1-1.5c-1,0.2-1.7,1.1-1.5,2.1
							c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.4c0.2-0.2,0.5-0.3,0.9-0.3c1-0.1,1.6,0.4,1.9,1
							C1857,1049.7,1857.3,1049.1,1857.2,1048.4z"/>
						<path class="st3" d="M1844.9,1046.4c0.1-0.4,0.3-0.8,0.4-1.3c0.1-0.2,0.1-0.3,0.1-0.5c-0.2-0.4-0.4-0.9-0.5-1.3
							c-0.2-0.2-0.4-0.4-0.7-0.5c-0.9-0.3-2,0.1-2.3,1.1c-0.1,0.4-0.3,0.8-0.4,1.3c-0.3,0.9,0.2,2,1.1,2.3
							C1843.5,1047.8,1844.6,1047.3,1844.9,1046.4z"/>
						<path class="st3" d="M1866.8,1031.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.4-1.5,0.9-2.4c-0.4-0.8-1.5-1.4-2.4-0.9
							c-0.4,0.2-0.7,0.4-1.1,0.6c-0.8,0.4-1.4,1.5-0.9,2.4C1864.8,1031.2,1865.9,1031.8,1866.8,1031.3z"/>
						<path class="st3" d="M1849.4,1051.4c-0.6-0.5-1.1-1-1.7-1.4c-0.7-0.6-2-0.3-2.5,0.4c-0.6,0.9-0.3,1.9,0.4,2.5
							c0.6,0.5,1.1,1,1.7,1.4c0.7,0.6,2,0.3,2.5-0.4C1850.4,1053,1850.2,1052,1849.4,1051.4z"/>
						<path class="st3" d="M1853,1059.5c-0.5-0.3-0.8-0.6-0.9-1.1c-0.3,0.6-0.4,1.2-0.4,1.9c0,0.9,0.6,1.9,1.7,2c0.9,0,2-0.6,2-1.7
							c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.4-0.4,0.4-1,0.2-1.6
							C1855.7,1059.2,1854.4,1060.2,1853,1059.5z M1855.3,1060.3c0-0.1,0-0.1,0-0.2C1855.3,1060.1,1855.3,1060.2,1855.3,1060.3z
							M1855.3,1060c0,0,0-0.1,0.1-0.2C1855.4,1059.8,1855.4,1059.9,1855.3,1060z"/>
						<path class="st3" d="M1859.8,1058.5c-0.3,0.7-0.2,1.5,0.6,2.1c0.6,0.4,1.3,0.4,1.8,0.1c-0.1,0-0.2,0.1-0.3,0.1
							C1860.4,1060.9,1859.7,1059.6,1859.8,1058.5z"/>
						<path class="st3" d="M1846.4,1055.7c-1.9-1.3-4,1.6-2.1,3C1846.2,1060.1,1848.3,1057.1,1846.4,1055.7z"/>
						<path class="st3" d="M1838.9,1049.6c-1.9-1.3-4,1.6-2.1,3C1838.7,1053.9,1840.8,1050.9,1838.9,1049.6z"/>
						<path class="st3" d="M1873,1040.8c0.4-0.3,1-0.5,1.6-0.3c0.2-0.6,0-1.4-0.7-1.8c-0.6-0.4-1.2-0.4-1.7-0.2
							C1873,1039,1873.3,1040,1873,1040.8z"/>
						<path class="st3" d="M1848.2,1027.7c0.8,0.6,1.7,0.3,2.3-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.6,0.1-1.2,0.6-1.6
							c-0.1-0.2-0.3-0.4-0.6-0.6C1848.4,1023.4,1846.3,1026.4,1848.2,1027.7z"/>
						<path class="st3" d="M1835.8,1039.9c0-0.6,0.5-1.1,1-1.4c-0.9-0.2-1.8,0.4-2.1,1.2c-0.3,0.7-0.2,1.5,0.6,2.1
							c0.4,0.3,0.8,0.4,1.2,0.3c0.3,0,0.6-0.2,0.9-0.4c0,0-0.1,0-0.1,0C1836.4,1041.5,1835.8,1040.9,1835.8,1039.9z"/>
						<path class="st3" d="M1872.3,1056.4c-1.8-0.8-3.5,1.9-1.7,3.2c1.6,1.2,3.4-0.9,2.7-2.3
							C1872.9,1057.1,1872.5,1056.8,1872.3,1056.4z"/>
						<path class="st3" d="M1838.5,1028c-1.3-0.9-2.6,0.1-2.8,1.3c-0.1,0.5,0.1,1.1,0.6,1.6c0.3-0.5,0.8-0.8,1.5-0.7
							c0.5,0,0.8,0.2,1,0.5C1839.4,1029.8,1839.5,1028.7,1838.5,1028z"/>
						<path class="st3" d="M1856.2,1019.5c1.9,1.3,4-1.6,2.1-3C1856.4,1015.2,1854.3,1018.2,1856.2,1019.5z"/>
						<path class="st3" d="M1874.7,1061.5c2.3,1.2,4.2-2.3,1.9-3.5C1874.3,1056.7,1872.4,1060.3,1874.7,1061.5z"/>
						<path class="st3" d="M1872.2,1069.6c-0.9-0.5-1.7-0.2-2.3,0.3h2.7C1872.6,1069.8,1872.4,1069.7,1872.2,1069.6z"/>
						<path class="st3" d="M1880.7,1065.7c-2.3-1.2-4.2,2.3-1.9,3.5C1881.1,1070.5,1883,1067,1880.7,1065.7z"/>
						<path class="st3" d="M1887.5,1061.2c-2.3-1.2-4.2,2.3-1.9,3.5C1887.9,1066,1889.8,1062.5,1887.5,1061.2z"/>
						<path class="st3" d="M1868.7,1061.4c-0.1,0-0.1-0.1-0.2-0.1c-2.3-1.2-4.2,2.3-1.9,3.5c0.6,0.3,1.2,0.3,1.7,0.1
							C1867.1,1064.1,1867.1,1062,1868.7,1061.4z"/>
						<path class="st3" d="M1887.8,1052.5c0.5,1-0.1,2.2-1.1,2.6c-0.4,0.2-0.9,0.4-1.3,0.6c-0.3,0.1-0.6,0.2-0.9,0.1
							c0.6,0.3,1.3,0.3,1.8-0.1c0.4-0.3,0.8-0.5,1.1-0.8c0.9-0.6,1.3-1.8,0.7-2.8c-0.5-0.8-1.6-1.2-2.5-0.8
							C1886.5,1051.2,1887.4,1051.7,1887.8,1052.5z"/>
						<path class="st3" d="M1895.3,1062.8c-2.3-1.2-4.2,2.3-1.9,3.5C1895.7,1067.6,1897.6,1064,1895.3,1062.8z"/>
						<path class="st3" d="M1868.1,1051.4c0.1,1-0.4,2-1.6,2.1c-1,0.1-1.7-0.4-2-1.1c-0.1,0.7,0.2,1.5,1,1.9
							C1867.5,1055.3,1869.1,1052.9,1868.1,1051.4z"/>
						<path class="st3" d="M1855.2,1068c-1.6-0.8-3,0.6-2.9,2h1.5c0.2-0.1,0.5-0.2,0.8-0.3c0.5-0.1,0.9,0,1.3,0.3h0.4
							C1856.4,1069.2,1856.1,1068.4,1855.2,1068z"/>
						<path class="st3" d="M1853,1059.5c1.4,0.8,2.7-0.3,2.9-1.6c0.1-0.7-0.1-1.5-1-2c-1.8-0.9-3.3,1-2.8,2.5
							C1852.2,1058.9,1852.5,1059.2,1853,1059.5z"/>
						<path class="st3" d="M1873,1040.8c0,0,0,0.1,0,0.1c-0.2,0.4-0.3,0.8-0.5,1.3c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0.5,0.4,1.1,1,1.4
							c2.3,1.2,4.2-2.3,1.9-3.5c-0.1-0.1-0.2-0.1-0.4-0.2C1874.1,1040.3,1873.5,1040.5,1873,1040.8z"/>
						<path class="st3" d="M1836.6,1023.2c0.1,0,0.2,0,0.3,0.1c0.3,0,0.6,0,0.9-0.2c1.2-0.7,1.1-3-0.6-3.1
							C1835.2,1019.8,1834.8,1022.7,1836.6,1023.2z"/>
						<path class="st3" d="M1837.7,1030.1c-0.7-0.1-1.2,0.3-1.5,0.7c-0.2,0.4-0.3,1-0.2,1.4c0.2,0.6,0.6,1,1.4,1.1
							c1.7,0.1,2.2-1.9,1.3-2.8C1838.5,1030.3,1838.2,1030.1,1837.7,1030.1z"/>
						<path class="st3" d="M1842.6,1027.7c2.1,0.2,2.4-3.1,0.3-3.3C1840.8,1024.3,1840.5,1027.6,1842.6,1027.7z"/>
						<path class="st3" d="M1849.7,1030.4c-2.1-0.2-2.4,3.1-0.3,3.3C1851.6,1033.8,1851.8,1030.5,1849.7,1030.4z"/>
						<path class="st3" d="M1846.3,1022.1c2.1,0.2,2.4-3.1,0.3-3.3C1844.4,1018.6,1844.2,1021.9,1846.3,1022.1z"/>
						<path class="st3" d="M1832,1028.4c2.1,0.2,2.4-3.1,0.3-3.3C1830.1,1025,1829.8,1028.2,1832,1028.4z"/>
						<path class="st3" d="M1850.2,1026.9c0,0.2,0.1,0.4,0.2,0.5c0.4,0.6,1,0.9,1.7,0.8c0.4-0.1,0.8-0.2,1.2-0.2
							c0.8-0.2,1.5-1.1,1.3-1.9c-0.2-0.9-1-1.5-1.9-1.3c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0.1-0.5,0.2-0.7,0.3
							C1850.4,1025.7,1850.1,1026.3,1850.2,1026.9z"/>
						<path class="st3" d="M1841.4,1034.6c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.7,1.7,0.8,2.3,0.2c0.7-0.6,0.8-1.6,0.2-2.3
							c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2C1841,1032.9,1840.8,1033.9,1841.4,1034.6z"/>
						<path class="st3" d="M1833.7,1036.9c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9
							c0.1,0.4,0.2,0.8,0.2,1.2c0.2,0.8,1.1,1.5,1.9,1.3C1833.3,1038.6,1833.9,1037.8,1833.7,1036.9z"/>
						<path class="st3" d="M1841.5,1015.4c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1c0.5-0.7,0.4-1.8-0.4-2.3
							c-0.7-0.5-1.8-0.4-2.3,0.4c-0.2,0.3-0.4,0.6-0.6,1C1840.7,1013.8,1840.8,1014.9,1841.5,1015.4z"/>
						<path class="st3" d="M1838.7,1038.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3,0-0.5,0-0.8,0.1c-0.5,0.2-1,0.8-1,1.4c0,1,0.6,1.6,1.5,1.8
							c0,0,0.1,0,0.1,0c0.6,0.1,1.3,0.2,1.9,0.3c0.9,0.1,1.7-0.7,1.8-1.5c0-0.8-0.4-1.4-1.1-1.7c-0.1,0-0.3-0.1-0.4-0.1
							C1839.3,1038.7,1839,1038.6,1838.7,1038.6z"/>
						<path class="st3" d="M1845.5,1044.7c0.4,0.7,1.4,1.2,2.2,0.8c0.7-0.4,1.2-1.4,0.8-2.2c0-0.1-0.1-0.2-0.2-0.3
							c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.4c0.1-0.9-0.7-1.7-1.5-1.8c-1,0-1.6,0.6-1.8,1.5
							c-0.1,0.4-0.1,0.9,0,1.3C1845,1043.7,1845.2,1044.2,1845.5,1044.7C1845.4,1044.6,1845.4,1044.6,1845.5,1044.7z M1848.3,1043
							c0-0.1-0.1-0.1-0.1-0.2C1848.2,1042.9,1848.2,1042.9,1848.3,1043z M1848.2,1042.5c0,0,0,0.1,0,0.2
							C1848.2,1042.7,1848.2,1042.6,1848.2,1042.5z"/>
						<path class="st3" d="M1852.4,1040.8c0.7,0.1,1.2-0.3,1.5-0.8c-0.5-0.6-0.7-1.5-0.4-2.2c-0.2-0.2-0.5-0.3-0.8-0.3
							C1850.6,1037.4,1850.3,1040.6,1852.4,1040.8z"/>
						<path class="st3" d="M1839.2,1043.6c-2.1-0.2-2.4,3.1-0.3,3.3C1841.1,1047,1841.3,1043.7,1839.2,1043.6z"/>
						<path class="st3" d="M1830.5,1042.2c-2.1-0.2-2.4,3.1-0.3,3.3C1832.4,1045.7,1832.7,1042.4,1830.5,1042.2z"/>
						<path class="st3" d="M1852.6,1020.7c2.1,0.2,2.4-3.1,0.3-3.3C1850.8,1017.3,1850.5,1020.6,1852.6,1020.7z"/>
						<path class="st3" d="M1827.6,1020.8c0.2,0,0.3,0,0.5,0c1-0.2,1.4-1.2,1.2-2.1c-0.2-0.6-0.6-1.1-1.4-1.1
							C1825.8,1017.4,1825.5,1020.6,1827.6,1020.8z"/>
						<path class="st3" d="M1824.5,1034.5c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.1-1.2,0.4c-0.9,0.8-0.7,2.8,0.9,2.9
							C1826.2,1037.9,1826.5,1034.8,1824.5,1034.5z"/>
						<path class="st3" d="M1859.9,1035.3c2.1,0.2,2.4-3.1,0.3-3.3C1858.1,1031.9,1857.8,1035.1,1859.9,1035.3z"/>
						<path class="st3" d="M1820.2,1025.5c-2.1-0.2-2.4,3.1-0.3,3.3C1822,1029,1822.3,1025.7,1820.2,1025.5z"/>
						<path class="st3" d="M1830.1,1010.7c2.1,0.2,2.4-3.1,0.3-3.3C1828.3,1007.3,1828,1010.5,1830.1,1010.7z"/>
						<path class="st3" d="M1796.7,1002.9c-2,0.2-1.7,3.3,0.3,3.1C1799,1005.9,1798.7,1002.7,1796.7,1002.9z"/>
						<path class="st3" d="M1799.2,1015.5c2-0.2,1.7-3.3-0.3-3.1C1796.9,1012.5,1797.2,1015.7,1799.2,1015.5z"/>
						<path class="st3" d="M1801.5,1007.2c-0.3,1,0.3,2.3,1.6,2.1c0.3,0,0.6-0.2,0.8-0.3c1-0.8,0.6-3-1.1-2.8
							C1802.1,1006.3,1801.7,1006.7,1801.5,1007.2z"/>
						<path class="st3" d="M1810.2,1013.7c0.1,0,0.1,0,0.2,0c1.5-0.1,1.7-1.9,0.9-2.7c-0.3-0.3-0.7-0.4-1.2-0.4
							C1808.2,1010.8,1808.4,1013.7,1810.2,1013.7z"/>
						<path class="st3" d="M1805.5,1003.4c2-0.2,1.7-3.3-0.3-3.1C1803.2,1000.5,1803.5,1003.6,1805.5,1003.4z"/>
						<path class="st3" d="M1794.5,1010c-0.1-0.8-0.7-1.5-1.7-1.4c-1.8,0.2-1.7,2.8-0.2,3.1c0.2,0,0.3,0,0.5,0
							C1794.2,1011.6,1794.6,1010.8,1794.5,1010z"/>
						<path class="st3" d="M1810.9,1005.3c-0.8,0.3-1.2,1.2-0.9,2c0.3,0.8,1.2,1.2,2,0.9c0.4-0.1,0.7-0.3,1.1-0.4
							c0.8-0.3,1.2-1.2,0.9-2c-0.3-0.8-1.2-1.2-2-0.9C1811.6,1005,1811.3,1005.1,1810.9,1005.3z"/>
						<path class="st3" d="M1803.4,1016.2c0.5,0.4,1.1,0.4,1.7,0.2c0.2-0.1,0.4-0.2,0.5-0.4c0.5-0.7,0.5-1.6-0.2-2.2
							c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.5-1.7-0.5-2.2,0.2c-0.5,0.7-0.5,1.6,0.2,2.2C1803.2,1016,1803.3,1016.1,1803.4,1016.2z"/>
						<path class="st3" d="M1795.8,1018.3c-0.3-0.8-1.2-1.2-2-0.9c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.3-0.2,0.7,0,1.1
							c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.8,1.2,1.2,2,0.9c0.8-0.3,1.2-1.2,0.9-2
							C1796.1,1019,1796,1018.7,1795.8,1018.3z"/>
						<path class="st3" d="M1800,998c0.7,0.3,1.8,0,2.1-0.8c0.1-0.3,0.3-0.7,0.4-1c0.3-0.8,0-1.7-0.8-2.1c-0.7-0.3-1.8,0-2.1,0.8
							c-0.1,0.3-0.3,0.7-0.4,1C1798.9,996.7,1799.2,997.6,1800,998z"/>
						<path class="st3" d="M1800.9,1023.2c0.5,0,0.9,0,1.4,0c0.8,0,1.5-0.9,1.4-1.7c-0.1-0.6-0.5-1-1-1.2c-0.2-0.1-0.5-0.2-0.7-0.2
							c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0-1.4,0c-0.8,0-1.5,0.9-1.4,1.7c0.1,0.9,0.8,1.4,1.7,1.4
							C1800.6,1023.2,1800.8,1023.2,1800.9,1023.2z"/>
						<path class="st3" d="M1811,1022.8c0,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.3,0-0.3
							c0-0.1,0-0.3-0.1-0.4c-0.2-0.6-1-1.1-1.6-1c-0.9,0.1-1.4,0.8-1.4,1.7c0,0.9,0.4,1.8,0.9,2.5c0.5,0.6,1.5,0.9,2.2,0.3
							c0.1-0.1,0.2-0.2,0.3-0.4C1811.4,1024.1,1811.5,1023.4,1811,1022.8z M1810.8,1022.6c0-0.1-0.1-0.1-0.1-0.1
							C1810.8,1022.5,1810.8,1022.6,1810.8,1022.6z M1810.7,1022.4c0,0,0-0.1,0-0.1C1810.7,1022.3,1810.7,1022.3,1810.7,1022.4z"/>
						<path class="st3" d="M1814.4,1019.9c2-0.2,1.7-3.3-0.3-3.1C1812.1,1017,1812.4,1020.1,1814.4,1019.9z"/>
						<path class="st3" d="M1802.5,1024.7c-1.4,0.1-1.7,1.5-1.1,2.4c0.3,0.4,0.7,0.8,1.4,0.7
							C1804.8,1027.6,1804.5,1024.5,1802.5,1024.7z"/>
						<path class="st3" d="M1794.2,1024.8c-0.9,0.1-1.4,0.8-1.4,1.5c0,0.8,0.6,1.7,1.7,1.6c0.2,0,0.3-0.1,0.5-0.1
							C1796.4,1027.2,1796,1024.7,1794.2,1024.8z"/>
						<path class="st3" d="M1811.3,1001.1c2-0.2,1.7-3.3-0.3-3.1C1808.9,998.2,1809.2,1001.3,1811.3,1001.1z"/>
						<path class="st3" d="M1787.9,1005.3c1.9-0.2,1.7-2.9,0.1-3.1c-0.1,0-0.2,0-0.4,0c-1.5,0.1-1.7,1.8-0.9,2.7
							C1787,1005.2,1787.4,1005.4,1787.9,1005.3z"/>
						<path class="st3" d="M1787.4,1021.7c2-0.2,1.7-3.3-0.3-3.1C1785.1,1018.8,1785.4,1021.9,1787.4,1021.7z"/>
						<path class="st3" d="M1820.5,1013.5c2-0.2,1.7-3.3-0.3-3.1C1818.2,1010.6,1818.5,1013.7,1820.5,1013.5z"/>
						<path class="st3" d="M1782,1014.1C1782,1014.1,1782,1014.1,1782,1014.1c0.7-0.1,1.1-0.4,1.3-0.9c0.4-1-0.2-2.4-1.5-2.2
							C1779.8,1011.2,1780,1014.2,1782,1014.1z"/>
						<path class="st3" d="M1788.6,995.5c2-0.2,1.7-3.3-0.3-3.1C1786.3,992.6,1786.6,995.7,1788.6,995.5z"/>
						<path class="st3" d="M1749.3,1012c3.1,0,3.1-4.7,0-4.8C1746.2,1007.2,1746.2,1011.9,1749.3,1012z"/>
						<path class="st3" d="M1751.2,1026.5c3.1,0,3.1-4.7,0-4.8C1748.1,1021.8,1748.1,1026.5,1751.2,1026.5z"/>
						<path class="st3" d="M1755.9,1016.5c0.3,0.7,1,1.3,2.1,1.3c3.1,0,3.1-4.7,0-4.8c-0.8,0-1.4,0.4-1.8,0.9
							C1755.7,1014.6,1755.6,1015.6,1755.9,1016.5z"/>
						<path class="st3" d="M1768.4,1025.6c3.1,0,3.1-4.7,0-4.8C1765.4,1020.8,1765.4,1025.6,1768.4,1025.6z"/>
						<path class="st3" d="M1762.6,1009.3c0.9,0,1.5-0.4,1.9-0.9c0.9-1.4,0.4-3.7-1.7-3.8c0,0-0.1,0-0.1,0
							C1759.6,1004.5,1759.5,1009.3,1762.6,1009.3z"/>
						<path class="st3" d="M1744.9,1018.4c0.3-1,0-2.2-0.9-2.8v4.1C1744.4,1019.3,1744.7,1018.9,1744.9,1018.4z"/>
						<path class="st3" d="M1772.9,1012.3c-0.3,0-0.5,0-0.8,0.1c-0.6,0.2-1.1,0.3-1.7,0.5c-0.7,0.2-1.4,0.8-1.6,1.5
							c-0.2,0.4-0.2,0.9,0,1.4c0.3,0.9,1,1.5,1.8,1.7c0.4,0.1,0.7,0.1,1.1,0c0.6-0.2,1.1-0.3,1.7-0.5c1.2-0.3,2.1-1.7,1.7-2.9
							C1774.8,1013.1,1773.9,1012.4,1772.9,1012.3z"/>
						<path class="st3" d="M1760.4,1024.4c-0.5-0.5-1.3-0.8-2.1-0.7c-0.5,0.1-1,0.3-1.3,0.7c-0.9,0.9-0.9,2.4,0,3.4
							c0.2,0.2,0.3,0.3,0.5,0.5c0.9,0.9,2.5,0.9,3.4,0c0.5-0.6,0.7-1.3,0.7-2c-0.1-0.5-0.3-1-0.7-1.4
							C1760.7,1024.7,1760.6,1024.6,1760.4,1024.4z"/>
						<path class="st3" d="M1744.5,1034.9c1.2-0.4,2-1.6,1.7-2.9c-0.2-0.6-0.3-1.1-0.5-1.7c-0.2-0.8-0.9-1.5-1.8-1.7v6.4
							C1744.1,1035,1744.3,1035,1744.5,1034.9z"/>
						<path class="st3" d="M1755.1,1000.2c1.1,0.6,2.6,0.3,3.3-0.8c0.2-0.5,0.5-1,0.7-1.4c0.6-1.1,0.3-2.6-0.8-3.3
							c-1.1-0.6-2.6-0.3-3.3,0.8c-0.2,0.5-0.5,1-0.7,1.4C1753.6,998.1,1753.9,999.6,1755.1,1000.2z"/>
						<path class="st3" d="M1751.9,1038.5c1,0.1,1.9,0.2,2.9,0.2c0.8,0.1,1.6-0.5,2-1.2c0.2-0.4,0.4-0.8,0.4-1.2
							c0-1.1-0.7-1.9-1.7-2.2c-0.2-0.1-0.5-0.1-0.7-0.2c-1-0.1-1.9-0.2-2.9-0.2c-1.2-0.1-2.4,1.2-2.4,2.4
							C1749.6,1037.4,1750.6,1038.3,1751.9,1038.5z"/>
						<path class="st3" d="M1766.3,1035.8c-0.3-0.2-0.7-0.3-1-0.2c-1.4,0.1-2.3,1-2.4,2.4c-0.1,1.3,0.3,2.8,1,3.9
							c0.7,1,2.1,1.6,3.2,0.9c1-0.6,1.6-2.2,0.9-3.2c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3
							c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6C1767.4,1036.8,1766.9,1036.2,1766.3,1035.8z M1767.7,1039.1
							c-0.1-0.1-0.1-0.2-0.1-0.2C1767.7,1038.9,1767.7,1039,1767.7,1039.1z M1767.6,1038.7c0,0,0-0.1,0-0.2
							C1767.6,1038.5,1767.6,1038.6,1767.6,1038.7z"/>
						<path class="st3" d="M1773.5,1030.8c-1.5,0-2.3,1.2-2.3,2.4c0,1.1,0.6,2.1,1.9,2.3c0.1,0,0.3,0,0.4,0
							C1776.6,1035.5,1776.6,1030.8,1773.5,1030.8z"/>
						<path class="st3" d="M1754.8,1040.9c-1.2,0-1.9,0.7-2.2,1.5c-0.2,0.7-0.1,1.6,0.3,2.2c0.4,0.6,1,1,1.9,1
							C1757.9,1045.7,1757.9,1040.9,1754.8,1040.9z"/>
						<path class="st3" d="M1743.9,1040.7v3.2C1744.7,1043,1744.7,1041.6,1743.9,1040.7z"/>
						<path class="st3" d="M1771.6,1006.6c1.9,0,2.6-1.8,2.2-3.2c-0.3-0.8-1-1.5-2.2-1.5c-0.2,0-0.3,0-0.5,0
							C1768.6,1002.4,1768.7,1006.6,1771.6,1006.6z"/>
						<path class="st3" d="M1785.8,1025.3c0.5-1.4-0.2-3.3-2.1-3.3c-3.1,0-3.1,4.7,0,4.8c0.3,0,0.5,0,0.7-0.1
							C1785.1,1026.5,1785.6,1025.9,1785.8,1025.3z"/>
						<path class="st3" d="M1866.5,1053.5c1.2-0.2,1.7-1.2,1.6-2.1c-0.1-1-0.8-2-2.1-1.8c-0.9,0.1-1.4,0.7-1.6,1.3c0,0,0,0.1,0,0.1
							c-0.1,0.4,0,0.9,0.1,1.3C1864.8,1053.1,1865.5,1053.6,1866.5,1053.5z"/>
						<path class="st3" d="M1869.1,1061.3c-0.2,0-0.3,0.1-0.4,0.1c-1.6,0.5-1.6,2.7-0.4,3.5c0.3,0.2,0.8,0.4,1.3,0.3
							C1872.1,1064.9,1871.6,1061,1869.1,1061.3z"/>
						<path class="st3" d="M1873.3,1057.3c0.3,0.1,0.6,0.2,0.9,0.1c2.5-0.3,2-4.2-0.5-3.9c-1.6,0.2-2,1.8-1.4,2.9
							C1872.5,1056.8,1872.9,1057.1,1873.3,1057.3z"/>
						<path class="st3" d="M1883.1,1058.7c-2.5,0.3-2,4.2,0.5,3.9C1886.1,1062.3,1885.6,1058.4,1883.1,1058.7z"/>
						<path class="st3" d="M1877.1,1049.9c2.5-0.3,2-4.2-0.5-3.9C1874.1,1046.3,1874.6,1050.2,1877.1,1049.9z"/>
						<path class="st3" d="M1861.4,1056.9c-1,0.1-1.5,0.8-1.6,1.6c-0.1,1.2,0.6,2.5,2.1,2.3c0.1,0,0.2,0,0.3-0.1
							C1864.4,1060.1,1863.8,1056.5,1861.4,1056.9z"/>
						<path class="st3" d="M1885.4,1055.7c0.4-0.2,0.9-0.4,1.3-0.6c0.9-0.4,1.5-1.6,1.1-2.6c-0.4-0.8-1.3-1.4-2.2-1.2
							c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.2-0.9,0.4-1.3,0.6c-0.9,0.4-1.5,1.6-1.1,2.6c0.3,0.7,1,1.1,1.7,1.2
							C1884.9,1055.8,1885.2,1055.8,1885.4,1055.7z"/>
						<path class="st3" d="M1877.3,1062.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.8-0.6-2.1-0.5-2.8,0.4c-0.6,0.9-0.5,2.1,0.4,2.8
							c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.6,2.1,0.5,2.8-0.4C1878.3,1064.8,1878.2,1063.6,1877.3,1062.9z"/>
						<path class="st3" d="M1862.9,1067.9c-0.8,0.4-1.3,1.2-1.2,2.1h4.2c-0.1-0.3-0.3-0.7-0.4-1
							C1865.1,1068,1863.9,1067.4,1862.9,1067.9z"/>
						<path class="st3" d="M1870,1043.3c0.7,0.3,1.6,0.1,2.2-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.3-0.8,0.5-1.3c0,0,0-0.1,0-0.1
							c0.2-0.8,0-1.8-0.8-2.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.9-0.4-2.2,0-2.6,1c-0.2,0.4-0.3,0.8-0.5,1.3
							C1868.6,1041.7,1868.9,1042.9,1870,1043.3z"/>
						<path class="st3" d="M1884.2,1046.8c2.5-0.3,2-4.2-0.5-3.9C1881.2,1043.2,1881.7,1047.1,1884.2,1046.8z"/>
						<path class="st3" d="M1854.6,1049c-0.3,0-0.6,0.2-0.9,0.3c-1.5,1-0.8,3.9,1.4,3.6c1.5-0.2,1.9-1.8,1.4-2.8
							C1856.2,1049.4,1855.5,1048.9,1854.6,1049z"/>
						<path class="st3" d="M1854.6,1069.7c-0.3,0-0.6,0.1-0.8,0.3h2C1855.5,1069.7,1855.1,1069.6,1854.6,1069.7z"/>
						<path class="st3" d="M1895.7,1058.1c-2.5,0.3-2,4.2,0.5,3.9C1898.8,1061.7,1898.3,1057.8,1895.7,1058.1z"/>
						<path class="st3" d="M1847.5,1060.2c-2.5,0.3-2,4.2,0.5,3.9C1850.5,1063.8,1850,1059.9,1847.5,1060.2z"/>
						<path class="st3" d="M1853.9,1040c0.4,0.4,0.9,0.6,1.6,0.5c2.5-0.3,2-4.2-0.5-3.9c-0.8,0.1-1.3,0.6-1.5,1.2
							C1853.2,1038.5,1853.4,1039.4,1853.9,1040z"/>
					</g>
					<g>
						<path id="XMLID_23_" class="st4" d="M2074.2,871V1043c0,9.9-8.1,18-18,18h-134.7c-3.8,0-7.2-2.4-8.5-5.9
							c-17.2-48.4-63.3-83-117.6-83c-13.8,0-27.1,2.3-39.6,6.4c-5.9,2-11.9-2.3-11.9-8.5V743.3c0-9.9,8.1-18,18-18h151.7
							c6.8,0,13.3,2.5,18.3,7.1l11.8,10.9l4.2,3.9l45.2,41.5l75.2,69.1C2072.1,861.2,2074.2,866,2074.2,871z"/>
						<g>
							<path class="st5" d="M1924.1,1029c-2.3-2-5.4,1.5-3.1,3.5C1923.3,1034.6,1926.4,1031.1,1924.1,1029z"/>
							<path class="st5" d="M1916,1041.2c-2.3-2-5.4,1.5-3.1,3.5C1915.2,1046.7,1918.3,1043.2,1916,1041.2z"/>
							<path class="st5" d="M1927,1039.4c-0.3,1.3-1.4,2.4-3.1,2c-0.4-0.1-0.7-0.3-1-0.5c0,0.6,0.2,1.2,0.8,1.8
								C1925.9,1044.6,1928.8,1041.4,1927,1039.4z"/>
							<path class="st5" d="M1928.8,1051.3c-2.2-0.9-4.5,2.1-2.5,4c0.1,0.1,0.1,0.1,0.2,0.2
								C1925.6,1053.9,1926.6,1051.2,1928.8,1051.3z"/>
							<path class="st5" d="M1935.8,1035.8c-2.3-2-5.4,1.5-3.1,3.5C1935,1041.4,1938.1,1037.8,1935.8,1035.8z"/>
							<path class="st5" d="M1914.8,1032.3c-0.9,0.1-1.7-0.4-2.2-1.2c-0.2-0.3-0.3-0.6-0.3-1c-1.8,0.2-3.2,2.6-1.4,4.1
								C1912.6,1035.8,1914.8,1034.1,1914.8,1032.3z"/>
							<path class="st5" d="M1938.9,1051.1c0.5-1.2,0.1-2.6-1.1-3.2c-0.5-0.3-1.1-0.5-1.6-0.8c-1.1-0.5-2.6-0.1-3.2,1.1
								c-0.5,1.2-0.1,2.6,1.1,3.2c0.5,0.3,1.1,0.5,1.6,0.8C1936.8,1052.7,1938.4,1052.3,1938.9,1051.1z"/>
							<path class="st5" d="M1918.7,1047C1918.7,1047,1918.7,1047,1918.7,1047c-1.4,0.1-2.4,1.2-2.3,2.5c0,0.2,0,0.5,0,0.7
								c0.1,1.2,1.2,2.3,2.5,2.2c1.3-0.1,2.3-1.2,2.2-2.5c0-0.2,0-0.5,0-0.7c0-0.3-0.1-0.6-0.3-0.9
								C1919.8,1048.3,1919.1,1047.8,1918.7,1047z"/>
							<path class="st5" d="M1936,1029.1c0.1,0,0.2-0.1,0.3-0.1c-0.3-1.6,0.8-3.5,2.7-3.3c0-0.1,0-0.1,0-0.2c-0.4-1.1-1.8-2-3-1.5
								c-0.5,0.2-1,0.4-1.5,0.6c-1.1,0.5-2,1.7-1.5,3C1933.4,1028.8,1934.8,1029.6,1936,1029.1z"/>
							<path class="st5" d="M1942,1039.3c-1.5,0.6-2.4,2.6-0.9,4c0.5,0.4,1,0.6,1.5,0.6C1940.5,1043.1,1940.6,1040.4,1942,1039.3z"/>
							<path class="st5" d="M1913.1,1022.1c-0.1,0-0.2,0-0.3,0c2.2,1.4,4.9-1.9,2.8-3.8c-0.7-0.6-1.4-0.7-2.1-0.5
								C1915.1,1018.6,1915.4,1021.5,1913.1,1022.1z"/>
							<path class="st5" d="M1899.9,1020.9c-1.8-1.5-4,0.2-3.9,2c0.5,0.6,1,1.3,1.4,2C1899.5,1025.8,1901.9,1022.7,1899.9,1020.9z"/>
							<path class="st5" d="M1927.1,1008.9c-2.3-2-5.4,1.5-3.1,3.5C1926.3,1014.4,1929.4,1010.9,1927.1,1008.9z"/>
							<path class="st5" d="M1961.5,1036.3c-2.7-0.5-3.7,3.1-1.8,4.4c0.4-0.1,0.8-0.1,1.3,0c0.3,0.1,0.5,0.2,0.7,0.3
								C1963.9,1040.3,1964.2,1036.7,1961.5,1036.3z"/>
							<path class="st5" d="M1960.9,1051.1c-1.1-0.2-2,0.3-2.4,1.1c0.9,0,1.7,0.5,2.2,1.3c0.1,0.2,0.3,0.4,0.4,0.7
								c0.3,0.5,0.4,1,0.3,1.6C1963.2,1054.8,1963.4,1051.5,1960.9,1051.1z"/>
							<path class="st5" d="M1968.3,1048.3c3,0.5,3.9-4.2,0.9-4.7C1966.1,1043.1,1965.3,1047.8,1968.3,1048.3z"/>
							<path class="st5" d="M1978.1,1053.2c-3-0.5-3.9,4.2-0.9,4.7C1980.3,1058.5,1981.2,1053.7,1978.1,1053.2z"/>
							<path class="st5" d="M1975.7,1036.1c-0.1-0.1-0.3-0.1-0.4-0.1c-3-0.5-3.9,4.2-0.9,4.7c2.2,0.4,3.2-1.9,2.5-3.5
								C1976.4,1037,1975.9,1036.6,1975.7,1036.1z"/>
							<path class="st5" d="M1953.6,1043c-3-0.5-3.9,4.2-0.9,4.7C1955.8,1048.3,1956.6,1043.6,1953.6,1043z"/>
							<path class="st5" d="M1983.4,1045.5c-0.6,0.1-1.2,0.1-1.8,0.2c-1.2,0.1-2.3,1.3-2.2,2.6c0.2,1.3,1.3,2.3,2.6,2.2
								c0.6-0.1,1.2-0.1,1.8-0.2c1.2-0.1,2.3-1.3,2.2-2.6C1985.8,1046.4,1984.7,1045.4,1983.4,1045.5z"/>
							<path class="st5" d="M1968.9,1056.8c-1.3-0.3-1.9-1.4-1.9-2.5c-0.3,0.1-0.6,0.2-0.8,0.4c-1,0.8-1.4,2.2-0.6,3.3
								c0.1,0.2,0.3,0.4,0.4,0.6c0.7,1,2.3,1.4,3.3,0.6c0.8-0.6,1.1-1.5,1-2.4C1969.9,1056.9,1969.4,1057,1968.9,1056.8z"/>
							<path class="st5" d="M1953.9,1058.6c-0.1-1.2-1.3-2.3-2.6-2.2c-1.3,0.2-2.3,1.3-2.2,2.6c0.1,0.6,0.1,1.2,0.2,1.8
								c0,0.1,0,0.1,0,0.2h4.7c0-0.2,0.1-0.4,0-0.6C1954,1059.8,1953.9,1059.2,1953.9,1058.6z"/>
							<path class="st5" d="M1971.4,1024.9c-0.5,0.7-1.3,1.1-2.2,0.8c-0.4,0.5-0.7,0.9-1,1.3c-0.8,1-0.8,2.6,0.3,3.4
								c1,0.8,2.6,0.8,3.4-0.3c0.3-0.4,0.7-0.9,1-1.3c0.8-1,0.8-2.6-0.3-3.4C1972.3,1025.1,1971.9,1024.9,1971.4,1024.9z"/>
							<path class="st5" d="M1984.3,1034.9c-2.7-0.1-3.4,4.2-0.5,4.8c1.9,0.3,3-1.4,2.7-2.9c-0.2,0-0.4,0.1-0.6,0
								C1985,1036.7,1984.4,1035.8,1984.3,1034.9z"/>
							<path class="st5" d="M1950.1,1034.8c0-0.4,0.1-0.8,0.2-1.2c-0.1-1-0.6-1.9-1.9-2.1c-3-0.5-3.9,4.2-0.9,4.7
								C1948.9,1036.4,1949.7,1035.7,1950.1,1034.8z"/>
							<path class="st5" d="M1938.4,1058.9c0-0.1-0.1-0.2-0.1-0.3C1938.3,1058.7,1938.3,1058.8,1938.4,1058.9z"/>
							<path class="st5" d="M1938.6,1059.3c-0.1-0.1-0.2-0.2-0.2-0.4C1938.4,1059,1938.5,1059.1,1938.6,1059.3z"/>
							<path class="st5" d="M1938.8,1059.5C1938.7,1059.5,1938.7,1059.4,1938.8,1059.5C1938.7,1059.4,1938.7,1059.5,1938.8,1059.5z"
								/>
							<path class="st5" d="M1993.1,1057.1c-2.4-0.4-3.4,2.3-2.3,3.8h3.7C1995.4,1059.7,1995.1,1057.5,1993.1,1057.1z"/>
							<path class="st5" d="M1935.3,1046.6c3,0.5,3.9-4.2,0.9-4.7C1933.1,1041.3,1932.2,1046.1,1935.3,1046.6z"/>
							<path class="st5" d="M1952.7,1021.9c3,0.5,3.9-4.2,0.9-4.7C1950.5,1016.6,1949.6,1021.3,1952.7,1021.9z"/>
							<path class="st5" d="M2028.7,1035.7c2.4-0.7,1.3-4.5-1.1-3.7C2025.1,1032.7,2026.3,1036.4,2028.7,1035.7z"/>
							<path class="st5" d="M2032.6,1042.9c-2.4,0.7-1.3,4.5,1.1,3.7C2036.1,1045.9,2035,1042.2,2032.6,1042.9z"/>
							<path class="st5" d="M2035.8,1034.4c-2.4,0.7-1.3,4.5,1.1,3.7C2039.4,1037.4,2038.2,1033.7,2035.8,1034.4z"/>
							<path class="st5" d="M2047.1,1041.8c2.4-0.7,1.3-4.5-1.1-3.7C2043.5,1038.8,2044.6,1042.5,2047.1,1041.8z"/>
							<path class="st5" d="M2037.4,1026.6c-2.4,0.7-1.3,4.5,1.1,3.7C2041,1029.6,2039.8,1025.9,2037.4,1026.6z"/>
							<path class="st5" d="M2024.3,1039.8c-2.4,0.7-1.3,4.5,1.1,3.7C2027.9,1042.8,2026.7,1039.1,2024.3,1039.8z"/>
							<path class="st5" d="M2048.2,1030.2c-0.4-0.1-0.9,0-1.3,0.3c-0.4,0.3-0.8,0.5-1.2,0.8c-0.9,0.6-1.2,1.8-0.6,2.7
								c0.6,0.9,1.8,1.2,2.7,0.6c0.4-0.3,0.8-0.5,1.2-0.8c0.2-0.1,0.4-0.3,0.5-0.5C2048.3,1032.9,2047.6,1031.4,2048.2,1030.2z"/>
							<path class="st5" d="M2041,1043.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.5-2.2-0.1-2.6,0.8c-0.5,1-0.2,2.1,0.8,2.6
								c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.8C2042.2,1044.7,2041.9,1043.6,2041,1043.1z"/>
							<path class="st5" d="M2030.3,1051c-0.6-0.9-1.8-1.2-2.7-0.6c-0.9,0.6-1.2,1.8-0.6,2.7c0.3,0.4,0.5,0.8,0.8,1.2
								c0.6,0.9,1.8,1.2,2.7,0.6c0.9-0.6,1.2-1.8,0.6-2.7C2030.8,1051.8,2030.6,1051.4,2030.3,1051z"/>
							<path class="st5" d="M2029.2,1021.3c-0.1,0.4-0.2,0.9-0.2,1.3c-0.2,1,0.4,2.1,1.5,2.4c1,0.2,2.2-0.4,2.4-1.5
								c0.1-0.4,0.2-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.4C2030.6,1019.7,2029.4,1020.3,2029.2,1021.3z"/>
							<path class="st5" d="M2038.4,1051.6c-0.8,0.2-1.6,0.3-2.3,0.5c-1,0.2-1.6,1.5-1.3,2.4c0.4,1.1,1.4,1.5,2.4,1.3
								c0.8-0.2,1.6-0.3,2.3-0.5c1-0.2,1.6-1.5,1.3-2.4C2040.4,1051.9,2039.4,1051.4,2038.4,1051.6z"/>
							<path class="st5" d="M2050.1,1052.8c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
								c-0.1-0.2-0.1-0.3-0.1-0.4c-0.2-1-1.5-1.6-2.4-1.3c-1.1,0.4-1.5,1.4-1.3,2.4c0.2,1.1,0.9,2.1,1.8,2.8c0.8,0.6,2,0.8,2.8-0.1
								C2050.8,1054.8,2051,1053.5,2050.1,1052.8z M2049.5,1052.1c0,0,0,0.1,0.1,0.2C2049.6,1052.3,2049.5,1052.2,2049.5,1052.1z
								M2049.7,1052.4c0,0.1,0.1,0.1,0.1,0.2C2049.7,1052.5,2049.7,1052.5,2049.7,1052.4z"/>
							<path class="st5" d="M2052.3,1044.6c-2.4,0.7-1.3,4.5,1.1,3.7C2055.9,1047.6,2054.7,1043.9,2052.3,1044.6z"/>
							<path class="st5" d="M2040.1,1057.1c-2.1,0.6-1.5,3.5,0.3,3.8h0.5c0.1,0,0.2,0,0.3-0.1
								C2043.6,1060.2,2042.5,1056.4,2040.1,1057.1z"/>
							<path class="st5" d="M2029.9,1059.4c-0.8,0.3-1.2,0.9-1.3,1.5h3.7C2032.1,1059.9,2031.2,1059,2029.9,1059.4z"/>
							<path class="st5" d="M2045,1026.1c2.4-0.7,1.3-4.5-1.1-3.7C2041.4,1023.1,2042.6,1026.8,2045,1026.1z"/>
							<path class="st5" d="M2016.3,1033.3c-2.4,0.7-1.3,4.5,1.1,3.7C2019.8,1036.3,2018.7,1032.5,2016.3,1033.3z"/>
							<path class="st5" d="M2019.7,1053.5c-2.4,0.7-1.3,4.5,1.1,3.7C2023.3,1056.5,2022.1,1052.8,2019.7,1053.5z"/>
							<path class="st5" d="M2058.7,1035.3c0.1,0,0.2-0.1,0.2-0.1c-0.2,0-0.4,0-0.7,0.1c-2.1,0.7-1.5,3.7,0.4,3.8
								C2057.3,1038.4,2056.9,1036.1,2058.7,1035.3z"/>
							<path class="st5" d="M2011.2,1045.5c-2.4,0.7-1.3,4.5,1.1,3.7C2014.8,1048.5,2013.6,1044.7,2011.2,1045.5z"/>
							<path class="st5" d="M2015.8,1024.8c2.4-0.7,1.3-4.5-1.1-3.7C2012.2,1021.8,2013.4,1025.5,2015.8,1024.8z"/>
							<path class="st5" d="M2063.1,984.9c-1.8-1-3.4,1.8-1.5,2.8C2063.3,988.7,2064.9,985.9,2063.1,984.9z"/>
							<path class="st5" d="M2059.5,994.2c-1.8-1-3.4,1.8-1.5,2.8C2059.8,998.1,2061.3,995.2,2059.5,994.2z"/>
							<path class="st5" d="M2066.4,991.2c-1.8-1-3.4,1.8-1.5,2.8C2066.7,995.1,2068.2,992.2,2066.4,991.2z"/>
							<path class="st5" d="M2070.1,999.3c-1.8-1-3.4,1.8-1.5,2.8C2070.4,1003.1,2071.9,1000.3,2070.1,999.3z"/>
							<path class="st5" d="M2071.9,987.7c-1.8-1-3.4,1.8-1.5,2.8C2072.2,991.5,2073.7,988.7,2071.9,987.7z"/>
							<path class="st5" d="M2055,990.4c1.8,1,3.4-1.8,1.5-2.8C2054.7,986.6,2053.2,989.4,2055,990.4z"/>
							<path class="st5" d="M2071.9,996.4c-0.2,0.9,0.3,1.7,1.2,1.9c0.3,0.1,0.7,0.2,1,0.2v-3.3c-0.1,0-0.2,0-0.3-0.1
								C2073.1,995,2072.1,995.5,2071.9,996.4z"/>
							<path class="st5" d="M2062.1,997.7c-0.8,0.3-1.3,1.1-1.1,2c0,0.1,0.1,0.3,0.1,0.4c0.2,0.8,1.2,1.4,2,1.1
								c0.8-0.3,1.3-1.1,1.1-2c0-0.1-0.1-0.3-0.1-0.4C2063.9,998,2062.9,997.4,2062.1,997.7z"/>
							<path class="st5" d="M2051.2,1000c0.9,0.2,1.7-0.3,1.9-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.8-0.3-1.8-1.2-1.9
								c-0.9-0.2-1.7,0.3-1.9,1.2c-0.1,0.4-0.2,0.8-0.3,1.2C2049.9,998.8,2050.4,999.8,2051.2,1000z"/>
							<path class="st5" d="M2070.3,979.8c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.5-1,1.5-0.5,2.2c0.5,0.7,1.5,1.1,2.2,0.5
								c0.3-0.2,0.6-0.4,0.9-0.6c0.7-0.5,1-1.5,0.5-2.2C2072,979.6,2071,979.3,2070.3,979.8z"/>
							<path class="st5" d="M2057.7,1005.5c0.3-0.1,0.5-0.4,0.7-0.7c0.3-0.6,0.2-1.1-0.1-1.6
								C2058.5,1004.1,2058.3,1005,2057.7,1005.5z"/>
							<path class="st5" d="M2056.3,1001.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.5-1.8-0.1-2.2,0.6c-0.4,0.7-0.2,1.5,0.4,2
								C2054.1,1003.1,2054.9,1001.8,2056.3,1001.8z"/>
							<path class="st5" d="M2062.3,1011.9c0.8-0.1,1.7-0.8,1.6-1.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
								c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.1-1.8-0.6-2.2c-0.8-0.4-1.7-0.1-2.2,0.6c-0.5,0.8-0.7,1.8-0.6,2.7
								C2060.7,1011.2,2061.3,1012,2062.3,1011.9z M2063.9,1009.5c0,0,0,0.1-0.1,0.1C2063.8,1009.7,2063.8,1009.6,2063.9,1009.5z
								M2063.8,1009.8c0,0.1,0,0.1,0,0.2C2063.8,1009.9,2063.8,1009.8,2063.8,1009.8z"/>
							<path class="st5" d="M2069.9,1006.9c-1.8-1-3.4,1.8-1.5,2.8C2070.2,1010.7,2071.7,1007.9,2069.9,1006.9z"/>
							<path class="st5" d="M2053.9,1009.7c1.8,1,3.4-1.8,1.5-2.8C2053.6,1005.9,2052.1,1008.7,2053.9,1009.7z"/>
							<path class="st5" d="M2048.2,1002.2c-1.8-1-3.4,1.8-1.5,2.8C2048.5,1006,2050,1003.2,2048.2,1002.2z"/>
							<path class="st5" d="M2055.7,979.1c-1.8-1-3.4,1.8-1.5,2.8C2056,982.9,2057.6,980,2055.7,979.1z"/>
							<path class="st5" d="M2044.3,994.1c-0.1-0.5,0.1-1,0.4-1.4c-1.2,0.3-1.9,2.2-0.4,3c0.5,0.3,0.9,0.3,1.3,0.1
								C2044.9,995.4,2044.4,994.8,2044.3,994.1z"/>
							<path class="st5" d="M2044.1,986c1.8,1,3.4-1.8,1.5-2.8C2043.8,982.1,2042.2,985,2044.1,986z"/>
							<path class="st5" d="M2062,971c-1.8-1-3.4,1.8-1.5,2.8C2062.3,974.8,2063.8,972,2062,971z"/>
							<path class="st5" d="M1752.9,964.9c2.5-0.9,1.1-4.8-1.4-3.9C1748.9,961.8,1750.3,965.8,1752.9,964.9z"/>
							<path class="st5" d="M1757.3,972.4c-2.5,0.9-1.1,4.8,1.4,3.9C1761.2,975.4,1759.8,971.5,1757.3,972.4z"/>
							<path class="st5" d="M1760.4,963.2c-2.5,0.9-1.1,4.8,1.4,3.9C1764.3,966.2,1762.9,962.3,1760.4,963.2z"/>
							<path class="st5" d="M1772.7,966.6c-0.4-0.2-0.9-0.2-1.4,0c-1.6,0.5-1.6,2.2-0.9,3.3C1770,968.4,1770.9,966.6,1772.7,966.6z"
								/>
							<path class="st5" d="M1761.7,954.9c-2.5,0.9-1.1,4.8,1.4,3.9C1765.6,957.9,1764.2,954,1761.7,954.9z"/>
							<path class="st5" d="M1751.1,971.9c0.3-1.4-0.9-3.1-2.7-2.5c-2.5,0.9-1.1,4.8,1.4,3.9c0,0,0,0,0.1,0
								C1750,972.8,1750.5,972.2,1751.1,971.9z"/>
							<path class="st5" d="M1771.9,958.5c-0.4,0.3-0.8,0.6-1.3,0.9c-0.9,0.6-1.2,2-0.5,2.9c0.7,0.9,1.9,1.2,2.9,0.5
								c0.4-0.3,0.8-0.6,1.3-0.9c0.9-0.6,1.2-2,0.5-2.9C1774.1,958.2,1772.9,957.9,1771.9,958.5z"/>
							<path class="st5" d="M1766.2,972.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.8-0.4-1.9-0.2-2.5,0.6c0,0,0.1,0,0.1,0
								c1.3-0.2,2.6,0.9,2.8,2.2c0,0.3,0.1,0.5,0.1,0.8c0.3-0.1,0.6-0.1,0.9-0.2c0.1-0.1,0.1-0.2,0.2-0.3
								C1767.7,973.9,1767.3,972.7,1766.2,972.2z"/>
							<path class="st5" d="M1756.6,951.8c0.1-0.5,0.1-0.9,0.2-1.4c0.1-1.1-0.5-2.3-1.7-2.4c-1.1-0.2-2.3,0.5-2.4,1.7
								c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,1.1,0.5,2.3,1.7,2.4C1755.3,953.6,1756.5,953,1756.6,951.8z"/>
							<path class="st5" d="M1768.4,950c-2.5,0.9-1.1,4.8,1.4,3.9c0.4-0.1,0.6-0.3,0.9-0.6c0.1-0.5,0.2-0.9,0.5-1.3
								C1771.1,950.8,1769.9,949.5,1768.4,950z"/>
							<path class="st5" d="M1784.2,963.1c-2.5,0.9-1.1,4.8,1.4,3.9C1788.2,966.2,1786.8,962.2,1784.2,963.1z"/>
							<path class="st5" d="M1808.1,961.7c-2.1,0.1-1.9,3.4,0.2,3.3C1810.5,964.9,1810.3,961.6,1808.1,961.7z"/>
							<path class="st5" d="M1810.1,971.8c-0.7,0-1.1,0.4-1.4,0.9c1,0.1,2,0.2,3,0.4C1811.6,972.3,1811,971.7,1810.1,971.8z"/>
							<path class="st5" d="M1814.5,965.4c-2.1,0.1-1.9,3.4,0.2,3.3c1.4-0.1,1.8-1.6,1.3-2.6c0,0,0-0.1-0.1-0.1
								C1815.6,965.7,1815.1,965.4,1814.5,965.4z"/>
							<path class="st5" d="M1822.1,970.4c-2.1,0.1-1.9,3.4,0.2,3.3C1824.4,973.6,1824.2,970.3,1822.1,970.4z"/>
							<path class="st5" d="M1818,962.5c1.7-0.5,1.4-3.4-0.6-3.2c-2.1,0.1-2,3.2,0,3.3C1817.5,962.5,1817.7,962.5,1818,962.5z"/>
							<path class="st5" d="M1803.9,967.6c-2.1,0.1-1.9,3.4,0.2,3.3C1806.2,970.7,1806,967.4,1803.9,967.6z"/>
							<path class="st5" d="M1824.3,964.4c-0.4,0.1-0.8,0.3-1.2,0.4c-0.8,0.3-1.4,1.3-1,2.1c0.3,0.8,1.2,1.3,2.1,1
								c0.4-0.1,0.8-0.3,1.2-0.4c0.8-0.3,1.4-1.3,1-2.1C1826.1,964.6,1825.2,964.1,1824.3,964.4z"/>
							<path class="st5" d="M1816.6,973.3c-0.7-0.6-1.8-0.6-2.3,0.1c1,0.2,2,0.3,3,0.5c-0.1-0.1-0.2-0.2-0.3-0.3
								C1816.9,973.5,1816.7,973.4,1816.6,973.3z"/>
							<path class="st5" d="M1814.1,955.8c0.2-0.3,0.3-0.7,0.5-1c0.3-0.8,0.1-1.8-0.7-2.2c-0.8-0.4-1.9-0.1-2.2,0.7
								c-0.2,0.3-0.3,0.7-0.5,1c-0.3,0.8-0.1,1.8,0.7,2.2C1812.7,956.9,1813.8,956.7,1814.1,955.8z"/>
							<path class="st5" d="M1823.7,960.4c2.1-0.1,1.9-3.4-0.2-3.3C1821.4,957.2,1821.6,960.5,1823.7,960.4z"/>
							<path class="st5" d="M1798.5,960.5c-0.2,0-0.3,0-0.4,0.1c0.5,0.4,0.9,1,1,1.7c0.1,0.5,0,1.1-0.3,1.5
								C1800.9,963.7,1800.7,960.4,1798.5,960.5z"/>
							<path class="st5" d="M1833,974c0.7,0,1.1-0.4,1.3-0.9c-0.6,0.5-1.5,0.6-2.3,0.1c-0.3-0.2-0.5-0.4-0.7-0.6
								C1831.5,973.4,1832,974,1833,974z"/>
							<path class="st5" d="M1793.1,970c0.2,0.7,0.1,1.4-0.2,2c0.2,0,0.4,0,0.6,0C1793.8,971.3,1793.6,970.5,1793.1,970z"/>
							<path class="st5" d="M1799.6,950.1c-0.2,0-0.4,0.1-0.6,0.1c0.5,0.9,0.4,2.1-0.2,3c0.3,0.2,0.6,0.3,1,0.2
								C1802,953.3,1801.8,950,1799.6,950.1z"/>
							<path class="st5" d="M1843.4,965.5c-2.6-1.4-4.8,2.6-2.2,4C1843.8,971,1846,967,1843.4,965.5z"/>
							<path class="st5" d="M1838.3,978.6c-0.9-0.5-1.7-0.3-2.3,0.1c1.1,0.4,2.2,0.8,3.3,1.2C1839.2,979.4,1838.9,979,1838.3,978.6z"
								/>
							<path class="st5" d="M1848,974.5c-2.6-1.4-4.8,2.6-2.2,4C1848.4,979.9,1850.6,975.9,1848,974.5z"/>
							<path class="st5" d="M1853.2,985.8c-0.4-0.2-0.7-0.3-1.1-0.3c0.6,0.3,1.2,0.7,1.8,1C1853.8,986.3,1853.5,986,1853.2,985.8z"/>
							<path class="st5" d="M1855.9,969.5c-2.6-1.4-4.8,2.6-2.2,4C1856.2,974.9,1858.4,970.9,1855.9,969.5z"/>
							<path class="st5" d="M1832,973.2c0.9,0.5,1.7,0.3,2.3-0.1c1.2-0.9,1.6-2.9-0.1-3.8c-2.3-1.3-4.3,1.7-2.9,3.4
								C1831.5,972.8,1831.7,973,1832,973.2z"/>
							<path class="st5" d="M1860.2,980.5c-0.1,0-0.1,0-0.2,0c1.3,1.8-1.1,4.5-3.1,3c-0.9-0.7-1-1.7-0.6-2.6
								c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2,1.2,0.4,2.5,1.7,2.7c0.5,0.1,1.1,0.3,1.6,0.4c1.2,0.3,2.5-0.5,2.7-1.7
								C1862.1,982.1,1861.5,980.8,1860.2,980.5z"/>
							<path class="st5" d="M1854.7,963.2c0.4-0.3,0.9-0.6,1.3-0.9c1-0.7,1.5-2.1,0.8-3.1c-0.6-1-2.1-1.5-3.1-0.8
								c-0.4,0.3-0.9,0.6-1.3,0.9c-1,0.7-1.5,2.1-0.8,3.1C1852.2,963.4,1853.6,963.9,1854.7,963.2z"/>
							<path class="st5" d="M1864.6,971.5c-2.6-1.4-4.8,2.6-2.2,4C1865,976.9,1867.2,972.9,1864.6,971.5z"/>
							<path class="st5" d="M1833.2,957.2c-2.6-1.4-4.8,2.6-2.2,4C1833.5,962.6,1835.7,958.6,1833.2,957.2z"/>
							<path class="st5" d="M1818.9,962.8c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0-0.7,0.1c-1.4,0.4-2.3,2.2-1.4,3.5
								c0,0,0,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.7,0.6C1819.2,968.2,1821.5,964.3,1818.9,962.8z"/>
							<path class="st5" d="M1842.1,945.9c-2.6-1.4-4.8,2.6-2.2,4C1842.4,951.3,1844.7,947.3,1842.1,945.9z"/>
							<path class="st5" d="M1856.9,983.5c2,1.6,4.4-1.2,3.1-3c-0.1-0.2-0.3-0.4-0.5-0.5c-1.3-1-2.7-0.2-3.3,0.9
								C1855.9,981.7,1856,982.8,1856.9,983.5z"/>
							<path class="st5" d="M1862.8,989.2c-0.8-0.6-1.6-0.5-2.3-0.2c0.3,0.5,0.5,1,0.5,1.5c0.8,0.5,1.5,1,2.3,1.5
								C1863.8,991.1,1863.8,990,1862.8,989.2z"/>
							<path class="st5" d="M1870.9,985.5c-2.3-1.7-4.9,1.8-2.7,3.5C1870.5,990.7,1873.2,987.2,1870.9,985.5z"/>
							<path class="st5" d="M1850.3,982.2c-1.3-1-2.7-0.3-3.3,0.9c1.3,0.6,2.6,1.2,3.8,1.8C1851.3,984.1,1851.3,983,1850.3,982.2z"/>
							<path class="st5" d="M1873.6,996.6c-0.5-0.2-1-0.4-1.5-0.6c-0.8-0.3-1.8-0.1-2.4,0.5c1.6,1.2,3.2,2.4,4.7,3.7
								c0.2-0.2,0.3-0.4,0.4-0.7C1875.2,998.3,1874.7,997,1873.6,996.6z"/>
							<path class="st5" d="M1870.3,974.5c-0.5,0.2-0.9,0.4-1.4,0.7c-1,0.5-1.7,1.8-1.2,2.9c0.5,1,1.8,1.7,2.9,1.2
								c0.5-0.2,0.9-0.4,1.4-0.7c1-0.5,1.7-1.8,1.2-2.9C1872.7,974.7,1871.4,974,1870.3,974.5z"/>
							<path class="st5" d="M1876,989c-0.7,0.9-0.8,2.2,0.4,3c1.9,1.4,4-0.8,3.4-2.5c-0.5,0.4-1.2,0.7-1.9,0.7
								C1877,990.2,1876.4,989.7,1876,989z"/>
							<path class="st5" d="M1850.9,970.6c-2.3-1.7-4.9,1.8-2.7,3.5C1850.5,975.8,1853.2,972.3,1850.9,970.6z"/>
							<path class="st5" d="M1836.5,974c-2.3-1.7-4.9,1.8-2.7,3.5C1836.1,979.3,1838.7,975.8,1836.5,974z"/>
							<path class="st5" d="M1858.3,964.6c2.3,1.7,4.9-1.8,2.7-3.5C1858.7,959.3,1856,962.8,1858.3,964.6z"/>
							<path class="st5" d="M1902,1030.9c-0.2,0-0.3,0.1-0.5,0.2c0.8,1.3,1.6,2.6,2.3,3.9C1905.6,1033.7,1904.5,1030.3,1902,1030.9z"
								/>
							<path class="st5" d="M1914.3,1040.2c2.8-0.7,1.8-5-1-4.4C1910.5,1036.5,1911.5,1040.8,1914.3,1040.2z"/>
							<path class="st5" d="M1904.8,1025.2c-0.7-0.4-1.4-1-1.9-1.7c-0.5,1.5,0.6,3.5,2.6,3.1c0.7-0.2,1.1-0.5,1.4-1
								C1906.1,1025.6,1905.4,1025.5,1904.8,1025.2z"/>
							<path class="st5" d="M1912.5,1031.1c0.5,0.8,1.3,1.2,2.2,1.2c0.3,0,0.6-0.1,0.8-0.3c0.5-0.3,1-0.5,1.5-0.8c1-0.6,1.5-2,0.9-3
								c-0.6-1-2-1.5-3-0.9c-0.5,0.3-1,0.5-1.5,0.8c-0.7,0.4-1.2,1.2-1.2,2.1C1912.3,1030.5,1912.4,1030.8,1912.5,1031.1z"/>
							<path class="st5" d="M1907.3,1041.2c0,0-0.1,0-0.1-0.1c0.4,0.8,0.8,1.6,1.1,2.3C1908.4,1042.6,1908.1,1041.7,1907.3,1041.2z"
								/>
							<path class="st5" d="M1898.2,1014c-1.1-0.3-2.5,0.3-2.8,1.5c-0.1,0.5-0.2,1-0.4,1.5c-0.3,1.1,0.3,2.5,1.5,2.8
								c1.1,0.3,2.5-0.3,2.8-1.5c0.1-0.5,0.2-1,0.4-1.5C1899.9,1015.7,1899.4,1014.3,1898.2,1014z"/>
							<path class="st5" d="M1916.9,1056.2c0.8-0.8,1-2.4,0.1-3.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
								c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.4c-0.2-1.2-1.6-2-2.7-1.7c-1,0.3-1.6,1.1-1.7,2c0.4,1,0.8,1.9,1.1,2.9
								c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.3,0.3,0.4,0.4C1914.7,1056.9,1916,1057.1,1916.9,1056.2z M1916.7,1052.7
								c-0.1-0.1-0.1-0.1-0.1-0.2C1916.6,1052.6,1916.6,1052.7,1916.7,1052.7z M1916.5,1052.4c0,0-0.1-0.1-0.1-0.2
								C1916.4,1052.3,1916.4,1052.3,1916.5,1052.4z"/>
							<path class="st5" d="M1920.1,1043.8c-1.6,0.4-2,2-1.4,3.1c0.4,0.8,1.1,1.4,2.1,1.3c0.1,0,0.2,0,0.3,0
								C1924,1047.5,1923,1043.2,1920.1,1043.8z"/>
							<path class="st5" d="M1912.8,1022.1c0.1,0,0.2,0,0.3,0c2.3-0.5,2-3.5,0.4-4.2c-0.4-0.2-0.9-0.2-1.4-0.1
								C1909.3,1018.4,1910.2,1022.4,1912.8,1022.1z"/>
							<path class="st5" d="M1926.8,1037.6c0.5,0.4,1.1,0.5,1.8,0.4c2.8-0.7,1.8-5-1-4.4c-1.6,0.4-2,2-1.4,3.2
								C1926.5,1037,1926.7,1037.3,1926.8,1037.6z"/>
							<path class="st5" d="M1875.6,999.3c-0.5,0.2-0.8,0.6-1.1,0.9c1.3,1,2.5,2.1,3.7,3.2C1880.3,1001.8,1878.3,998,1875.6,999.3z"
								/>
							<path class="st5" d="M1886.4,1000.9c-2.9,1.4-0.7,5.8,2.1,4.4C1891.3,1003.9,1889.2,999.5,1886.4,1000.9z"/>
							<path class="st5" d="M1899.6,1003.5c-2.9,1.4-0.7,5.8,2.1,4.4C1904.6,1006.6,1902.4,1002.1,1899.6,1003.5z"/>
							<path class="st5" d="M1886.9,990.9c-2.9,1.4-0.7,5.8,2.1,4.4C1891.9,994,1889.7,989.5,1886.9,990.9z"/>
							<path class="st5" d="M1897.7,998.6c0.9,1,2.4,1.2,3.5,0.2c0.5-0.4,0.9-0.8,1.4-1.2c1-0.9,1.2-2.5,0.2-3.5
								c-0.9-1-2.4-1.2-3.5-0.2c-0.5,0.4-0.9,0.8-1.4,1.2C1897,996,1896.8,997.7,1897.7,998.6z"/>
							<path class="st5" d="M1895.9,1013.8c0.4-1.3-0.2-2.7-1.5-3.1c-0.2-0.1-0.4-0.2-0.7-0.2c-1.2-0.4-2.8,0.2-3.1,1.5
								c-0.4,1.3,0.2,2.7,1.5,3.1c0.2,0.1,0.4,0.2,0.7,0.2C1893.9,1015.7,1895.5,1015.1,1895.9,1013.8z"/>
							<path class="st5" d="M1878.3,983.6c-1.3-0.1-2.6,0.9-2.7,2.2c0,0.6,0,1.1,0,1.7c0,0.5,0.1,1,0.4,1.5c0.4,0.7,1,1.2,1.9,1.2
								c0.7,0,1.4-0.2,1.9-0.7c0.4-0.4,0.8-0.9,0.8-1.6c0-0.6,0-1.1,0-1.7C1880.6,985,1879.7,983.7,1878.3,983.6z"/>
							<path class="st5" d="M1907.5,1021.1c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
								c-0.1-0.2-0.1-0.4-0.2-0.5c-0.4-1.2-2.2-1.7-3.3-1.2c-1.3,0.7-1.6,2-1.2,3.3c0.2,0.5,0.5,1,0.8,1.4c0.5,0.7,1.2,1.3,1.9,1.7
								c0.6,0.3,1.4,0.5,2,0.3c0.5-0.1,1-0.4,1.4-1C1908.8,1023.5,1908.8,1021.7,1907.5,1021.1z M1907.1,1020.9
								c-0.1-0.1-0.2-0.1-0.2-0.2C1907,1020.7,1907.1,1020.8,1907.1,1020.9z M1906.8,1020.6c0,0-0.1-0.1-0.1-0.2
								C1906.7,1020.4,1906.8,1020.5,1906.8,1020.6z"/>
							<path class="st5" d="M1908.8,1010.5c-2.9,1.4-0.7,5.8,2.1,4.4C1913.8,1013.6,1911.7,1009.1,1908.8,1010.5z"/>
							<path class="st5" d="M1896.2,988.8c2.9-1.4,0.7-5.8-2.1-4.4C1891.2,985.7,1893.3,990.2,1896.2,988.8z"/>
							<path class="st5" d="M1916.5,1002.2c2.9-1.4,0.7-5.8-2.1-4.4C1911.6,999.2,1913.7,1003.6,1916.5,1002.2z"/>
							<path class="st5" d="M1857.5,988.2c-0.1,0-0.2,0.1-0.2,0.1c1.3,0.7,2.5,1.4,3.7,2.2c0-0.5-0.2-1.1-0.5-1.5
								C1859.8,988.1,1858.7,987.6,1857.5,988.2z"/>
							<path class="st5" d="M2061.2,1026.4c-2.5,1.1-0.8,5,1.7,3.9C2065.4,1029.2,2063.7,1025.3,2061.2,1026.4z"/>
							<path class="st5" d="M2068,1037.6c-2.5,1.1-0.8,5,1.7,3.9C2072.2,1040.4,2070.5,1036.5,2068,1037.6z"/>
							<path class="st5" d="M2070.4,1028c-2.5,1.1-0.8,5,1.7,3.9C2074.6,1030.8,2072.9,1026.9,2070.4,1028z"/>
							<path class="st5" d="M2072.8,1023.3c2.5-1.1,0.8-5-1.7-3.9C2068.6,1020.5,2070.3,1024.4,2072.8,1023.3z"/>
							<path class="st5" d="M2058.9,1035.2c-0.1,0-0.2,0-0.2,0.1c-1.8,0.8-1.4,3.1-0.1,3.8c0.5,0.3,1.1,0.4,1.8,0.1
								C2062.8,1038.1,2061.3,1034.5,2058.9,1035.2z"/>
							<path class="st5" d="M2073.7,1037.7c-0.3,0.8-0.1,1.7,0.5,2.2v-2.9C2074,1037.1,2073.8,1037.4,2073.7,1037.7z"/>
							<path class="st5" d="M2067.3,1050.8c0.9-0.8,1.1-2.1,0.3-3c-0.3-0.4-0.7-0.8-1-1.2c-0.7-0.9-2.1-1.1-3-0.3
								c-0.9,0.8-1.1,2.1-0.3,3c0.3,0.4,0.7,0.8,1,1.2C2065,1051.3,2066.4,1051.5,2067.3,1050.8z"/>
							<path class="st5" d="M2063.4,1018.6c1.1,0.1,2.3-0.7,2.4-1.9c0-0.5,0.1-1,0.1-1.5c0.1-1.1-0.7-2.3-1.9-2.4
								c-1.1-0.1-2.3,0.7-2.4,1.9c0,0.5-0.1,1-0.1,1.5C2061.5,1017.3,2062.2,1018.5,2063.4,1018.6z"/>
							<path class="st5" d="M2071.8,1049.9c0.2,0.3,0.4,0.6,0.6,0.8c0.6-1.2,1-2.5,1.3-3.9c-0.3,0.1-0.6,0.2-0.9,0.3
								C2071.8,1047.5,2071.3,1048.9,2071.8,1049.9z"/>
							<path class="st5" d="M2067.2,1055.7c-1.1,0.5-1.4,1.4-1.2,2.3c1-0.7,2-1.4,2.9-2.3C2068.4,1055.5,2067.8,1055.4,2067.2,1055.7
								z"/>
							<path class="st5" d="M2049.2,1029.3c-0.5,0.2-0.8,0.6-1,0.9c-0.6,1.1,0.1,2.7,1.3,3c0.4,0.1,0.9,0.1,1.4-0.1
								C2053.4,1032.1,2051.7,1028.2,2049.2,1029.3z"/>
							<path class="st5" d="M2055.5,1050.7c-2.5,1.1-0.8,5,1.7,3.9C2059.7,1053.5,2058,1049.6,2055.5,1050.7z"/>
							<path class="st5" d="M2047,1047c2.5-1.1,0.8-5-1.7-3.9C2042.8,1044.2,2044.5,1048.1,2047,1047z"/>
							<path class="st5" d="M2047.6,1020.2c2.5-1.1,0.8-5-1.7-3.9C2043.4,1017.4,2045.1,1021.3,2047.6,1020.2z"/>
							<path class="st5" d="M1773.4,951.2c-1-0.2-1.8,0.3-2.3,0.9c-0.3,0.4-0.4,0.8-0.5,1.3c-0.1,1.2,0.5,2.5,2,2.7
								C1775.8,956.6,1776.5,951.7,1773.4,951.2z"/>
							<path class="st5" d="M1773.1,966.6c-0.1,0-0.3,0-0.4,0c-1.8,0-2.7,1.8-2.3,3.3c0.2,0.8,0.8,1.5,1.9,1.7
								C1775.5,972,1776.3,967.1,1773.1,966.6z"/>
							<path class="st5" d="M1781.5,958.6c-3.2-0.5-3.9,4.5-0.7,4.9C1783.9,964,1784.7,959.1,1781.5,958.6z"/>
							<path class="st5" d="M1793.1,970c-0.2-0.8-0.8-1.5-1.9-1.7c-2.4-0.4-3.4,2.3-2.4,3.9c1.4-0.1,2.8-0.1,4.1-0.1
								C1793.2,971.4,1793.3,970.7,1793.1,970z"/>
							<path class="st5" d="M1787.6,950.5c-3.2-0.5-3.9,4.5-0.7,4.9C1790.1,955.9,1790.8,951,1787.6,950.5z"/>
							<path class="st5" d="M1765.3,958.5c-3.2-0.5-3.9,4.5-0.7,4.9C1767.7,963.9,1768.5,959,1765.3,958.5z"/>
							<path class="st5" d="M1799.1,962.3c-0.1-0.7-0.5-1.3-1-1.7c-0.5-0.4-1.1-0.6-1.8-0.5c-0.6,0.1-1.2,0.2-1.8,0.2
								c-1.3,0.2-2.4,1.4-2.2,2.8c0.2,1.3,1.4,2.4,2.8,2.2c0.6-0.1,1.2-0.2,1.8-0.2c0.8-0.1,1.5-0.6,1.9-1.2
								C1799.1,963.4,1799.2,962.9,1799.1,962.3z"/>
							<path class="st5" d="M1782.2,970.8c-0.8-1.1-2.4-1.4-3.5-0.5c-0.9,0.7-1.2,1.9-0.8,3c1.7-0.2,3.5-0.4,5.2-0.6
								c0-0.4-0.2-0.9-0.5-1.2C1782.5,971.2,1782.4,971,1782.2,970.8z"/>
							<path class="st5" d="M1763.3,972.4c0,0-0.1,0-0.1,0c-1.3,0.3-2.2,1.4-2.1,2.7c0.1,0.5,0.1,1,0.2,1.5c1.6-0.5,3.2-0.9,4.9-1.3
								c0-0.3-0.1-0.5-0.1-0.8C1765.9,973.3,1764.6,972.2,1763.3,972.4z"/>
							<path class="st5" d="M1780.5,944.8c1,0.8,2.7,0.8,3.5-0.4c0.3-0.5,0.7-0.9,1-1.4c0.8-1.1,0.7-2.7-0.4-3.5
								c-1-0.8-2.7-0.8-3.5,0.4c-0.3,0.5-0.7,0.9-1,1.4C1779.3,942.4,1779.3,944,1780.5,944.8z"/>
							<path class="st5" d="M1799.1,950.2c-0.3-0.6-0.9-1-1.7-1.1c-3.2-0.5-3.9,4.5-0.7,4.9c1,0.2,1.8-0.2,2.2-0.9
								C1799.5,952.4,1799.6,951.1,1799.1,950.2z"/>
							<path class="st5" d="M1758.9,951.5c3.2,0.5,3.9-4.5,0.7-4.9C1756.4,946.1,1755.7,951,1758.9,951.5z"/>
							<path class="st5" d="M1752.5,971.8c-0.5-0.1-1,0-1.4,0.2c-0.6,0.3-1,0.8-1.2,1.4c-0.5,1.3,0.1,3,1.9,3.3
								C1754.9,977.2,1755.7,972.2,1752.5,971.8z"/>
							<path class="st5" d="M1806.8,971.9c-0.7-0.1-1.3,0.1-1.8,0.4c1.1,0.1,2.1,0.2,3.1,0.3C1807.9,972.3,1807.4,972,1806.8,971.9z"
								/>
							<path class="st5" d="M1747.1,957.8c-3.2-0.5-3.9,4.5-0.7,4.9C1749.5,963.2,1750.3,958.3,1747.1,957.8z"/>
							<path class="st5" d="M1763.7,936.5c3.2,0.5,3.9-4.5,0.7-4.9C1761.3,931.1,1760.5,936,1763.7,936.5z"/>
							<path class="st5" d="M1952,1037.1c3.3,0.8,4.6-4.3,1.3-5.1c-1.5-0.4-2.5,0.4-3,1.5c-0.2,0.4-0.2,0.8-0.2,1.2
								C1950.2,1035.8,1950.7,1036.8,1952,1037.1z"/>
							<path class="st5" d="M1951.5,1048.1c-3.3-0.8-4.6,4.3-1.3,5.1C1953.5,1054.1,1954.7,1049,1951.5,1048.1z"/>
							<path class="st5" d="M1959.8,1040.6c-2.2,0.6-2.7,4.4,0,5.1c3,0.8,4.3-3.4,2-4.8c-0.2-0.1-0.4-0.2-0.7-0.3
								C1960.6,1040.5,1960.2,1040.5,1959.8,1040.6z"/>
							<path class="st5" d="M1970.2,1051.8c-1.9-0.5-3.2,1.1-3.2,2.6c0,1.1,0.6,2.1,1.9,2.5c0.5,0.1,1,0.1,1.4,0
								C1972.5,1056.1,1972.9,1052.4,1970.2,1051.8z"/>
							<path class="st5" d="M1967,1037.9c3.3,0.8,4.6-4.3,1.3-5.1C1965,1032,1963.8,1037,1967,1037.9z"/>
							<path class="st5" d="M1944.1,1038.8c-0.9-0.2-1.6,0-2.2,0.4c-1.4,1.1-1.5,3.8,0.6,4.6c0.1,0,0.2,0.1,0.2,0.1
								C1946.1,1044.8,1947.4,1039.7,1944.1,1038.8z"/>
							<path class="st5" d="M1979.1,1046.3c-0.1-1.4-1.2-2.6-2.7-2.6c-0.6,0-1.3,0-1.9,0.1c-1.4,0-2.7,1.3-2.6,2.7
								c0.1,1.4,1.2,2.6,2.7,2.6c0.6,0,1.3,0,1.9-0.1C1977.9,1049,1979.2,1047.7,1979.1,1046.3z"/>
							<path class="st5" d="M1961,1054.1c-0.1-0.2-0.3-0.4-0.4-0.7c-0.4-0.7-1.3-1.2-2.2-1.3c-0.5,0-1,0.1-1.4,0.4
								c-1.2,0.8-1.7,2.3-0.9,3.6c0.1,0.2,0.3,0.4,0.4,0.7c0.7,1.2,2.4,1.7,3.6,0.9c0.7-0.5,1.2-1.2,1.3-2
								C1961.4,1055.1,1961.3,1054.6,1961,1054.1z"/>
							<path class="st5" d="M1940.6,1053.3c-1.4,0.1-2.6,1.2-2.6,2.7c0,0.6,0,1.3,0.1,1.9c0,0.2,0.1,0.4,0.1,0.7
								c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0.5,0.6,1.2,1,2,1
								c1.4-0.1,2.6-1.2,2.6-2.7c0-0.6,0-1.3-0.1-1.9C1943.3,1054.4,1942.1,1053.2,1940.6,1053.3z"/>
							<path class="st5" d="M1966.3,1021c-1-0.9-2.7-1.1-3.7,0c-0.4,0.5-0.8,0.9-1.2,1.4c-0.9,1-1.1,2.7,0,3.7c1,0.9,2.7,1.1,3.7,0
								c0.4-0.5,0.8-0.9,1.2-1.4C1967.2,1023.7,1967.3,1022,1966.3,1021z"/>
							<path class="st5" d="M1978.8,1032.4c-0.5,0.5-1.3,0.6-2,0.2c-1.1,0.7-1.6,2.3-1,3.5c0.2,0.5,0.6,0.9,1.2,1.1
								c0.1,0.1,0.3,0.1,0.4,0.2C1980.6,1038.2,1981.9,1033.3,1978.8,1032.4z"/>
							<path class="st5" d="M1936.3,1029c0.2,0.8,0.8,1.6,1.8,1.9c3.3,0.8,4.6-4.3,1.3-5.1c-0.1,0-0.3,0-0.4-0.1
								C1937.1,1025.5,1936,1027.4,1936.3,1029z"/>
							<path class="st5" d="M1929.4,1051.4c-0.2,0-0.4-0.1-0.6-0.1c-2.2-0.1-3.2,2.5-2.2,4.1c0.3,0.5,0.8,0.9,1.5,1.1
								C1931.4,1057.3,1932.7,1052.3,1929.4,1051.4z"/>
							<path class="st5" d="M1986.2,1057.2c-2.4-0.6-3.8,2-2.9,3.8h4.5C1988.5,1059.6,1988.1,1057.7,1986.2,1057.2z"/>
							<path class="st5" d="M1927,1039.4c0.1-0.6,0.1-1.2-0.2-1.8c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.5
								c-2.9-0.7-4.2,3.1-2.3,4.6c0.3,0.2,0.6,0.4,1,0.5C1925.6,1041.8,1926.7,1040.7,1927,1039.4z"/>
							<path class="st5" d="M1944.7,1015.7c3.3,0.8,4.6-4.3,1.3-5.1C1942.7,1009.8,1941.4,1014.8,1944.7,1015.7z"/>
							<path class="st5" d="M1992.5,1004.5c-2-1.6-4.4,1.5-2.4,3.1C1992,1009.2,1994.5,1006.1,1992.5,1004.5z"/>
							<path class="st5" d="M1983.8,1018.1c2,1.6,4.4-1.5,2.4-3.1C1984.3,1013.4,1981.8,1016.5,1983.8,1018.1z"/>
							<path class="st5" d="M1995.2,1012.8c-2-1.6-4.4,1.5-2.4,3.1C1994.7,1017.5,1997.1,1014.4,1995.2,1012.8z"/>
							<path class="st5" d="M1998,1023.2c-2-1.6-4.4,1.5-2.4,3.1C1997.5,1027.8,2000,1024.8,1998,1023.2z"/>
							<path class="st5" d="M2002.5,1009.6c-2-1.6-4.4,1.5-2.4,3.1C2002.1,1014.3,2004.5,1011.2,2002.5,1009.6z"/>
							<path class="st5" d="M1981.7,1009.5c2,1.6,4.4-1.5,2.4-3.1C1982.1,1004.8,1979.7,1007.9,1981.7,1009.5z"/>
							<path class="st5" d="M2005.7,1022.2c0.4-1,0-2.2-1-2.6c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.2,0-2.6,1c-0.4,1,0,2.2,1,2.6
								c0.5,0.2,0.9,0.4,1.4,0.6C2004.1,1023.7,2005.4,1023.2,2005.7,1022.2z"/>
							<path class="st5" d="M1990.9,1021.4c-0.1-1-1.1-1.9-2.2-1.7c-1.1,0.2-1.9,1.1-1.7,2.2c0,0.2,0,0.4,0.1,0.6
								c0.1,1,1.1,1.9,2.2,1.7c1.1-0.2,1.9-1.1,1.7-2.2C1990.9,1021.8,1990.9,1021.6,1990.9,1021.4z"/>
							<path class="st5" d="M1975.2,1020.1c1,0.4,2.2,0,2.6-1c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.2-1-2.6c-1-0.4-2.2,0-2.6,1
								c-0.2,0.5-0.4,0.9-0.6,1.4C1973.8,1018.5,1974.2,1019.8,1975.2,1020.1z"/>
							<path class="st5" d="M2002.4,1004.1c0.4-0.2,0.8-0.4,1.2-0.6c0.9-0.4,1.6-1.5,1.1-2.5c-0.4-0.9-1.6-1.6-2.5-1.1
								c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0.4-1.6,1.5-1.1,2.5C2000.2,1003.9,2001.4,1004.5,2002.4,1004.1z"/>
							<path class="st5" d="M1977.9,1023.5c-0.7,0.9-0.5,2,0.3,2.8c0.6,0.5,1.2,1.1,1.8,1.6c0.8,0.7,2.2,0.5,2.8-0.3
								c0.7-0.9,0.5-2-0.3-2.8c-0.6-0.5-1.2-1.1-1.8-1.6C1979.9,1022.4,1978.5,1022.6,1977.9,1023.5z"/>
							<path class="st5" d="M1988.3,1031.2c-0.9-0.7-2-0.5-2.8,0.3c-0.8,0.8-1.3,2-1.3,3.1c0,0.1,0,0.2,0,0.4
								c0.1,0.9,0.7,1.8,1.7,1.9c0.2,0,0.4,0,0.6,0c0.8-0.1,1.6-0.7,1.7-1.6c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.1-0.3
								c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3C1989.4,1033.2,1989.1,1031.8,1988.3,1031.2z M1988.2,1034.7
								c0-0.1,0-0.2,0.1-0.2C1988.2,1034.6,1988.2,1034.7,1988.2,1034.7z M1988.3,1034.4c0,0,0-0.1,0.1-0.2
								C1988.4,1034.3,1988.3,1034.3,1988.3,1034.4z"/>
							<path class="st5" d="M1996.2,1032.3c-2-1.6-4.4,1.5-2.4,3.1C1995.7,1036.9,1998.2,1033.8,1996.2,1032.3z"/>
							<path class="st5" d="M1978.8,1032.4c0.9-0.7,1.2-2.1,0.1-3.1c-2-1.6-4.4,1.5-2.4,3.1c0.1,0.1,0.2,0.2,0.3,0.2
								C1977.4,1033,1978.2,1032.8,1978.8,1032.4z"/>
							<path class="st5" d="M1971.4,1024.9c0.6-0.8,0.6-1.9-0.3-2.7c-2-1.6-4.4,1.5-2.4,3.1c0.2,0.2,0.4,0.3,0.6,0.3
								C1970.1,1025.9,1971,1025.5,1971.4,1024.9z"/>
							<path class="st5" d="M2009.7,1012.5c-2-1.6-4.4,1.5-2.4,3.1C2009.3,1017.2,2011.7,1014.1,2009.7,1012.5z"/>
							<path class="st5" d="M1982.4,999.1c2,1.6,4.4-1.5,2.4-3.1C1982.9,994.4,1980.5,997.5,1982.4,999.1z"/>
							<path class="st5" d="M1967.7,1013.6c2,1.6,4.4-1.5,2.4-3.1C1968.2,1008.9,1965.7,1012,1967.7,1013.6z"/>
							<path class="st5" d="M2007.3,1031.8c-2-1.6-4.4,1.5-2.4,3.1C2006.8,1036.5,2009.3,1033.4,2007.3,1031.8z"/>
							<path class="st5" d="M1969.5,1001.9c2,1.6,4.4-1.5,2.4-3.1C1969.9,997.3,1967.5,1000.3,1969.5,1001.9z"/>
							<path class="st5" d="M1991.6,990.7c2,1.6,4.4-1.5,2.4-3.1C1992,986.1,1989.6,989.2,1991.6,990.7z"/>
							<path class="st5" d="M2027.5,987.7c2.7,0.5,3.4-3.8,0.7-4.2C2025.5,983,2024.8,987.2,2027.5,987.7z"/>
							<path class="st5" d="M2027.1,1000.9c2.7,0.5,3.4-3.8,0.7-4.2C2025.1,996.3,2024.3,1000.5,2027.1,1000.9z"/>
							<path class="st5" d="M2034.4,994.2c2.7,0.5,3.4-3.8,0.7-4.2C2032.4,989.5,2031.7,993.7,2034.4,994.2z"/>
							<path class="st5" d="M2042.5,1002.7c2.7,0.5,3.4-3.8,0.7-4.2C2040.5,998,2039.8,1002.2,2042.5,1002.7z"/>
							<path class="st5" d="M2039.8,987.3c2.7,0.5,3.4-3.8,0.7-4.2C2037.8,982.7,2037.1,986.9,2039.8,987.3z"/>
							<path class="st5" d="M2021.2,989.6c-2.7-0.5-3.4,3.8-0.7,4.2C2023.2,994.3,2023.9,990.1,2021.2,989.6z"/>
							<path class="st5" d="M2050.2,993.5c-0.2-1.1-1.2-2-2.3-1.9c-0.5,0.1-1.1,0.1-1.6,0.2c-0.6,0.1-1.2,0.4-1.5,0.9
								c-0.3,0.4-0.4,0.9-0.4,1.4c0.1,0.7,0.6,1.4,1.2,1.7c0.3,0.2,0.7,0.3,1.1,0.2c0.5-0.1,1.1-0.1,1.6-0.2
								C2049.4,995.7,2050.3,994.6,2050.2,993.5z"/>
							<path class="st5" d="M2032.4,1003.4c0.6,0.9,2.1,1.2,3,0.5c0.9-0.7,1.2-2,0.5-3c-0.1-0.2-0.2-0.3-0.4-0.5
								c-0.6-0.9-2.1-1.2-3-0.5c-0.9,0.7-1.2,2-0.5,3C2032.2,1003.1,2032.3,1003.3,2032.4,1003.4z"/>
							<path class="st5" d="M2019.9,1007.4c1.1-0.2,2-1.2,1.9-2.3c-0.1-0.5-0.1-1.1-0.2-1.6c-0.1-1.1-1.2-2.1-2.3-1.9
								c-1.1,0.2-2,1.2-1.9,2.3c0.1,0.5,0.1,1.1,0.2,1.6C2017.7,1006.6,2018.7,1007.6,2019.9,1007.4z"/>
							<path class="st5" d="M2035.1,973.9c-0.3,0.4-0.6,0.8-0.9,1.2c-0.7,0.9-0.7,2.3,0.3,3c0.9,0.7,2.3,0.7,3-0.3
								c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,0.7-2.3-0.3-3C2037.2,973,2035.8,973,2035.1,973.9z"/>
							<path class="st5" d="M2025.9,1011.7c0.9,0.2,1.7,0.4,2.6,0.6c1.1,0.3,2.3-0.7,2.5-1.8c0.2-1.2-0.6-2.2-1.8-2.5
								c-0.9-0.2-1.7-0.4-2.6-0.6c-1.1-0.3-2.3,0.7-2.5,1.8C2024,1010.4,2024.7,1011.4,2025.9,1011.7z"/>
							<path class="st5" d="M2040.1,1015c0-0.1-0.1-0.2-0.2-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.1-0.5
								c0.3-1.1-0.7-2.3-1.8-2.5c-1.2-0.2-2.2,0.6-2.5,1.8c-0.3,1.2-0.2,2.5,0.4,3.6c0.5,1,1.6,1.7,2.8,1.2
								C2039.8,1017.3,2040.6,1016.1,2040.1,1015z M2039.8,1014c0,0,0,0.1,0,0.2C2039.8,1014.2,2039.8,1014.1,2039.8,1014z
								M2039.9,1014.6c0-0.1-0.1-0.2,0-0.2C2039.9,1014.5,2039.9,1014.5,2039.9,1014.6z"/>
							<path class="st5" d="M2045.6,1012.3c2.7,0.5,3.4-3.8,0.7-4.2C2043.6,1007.6,2042.8,1011.8,2045.6,1012.3z"/>
							<path class="st5" d="M2028.1,1014.3c-2.7-0.5-3.4,3.8-0.7,4.2C2030.1,1019,2030.8,1014.8,2028.1,1014.3z"/>
							<path class="st5" d="M2017.1,1011.5c-2.7-0.5-3.4,3.8-0.7,4.2C2019.1,1016.2,2019.8,1012,2017.1,1011.5z"/>
							<path class="st5" d="M2048.2,986.3c2.7,0.5,3.4-3.8,0.7-4.2C2046.2,981.6,2045.5,985.9,2048.2,986.3z"/>
							<path class="st5" d="M2015.8,983.5c2.7,0.5,3.4-3.8,0.7-4.2C2013.8,978.8,2013.1,983,2015.8,983.5z"/>
							<path class="st5" d="M2009.3,1005c2.7,0.5,3.4-3.8,0.7-4.2C2007.3,1000.3,2006.6,1004.6,2009.3,1005z"/>
							<path class="st5" d="M2057.7,1005.5c0.6-0.6,0.8-1.5,0.6-2.3c-0.2-0.7-0.7-1.3-1.6-1.4c-0.1,0-0.2,0-0.3,0
								c-1.4-0.1-2.2,1.3-2.1,2.4c0.1,0.8,0.6,1.6,1.7,1.8C2056.7,1006.2,2057.3,1006,2057.7,1005.5z"/>
							<path class="st5" d="M2004.9,993c2.7,0.5,3.4-3.8,0.7-4.2C2002.9,988.3,2002.2,992.5,2004.9,993z"/>
							<path class="st5" d="M2020.2,970.7c2.7,0.5,3.4-3.8,0.7-4.2C2018.2,966,2017.5,970.2,2020.2,970.7z"/>
							<path class="st5" d="M2054.9,944.8c0-0.4,0-0.8,0.1-1.2c-1.1,0.4-1.8,2.1-0.4,3c0.4,0.3,0.9,0.3,1.2,0.2
								C2055.3,946.4,2054.9,945.6,2054.9,944.8z"/>
							<path class="st5" d="M2052,953.1c-1.8-1.2-3.6,1.6-1.8,2.8C2051.9,957.1,2053.8,954.3,2052,953.1z"/>
							<path class="st5" d="M2059.3,950.6c-1.8-1.2-3.6,1.6-1.8,2.8C2059.3,954.6,2061.1,951.8,2059.3,950.6z"/>
							<path class="st5" d="M2060.7,962c1.8,1.2,3.6-1.6,1.8-2.8C2060.7,958,2058.9,960.8,2060.7,962z"/>
							<path class="st5" d="M2065.3,947.4c-1.8-1.2-3.6,1.6-1.8,2.8C2065.3,951.3,2067.1,948.5,2065.3,947.4z"/>
							<path class="st5" d="M2049.5,946c-1.8-1.2-3.6,1.6-1.8,2.8C2049.5,950,2051.3,947.2,2049.5,946z"/>
							<path class="st5" d="M2067.9,955.7c-0.4-0.1-0.8-0.2-1.2-0.4c-0.8-0.3-1.9,0.2-2.1,1.1c-0.2,0.9,0.2,1.8,1.1,2.1
								c0.4,0.1,0.8,0.2,1.2,0.4c0.8,0.3,1.9-0.2,2.1-1.1C2069.3,956.9,2068.8,955.9,2067.9,955.7z"/>
							<path class="st5" d="M2053.1,958.9c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.1,1.5,2,1.3c0.9-0.2,1.5-1.1,1.3-2c0-0.2-0.1-0.3-0.1-0.5
								c-0.2-0.9-1.1-1.5-2-1.3C2053.5,957.1,2052.9,958,2053.1,958.9z"/>
							<path class="st5" d="M2044.4,953.9c-0.9-0.2-1.8,0.2-2.1,1.1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.3,0.8,0.2,1.9,1.1,2.1
								c0.9,0.2,1.8-0.2,2.1-1.1c0.1-0.4,0.2-0.8,0.4-1.2C2045.7,955.2,2045.3,954.2,2044.4,953.9z"/>
							<path class="st5" d="M2066.5,939.8c-0.4-0.8-1.4-1.2-2.2-0.8c-0.3,0.2-0.7,0.4-1,0.6c-0.8,0.4-1.2,1.4-0.8,2.2
								c0.4,0.8,1.4,1.2,2.2,0.8c0.1-0.1,0.2-0.1,0.4-0.2c0.3-0.7,0.8-1.3,1.5-1.5C2066.8,940.6,2066.7,940.2,2066.5,939.8z"/>
							<path class="st5" d="M2045.5,960.9c-0.5,0.8-0.3,1.7,0.5,2.3c0.5,0.4,1.1,0.8,1.6,1.3c0.7,0.5,1.9,0.3,2.3-0.5
								c0.5-0.8,0.3-1.7-0.5-2.3c-0.5-0.4-1.1-0.8-1.6-1.3C2047.1,959.9,2045.9,960.2,2045.5,960.9z"/>
							<path class="st5" d="M2052.6,967.2c-0.6,0.7-0.9,1.7-0.9,2.7c0,0.9,0.6,1.7,1.6,1.8c0.8,0,1.8-0.7,1.8-1.6c0-0.1,0-0.2,0-0.3
								c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.7,0.2-1.9-0.5-2.3
								C2054.1,966.2,2053.2,966.4,2052.6,967.2z M2055.2,969.3c0,0,0,0.1-0.1,0.1C2055.1,969.4,2055.2,969.3,2055.2,969.3z
								M2055.1,969.7c0-0.1,0-0.1,0-0.2C2055.1,969.6,2055.1,969.6,2055.1,969.7z"/>
							<path class="st5" d="M2059.8,969.8c1.8,1.2,3.6-1.6,1.8-2.8C2059.9,965.8,2058,968.6,2059.8,969.8z"/>
							<path class="st5" d="M2046.7,965.8c-1.8-1.2-3.6,1.6-1.8,2.8C2046.7,969.8,2048.5,967,2046.7,965.8z"/>
							<path class="st5" d="M2037.8,963.2c1.8,1.2,3.6-1.6,1.8-2.8C2037.9,959.2,2036,962,2037.8,963.2z"/>
							<path class="st5" d="M2069.8,952c1.8,1.2,3.6-1.6,1.8-2.8C2069.8,948.1,2068,950.9,2069.8,952z"/>
							<path class="st5" d="M2048.9,936.9c-1.1-0.3-2.1,0.8-2.1,1.8c0.4,0,0.9,0.1,1.4,0.4c0.4,0.3,0.6,0.6,0.8,1
								c0.9-0.4,1.5-1.6,0.9-2.5c-0.2-0.1-0.3-0.1-0.4-0.3C2049.2,937.2,2049.1,937,2048.9,936.9z"/>
							<path class="st5" d="M2036.2,953.3c1.8,1.2,3.6-1.6,1.8-2.8C2036.2,949.3,2034.3,952.1,2036.2,953.3z"/>
							<path class="st5" d="M2069.2,968.6c1.8,1.2,3.6-1.6,1.8-2.8C2069.2,964.6,2067.4,967.4,2069.2,968.6z"/>
							<path class="st5" d="M2038.6,940.5c-1.8-1.2-3.6,1.6-1.8,2.8C2038.6,944.5,2040.4,941.7,2038.6,940.5z"/>
							<path class="st5" d="M2056.5,929.3c-1.8-1.2-3.6,1.6-1.8,2.8c1.3,0.9,2.6-0.4,2.6-1.6c0-0.1,0-0.2-0.1-0.4
								C2057.1,929.9,2056.9,929.6,2056.5,929.3z"/>
							<path class="st5" d="M2059.6,913.1c2.4,1.7,5-2.1,2.6-3.8C2059.7,907.7,2057.2,911.5,2059.6,913.1z"/>
							<path class="st5" d="M2053.2,925.7c2.4,1.7,5-2.1,2.6-3.8C2053.3,920.3,2050.8,924.1,2053.2,925.7z"/>
							<path class="st5" d="M2063.3,922.5c2.4,1.7,5-2.1,2.6-3.8C2063.5,917.1,2060.9,920.8,2063.3,922.5z"/>
							<path class="st5" d="M2067.4,934.3c2.4,1.7,5-2.1,2.6-3.8C2067.5,928.9,2064.9,932.7,2067.4,934.3z"/>
							<path class="st5" d="M2071.6,918.3c0.9,0.6,1.9,0.5,2.6-0.1v-3.7C2071.8,912.9,2069.2,916.6,2071.6,918.3z"/>
							<path class="st5" d="M2050,915.9c2.4,1.7,5-2.1,2.6-3.8C2050.2,910.5,2047.6,914.2,2050,915.9z"/>
							<path class="st5" d="M2073,926.8c-0.3,1.1,0.1,2.3,1.2,2.8v-4.1C2073.6,925.8,2073.2,926.2,2073,926.8z"/>
							<path class="st5" d="M2057.2,930.5c0.2,1.2,1.5,2.1,2.7,1.8c1.2-0.3,2.1-1.4,1.8-2.7c0-0.2-0.1-0.4-0.1-0.6
								c-0.2-1.2-1.5-2.1-2.7-1.8c-1.2,0.3-2.1,1.4-1.8,2.7c0,0.1,0,0.2,0.1,0.3C2057.2,930.3,2057.2,930.4,2057.2,930.5
								C2057.2,930.5,2057.2,930.5,2057.2,930.5z"/>
							<path class="st5" d="M2043.3,928.8c1.2,0.4,2.5-0.2,2.9-1.4c0.2-0.5,0.4-1.1,0.5-1.6c0.4-1.1-0.2-2.6-1.4-2.9
								c-1.2-0.4-2.5,0.2-2.9,1.4c-0.2,0.5-0.4,1.1-0.5,1.6C2041.5,927,2042.1,928.4,2043.3,928.8z"/>
							<path class="st5" d="M2073,903.2c-0.5,0.2-0.9,0.5-1.4,0.7c-1.1,0.6-1.7,1.9-1.1,3c0.5,1,1.9,1.7,3,1.1
								c0.2-0.1,0.4-0.2,0.6-0.3V903C2073.8,902.9,2073.4,903,2073,903.2z"/>
							<path class="st5" d="M2047.2,935.6c0.5,0.4,1.1,0.9,1.6,1.3c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.3
								c0.9,0.4,2.2,0,2.7-0.8c0.7-1.1,0.4-2.4-0.6-3.2c-0.7-0.6-1.5-1.2-2.2-1.8c-0.9-0.8-2.6-0.4-3.2,0.6
								C2046,933.5,2046.2,934.8,2047.2,935.6z"/>
							<path class="st5" d="M2059.4,940.6c-1.1-0.7-2.4-0.4-3.2,0.6c-0.6,0.7-1,1.5-1.1,2.4c-0.1,0.4-0.1,0.8-0.1,1.2
								c0,0.8,0.3,1.5,0.9,2c0.3,0.3,0.7,0.4,1.2,0.4c1.1,0,2.5-0.8,2.4-2.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3
								c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.3-0.3C2060.7,942.8,2060.4,941.2,2059.4,940.6z M2059.5,944.7
								c0-0.1,0-0.2,0-0.2C2059.5,944.5,2059.5,944.6,2059.5,944.7z M2059.6,944.3c0,0,0-0.1,0.1-0.2
								C2059.6,944.2,2059.6,944.2,2059.6,944.3z"/>
							<path class="st5" d="M2065.2,942.4c-0.3,0.9-0.2,1.9,0.8,2.6c2.4,1.7,5-2.1,2.6-3.8c-0.7-0.4-1.3-0.5-1.9-0.3
								C2066,941.2,2065.4,941.7,2065.2,942.4z"/>
							<path class="st5" d="M2048.2,939.1c-0.5-0.3-0.9-0.4-1.4-0.4c-1.9,0.1-3.2,2.8-1.2,4.2c2,1.4,4.2-1,3.4-2.8
								C2048.9,939.7,2048.6,939.4,2048.2,939.1z"/>
							<path class="st5" d="M2036.2,935.2c2.4,1.7,5-2.1,2.6-3.8C2036.3,929.8,2033.7,933.6,2036.2,935.2z"/>
							<path class="st5" d="M2050.1,903.8c2.4,1.7,5-2.1,2.6-3.8C2050.3,898.4,2047.7,902.1,2050.1,903.8z"/>
							<path class="st5" d="M2034.2,921.7c2.4,1.7,5-2.1,2.6-3.8C2034.3,916.3,2031.7,920,2034.2,921.7z"/>
							<path class="st5" d="M2035.3,908.1c2.4,1.7,5-2.1,2.6-3.8C2035.5,902.6,2032.9,906.4,2035.3,908.1z"/>
							<path class="st5" d="M2060.1,893.4c2.4,1.7,5-2.1,2.6-3.8C2060.3,888,2057.7,891.7,2060.1,893.4z"/>
						</g>
						<g>
							<path class="st5" d="M2019.4,1023.3c-1.7,15.4-10.6,26.7-20.3,37.6h-7.2c2.6-2.9,5.1-5.7,7.6-8.6
								c8.9-10.6,17.6-25.4,13.8-39.8c-3.5-13.4-16.9-18.9-28.9-22.4c-12.5-3.7-25.4-5.8-38.1-8.5c-12.9-2.7-25.7-6-37.7-11.5
								c-11.9-5.5-22.5-13.4-33.4-20.6c-11.2-7.5-22.7-13.9-35.8-17.3c-13.4-3.4-27.3-4-41.1-3.2c-18.3,1-36.3,4.4-54.4,7.1v-5.5
								c23.3-3.5,46.5-8.1,70.1-7.3c14.1,0.5,28.2,2.9,41.3,8.6c12.6,5.5,23.7,13.7,35,21.3c12.1,8.1,24.5,14.5,38.5,18.6
								c14.1,4.1,28.5,6.3,42.8,9.5C1993.9,986.2,2022.4,994.9,2019.4,1023.3z"/>
							<path class="st5" d="M2074.2,985.6v6.7c-15-10.5-28.6-22.9-41-37c-23.1-26.2-42.9-55.6-63.3-83.9
								c-10.7-14.8-21.7-29.5-31.5-44.8c-8.6-13.4-15.1-28.4-13.2-44.6c1.8-15.2,8.9-27.8,18.6-38.6l4.2,3.9
								c-13.4,14.9-21.8,32.9-15.7,54.2c4.6,16.1,15.8,30,25.4,43.4c10,14.2,20.2,28.2,30.3,42.3c18.9,26.2,37.4,53.1,60.1,76.2
								C2056.2,971.5,2064.9,978.9,2074.2,985.6z"/>
							<path class="st5" d="M1950,932.3c-18.3,2.8-37.9-4.6-53.3-13.9c-17.2-10.4-31.5-24.9-45.5-39.2
								c-14.5-14.8-29.4-29.1-46.3-41.2c-15.6-11.2-32.4-19.8-48.9-29.5c-4.1-2.4-8.2-5-12.1-7.9v-6.9c7.1,5.6,15,10.2,22.9,14.6
								c17.4,9.7,34.3,19.5,50,31.8c15.1,11.7,28.4,25.2,41.7,38.8c13.4,13.7,27.3,27.2,44.2,36.7c8.2,4.6,17,8.2,26.3,10.2
								c5.2,1.1,10.5,1.6,15.8,1.4c1.7,0,4.2,0.1,5.8-0.6c2.3-0.1,3-1.3,2-3.7c0-1,0-2-0.1-3c-0.3-0.7-1.3-1.5-1.8-2
								c-11.9-13.5-27-23.5-41.8-33.6c-14.8-10.1-28.7-20.8-41.2-33.7c-13.8-14.3-26.1-29.8-38.8-45.1
								c-13.4-16.1-26.8-32.1-40.1-48.2c-8.8-10.5-17.6-21-25.9-31.9h6.9c11.1,14.4,23.1,28.2,34.7,42.2
								c16.2,19.4,32.2,38.9,48.5,58.2c13.3,15.8,27.4,31,44,43.4c14.8,11,30.8,20.4,44.8,32.4c5.8,4.9,14.3,11.4,17.3,18.7
								C1962.2,927.1,1956.1,931.4,1950,932.3z"/>
							<g>
								<path class="st3" d="M1743.9,893.6c-1.9,0-3.5-1.6-3.5-3.5V743.3c0-11.9,9.6-21.5,21.5-21.5h151.7c1.9,0,3.5,1.6,3.5,3.5
									s-1.6,3.5-3.5,3.5H1762c-8,0-14.5,6.5-14.5,14.5v146.8C1747.4,892,1745.9,893.6,1743.9,893.6z"/>
							</g>
							<g>
								<path class="st0" d="M2074.2,871v19h-233.1c-5,0-9-4-9-9v-83.4c0-5,4-9,9-9h152l0.4,0.4l4.5,4.2l7.3,6.7l17.2,15.8l45.7,42
									C2072.1,861.2,2074.2,866,2074.2,871z"/>
								<polygon class="st6" points="1967.4,788.7 1926.2,890.1 1877.3,890.1 1918.4,788.7 							"/>
								<polygon class="st6" points="2022.6,815.8 1992.5,890.1 1958.8,890.1 1998.1,793.2 2005.4,800 							"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path id="XMLID_2_" class="st5" d="M1689.4,604.4v413.1c0,9.9-8.1,18-18,18H496.2c-13,0-21.7-13.4-16.5-25.2
							c0.1-0.2,0.2-0.4,0.3-0.6l2-4.1l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8
							l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l21.3-44.3l2.3-4.8l8-16.7c3-6.2,9.3-10.2,16.2-10.2h976.5
							C1681.4,586.4,1689.4,594.5,1689.4,604.4z"/>
						<g>
							<path class="st4" d="M766.3,594.9c1-0.4,1.5-1.2,1.6-2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.7-0.7-0.8-1.4-0.7-2.2
								c-0.5-0.3-1.2-0.5-2-0.2C761.6,591.2,763.2,595.9,766.3,594.9z"/>
							<path class="st4" d="M781.8,588c0.4,0.1,0.9,0.1,1.4-0.1c0.8-0.3,1.3-0.9,1.5-1.5h-3.2C781.9,587,781.9,587.5,781.8,588z"/>
							<path class="st4" d="M754.8,591.4c0.3-1,1.2-1.8,2.3-1.9c0.2-1.2-0.4-2.6-1.6-3H754c0,0-0.1,0-0.1,0
								C751,587.4,752.2,591.6,754.8,591.4z"/>
							<path class="st4" d="M771.2,592.1c0.1,0.8,0.6,1.6,1.4,2c0.2,0.1,0.4,0.2,0.7,0.3c1.2,0.6,2.8,0.1,3.4-1.2
								c0.5-1.3,0.1-2.8-1.2-3.4c-0.2-0.1-0.4-0.2-0.7-0.3c-1-0.5-2.3-0.2-3,0.6C771.9,590.9,771.6,591.6,771.2,592.1z"/>
							<path class="st4" d="M759,604.9c0.8,1.1,2.4,1.5,3.5,0.7c1.1-0.8,1.5-2.3,0.7-3.5c0,0,0,0-0.1-0.1c-0.5,0.4-1,0.6-1.7,0.6
								c-1.4,0-2.6-1.3-2.6-2.6c0-0.1,0-0.2,0-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.8-1.5,2.3-0.7,3.5
								C758.3,603.9,758.7,604.4,759,604.9z"/>
							<path class="st4" d="M775.7,602.6c-0.5-1.4-1.8-1.9-3.2-1.6c-1,0.3-2,0.5-3,0.8c-1.3,0.3-2,2-1.6,3.2c0.3,0.7,0.8,1.2,1.5,1.5
								c0,0-0.1,0-0.1-0.1c-2.3-2.4,1.4-6,3.7-3.6c1,1.1,0.9,2.3,0.2,3.3c0.3-0.1,0.6-0.2,1-0.2C775.4,605.5,776.2,603.8,775.7,602.6
								z"/>
							<path class="st4" d="M787.7,602c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0
								c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2,1-0.9,1.9-2.2,1.9c-0.3,0-0.5,0-0.7-0.1c0,0.3-0.1,0.7-0.3,1
								c-0.2,0.3-0.4,0.6-0.8,0.8c0.5,0.9,1.1,1.6,1.9,2.2c1.1,0.8,2.7,0.9,3.6-0.3C788.7,604.6,788.8,602.8,787.7,602z M786.9,601.2
								c0,0,0.1,0.1,0.1,0.2C786.9,601.4,786.9,601.3,786.9,601.2z M787.1,601.5c0.1,0.1,0.1,0.1,0.2,0.2
								C787.2,601.7,787.1,601.6,787.1,601.5z"/>
							<path class="st4" d="M791.8,596.2c3-1,1.5-5.5-1.4-4.8c1,1.5-0.1,3.3-1.5,3.8C789.4,596,790.5,596.6,791.8,596.2z"/>
							<path class="st4" d="M774.9,608.1c-3.1,1.1-1.4,5.8,1.6,4.8C779.6,611.8,778,607,774.9,608.1z"/>
							<path class="st4" d="M761.9,611.5c-3.1,1.1-1.4,5.8,1.6,4.8C766.6,615.2,765,610.4,761.9,611.5z"/>
							<path class="st4" d="M748.8,605.9c-0.3-0.4-0.6-0.8-1-1.1c-1,0.8-1.1,2.1-0.5,3.2c0.1,0.1,0.1,0.2,0.2,0.3
								c0.7-0.7,1.8-0.9,2.7-0.1c0.3,0.2,0.5,0.5,0.6,0.7c0.9-0.6,1.2-1.6,1.1-2.5C750.8,606.9,749.5,606.8,748.8,605.9z"/>
							<path class="st4" d="M739.9,596.6c0.1-0.3,0.2-0.6,0.4-0.9c-0.5-1.1-1.6-1.9-3-1.4c-1.1,0.4-1.6,1.2-1.6,2.1
								c0.8-0.3,1.8-0.2,2.6,0.6c0.6,0.6,0.8,1.4,0.7,2c0.1,0,0.2-0.1,0.3-0.1C738.7,598.2,738.9,597,739.9,596.6z"/>
							<path class="st4" d="M697.7,589.5c1.8,0,2.1-2.2,1.1-3.1h-2.1C695.6,587.3,695.9,589.5,697.7,589.5z"/>
							<path class="st4" d="M698.9,600c2.2,0,2.2-3.4,0-3.4C696.8,596.5,696.7,599.9,698.9,600z"/>
							<path class="st4" d="M703.9,593.7c2.2,0,2.2-3.4,0-3.4C701.7,590.3,701.7,593.7,703.9,593.7z"/>
							<path class="st4" d="M711.3,599.4c1.3,0,1.9-1.2,1.6-2.2c-0.1,0-0.2,0-0.4,0c-0.8,0-1.3-0.6-1.4-1.2
								C709.2,596.2,709.2,599.4,711.3,599.4z"/>
							<path class="st4" d="M707,587.7c-0.2-0.4-0.2-0.8-0.1-1.2h-1.2C705.8,587,706.3,587.5,707,587.7z"/>
							<path class="st4" d="M692.9,595.2c2.2,0,2.2-3.4,0-3.4C690.7,591.7,690.7,595.2,692.9,595.2z"/>
							<path class="st4" d="M713.8,593.6c0.4-0.1,0.8-0.2,1.2-0.3c0.9-0.2,1.5-1.2,1.2-2.1c-0.3-0.9-1.2-1.5-2.1-1.2
								c-0.4,0.1-0.8,0.2-1.2,0.3c-0.9,0.2-1.5,1.2-1.2,2.1C711.9,593.3,712.8,593.9,713.8,593.6z"/>
							<path class="st4" d="M703.2,598.5c-0.6,0.7-0.7,1.7,0,2.4c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.6,1.8,0.7,2.4,0
								c0.6-0.7,0.7-1.7,0-2.4c-0.1-0.1-0.2-0.2-0.3-0.4C704.9,597.9,703.8,597.8,703.2,598.5z"/>
							<path class="st4" d="M693.3,605.9c0.3,0.1,0.5,0.1,0.8,0c0.9-0.3,1.5-1.2,1.2-2.1c-0.1-0.4-0.2-0.8-0.3-1.2
								c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.5,1.2-1.2,2.1c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.3,0.7,0.6,0.9
								C692.9,605.7,693.1,605.8,693.3,605.9z"/>
							<path class="st4" d="M700.4,605.2c-0.3,0-0.7-0.1-1-0.1c-0.9-0.1-1.8,0.8-1.7,1.7c0,1,0.7,1.6,1.7,1.7c0.1,0,0.2,0,0.3,0
								C699,607.5,699.1,606,700.4,605.2z"/>
							<path class="st4" d="M707.2,608.4c-0.1,0.9,0.2,1.9,0.7,2.7c0.5,0.7,1.5,1.2,2.3,0.6c0.7-0.5,1.2-1.5,0.6-2.3
								c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.7c0,0-0.1,0-0.1,0
								C708.7,607.4,708.1,608.2,707.2,608.4z"/>
							<path class="st4" d="M714.9,603.2c-2.2,0-2.2,3.4,0,3.4C717.1,606.6,717.1,603.2,714.9,603.2z"/>
							<path class="st4" d="M701.4,610.3c-2.2,0-2.2,3.4,0,3.4C703.6,613.8,703.6,610.4,701.4,610.3z"/>
							<path class="st4" d="M693.5,611.3c0.2-0.1,0.4-0.1,0.5-0.2c0-0.8-0.6-1.6-1.6-1.6c-2.1,0-2.2,3.2-0.2,3.4
								C692.3,612.3,692.7,611.7,693.5,611.3z"/>
							<path class="st4" d="M686.7,604.8c0.8-1,0.4-2.8-1.2-2.9c-1.4,0-1.9,1.4-1.5,2.4c0.4-0.2,0.9-0.3,1.3-0.3
								C685.9,604.2,686.4,604.4,686.7,604.8z"/>
							<path class="st4" d="M720.7,598.1c-0.3,1,0.2,2.3,1.5,2.3c2.2,0,2.2-3.4,0-3.4c-0.1,0-0.3,0-0.4,0
								C721.7,597.5,721.3,598,720.7,598.1z"/>
							<path class="st4" d="M680.5,596.4c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.4-0.3,0.7-0.4c0.8-0.8,0.6-2.6-0.7-2.9
								c-0.7,0.8-1.3,1.7-1.8,2.7C679.5,596.1,679.9,596.4,680.5,596.4z"/>
							<path class="st4" d="M665.4,635.2c1.4,0,2.3-2,0.9-2.9c-0.2-0.1-0.5-0.2-0.7-0.2C666.4,633,666.4,634.5,665.4,635.2z"/>
							<path class="st4" d="M670.2,630.9c-0.7-0.3-1.3-0.5-2-0.8c0,0.7,0.4,1.3,1.1,1.5c0.4,0.1,0.7,0.2,1.1,0.3
								c0.8,0.2,1.7-0.2,1.9-1.1c0.1-0.2,0.1-0.4,0-0.7C671.9,630.9,671,631.2,670.2,630.9z"/>
							<path class="st4" d="M658,644.2c0.5,0,1-0.3,1.4-0.7c-1.4-0.7-1.6-3.2,0.2-3.8c-0.1,0-0.1-0.1-0.2-0.1
								c-0.4-0.2-0.8-0.3-1.2-0.2l-1.7,3.5C656.7,643.5,657.2,644.2,658,644.2z"/>
							<path class="st4" d="M664,642.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.6-0.5-0.9-1.2-1-1.9C663.2,641.2,663.3,641.9,664,642.3z"/>
							<path class="st4" d="M675.3,623.7c-0.1-0.1-0.3-0.3-0.4-0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1
								c-1.7-1.1-3.4,1.6-1.7,2.7C674.3,626.3,675.6,624.9,675.3,623.7z"/>
							<path class="st4" d="M672.3,638.8c-0.4,0.7-0.4,1.6,0.4,2.1c1.7,1.1,3.4-1.6,1.7-2.7c-0.1,0-0.1-0.1-0.2-0.1
								c-0.2,0.3-0.6,0.5-1,0.6C672.8,638.9,672.6,638.9,672.3,638.8z"/>
							<path class="st4" d="M664.7,635.6c0.3-0.1,0.6-0.2,0.8-0.3c1-0.7,1-2.3,0.2-3.1c-0.4-0.5-1.1-0.7-1.9-0.5
								C661.2,632.2,662.1,636.2,664.7,635.6z"/>
							<path class="st4" d="M669.1,647.5c2.6-0.6,1.7-4.6-0.9-4C665.6,644.1,666.5,648.1,669.1,647.5z"/>
							<path class="st4" d="M672.3,638.8c0.3,0,0.5,0,0.8,0c0.5-0.1,0.8-0.3,1-0.6c1.2-1.3,0.2-3.9-2-3.4
								C670,635.3,670.4,638.5,672.3,638.8z"/>
							<path class="st4" d="M683.5,643.4c2.6-0.6,1.7-4.6-0.9-4C680,639.9,680.9,644,683.5,643.4z"/>
							<path class="st4" d="M674.5,626.8c-2.6,0.6-1.7,4.6,0.9,4C678,630.2,677.1,626.2,674.5,626.8z"/>
							<path class="st4" d="M659.3,643.5c0.4,0.2,0.8,0.2,1.3,0.1c2.6-0.6,1.7-4.6-0.9-4c-0.1,0-0.1,0-0.2,0.1
								C657.7,640.3,657.9,642.8,659.3,643.5z"/>
							<path class="st4" d="M681.7,633.8c-0.1,0.4,0,0.9,0.2,1.3c0.6,1,1.8,1.4,2.8,0.8c0.4-0.2,0.9-0.5,1.3-0.7
								c0.7-0.4,1.2-1.3,1.1-2.2c-0.3,0.1-0.7,0.1-1.1,0c-0.9-0.3-1.3-0.9-1.3-1.6c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.7,0.4-1,0.6
								C682.9,632.8,682.5,633.4,681.7,633.8z"/>
							<path class="st4" d="M676.4,646.8c0.5-0.2,1-0.3,1.4-0.2c0.3-0.9,0-1.8-0.8-2.4c-0.2-0.1-0.3-0.2-0.5-0.3
								c-0.9-0.6-2.3-0.3-2.8,0.6c-0.6,1-0.3,2.2,0.6,2.8c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.2
								C675.4,647.5,675.8,647.1,676.4,646.8z"/>
							<path class="st4" d="M662.3,655c0.5,0.9,1.8,1.4,2.8,0.8c1-0.6,1.4-1.8,0.8-2.8c-0.2-0.4-0.5-0.9-0.7-1.3
								c-0.5-0.9-1.8-1.4-2.8-0.8c-1,0.6-1.4,1.8-0.8,2.8C661.8,654.1,662.1,654.6,662.3,655z"/>
							<path class="st4" d="M667.3,624.5c0.1,0,0.2,0,0.3,0.1c0.1-0.7,0.5-1.4,1.3-1.8c0.4-0.2,0.8-0.2,1.1-0.2
								c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.6,0.1-1.2-0.2-1.7c-0.4-0.2-0.7-0.5-0.8-0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7-0.1-1,0
								l0,0.1l-1.9,3.9C666.1,623.8,666.6,624.4,667.3,624.5z"/>
							<path class="st4" d="M676.1,654.6c-0.3-1.1-1.3-1.7-2.5-1.5c-0.8,0.1-1.7,0.2-2.5,0.4c-1.1,0.2-1.8,1.4-1.5,2.5
								c0.3,1.1,1.3,1.7,2.5,1.5c0.8-0.1,1.7-0.2,2.5-0.4C675.6,656.9,676.3,655.6,676.1,654.6z"/>
							<path class="st4" d="M685.5,654.9c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2-0.1-0.4-0.1-0.4
								c-0.1-1.1-1.5-1.8-2.5-1.5c-1.2,0.3-1.7,1.3-1.5,2.5c0.1,1.1,0.8,2.3,1.6,3.1c0.8,0.7,2.1,0.9,2.9,0.1c0.7-0.7,1-2.1,0.1-2.9
								C685.7,655.1,685.6,655,685.5,654.9z M685.2,654.4c0,0,0,0.1,0.1,0.2C685.3,654.6,685.3,654.5,685.2,654.4z M685.4,654.7
								c0,0.1,0.1,0.1,0.1,0.2C685.4,654.8,685.4,654.8,685.4,654.7z"/>
							<path class="st4" d="M689.7,650.8c2.3-0.5,1.8-3.7-0.1-4c-0.5,0.4-1.1,0.6-1.8,0.5C686.5,648.5,687.5,651.3,689.7,650.8z"/>
							<path class="st4" d="M675.9,663c2.6-0.6,1.7-4.6-0.9-4C672.4,659.6,673.3,663.6,675.9,663z"/>
							<path class="st4" d="M664.1,660.6c-2.6,0.6-1.7,4.6,0.9,4C667.6,664,666.7,660,664.1,660.6z"/>
							<path class="st4" d="M682.5,626.8c1.5-0.3,1.8-1.8,1.3-2.9c-1,0.2-1.9-0.3-2.4-1.1C679,623.5,679.9,627.4,682.5,626.8z"/>
							<path class="st4" d="M654.8,657.7c2.6-0.6,1.7-4.6-0.9-4C651.3,654.2,652.2,658.2,654.8,657.7z"/>
							<path class="st4" d="M696.6,641.5c2.6-0.6,1.7-4.6-0.9-4C693.1,638,694,642,696.6,641.5z"/>
							<path class="st4" d="M732.1,599.1c-1-0.8-2.2-0.5-2.8,0.3c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.8-0.6,1.1c0,0.6,0.2,1.1,0.7,1.6
								C731.5,604.1,734.2,600.9,732.1,599.1z"/>
							<path class="st4" d="M722.5,613.3c2.1,1.7,4.8-1.5,2.7-3.2C723.1,608.3,720.4,611.6,722.5,613.3z"/>
							<path class="st4" d="M734.7,608c-2.1-1.7-4.8,1.5-2.7,3.2C734.1,612.9,736.8,609.7,734.7,608z"/>
							<path class="st4" d="M734.7,622.3c2.1,1.7,4.8-1.5,2.7-3.2C735.3,617.4,732.6,620.6,734.7,622.3z"/>
							<path class="st4" d="M742.6,604.9c-2.1-1.7-4.8,1.5-2.7,3.2C742,609.8,744.7,606.6,742.6,604.9z"/>
							<path class="st4" d="M720.2,603.8c0.1,0.1,0.1,0.2,0.2,0.2c2.1,1.7,4.8-1.5,2.7-3.2c-1.3-1.1-2.8-0.2-3.3,1
								C720.4,602.3,720.5,603.2,720.2,603.8z"/>
							<path class="st4" d="M745.7,618.3c0.4-1,0-2.3-1-2.8c-0.5-0.2-0.9-0.4-1.4-0.6c-1-0.4-2.3,0-2.8,1c-0.4,1,0,2.3,1,2.8
								c0.5,0.2,0.9,0.4,1.4,0.6C743.9,619.8,745.2,619.4,745.7,618.3z"/>
							<path class="st4" d="M727.6,615.1c-1.1,0.1-2,1.1-1.9,2.3c0,0.2,0,0.4,0.1,0.6c0.1,1.1,1.2,2,2.3,1.9c1.1-0.1,2-1.1,1.9-2.3
								c0-0.2,0-0.4-0.1-0.6C729.8,616,728.8,615,727.6,615.1z"/>
							<path class="st4" d="M716,614.2c0.2-0.5,0.4-0.9,0.6-1.4c0.4-1,0-2.3-1-2.8c-1-0.4-2.3,0-2.8,1c-0.2,0.5-0.4,0.9-0.6,1.4
								c-0.4,1,0,2.3,1,2.8C714.3,615.6,715.6,615.2,716,614.2z"/>
							<path class="st4" d="M740.6,596.5c1.4,0.1,1.8,1.7,1.2,2.6c0.3,0,0.6,0,0.9-0.1c0.4-0.2,0.9-0.4,1.3-0.6
								c1-0.4,1.7-1.6,1.3-2.7c0-0.1-0.1-0.2-0.2-0.3c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2,0-0.3,0.1-0.5,0.1
								c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.6-0.4,0.9C740.1,596.5,740.3,596.5,740.6,596.5z"/>
							<path class="st4" d="M719.4,619c-0.2-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-2.3-0.6-2.9,0.3c-0.7,1-0.6,2.1,0.3,2.9
								c0.6,0.6,1.2,1.2,1.8,1.8c0.8,0.8,2.3,0.6,2.9-0.3c0.1-0.1,0.1-0.1,0.1-0.2C719.2,623.1,718,620.4,719.4,619z"/>
							<path class="st4" d="M726.9,627.4c-1-0.7-2.1-0.6-2.9,0.3c-0.8,0.8-1.4,2-1.5,3.2c-0.1,1.1,0.5,2.3,1.7,2.4
								c1,0.1,2.3-0.5,2.4-1.7c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.3-0.3
								C727.9,629.5,727.7,628,726.9,627.4z M726.6,631.1c0-0.1,0-0.2,0.1-0.2C726.7,631,726.6,631,726.6,631.1z M726.7,630.8
								c0,0,0-0.1,0.1-0.2C726.8,630.6,726.8,630.7,726.7,630.8z"/>
							<path class="st4" d="M732.5,631.9c2.1,1.7,4.8-1.5,2.7-3.2C733.1,627,730.4,630.2,732.5,631.9z"/>
							<path class="st4" d="M714.1,628.3c2.1,1.7,4.8-1.5,2.7-3.2C714.8,623.3,712.1,626.6,714.1,628.3z"/>
							<path class="st4" d="M707.3,619.3c-0.5,0.4-1,0.6-1.6,0.5c0.1,0.2,0.3,0.4,0.5,0.6c2,1.6,4.5-1.2,2.9-2.9
								c-0.1,0.2-0.3,0.4-0.5,0.6C708.2,618.6,707.7,619,707.3,619.3z"/>
							<path class="st4" d="M747.5,608.2c0.5,0.9,0.3,2-0.3,2.7c0.1,0.1,0.2,0.3,0.4,0.4c1.8,1.5,4.1-0.7,3.3-2.5
								c-0.1-0.3-0.3-0.5-0.6-0.7C749.2,607.3,748.2,607.6,747.5,608.2z"/>
							<path class="st4" d="M724.7,592.4c-0.7-0.5-0.8-1.7-0.3-2.4c-0.1-0.1-0.1-0.1-0.2-0.2c-2.1-1.7-4.8,1.5-2.7,3.2
								C722.8,594,724.1,593.4,724.7,592.4z"/>
							<path class="st4" d="M705.5,608c0.6,0.5,1.2,0.6,1.8,0.4c0.8-0.2,1.5-1,1.6-1.8c0.1-0.6-0.1-1.3-0.7-1.8
								C706.1,603,703.4,606.3,705.5,608z"/>
							<path class="st4" d="M744.3,631.8c2.1,1.7,4.8-1.5,2.7-3.2C744.9,626.9,742.3,630.1,744.3,631.8z"/>
							<path class="st4" d="M710.4,592.4c-2.1-1.7-4.8,1.5-2.7,3.2C709.8,597.4,712.5,594.2,710.4,592.4z"/>
							<path class="st4" d="M643.9,672.9c2.1-0.6,1.7-3.4,0-4l-1.6,3.2l-0.2,0.4C642.6,673,643.2,673.2,643.9,672.9z"/>
							<path class="st4" d="M649.2,684.6c2.6-0.8,1.4-4.7-1.2-4C645.4,681.4,646.6,685.4,649.2,684.6z"/>
							<path class="st4" d="M652.7,675.6c2.6-0.8,1.4-4.7-1.2-4C648.9,672.4,650.1,676.4,652.7,675.6z"/>
							<path class="st4" d="M662.2,675.5c-2.6,0.8-1.4,4.7,1.2,4C665.9,678.7,664.7,674.8,662.2,675.5z"/>
							<path class="st4" d="M654.4,667.4c2.6-0.8,1.4-4.7-1.2-4C650.6,664.2,651.8,668.1,654.4,667.4z"/>
							<path class="st4" d="M640.4,681.3c2.3-0.7,1.6-4-0.5-4.1l-1.6,3.4C638.8,681.2,639.5,681.6,640.4,681.3z"/>
							<path class="st4" d="M666,668.2c-0.6-0.9-1.9-1.3-2.9-0.7c-0.4,0.3-0.9,0.6-1.3,0.8c-0.9,0.6-1.3,1.9-0.7,2.9
								c0.6,0.9,1.9,1.3,2.9,0.7c0.4-0.3,0.9-0.6,1.3-0.8C666.3,670.5,666.7,669.1,666,668.2z"/>
							<path class="st4" d="M653.5,681.4c-0.5,1-0.2,2.3,0.8,2.8c0.2,0.1,0.4,0.2,0.5,0.3c1,0.5,2.3,0.2,2.8-0.8
								c0.5-1,0.2-2.3-0.8-2.8c-0.2-0.1-0.4-0.2-0.5-0.3C655.4,680,654,680.4,653.5,681.4z"/>
							<path class="st4" d="M643.1,692.9c0.6,0.7,1.8,1,2.7,0.4c0.9-0.6,1.3-1.9,0.7-2.9c-0.3-0.4-0.6-0.9-0.8-1.3
								c-0.6-0.9-1.9-1.3-2.9-0.7c-0.9,0.6-1.3,1.9-0.7,2.9C642.6,691.7,643,692.3,643.1,692.9z"/>
							<path class="st4" d="M648.3,660.1c0-0.1,0-0.3,0.1-0.4l-0.7,1.5C648,660.9,648.2,660.6,648.3,660.1z"/>
							<path class="st4" d="M654.1,689.9c-0.8,0.2-1.7,0.4-2.5,0.5c-1.1,0.2-1.7,1.6-1.4,2.6c0.4,1.1,1.4,1.6,2.6,1.4
								c0.8-0.2,1.7-0.4,2.5-0.5c1.1-0.2,1.7-1.6,1.4-2.6C656.3,690.2,655.2,689.7,654.1,689.9z"/>
							<path class="st4" d="M666.2,691c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.1-0.4
								c-0.2-1.1-1.6-1.7-2.6-1.4c-1.1,0.4-1.6,1.4-1.4,2.6c0.2,1.1,0.9,2.3,1.8,3c0.9,0.7,2.2,0.8,2.9-0.1c0.7-0.8,0.8-2.2-0.1-2.9
								C666.5,691.2,666.3,691.1,666.2,691z M665.9,690.5c0,0,0,0.1,0.1,0.2C665.9,690.7,665.9,690.6,665.9,690.5z M666.1,690.8
								c0,0.1,0.1,0.1,0.1,0.2C666.1,690.9,666.1,690.9,666.1,690.8z"/>
							<path class="st4" d="M668.9,682.6c-2.6,0.8-1.4,4.7,1.2,4C672.7,685.8,671.5,681.8,668.9,682.6z"/>
							<path class="st4" d="M655.8,695.8c-2.6,0.8-1.4,4.7,1.2,4C659.6,699,658.4,695,655.8,695.8z"/>
							<path class="st4" d="M647.2,698.9c-0.4-0.6-1.2-1-2.2-0.7c-2.6,0.8-1.4,4.7,1.2,4c1-0.3,1.4-1,1.4-1.8
								c-0.1-0.1-0.2-0.3-0.3-0.4C647.2,699.6,647.2,699.2,647.2,698.9z"/>
							<path class="st4" d="M661.2,662.9c2.6-0.8,1.4-4.7-1.2-4C657.5,659.7,658.7,663.7,661.2,662.9z"/>
							<path class="st4" d="M635.5,695.8c2.6-0.8,1.4-4.7-1.2-4C631.8,692.6,632.9,696.6,635.5,695.8z"/>
							<path class="st4" d="M675.2,672.7c-2.6,0.8-1.4,4.7,1.2,4C679,675.9,677.8,671.9,675.2,672.7z"/>
							<path class="st4" d="M629.8,714.8c0.2,0.5,0.4,0.9,0.6,1.4c0,0,0,0.1,0,0.1c0.8-0.6,1.2-1.8,0.3-2.7c-0.8-0.9-2-0.7-2.6,0
								C628.8,713.7,629.5,714.2,629.8,714.8z"/>
							<path class="st4" d="M623.4,722.4c-1.6-1.8-4.4,0.7-2.7,2.5C622.3,726.7,625,724.2,623.4,722.4z"/>
							<path class="st4" d="M630.1,724.8c1.5,0.4,3.2-1.6,1.9-3c-1.4-1.5-3.5,0.1-3.2,1.6C629.5,723.6,630,724.1,630.1,724.8z"/>
							<path class="st4" d="M631.4,732.3c-0.1-0.4,0-0.7,0.1-1.1c-1.3,0.2-2.4,1.8-1.2,3.1c0.9,1,2.1,0.7,2.8-0.1
								C632.2,733.9,631.6,733.2,631.4,732.3z"/>
							<path class="st4" d="M639,722.6c0.7-0.5,1.1-1.5,0.5-2.4c-0.3,0.3-0.7,0.5-1.1,0.6c-0.2,0-0.4,0-0.6,0c0.1,0.7,0,1.5-0.5,2.1
								c0.2,0.1,0.3,0.1,0.5,0.1c0.2-0.2,0.5-0.3,0.9-0.4C638.8,722.6,638.9,722.6,639,722.6z"/>
							<path class="st4" d="M623.1,714.9c-0.4,0.6-1.1,0.8-1.7,0.9l-0.2,0.4l-0.4,0.9C622,717.5,623.4,716.2,623.1,714.9z"/>
							<path class="st4" d="M637.8,731.4c0.1,0.4,0,0.8-0.1,1.2c0.1,0,0.1,0.1,0.2,0.1c0.8,0.5,2.1,0.3,2.6-0.6
								c0.5-0.9,0.3-2-0.6-2.6c-0.4-0.2-0.8-0.5-1.2-0.7c-0.8-0.5-2.1-0.3-2.6,0.6C636.9,729.7,637.6,730.5,637.8,731.4z"/>
							<path class="st4" d="M624.7,731.4c1,0,1.9-0.7,1.9-1.8c0-0.2,0-0.4,0-0.5c0-1-0.8-1.9-1.8-1.9c-1,0-1.9,0.7-1.9,1.8
								c0,0.2,0,0.4,0,0.5c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4C623.3,730.8,624,731.4,624.7,731.4z"/>
							<path class="st4" d="M642.7,712.2c-0.1-0.3-0.3-0.6-0.5-0.9c-0.6,0.9-2,1-2.8,0.4c-0.2-0.1-0.3-0.2-0.5-0.3
								c0,0-0.1-0.1-0.1-0.1c-0.7,0.4-1.2,1.2-1,2c0.2,0.9,1.2,1.7,2.2,1.4c0.4-0.1,0.8-0.2,1.2-0.3
								C642.2,714.1,643,713.2,642.7,712.2z"/>
							<path class="st4" d="M615.5,733.4c0.6,0.8,1.9,0.8,2.6,0.1c0.8-0.8,0.8-1.8,0.1-2.6c-0.5-0.6-0.9-1.2-1.4-1.8
								c-0.4-0.5-1-0.7-1.6-0.6l-1.3,2.8c0.1,0.1,0.1,0.2,0.2,0.3C614.6,732.2,615.1,732.8,615.5,733.4z"/>
							<path class="st4" d="M622.1,739.7c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0c0.8-0.6,0.8-2,0.1-2.6
								c-0.8-0.8-1.8-0.7-2.6-0.1c-0.5,0.4-0.9,0.9-1.2,1.5C619.9,738.5,621.4,738.8,622.1,739.7z"/>
							<path class="st4" d="M627.8,739.6c-1,0.5-1.6,1.8-0.6,2.9c1.1,1.2,2.6,0.5,3.1-0.7c-0.2,0-0.3,0-0.5,0
								C628.5,741.6,627.9,740.6,627.8,739.6z"/>
							<path class="st4" d="M613.4,735.9c0.5,0.1,0.9,0.3,1.2,0.5c0.2-0.6,0.2-1.3-0.3-1.9c-0.5-0.5-1.1-0.7-1.6-0.6l-0.5,1l-0.9,1.8
								C611.7,736.2,612.4,735.8,613.4,735.9z"/>
							<path class="st4" d="M645.6,723.7c-1.6-1.8-4.4,0.7-2.7,2.5C644.5,728,647.2,725.5,645.6,723.7z"/>
							<path class="st4" d="M637.6,743.8c1.6,1.8,4.4-0.7,2.7-2.5C638.7,739.5,636,742,637.6,743.8z"/>
							<path class="st4" d="M634.8,698.1c-1.6-1.8-4.4,0.7-2.7,2.5C633.7,702.4,636.4,699.9,634.8,698.1z"/>
							<path class="st4" d="M674.6,605.2l-0.6,1.3l-0.8,1.7C674.6,608.1,675.3,606.4,674.6,605.2z"/>
							<path class="st4" d="M670,620.2c0.1,0,0.2,0.1,0.3,0.1c2.4,0.8,3.6-3,1.2-3.8c-1.9-0.6-3,1.5-2.3,2.9
								C669.3,619.7,669.6,620,670,620.2z"/>
							<path class="st4" d="M677.9,615c2.4,0.8,3.6-3,1.2-3.8C676.6,610.4,675.4,614.2,677.9,615z"/>
							<path class="st4" d="M684.4,623.7c0,0-0.1,0-0.1,0C684.3,623.8,684.3,623.8,684.4,623.7c2.4,0.8,3.6-3,1.1-3.7
								c-0.2,0-0.3-0.1-0.5-0.1C686,621,686.1,622.9,684.4,623.7z"/>
							<path class="st4" d="M682.6,607.9c0-0.5,0-1,0.1-1.5c-0.4,0.7-0.5,1.7,0,2.3C682.6,608.4,682.6,608.2,682.6,607.9z"/>
							<path class="st4" d="M688.9,618.1c0.5,0,1,0,1.5,0c1,0,2.1-0.8,2-1.9c0-1.1-0.8-2-1.9-2c-0.5,0-1,0-1.5,0c-1,0-2.1,0.8-2,1.9
								C687,617.1,687.8,618.1,688.9,618.1z"/>
							<path class="st4" d="M675.3,623.7c0.6,0.6,1.6,0.8,2.3,0.4c0.9-0.5,1.3-1.7,0.8-2.7c-0.1-0.2-0.2-0.3-0.3-0.5
								c-0.5-0.9-1.8-1.4-2.7-0.8c-0.9,0.5-1.3,1.7-0.8,2.7c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
								C675,623.4,675.1,623.6,675.3,623.7z"/>
							<path class="st4" d="M680.9,596.4c-0.3,0.3-0.6,0.7-0.9,1c-0.7,0.7-0.9,2-0.1,2.8c0.7,0.7,2,0.9,2.8,0.1
								c0.3-0.3,0.6-0.7,0.9-1c0.7-0.7,0.9-2,0.1-2.8c-0.5-0.5-1.4-0.8-2.1-0.6C681.4,596.1,681.2,596.2,680.9,596.4
								C681,596.4,681,596.4,680.9,596.4z"/>
							<path class="st4" d="M668,626.2c-0.6,0.2-1.2,0.7-1.3,1.3c-0.3,1.1,0.3,2.1,1.3,2.5c0.1,0,0.2,0.1,0.3,0.1
								c0.7,0.3,1.3,0.5,2,0.8c0.8,0.3,1.7-0.1,2.2-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.3-1.1-0.3-2.1-1.3-2.5c-0.1,0-0.2-0.1-0.2-0.1
								c-0.1,0-0.1,0.1-0.2,0.1C669.6,627.8,668.5,627.2,668,626.2z"/>
							<path class="st4" d="M680.5,634.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0,0.1c0,0,0-0.1,0-0.1c-1.4-0.2-2.3-1.7-2-3
								c-0.7,0.1-1.3,0.6-1.6,1.4c-0.4,1-0.5,2.3-0.1,3.4c0.3,1,1.3,1.8,2.4,1.5c1-0.3,1.8-1.3,1.5-2.4
								C680.6,634.7,680.5,634.6,680.5,634.4z"/>
							<path class="st4" d="M685.9,633c0.4,0.1,0.8,0.1,1.1,0c1.6-0.5,2-3.2,0-3.8c-1.6-0.5-2.6,0.9-2.5,2.2
								C684.6,632.1,685.1,632.7,685.9,633z"/>
							<path class="st4" d="M668.5,636.5c2.4,0.8,3.6-3,1.2-3.8C667.2,632,666,635.8,668.5,636.5z"/>
							<path class="st4" d="M691.5,609.4c2.2,0.7,3.4-2.3,1.8-3.5c-0.2-0.1-0.4-0.2-0.6-0.3C690.2,604.9,689.1,608.7,691.5,609.4z"/>
							<path class="st4" d="M696.2,628.5c2.4,0.8,3.6-3,1.2-3.8C695,624,693.8,627.8,696.2,628.5z"/>
							<path class="st4" d="M683.8,623.9c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c1.7-0.8,1.6-2.7,0.7-3.8c-0.6-0.7-1.6-1-2.6-0.5
								c-1.5,0.7-1.6,2.3-0.9,3.5C681.9,623.6,682.8,624.1,683.8,623.9z"/>
							<path class="st4" d="M690.2,631.9c-2.8,1.3-0.8,5.6,2,4.4C695,635,693.1,630.6,690.2,631.9z"/>
							<path class="st4" d="M692.8,621c-2.8,1.3-0.8,5.6,2,4.4C697.6,624.1,695.6,619.8,692.8,621z"/>
							<path class="st4" d="M705.6,623.8c-2.8,1.3-0.8,5.6,2,4.4C710.4,626.9,708.4,622.5,705.6,623.8z"/>
							<path class="st4" d="M694,611.1c-0.2,0-0.3,0.1-0.5,0.2c-0.8,0.4-1.2,1-1.3,1.6c-0.3,1.7,1.3,3.7,3.3,2.8
								C698.1,614.5,696.5,610.6,694,611.1z"/>
							<path class="st4" d="M680.4,634c0.4,0,0.8,0,1.2-0.2c0.8-0.4,1.2-1,1.3-1.7c0.3-1.7-1.3-3.6-3.3-2.7c-0.8,0.3-1.2,0.9-1.3,1.6
								C678.2,632.3,679.1,633.8,680.4,634z"/>
							<path class="st4" d="M708.9,614.8c-0.9-0.9-2.4-1.2-3.4-0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-1,0.8-1.2,2.4-0.3,3.4
								c0.5,0.5,1.1,0.8,1.8,0.8c0.6,0,1.1-0.1,1.6-0.5c0.5-0.4,0.9-0.8,1.4-1.2c0.2-0.2,0.4-0.4,0.5-0.6
								C709.6,616.7,709.6,615.6,708.9,614.8z"/>
							<path class="st4" d="M700.4,630.7c-0.2-0.1-0.4-0.2-0.6-0.2c-1.2-0.4-2.7,0.2-3.1,1.4c-0.4,1.2,0.2,2.6,1.4,3.1
								c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.4,2.7-0.2,3.1-1.4C702.2,632.5,701.7,631.2,700.4,630.7z"/>
							<path class="st4" d="M689.6,646.8C689.6,646.7,689.6,646.7,689.6,646.8c1-0.9,1.2-2.4,0.3-3.4c-0.4-0.5-0.8-0.9-1.2-1.4
								c-0.8-1-2.4-1.2-3.4-0.3c-0.9,0.9-1.2,2.4-0.3,3.4c0.4,0.5,0.8,0.9,1.2,1.4c0.4,0.5,1,0.7,1.6,0.8
								C688.5,647.3,689.1,647.2,689.6,646.8z"/>
							<path class="st4" d="M682.7,608.7c0.2,1,1,1.8,2,1.8c1.2,0.1,2.6-0.8,2.6-2.1c0-0.5,0-1.1,0.1-1.6c0-0.7-0.2-1.4-0.7-1.9
								c-0.4-0.4-0.9-0.7-1.4-0.7c-0.4,0-0.9,0.1-1.3,0.3c-0.7,0.4-1.3,1-1.3,1.9c0,0,0,0.1,0,0.1c0,0.5,0,1-0.1,1.5
								C682.6,608.2,682.6,608.4,682.7,608.7z"/>
							<path class="st4" d="M701.8,642.7c-0.6-1.2-1.9-1.6-3.2-1.2c-0.9,0.3-1.8,0.7-2.8,1c-1.2,0.4-1.7,2.1-1.2,3.2
								c0.6,1.2,1.9,1.6,3.2,1.2c0.9-0.3,1.8-0.7,2.8-1C701.8,645.4,702.4,643.8,701.8,642.7z"/>
							<path class="st4" d="M713.1,641c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
								c-0.1-0.2-0.1-0.4-0.1-0.4c-0.4-1.2-2.1-1.7-3.2-1.2c-1.2,0.6-1.6,1.9-1.2,3.2c0.4,1.3,1.4,2.4,2.6,3.1
								c1.1,0.6,2.6,0.6,3.3-0.6C714.3,643.4,714.2,641.7,713.1,641z M712.3,640.3c0,0,0.1,0.1,0.1,0.2
								C712.3,640.5,712.3,640.4,712.3,640.3z M712.5,640.6c0.1,0.1,0.1,0.1,0.2,0.2C712.6,640.7,712.5,640.7,712.5,640.6z"/>
							<path class="st4" d="M714.5,630.8c-2.8,1.3-0.8,5.6,2,4.4C719.2,633.8,717.3,629.5,714.5,630.8z"/>
							<path class="st4" d="M701.6,647.9c-2.8,1.3-0.8,5.6,2,4.4C706.4,651,704.4,646.6,701.6,647.9z"/>
							<path class="st4" d="M689.6,652.3c-2.8,1.3-0.8,5.6,2,4.4C694.4,655.4,692.4,651,689.6,652.3z"/>
							<path class="st4" d="M700.4,605.2c-1.4,0.7-1.4,2.3-0.8,3.3c0.6,0.9,1.7,1.5,2.9,0.9c2.8-1.3,0.8-5.6-2-4.4
								C700.5,605.2,700.5,605.2,700.4,605.2z"/>
							<path class="st4" d="M667.6,624.6c-0.1,0.6,0.1,1.1,0.4,1.6c0.6,0.9,1.7,1.5,2.9,0.9c0.1,0,0.2-0.1,0.2-0.1
								c2.1-1.2,1-4.4-1.1-4.4c-0.3,0-0.7,0-1.1,0.2C668.1,623.2,667.7,623.9,667.6,624.6z"/>
							<path class="st4" d="M677.8,646.6c-0.4-0.1-0.9,0-1.4,0.2c-0.6,0.3-1,0.7-1.2,1.1c-0.8,1.7,0.9,4.2,3.1,3.2
								C680.7,650.1,679.7,647,677.8,646.6z"/>
							<path class="st4" d="M720.1,618.5c-0.3,0.1-0.5,0.3-0.7,0.5c-1.4,1.4-0.2,4.1,1.8,4.1c0.3,0,0.6-0.1,1-0.2
								C724.9,621.6,722.9,617.2,720.1,618.5z"/>
							<path class="st4" d="M664.7,638.4c-1,0.5-1.4,1.3-1.3,2.1c0,0.7,0.4,1.5,1,1.9c0.6,0.5,1.4,0.7,2.4,0.3
								C669.5,641.5,667.6,637.1,664.7,638.4z"/>
							<path class="st4" d="M754.8,591.4c-0.3,0.8-0.2,1.7,0.6,2.5c2.3,2.4,6-1.2,3.7-3.6c-0.6-0.6-1.3-0.8-2-0.7
								C756.1,589.6,755.2,590.4,754.8,591.4z"/>
							<path class="st4" d="M767.4,592.5c0.2,0.2,0.3,0.3,0.5,0.4c1.2,0.7,2.5,0.2,3.3-0.7c0.4-0.5,0.7-1.2,0.6-1.9
								c-0.1-0.5-0.3-0.9-0.7-1.4c-1.7-1.7-4-0.3-4.4,1.5C766.5,591,766.7,591.8,767.4,592.5z"/>
							<path class="st4" d="M773,602.8c-2.3-2.4-6,1.2-3.7,3.6c0,0,0.1,0.1,0.1,0.1c1.3,1.2,2.9,0.7,3.8-0.4
								C773.9,605.2,774,603.9,773,602.8z"/>
							<path class="st4" d="M777.5,589.6c1.7,1.7,4.1,0.3,4.4-1.5c0.1-0.5,0-1.1-0.3-1.6h-4.3C776.6,587.3,776.5,588.6,777.5,589.6z"
								/>
							<path class="st4" d="M783.3,602.9c0.2-0.3,0.3-0.6,0.3-1c-1.2-0.4-1.7-1.6-1.5-2.7c-0.5-0.3-0.9-0.5-1.4-0.8
								c-1.2-0.7-2.9-0.3-3.5,0.9c-0.6,1.2-0.3,2.8,0.9,3.5c0.6,0.3,1.1,0.6,1.7,0.9c0.8,0.5,2,0.4,2.8-0.1
								C782.8,603.5,783.1,603.2,783.3,602.9z"/>
							<path class="st4" d="M761.5,602.7c0.6,0,1.2-0.3,1.7-0.6c0.6-0.5,0.9-1.1,0.9-1.9c0-0.2,0-0.5,0-0.7c0-1.4-1.2-2.7-2.6-2.6
								c-1.4,0-2.6,1.1-2.6,2.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3C758.9,601.4,760.1,602.7,761.5,602.7z"/>
							<path class="st4" d="M743.9,595.1c0.4,0.2,0.8,0.3,1.2,0.3c0.9,0,1.8-0.4,2.3-1.2c-1.8-0.4-1.5-3.3,0.5-3.2
								c0.3,0,0.6,0.1,0.8,0.3c0-0.9-0.4-1.9-1.3-2.3c-1.2-0.6-2.8-0.3-3.5,0.9c-0.3,0.6-0.6,1.1-0.9,1.7c-0.5,0.8-0.4,2,0.1,2.8
								C743.2,594.6,743.5,594.9,743.9,595.1z"/>
							<path class="st4" d="M750.4,599.9c-0.9-1-2.8-1-3.6,0c-1,1.1-1,2.5,0,3.6c0.4,0.4,0.7,0.9,1.1,1.3c0.3,0.4,0.6,0.8,1,1.1
								c0.7,0.8,2.1,1,3.1,0.5c0.2-0.1,0.4-0.3,0.6-0.4c1-1.1,1-2.5,0-3.6C751.8,601.5,751.1,600.7,750.4,599.9z"/>
							<path class="st4" d="M755.4,611.6c-1.1,0.9-2,2.3-2.2,3.8c-0.2,1.3,0.4,2.8,1.8,3.2c1.3,0.3,2.9-0.4,3.2-1.8
								c0-0.1,0-0.3,0.1-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0c0.1-0.1,0.3-0.3,0.4-0.3
								c1.1-0.9,1-2.8,0-3.6C758,610.6,756.6,610.7,755.4,611.6z M758.3,616.2c0-0.1,0.1-0.2,0.1-0.2
								C758.4,616.1,758.3,616.1,758.3,616.2z M758.5,615.8c0-0.1,0.1-0.1,0.1-0.2C758.6,615.7,758.6,615.7,758.5,615.8z"/>
							<path class="st4" d="M765.5,618c2.3,2.4,6-1.2,3.7-3.6C766.8,612,763.1,615.6,765.5,618z"/>
							<path class="st4" d="M747.5,608.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-2.3-2.4-6,1.2-3.7,3.6
								c1.3,1.3,2.9,0.8,3.8-0.3C747.7,610.2,748,609.2,747.5,608.2z"/>
							<path class="st4" d="M738.2,597.1c-0.8-0.8-1.7-0.9-2.6-0.6c-1.2,0.5-2.2,1.8-1.8,3.2c0.3-0.1,0.6-0.2,0.9-0.2
								c0.8,0,1.6,0.9,1.5,1.7c0,0.1,0,0.2-0.1,0.3c1.3,0,2.5-1.1,2.7-2.3C739,598.5,738.8,597.7,738.2,597.1z"/>
							<path class="st4" d="M790.4,591.3c-0.1-0.2-0.2-0.3-0.4-0.5c-2.3-2.4-6,1.2-3.7,3.6c0.8,0.8,1.7,0.9,2.6,0.6
								C790.3,594.6,791.4,592.8,790.4,591.3z"/>
							<path class="st4" d="M780,619.2c2.3,2.4,6-1.2,3.7-3.6C781.3,613.2,777.6,616.8,780,619.2z"/>
							<path class="st4" d="M807.1,587.4c-3,0-2.9,4.6,0.1,4.6C810.2,592,810.1,587.4,807.1,587.4z"/>
							<path class="st4" d="M809.2,601.5c-3,0-2.9,4.6,0.1,4.6C812.2,606.1,812.2,601.5,809.2,601.5z"/>
							<path class="st4" d="M815.8,597.6c3,0,2.9-4.6-0.1-4.6C812.7,593,812.8,597.6,815.8,597.6z"/>
							<path class="st4" d="M826,604.9c3,0,2.9-4.6-0.1-4.6C822.9,600.4,823,605,826,604.9z"/>
							<path class="st4" d="M820.1,589.3c1.7,0,2.4-1.5,2.1-2.8h-4.3C817.7,587.7,818.4,589.3,820.1,589.3z"/>
							<path class="st4" d="M801,599.9c3,0,2.9-4.6-0.1-4.6C797.9,595.3,798,599.9,801,599.9z"/>
							<path class="st4" d="M829.1,597.1c0.1,0,0.2-0.1,0.4-0.1c0.1-1.4,1.4-2.8,2.9-2.4c0-0.3,0-0.6-0.1-0.8
								c-0.4-1.2-1.6-1.9-2.9-1.6c-0.5,0.2-1.1,0.3-1.6,0.5c-1.2,0.3-2,1.7-1.6,2.9C826.6,596.7,827.8,597.4,829.1,597.1z"/>
							<path class="st4" d="M818.2,603.9c-0.9-0.8-2.4-0.9-3.3,0c-0.4,0.4-0.6,1-0.6,1.5c0.3,0,0.7,0.1,1.1,0.4
								c1,0.6,1.2,1.5,1.1,2.4c0.8,0.2,1.7,0,2.3-0.6c0.8-0.9,0.9-2.4,0-3.3C818.5,604.2,818.3,604.1,818.2,603.9z"/>
							<path class="st4" d="M804,609.9c-0.3-1.2-1.7-2-2.9-1.6c-1.2,0.4-1.9,1.6-1.6,2.9c0.2,0.5,0.3,1.1,0.5,1.6
								c0.3,1.2,1.7,2,2.9,1.6c1.2-0.4,1.9-1.6,1.6-2.9C804.4,610.9,804.2,610.4,804,609.9z"/>
							<path class="st4" d="M815.2,615.5c-0.1-1.3-1-2.2-2.3-2.3c-0.9-0.1-1.9-0.1-2.8-0.2c-1.2-0.1-2.3,1.2-2.3,2.3
								c0.1,1.3,1,2.2,2.3,2.3c0.9,0.1,1.9,0.1,2.8,0.2C814.2,617.9,815.3,616.7,815.2,615.5z"/>
							<path class="st4" d="M825.5,618.1c0,0,0-0.1-0.1-0.1c-0.6,0.3-1.4,0.3-2.2-0.2c-1.2-0.7-1.3-1.9-0.9-2.9
								c-0.9,0.3-1.5,1.1-1.6,2.2c-0.1,1.3,0.3,2.7,1.1,3.7c0.7,1,2.1,1.5,3.2,0.8c1-0.6,1.6-2.1,0.8-3.2
								C825.7,618.3,825.6,618.2,825.5,618.1c0,0,0-0.1,0-0.1C825.5,618,825.5,618,825.5,618.1z"/>
							<path class="st4" d="M831,609.9c-0.1,0-0.1,0-0.2,0c0,0.3-0.2,0.6-0.3,0.9c-0.3,0.6-1,1-1.7,1.1c-0.1,1.2,0.6,2.6,2.2,2.5
								C834,614.5,833.9,609.9,831,609.9z"/>
							<path class="st4" d="M813,620c-3,0-2.9,4.6,0.1,4.6C816,624.6,816,620,813,620z"/>
							<path class="st4" d="M800.8,619.3c-3,0-2.9,4.6,0.1,4.6C803.8,623.8,803.7,619.2,800.8,619.3z"/>
							<path class="st4" d="M794,589.9c2,0,2.6-2.1,1.9-3.4H792C791.3,587.8,792,589.9,794,589.9z"/>
							<path class="st4" d="M791.2,609.3c-3,0-2.9,4.6,0.1,4.6C794.2,613.8,794.2,609.2,791.2,609.3z"/>
							<path class="st4" d="M840.7,601.3c-0.5,0-0.9,0.1-1.2,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.5,0.2,0.7
								c0.1,0.6,0,1.2-0.2,1.7c0.2,0.4,0.3,0.8,0.2,1.2c0.3,0.1,0.5,0.2,0.9,0.2C843.8,605.9,843.7,601.3,840.7,601.3z"/>
							<path class="st4" d="M783.6,601.9c0.2,0.1,0.5,0.1,0.7,0.1c1.3,0,2-0.9,2.2-1.9c0.2-1.3-0.6-2.7-2.2-2.7c-1.3,0-2,0.9-2.1,1.8
								C781.9,600.3,782.4,601.5,783.6,601.9z"/>
							<path class="st4" d="M841.9,597.7c-2.1,0.3-1.6,3.6,0.5,3.3C844.4,600.6,844,597.4,841.9,597.7z"/>
							<path class="st4" d="M845,610.8c2.1-0.3,1.6-3.6-0.5-3.3C842.5,607.8,842.9,611,845,610.8z"/>
							<path class="st4" d="M847.2,603c0.5-0.1,1,0,1.6,0.4c0.3,0.2,0.5,0.3,0.6,0.5c1.5-0.7,1-3.4-1-3.1
								C847.2,601,846.8,602.2,847.2,603z"/>
							<path class="st4" d="M856.3,605.2c-2.1,0.3-1.6,3.6,0.5,3.3C858.9,608.1,858.4,604.9,856.3,605.2z"/>
							<path class="st4" d="M851.2,597.8c2.1-0.3,1.6-3.6-0.5-3.3C848.7,594.9,849.1,598.1,851.2,597.8z"/>
							<path class="st4" d="M838.1,605.8c-0.4,0.1-0.9,0.1-1.3-0.1c0.1,0.8,0.8,1.5,1.8,1.3c0.8-0.1,1.2-0.7,1.3-1.3
								c0.1-0.4,0-0.9-0.2-1.2C839.4,605.1,838.8,605.6,838.1,605.8z"/>
							<path class="st4" d="M858.3,601.1c-0.2,0.7-0.6,1.2-1.2,1.5c0.4,0.1,0.8,0.2,1.2,0c0.4-0.2,0.7-0.3,1.1-0.5
								c0.8-0.3,1.3-1.4,0.9-2.2c-0.4-0.8-1.3-1.2-2.2-0.9c0,0-0.1,0-0.1,0.1C858.3,599.7,858.4,600.4,858.3,601.1z"/>
							<path class="st4" d="M851.5,608.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7-0.5-1.8-0.4-2.3,0.3c-0.5,0.7-0.4,1.8,0.3,2.3
								c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.8,0.4,2.3-0.3C852.4,610.3,852.3,609.3,851.5,608.7z"/>
							<path class="st4" d="M841.7,613.9c-0.3-0.8-1.4-1.2-2.2-0.9c-0.8,0.4-1.2,1.3-0.9,2.2c0.2,0.4,0.3,0.7,0.5,1.1
								c0.3,0.8,1.4,1.3,2.2,0.9c0.8-0.4,1.2-1.3,0.9-2.2C842,614.6,841.8,614.3,841.7,613.9z"/>
							<path class="st4" d="M845.2,592.3c0.8,0.3,1.8,0,2.2-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.8,0-1.8-0.9-2.2
								c-0.8-0.3-1.8,0-2.2,0.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0.3,0.3,0.6,0.6,0.7,1
								C845,592.2,845.1,592.3,845.2,592.3z"/>
							<path class="st4" d="M848,618.8c0.2,0,0.5,0,0.7,0c0.9-0.1,1.6-1,1.4-1.9c-0.2-0.9-0.8-1.3-1.6-1.4
								C849.3,616.6,848.9,618,848,618.8z"/>
							<path class="st4" d="M857.6,617.8c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.2,0-0.3,0-0.4c0-0.9-1-1.6-1.9-1.4
								c-0.9,0.2-1.4,0.9-1.4,1.9c0,0.9,0.5,1.9,1.1,2.6c0.6,0.6,1.6,0.9,2.3,0.3c0.6-0.5,0.9-1.6,0.3-2.3
								C857.8,618,857.7,617.9,857.6,617.8z"/>
							<path class="st4" d="M860.8,611.5c-0.7,0.1-1.1,0.5-1.3,1c0.6-0.2,1.3-0.2,2,0.3c0.5,0.3,0.8,0.7,1,1.1
								C862.9,612.9,862.3,611.3,860.8,611.5z"/>
							<path class="st4" d="M848.9,620.3c-2.1,0.3-1.6,3.6,0.5,3.3C851.5,623.3,851,620,848.9,620.3z"/>
							<path class="st4" d="M840.2,620.8c-2.1,0.3-1.6,3.6,0.5,3.3C842.7,623.8,842.3,620.6,840.2,620.8z"/>
							<path class="st4" d="M857.1,595.1c1.2-0.2,1.5-1.2,1.3-2.1c-0.4-0.2-0.6-0.6-0.7-1c-0.3-0.2-0.6-0.2-1-0.2
								C854.6,592.2,855,595.4,857.1,595.1z"/>
							<path class="st4" d="M831,599.2c0.1,0.8,0.8,1.5,1.8,1.4c1.3-0.2,1.6-1.6,1.1-2.5C833.3,599.1,832.2,599.7,831,599.2z"/>
							<path class="st4" d="M832.5,614.6c-2.1,0.3-1.6,3.6,0.5,3.3c0.2,0,0.4-0.1,0.6-0.2c0.2-0.7,0.4-1.3,0.8-1.8
								C834.1,615.1,833.5,614.5,832.5,614.6z"/>
							<path class="st4" d="M867.4,607.8c2.1-0.3,1.6-3.6-0.5-3.3C864.8,604.8,865.3,608.1,867.4,607.8z"/>
							<path class="st4" d="M833,590.2c2.1-0.3,1.6-3.6-0.5-3.3C830.4,587.2,830.9,590.5,833,590.2z"/>
							<path class="st4" d="M630.5,699c2-0.3,2.1-2.9,0.7-3.7l-1.7,3.6C629.8,699,630.2,699.1,630.5,699z"/>
							<path class="st4" d="M633.3,707c-2.6,0.4-2,4.3,0.6,4C636.5,710.6,635.9,706.6,633.3,707z"/>
							<path class="st4" d="M638.6,702.9c2.6-0.4,2-4.3-0.6-4C635.4,699.2,636,703.2,638.6,702.9z"/>
							<path class="st4" d="M648.2,708.1c2.6-0.4,2-4.3-0.6-4C645.1,704.5,645.7,708.5,648.2,708.1z"/>
							<path class="st4" d="M641.4,695.2c1.3-0.2,1.8-1.3,1.6-2.3c-0.1-0.6-0.4-1.2-1-1.5c-0.3-0.2-0.7-0.3-1.2-0.2
								C638.3,691.6,638.8,695.5,641.4,695.2z"/>
							<path class="st4" d="M627.4,703.4l-1.5,3.1c0.1,0,0.1,0,0.2,0C627.7,706.3,628,704.5,627.4,703.4z"/>
							<path class="st4" d="M652.4,697.7c-0.5-1-1.6-1.5-2.6-1c-0.5,0.2-0.9,0.4-1.4,0.6c-0.6,0.3-1.1,0.9-1.2,1.6c0,0.3,0,0.7,0.1,1
								c0.1,0.2,0.2,0.3,0.3,0.4c0.6,0.7,1.5,1,2.4,0.6c0.5-0.2,0.9-0.4,1.4-0.6C652.4,699.9,652.9,698.7,652.4,697.7z"/>
							<path class="st4" d="M639.4,711.7c0.8,0.6,2.2,0.5,2.8-0.4c0.6-0.9,0.5-2.2-0.4-2.8c-0.2-0.1-0.3-0.2-0.5-0.3
								c-0.8-0.6-2.2-0.5-2.8,0.4c-0.6,0.9-0.5,2,0.3,2.7c0,0,0.1,0.1,0.1,0.1C639.1,711.4,639.3,711.6,639.4,711.7z"/>
							<path class="st4" d="M626.7,717.8c0.4,1,1.7,1.5,2.6,1c0.9-0.5,1.4-1.5,1.1-2.5c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-0.9-0.6-1.4
								c-0.3-0.7-1-1.1-1.7-1.2c-0.3,0-0.7,0-1,0.2c-1,0.5-1.5,1.6-1,2.6C626.3,716.9,626.5,717.3,626.7,717.8z"/>
							<path class="st4" d="M636.6,687.4c0.1-0.4,0.3-0.9,0.4-1.3c0.2-0.7,0.1-1.5-0.4-2.1l-2.2,4.6
								C635.4,688.7,636.3,688.3,636.6,687.4z"/>
							<path class="st4" d="M635.9,719c1.1,0.1,1.8,0.9,1.9,1.8c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.2,1.1-0.6c0.4-0.5,0.7-1.1,0.6-1.7
								c-0.2-1.1-1.1-1.8-2.3-1.7c-0.8,0.1-1.6,0.1-2.5,0.2c-1.1,0.1-1.9,1.3-1.7,2.3c0,0.2,0.1,0.4,0.2,0.6
								C634.3,719.2,635,718.9,635.9,719z"/>
							<path class="st4" d="M649.3,719.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0-0.4c-0.1-1.1-1.3-1.9-2.3-1.7
								c-1.2,0.2-1.8,1.1-1.7,2.3c0.1,1.1,0.6,2.3,1.3,3.1c0.7,0.8,2,1.1,2.8,0.3c0.8-0.7,1.1-2,0.3-2.8
								C649.5,719.8,649.4,719.6,649.3,719.5z M649.1,719c0,0,0,0.1,0.1,0.2C649.1,719.2,649.1,719.1,649.1,719z M649.2,719.3
								c0,0.1,0.1,0.1,0.1,0.2C649.2,719.5,649.2,719.4,649.2,719.3z"/>
							<path class="st4" d="M653.1,711.9c-2.6,0.4-2,4.3,0.6,4C656.3,715.5,655.7,711.5,653.1,711.9z"/>
							<path class="st4" d="M639,722.6c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.2-0.9,0.4c-1.4,1.1-0.7,3.9,1.5,3.6
								C641.7,726.3,641.3,722.6,639,722.6z"/>
							<path class="st4" d="M626.3,725.2c-0.1,1.1,0.8,2.4,2.2,2.2c1.4-0.2,1.9-1.5,1.6-2.5c-0.2-0.6-0.6-1.2-1.3-1.4
								c-0.1,0-0.1,0-0.2,0C628.4,724.4,627.6,725.2,626.3,725.2z"/>
							<path class="st4" d="M648.6,691.9c2.6-0.4,2-4.3-0.6-4C645.5,688.3,646.1,692.2,648.6,691.9z"/>
							<path class="st4" d="M620.7,717.1l-1.2,2.5C620.7,719.3,621,718.1,620.7,717.1z"/>
							<path class="st4" d="M660.6,703.3c-2.6,0.4-2,4.3,0.6,4C663.8,706.9,663.2,703,660.6,703.3z"/>
							<path class="st4" d="M612.8,740.6c2.5,0.3,3.3-2.9,1.8-4.2c-0.3-0.3-0.7-0.4-1.2-0.5c-1-0.1-1.7,0.3-2.1,0.9
								c0,0.1-0.1,0.1-0.1,0.2l-0.2,0.4C610.5,738.6,611,740.4,612.8,740.6z"/>
							<path class="st4" d="M620.6,732.9c1.8,0.2,2.7-1.3,2.5-2.7c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.8-1.4-1.8-1.5
								C618.2,727.9,617.6,732.5,620.6,732.9z"/>
							<path class="st4" d="M630.4,737.2c-1.7-0.2-2.6,1.1-2.6,2.5c0,1,0.6,2,2,2.2c0.2,0,0.3,0,0.5,0
								C632.9,741.7,633.2,737.5,630.4,737.2z"/>
							<path class="st4" d="M626.1,725.1c0.1,0,0.1,0,0.2,0c1.3,0.1,2.1-0.8,2.3-1.8c0.3-1.2-0.3-2.6-1.9-2.8
								C623.7,720.1,623.1,724.8,626.1,725.1z"/>
							<path class="st4" d="M637.8,731.4c-0.2-0.9-0.8-1.7-1.7-1.9c-0.3-0.1-0.6-0.1-1-0.1c-0.6,0.1-1.1,0.2-1.7,0.3
								c-0.9,0.1-1.6,0.8-1.9,1.6c-0.1,0.3-0.2,0.7-0.1,1.1c0.2,0.9,0.8,1.6,1.6,1.9c0.3,0.1,0.7,0.1,1,0.1c0.6-0.1,1.1-0.2,1.7-0.3
								c0.8-0.1,1.5-0.7,1.8-1.5C637.8,732.2,637.8,731.8,637.8,731.4z"/>
							<path class="st4" d="M622.6,740.4c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.5c-0.7-0.9-2.1-1.2-3.1-0.5
								c-0.1,0-0.1,0.1-0.2,0.1c-1,0.8-1.2,2.3-0.4,3.3c0.1,0.2,0.3,0.4,0.4,0.5c0.8,1,2.3,1.2,3.3,0.4
								C623,742.7,623.2,741.5,622.6,740.4z"/>
							<path class="st4" d="M607.2,745.3C607.2,745.3,607.2,745.3,607.2,745.3l-1.2,2.4C606.9,747.2,607.4,746.3,607.2,745.3z"/>
							<path class="st4" d="M623.1,714.9c0.3-0.5,0.6-0.9,0.9-1.4c0.6-0.9,0.6-2.2-0.1-3l-0.4,0.9l-2.1,4.4
								C622.1,715.7,622.7,715.5,623.1,714.9z"/>
							<path class="st4" d="M615.4,748.3c-0.9-0.2-1.9-0.4-2.8-0.6c-1.2-0.3-2.5,0.8-2.6,2c-0.1,1.4,0.7,2.4,2,2.6
								c0.1,0,0.2,0,0.3,0.1c-0.5-1.4,0.4-3.3,2.3-3.1c1.8,0.2,2.3,2,1.7,3.3c0.6-0.4,1.1-1,1.1-1.7
								C617.6,749.6,616.7,748.6,615.4,748.3z"/>
							<path class="st4" d="M627.5,755c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
								c0.3-1.2-0.8-2.5-2-2.6c-1.4-0.1-2.3,0.7-2.6,2c-0.3,1.3-0.1,2.8,0.5,3.9c0.6,1.1,1.9,1.8,3.1,1.2c1.1-0.5,1.9-1.9,1.2-3.1
								C627.6,755.3,627.5,755.1,627.5,755z M627.4,754.4c0,0,0,0.1,0,0.2C627.3,754.5,627.4,754.4,627.4,754.4z M627.4,754.7
								c0,0.1,0,0.2,0.1,0.2C627.4,754.9,627.4,754.8,627.4,754.7z"/>
							<path class="st4" d="M634.1,747.5c-3-0.4-3.6,4.3-0.6,4.7C636.5,752.6,637.1,747.9,634.1,747.5z"/>
							<path class="st4" d="M615.3,759.6c1.7-0.9,1.7-4.1-0.8-4.4c-2.7-0.3-3.4,3.3-1.4,4.4c0.3-0.1,0.6-0.1,0.9-0.2
								C614.5,759.3,615,759.4,615.3,759.6z"/>
							<path class="st4" d="M601.7,757.3c1.6,0.2,2.5-1,2.6-2.2c-0.6-0.4-0.9-1.1-0.9-1.8l-1.3,2.7l-0.6,1.3
								C601.5,757.3,601.6,757.3,601.7,757.3z"/>
							<path class="st4" d="M637.8,720.8c-0.2-0.9-0.8-1.6-1.9-1.8c-0.9-0.1-1.6,0.2-2,0.8c-1,1.3-0.7,3.6,1.4,3.9
								c0.9,0.1,1.6-0.2,2-0.8C637.7,722.3,637.9,721.5,637.8,720.8z"/>
							<path class="st4" d="M645.2,740.3c-3-0.4-3.6,4.3-0.6,4.7C647.6,745.3,648.2,740.6,645.2,740.3z"/>
							<path class="st4" d="M600.8,758.6l-0.9,1.9l-1,2.1c0.3,0.2,0.7,0.4,1.1,0.4C602.8,763.3,603.4,759.2,600.8,758.6z"/>
							<path class="st4" d="M609.6,767c-2.9-0.3-3.4,4.2-0.5,4.5C612,771.9,612.5,767.3,609.6,767z"/>
							<path class="st4" d="M604.3,755.1c0.3,0.2,0.6,0.3,1,0.4c2.9,0.3,3.4-4.2,0.5-4.5c-0.6-0.1-1.1,0.1-1.5,0.4l-0.1,0.3l-0.8,1.7
								C603.4,754,603.7,754.6,604.3,755.1z"/>
							<path class="st4" d="M615.3,759.6c-0.4-0.2-0.8-0.3-1.3-0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0-0.5,0.1-0.7,0.1
								c-1.2,0.2-2.1,1.4-1.9,2.6c0.2,1.2,1.3,2.1,2.6,1.9c0.6-0.1,1.1-0.2,1.7-0.3c1.2-0.2,2.1-1.4,1.9-2.6
								C616.5,760.5,616,759.9,615.3,759.6z"/>
							<path class="st4" d="M602,770.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-0.9-2.3-1.2-3.2-0.4c-0.9,0.8-1.2,2.2-0.4,3.2
								c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.9,2.3,1.2,3.2,0.4C602.6,772.5,602.8,771.1,602,770.1z"/>
							<path class="st4" d="M592,782c0.9,0.2,1.8,0.4,2.7,0.5c1.2,0.2,2.4-0.8,2.5-2c0.1-1.3-0.8-2.3-2-2.5c-0.9-0.2-1.8-0.4-2.7-0.5
								c-0.3-0.1-0.6,0-0.8,0l-1.3,2.7l-0.2,0.3C590.5,781.4,591.1,781.9,592,782z"/>
							<path class="st4" d="M607,784.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4
								c0.2-1.2-0.9-2.4-2-2.5c-1.3-0.1-2.3,0.8-2.5,2c-0.3,1.2,0,2.7,0.6,3.8c0.6,1,1.8,1.7,3,1.2c1-0.5,1.8-1.9,1.2-3
								C607.2,784.6,607.1,784.5,607,784.3z M606.9,783.7c0,0,0,0.1,0,0.2C606.9,783.9,606.9,783.8,606.9,783.7z M607,784.1
								c0,0.1,0,0.1,0.1,0.2C607,784.2,606.9,784.1,607,784.1z"/>
							<path class="st4" d="M613.4,777c-2.9-0.3-3.4,4.2-0.5,4.5C615.8,781.9,616.3,777.3,613.4,777z"/>
							<path class="st4" d="M594.5,784.7C594.5,784.7,594.5,784.7,594.5,784.7c1.4,1.3,0.2,4.1-1.9,3.8c0.3,0.4,0.8,0.6,1.4,0.7
								C596.9,789.6,597.4,785,594.5,784.7z"/>
							<path class="st4" d="M614.6,749.3c-1.9-0.2-2.8,1.7-2.3,3.1c0.2,0.7,0.8,1.3,1.8,1.4c1.1,0.1,1.9-0.4,2.2-1.2
								C616.9,751.4,616.5,749.6,614.6,749.3z"/>
							<path class="st4" d="M624,769.8c-2.9-0.3-3.4,4.2-0.5,4.5C626.4,774.7,626.9,770.1,624,769.8z"/>
							<path class="st4" d="M584.5,799.7c-2.7-1-4.3,3.1-1.6,4.2C585.5,804.9,587.2,800.8,584.5,799.7z"/>
							<path class="st4" d="M590.6,793.6c-0.6-0.1-1.1-0.1-1.7-0.2c-1.2-0.1-2.4,0.8-2.4,2c-0.1,1.2,0.7,2.3,2,2.4
								c0.6,0.1,1.1,0.1,1.7,0.2c1.2,0.1,2.4-0.8,2.4-2C592.7,794.9,591.9,793.8,590.6,793.6z"/>
							<path class="st4" d="M575.9,818.5c1.1-0.2,2.2-1.3,1.9-2.6c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2
								c0.1-0.2,0.2-0.4,0.2-0.4c0.5-1-0.2-2.5-1.3-2.9c-0.5-0.2-0.9-0.2-1.4-0.1l-1.3,2.7l-0.8,1.6c0,0.3,0.1,0.5,0.1,0.8
								C573.7,817.7,574.7,818.7,575.9,818.5z M577.7,814.9c0,0,0,0.1,0,0.2C577.7,815,577.7,815,577.7,814.9z M577.7,815.2
								c0,0.1,0,0.2,0,0.2C577.7,815.4,577.7,815.3,577.7,815.2z"/>
							<path class="st4" d="M585.5,810.2c-2.7-1-4.3,3.1-1.6,4.2C586.6,815.4,588.2,811.2,585.5,810.2z"/>
							<path class="st4" d="M594.4,784.7c-0.2-0.2-0.4-0.3-0.7-0.5c-2.7-1-4.3,3.1-1.6,4.2c0.2,0.1,0.4,0.1,0.5,0.1
								C594.7,788.8,595.9,786,594.4,784.7z"/>
							<path class="st4" d="M1679.9,709.3c-1.2-0.1-1.8,0.9-1.7,1.9c0.4,0,0.7,0,1.1,0.1c0.6,0.3,1.1,0.5,1.7,0.8
								C1681.8,711.2,1681.5,709.4,1679.9,709.3z"/>
							<path class="st4" d="M1680.6,722.7c1.7-0.3,1.7-3-0.1-3.2c-0.1,0.1-0.2,0.1-0.2,0.2C1681.2,720.4,1681.2,721.7,1680.6,722.7z"
								/>
							<path class="st4" d="M1685.4,717.1c2.1,0.2,2.4-3.1,0.3-3.3C1683.5,713.6,1683.3,716.9,1685.4,717.1z"/>
							<path class="st4" d="M1687.7,709.3c-0.3,0.9,0.1,2.1,1.3,2.2c0.1,0,0.3,0,0.4,0v-2.1C1688.9,709.6,1688.2,709.5,1687.7,709.3z
								"/>
							<path class="st4" d="M1674.7,717.7c2.1,0.2,2.4-3.1,0.3-3.3C1672.9,714.3,1672.6,717.6,1674.7,717.7z"/>
							<path class="st4" d="M1687,722.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.7-1.7-0.8-2.3-0.2
								c-0.7,0.6-0.8,1.6-0.2,2.3c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.3,0.7,0.5,1.1,0.6C1685.5,723.8,1685.9,722.7,1687,722.3z"/>
							<path class="st4" d="M1676.2,725c-0.2-0.8-1.1-1.5-1.9-1.3c-0.9,0.2-1.5,1-1.3,1.9c0.1,0.4,0.2,0.8,0.2,1.2
								c0.2,0.8,1.1,1.5,1.9,1.3c0.9-0.2,1.5-1,1.3-1.9C1676.4,725.8,1676.3,725.4,1676.2,725z"/>
							<path class="st4" d="M1686.8,701.1c-0.7-0.5-1.8-0.4-2.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.6-0.2,1.3-0.7,1.8
								c0,0.5,0.2,0.9,0.7,1.2c0.7,0.5,1.8,0.4,2.3-0.4c0.2-0.3,0.4-0.6,0.6-1C1687.7,702.7,1687.6,701.6,1686.8,701.1z"/>
							<path class="st4" d="M1682.3,728.1c-0.7-0.1-1.3-0.2-2-0.3c-0.9-0.1-1.7,0.7-1.8,1.5c0,1,0.6,1.6,1.5,1.8
								c0.7,0.1,1.3,0.2,2,0.3c0.9,0.1,1.7-0.7,1.8-1.5C1683.8,728.9,1683.2,728.2,1682.3,728.1z"/>
							<path class="st4" d="M1687.6,731.3c-0.1,0.6,0,1.2,0.1,1.8c0.2,0.3,0.4,0.6,0.4,1c0.3,0.4,0.8,0.8,1.3,0.8v-5.1c0,0,0,0-0.1,0
								C1688.4,729.8,1687.8,730.4,1687.6,731.3z"/>
							<path class="st4" d="M1681.9,732.9c-2.1-0.2-2.4,3.1-0.3,3.3C1683.7,736.3,1684,733.1,1681.9,732.9z"/>
							<path class="st4" d="M1674.6,733.7c-1-0.2-1.7-1.2-1.9-2.2c-1.6,0.3-1.7,3.1,0.2,3.2C1673.8,734.9,1674.4,734.3,1674.6,733.7z
								"/>
							<path class="st4" d="M1669.7,707.3c0-0.1,0-0.1,0-0.2c-1.1,0.7-1,2.9,0.7,3.1c0.4,0,0.8-0.1,1.1-0.3
								C1670.4,709.6,1669.6,708.4,1669.7,707.3z"/>
							<path class="st4" d="M1667.1,723.8c-2.1-0.2-2.4,3.1-0.3,3.3C1669,727.2,1669.2,723.9,1667.1,723.8z"/>
							<path class="st4" d="M1662.7,718.1c2.1,0.2,2.4-3.1,0.3-3.3C1660.9,714.6,1660.6,717.9,1662.7,718.1z"/>
							<path class="st4" d="M1673.2,696.8c-2.1-0.2-2.4,3.1-0.3,3.3C1675.1,700.2,1675.3,697,1673.2,696.8z"/>
							<path class="st4" d="M1674.6,733.7c0.4,0.1,0.8,0.1,1.3,0c3.1-0.9,1.7-5.8-1.4-4.9c-1.4,0.4-1.9,1.6-1.7,2.8
								C1672.9,732.5,1673.6,733.5,1674.6,733.7z"/>
							<path class="st4" d="M1680.7,743.1c-3.1,0.9-1.7,5.8,1.4,4.9C1685.2,747.1,1683.8,742.2,1680.7,743.1z"/>
							<path class="st4" d="M1685.1,732.2c-3.1,0.9-1.7,5.8,1.4,4.9c1.5-0.4,2-1.8,1.7-3c-0.1-0.4-0.2-0.7-0.4-1
								C1687.2,732.3,1686.3,731.9,1685.1,732.2z"/>
							<path class="st4" d="M1687.8,723.3c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
								c-1.2,0.5-1.6,1.6-1.4,2.6c0.2,1.4,1.4,2.7,3.1,2.2c0.3-0.1,0.5-0.2,0.7-0.3v-1.6C1688.7,724.8,1688.1,724.1,1687.8,723.3z"/>
							<path class="st4" d="M1670.1,738.9c-3.1,0.9-1.7,5.8,1.4,4.9C1674.6,742.9,1673.2,738,1670.1,738.9z"/>
							<path class="st4" d="M1687.4,744.1c-0.6,1.2-0.3,2.8,1,3.4c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.1,0.4,0.2v-5.2
								C1688.6,742.9,1687.8,743.4,1687.4,744.1z"/>
							<path class="st4" d="M1677.6,753.5c-0.7-1.1-2.3-1.6-3.5-0.8c-1.1,0.8-1.6,2.3-0.8,3.5c0.3,0.5,0.7,1.1,1,1.6
								c0.7,1.1,2.3,1.6,3.5,0.8c1.1-0.8,1.6-2.3,0.8-3.5C1678.2,754.5,1677.9,754,1677.6,753.5z"/>
							<path class="st4" d="M1680.3,717.1c-0.9-0.4-1.8-0.8-2.7-1.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.1,0.5-0.2,1-0.3,1.6
								c-0.2,1,0.1,2,0.9,2.6c0.6-0.4,1.3-0.6,2.1-0.3c0.3,0.1,0.5,0.3,0.7,0.4c0.1,0,0.2-0.1,0.2-0.2c0.4-0.3,0.8-0.8,0.9-1.4
								c0.1-0.3,0.1-0.6,0.2-0.9C1681.2,717.3,1680.7,717.3,1680.3,717.1z"/>
							<path class="st4" d="M1688,754.5c-1,0.2-2,0.4-3.1,0.6c-1.3,0.3-2.1,1.9-1.7,3.1c0.5,1.4,1.7,2,3.1,1.7c1-0.2,2-0.4,3.1-0.6
								c0,0,0.1,0,0.1,0v-4.8C1689,754.5,1688.5,754.4,1688,754.5z"/>
							<path class="st4" d="M1689.4,766.3C1689.4,766.2,1689.4,766.2,1689.4,766.3l0.1-4.3C1687.7,762.9,1688,765.3,1689.4,766.3z"/>
							<path class="st4" d="M1676.8,764.4c-1.8,0.5-2.1,2.3-1.4,3.6c0.1,0,0.2,0,0.3,0c0.9,0.1,1.3,0.7,1.4,1.3
								c0.4,0.1,0.8,0.1,1.2-0.1C1681.3,768.4,1680,763.5,1676.8,764.4z"/>
							<path class="st4" d="M1659.8,730.3c-3.1,0.9-1.7,5.8,1.4,4.9C1664.3,734.2,1663,729.4,1659.8,730.3z"/>
							<path class="st4" d="M1663.8,756.5c-3.1,0.9-1.7,5.8,1.4,4.9C1668.3,760.5,1667,755.6,1663.8,756.5z"/>
							<path class="st4" d="M1653,745.9c-3.1,0.9-1.7,5.8,1.4,4.9C1657.5,749.9,1656.1,745,1653,745.9z"/>
							<path class="st4" d="M1659.4,719.3c3.1-0.9,1.7-5.8-1.4-4.9C1654.9,715.3,1656.2,720.2,1659.4,719.3z"/>
							<path class="st4" d="M1675.6,768c-0.1,0-0.2,0-0.3,0c-1.8,0.1-1.9,3,0,3.2c1.2,0.1,1.8-0.9,1.6-1.9
								C1676.9,768.7,1676.5,768.1,1675.6,768z"/>
							<path class="st4" d="M1675.9,781c2,0.2,2.3-3,0.2-3.2C1674.1,777.7,1673.9,780.8,1675.9,781z"/>
							<path class="st4" d="M1680.9,775.5c2,0.2,2.3-3,0.2-3.2C1679.1,772.2,1678.9,775.3,1680.9,775.5z"/>
							<path class="st4" d="M1687.5,781.2c2,0.2,2.3-3,0.2-3.2C1685.7,777.9,1685.4,781,1687.5,781.2z"/>
							<path class="st4" d="M1684.4,770.1c2,0.2,2.3-3,0.2-3.2C1682.6,766.8,1682.4,769.9,1684.4,770.1z"/>
							<path class="st4" d="M1670.8,773c-2-0.2-2.3,3-0.2,3.2C1672.6,776.3,1672.9,773.2,1670.8,773z"/>
							<path class="st4" d="M1688.2,774.7c0.1,0.6,0.6,1.1,1.2,1.2v-3.1C1688.6,773,1688,773.9,1688.2,774.7z"/>
							<path class="st4" d="M1680.1,782.4c0.5,0.6,1.6,0.8,2.2,0.2c0.6-0.6,0.7-1.6,0.2-2.2c-0.1-0.1-0.2-0.2-0.3-0.3
								c-0.5-0.6-1.6-0.8-2.2-0.2c-0.6,0.6-0.7,1.6-0.2,2.2C1679.9,782.2,1680,782.3,1680.1,782.4z"/>
							<path class="st4" d="M1672.3,784.4c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-0.8-1-1.5-1.9-1.3c-0.8,0.2-1.4,1-1.3,1.9
								c0.1,0.4,0.2,0.8,0.2,1.2c0,0.1,0,0.2,0.1,0.2c0.7,0.1,1.3,0.5,1.6,1c0.1,0,0.1,0,0.2,0C1671.9,786,1672.5,785.3,1672.3,784.4
								z"/>
							<path class="st4" d="M1682.2,760.1c-0.7-0.4-1.7-0.4-2.2,0.4c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.7-0.4,1.7,0.4,2.2
								c0.7,0.4,1.7,0.4,2.2-0.4c0.2-0.3,0.4-0.6,0.6-0.9C1683,761.6,1683,760.6,1682.2,760.1z"/>
							<path class="st4" d="M1678,786.1c-0.6-0.1-1.3-0.2-1.9-0.3c-0.8-0.1-1.7,0.7-1.7,1.5c0,0.9,0.6,1.6,1.5,1.7
								c0.6,0.1,1.3,0.2,1.9,0.3c0.8,0.1,1.7-0.7,1.7-1.5C1679.5,786.9,1678.9,786.2,1678,786.1z"/>
							<path class="st4" d="M1685.5,790.4c0.2-0.5,0.4-1,0.6-1.5c0-0.1,0.1-0.2,0.1-0.2c-0.2-0.5-0.8-0.9-1.3-0.9
								c-0.9,0-1.6,0.6-1.7,1.5c-0.2,0.9,0,1.9,0.5,2.6c0.4,0.7,1.2,1.1,2,0.8C1685.3,792,1685.2,791.1,1685.5,790.4z"/>
							<path class="st4" d="M1689.4,787.7v-2.4C1688.8,785.9,1688.8,787.1,1689.4,787.7z"/>
							<path class="st4" d="M1677.4,793.9c2,0.2,2.3-3,0.2-3.2C1675.6,790.6,1675.4,793.8,1677.4,793.9z"/>
							<path class="st4" d="M1668.2,789.8c-0.9,0.8-0.7,2.7,0.9,2.8c1.7,0.1,2.2-2.1,1-2.9c-0.2,0.1-0.5,0.2-0.8,0.2
								C1668.8,790,1668.5,789.9,1668.2,789.8z"/>
							<path class="st4" d="M1689.4,768.2v-2c0,0,0,0.1-0.1,0.1C1689,766.8,1689,767.7,1689.4,768.2z"/>
							<path class="st4" d="M1666.4,768.9c2,0.2,2.3-3,0.2-3.2C1664.7,765.5,1664.4,768.7,1666.4,768.9z"/>
							<path class="st4" d="M1663.3,782c-2-0.2-2.3,3-0.2,3.2C1665.1,785.4,1665.4,782.2,1663.3,782z"/>
							<path class="st4" d="M1659,776.6c2,0.2,2.3-3,0.2-3.2C1657.2,773.3,1657,776.4,1659,776.6z"/>
							<path class="st4" d="M1669.1,756c-2-0.2-2.3,3-0.2,3.2C1670.9,759.3,1671.1,756.1,1669.1,756z"/>
							<path class="st4" d="M1669.3,679.3c1.9,1.1,3.5-1.9,1.6-3C1669.1,675.3,1667.4,678.2,1669.3,679.3z"/>
							<path class="st4" d="M1667.1,686.1c-1.9-1.1-3.5,1.9-1.6,3C1667.4,690.1,1669,687.1,1667.1,686.1z"/>
							<path class="st4" d="M1672.7,685.9c1.9,1.1,3.5-1.9,1.6-3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0.1-0.4,0.1-0.7,0.1
								C1671.9,683.3,1671.3,685.1,1672.7,685.9z"/>
							<path class="st4" d="M1678.2,691.5c0.6,0.9,0.3,2.3-0.4,3.1C1679,694.1,1679.6,692.3,1678.2,691.5z"/>
							<path class="st4" d="M1679,680.6c0.7-0.4,1.4-0.4,2-0.2c-0.1-0.4-0.3-0.8-0.8-1.1c-1.6-0.9-3,1.1-2.3,2.3
								C1678.1,681.2,1678.5,680.9,1679,680.6z"/>
							<path class="st4" d="M1663.3,678.8c-1.5,0-2.4,2.3-0.8,3.2c1.4,0.8,2.7-0.7,2.5-1.9C1664.2,679.9,1663.7,679.5,1663.3,678.8z"
								/>
							<path class="st4" d="M1682.9,687.4c-0.2-0.1-0.5-0.1-0.7-0.2c-0.9-0.2-1.9,0.3-2,1.2c-0.2,0.9,0.3,1.8,1.2,2
								c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.7-0.2,2-1c-0.1,0-0.1,0-0.2,0C1683.2,689.3,1682.8,688.3,1682.9,687.4z"/>
							<path class="st4" d="M1672.1,691.4c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.3-1.4,1.2-1.2,2.1
								c0,0.2,0.1,0.3,0.1,0.5c0.2,0.9,1.2,1.5,2.1,1.2C1671.8,693.2,1672.3,692.3,1672.1,691.4z"/>
							<path class="st4" d="M1658.4,691.9c0.9,0.2,1.8-0.3,2-1.2c0,0,0-0.1,0-0.1c-0.6,0.4-1.3,0.6-2.1,0.3c-0.5-0.2-0.9-0.5-1.2-0.9
								C1657,690.8,1657.6,691.8,1658.4,691.9z"/>
							<path class="st4" d="M1678.8,674.5c-0.1,0.1-0.3,0.3-0.5,0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,1-0.6
								c0.7-0.5,1.1-1.5,0.6-2.3c-0.4-0.6-1.2-1-1.8-0.8C1679.9,671.9,1679.8,673.5,1678.8,674.5z"/>
							<path class="st4" d="M1661.1,694.4c-0.4,0.9-0.1,1.8,0.7,2.3c0.6,0.4,1.2,0.8,1.7,1.2c0.7,0.5,1.9,0.1,2.3-0.7
								c0.4-0.9,0.1-1.8-0.7-2.3c-0.6-0.4-1.2-0.8-1.7-1.2C1662.7,693.2,1661.5,693.6,1661.1,694.4z"/>
							<path class="st4" d="M1671.5,702.1c0.1-0.1,0.2-0.3,0.2-0.3c0.5-0.7,0.1-1.9-0.7-2.3c-0.9-0.4-1.8-0.1-2.3,0.7
								c-0.5,0.8-0.8,1.8-0.7,2.8c0.1,0.9,0.8,1.7,1.7,1.7c0.1,0,0.2,0,0.3,0c0.3-0.5,0.7-0.9,1.3-1.1c0.1-0.2,0.1-0.4,0.1-0.6
								c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2C1671.5,702.3,1671.5,702.2,1671.5,702.1z"/>
							<path class="st4" d="M1676.2,702.3c1.9,1.1,3.5-1.9,1.6-3C1676,698.3,1674.3,701.3,1676.2,702.3z"/>
							<path class="st4" d="M1662.4,700.6c0.5,0.2,0.8,0.5,1.1,0.8c0.3-0.7,0.2-1.6-0.7-2.1c-1.3-0.7-2.5,0.4-2.5,1.6
								C1660.8,700.4,1661.5,700.2,1662.4,700.6z"/>
							<path class="st4" d="M1653.6,697.2c1.9,1.1,3.6-1.9,1.6-3C1653.3,693.2,1651.7,696.1,1653.6,697.2z"/>
							<path class="st4" d="M1685,683.7c1.9,1.1,3.5-1.9,1.6-3C1684.8,679.7,1683.1,682.7,1685,683.7z"/>
							<path class="st4" d="M1663.4,670.1c-1.9-1.1-3.5,1.9-1.6,3C1663.6,674.1,1665.3,671.2,1663.4,670.1z"/>
							<path class="st4" d="M1652.8,684.4c-1.9-1.1-3.5,1.9-1.6,3C1653.1,688.4,1654.7,685.5,1652.8,684.4z"/>
							<path class="st4" d="M1687.2,697.5c-1.9-1.1-3.5,1.9-1.6,3C1687.5,701.5,1689.2,698.5,1687.2,697.5z"/>
							<path class="st4" d="M1651.1,677.2c1.9,1.1,3.5-1.9,1.6-3C1650.9,673.2,1649.2,676.2,1651.1,677.2z"/>
							<path class="st4" d="M1670,661.8c-1.9-1.1-3.5,1.9-1.6,3C1670.3,665.8,1671.9,662.8,1670,661.8z"/>
							<path class="st4" d="M1658.1,592c1.8,1.4,4-1.4,2.2-2.8C1658.4,587.8,1656.3,590.6,1658.1,592z"/>
							<path class="st4" d="M1666.1,589.9c1.8,1.4,4-1.4,2.2-2.8C1666.4,585.7,1664.3,588.5,1666.1,589.9z"/>
							<path class="st4" d="M1668.6,597.8c0.1-0.4,0.1-0.9,0.2-1.3c0,0,0-0.1,0-0.1c-0.9,0.6-1.3,2-0.2,2.9c0.1,0.1,0.3,0.2,0.4,0.2
								C1668.7,599,1668.5,598.4,1668.6,597.8z"/>
							<path class="st4" d="M1672.6,587c0.8,0.7,1.8,0.4,2.3-0.2c-0.9-0.2-1.9-0.3-2.8-0.3C1672.3,586.7,1672.4,586.9,1672.6,587z"/>
							<path class="st4" d="M1674.3,596c0.4,0.2,0.8,0.3,1.2,0.5c0.9,0.3,2-0.1,2.3-1c0.3-0.9,0-1.9-1-2.3c-0.4-0.2-0.8-0.3-1.2-0.5
								c-0.9-0.3-2,0.1-2.3,1C1673,594.6,1673.4,595.6,1674.3,596z"/>
							<path class="st4" d="M1661,595.9c0.1,0.9,1,1.7,2,1.5c0.9-0.2,1.7-1,1.5-2c0-0.2,0-0.3-0.1-0.5c-0.1-0.9-1-1.7-2-1.5
								c-0.9,0.2-1.7,1-1.5,2C1661,595.5,1661,595.7,1661,595.9z"/>
							<path class="st4" d="M1650.3,593.9c0.9,0.3,1.9,0,2.3-1c0.2-0.4,0.3-0.8,0.5-1.2c0.3-0.9-0.1-2-1-2.3c-0.9-0.3-1.9,0-2.3,1
								c-0.2,0.4-0.3,0.8-0.5,1.2C1649,592.4,1649.4,593.5,1650.3,593.9z"/>
							<path class="st4" d="M1652.7,596.8c-0.6,0.8-0.4,1.8,0.3,2.5c0.5,0.5,1.1,1,1.6,1.5c0.7,0.6,2,0.4,2.5-0.3
								c0.6-0.8,0.4-1.8-0.3-2.5c0,0-0.1-0.1-0.1-0.1c0.2,0.9-0.1,1.9-1.2,2.3c-2.4,0.8-3.6-2.9-1.2-3.7c0.3-0.1,0.5-0.1,0.7-0.1
								C1654.3,595.9,1653.2,596.2,1652.7,596.8z"/>
							<path class="st4" d="M1662.2,603.7c-0.8-0.6-1.8-0.4-2.5,0.3c-0.7,0.7-1.1,1.8-1.1,2.8c0,0.9,0.5,1.9,1.5,2
								c0.9,0.1,1.9-0.5,2-1.5c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
								C1663.2,605.5,1662.9,604.2,1662.2,603.7z M1662.2,606.6c0,0,0-0.1,0.1-0.1C1662.3,606.5,1662.2,606.5,1662.2,606.6z"/>
							<path class="st4" d="M1669.2,607.4c0.8-0.7,1.1-2,0.1-2.8c-1.7-1.3-3.8,1.2-2.3,2.6c0.1-0.1,0.3-0.2,0.5-0.2
								C1668.1,606.8,1668.8,607,1669.2,607.4z"/>
							<path class="st4" d="M1653.7,602.1C1653.7,602.1,1653.7,602.1,1653.7,602.1c-1.8-1.4-4,1.4-2.2,2.8c0.9,0.7,1.8,0.4,2.4-0.3
								C1653.1,604,1653.1,602.8,1653.7,602.1z"/>
							<path class="st4" d="M1647.1,597.8c-0.2,0.1-0.5,0.1-0.7,0.2c-0.9,0.2-1.8-0.4-2.1-1.3c-0.1-0.3-0.1-0.6,0-0.8
								c-0.7,0.7-0.9,1.9,0.1,2.6C1645.5,599.4,1646.7,598.8,1647.1,597.8z"/>
							<path class="st4" d="M1679.1,589.6c0.8,0.6,1.7,0.4,2.2-0.1c-0.9-0.6-1.8-1.1-2.8-1.5C1678.4,588.5,1678.5,589.1,1679.1,589.6
								z"/>
							<path class="st4" d="M1643.5,588c0.5,0.4,1,0.4,1.5,0.3c0.1-0.7,0.5-1.4,1.3-1.5c0-0.1,0-0.2,0-0.4h-3.5
								C1642.8,587,1643,587.6,1643.5,588z"/>
							<path class="st4" d="M1678.3,605.3c-0.7,0.2-1.3,0.1-1.8-0.3c-0.3,0.6-0.2,1.4,0.5,2c1.7,1.3,3.8-1.2,2.3-2.6
								C1679.2,604.7,1678.9,605.1,1678.3,605.3z"/>
							<path class="st4" d="M1668.6,610.7c1.6-0.5,1.6-2.5,0.6-3.3c-0.4-0.4-1.1-0.6-1.8-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
								C1665.2,608.3,1666.4,611.5,1668.6,610.7z"/>
							<path class="st4" d="M1672.6,617.9c-2.4,0.8-1.2,4.5,1.2,3.7C1676.2,620.8,1675,617.1,1672.6,617.9z"/>
							<path class="st4" d="M1676.9,613c2.4-0.8,1.2-4.5-1.2-3.7C1673.3,610.1,1674.5,613.8,1676.9,613z"/>
							<path class="st4" d="M1687,616.4c2.4-0.8,1.2-4.5-1.2-3.7C1683.4,613.5,1684.6,617.2,1687,616.4z"/>
							<path class="st4" d="M1677.1,601.6c-1.7,0.5-1.6,2.5-0.5,3.4c0.4,0.4,1,0.5,1.8,0.3c0.6-0.2,0.9-0.5,1.1-0.9
								C1680.1,603,1678.9,601,1677.1,601.6z"/>
							<path class="st4" d="M1665.5,618.7c2.4-0.8,1.2-4.5-1.2-3.7C1661.9,615.7,1663.2,619.4,1665.5,618.7z"/>
							<path class="st4" d="M1686.6,605.2c-0.4,0.3-0.8,0.5-1.2,0.8c-0.8,0.6-1.2,1.8-0.5,2.7c0.6,0.8,1.8,1.2,2.7,0.5
								c0.4-0.3,0.8-0.5,1.2-0.8c0.3-0.2,0.6-0.6,0.7-0.9v-1.4c0-0.1-0.1-0.2-0.2-0.3C1688.6,604.9,1687.5,604.6,1686.6,605.2z"/>
							<path class="st4" d="M1677.8,618.5c-0.4,1-0.1,2.1,0.9,2.6c0.2,0.1,0.3,0.2,0.5,0.3c0.9,0.5,2.2,0.1,2.6-0.9
								c0.4-1,0.1-2.1-0.9-2.6c-0.2-0.1-0.3-0.2-0.5-0.3C1679.5,617.1,1678.3,617.5,1677.8,618.5z"/>
							<path class="st4" d="M1668.1,629.3c0.3,0.4,0.7,0.7,1.2,0.8c0.2-1.1,1.3-1.8,2.3-2c0-0.3-0.1-0.7-0.3-1
								c-0.3-0.4-0.5-0.8-0.8-1.2c-0.6-0.8-1.8-1.2-2.7-0.5c-0.8,0.6-1.2,1.8-0.5,2.7C1667.6,628.5,1667.8,628.9,1668.1,629.3z"/>
							<path class="st4" d="M1668.8,596.5c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.6,0.1,1.2,0.5,1.7c0.3,0.3,0.6,0.5,1,0.6
								c1,0.2,2.1-0.4,2.3-1.5c0.1-0.4,0.1-0.9,0.2-1.3c0.2-1-0.4-2.1-1.5-2.3c-1-0.2-2,0.4-2.3,1.4
								C1668.8,596.4,1668.8,596.4,1668.8,596.5z"/>
							<path class="st4" d="M1681,627.7c-0.4-1.1-1.4-1.5-2.5-1.2c-0.8,0.2-1.6,0.4-2.3,0.6c-1,0.2-1.6,1.5-1.2,2.5
								c0.4,1.1,1.4,1.5,2.5,1.2c0.8-0.2,1.6-0.4,2.3-0.6C1680.8,629.9,1681.4,628.6,1681,627.7z"/>
							<path class="st4" d="M1687.1,625.1c-1.1,0.4-1.5,1.4-1.2,2.5c0.2,1,0.8,1.9,1.6,2.6c0.1-0.6,0.6-1.2,1.3-1.6
								c0.2-0.1,0.4-0.2,0.7-0.2V626C1689,625.2,1687.9,624.8,1687.1,625.1z"/>
							<path class="st4" d="M1682,635.4c1.7-1.1,0.5-4.2-1.7-3.5c-2.3,0.7-1.3,4.1,0.8,3.8c0,0,0.1-0.1,0.1-0.1
								C1681.6,635.5,1681.8,635.4,1682,635.4z"/>
							<path class="st4" d="M1670.1,634.4c-2.2,0.9-1,4.4,1.4,3.6c1.5-0.5,1.6-2,0.9-3c-0.2,0-0.4,0.1-0.7,0.1
								C1671.1,635.1,1670.5,634.8,1670.1,634.4z"/>
							<path class="st4" d="M1683.4,597.2c-2.4,0.8-1.2,4.5,1.2,3.7C1687,600.1,1685.8,596.4,1683.4,597.2z"/>
							<path class="st4" d="M1656.2,608.6c-2.4,0.8-1.2,4.5,1.2,3.7C1659.8,611.5,1658.6,607.8,1656.2,608.6z"/>
							<path class="st4" d="M1661.3,632.4c2.4-0.8,1.2-4.5-1.2-3.7C1657.7,629.5,1658.9,633.2,1661.3,632.4z"/>
							<path class="st4" d="M1652.6,624.6c2.4-0.8,1.2-4.5-1.2-3.7C1649,621.6,1650.2,625.3,1652.6,624.6z"/>
							<path class="st4" d="M1655.5,600.2c1.1-0.4,1.4-1.4,1.2-2.3c-0.2-0.8-0.8-1.5-1.7-1.5c-0.2,0-0.5,0-0.7,0.1
								C1651.9,597.2,1653.1,601,1655.5,600.2z"/>
							<path class="st4" d="M1669.5,644.9c-3,1.5-0.6,6.2,2.4,4.7C1674.9,648,1672.5,643.4,1669.5,644.9z"/>
							<path class="st4" d="M1678.7,658.2c-3,1.5-0.6,6.2,2.4,4.7C1684.1,661.4,1681.7,656.7,1678.7,658.2z"/>
							<path class="st4" d="M1681,646.2c-3,1.5-0.6,6.2,2.4,4.7C1686.4,649.4,1684,644.7,1681,646.2z"/>
							<path class="st4" d="M1682,635.4c-0.2,0-0.5,0.1-0.7,0.2c0,0-0.1,0.1-0.1,0.1c-2.8,1.6-0.5,6.1,2.5,4.6
								C1686.4,638.9,1684.7,634.8,1682,635.4z"/>
							<path class="st4" d="M1669.5,660.7c3-1.5,0.6-6.2-2.4-4.7C1664.1,657.6,1666.5,662.2,1669.5,660.7z"/>
							<path class="st4" d="M1685.8,657.9c-0.4,1.4,0.3,2.8,1.7,3.3c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0.1,0.8,0.1,1.2,0.1v-5.2
								c-0.1,0-0.2-0.1-0.3-0.1C1687.8,655.8,1686.2,656.5,1685.8,657.9z"/>
							<path class="st4" d="M1678.3,674.8c0.2-0.1,0.3-0.2,0.5-0.4c1-1,1.1-2.6,0.2-3.6c0,0,0-0.1-0.1-0.1c-0.4-0.5-0.9-0.9-1.3-1.4
								c-0.9-1-2.7-1.2-3.7-0.2c-1,1-1.2,2.6-0.2,3.7c0.4,0.5,0.9,0.9,1.3,1.4C1675.9,675.2,1677.3,675.4,1678.3,674.8z"/>
							<path class="st4" d="M1669.3,630.1c0,0.2-0.1,0.3-0.1,0.5c0,0.6,0,1.2,0,1.8c0,0.8,0.3,1.5,0.9,2.1c0.4,0.4,1,0.7,1.6,0.7
								c0.2,0,0.4,0,0.7-0.1c1.1-0.3,2.1-1.2,2.1-2.4c0-0.6,0-1.2,0-1.8c0-1.4-1-2.7-2.5-2.8c-0.1,0-0.2,0-0.4,0
								C1670.6,628.3,1669.5,629,1669.3,630.1z"/>
							<path class="st4" d="M1685.4,669.6c-1.3,0.5-1.8,2.4-1.1,3.5c0.7,1.3,2.2,1.7,3.5,1.1c0.5-0.2,1.1-0.5,1.6-0.7v-5.5
								c-0.3,0-0.7,0.1-1,0.2C1687.4,668.8,1686.4,669.2,1685.4,669.6z"/>
							<path class="st4" d="M1681.4,685.3c2.3-1.2,1.5-4.1-0.4-4.8c-0.6-0.2-1.3-0.2-2,0.2c-0.5,0.3-0.9,0.7-1.1,1.1
								C1676.9,683.6,1678.9,686.5,1681.4,685.3z"/>
							<path class="st4" d="M1687.5,630.1c-0.3,1.4,0.6,3,2,3.3v-5.1c-0.2,0-0.4,0.1-0.7,0.2C1688,628.9,1687.6,629.5,1687.5,630.1z"
								/>
							<path class="st4" d="M1657.4,654c3-1.5,0.6-6.2-2.4-4.7C1652,650.9,1654.4,655.5,1657.4,654z"/>
							<path class="st4" d="M1664.3,675.2c-1.6,0.8-1.7,2.4-1,3.6c0.4,0.6,0.9,1.1,1.6,1.3c0.5,0.1,1.1,0.1,1.7-0.2
								C1669.7,678.3,1667.3,673.7,1664.3,675.2z"/>
							<path class="st4" d="M1653.6,671.2c3-1.5,0.6-6.2-2.4-4.7C1648.2,668.1,1650.5,672.8,1653.6,671.2z"/>
							<path class="st4" d="M1652.3,638.2c3-1.5,0.6-6.2-2.4-4.7C1647,635.1,1649.3,639.8,1652.3,638.2z"/>
							<path class="st4" d="M1682.9,687.4c-0.1,1,0.3,1.9,1.5,2.4c0.1,0,0.1,0,0.2,0c2.8,0.9,4.4-3.5,1.5-4.5
								C1684.3,684.6,1683.1,686,1682.9,687.4z"/>
							<path class="st4" d="M1682.8,699.8c-2.9-1.1-4.6,3.4-1.7,4.5c1.1,0.4,2,0,2.5-0.6c0.4-0.5,0.7-1.1,0.7-1.8
								C1684.3,701,1683.9,700.1,1682.8,699.8z"/>
							<path class="st4" d="M1689.4,697.4V695C1689.1,695.7,1689,696.7,1689.4,697.4z"/>
							<path class="st4" d="M1678.2,691.5c-0.2-0.4-0.6-0.7-1.2-0.9c-2.9-1.1-4.6,3.4-1.7,4.5c1,0.4,1.9,0.1,2.4-0.5
								C1678.6,693.7,1678.8,692.4,1678.2,691.5z"/>
							<path class="st4" d="M1687.4,704.3c-1.2,0.6-1.7,2-1.2,3.2c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.5,0.7,0.9,1.2,1.1
								c0.5,0.3,1.2,0.3,1.8,0.1v-5.1C1688.8,704,1688.1,704,1687.4,704.3z"/>
							<path class="st4" d="M1670.1,704.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5-0.1,1-0.1,1.6c0,0.1,0,0.1,0,0.2c-0.1,1.1,0.7,2.3,1.8,2.5
								c0.1,0,0.2,0.1,0.4,0.1c1.3,0.1,2.5-0.8,2.6-2.2c0-0.6,0.1-1.2,0.1-1.8c0.1-1.3-0.9-2.5-2.2-2.6c-0.4,0-0.7,0.1-1,0.2
								C1670.9,703.7,1670.4,704.1,1670.1,704.6z"/>
							<path class="st4" d="M1678.2,711.2c-0.9,0.1-1.8,0.7-2,1.6c-0.3,1-0.1,1.9,0.6,2.5c0.2,0.2,0.5,0.4,0.8,0.6
								c0.9,0.4,1.8,0.8,2.7,1.2c0.4,0.2,0.8,0.2,1.3,0.1c0.8-0.2,1.6-0.8,1.8-1.5c0.4-1.3-0.2-2.5-1.4-3.1c-0.3-0.2-0.7-0.3-1-0.5
								c-0.6-0.3-1.1-0.5-1.7-0.8C1679,711.2,1678.6,711.1,1678.2,711.2z"/>
							<path class="st4" d="M1688.2,719.2c-0.4,0.9-0.6,1.9-0.5,2.9c0,0.4,0.1,0.8,0.2,1.2c0.2,0.8,0.8,1.6,1.6,1.9v-7.2
								C1688.9,718.2,1688.5,718.6,1688.2,719.2z"/>
							<path class="st4" d="M1680.3,719.6c-0.2-0.2-0.4-0.3-0.7-0.4c-0.8-0.3-1.6-0.1-2.1,0.3c-1.3,1-1.6,3.5,0.5,4.2
								c1.2,0.5,2.3-0.1,2.8-1C1681.2,721.7,1681.2,720.4,1680.3,719.6z"/>
							<path class="st4" d="M1666.2,718.4c2.9,1.1,4.6-3.4,1.7-4.5C1665,712.8,1663.3,717.3,1666.2,718.4z"/>
							<path class="st4" d="M1674,678.2c-2.9-1.1-4.6,3.4-1.7,4.5c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1
								C1675.7,682.3,1676.4,679.1,1674,678.2z"/>
							<path class="st4" d="M1660.7,705.1c2.4,0.9,4-2.1,2.7-3.7c-0.2-0.3-0.6-0.6-1.1-0.8c-0.8-0.3-1.6-0.1-2.1,0.2
								C1658.9,701.8,1658.6,704.3,1660.7,705.1z"/>
							<path class="st4" d="M1658.4,690.9c0.8,0.3,1.6,0.1,2.1-0.3c1.3-1,1.6-3.5-0.5-4.2c-2.4-0.9-3.9,1.9-2.8,3.6
								C1657.4,690.3,1657.8,690.7,1658.4,690.9z"/>
							<path class="st4" d="M1681.5,665c-2.9-1.1-4.6,3.4-1.7,4.5C1682.8,670.6,1684.4,666.1,1681.5,665z"/>
							<path class="st4" d="M1681.7,801.2c-3,0.3-2.5,5.1,0.5,4.7C1685.2,805.6,1684.7,800.8,1681.7,801.2z"/>
							<path class="st4" d="M1685.7,820.1c3-0.3,2.5-5.1-0.5-4.7C1682.1,815.7,1682.6,820.5,1685.7,820.1z"/>
							<path class="st4" d="M1689.4,809.9v-3C1688.8,807.7,1688.8,809,1689.4,809.9z"/>
							<path class="st4" d="M1676.5,814.5c3-0.3,2.5-5.1-0.5-4.7C1673,810.1,1673.5,814.9,1676.5,814.5z"/>
							<path class="st4" d="M1680.7,824.5c-0.5-1.2-1.9-1.9-3.1-1.3c-1.2,0.5-1.8,1.9-1.3,3.1c0.2,0.5,0.4,1.1,0.7,1.6
								c0.5,1.2,1.9,1.9,3.1,1.3c1.2-0.5,1.8-1.9,1.3-3.1C1681.1,825.6,1680.9,825,1680.7,824.5z"/>
							<path class="st4" d="M1686.2,788.7c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.5-0.4,1-0.6,1.5c-0.3,0.7-0.2,1.5,0.1,2.2
								c0.2,0.4,0.6,0.8,1.1,0.9c0.9,0.4,2.1,0.2,2.8-0.6v-5.2c-0.1,0-0.1-0.1-0.2-0.1C1688.1,787.3,1686.8,787.6,1686.2,788.7z"/>
							<path class="st4" d="M1685.1,829.8c0.2,1.4,1.3,2.1,2.6,2.1c0.6,0,1.2,0,1.7,0v-3.1c0-0.2,0-0.4,0-0.7v-0.9
								c-0.8,0-1.5,0-2.3,0.1C1685.9,827.3,1684.9,828.6,1685.1,829.8z"/>
							<path class="st4" d="M1689.4,838.1v-3.3C1688.6,835.7,1688.6,837.2,1689.4,838.1z"/>
							<path class="st4" d="M1677.5,839c0.3,0.1,0.7,0.2,1.2,0.2c3-0.3,2.5-5.1-0.5-4.7c-1.6,0.2-2.2,1.5-2,2.7c0.1,0,0.1,0,0.2,0
								C1677.3,837.6,1677.6,838.3,1677.5,839z"/>
							<path class="st4" d="M1668.4,804.9c3-0.3,2.5-5.1-0.5-4.7C1664.9,800.5,1665.4,805.3,1668.4,804.9z"/>
							<path class="st4" d="M1667.9,829.9c3-0.3,2.5-5.1-0.5-4.7C1664.4,825.5,1664.9,830.2,1667.9,829.9z"/>
							<path class="st4" d="M1659.6,818.3c3-0.3,2.5-5.1-0.5-4.7C1656.1,813.9,1656.6,818.6,1659.6,818.3z"/>
							<path class="st4" d="M1669.3,785.2c-0.2,0-0.4,0-0.5,0c-2.6,0.3-2.6,3.9-0.6,4.6c0.3,0.1,0.7,0.2,1.1,0.1
								c0.3,0,0.5-0.1,0.8-0.2c1.3-0.6,1.6-2.3,0.9-3.5C1670.6,785.7,1670,785.3,1669.3,785.2z"/>
							<path class="st4" d="M1683.5,833.1c-2.3-0.9-3.6,2.7-1.3,3.5C1684.5,837.5,1685.8,834,1683.5,833.1z"/>
							<path class="st4" d="M1680.2,846.8c-0.9-0.4-1.2-1.2-1.2-1.9c-0.9,0.9-1,2.6,0.5,3.2c1.1,0.4,2-0.2,2.4-1.1
								C1681.4,847.1,1680.8,847.1,1680.2,846.8z"/>
							<path class="st4" d="M1688.4,839.9c-2.3-0.9-3.6,2.7-1.3,3.5c1.2,0.5,2.1-0.3,2.4-1.2V841
								C1689.3,840.5,1688.9,840.2,1688.4,839.9z"/>
							<path class="st4" d="M1675,840.8c1.4,0.5,2.4-0.6,2.5-1.7c0.1-0.7-0.3-1.5-1.2-1.8c-0.1,0-0.1,0-0.2,0
								C1674,836.6,1672.8,839.9,1675,840.8z"/>
							<path class="st4" d="M1683.5,850.6c0.1,0.2,0.1,0.3,0.2,0.5c0.4,0.9,1.6,1.4,2.5,1c0.9-0.5,1.4-1.5,1-2.5
								c-0.1-0.2-0.1-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-1C1683.5,848.6,1683.1,849.7,1683.5,850.6z"/>
							<path class="st4" d="M1672.2,852.4c1,0.1,2-0.6,2.1-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2.1c-1-0.1-2,0.6-2.1,1.7
								c0,0.5-0.1,0.9-0.1,1.4C1670.4,851.3,1671.1,852.4,1672.2,852.4z"/>
							<path class="st4" d="M1689.4,828.8v-0.7C1689.4,828.3,1689.4,828.5,1689.4,828.8z"/>
							<path class="st4" d="M1680.1,854.6c-0.7-0.3-1.4-0.7-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
								c0.7,0.3,1.4,0.7,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1681.5,856,1681.1,855.1,1680.1,854.6z"/>
							<path class="st4" d="M1684.9,859.9c-0.4,0.8-0.5,1.7-0.4,2.6c0.8,0.5,1.2,1.2,1.1,2c0.3,0.2,0.7,0.3,1.1,0.2
								c0.9-0.2,1.8-1.2,1.6-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0.2-0.4
								c0.4-0.9-0.2-2.1-1.1-2.4C1686.3,858.4,1685.4,858.9,1684.9,859.9z"/>
							<path class="st4" d="M1678.1,859.8c-2.3-0.9-3.6,2.7-1.3,3.5C1679,864.2,1680.4,860.7,1678.1,859.8z"/>
							<path class="st4" d="M1667.7,859c2.3,0.9,3.6-2.7,1.3-3.5C1666.7,854.6,1665.4,858.2,1667.7,859z"/>
							<path class="st4" d="M1672.8,831c2.3,0.9,3.6-2.7,1.3-3.5C1671.8,826.6,1670.5,830.1,1672.8,831z"/>
							<path class="st4" d="M1663.4,848.5c2.3,0.9,3.6-2.7,1.3-3.5C1662.5,844.1,1661.1,847.7,1663.4,848.5z"/>
							<path class="st4" d="M1661.7,837.3c2.3,0.9,3.6-2.7,1.3-3.5C1660.7,832.9,1659.4,836.5,1661.7,837.3z"/>
							<path class="st4" d="M1680.1,817.1c-2.3-0.9-3.6,2.7-1.3,3.5C1681,821.6,1682.4,818,1680.1,817.1z"/>
							<path class="st4" d="M1684.5,862.5C1684.5,862.5,1684.5,862.5,1684.5,862.5c-2.7-1.4-4.8,2.7-2.1,4.1c1.7,0.9,3.2-0.6,3.3-2.1
								C1685.7,863.7,1685.4,863,1684.5,862.5z"/>
							<path class="st4" d="M1679.8,875.9c-2.6-1.3-4.7,2.8-2.1,4.1C1680.4,881.3,1682.5,877.2,1679.8,875.9z"/>
							<path class="st4" d="M1687.4,875.4c0.7,0.4,1.4,0.3,2,0v-4.1C1686.8,870,1684.8,874.1,1687.4,875.4z"/>
							<path class="st4" d="M1673.3,870.7c2.6,1.3,4.7-2.8,2.1-4.1C1672.7,865.2,1670.6,869.3,1673.3,870.7z"/>
							<path class="st4" d="M1683.8,880.7c-1.2,0.4-1.9,1.7-1.5,2.9c0.1,0.2,0.1,0.4,0.2,0.6c0.4,1.1,1.8,1.9,2.9,1.5
								c1.2-0.4,1.9-1.7,1.5-2.9c-0.1-0.2-0.1-0.4-0.2-0.6C1686.3,881,1684.9,880.3,1683.8,880.7z"/>
							<path class="st4" d="M1669.6,878.2c-1.2-0.2-2.5,0.5-2.7,1.8c-0.1,0.6-0.2,1.1-0.3,1.7c-0.2,1.2,0.6,2.5,1.8,2.7
								c1.2,0.2,2.5-0.5,2.7-1.8c0.1-0.6,0.2-1.1,0.3-1.7C1671.6,879.7,1670.8,878.4,1669.6,878.2z"/>
							<path class="st4" d="M1673.2,890.7c0.8,0.5,1.6,1,2.4,1.5c1,0.6,2.6,0,3.1-1c0.5-1.2,0.1-2.4-1-3.1c-0.8-0.5-1.6-1-2.4-1.5
								c-1-0.6-2.6,0-3.1,1C1671.7,888.8,1672.1,890,1673.2,890.7z"/>
							<path class="st4" d="M1686.1,894c-1.2-0.5-2.4-0.1-3.1,1c-0.7,1.1-1,2.5-0.8,3.8c0.2,1.2,1.1,2.3,2.4,2.1
								c1.1-0.1,2.4-1.2,2.1-2.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.2-0.4
								C1687.7,896,1687.1,894.4,1686.1,894z M1686.7,898.1c0-0.1,0-0.2,0-0.2C1686.7,897.9,1686.7,898,1686.7,898.1z M1686.8,897.5
								c0,0,0,0.1-0.1,0.2C1686.8,897.6,1686.8,897.5,1686.8,897.5z"/>
							<path class="st4" d="M1674.7,894.1c-2.6-1.3-4.7,2.8-2.1,4.1C1675.3,899.5,1677.4,895.4,1674.7,894.1z"/>
							<path class="st4" d="M1664.2,887.8c-2.6-1.3-4.7,2.8-2.1,4.1C1664.8,893.2,1666.8,889.1,1664.2,887.8z"/>
							<path class="st4" d="M1673.7,854.5c-2.6-1.3-4.7,2.8-2.1,4.1C1674.3,859.9,1676.4,855.8,1673.7,854.5z"/>
							<path class="st4" d="M1660.3,874.5c-2.6-1.3-4.7,2.8-2.1,4.1C1660.9,880,1662.9,875.9,1660.3,874.5z"/>
							<path class="st4" d="M1657.5,864.9c2.7,1.3,4.7-2.8,2.1-4.1C1656.9,859.4,1654.8,863.6,1657.5,864.9z"/>
							<path class="st4" d="M1680.2,846.8c0.6,0.3,1.2,0.3,1.7,0.1c1.7-0.6,2.4-3.2,0.4-4.3c-1.8-0.9-3.3,0.7-3.3,2.2
								C1679,845.6,1679.3,846.4,1680.2,846.8z"/>
							<path class="st4" d="M1585.4,590.4c-2.1-2-5.1,1.3-3,3.2C1584.5,595.6,1587.5,592.3,1585.4,590.4z"/>
							<path class="st4" d="M1596.1,589.3c-0.1-0.2-0.2-0.4-0.4-0.5c-2.1-2-5.1,1.3-3,3.2c1.4,1.3,3.3,0.2,3.7-1.2
								C1596,590.4,1595.9,589.8,1596.1,589.3z"/>
							<path class="st4" d="M1594.8,604c2.1,2,5.1-1.3,3-3.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.1,0-0.2,0.1-0.3,0.1
								C1594.4,600.6,1593.3,602.6,1594.8,604z"/>
							<path class="st4" d="M1601.2,589.1c1.9,1.8,4.6-0.8,3.5-2.7h-0.4c-0.4,0.2-0.8,0.2-1.4,0c0,0,0,0,0,0h-2
								C1600.3,587.2,1600.3,588.3,1601.2,589.1z"/>
							<path class="st4" d="M1606.7,600.4c0.5-1.1,0.2-2.4-0.9-3c-0.5-0.3-1-0.5-1.5-0.8c-1-0.5-2.5-0.2-3,0.9
								c-0.5,1.1-0.2,2.4,0.9,3c0.5,0.3,1,0.5,1.5,0.8C1604.7,601.8,1606.2,601.5,1606.7,600.4z M1602.8,600.1
								c-2.3-0.8-1-4.4,1.3-3.5C1606.3,597.4,1605,600.9,1602.8,600.1z"/>
							<path class="st4" d="M1585.6,598.8c0,1.2,1.1,2.2,2.3,2.1c1.2-0.1,2.2-1.1,2.1-2.3c0-0.2,0-0.4,0-0.6c0-1.2-1.1-2.2-2.3-2.1
								c-1.2,0.1-2.2,1.1-2.1,2.3C1585.6,598.4,1585.6,598.6,1585.6,598.8z"/>
							<path class="st4" d="M1572.5,595.1c0,0,0.1,0,0.1,0c-0.1-1.2,1-2.5,2.5-2c0.3,0.1,0.5,0.3,0.7,0.4c0.2-0.3,0.3-0.6,0.5-0.9
								c0,0,0-0.1,0.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.3-2-1.5-1.9-2.7c-0.5,0.2-0.9,0.5-1.1,1c-0.3,0.5-0.5,1-0.8,1.5
								C1571,593.1,1571.4,594.6,1572.5,595.1z"/>
							<path class="st4" d="M1579.4,603.6c0.4,0.1,0.6,0.3,0.8,0.6c0.9-1,0.8-2.2-0.1-3.2c-0.6-0.7-1.2-1.3-1.8-2
								c-0.8-0.9-2.4-0.8-3.1,0.1c-0.8,1-0.7,2.2,0.1,3.1c0.6,0.7,1.2,1.3,1.8,2c0,0,0.1,0.1,0.1,0.1
								C1577.6,603.7,1578.4,603.2,1579.4,603.6z"/>
							<path class="st4" d="M1586.2,608.8c-1-0.8-2.2-0.7-3.1,0.1c-0.9,0.8-1.6,2.1-1.8,3.3c-0.1,1.1,0.4,2.4,1.7,2.7
								c1.1,0.2,2.5-0.4,2.7-1.7c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.4-0.3
								c0.3-0.2,0.4-0.5,0.5-0.8C1586,610.6,1585.8,609.6,1586.2,608.8z M1585.7,612.8c0-0.1,0-0.2,0.1-0.2
								C1585.7,612.6,1585.7,612.7,1585.7,612.8z M1585.8,612.4c0,0,0.1-0.1,0.1-0.2C1585.9,612.3,1585.9,612.3,1585.8,612.4z"/>
							<path class="st4" d="M1595,610.8c-2.1-2-5.2,1.3-3.1,3.2c1.7,1.6,4-0.2,3.7-2c-0.3-0.3-0.5-0.7-0.6-1.1
								C1595,610.9,1595,610.8,1595,610.8z"/>
							<path class="st4" d="M1572.6,609c2.1,2,5.1-1.3,3-3.2C1573.6,603.8,1570.5,607.1,1572.6,609z"/>
							<path class="st4" d="M1566.6,596.5c-1.9-0.3-3.6,2.2-1.9,3.8c1.6,1.5,3.8-0.1,3.7-1.8c-0.2-0.1-0.4-0.1-0.6-0.3
								C1567,597.8,1566.7,597.1,1566.6,596.5z"/>
							<path class="st4" d="M1612.1,589.8c-1.5-1.4-3.5-0.1-3.7,1.5c0.3,0,0.5,0,0.8,0.1c1,0.1,1.8,1,1.7,2c0,0.1,0,0.1,0,0.2
								C1612.4,593.3,1613.6,591.2,1612.1,589.8z"/>
							<path class="st4" d="M1568.1,586.4h-3.8c0.1,0.2,0.2,0.4,0.4,0.5C1565.9,588.1,1567.4,587.5,1568.1,586.4z"/>
							<path class="st4" d="M1604.4,614.6c2.1,2,5.1-1.3,3-3.2C1605.4,609.4,1602.3,612.6,1604.4,614.6z"/>
							<path class="st4" d="M1630.5,590.1c-2.2-0.1-2.3,3.4-0.1,3.4C1632.6,593.6,1632.7,590.2,1630.5,590.1z"/>
							<path class="st4" d="M1631.4,604.1c2.2,0.1,2.3-3.4,0.1-3.4C1629.3,600.6,1629.2,604,1631.4,604.1z"/>
							<path class="st4" d="M1636.5,597.9c2.2,0.1,2.3-3.4,0.1-3.4C1634.4,594.4,1634.3,597.9,1636.5,597.9z"/>
							<path class="st4" d="M1644,600.3c-2.2-0.1-2.3,3.4-0.1,3.4C1646.1,603.8,1646.2,600.4,1644,600.3z"/>
							<path class="st4" d="M1640.2,588.5c-2.2-0.1-2.3,3.4-0.1,3.4C1642.3,592,1642.4,588.6,1640.2,588.5z"/>
							<path class="st4" d="M1625.5,599.1c2.2,0.1,2.3-3.4,0.1-3.4C1623.4,595.7,1623.3,599.1,1625.5,599.1z"/>
							<path class="st4" d="M1644.4,596.8c0.3,0.9,1.1,1.5,2.1,1.3c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0,0.4-0.1,0.5-0.1
								c0.9-0.2,1.5-1.2,1.3-2.1c-0.3-0.9-1.1-1.5-2.1-1.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.6,0.2-1.1,0.7-1.3,1.3
								C1644.3,596.2,1644.3,596.5,1644.4,596.8z"/>
							<path class="st4" d="M1638.1,602.8c-0.6-0.7-1.8-0.7-2.4-0.1c-0.6,0.7-0.7,1.7-0.1,2.4c0.1,0.1,0.2,0.2,0.3,0.4
								c0.6,0.7,1.8,0.7,2.4,0.1c0.6-0.7,0.7-1.7,0.1-2.4C1638.3,603,1638.2,602.9,1638.1,602.8z"/>
							<path class="st4" d="M1627.4,606.7c-0.2-0.9-1.2-1.5-2.1-1.3c-0.9,0.3-1.5,1.1-1.3,2.1c0.1,0.4,0.2,0.8,0.3,1.2
								c0.2,0.9,1.2,1.5,2.1,1.3c0.9-0.3,1.5-1.1,1.3-2.1C1627.6,607.5,1627.5,607.1,1627.4,606.7z"/>
							<path class="st4" d="M1633.9,609.5c-0.7-0.1-1.4-0.2-2.1-0.2c-0.9-0.1-1.8,0.8-1.8,1.7c0,1,0.7,1.7,1.7,1.8
								c0.7,0.1,1.4,0.2,2.1,0.2c0.9,0.1,1.8-0.8,1.8-1.7C1635.5,610.3,1634.8,609.6,1633.9,609.5z"/>
							<path class="st4" d="M1643.1,613.5c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4c0.1-0.9-0.8-1.8-1.7-1.8
								c-1,0-1.6,0.7-1.8,1.7c-0.1,1,0.1,2,0.7,2.8c0.5,0.7,1.5,1.2,2.3,0.7c0.7-0.4,1.2-1.5,0.7-2.3
								C1643.2,613.7,1643.2,613.6,1643.1,613.5z"/>
							<path class="st4" d="M1647.4,607.6c-2.2-0.1-2.3,3.4-0.1,3.4C1649.5,611.1,1649.6,607.7,1647.4,607.6z"/>
							<path class="st4" d="M1633.7,614.5c-2.2-0.1-2.3,3.4-0.1,3.4C1635.8,618,1635.9,614.6,1633.7,614.5z"/>
							<path class="st4" d="M1624.7,613.5c-2.2-0.1-2.3,3.4-0.1,3.4C1626.7,617,1626.9,613.6,1624.7,613.5z"/>
							<path class="st4" d="M1645,588.3c-0.1,0.9,0.4,1.9,1.6,1.9c2.2,0.1,2.3-3.4,0.1-3.4c-0.1,0-0.2,0-0.3,0
								C1645.5,586.9,1645.1,587.6,1645,588.3z"/>
							<path class="st4" d="M1620.8,588c-2.2-0.1-2.3,3.4-0.1,3.4C1622.8,591.5,1623,588.1,1620.8,588z"/>
							<path class="st4" d="M1617.9,605.8c-2.2-0.1-2.3,3.4-0.1,3.4C1620,609.3,1620.1,605.9,1617.9,605.8z"/>
							<path class="st4" d="M1655,601.6c-0.6,0-1,0.2-1.3,0.5c-0.6,0.7-0.5,1.9,0.2,2.5c0.3,0.2,0.6,0.4,1,0.4
								C1657.1,605.1,1657.2,601.6,1655,601.6z"/>
							<path class="st4" d="M1613.1,596.7c-2.2-0.1-2.3,3.4-0.1,3.4C1615.2,600.2,1615.3,596.8,1613.1,596.7z"/>
							<path class="st4" d="M1589.7,596.1c2.3,0.8,3.6-2.7,1.3-3.5C1588.8,591.7,1587.5,595.2,1589.7,596.1z"/>
							<path class="st4" d="M1598.5,588c-1.2-0.5-2.1,0.4-2.4,1.3c-0.1,0.5-0.1,1.1,0.2,1.5c0.2,0.3,0.5,0.5,0.9,0.7
								C1599.4,592.3,1600.8,588.8,1598.5,588z"/>
							<path class="st4" d="M1604.1,596.6c-2.3-0.8-3.6,2.7-1.3,3.5C1605,600.9,1606.3,597.4,1604.1,596.6z"/>
							<path class="st4" d="M1604.3,586.4h-1.4c0,0,0,0,0,0C1603.4,586.7,1603.9,586.6,1604.3,586.4z"/>
							<path class="st4" d="M1585.3,588.9c1.7,0.6,2.8-1.2,2.4-2.5h-3.4C1583.9,587.3,1584.1,588.5,1585.3,588.9z"/>
							<path class="st4" d="M1609.2,591.4c-0.3,0-0.5,0-0.8-0.1c-0.2,0-0.4,0-0.6,0c-1-0.1-2,0.7-2,1.7c0,1,0.7,2,1.7,2
								c0.5,0,0.9,0.1,1.4,0.1c0.9,0.1,1.8-0.6,2-1.5c0-0.1,0-0.1,0-0.2C1610.9,592.4,1610.2,591.5,1609.2,591.4z"/>
							<path class="st4" d="M1597.4,597.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.9-1.6-1.4-2.5-0.9c-0.9,0.5-1.4,1.5-0.9,2.5
								c0.1,0.2,0.2,0.3,0.2,0.5c0.3,0.7,1.1,1.2,1.9,1.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
								C1597.4,599.6,1597.8,598.5,1597.4,597.5z"/>
							<path class="st4" d="M1582.5,600.5c1,0,2-0.7,2-1.7c0-0.5,0.1-0.9,0.1-1.4c0.1-1-0.7-2-1.7-2c-1,0-2,0.7-2,1.7
								c0,0.5-0.1,0.9-0.1,1.4C1580.7,599.4,1581.5,600.4,1582.5,600.5z"/>
							<path class="st4" d="M1590.4,602.6c-0.7-0.3-1.4-0.6-2.1-1c-0.9-0.4-2.1,0.2-2.4,1.1c-0.3,1,0.1,2,1.1,2.4
								c0.7,0.3,1.4,0.6,2.1,1c0.9,0.4,2.1-0.2,2.4-1.1C1591.8,604,1591.4,603,1590.4,602.6z"/>
							<path class="st4" d="M1597.7,606.7c-1-0.3-2,0.2-2.4,1.1c-0.4,0.9-0.5,2-0.3,3c0,0,0,0.1,0,0.1c0.1,0.4,0.3,0.8,0.6,1.1
								c0.4,0.4,0.9,0.6,1.5,0.5c0.9-0.2,1.8-1.2,1.5-2.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
								c0.1-0.2,0.1-0.3,0.2-0.4C1599.2,608.2,1598.6,607,1597.7,606.7z"/>
							<path class="st4" d="M1605.1,605.3c-2.3-0.8-3.6,2.7-1.3,3.5C1606.1,609.7,1607.4,606.2,1605.1,605.3z"/>
							<path class="st4" d="M1588.5,607.8c-1.1-0.4-1.9,0.2-2.3,1c-0.3,0.8-0.2,1.8,0.6,2.3c0.1,0.1,0.2,0.1,0.4,0.2
								C1589.4,612.1,1590.8,608.6,1588.5,607.8z"/>
							<path class="st4" d="M1580.5,604.8c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.2-0.5-0.4-0.8-0.6c-1-0.4-1.8,0.1-2.2,0.8
								c-0.5,0.9-0.4,2.3,0.9,2.7c1.1,0.4,2-0.2,2.3-1.1C1580.4,605.6,1580.4,605.2,1580.5,604.8z"/>
							<path class="st4" d="M1612.3,586.4h-2.8c0.2,0.2,0.4,0.4,0.8,0.5C1611.2,587.3,1611.9,587,1612.3,586.4z"/>
							<path class="st4" d="M1572.6,595.1c0,0.6,0.4,1.3,1.2,1.6c2,0.7,3.2-1.9,2-3.1c-0.2-0.2-0.4-0.3-0.7-0.4
								C1573.6,592.6,1572.6,593.9,1572.6,595.1z"/>
							<path class="st4" d="M1615.1,601.7c-2.3-0.8-3.6,2.7-1.3,3.5C1616,606,1617.3,602.5,1615.1,601.7z"/>
							<path class="st4" d="M1673.6,906.2c-2.1-0.4-2.7,2.8-0.7,3.2C1675,909.8,1675.7,906.6,1673.6,906.2z"/>
							<path class="st4" d="M1672.2,919.5c2.1,0.4,2.7-2.8,0.7-3.2C1670.8,915.9,1670.1,919.1,1672.2,919.5z"/>
							<path class="st4" d="M1678,914.6c2.1,0.4,2.7-2.8,0.7-3.2C1676.6,910.9,1676,914.1,1678,914.6z"/>
							<path class="st4" d="M1684.6,918.1c-2.1-0.4-2.7,2.8-0.7,3.2C1686,921.7,1686.7,918.5,1684.6,918.1z"/>
							<path class="st4" d="M1683,906.3c-2.1-0.4-2.7,2.8-0.7,3.2C1684.4,909.9,1685.1,906.7,1683,906.3z"/>
							<path class="st4" d="M1668.1,910.7c-2.1-0.4-2.7,2.8-0.7,3.2C1669.5,914.3,1670.1,911.1,1668.1,910.7z"/>
							<path class="st4" d="M1687.1,913c-0.9,0.1-1.6,0.9-1.5,1.7c0.1,0.9,0.8,1.6,1.7,1.5c0.4,0,0.8-0.1,1.2-0.1
								c0.3,0,0.6-0.2,0.9-0.4v-2.6c-0.3-0.2-0.7-0.4-1.1-0.4C1687.9,913,1687.5,913,1687.1,913z"/>
							<path class="st4" d="M1679,919.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3
								c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.7,1.5,1,2.3,0.5C1679.2,921.5,1679.5,920.5,1679,919.8z"/>
							<path class="st4" d="M1666.2,919.8c-0.9,0.1-1.6,0.8-1.5,1.7c0,0.4,0.1,0.8,0.1,1.2c0.1,0.9,0.9,1.6,1.7,1.5
								c0.9-0.1,1.6-0.8,1.5-1.7c0-0.4-0.1-0.8-0.1-1.2C1667.9,920.4,1667.1,919.7,1666.2,919.8z"/>
							<path class="st4" d="M1681.5,901.3c0.5-0.7,0.6-1.7-0.1-2.3c-0.7-0.5-1.7-0.6-2.3,0.1c-0.2,0.3-0.5,0.6-0.7,0.9
								c-0.5,0.7-0.6,1.7,0.1,2.3c0.7,0.5,1.7,0.6,2.3-0.1C1681,901.9,1681.3,901.6,1681.5,901.3z"/>
							<path class="st4" d="M1673.6,925c-0.6-0.2-1.3-0.4-1.9-0.6c-0.8-0.2-1.8,0.4-1.9,1.3c-0.2,0.9,0.4,1.7,1.3,1.9
								c0.6,0.2,1.3,0.4,1.9,0.6c0.8,0.2,1.8-0.4,1.9-1.3C1675,926,1674.5,925.3,1673.6,925z"/>
							<path class="st4" d="M1681.6,930.3c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.3c0.3-0.8-0.5-1.8-1.3-1.9
								c-0.9-0.2-1.7,0.4-1.9,1.3c-0.3,0.9-0.2,1.9,0.2,2.8c0.3,0.8,1.2,1.4,2.1,1c0.8-0.3,1.4-1.2,1-2.1
								C1681.7,930.5,1681.7,930.4,1681.6,930.3z"/>
							<path class="st4" d="M1686,928.7c2.1,0.4,2.7-2.8,0.7-3.2C1684.6,925.1,1683.9,928.3,1686,928.7z"/>
							<path class="st4" d="M1672.6,929.7c-2.1-0.4-2.7,2.8-0.7,3.2C1674,933.4,1674.7,930.2,1672.6,929.7z"/>
							<path class="st4" d="M1664.3,927.3c-2.1-0.4-2.7,2.8-0.7,3.2C1665.6,930.9,1666.3,927.7,1664.3,927.3z"/>
							<path class="st4" d="M1688.8,909c0.2,0.1,0.5,0,0.7,0v-3.2C1687.4,905.3,1686.7,908.5,1688.8,909z"/>
							<path class="st4" d="M1664.1,905.9c2.1,0.4,2.7-2.8,0.7-3.2C1662.7,902.2,1662.1,905.4,1664.1,905.9z"/>
							<path class="st4" d="M1659.2,918.9c-2.1-0.4-2.7,2.8-0.7,3.2C1660.6,922.5,1661.2,919.3,1659.2,918.9z"/>
							<path class="st4" d="M1656.2,909.6c-2.1-0.4-2.7,2.8-0.7,3.2C1657.6,913.2,1658.2,910,1656.2,909.6z"/>
							<path class="st4" d="M1668.5,893c-2.1-0.4-2.7,2.8-0.7,3.2C1669.9,896.6,1670.6,893.4,1668.5,893z"/>
							<path class="st4" d="M877.6,589.3c1.7,0.3,2.5-1.7,1.7-2.8h-2.7C875.9,587.4,876.1,589,877.6,589.3z"/>
							<path class="st4" d="M877,600.1c2.2,0.4,2.9-3,0.6-3.4C875.4,596.2,874.8,599.6,877,600.1z"/>
							<path class="st4" d="M883.1,594.7c2.2,0.4,2.9-3,0.6-3.4C881.5,590.8,880.9,594.2,883.1,594.7z"/>
							<path class="st4" d="M890.2,598.3c-2.2-0.4-2.9,3-0.6,3.4C891.8,602.1,892.5,598.7,890.2,598.3z"/>
							<path class="st4" d="M887.6,589.1c1.7,0.3,2.5-1.6,1.8-2.7h-2.8C885.9,587.4,886.2,588.9,887.6,589.1z"/>
							<path class="st4" d="M872.4,590.7c-2.2-0.4-2.9,3-0.6,3.4C873.9,594.6,874.6,591.1,872.4,590.7z"/>
							<path class="st4" d="M894.1,592.7c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0.1-1.7,0.9-1.6,1.9c0.1,0.9,0.9,1.7,1.9,1.6
								c0.4,0,0.9-0.1,1.3-0.1c0.9-0.1,1.7-0.9,1.6-1.9C895.9,593.3,895.1,592.6,894.1,592.7z"/>
							<path class="st4" d="M884.2,600.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.8-1.7-1-2.4-0.5c-0.8,0.6-1,1.6-0.5,2.4
								c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.8,1.7,1,2.4,0.5C884.5,602.1,884.8,601,884.2,600.2z"/>
							<path class="st4" d="M870.6,600.5c-0.9,0.1-1.7,0.9-1.6,1.9c0,0.4,0.1,0.9,0.1,1.3c0.1,0.9,0.9,1.7,1.9,1.6
								c0.9-0.1,1.7-0.9,1.6-1.9c0-0.4-0.1-0.9-0.1-1.3C872.4,601.1,871.6,600.3,870.6,600.5z"/>
							<path class="st4" d="M878.6,605.9c-0.7-0.2-1.4-0.4-2.1-0.6c-0.9-0.2-1.9,0.5-2,1.4c-0.1,1,0.4,1.8,1.4,2
								c0.7,0.2,1.4,0.4,2.1,0.6c0.9,0.2,1.9-0.5,2-1.4C880.2,607,879.6,606.2,878.6,605.9z"/>
							<path class="st4" d="M887.3,611.4c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.4c0.3-0.9-0.5-1.9-1.4-2
								c-1-0.1-1.8,0.5-2,1.4c-0.3,0.9-0.2,2.1,0.2,2.9c0.4,0.8,1.3,1.4,2.2,1.1c0.8-0.3,1.5-1.4,1.1-2.2
								C887.4,611.6,887.3,611.5,887.3,611.4z"/>
							<path class="st4" d="M892.6,606.2c-2.2-0.4-2.9,3-0.6,3.4C894.1,610,894.8,606.6,892.6,606.2z"/>
							<path class="st4" d="M877.7,611c-2.2-0.4-2.9,3-0.6,3.4C879.2,614.8,879.9,611.4,877.7,611z"/>
							<path class="st4" d="M868.7,608.6c-2.2-0.4-2.9,3-0.6,3.4C870.2,612.4,870.9,609,868.7,608.6z"/>
							<path class="st4" d="M894.4,588.4c1.4,0.3,2.1-0.9,2-2h-3.3C893,587.3,893.4,588.2,894.4,588.4z"/>
							<path class="st4" d="M863.1,599.7c-2.2-0.4-2.9,3-0.6,3.4C864.6,603.6,865.3,600.1,863.1,599.7z"/>
							<path class="st4" d="M901.1,601.2c-2.2-0.4-2.9,3-0.6,3.4C902.7,605.1,903.4,601.6,901.1,601.2z"/>
							<path class="st4" d="M858.4,593c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0.1,0.8,0,1.1-0.2c-0.3-0.1-0.6-0.2-0.9-0.3
								c-1-0.6-1.3-1.6-1.1-2.5c-0.5,0.5-0.7,1.2-0.5,1.8C857.8,592.4,858,592.8,858.4,593z"/>
							<path class="st4" d="M829.4,596.9c0,0.8,0.3,1.5,1.1,2.1c0.1,0.1,0.3,0.1,0.4,0.2c1.2,0.5,2.4-0.1,2.9-1.1
								c0.6-1,0.6-2.4-0.7-3.3c-0.3-0.2-0.5-0.3-0.8-0.3C830.8,594.2,829.5,595.5,829.4,596.9z"/>
							<path class="st4" d="M841.3,595.1c2.1,1.3,4.2-1,3.6-2.9c-0.1-0.4-0.3-0.7-0.7-1c-0.1-0.1-0.2-0.2-0.3-0.3
								C841.2,589.2,838.6,593.4,841.3,595.1z"/>
							<path class="st4" d="M848.8,603.4c-0.6-0.3-1.1-0.4-1.6-0.4c-1.9,0.3-3.1,3.2-1,4.5c2.4,1.5,4.8-1.7,3.2-3.6
								C849.2,603.7,849,603.6,848.8,603.4z"/>
							<path class="st4" d="M849.9,590.2c2.5,1.5,4.9-1.9,3.1-3.7h-3.5C848.6,587.5,848.4,589.2,849.9,590.2z"/>
							<path class="st4" d="M826.7,588.6c1.9,1.2,3.7-0.5,3.7-2.2h-4.8C825.5,587.2,825.8,588.1,826.7,588.6z"/>
							<path class="st4" d="M857.1,602.6c0.6-0.3,1-0.8,1.2-1.5c0.2-0.7,0-1.4-0.3-2c-0.3-0.5-0.7-0.8-1.3-1
								c-0.6-0.2-1.2-0.3-1.8-0.5c-1.2-0.4-2.7,0.4-3,1.7c-0.3,1.3,0.3,2.7,1.7,3c0.6,0.2,1.2,0.3,1.8,0.5
								C855.8,603,856.5,602.9,857.1,602.6z"/>
							<path class="st4" d="M838.1,605.8c0.7-0.2,1.3-0.7,1.6-1.3c0.3-0.5,0.4-1.1,0.2-1.7c-0.1-0.2-0.1-0.5-0.2-0.7
								c0-0.2-0.1-0.4-0.2-0.5c-0.5-1-1.7-1.6-2.7-1.3c-1.3,0.4-2.1,1.6-1.8,2.9c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.8,0.9,1.5,1.7,1.8
								C837.2,605.9,837.7,605.9,838.1,605.8z"/>
							<path class="st4" d="M823.2,601.1c0.2-0.6,0.3-1.2,0.5-1.8c0.4-1.2-0.4-2.7-1.7-3c-1.3-0.3-2.7,0.3-3,1.7
								c-0.2,0.6-0.3,1.2-0.5,1.8c-0.4,1.2,0.4,2.7,1.7,3C821.4,603.1,822.8,602.4,823.2,601.1z"/>
							<path class="st4" d="M830.5,610.9c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.9-0.3-1.8-1.1-2.4c-0.8-0.6-1.6-1.2-2.4-1.8
								c-1-0.8-2.8-0.3-3.4,0.8c-0.7,1.2-0.3,2.6,0.8,3.4c0.8,0.6,1.6,1.2,2.4,1.8c0.5,0.4,1.1,0.4,1.7,0.3
								C829.5,611.8,830.1,611.5,830.5,610.9z"/>
							<path class="st4" d="M837.9,614.6c-1.2-0.7-2.5-0.3-3.4,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.6-0.6,1.2-0.8,1.8
								c-0.1,0.6-0.2,1.1-0.2,1.7c0.1,1.3,1,2.5,2.4,2.5c1.2,0,2.6-1,2.5-2.4c0-0.1,0-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.3
								c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.4C839.5,617,839,615.2,837.9,614.6z M838.2,619
								c0-0.1,0-0.2,0-0.2C838.2,618.9,838.2,618.9,838.2,619z M838.3,618.6c0,0,0-0.1,0.1-0.2C838.4,618.5,838.4,618.5,838.3,618.6z
								"/>
							<path class="st4" d="M848.5,615.5c-0.2-0.2-0.4-0.5-0.7-0.6c-2.7-1.7-5.3,2.5-2.6,4.2c1.1,0.7,2.1,0.4,2.8-0.2
								C848.9,618,849.3,616.6,848.5,615.5z"/>
							<path class="st4" d="M825.9,613.6c-1.5-0.9-3,0-3.5,1.2c-0.4,1-0.2,2.2,0.9,2.9c0.8,0.5,1.5,0.5,2.2,0.2
								C827,617.2,827.8,614.8,825.9,613.6z"/>
							<path class="st4" d="M815.4,605.9c-0.4-0.2-0.7-0.3-1.1-0.4c-2.2-0.2-3.8,3.1-1.5,4.5c1.7,1.1,3.4-0.3,3.7-1.8
								C816.6,607.4,816.3,606.5,815.4,605.9z"/>
							<path class="st4" d="M859.2,592.7c0.3,0.2,0.6,0.3,0.9,0.3c2.3,0.4,4.1-3,1.7-4.5c-1.7-1-3.3,0.2-3.6,1.6
								C858,591.1,858.2,592.1,859.2,592.7z"/>
							<path class="st4" d="M810,595.6c2.7,1.7,5.3-2.5,2.6-4.2C809.9,589.8,807.3,593.9,810,595.6z"/>
							<path class="st4" d="M861.5,612.8c-0.7-0.4-1.4-0.5-2-0.3c-1.7,0.6-2.6,3.2-0.6,4.4c2.2,1.4,4.3-1.2,3.6-3
								C862.3,613.5,862,613.1,861.5,612.8z"/>
							<path class="st4" d="M724.7,592.4c0.2,0.2,0.5,0.3,0.9,0.3c2.1,0.1,2.3-3.1,0.2-3.2c-0.6,0-1,0.2-1.3,0.6
								C723.9,590.7,724,591.9,724.7,592.4z"/>
							<path class="st4" d="M730.5,587c0.6,0,1.1-0.2,1.3-0.6h-2.5C729.6,586.8,730,587,730.5,587z"/>
							<path class="st4" d="M737.3,592.7c2.1,0.1,2.3-3.1,0.2-3.2C735.4,589.4,735.2,592.6,737.3,592.7z"/>
							<path class="st4" d="M720.1,587.9c1,0.1,1.6-0.7,1.6-1.5h-3.1C718.7,587.2,719.1,587.8,720.1,587.9z"/>
							<path class="st4" d="M739.9,587.4c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1-0.7h-4C738.4,587.1,739.1,587.6,739.9,587.4z"/>
							<path class="st4" d="M729.8,594.1c0.6,0.6,1.7,0.7,2.3,0.1c0.6-0.6,0.7-1.6,0.1-2.3c-0.1-0.1-0.2-0.2-0.3-0.3
								c-0.6-0.6-1.7-0.7-2.3-0.1c-0.6,0.6-0.7,1.6-0.1,2.3C729.6,593.9,729.7,594,729.8,594.1z"/>
							<path class="st4" d="M718.8,596.9c0.2,0.8,1.1,1.5,1.9,1.2c0.6-0.2,1-0.6,1.2-1.1c0.1-0.3,0.1-0.5,0-0.8
								c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.8-1.1-1.5-1.9-1.2c-0.8,0.2-1.4,1-1.2,1.9C718.6,596.1,718.7,596.5,718.8,596.9z"/>
							<path class="st4" d="M729.2,599.4c-0.1-0.8-0.7-1.4-1.5-1.5c-0.7-0.1-1.3-0.2-2-0.3c-0.8-0.1-1.7,0.7-1.7,1.5
								c0,0.9,0.6,1.6,1.5,1.7c0.7,0.1,1.3,0.2,2,0.3c0.4,0.1,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.7,0.6-1.1
								C729.3,599.5,729.2,599.5,729.2,599.4z"/>
							<path class="st4" d="M736.3,601.2c0.1-0.8-0.7-1.7-1.5-1.7c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.3-0.7,0.7-0.8,1.3
								c-0.1,0.9,0.1,1.9,0.6,2.7c0.5,0.7,1.4,1.2,2.2,0.7c0.7-0.4,1.2-1.4,0.7-2.2c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.1-0.1-0.2
								c0-0.1,0-0.1,0-0.2C736.2,601.3,736.3,601.2,736.3,601.2z"/>
							<path class="st4" d="M740.4,599.7c0.6,0,1.1-0.2,1.4-0.7c0.6-0.9,0.3-2.5-1.2-2.6c-0.3,0-0.5,0-0.7,0.1
								c-0.9,0.4-1.2,1.6-0.7,2.4C739.4,599.4,739.8,599.7,740.4,599.7z"/>
							<path class="st4" d="M727.3,605.9c2.1,0.1,2.3-3.1,0.2-3.2C725.4,602.5,725.2,605.7,727.3,605.9z"/>
							<path class="st4" d="M718.7,604.7c0.8,0,1.3-0.4,1.5-0.9c0.3-0.7,0.2-1.5-0.4-2c-0.2-0.2-0.5-0.3-0.9-0.4
								C716.8,601.3,716.6,604.6,718.7,604.7z"/>
							<path class="st4" d="M712.6,597.2c0.1,0,0.2,0,0.4,0c1.7-0.2,1.8-3.1-0.2-3.2c-1.3-0.1-1.8,1.1-1.6,2
								C711.3,596.6,711.7,597.2,712.6,597.2z"/>
							<path class="st4" d="M747.4,594.1c0.1,0,0.2,0,0.3,0c1.8,0.1,2.2-2.2,1-3c-0.2-0.1-0.5-0.2-0.8-0.3
								C745.9,590.8,745.6,593.8,747.4,594.1z"/>
							<path class="st4" d="M707,587.7c0.2,0.5,0.6,0.8,1.3,0.9c1.3,0.1,1.8-1.1,1.6-2.1h-3C706.8,586.8,706.8,587.3,707,587.7z"/>
							<path class="st4" d="M1550.9,589.7c2.3,1.3,4.5-1.3,3.7-3.2h-4.7C1549.4,587.5,1549.5,588.9,1550.9,589.7z"/>
							<path class="st4" d="M1570.4,593.7c-2.1-1.2-4.1,0.9-3.8,2.8c0.1,0.7,0.5,1.3,1.2,1.7c0.2,0.1,0.4,0.2,0.6,0.3
								C1571,599.3,1573.1,595.3,1570.4,593.7z"/>
							<path class="st4" d="M1575.4,592.3c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.6,0.2,1,0.2c1.3,0.3,2.9-0.5,3.2-1.9
								c0.3-1.4-0.5-2.8-1.9-3.2c-0.6-0.2-1.3-0.3-1.9-0.5c-1.3-0.3-2.9,0.5-3.2,1.9c0,0.2-0.1,0.3-0.1,0.5
								C1573.4,590.8,1574.1,592,1575.4,592.3z"/>
							<path class="st4" d="M1560.8,592.7c-0.4-1.3-1.9-2.3-3.2-1.8c-1.3,0.4-2.2,1.8-1.8,3.2c0.1,0.2,0.1,0.5,0.2,0.7
								c0.4,1.3,1.9,2.3,3.2,1.8c1.3-0.4,2.2-1.8,1.8-3.2C1560.9,593.2,1560.8,593,1560.8,592.7z"/>
							<path class="st4" d="M1543.6,590.3c0.3-1.3-0.5-2.9-1.9-3.2c-1.4-0.3-2.8,0.5-3.2,1.9c-0.2,0.6-0.3,1.3-0.5,1.9
								c-0.3,1.3,0.5,2.9,1.9,3.2c1.4,0.3,2.8-0.5,3.2-1.9C1543.3,591.5,1543.4,590.9,1543.6,590.3z"/>
							<path class="st4" d="M1550.3,598.7c-0.9-0.6-1.8-1.2-2.6-1.8c-1.1-0.8-3-0.2-3.6,1c-0.7,1.3-0.3,2.7,1,3.6
								c0.9,0.6,1.8,1.2,2.6,1.8c1.1,0.8,3,0.2,3.6-1C1551.9,601,1551.5,599.6,1550.3,598.7z"/>
							<path class="st4" d="M1559.3,606c-1.4-0.7-2.7-0.2-3.6,1c-0.8,1.2-1.3,2.8-1.1,4.3c0.1,1.4,1.1,2.7,2.6,2.6
								c1.3-0.1,2.7-1.2,2.6-2.6c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
								c0.1-0.2,0.2-0.3,0.3-0.4C1561,608.5,1560.4,606.7,1559.3,606z M1559.9,610c0,0,0,0.1-0.1,0.2
								C1559.8,610.2,1559.9,610.1,1559.9,610z M1559.8,610.5c0,0.1,0,0.2,0,0.3C1559.7,610.6,1559.8,610.5,1559.8,610.5z"/>
							<path class="st4" d="M1569.8,606c-2.9-1.7-5.5,2.8-2.6,4.5C1570.1,612.2,1572.7,607.6,1569.8,606z"/>
							<path class="st4" d="M1546.4,605.4c-2.9-1.7-5.5,2.8-2.6,4.5C1546.7,611.6,1549.3,607.1,1546.4,605.4z"/>
							<path class="st4" d="M1534.9,597.6c-2.9-1.7-5.5,2.8-2.6,4.5C1535.2,603.8,1537.9,599.2,1534.9,597.6z"/>
							<path class="st4" d="M1531.8,586.4h-3.5c0.1,0.1,0.3,0.3,0.5,0.4C1530,587.5,1531.1,587.2,1531.8,586.4z"/>
							<path class="st4" d="M1584.3,603.2c-1.7-1-3.3,0.2-3.7,1.6c-0.1,0.4-0.2,0.8-0.1,1.2c0.1,0.7,0.5,1.3,1.2,1.8
								C1584.6,609.4,1587.2,604.9,1584.3,603.2z"/>
						</g>
					</g>
					<g>
						<path class="st3" d="M1689.4,667.5c-1.9,0-3.5-1.6-3.5-3.5v-59.6c0-8-6.5-14.5-14.5-14.5h-551.5c-1.9,0-3.5-1.6-3.5-3.5
							s1.6-3.5,3.5-3.5h551.5c11.9,0,21.5,9.6,21.5,21.5V664C1692.9,665.9,1691.4,667.5,1689.4,667.5z"/>
					</g>
					<g>
						<path class="st4" d="M1639.7,652.7v316.6c0,9.2-7.4,16.6-16.6,16.6H573c-12.2,0-20.2-12.8-14.9-23.8l110.1-228.7l42.3-87.9
							c2.8-5.7,8.6-9.4,14.9-9.4h897.8C1632.3,636.2,1639.7,643.6,1639.7,652.7z"/>
						<path class="st3" d="M1639.7,831.3v4.9c-28.5,7.7-57.1,15.4-85.6,23.1c-43.3,11.7-125.2,34.8-169.8,21.2
							c-19.6-6-38.1-15.4-58.3-19.8c-18.5-4-37.3-2.1-53.9,7.3c-30.4,17.3-48.7,52.8-51,87c-0.7,10.4-0.3,20.7,0.9,30.9h-5
							c-1.6-13.6-1.7-27.4,0.4-41.2c5.1-33.5,25.2-67.5,56.2-82.9c15.8-7.9,33.2-9.6,50.5-6.3c21.3,4,40.8,14.1,61.4,20.4
							c47.1,14.4,132.6-11.9,178.2-24.1C1589.1,844.9,1614.4,838.1,1639.7,831.3z"/>
						<path class="st3" d="M1639.7,711.9v4.8c-41.7-0.9-84.1-8.2-125,2.6c-21,5.6-38.3,17-56,29c-15.5,10.5-31.9,21.3-50.9,23.6
							c-21.2,2.6-40.4-7.5-56.8-19.9c-18.5-14-35.8-30-52.1-46.5c-15.4-15.7-31.8-32.2-43.1-51.2c-3.4-5.6-6.3-11.8-8.3-18.2h4.8
							c3.7,11.2,11,21.9,17.7,30.2c15.7,19.4,33.2,37.5,51.5,54.5c15.9,14.6,32.6,30.4,52.1,40c18.9,9.3,38.6,8.1,57.2-1.1
							c17.1-8.5,31.9-20.8,48.2-30.5c17.3-10.3,36.4-16.2,56.3-18.7C1570.2,706.1,1604.9,711.1,1639.7,711.9z"/>
						<path class="st3" d="M1456.5,934.4c4.1,5.5,11.2,8.2,17.6,10c8.2,2.4,16.6,4.1,25,5.8c46.2,9.3,93.7,12,140.7,8.2v4.8
							c-16.4,1.3-32.8,1.8-49.3,1.6c-38.5-0.6-77.3-5.4-114.6-15c-11.1-2.9-26-8.6-26.8-22.1c-0.8-13.2,12.9-21,23.8-24.4
							c16.2-5.2,34-7,50.9-8.3c18-1.4,36.2-1.8,54.3-2.4c20.6-0.7,41.4-1.7,61.7-5.3v4.8c-20,3.5-40.6,4.6-60.9,5.3
							c-33.7,1.1-68.2,0.9-101.1,9.5C1466.8,909.6,1445.7,920.1,1456.5,934.4z"/>
						<path class="st3" d="M1221.7,831.7c-12.1,16.9-37.2,22.7-56.6,24.9c-22.4,2.5-45,0.2-67.4-1.3c-22-1.5-44.1-1.8-66.1-0.7
							c-22.5,1.1-44.6,4.2-66.9,7.5c-10.7,1.6-22.6,2.4-31.9-4.1c-3.4-2.3-6.3-5.4-6.4-9.7c-0.2-5.6,4-8.4,8.3-11.1
							c17.9-11.3,38.4-22.1,60.2-20.2c19.4,1.7,37.2,10.8,56.2,14.3c20.5,3.7,40.7-1.1,59.8-8.2c23-8.6,45.3-20.5,70-23.4
							c10.9-1.3,23.2-1,33.3,3.9c11.7,5.7,14.1,17.9,7.3,28.6c-1.6,2.6,2.7,4.6,4.3,2c4.9-7.7,6.4-17.7,1.4-25.7
							c-4.4-7-12.7-10.7-20.5-12.5c-22.7-5.2-46.3,2.2-67.3,10.5c-22.6,9-45.3,20.5-70.1,21.4c-22.9,0.9-43.4-10.4-65.4-14.5
							c-22.3-4.1-42.9,3.1-62.2,14.1c-7.1,4.1-17.4,8.3-19.5,17.2c-1.9,8,3.2,14.5,9.7,18.4c9,5.3,19.7,5.6,29.8,4.3
							c12.6-1.5,25.2-4,37.8-5.4c25.1-2.8,50.5-3.9,75.7-3.1c28.1,0.8,56.3,5.2,84.5,3c12.6-1,25.2-3.3,37.1-7.7
							c10.8-4.1,21.9-9.8,28.7-19.4C1227.1,832.3,1223.5,829.2,1221.7,831.7z"/>
						<path class="st3" d="M1129.3,710.4c-5.3,5.7-13,9.1-19.9,12.5c-7.9,3.9-16.1,7.3-24.4,10.4c-17,6.2-34.7,10.7-52.6,13.3
							c-75.2,10.9-147.1-15.4-218.5-35.2c-40.7-11.2-82.2-19.1-124-23.3l2.2-4.5c46.2,4.9,91.9,14.2,136.8,27.1
							c69.5,19.9,140.2,43.3,213.3,29.5c17.5-3.3,34.7-8.4,51.2-15.2c8.1-3.3,16.2-7.1,23.8-11.4c7-4,13.9-9.7,12.6-18.6
							c-2.7-18.7-24.5-21.3-39.5-19.6c-17.3,2-34.2,5-51.7,5.8c-67.8,2.9-134.5-12.6-200.5-25.8c-39.5-7.9-79.6-15.3-119.9-17.5
							c2.2-1,4.6-1.6,7.1-1.6h29.4c37.9,4.6,75.5,12.6,112.7,20.1c69.7,14.1,140.7,27.2,211.8,15.8c15.3-2.5,33-5.2,45.8,5.7
							C1134.7,686.1,1138.5,700.3,1129.3,710.4z"/>
						<path class="st3" d="M1117.7,985.9h-14.5c-7.2-2.2-14.3-4.3-21.3-6.4c-49.1-15.2-98.9-29.7-150.2-34.9
							c-48.8-5-97.9,0.4-146.7-4.4c-24.2-2.4-47.4-7.7-70-16.7c-23-9.2-45.1-19-69.7-22.9c-18.8-3-37.6-3.5-56.5-2.4l2.3-4.9
							c23.8-1.2,47.5,0.1,70.9,5.7c24.6,6,46.8,18,70.8,25.9c24.2,7.9,49.6,11,75,12c25.3,1.1,50.7,0.2,76,0.5
							c25.4,0.2,50.6,2,75.7,6c51,8.2,101.1,23.6,150.1,39.6C1112.3,984,1115,984.9,1117.7,985.9z"/>
					</g>
				</g>
				<g>
					<circle class="st3" cx="1792.7" cy="1109" r="96"/>
					<circle class="st2" cx="1792.7" cy="1109" r="31.3"/>
					<g>
						<path class="st2" d="M1774.8,1180.3c-0.3,0-0.6,0-0.9-0.1c-30.4-5.8-50.7-28.1-51.8-56.9c-0.1-2.6,1.9-4.7,4.5-4.8
							c2.5-0.1,4.7,1.9,4.8,4.5c0.9,24.3,18.2,43.2,44.2,48.1c2.5,0.5,4.2,2.9,3.7,5.4C1779,1178.7,1777,1180.3,1774.8,1180.3z"/>
					</g>
					<circle class="st3" cx="1792.7" cy="1109" r="12.7"/>
				</g>
				<g>
					<circle class="st3" cx="731.8" cy="1109" r="96"/>
					<circle class="st2" cx="731.8" cy="1109" r="31.3"/>
					<circle class="st3" cx="731.8" cy="1109" r="12.7"/>
					<g>
						<path class="st2" d="M665.1,1096c-0.3,0-0.6,0-0.9-0.1c-2.5-0.5-4.2-2.9-3.7-5.4c5.6-30.4,27.9-50.8,56.7-52
							c2.6-0.1,4.7,1.9,4.8,4.4s-1.9,4.7-4.4,4.8c-24.3,1-43.1,18.4-47.9,44.4C669.3,1094.4,667.3,1096,665.1,1096z"/>
					</g>
				</g>
				<g>
					<circle class="st3" cx="996.9" cy="1109" r="96"/>
					<circle class="st2" cx="996.9" cy="1109" r="31.3"/>
					<g>
						<path class="st2" d="M1049.5,1155.9c-1.3,0-2.6-0.5-3.5-1.6c-1.7-1.9-1.5-4.9,0.4-6.5c18.3-16,22-41.4,9.5-64.6
							c-1.2-2.3-0.4-5.1,1.9-6.3c2.3-1.2,5.1-0.4,6.3,1.9c14.7,27.2,10.2,57.1-11.5,76C1051.6,1155.5,1050.5,1155.9,1049.5,1155.9z"
							/>
					</g>
					<circle class="st3" cx="996.9" cy="1109" r="12.7"/>
				</g>
			</g>
			<g>
				<path class="st3" d="M2109.8,1211.4H447.5c-3.9,0-7-3.1-7-7s3.1-7,7-7h1662.2c3.9,0,7,3.1,7,7S2113.6,1211.4,2109.8,1211.4z"/>
			</g>
		</g>
		<g>
			<path class="st7" d="M542.4,808.7l-111.9-72.4c-4.6-3-10.6,0.3-10.6,5.8v36.1H208c-3.8,0-6.9,3.1-6.9,6.9v58.8
				c0,3.8,3.1,6.9,6.9,6.9h211.8v36.1c0,5.4,6,8.7,10.6,5.8l111.9-72.4C546.6,817.5,546.6,811.4,542.4,808.7z"/>
			<g>
				<path class="st3" d="M419.9,829.7H208c-3.8,0-6.9-3.1-6.9-6.9v21c0,3.8,3.1,6.9,6.9,6.9h211.8V829.7z"/>
				<path class="st3" d="M542.4,808.7l-7.3-4.8l-104.6,67.7c-4.6,3-10.6-0.3-10.6-5.8v21c0,5.4,6,8.7,10.6,5.8l111.9-72.4
					C546.6,817.5,546.6,811.4,542.4,808.7z"/>
			</g>
			<path class="st6" d="M529.4,807.1c-1.3,0-2.6-0.4-3.7-1.1l-98.9-64v36.1c0,3.8-3.1,6.9-6.9,6.9H208.6c-3.8,0-6.9-3.1-6.9-6.9
				c0-3.8,3.1-6.9,6.9-6.9H413V742c0-5,2.7-9.7,7.2-12.1c4.4-2.4,9.8-2.2,14,0.5l98.9,64c3.2,2.1,4.1,6.3,2,9.5
				C533.9,806,531.7,807.1,529.4,807.1z"/>
		</g>
	</g>
	</svg>
</template>

<style lang="scss" scoped>
.st0{fill:#FFE4DE;}
.st1{fill:#FFEEEB;}
.st2{fill:#265987;}
.st3{fill:#173D60;}
.st4{fill:#F99178;}
.st5{fill:#D65C5C;}
.st6{fill:#FFFFFF;}
.st7{fill:#58DEF4;}
.st8{opacity:0.8;fill:#279193;}
</style>