const url_wn = `${process.env.VUE_APP_WN}/`;

export const get_vehicles_sensor = 
 async ({
    organisation: organisation_id,
    vehicle_id: vehicle_id = null,
  }) => {
    //Ожидает из параметров query
    //key: organisation_id с id организации
    const url = new URL('carpark/vehicle_mobile_monitoring', url_wn);

    let result = await window.ky_wn.post(url, {
      json: {
        organisation_id,
        vehicle_id,
        custom_organisation: true,
      }
    }).json();
    
    if (vehicle_id) {
      result.co = [result.lat ? result.lat : 0, result.lng ? result.lng : 0];
    } else {
      result?.data.map(element => {
        element.co = [element.lat ? element.lat : 0, element.lng ? element.lng : 0];
  
        return element;
      })
    }

    return result
  }