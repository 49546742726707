import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './routes'
import store from './store'
import { Api } from "./services/Api"
import ky from './ky'
import components from '@/components'
import './styles/fonts.scss'

window.ky = ky;
window.Api = Api()

const app = createApp(App);

components.forEach(element => {
  app.component(element.name, element.component)
})

app.use(store).use(router).mount('#app')