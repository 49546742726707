<!-- КГО к вывозу -->
<template>
  <div class="kgo-remaining">
    <div class="kgo-remaining-title">
      <img src="@/assets/images/sofa_up.svg" alt="" />
      КГО к вывозу
    </div>
    <div class="kgo-remaining-cards">
      <kgo-remaining-card
        :item="item"
        v-for="(item, index) of items"
        :key="index"
      />
      <div class="preloader" v-if="loading">
        <preloaderLogo />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { global_state } from "@/store/global";
import kgoRemainingCard from "./kgo_remaining-card.vue";
import preloaderLogo from "@/components/icons_color/preloader_logo";

export default {
  components: { kgoRemainingCard, preloaderLogo },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.fetching();
  },
  setup() {
    const globalStore = global_state();

    return { globalStore };
  },
  methods: {
    fetching() {
      this.loading = true;

      if (this.globalStore.use_organisation?.id && this.globalStore.use_date) {
        Api.events
          .event_remaining_kgo({
            organisation: this.globalStore.use_organisation.id,
            date: this.globalStore.use_date,
          })
          .then((res) => {
            this.items = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.kgo-remaining {
  padding: 0 26px;
}

.kgo-remaining-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  & img {
    margin-right: 15px;
    display: inline-block;
    width: 30px;
  }
}
.preloader {
  padding-top: 50px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.kgo-remaining-cards {
}
</style>