const url_wn = `${process.env.VUE_APP_WN}/`;
//breakdown - Поломка; failure: Невывозы; kgo - кго забрали; kgo_remaining - кго к вывозу;

export const get_event_failure = async ({
  organisation,
  date
}) => {
  //Ожидает из параметров query
  //key: о с id организации 
  //key: date для фильтрации по выбранному дню

  const url = new URL('fact/failure', url_wn);
  url.searchParams.set('page', 'all')
  url.searchParams.set('o', organisation)
  url.searchParams.set('date', date)
  url.searchParams.set('waste_type_ids', [])

  return await window.ky_wn.get(url).json();
}

export const get_event_breakdown = async ({
  organisation,
  date
}) => {
  //Ожидает из параметров query
  //key: о с id организации 
  //key: date для фильтрации по выбранному дню

  const url = new URL('fact/breakdown', url_wn);
  url.searchParams.set('page', 'all')
  url.searchParams.set('o', organisation)
  url.searchParams.set('date', date)
  url.searchParams.set('waste_type_ids', [])

  return await window.ky_wn.get(url).json();
}

export const get_event_kgo =
  async ({
    organisation: o,
    date,
    page = 'all',
    waste_type_ids = [],
  }) => {
    //Ожидает из параметров query
    //key: о с id организации 
    //key: date для фильтрации по выбранному дню
    //key: page = 'all' дефолтное все страницы 
    //key: waste_type_ids = [] массив с id типа контейнерной площадки
    const url = new URL('fact/kgo_list', url_wn);

    return await window.ky_wn.post(url, {
      json: {
        o,
        date,
        page,
        waste_type_ids,
      }
    }).json();
  }

export const get_event_remaining_kgo =
  async ({
    organisation: o,
    date,
    page = 'all',
    waste_type_ids = [],
  }) => {
    //Ожидает из параметров query
    //key: о с id организации 
    //key: date для фильтрации по выбранному дню
    //key: page = 'all' дефолтное все страницы 
    //key: waste_type_ids = [] массив с id типа контейнерной площадки
    const url = new URL('fact/remaining_kgo_list', url_wn);

    return await window.ky_wn.post(url, {
      json: {
        o,
        date,
        page,
        waste_type_ids,
      }
    }).json();
  }